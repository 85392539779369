






import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { constants } from '@/utils/constants';

@Component({
  props: {
    routeObj: {
      type: Object,
    },
  },
})
export default class RdmEditNavBtn extends Vue {
  get Route() {
    if (this.$props.routeObj) {
      return this.$props.routeObj;
    }
    return {
      name: constants.routes.COMPANY,
    };
  }
}
