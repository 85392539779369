











































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import { httpModule } from '@/store/modules/moduleHttp';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { GRSchedule, Schedule, TimeSlot } from '@/models';
import ProductCreateSchedule from '@/components/product/create/ProductCreateSchedule.vue';
import RdmTimeInput from '@/components/common/time/RdmTimeInput.vue';
import {
  optionalStartDateBeforeEndDateRules,
  requiredFieldRules,
} from '@/utils/validation-rules';

@Component({
  components: {
    RdmCollapsableControlCard,
    ProductCreateSchedule,
    RdmTimeInput,
  },
})
export default class ProductViewSchedule extends Vue {
  private structuredSchedule = [] as any;
  private editDateRange = false;
  private requiredFieldRules = requiredFieldRules;
  private optionalStartBeforeEndField = optionalStartDateBeforeEndDateRules;
  private startDateMenu = false;
  private endDateMenu = false;
  private editedDateStart = '';
  private editedDateEnd = '' as string | undefined | null;
  private startTime = '';
  private endTime = '';
  private modalIsOpen = false;
  private reload = false;

  get Product() {
    return productModule.Product;
  }
  get ProductSchedules() {
    return productModule.ProductSchedules;
  }

  get ProductSchedule() {
    return productModule.ProductSchedule;
  }

  get CreateSchedule() {
    return scheduleModule.CreateSchedule;
  }

  get Loading() {
    return scheduleModule.Loading;
  }

  async mounted() {
    await httpModule.productSchedules(this.Product.id);
  }

  updateSlotStart(slot: any, newStartTime: any) {
    slot.start = newStartTime;
  }

  updateSlotEnd(slot: any, newEndTime: any) {
    slot.end = newEndTime;
  }

  validSlotTime(slot: any) {
    if (slot.start && slot.end) {
      let hourMins = slot.start.split(':');
      const start = {
        hours: Number(hourMins[0]),
        minutes: Number(hourMins[1]),
      };

      hourMins = slot.end.split(':');
      const end = {
        hours: Number(hourMins[0]),
        minutes: Number(hourMins[1]),
      };

      if (start.hours * 60 + start.minutes > end.hours * 60 + end.minutes) {
        return true;
      }
    }

    return false;
  }

  toggleEditDateRange() {
    this.editDateRange = !this.editDateRange;
    this.editedDateStart = this.ProductSchedule.validFrom;
    this.editedDateEnd = this.ProductSchedule.validUntil;
  }

  allowedDateFilter(d: string) {
    /**
     * Example function logic
     *
     *     const date = new Date(d);
     *     if (date.getDay() === 3 || date.getDay() === 5) {
     *       return d;
     *     }
     *     return null;
     */
    // todo check if date is within the start and end date of each schedule. If the date is, then disable the date.
    // todo: move to helper file and change signature to (date: string, schedules: Array<Schedule>)
    return d;
  }

  private openModal() {
    this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  async deleteSchedule() {
    httpModule
      .deleteProductSchedule({
        product: this.Product,
        schedule: this.ProductSchedule,
      })
      .then(() => {
        const updatedSchedulesList = this.ProductSchedules.filter(
          (item: GRSchedule) => item.id !== this.ProductSchedule.id,
        );
        productModule.setProductSchedules(updatedSchedulesList);
        productModule.setProductSchedule({} as Schedule);
        this.structuredSchedule = [];
      })
      .catch()
      .finally(() => this.closeModal());
  }

  async saveUpdatedDateRange() {
    const dateRange = {
      validFrom: this.editedDateStart,
      validUntil: this.editedDateEnd,
    };
    await httpModule.updateScheduleDateRange({
      dateRange: dateRange,
      schedule: this.ProductSchedule,
    });
    await httpModule.productSchedules(this.Product.id);
    this.toggleEditDateRange();
  }

  async saveUpdatedTimeSLot(slot: TimeSlot, weekday: string) {
    let payload = {} as TimeSlot;
    payload.end = slot.end;
    payload.start = slot.start;
    payload = { ...payload, key: slot.key, weekday: weekday };

    await httpModule.updateScheduleTimeslot({
      slot: payload,
      schedule: this.ProductSchedule,
    });
    await httpModule.productSchedules(this.Product.id);
  }

  async deleteTimeSlot(slot: TimeSlot) {
    await httpModule.deleteScheduleTimeslot({
      slot: slot,
      schedule: this.ProductSchedule,
    });
    await httpModule.productSchedules(this.Product.id);
  }

  async toggleEditTimeSlot(i: number, slot: TimeSlot, state: boolean) {
    if (state == false) await httpModule.productSchedules(this.Product.id);
    const copy = [...this.structuredSchedule];
    copy[i].timeSlots.map((item: TimeSlot) => {
      item.editMode = item.key === slot.key ? state : item.editMode;
    });

    this.startTime = slot.start;
    this.endTime = slot.end;
    this.structuredSchedule = copy;
  }

  addSchedule() {
    productModule.goCreateProductStep(4);
    productModule.setCreateProductScheduleOpen(true);
    scheduleModule.clearState();
    scheduleModule.toggleCreateSchedule();
  }

  structuringData() {
    if (this.ProductSchedule.timeSlots === null) return (this.structuredSchedule = []);
    const days = [] as Array<string>;
    let timeSlots = [] as any;
    const structured = [] as any;
    this.ProductSchedule.timeSlots.map((item) => {
      if (!days.includes(item.weekday)) {
        days.push(item.weekday);
      }
    });
    days.map((day) => {
      this.ProductSchedule.timeSlots.map((item) => {
        if (item.weekday === day) {
          timeSlots.push(item);
        }
      });
      structured.push({
        weekday: day,
        timeSlots: timeSlots,
      });
      timeSlots = [];
    });
    this.structuredSchedule = structured;
  }

  async toggleSchedule(i: number, schedule: GRSchedule) {
    const updatedStateSchedules = [...this.ProductSchedules];
    updatedStateSchedules.map((item, index) => {
      if (i === index) {
        item.open = !item.open;
      } else {
        item.open = false;
      }
    });
    await httpModule.getSchedule(schedule.id);
    productModule.setProductSchedules(updatedStateSchedules);
    this.structuringData();
  }
}
