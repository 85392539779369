











































































































































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import { requiredFieldRules, validWholeNumbersRules } from '@/utils/validation-rules';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import {
  BookingCutoffOptions,
  CancellationPolicyOptions,
  Product,
  ProductPolicy,
  RefundablePolicyOptions,
} from '@/models';
import { handleKeyDownOnlyWholeNumeric } from '@/utils/helpers';

@Component
export default class ProductViewPolicyModal extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private validWholeNumbersRules = validWholeNumbersRules;
  private product = {} as Product;
  private cancellationType = 0 as CancellationPolicyOptions;
  private refundable = 0 as RefundablePolicyOptions;
  private bookingCutoff = 0 as BookingCutoffOptions;
  private cutoffForm = false;

  private bookingCutoffTimes = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;

  mounted() {
    this.product = _.cloneDeep(this.Product);
    if (this.product && this.product.policy) {
      this.cancellationType = this.product.policy.cancellable
        ? CancellationPolicyOptions.YES
        : CancellationPolicyOptions.NO;
      this.refundable =
        this.product.policy.refundable !== undefined && this.product.policy.refundable
          ? RefundablePolicyOptions.YES
          : RefundablePolicyOptions.NO;
      this.bookingCutoff =
        this.product.policy.cutOffTime && this.product.policy.cutOffTime > 0
          ? BookingCutoffOptions.YES
          : BookingCutoffOptions.NO;
      if (this.bookingCutoff === BookingCutoffOptions.YES) {
        const dhm = this.secondsToDhms(this.product.policy.cutOffTime);
        this.bookingCutoffTimes.days = dhm.days;
        this.bookingCutoffTimes.hours = dhm.hours;
        this.bookingCutoffTimes.minutes = dhm.mins;
      }
    }
  }

  secondsToDhms(seconds: any) {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (60 * 24));
    const h = Math.floor((seconds % (60 * 24)) / 60);
    const m = Math.floor(seconds % 60);

    return {
      days: d,
      hours: h,
      mins: m,
    };
  }

  clearValidateState() {
    if (this.$refs?.policyForm) {
      const policyForm: any = this.$refs.policyForm;
      policyForm.reset();
    }
  }

  validate() {
    if (this.$refs?.policyForm) {
      const policyForm: any = this.$refs.policyForm;
      const isValid = policyForm.validate();
      if (isValid) {
        this.createPolicy();
      }
    }
  }

  closeEditModal() {
    productModule.setEditProductPolicyModal(false);
  }

  createPolicy() {
    const minutes = this.bookingCutoffTimes.minutes;
    const minutesFromHours = this.bookingCutoffTimes.hours * 60;
    const minutesFromDays = this.bookingCutoffTimes.days * 24 * 60;
    const cancelCutoff =
      this.bookingCutoff === BookingCutoffOptions.YES
        ? +minutes + +minutesFromHours + +minutesFromDays || 0
        : 0;
    const cancellable = this.cancellationType === CancellationPolicyOptions.YES;
    const refundable = this.refundable === RefundablePolicyOptions.YES;

    this.product.policy = {} as ProductPolicy;
    this.product.policy.refundable = refundable;
    this.product.policy.cancellable = cancellable;
    this.product.policy.cutOffTime = cancelCutoff;
    this.updateProduct();
  }

  async updateProduct() {
    productModule.setProduct(this.product);
    await httpModule
      .updateProduct()
      .then(() => {})
      .catch(() => {
        // todo send alert
      });
    this.closeEditModal();
  }

  get Product() {
    return productModule.Product;
  }

  get EditProductPolicyFormState() {
    return productModule.EditProductPolicyFormState;
  }

  set EditProductPolicyFormState(state: boolean) {
    productModule.setEditProductPolicyFormState(state);
  }

  get Loading() {
    return productModule.Loading;
  }

  /* UI Data */

  get CancellationTypes() {
    return [CancellationPolicyOptions.YES, CancellationPolicyOptions.NO];
  }

  get RefundValues() {
    return [RefundablePolicyOptions.YES, RefundablePolicyOptions.NO];
  }

  get BookingCutoff() {
    return [BookingCutoffOptions.YES, BookingCutoffOptions.NO];
  }
}
