var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.Loading),expression:"Loading"}],attrs:{"type":"table-thead, table-row-divider@10, table-tfoot"}}),(!_vm.Loading)?[_c('v-row',{staticClass:"max-width"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Search'),"append-icon":"mdi-magnify","clearable":"","outlined":"","hide-details":"","background-color":"white"},model:{value:(_vm.Search),callback:function ($$v) {_vm.Search=$$v},expression:"Search"}})],1),_c('v-col',{staticClass:"text-align-right",attrs:{"cols":"12","md":"4","offset-md":"4","align-self":"end"}},[(_vm.Company && _vm.Company.orgId && _vm.Company.orgId.length > 0 && _vm.IsOrgAdmin)?_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.navigateToAddContacts}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Add Contact")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"max-width",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.Loading),expression:"!Loading"}],staticClass:"elevation-0",attrs:{"headers":_vm.contactsHeaders,"items":_vm.Contacts,"items-per-page":10,"search":_vm.Search,"sort-by":['lastName', 'firstName'],"loading":_vm.Loading,"hide-default-footer":(_vm.Contacts && _vm.Contacts.length === 0) || !_vm.Contacts,"footer-props":{
              itemsPerPageOptions: _vm.paginationOptions,
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
            }},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" ")]}},{key:"item.lastName",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastName)+" ")]}},{key:"item.role",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}},{key:"item.view",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","plain":"","disabled":!item},on:{"click":function($event){return _vm.navigateToView(item)}}},[_vm._v("View")]),(_vm.IsOrgAdmin)?_c('v-btn',{attrs:{"color":"error","plain":""},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" Remove ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-left"},[_vm._v(" You don’t have any Contacts yet. Click "),_c('router-link',{attrs:{"to":{ name: _vm.routeContactAdd }}},[_vm._v("here")]),_vm._v(" to add one. ")],1)]},proxy:true}],null,false,1117803778)})],1)],1)],1)]:_vm._e(),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.modalIsOpen),callback:function ($$v) {_vm.modalIsOpen=$$v},expression:"modalIsOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("Remove Contact")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',[_vm._v("Are you sure you want to remove Contact?")]),_c('p',[_vm._v(" By removing this Contact you are potentially changing data associated to a channel. Please validate and republish your data. ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.removeContact}},[_vm._v("Remove")]),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }