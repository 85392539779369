























































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmReturnToBtn from '../../../components/common/button/RdmReturnToBtn.vue';
import { constants } from '@/utils/constants';
import { productModule } from '@/store/modules/moduleProduct';
import {
  optionalStartDateBeforeEndDateRules,
  requiredFieldRules,
  requiredNumberFieldRules,
  requiredOnlyPositiveWholeNumbersFieldRules,
  requiredStartDateBeforeEndDateRules,
} from '@/utils/validation-rules';
import {
  AvailabilitySchedule,
  AvailabilityType,
  Days,
  SelectedDay,
  SelectedDays,
  TimeCapacity,
  TimeEntryMode,
} from '@/api/inventory/InventoryModels';
import moment from 'moment';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import RdmUnsavedChangesModal from '@/components/common/alert/RdmUnsavedChangesModal.vue';
import faker from 'faker';

import _ from 'lodash';
import RdmTimeInput from '@/components/common/time/RdmTimeInput.vue';
import { profileModule } from '@/store/modules/moduleProfile';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';

@Component({
  components: {
    RdmCollapsableCard,
    RdmReturnToBtn,
    RdmTimeInput,
    RdmUnsavedChangesModal,
  },
  props: {},
})
export default class ProductTimeCapacityCreate extends Vue {
  private parentName = constants.titles.PRODUCT;
  private requiredField = requiredFieldRules;
  private requiredOnlyPositiveWholeNumbersFieldRules =
    requiredOnlyPositiveWholeNumbersFieldRules;
  private requiredNumberFieldRules = requiredNumberFieldRules;
  private optionalStartBeforeEndField = optionalStartDateBeforeEndDateRules;
  private requiredStartBeforeEndField = requiredStartDateBeforeEndDateRules;
  private unsavedChanges = false;
  private isLimitedCapacity = false;
  private formValid = false;
  private startDateMenu = false;
  private endDateMenu = false;
  private timeCapacity = {
    name: '',
    validStart: '',
    validEnd: '',
    includeCapacity: false,
  };
  private timeEntryModes = [TimeEntryMode.Grouped, TimeEntryMode.Individual];
  private timeEntryMode = '';
  private timeEntryModeGrouped = TimeEntryMode.Grouped;
  private timeEntryModeIndividual = TimeEntryMode.Individual;
  private days = [] as Array<Days>;
  private availAbleDays = [] as Array<Days>;
  private selectedDays = [] as Array<SelectedDays>;
  private groupsDays = [] as Array<SelectedDay>;
  private individualDays = [] as Array<SelectedDay>;

  mounted() {
    if (!productModule.Product?.id) {
      // todo pop alert
      this.$router.push({ name: constants.routes.PRODUCT_LIST }).catch(() => {});
      return;
    }
    this.timeEntryMode = this.timeEntryModeGrouped;
    this.days = this.createInitialDays();
    this.availAbleDays = this.createInitialDays();
    this.timeCapacity.validStart = moment().format('YYYY-MM-DD');
    if (this.Product?.availabilityType === AvailabilityType.OpeningHours) {
      this.isLimitedCapacity = true;
      this.timeCapacity.includeCapacity = true;
    }
    this.addDayIndividual();
  }

  validateForm() {
    if (this.$refs && this.$refs.createTimeCapacityForm) {
      const { createTimeCapacityForm } = this.$refs as any;
      createTimeCapacityForm.validate();
    }
  }

  addDayIndividual() {
    for (let i = 0; i < this.days.length; i++) {
      this.individualDays.push(this.createIndividualDays(i));
    }
  }

  addDayGroup() {
    this.sortDays(this.availAbleDays);
    this.groupsDays.push(this.createSelectedDay());
    this.availAbleDays.forEach((availableDay) => {
      const dayI = this.days.findIndex((day) => availableDay === day);
      this.days.splice(dayI, 1);
    });
    this.availAbleDays = [];
    this.sortDays(this.days);
  }

  removeDay(day: Days, groupIndex: number, dayIndex: number) {
    try {
      this.days.push(day);
      this.availAbleDays.push(day);
      this.sortDays(this.days);
      this.groupsDays[groupIndex].day.splice(dayIndex, 1);
    } catch (e) {
      // todo send error to backend
    }
  }

  removeDayGroup(groupIndex: number) {
    try {
      this.groupsDays[groupIndex].day.forEach((day) => {
        this.days.push(day);
        this.availAbleDays.push(day);
      });
      this.groupsDays.splice(groupIndex, 1);
      this.sortDays(this.days);
    } catch (e) {
      // todo send error to backend
    }
  }

  addTimeIndividualRow(groupIndex: number) {
    try {
      this.individualDays[groupIndex].times.push(this.createTimeCapacity());
    } catch (e) {
      // todo send error to backend
    }
  }

  removeTimeIndividualRow(groupIndex: number, timeIndex: number) {
    try {
      this.individualDays[groupIndex].times.splice(timeIndex, 1);
    } catch (e) {
      // todo send error to backend
    }
  }

  addTimeRow(groupIndex: number) {
    try {
      this.groupsDays[groupIndex].times.push(this.createTimeCapacity());
    } catch (e) {
      // todo send error to backend
    }
  }

  removeTimeRow(groupIndex: number, timeIndex: number) {
    try {
      this.groupsDays[groupIndex].times.splice(timeIndex, 1);
    } catch (e) {
      // todo send error to backend
    }
  }

  hasSelectedDay(day: string) {
    return this.availAbleDays.findIndex((element: string) => element === day) > -1;
  }

  createSelectedDay(): SelectedDay {
    return {
      day: [...this.availAbleDays],
      times: [this.createTimeCapacity()],
    };
  }

  createIndividualDays(dayIndex: number): SelectedDay {
    const day = this.days[dayIndex];
    return {
      day: [day],
      times: [this.createTimeCapacity()],
    };
  }

  createTimeCapacity(): TimeCapacity {
    return {
      start: '',
      end: '',
      maxCapacity: undefined,
      timeStartMenu: false,
      timeEndMenu: false,
    };
  }

  createInitialDays(): Array<Days> {
    return [
      Days.Sunday,
      Days.Monday,
      Days.Tuesday,
      Days.Wednesday,
      Days.Thursday,
      Days.Friday,
      Days.Saturday,
    ];
  }

  sortDays(days: Array<Days>) {
    return days.sort((a, b) => {
      const aVal = moment().day(a).day();
      const bVal = moment().day(b).day();
      if (aVal < bVal) {
        return -1;
      }

      if (aVal > bVal) {
        return 1;
      }

      return 0;
    });
  }

  saveTimeCapacities() {
    if (this.$refs && this.$refs.createTimeCapacityForm) {
      const { createTimeCapacityForm } = this.$refs as any;
      const isValid = createTimeCapacityForm.validate();
      if (isValid) {
        const availabilitySchedules = [] as Array<AvailabilitySchedule>;
        if (this.timeEntryMode === this.timeEntryModeGrouped) {
          this.groupsDays.forEach((selectedDay) => {
            const groupId = faker.datatype.uuid();
            selectedDay.times.forEach((times) => {
              availabilitySchedules.push({
                id: faker.datatype.uuid(),
                supplierId: companyModule.Company?.orgId || '',
                productId: productModule.Product?.id || '',
                groupId: groupId,
                org: profileModule.Profile?.org_code || '',
                name: this.timeCapacity.name,
                validFrom: this.timeCapacity.validStart,
                validUntil: this.timeCapacity.validEnd,
                startTime: times.start,
                endTime: times.end,
                maxCapacity:
                  this.timeCapacity.includeCapacity && times.maxCapacity
                    ? _.toNumber(times.maxCapacity)
                    : undefined,
                daysOfWeek: [...selectedDay.day],
                excludedDays: [],
                eventDuration: 0,
                eventPadding: 0,
              });
            });
          });
        } else if (this.timeEntryMode === this.timeEntryModeIndividual) {
          const groupId = faker.datatype.uuid();
          this.individualDays.forEach((selectedDay) => {
            if (selectedDay.times && selectedDay.times.length > 0) {
              selectedDay.times.forEach((times) => {
                availabilitySchedules.push({
                  id: faker.datatype.uuid(),
                  supplierId: companyModule.Company?.orgId || '',
                  productId: productModule.Product?.id || '',
                  groupId: groupId,
                  org: profileModule.Profile?.org_code || '',
                  name: this.timeCapacity.name,
                  validFrom: this.timeCapacity.validStart,
                  validUntil: this.timeCapacity.validEnd,
                  startTime: times.start,
                  endTime: times.end,
                  maxCapacity: times.maxCapacity
                    ? _.toNumber(times.maxCapacity)
                    : undefined,
                  daysOfWeek: [...selectedDay.day],
                  excludedDays: [],
                  eventDuration: 0,
                  eventPadding: 0,
                });
              });
            }
          });
        }
        httpModule
          .createAvailabilitySchedules(availabilitySchedules)
          .then(() => {
            this.returnToProductPage();
          })
          .finally(() => {});
      }
    }
  }

  discardWarning() {
    this.unsavedChanges = true;
  }

  returnToProductPage() {
    this.unsavedChanges = false;
    const location = productModule.Product?.id
      ? { name: this.RouterProductDetails, params: { id: productModule.Product.id } }
      : { name: this.RouterProductDetails };
    this.$router.push(location).catch(() => {});
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get RouterProductDetails() {
    if (productModule.Product?.id) {
      return constants.routes.PRODUCT_DETAILS;
    }
    return constants.routes.PRODUCT_LIST;
  }

  get RouterProductDetailsParams() {
    if (
      productModule.Product &&
      productModule.Product.id &&
      productModule.Product.id.length > 0
    ) {
      return { id: productModule.Product.id };
    }
    return {};
  }

  get Product() {
    return productModule.Product;
  }

  get Loading() {
    return productModule.Loading;
  }
}
