import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../../index';
import { Ticket } from '../../../api/backplane/BackplaneModels';
import {
  MakeValidationErrHandlers,
  SearchTicketsErrHandlers,
} from '@/api/wps/ErrHandlers';
import { DateRange, ModuleWithDates, ModuleWithLoading } from './baseModules';
import backplaneClient from '@/api/backplane/BackplaneClient';

export interface TicketOrdered extends Ticket {
  i: number;
}

@Module({
  dynamic: true,
  store,
  name: 'report-arrivals',
  namespaced: true,
})
// TODO: again shitty vuex-module-decorators, it does not handle inheritance
//class ArrivalsModule extends ModuleWithDates(ModuleWithLoading(VuexModule)) {
class ArrivalsModule extends VuexModule {
  /* DATA */

  public nested = new (ModuleWithDates(ModuleWithLoading(Object)))();
  private tickets: TicketOrdered[] = [];

  /* GETTERS */

  get Tickets(): TicketOrdered[] {
    return this.tickets;
  }

  /* MUTATIONS */

  @Mutation
  private appendTickets(tickets: Ticket[]) {
    this.tickets.push(...tickets.map((ticket, i) => ({ ...ticket, i })));
  }
  @Mutation
  private dropTicket(i: number) {
    const arr = this.tickets;
    arr.splice(i, 1);
    for (let j = i; j < arr.length; ++j) {
      arr[j].i = j;
    }
  }
  @Mutation
  private resetTickets() {
    this.tickets = [];
  }

  @Mutation
  private setFromDate(fromDate: moment.Moment | null) {
    this.nested.setFromDate(fromDate);
  }
  @Mutation
  private setUntilDate(untilDate: moment.Moment | null) {
    this.nested.setUntilDate(untilDate);
  }

  @Mutation
  private startLoading() {
    this.nested.startLoading();
  }
  @Mutation
  private finishLoading() {
    this.nested.finishLoading();
  }

  @Mutation
  private setClearState() {
    this.tickets = [];
    this.nested.clearLoading();
    this.nested.setFromDate(null);
    this.nested.setUntilDate(null);
  }

  /* ACTIONS */

  @Action
  async changeDates(payload: { range: DateRange; errs?: SearchTicketsErrHandlers }) {
    const { range, errs } = payload;
    let smthChanged = false;
    const newDates = this.nested.datesToChange(range);

    if (newDates.fromDate !== undefined) {
      smthChanged = true;
      this.setFromDate(newDates.fromDate);
    }
    if (newDates.untilDate !== undefined) {
      smthChanged = true;
      this.setUntilDate(newDates.untilDate);
    }

    if (smthChanged) {
      await this.searchTickets(errs);
    }
  }

  @Action
  async searchTickets(errs?: SearchTicketsErrHandlers) {
    const fromDate = this.nested.FromDate ? this.nested.FromDate.toISOString() : '';
    const untilDate = this.nested.UntilDate ? this.nested.UntilDate.toISOString() : '';

    let num = 0;
    let count = Infinity;
    const tickets: Ticket[] = [];

    for (let start = 0; start < count; start += num) {
      // 'this' for do is changed to use mutations
      await this.nested.do.call(this, async () => {
        const query = {
          travel_date_from: fromDate,
          travel_date_until: untilDate,
          status: 1 /* OPEN */,
          limit: 200,
          start,
          is_redeemable: true,
        };

        const data = await backplaneClient.searchTickets(query, errs).toPromise();

        count = data.count;
        if (data.tickets) {
          num = data.tickets.length;
          tickets.push(...data.tickets);
        }
      });
    }

    this.resetTickets();
    this.appendTickets(tickets);
  }

  @Action
  async redeem(payload: { i: number; errs?: MakeValidationErrHandlers }) {
    const { i, errs } = payload;
    await this.nested.do.call(this, async () => {
      await backplaneClient.makeValidation({ ticket: this.tickets[i] }, errs).toPromise();
    });
    this.dropTicket(i);
  }

  @Action({ root: true })
  private clearState() {
    this.setClearState();
  }
}

export function getArrivalsModule() {
  return getModule(ArrivalsModule, store);
}
