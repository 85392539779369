var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.navigateBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(_vm._s(_vm.backButtonText)+" ")],1)],1),_c('v-row',[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.PageTitle))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v(_vm._s('Name'))]),_c('p',[_c('label',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.ChannelName)))])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Products")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.Loading,"headers":_vm.productsHeaders,"items":_vm.ConnectedChannels,"items-per-page":10,"sort-by":"name","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
        }},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" ")]}},{key:"item.supplier",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.supplier.name)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }