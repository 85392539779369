










































































































































import { Component, Vue } from 'vue-property-decorator';
import { getTicketStatusByValue } from '@/utils/web-redemption';
import moment from 'moment';
import {
  DatePickerOptionsDate,
  DatePickerOptionsDateRange,
  DateSearchTypeList,
} from '@/utils/componentHelpers';
import {
  getArrivalsModule,
  TicketOrdered,
} from '@/store/modules/.deprecated/moduleReportArrivals';
import { tablePaginationOptions } from '@/utils/helpers';

@Component
export default class UpcomingArrivalsReport extends Vue {
  /* DATA */

  // modules
  private arrivalModule = getArrivalsModule();

  // dialog appearance
  private dateFromModal = false;
  private dateUntilModal = false;

  // date picker values - OLD todo
  private fromDate = '';
  private untilDate = '';

  // date picker values - NEW todo
  private dateRange = [];
  private datePick = '';
  private dateRangeType = 'dateRange';

  // other
  private search = '';

  private reportHeaders: Array<Record<string, any>> = [
    { text: 'Reseller', align: 'left', sortable: true, value: 'reseller_name' },
    {
      text: 'Travel Date',
      align: 'left',
      sortable: true,
      value: 'travel_date',
      sort: (a: string, b: string) => (moment(a).isBefore(moment(b)) ? -1 : 1),
    },
    { text: 'Name', align: 'left', sortable: false, value: 'name' },
    { text: 'Status', align: 'left', sortable: false, value: 'status' },
    { text: 'Booking ID', align: 'left', sortable: false, value: 'booking_id' },
  ];

  private options = DatePickerOptionsDateRange(this.$i18n);

  private optionSingleDay = DatePickerOptionsDate(this.$i18n);

  private dateSearchTypeList = DateSearchTypeList(this.$i18n);

  /* COMPUTED */

  private get Tickets(): any[] {
    return this.arrivalModule.Tickets;
  }

  private get FormattedTickets(): any[] {
    const tickets = [] as Array<any>;
    this.arrivalModule.Tickets.map((t: TicketOrdered) => {
      tickets.push({
        id: t.id,
        reseller_name: t.reseller_name,
        travel_date: t.travel_date,
        name: this.travellerName(t),
        status: this.ticketStatus(t),
        booking_id: t.booking_id,
      });
    });
    return tickets;
  }

  private get ItemsPerPage() {
    return 10;
  }

  private get Loading(): boolean {
    return this.arrivalModule.nested.Loading;
  }

  get paginationOptions() {
    return tablePaginationOptions(this.Tickets);
  }

  /* HOOKS */

  private async created() {
    if (this.Tickets.length === 0) {
      try {
        await this.arrivalModule.changeDates({
          range: {
            fromDate: moment(),
            untilDate: moment().add(10, 'days'),
          },
        });
      } catch {
        // todo send alert to backend
      }
    }
  }

  /* METHODS */

  // event handlers

  private async changeDates(which: string) {
    try {
      this.dateFromModal = false;
      this.dateUntilModal = false;

      const dateRange: {
        fromDate?: moment.Moment | null;
        untilDate?: moment.Moment | null;
      } = {};

      if (which === 'from') {
        dateRange.fromDate = this.fromDate ? moment(this.fromDate) : null;
        this.fromDate = '';
      } else if (which === 'until') {
        dateRange.untilDate = this.untilDate ? moment(this.untilDate) : null;
        this.untilDate = '';
        // NEW - start
      } else if (which === 'range') {
        if (!this.dateRange) return;

        dateRange.fromDate = this.dateRange[0] ? moment(this.dateRange[0]) : null;
        dateRange.untilDate = this.dateRange[1] ? moment(this.dateRange[1]) : null;
      } else if (which === 'dateSelect') {
        if (this.dateRangeType === 'fromDate') {
          dateRange.fromDate = this.datePick ? moment(this.datePick) : null;
          dateRange.untilDate = null;
        } else if (this.dateRangeType === 'untilDate') {
          dateRange.untilDate = this.datePick ? moment(this.datePick) : null;
          dateRange.fromDate = null;
        }
        // NEW - end
      }

      await this.arrivalModule.changeDates({ range: dateRange });
    } catch {
      // send alert to backend
    }
  }

  private clearValues() {
    this.dateRange = [];
    this.datePick = '';
  }

  private refresh() {
    this.arrivalModule.searchTickets().catch(() => {
      // todo send alert to backend
    });
  }

  // template helpers

  private ticketStatus(ticket: any) {
    try {
      return getTicketStatusByValue(ticket.status);
    } catch {
      return '';
    }
  }

  private travellerName(item: any) {
    const nameBuilder = (str: string) => (str ? `${str} ` : '');
    try {
      return (
        nameBuilder(item.travelers[0].name.title) +
        nameBuilder(item.travelers[0].name.given) +
        nameBuilder(item.travelers[0].name.family)
      );
    } catch {
      return '';
    }
  }

  private copy(id: string) {
    navigator.clipboard.writeText(id);
  }
}
