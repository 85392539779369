
























































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { channelModule } from '@/store/modules/moduleChannel';

@Component
export default class ChannelHome extends Vue {
  private activeTab = '/channel/listings';
  private rateCardRouteName = constants.routes.CHANNEL_RATE_CARDS;
  private routeAddRateCard = constants.routes.CHANNEL_RATE_CARD_CREATE;
  private routeAvailableChannels = constants.routes.CHANNEL_AVAILABLE_LIST;
  private selectedTabId = 1;

  private tabs: Array<any> = [
    { id: 1, name: constants.titles.CONNECTED_CHANNELS, route: '/channels' },
    {
      id: 2,
      name: constants.titles.AVAILABLE_CHANNELS,
      route: '/channels/available-channels',
    },
    { id: 3, name: constants.titles.RATE_CARDS, route: '/channels/rate-cards' },
  ];

  get ChannelAccess() {
    return channelModule.ChannelAccess;
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get SearchText() {
    return channelModule.SearchText;
  }

  set SearchText(search: string) {
    channelModule.setSearchText(search);
  }

  get CurrentRoute() {
    return this.$route?.name;
  }

  private onTabChange(id: number) {
    this.selectedTabId = id;
  }
}
