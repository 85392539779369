





















































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import SaveState from '@/components/common/savestate/SaveState.vue';
import RateCardCreateDetails from '@/components/rateCard/create/RateCardCreateDetails.vue';
import RateCardCreatePriceAdjustments from '@/components/rateCard/create/RateCartCreatePriceAdjustments.vue';
import RateCardCreateConnectChannels from '@/components/rateCard/create/RateCardCreateConnectChannels.vue';
import { channelModule } from '@/store/modules/moduleChannel';
import RateCardCreateValidation from '@/components/rateCard/create/RateCardCreateValidation.vue';
import RateCardCreateSelectOffers from '@/components/rateCard/create/RateCardCreateSelectOffers.vue';
import { companyModule } from '@/store/modules/moduleCompany';
import { httpModule } from '@/store/modules/moduleHttp';
@Component({
  components: {
    RateCardCreateSelectOffers,
    SaveState,
    RateCardCreateDetails,
    RateCardCreateValidation,
    RateCardCreatePriceAdjustments,
    RateCardCreateConnectChannels,
    RdmCollapsableControlCard,
    RdmCollapsableCard,
    RdmReturnToBtn,
  },
})
export default class RateCardCreate extends Vue {
  private routeRateCards = constants.routes.CHANNEL_RATE_CARDS;
  private orgTypeRC = constants.ORG_TYPE_RC;
  private orgTypeTS = constants.ORG_TYPE_TS;

  async mounted() {
    await httpModule.getCompanyRates();
    if (this.OrgType === this.orgTypeTS) {
      channelModule.setFormDetailsState(true);
    }
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get OrgType() {
    return companyModule.Company.orgType || this.orgTypeRC;
  }

  get RateCard() {
    return channelModule.RateCard;
  }

  get IsAdjustPrice() {
    return channelModule.AdjustPriceState;
  }

  get Loading() {
    return channelModule.Loading;
  }
}
