import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Channel } from '@/models/ChannelModels';
import {
  RateCard,
  CompanyRate,
  ChannelBinding,
  EffectiveDateRange,
  ChannelSpr,
  ChannelBindingRQ,
} from '@/models/ModelsOppInventory';
import { productModule } from '@/store/modules/moduleProduct';
import { compareDateStrings } from '@/utils/helpers';
import _ from 'lodash';
import channelClient from '@/api/channel/ChannelClient';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import { Product } from '@/models';
import moment from 'moment/moment';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-channel',
  namespaced: true,
})
class ModuleChannel extends VuexModule {
  /* Data */
  private channelAccess = true;
  private channelConnected = false;
  private loading = false;
  private available = [] as Array<Channel>;
  private connected = [] as Array<ChannelBinding>;
  private rateCards = [] as Array<RateCard>;
  private rateCard = {} as RateCard;
  private channel = {} as any;
  private editOffersOnCard = false;
  private selectedChannel = {} as ChannelBinding;
  private offerDateRange = {} as EffectiveDateRange;
  private validationInterval = null as any;
  private validationError = false;
  private validationErrorDetails = {} as any;
  private validationProgress = 0 as number;
  private validationState = false;
  private selectOffersState = true;
  private searchText = '';
  private rateSearchText = '';
  private adjustPrice = false;
  private formDetailsState = false;
  private formPriceAdjustmentsState = false;
  private formConnectChannelState = false;
  private createRateCardFormState = true;
  private createRateCardPriceState = false;
  private createRateCardChannels = false;
  private createRateCardDetailsForm = {} as any;
  private createRateCardAdjustPriceForm = {} as any;
  private createRateCardConnectChannelForm = {} as any;
  private companyRates = [] as Array<CompanyRate>;
  private createCompanyRates = [] as Array<ChannelSpr>;
  private rateCardChannels = [] as any;
  private rateCardSpr = [] as Array<ChannelSpr>;

  /* Getters */

  get ChannelToBind() {
    return this.channel;
  }

  get ChannelAccess() {
    return this.channelAccess;
  }

  get RateCardSpr() {
    return this.rateCardSpr;
  }

  get RateCardChannels() {
    return this.rateCardChannels;
  }
  get ChannelConnected(): boolean {
    return this.channelConnected;
  }

  get Loading(): boolean {
    return this.loading;
  }

  get CompanyRates() {
    return this.companyRates;
  }

  get CreateCompanyRates() {
    return this.createCompanyRates;
  }

  get SelectOffersState() {
    return this.selectOffersState;
  }

  get ValidationErrorDetails() {
    return this.validationErrorDetails;
  }

  get ValidationInterval() {
    return this.validationInterval;
  }

  get ValidationState(): boolean {
    return this.validationState;
  }

  get ValidationError(): boolean {
    return this.validationError;
  }

  get ValidationProgress(): number {
    return this.validationProgress;
  }

  get EditOffersState(): boolean {
    return this.editOffersOnCard;
  }

  get CreateRateCardDetailsForm(): any {
    return this.createRateCardDetailsForm;
  }

  get CreateRateCardAdjustPriceForm(): any {
    return this.createRateCardAdjustPriceForm;
  }

  get CreateRateCardConnectChannelForm(): any {
    return this.createRateCardConnectChannelForm;
  }

  get CreateRateCardFormState(): boolean {
    return this.createRateCardFormState;
  }

  get AdjustPriceState(): boolean {
    return this.adjustPrice;
  }

  get FormDetailsState(): boolean {
    return this.formDetailsState;
  }

  get FormPriceAdjustmentsState(): boolean {
    return this.formPriceAdjustmentsState;
  }

  get FromConnectChannelState(): boolean {
    return this.formConnectChannelState;
  }

  get AvailableChannels() {
    return this.available;
  }
  get ConnectedChannels() {
    return this.connected;
  }

  get RateCards() {
    return this.rateCards;
  }

  get RateCard() {
    return this.rateCard;
  }

  get SearchText() {
    return this.searchText;
  }

  get RateSearchText() {
    return this.rateSearchText;
  }

  get Channel() {
    return this.selectedChannel;
  }

  get Recent5Products() {
    const dateSortedProducts = productModule.Products.sort((a, b) => {
      if (compareDateStrings(a.meta?.updatedAt || '', b.meta?.updatedAt || '')) {
        return -1;
      }

      if (compareDateStrings(b.meta?.updatedAt || '', a.meta?.updatedAt || '')) {
        return 1;
      }

      return 0;
    });
    const p = _.take<Product>(dateSortedProducts, 5);
    return p.sort((a, b) => {
      const lowerA = a.internalName?.toLowerCase() || '';
      const lowerB = b.internalName?.toLowerCase() || '';

      if (lowerA < lowerB) {
        return -1;
      }

      if (lowerA > lowerB) {
        return 1;
      }

      return 0;
    });
  }

  get OfferDateRange() {
    return this.offerDateRange;
  }

  /* Setters */
  @Mutation
  setChannelToBind(channel: any) {
    this.channel = channel;
  }
  @Mutation
  setChannelAccess(state: boolean) {
    this.channelAccess = state;
  }

  @Mutation
  setRateCardSpr(spr: Array<ChannelSpr>) {
    this.rateCardSpr = spr;
  }

  @Mutation
  setChannelConnected(state: boolean) {
    this.channelConnected = state;
  }

  @Mutation
  setRateCardChannels(channels: any) {
    this.rateCardChannels = channels;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setCompanyRates(rates: Array<CompanyRate>) {
    this.companyRates = rates;
  }

  @Mutation
  setCreateCompanyRates(rates: Array<ChannelSpr>) {
    this.createCompanyRates = rates;
  }

  @Mutation
  setSelectOffersState(state: boolean) {
    this.selectOffersState = state;
  }

  @Mutation
  setValidationErrorDetails(details: any) {
    this.validationErrorDetails = details;
  }

  @Mutation
  setValidationInterval(interval: any) {
    this.validationInterval = interval;
  }

  @Mutation
  setValidationState(state: boolean) {
    this.validationState = state;
  }

  @Mutation
  setValidationError(status: boolean) {
    this.validationError = status;
  }

  @Mutation
  setValidationProgress(progress: number) {
    this.validationProgress = progress;
  }

  @Mutation
  setOfferDateRange(effectiveDateRange: EffectiveDateRange) {
    this.offerDateRange = effectiveDateRange;
  }

  @Mutation
  setEditOffersState() {
    this.editOffersOnCard = !this.editOffersOnCard;
  }

  @Mutation
  setCreateRateCardDetailsForm(form: any) {
    this.createRateCardDetailsForm = form;
  }

  @Mutation
  setCreateRateCardAdjustPriceForm(form: any) {
    this.createRateCardAdjustPriceForm = form;
  }

  @Mutation
  setCreateRateCardConnectChannelForm(form: any) {
    this.createRateCardConnectChannelForm = form;
  }

  @Mutation
  setAvailableChannels(channels: any[]) {
    this.available = channels;
  }

  @Mutation
  setRateCards(rateCards: Array<RateCard>) {
    rateCards.sort((a, b) => a.name.localeCompare(b.name));
    this.rateCards = rateCards;
  }

  @Mutation
  setRateCard(rateCard: RateCard) {
    this.rateCard = rateCard;
  }

  @Mutation
  setCreateRateCardFormState(state: boolean) {
    this.createRateCardFormState = state;
  }

  @Mutation
  setFormDetailsState(state: boolean) {
    this.formDetailsState = state;
  }

  @Mutation
  setFormPriceAdjustmentsState(state: boolean) {
    this.formPriceAdjustmentsState = state;
  }

  @Mutation
  setFormConnectChanelState(state: boolean) {
    this.formConnectChannelState = state;
  }

  @Mutation
  setAdjustPriceState(state: boolean) {
    return (this.adjustPrice = state);
  }

  @Mutation
  setSearchText(search: string) {
    this.searchText = search;
  }

  @Mutation
  setRateSearchText(search: string) {
    this.rateSearchText = search;
  }

  @Mutation
  setChannels(channels: Array<ChannelBinding>) {
    this.connected = channels;
  }

  @Mutation
  setSelectedChannel(channel: ChannelBinding) {
    this.selectedChannel = channel;
  }

  @Mutation
  setConnectedChannels(channels: Array<ChannelBinding>) {
    this.connected = channels;
  }

  @Action
  async getAvailableChannels() {
    try {
      this.setLoading(true);
      const res = await channelClient.getAvailableChannels().toPromise();
      this.setAvailableChannels(res.channels);
    } catch {
      this.setAvailableChannels([]);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getConnectedChannels() {
    this.setLoading(true);
    if (productModule.Products.length === 0) await httpModule.loadProducts();
    const promises = productModule.Products.map((product) => {
      //TODO: only for dev and sandbox. for testing purposes. Remove when we will have valid account for channels
      if (process.env.VUE_APP_ENV !== 'production') {
        return channelClient
          .getConnectedChannels('f0cccf98-a4d9-4b40-a109-cc335075e6ee', '8dc6f074-26e2-4606-bb2e-1bd78c851df9')
          .toPromise();
      } else {
        return channelClient.getConnectedChannels(companyModule.Company.orgId, product.id).toPromise();
      }
    });
    Promise.all(promises)
      .then((responses) => {
        this.setConnectedChannels(responses);
        this.setLoading(false);
      })
      .catch(() => {
        this.setConnectedChannels([]);
        this.setLoading(false);
      });
  }

  @Action
  addCreateRateCard(companyRate: ChannelSpr) {
    this.createCompanyRates.push(companyRate);
  }

  @Action
  addRateCard(rateCard: RateCard) {
    this.rateCards.push(rateCard);
  }

  @Action
  initRateCards(rateCards: Array<RateCard>) {
    this.setRateCards(rateCards);
  }

  @Action
  updateRateCard(rateCard: RateCard) {
    const index = this.rateCards.findIndex((r) => r.id === rateCard.id);
    if (index > -1) {
      this.rateCards[index] = rateCard;
    } else {
      this.rateCards.push(rateCard);
    }
  }

  /**
   * updateChannelBinding
   * - updates the channel binding in core
   * - stores the channel on the rate card
   * @param request
   */
  @Action
  async updateChannelBinding(request: ChannelBindingRQ) {
    // update the channel binding in core
    let validFrom = moment().format();
    let validTo = moment().add(100, 'years').format();
    if (request.validFrom) {
      validFrom = request.validFrom;
    }
    if (request.validUntil) {
      validTo = request.validUntil;
    }
    await httpModule.updateChannelBindings({
      supplierId: request.supplierID,
      productId: request.productID,
      channelId: request.channelID,
      payload: {
        priceAt: 'PRICE_AT_SALE',
        priceTags: [],
        rateIds: request.rateIDs,
        valid: {
          from: validFrom,
          until: validTo,
        },
      },
    });

    // update the rate card with the connected channel
    await httpModule.storeRateCardChannel({
      rateCardId: request.rateCardID,
      channelId: request.channelID,
      payload: {
        id: request.channelID,
        name: request.channelName,
      },
    });
  }

  /**
   * createChannelBinding
   * - creates the channel binding in core
   * - stores the channel on the rate card
   * @param request
   */
  @Action
  async createChannelBinding(request: ChannelBindingRQ) {
    // create the channel binding in core
    let validFrom = moment().format();
    let validTo = moment().add(100, 'years').format();
    if (request.validFrom) {
      validFrom = request.validFrom;
    }
    if (request.validUntil) {
      validTo = request.validUntil;
    }
    await httpModule.createChannelBinding({
      supplierId: request.supplierID,
      productId: request.productID,
      channelId: request.channelID,
      payload: {
        priceAt: 'PRICE_AT_SALE',
        priceTags: [],
        rateIds: request.rateIDs,
        valid: {
          from: validFrom,
          until: validTo,
        },
      },
    });

    // update the rate card with the connected channel
    await httpModule.storeRateCardChannel({
      rateCardId: request.rateCardID,
      channelId: request.channelID,
      payload: {
        id: request.channelID,
        name: request.channelName,
      },
    });
  }

  /**
   * deleteChannelBinding
   * - deletes the channel binding in core
   * - deletes the channel from the rate card
   * @param request
   */
  @Action
  async deleteChannelBinding(request: ChannelBindingRQ) {
    await httpModule.deleteChannelBinding({
      supplierId: request.supplierID,
      productId: request.productID,
      channelId: request.channelID,
    });
    await httpModule.deleteRateCardChannel({
      rateCardId: request.rateCardID,
      channelId: request.channelID,
    });
  }

  @Action
  selectChannel(channel: ChannelBinding) {
    this.setSelectedChannel(channel);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setAvailableChannels([] as Array<Channel>);
    this.setConnectedChannels([] as Array<ChannelBinding>);
    this.setChannels([] as Array<ChannelBinding>);
    this.setSelectedChannel({} as ChannelBinding);
    this.setRateCards([] as Array<RateCard>);
    this.setCreateCompanyRates([] as Array<ChannelSpr>);
    this.setOfferDateRange({} as EffectiveDateRange);
    this.setSearchText('');
  }
}

export const channelModule = getModule(ModuleChannel, store);
