




















































































































































import { Component, Vue } from 'vue-property-decorator';
import { pricingModule } from '@/store/modules/modulePricing';
import { constants } from '@/utils/constants';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { Option, Product, ProductPolicy } from '@/models';

@Component
export default class OfferCreatePolicy extends Vue {
  private routeToProductDetails = constants.routes.PRODUCT_DETAILS;
  private routeToOptionDetails = constants.routes.OPTION_DETAILS;

  validateSelection(selection: string) {
    pricingModule.chooseSelectedOfferPolicy(
      this.Policies.find((i) => i.id === selection),
    );
    if (this.$refs && this.$refs.offerCreatePolicy) {
      const { offerCreatePolicy } = this.$refs as any;
      offerCreatePolicy.validate();
      if (selection && selection.length > 0) {
        pricingModule.setCreateForm2Valid(true);
      } else {
        pricingModule.setCreateForm2Valid(false);
      }
    }
  }

  get Step2Done() {
    return pricingModule.CreateStep2Done;
  }

  get Form2Valid() {
    return pricingModule.CreateForm2Valid;
  }

  set Form2Valid(valid: boolean) {
    pricingModule.setCreateForm2Valid(valid);
  }

  get SelectedProductPolicyId() {
    return pricingModule.SelectedProductPolicyId;
  }

  set SelectedProductPolicyId(policyId: string) {
    pricingModule.setSelectedProductPolicyId(policyId);
  }

  get Policies() {
    return pricingModule.Policies;
  }

  get SelectedProductPolicy() {
    const policy = this.Policies.find((p) => p.id === this.SelectedProductPolicyId);
    if (policy) {
      return policy;
    }
    return {} as ProductPolicy;
  }

  get SelectedProductName() {
    if (this.SelectedProduct) {
      const product = this.Products.find(
        (product) => product.id === this.SelectedOfferProductId,
      );
      if (product) {
        return product.internalName || '';
      }
    }
    return '';
  }

  get SelectedOptionName() {
    if (this.SelectedOption) {
      const option = this.Options.find((o) => o.id === this.SelectedOfferOptionId);
      if (option) {
        return option.internalName || '';
      }
    }
    return '';
  }

  get Products() {
    return productModule.Products.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get Options() {
    return optionModule.Options.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get SelectedProduct() {
    return pricingModule.SelectedOfferProduct;
  }

  set SelectedProduct(product: Product) {
    pricingModule.setSelectedProduct(product);
  }

  get SelectedOption() {
    return pricingModule.SelectedOfferOption;
  }

  set SelectedOption(option: Option) {
    pricingModule.setSelectedOption(option);
  }

  get SelectedOfferProductId() {
    return pricingModule.SelectedOfferProductId;
  }

  set SelectedOfferProductId(id: string) {
    pricingModule.setSelectedOfferProductId(id);
  }

  get SelectedOfferOptionId() {
    return pricingModule.SelectedOfferOptionId;
  }

  set SelectedOfferOptionId(id: string) {
    pricingModule.setSelectedOfferOptionId(id);
  }
}
