



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import {
  descriptionLimitRules,
  optionalNumberMinimumRules,
  requiredFieldRules,
  requiredMultiSelectFieldRules,
  greaterThan1Rules,
} from '@/utils/validation-rules';
import { optionModule } from '@/store/modules/moduleOption';
import faker from 'faker';
import { httpModule } from '@/store/modules/moduleHttp';
import {
  BookingCutoffOptions,
  CancellationPolicyOptions,
  CapacityPool,
  DisplayUnitPrice,
  InventoryType,
  Option,
  OptionCapacityPool,
  OptionUnitPrice,
  OptionUnitPriceAmount,
  ProductPolicy,
  PoolStructure,
  RefundablePolicyOptions,
  TripRoute,
} from '@/models';
import { companyModule } from '@/store/modules/moduleCompany';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import _, { isArray } from 'lodash';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { AvailabilityType, CapacityType } from '@/api/inventory/InventoryModels';
import { handleKeyDownOnlyNumeric, dollarsToCents, zeroPrice } from '@/utils/helpers';
import OptionCreatePricing from '@/components/product/option/create/OptionCreatePricing.vue';
import OptionCreateUnitPricing from '@/components/product/option/create/OptionCreateUnitPricing.vue';
import OptionCreateSchedule from '@/components/product/option/create/OptionCreateSchedule.vue';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { TimeEntryMode } from '@/api/inventory/InventoryModels';

@Component({
  components: {
    OptionCreateUnitPricing,
    OptionCreatePricing,
    RdmCollapsableControlCard,
    RdmCollapsableCard,
    RdmReturnToBtn,
    OptionCreateSchedule,
  },
})
export default class OptionCreate extends Vue {
  private dollarsToCents = dollarsToCents;
  private isDone = false;
  private routeOptionList = constants.routes.OPTION_LIST;
  private routeToCreateOption = constants.routes.OPTION_LIST;
  private requiredFieldRules = requiredFieldRules;
  private requiredMultipleSelectRules = requiredMultiSelectFieldRules;
  private greaterThan1Rules = greaterThan1Rules;
  private descriptionLimitRules = descriptionLimitRules;
  private handleKeyDown = handleKeyDownOnlyNumeric;
  private scheduleId = '';
  private radioYes = 'yes';
  private radioNo = 'no';
  private descriptionPlaceholder = 'Add description here';
  private descriptionLimit = 1000;
  private currency = 'USD';
  private createOptionDetailForm = false;
  private createOptionLocationForm = false;
  private createOptionPolicyForm = false;
  private createOptionScheduleForm = false;
  private createOptionPricingForm = false;
  private step = 1;
  private inventoryType = null;
  private capacityType = CapacityType;
  private selectedCapacityType = null;
  private includedInPrice = null;
  private shareLocation = this.radioNo;
  private sharePricing = this.radioNo;
  private sharePolicy = this.radioNo;
  private shareSchedule = this.radioNo;
  private shareInventory = null;
  private createLocation = false;
  private createPricing = false;
  private createPolicy = false;
  private createSchedule = false;
  private openOptionDetail = false;
  private openOptionLocation = false;
  private openOptionPolicy = false;
  private openOptionPricing = false;
  private openOptionSchedule = false;
  private isCostAssociated = null;
  private selectedTravelerTypes = [] as Array<string>;
  private selectedSchedule = null;
  private cancellationType = null as CancellationPolicyOptions | null;
  private baseCancellationType = 0 as CancellationPolicyOptions;
  private refundable = null as RefundablePolicyOptions | null;
  private baseRefundable = 0 as RefundablePolicyOptions;
  private bookingCutoff = null as BookingCutoffOptions | null;
  private baseBookingCutoff = 0 as BookingCutoffOptions;
  private capacityPool = {} as CapacityPool;
  private byUnitPool = {} as Map<string, string>;
  private byUnitPoolArr = [] as Array<PoolStructure>;
  private modalIsOpen = false;

  private optionalNumberMin = optionalNumberMinimumRules;
  private selectedTravelers = [] as Array<string>;
  private displayUnits = [] as Array<DisplayUnitPrice>;
  private cutoffForm = false;
  private saving = false;
  private cancellationCutoff = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private bookingCutoffTimes = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private baseCancellationCutoff = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private baseBookingCutoffTimes = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private option = {} as Option;
  public detailsForm = {
    travelers: [],
    cancellable: true,
    refundable: true,
    address: {},
  } as any;
  private tripRoute = {
    departure: '',
    arrival: '',
    stops: [] as Array<string>,
  } as TripRoute;

  mounted() {
    this.openOptionDetail = true;
    this.$root.$on(constants.events.CREATE_OPTION, () => {
      this.validateForm();
    });
    this.toggleAddPricingSet();
    const travelers = optionModule.SelectedUnitIds || ([] as Array<string>);
    this.handleChangeTravelerType(travelers);
  }

  openModal() {
    this.modalIsOpen = true;
  }

  closeModal() {
    this.modalIsOpen = false;
  }

  toggleAllTravelerTypes() {
    this.$nextTick(() => {
      if (this.detailsForm.travelers.length === this.TravelerTypes.length) {
        this.detailsForm.travelers = [];
      } else {
        this.detailsForm.travelers = this.TravelerTypes;
      }
      this.populateCapacityUnit();
    });
  }

  destroy() {
    this.$root.$off(constants.events.CREATE_OPTION);
  }

  discard() {
    this.$router.push({ name: constants.routes.OPTION_LIST }).catch();
  }

  nextStepValidate1(done: boolean) {
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (isValid) {
        this.configOption();
        if (done) {
          this.addOptionDetails();
        } else {
          this.step1NextStage();
        }
      }
    }
  }

  nextStepValidate2(done: boolean) {
    if (this.$refs?.locationForm) {
      const form: any = this.$refs.locationForm;
      const isValid = form.validate();
      if (isValid) {
        this.configOption();
        if (done) {
          this.addOptionDetails();
        } else {
          this.step2NextStage();
        }
      }
    }
  }

  nextStepValidate3(done: boolean) {
    if (this.$refs?.policyForm) {
      const form: any = this.$refs.policyForm;
      const isValid = form.validate();
      if (isValid) {
        this.configOption();
        if (done) {
          this.addOptionDetails();
        } else {
          this.step3NextStage();
        }
      }
    }
  }

  nextStepValidate4(done: boolean) {
    if (this.$refs?.pricingForm) {
      const form: any = this.$refs.pricingForm;
      const isValid = form.validate();
      this.isDone = done;
      const zeroPrices = this.displayUnits?.filter((item: any) => zeroPrice(item));
      if (isValid && this.HasValidUnits && zeroPrices.length === 0) {
        this.configOption();
        if (done) {
          this.addOptionDetails();
        } else {
          this.step4NextStage();
        }
      } else {
        this.openModal();
      }
    }
  }

  confirmPriceSubmit() {
    if (this.isDone) {
      this.addOptionDetails();
    } else {
      this.step4NextStage();
    }
  }

  step1NextStage() {
    if (this.shareLocation === this.radioYes) {
      this.createLocation = true;
      this.step = 2;
      this.openLocationSection();
      return;
    }

    if (this.sharePolicy === this.radioYes) {
      this.createPolicy = true;
      this.step = 3;
      this.openPolicySection();
      return;
    }
    if (this.sharePricing === this.radioYes) {
      this.createPricing = true;
      this.step = 4;
      this.openPricingSection();
      return;
    }
    if (this.shareSchedule === this.radioYes) {
      this.createSchedule = true;
      this.step = 5;
      this.openScheduleSection();
      return;
    }
    // this.openSection();
    // this.navigateOptionsList();
    this.finish();
  }

  step2NextStage() {
    if (this.sharePolicy === this.radioYes) {
      this.createPolicy = true;
      this.step = 3;
      this.openPolicySection();
      return;
    }
    if (this.sharePricing === this.radioYes) {
      this.createPricing = true;
      this.step = 4;
      this.openPricingSection();
      return;
    }
    if (this.shareSchedule === this.radioYes) {
      this.createSchedule = true;
      this.step = 5;
      this.openScheduleSection();
      return;
    }
    this.finish();
    // this.navigateOptionsList();
  }

  step3NextStage() {
    if (this.sharePricing === this.radioYes) {
      this.createPricing = true;
      this.step = 4;
      this.openPricingSection();
      return;
    }
    if (this.shareSchedule === this.radioYes) {
      this.createSchedule = true;
      this.step = 5;
      this.openScheduleSection();
      return;
    }
    this.finish();
    // this.navigateOptionsList();
  }

  step4NextStage() {
    if (this.shareSchedule === this.radioYes) {
      this.createSchedule = true;
      this.step = 5;
      this.openScheduleSection();
      return;
    }
    this.finish();
    // this.navigateOptionsList();
  }

  openDetailSection() {
    this.openOptionDetail = true;
    this.openOptionLocation = false;
    this.openOptionPolicy = false;
    optionModule.setOpenCreatePricing(false);
    this.openOptionSchedule = false;
  }

  openLocationSection() {
    this.openOptionDetail = false;
    this.openOptionLocation = true;
    this.openOptionPolicy = false;
    optionModule.setOpenCreatePricing(false);
    this.openOptionSchedule = false;
  }

  openPolicySection() {
    this.openOptionDetail = false;
    this.openOptionLocation = false;
    this.openOptionPolicy = true;
    optionModule.setOpenCreatePricing(false);
    this.openOptionSchedule = false;
  }

  openPricingSection() {
    this.openOptionPricing = true;
    optionModule.setOpenCreatePricing(true);
    this.openOptionDetail = false;
    this.openOptionLocation = false;
    this.openOptionPolicy = false;
    this.openOptionSchedule = false;
  }

  openScheduleSection() {
    scheduleModule.clearState();
    this.openOptionDetail = false;
    this.openOptionLocation = false;
    this.openOptionPolicy = false;
    this.openOptionPricing = false;
    this.openOptionSchedule = true;
  }

  openSection() {
    this.openOptionDetail = false;

    if (this.step === 2) {
      //
    }

    if (this.step === 5) {
      this.openOptionLocation = false;
      this.openOptionPolicy = false;
      this.openOptionPricing = false;
      this.openOptionSchedule = true;
    } else if (this.step === 4) {
      this.openOptionLocation = false;
      this.openOptionPolicy = false;
      this.openOptionSchedule = false;
      this.openOptionPricing = true;
    } else if (this.step === 3) {
      this.openOptionLocation = false;
      this.openOptionPricing = false;
      this.openOptionSchedule = false;
      this.openOptionPolicy = true;
    } else if (this.step === 2) {
      this.openOptionPolicy = false;
      this.openOptionPricing = false;
      this.openOptionSchedule = false;
      this.openOptionLocation = true;
    }
  }

  configOption() {
    const map = new Map(Object.entries(this.detailsForm));
    const id = faker.datatype.uuid();
    const orgId = _.cloneDeep(companyModule.Company.orgId);
    const name = String(map.get('name') || '') || '';
    const description = String(map.get('description') || '') || '';

    this.option = {
      id: id,
      orgId: orgId,
      internalName: name,
      description: description,
    } as Option;

    optionModule.setOption(this.option);

    let travelers = map.get('travelers') as Array<string>;
    if (isArray(travelers)) {
      travelers = travelers.map((traveler: any) =>
        traveler.value ? traveler.value : traveler,
      );
    }
    optionModule.setSelectedUnitIds(travelers);
  }

  addOptionDetails() {
    if (
      _.isBoolean(this.shareSchedule) &&
      (this.shareSchedule === true || this.shareSchedule === false)
    ) {
      this.option.sharesAvailabilityWithProduct = !!this.shareSchedule;
    }

    if (
      _.isBoolean(this.shareInventory) &&
      (this.shareInventory === true || this.shareInventory === false)
    ) {
      this.option.sharesCapacityWithProduct = !!this.shareInventory;
    }

    if (this.createSchedule) {
      this.createNewSchedule();
    } else {
      // this.scheduleId = scheduleModule.DefaultSchedule.id;
    }

    if (this.createPolicy) {
      this.configPolicy();
    }

    this.saveForm();
    // todo send alert
  }

  finish() {
    this.addOptionDetails();
  }

  generateTimeSlotsForGrouped() {
    const timeSlots = [] as any;
    scheduleModule.GroupedDays.map((group: any) => {
      group.times.map((time: any) => {
        group.days.map((day: string) => {
          timeSlots.push({
            weekday: day,
            start: time.start,
            end: time.end,
          });
        });
      });
    });
    return timeSlots;
  }

  generateTimeSlotsForIndividual() {
    const timeSlots = [] as any;
    scheduleModule.IndividualDays.map((day: any) => {
      day.timeSlots.map((time: any) => {
        if (time.start !== null || time.start?.length > 0) {
          timeSlots.push({
            weekday: day.weekday,
            start: time.start,
            end: time.end,
          });
        }
      });
    });
    return timeSlots;
  }

  handleChangeTravelerType(travelers: Array<string>) {
    this.selectedTravelers = _.cloneDeep(travelers);
    const oldUnits = _.cloneDeep(this.displayUnits);
    const newUnits = [] as Array<DisplayUnitPrice>;
    const emptyUnit = {
      retail: '',
      original: '',
      net: '',
    } as DisplayUnitPrice;
    for (const t of travelers) {
      emptyUnit.unitId = t;
      const index = oldUnits.findIndex((o) => o.unitId === t);
      if (index > -1) {
        const u = oldUnits[index] || _.cloneDeep(emptyUnit);
        newUnits.push(u);
      } else {
        const u = _.cloneDeep(emptyUnit);
        newUnits.push(u);
      }
    }
    this.displayUnits = _.cloneDeep(newUnits);
  }

  isValidUnitPrice(unit: DisplayUnitPrice) {
    return (
      !!(unit.net && _.isNumber(+unit.net) && _.toNumber(unit.net) >= 0) ||
      !!(unit.original && _.isNumber(+unit.original) && _.toNumber(unit.original) >= 0) ||
      !!(unit.retail && _.isNumber(+unit.retail) && _.toNumber(unit.retail) >= 0)
    );
  }

  async addUnitPrices() {
    const unitPrices = [] as Array<OptionUnitPrice>;
    this.selectedTravelers.forEach((t: string) => {
      const du = this.displayUnits.find((du: DisplayUnitPrice) => du.unitId === t);
      if (du) {
        const optionUnitPriceAmount = {
          currency: this.currency,
        } as OptionUnitPriceAmount;
        if (typeof du.net === 'string' && du.net.length) {
          optionUnitPriceAmount.net = this.dollarsToCents(_.toNumber(du.net));
        }
        if (typeof du.retail === 'string' && du.retail.length) {
          optionUnitPriceAmount.retail = this.dollarsToCents(_.toNumber(du.retail));
        }
        if (typeof du.original === 'string' && du.original.length) {
          optionUnitPriceAmount.original = this.dollarsToCents(_.toNumber(du.original));
        }

        unitPrices.push({
          unitId: du.unitId,
          amount: optionUnitPriceAmount,
        } as OptionUnitPrice);
      }
    });
    this.option.unitPrices = unitPrices;
    // const option = _.cloneDeep(optionModule.Option);
    // option.unitPrices = unitPrices;
    //
    // optionModule.setOption(option);
    // try {
    //   httpModule
    //     .createOption(option)
    //     .then(() => {
    //       // httpModule.addScheduleToOption(scheduleModule.Schedule.id).then();
    //     })
    //     .catch(() => {
    //       // todo send alert
    //     })
    //     .finally(() => {
    //       this.navigateOptionDetails(optionModule.Option.id);
    //     });
    // } catch (e) {
    //   console.log(e);
    //   // todo send alert to backend
    // }
  }

  convertCurrencyToMinorUnit(amount: any) {
    if (amount) {
      const amountNumber = _.toNumber(amount);
      return amountNumber * 100;
    } else if (amount === 0) return amount;
    return amount;
  }

  async createNewLocation() {
    if (!this.createLocation) {
      return;
    }
    if (
      this.tripRoute &&
      this.tripRoute.departure &&
      _.isString(this.tripRoute.departure) &&
      this.tripRoute.departure.length > 0 &&
      this.tripRoute.arrival &&
      _.isString(this.tripRoute.arrival) &&
      this.tripRoute.arrival.length > 0
    ) {
      optionModule.setTripRoute(this.tripRoute);
      await httpModule
        .updateOptionTripRoute()
        .then(() => {})
        .catch();
    }
  }

  async createNewSchedule() {
    this.scheduleId = faker.datatype.uuid();
    let timeSlots = [] as any;
    if (this.TimeEntryMode === TimeEntryMode.Grouped) {
      timeSlots = this.generateTimeSlotsForGrouped();
    }
    if (this.TimeEntryMode === TimeEntryMode.Individual) {
      timeSlots = this.generateTimeSlotsForIndividual();
    }

    const end = scheduleModule.ScheduleData.validEnd;
    const schedule = {
      id: this.scheduleId,
      name: scheduleModule.ScheduleData.name,
      validFrom: scheduleModule.ScheduleData.validStart,
      validUntil: _.isEmpty(end) ? null : end,
      timeSlots: timeSlots,
    };

    await httpModule.updateSchedule(schedule);
  }

  configPolicy() {
    const bookingMinutes = this.bookingCutoffTimes.minutes;
    const bookingMinutesFromHours = this.bookingCutoffTimes.hours * 60;
    const bookingMinutesFromDays = this.bookingCutoffTimes.days * 24 * 60;
    const bookingCutoff =
      +bookingMinutes + +bookingMinutesFromHours + +bookingMinutesFromDays || 0;

    const cancellable = this.cancellationType === CancellationPolicyOptions.YES;
    const refundable = this.refundable === RefundablePolicyOptions.YES;
    this.option.policy = {
      refundable: refundable, // todo fix
      cancellable: cancellable, // todo fix
      cutOffTime: bookingCutoff,
    } as ProductPolicy;
  }

  unitIdToTravelerName(id: string) {
    const i = this.TravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.TravelerTypes[i].text;
    }
    return '';
  }

  hasSelectedTravelerType(id: string): boolean {
    return this.selectedTravelerTypes.findIndex((t: string) => t === id) > -1;
  }

  toggleOptionDetail() {
    this.openOptionDetail = !this.openOptionDetail;
  }

  toggleOptionLocation() {
    this.openOptionLocation = !this.openOptionLocation;
  }

  toggleOptionPolicy() {
    this.openOptionPolicy = !this.openOptionPolicy;
  }

  toggleOptionPricing() {
    this.openOptionPricing = !this.openOptionPricing;
  }

  toggleAddPricingSet() {
    optionModule.setCreateModeOptionCreatePricingSet(true);
  }

  validateForm() {
    if (this.$refs && this.$refs.form) {
      const { form } = this.$refs as any;
      const valid = form.validate();
      if (valid) {
        this.addOptionDetails();
      }
    }
  }

  populateCapacityUnit() {
    this.byUnitPoolArr = [] as Array<PoolStructure>;
    if (this.detailsForm && Array.isArray(this.detailsForm.travelers)) {
      this.detailsForm.travelers.forEach((traveler: any) => {
        if (traveler.value)
          this.byUnitPoolArr.push({ unitId: traveler.value, value: '' });
        else this.byUnitPoolArr.push({ unitId: traveler, value: '' });
      });
      this.handleChangeTravelerType(
        this.detailsForm.travelers.map((t: any) => (t.value ? t.value : t)),
      );
    }
  }

  async saveForm() {
    try {
      const byUnit = {} as Map<string, any>;
      this.byUnitPoolArr.forEach((p) => {
        const val = _.toNumber(p.value);
        if (p.value && _.isNumber(val) && val > 0) {
          _.set(byUnit, p.unitId, val);
        }
      });
      const capacityType = (this.selectedCapacityType || 'unlimited') as CapacityType;
      if (capacityType === CapacityType.CapacityLimitUnlimited) {
        this.option.availabilityType = AvailabilityType.OpeningHours;
      } else {
        this.option.availabilityType = AvailabilityType.StartTime;
      }
      if (this.createPricing) {
        await this.addUnitPrices();
      }
      await httpModule
        .createOption(this.option)
        .then(() => {
          optionModule.setOption(this.option);
        })
        .catch(() => {
          // todo send alert
        });
      const pool = {
        orgId: this.option.orgId,
        optionId: this.option.id,
        capacityPool: {
          unlimited:
            this.selectedCapacityType === this.capacityType.CapacityLimitUnlimited,
          pooled:
            this.selectedCapacityType === this.capacityType.CapacityLimitPooled
              ? _.toNumber(this.capacityPool.pooled)
              : 0,
          byUnit: byUnit,
        },
      } as OptionCapacityPool;
      optionModule.setOptionCapacityPool(pool);
      await httpModule.updateOptionCapacity();
      await this.createNewLocation();
      if (this.scheduleId) await httpModule.addScheduleToOption(this.scheduleId); // todo check valid default schedule
      this.navigateOptionDetails(optionModule.Option.id);
    } catch (e) {
      console.log(e);
      // todo send alert to backend
    }
  }

  navigateOptionsList() {
    this.$router.push({ name: this.routeOptionList });
  }

  navigateOptionDetails(id: string) {
    this.saving = false;
    this.$router
      .push({ name: constants.routes.OPTION_DETAILS, params: { id: id } })
      .catch();
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get CapacityTypes() {
    return [
      CapacityType.CapacityLimitUnlimited,
      CapacityType.CapacityLimitPooled,
      CapacityType.CapacityLimitByUnit,
    ];
  }

  get InventoryTypes() {
    return [InventoryType.FREESALE, InventoryType.LIMITED];
  }

  get CreateOptionFormState(): boolean {
    return optionModule.CreateOptionFormState;
  }

  set CreateOptionFormState(state: boolean) {
    optionModule.setCreateOptionFormState(state);
  }

  get CompanyAddresses() {
    return companyModule.Addresses;
  }

  get TravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get HasOwnData() {
    return (
      this.shareLocation === 'yes' ||
      this.sharePolicy === 'yes' ||
      this.sharePricing === 'yes' ||
      this.shareSchedule === 'yes'
    );
  }

  get HasValidUnits() {
    const unitCount = this.displayUnits.length;
    if (unitCount === 0) {
      return false;
    }

    let isValid = true;

    this.displayUnits.forEach((u) => {
      if (isValid) {
        const valid = this.isValidUnitPrice(u);
        if (!valid) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  get CancellationTypes() {
    return [CancellationPolicyOptions.YES, CancellationPolicyOptions.NO];
  }

  get RefundValues() {
    return [RefundablePolicyOptions.YES, RefundablePolicyOptions.NO];
  }

  get BookingCutoff() {
    return [BookingCutoffOptions.NO, BookingCutoffOptions.YES];
  }

  get Schedules() {
    // todo replace with individual schedules
    return scheduleModule.Schedules;
  }

  get HasArrivalDeparture() {
    return (
      (this.tripRoute &&
        _.isString(this.tripRoute.departure) &&
        this.tripRoute.departure.length > 0) ||
      (this.tripRoute &&
        _.isString(this.tripRoute.arrival) &&
        this.tripRoute.arrival.length > 0)
    );
  }

  get CreateOptionScheduleForm() {
    return optionModule.CreateOptionScheduleForm;
  }

  get TimeEntryMode() {
    return scheduleModule.TimeEntryMode;
  }

  get OpenCreatePricing() {
    return optionModule.OpenCreatePricing;
  }

  get Loading() {
    return optionModule.Loading;
  }

  get TravelerTypeSelectIcon() {
    if (
      this.detailsForm.travelers &&
      this.detailsForm.travelers.length === this.TravelerTypes.length
    ) {
      return 'mdi-close-box';
    }
    if (
      this.detailsForm.travelers &&
      this.detailsForm.travelers.length > 0 &&
      this.detailsForm.travelers &&
      this.detailsForm.travelers.length < this.TravelerTypes.length
    ) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }
}
