import Vue from 'vue';
import Router from 'vue-router';
import { routes } from '@/router/routes';
import { constants, Role } from '@/utils/constants';
import { HasAnyRole, IsAllRole, profileModule } from '@/store/modules/moduleProfile';
import { httpModule } from '@/store/modules/moduleHttp';
import { appModule } from '@/store/modules/moduleApp';
import { codes } from '@/utils/codeConstants';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthRequired = to.matched.some((r) => r.meta?.requiresAuth);
  const allowedAnyRole =
    to.meta?.allowedAnyRole &&
    Array.isArray(to.meta?.allowedAnyRole) &&
    to.meta?.allowedAnyRole.length > 0;

  try {
    // if no matched routes found, redirect to 404 page
    if (to.matched.length === 0) {
      next({ name: constants.routes.NOT_FOUND });
    }

    if (!isAuthRequired) {
      next();
    } else {
      //TODO: save these commented sections to restore public features when we will be ready RE-7998
      // all features in routes' chain (parent -> children) must be matched
      // const featuresRequired = to.matched.map((r) => r.meta?.feature) as Array<
      //   string | undefined
      // >;
      // each route has its own access policy
      const roleRequired = to.meta?.role as Array<Role> | undefined;
      const allowedRoleRequired = to.meta?.allowedAnyRole as Array<Role> | undefined;
      // if (appModule.Features.length === 0) {
      //   await httpModule.getPublicFeatures();
      // }
      //
      // const featureMatch = featuresRequired.reduce(
      //   (prev, curr) => prev && (!curr || IsFeature(appModule.Features, curr)),
      //   true,
      // );
      // if (!featureMatch) {
      //   next({ name: constants.routes.HOME });
      //   appModule.addMessageError(codes.PROFILE_ERROR_UNAUTHORIZED);
      //   return;
      // }

      if (!profileModule.Profile) {
        await httpModule.getProfile();
      }
      const hasAnyRole =
        HasAnyRole(profileModule.Profile, allowedRoleRequired).length > 0;
      if (
        roleRequired &&
        !profileModule.IsAdmin &&
        !IsAllRole(profileModule.Profile, roleRequired) &&
        !(allowedAnyRole && hasAnyRole)
      ) {
        next({ name: constants.routes.HOME });
        appModule.addMessageError(codes.PROFILE_ERROR_UNAUTHORIZED);
        // todo send message to backend
        return;
      }

      appModule.setPreviousRoute(from);
      next();
    }
  } catch (e) {
    //TODO: Add logger
    // if (e) {
    //   await wpsClient
    //     .logUIErr(
    //       {
    //         severity: 'error',
    //         message: _.toString(e), // err.toString ? err.toString() : 'UI error';
    //       },
    //       e,
    //     )
    //     .toPromise();
    // }
    next(false);
    e.where = 'router beforeEach hook';
    throw e;
  }
});

router.afterEach((to) => {
  Vue.nextTick().then(() => {
    // set page title
    document.title = `${
      to?.meta?.browserTitle || to?.meta?.title || 'Redeam'
    } - Redeam Central`;
  });
});

export default router;
