



































































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import { companyModule } from '@/store/modules/moduleCompany';
import ProductViewLocationModal from '@/components/product/modal/ProductViewLocationModal.vue';
import { constants } from '@/utils/constants';
import { timezoneByCountry } from '@/app-codes/timezones';

@Component({
  components: { ProductViewLocationModal },
})
export default class ProductViewLocation extends Vue {
  private hoverCard = false;
  private timeZones = timezoneByCountry;

  mounted() {
    // if (this.Product.addressId) {
    //   const latLong = this.getLatLong(this.Product.addressId);
    //   httpModule.getTimezoneByLatLong(latLong);
    // }
  }

  openEditModal() {
    this.$root.$emit(constants.events.PRODUCT_LOCATION_EDIT_MODAL_CLEAR_VALIDATION);
    productModule.configureLocationForm();
    productModule.setEditProductLocationModal(true);
  }

  convertAddressIdToAddress(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.streetAddress && address.streetAddress.length > 0) {
        return `${address.streetAddress}, ${address.city}, ${address.region} ${address.postalCode}`;
      }
    }
    return '';
  }

  convertAddressIdToName(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.name && address.name.length > 0) {
        return address.name;
      }
    }
    return '';
  }

  convertAddressIdToLatLong(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.latitude && address.longitude) {
        return `${address.latitude}, ${address.longitude}`;
      }
    }
    return '';
  }

  getLatLong(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.latitude && address.longitude) {
        return { lat: address.latitude, long: address.longitude };
      }
    }
    return { lat: 0, long: 0 };
  }

  mouseEnterEvent() {
    this.hoverCard = true;
  }

  mouseLeaveEvent() {
    this.hoverCard = false;
  }

  get HoverCard() {
    return this.hoverCard;
  }

  get Product() {
    return productModule.Product;
  }

  get TripRoute() {
    return productModule.TripRoute;
  }

  get EditModal() {
    return productModule.EditProductLocationModal;
  }

  get Timezone() {
    if (this.Product && this.Product.timeZone && this.Product.timeZone.length > 0) {
      const timezone = this.timeZones.find((t) => t.value === this.Product.timeZone);
      if (timezone) {
        return `(GMT${timezone?.offset?.standard}) ${timezone?.name} Time`;
      }
    }
    return '';
  }
}
