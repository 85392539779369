













































































































import { Component, Vue } from 'vue-property-decorator';
import { DropdownItem } from '@/models';
import { getYear, addYears, subYears, format, subMonths, addMonths } from 'date-fns';
import AvailabilityStatusCalendarMonthly from '@/components/inventory/availability/AvailabilityStatusCalendarMonthly.vue';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';

@Component({
  components: { AvailabilityStatusCalendarMonthly },
})
export default class AvailabilityStatusCalendar extends Vue {
  private calendarDates = [] as Array<DropdownItem>;
  private selectedCalendarDate = 'year';

  mounted() {
    this.calendarDates.push({ text: 'Year', value: 'year' });
    this.calendarDates.push({ text: 'Month', value: 'month' });
  }

  today() {
    this.CurrentDate = new Date();
    this.dateChange();
  }

  previous() {
    if (this.selectedCalendarDate === 'month') {
      this.previousMonth();
    } else if (this.selectedCalendarDate === 'year') {
      this.previousYear();
    }
  }

  next() {
    if (this.selectedCalendarDate === 'month') {
      this.nextMonth();
    } else if (this.selectedCalendarDate === 'year') {
      this.nextYear();
    }
  }

  previousYear() {
    this.CurrentDate = subYears(this.CurrentDate, 1);
    this.dateChange();
  }

  nextYear() {
    this.CurrentDate = addYears(this.CurrentDate, 1);
    this.dateChange();
  }

  previousMonth() {
    this.CurrentDate = subMonths(this.CurrentDate, 1);
    this.dateChange();
  }

  nextMonth() {
    this.CurrentDate = addMonths(this.CurrentDate, 1);
    this.dateChange();
  }

  get Month() {
    return format(this.CurrentDate, 'MMMM');
  }

  get Year() {
    return getYear(this.CurrentDate);
  }

  dateChange() {
    this.$emit('select-calendar-date', this.selectedCalendarDate);
  }

  get LoadingAvails(): boolean {
    return availabilityStatusModule.Loading;
  }

  get MonthYear() {
    if (this.CurrentDate) {
      if (this.selectedCalendarDate === 'month') {
        return format(this.CurrentDate, 'MMMM yyyy');
      } else if (this.selectedCalendarDate === 'year') {
        return format(this.CurrentDate, 'yyyy');
      }
    }
    return '';
  }

  get CurrentDate() {
    return availabilityStatusModule.CurrentDate;
  }

  set CurrentDate(date: Date) {
    availabilityStatusModule.setCurrentDate(date);
  }
}
