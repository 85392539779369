




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import VoucherInfo from './VoucherInfo.vue';
import WebRedemptionModule from '@/store/modules/.deprecated/moduleWebRedemption';

@Component({
  components: {
    'rdm-voucher-info': VoucherInfo,
  },
})
export default class VoucherPasses extends Vue {
  @Prop({ type: Array, required: true })
  public passes!: any[];

  private webRedemption = getModule(WebRedemptionModule);

  private selectedItem = null;

  private selectPass(pass: any) {
    this.webRedemption.setSelectedTicket(null);
    this.webRedemption.setSelectedPass(pass);
  }

  private selectRawPass(pass: any) {
    this.webRedemption.setSelectedRawTicket(null);
    const p = this.webRedemption.rawPasses[pass.id];
    this.webRedemption.setSelectedRawPass(p);
  }

  private get resellerRef() {
    return (
      this.webRedemption.getSelectedRawPass &&
      this.webRedemption.getSelectedRawPass.resellerRef
    );
  }

  private set resellerRef(ref) {
    const pass = this.webRedemption.rawPasses.find((p) => p.reseller_ref === ref);
    this.webRedemption.setSelectedRawPass(pass);
  }

  private get selectedPass() {
    return this.webRedemption.getSelectedPass;
  }

  private get selectedRawPass() {
    return this.webRedemption.getSelectedRawPass;
  }
}
