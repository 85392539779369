

























































import { Component, Vue } from 'vue-property-decorator';
import { descriptionLimitRules } from '@/utils/validation-rules';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import { offerModule } from '@/store/modules/moduleOffer';
import { incrementMeta, incrementVersion } from '@/utils/helpers';

@Component
export default class OfferEditDescription extends Vue {
  private descriptionLimitRules = descriptionLimitRules;
  private offerDescription = '';
  private baseOfferDescription = '';
  private descriptionLimit = 2000;

  mounted() {
    this.offerDescription = _.cloneDeep(this.Offer.description || '');
    this.baseOfferDescription = _.cloneDeep(this.Offer.description || '');
  }

  toggleDescription() {
    offerModule.toggleEditOfferDescription();
  }

  async saveDescription() {
    const offer = _.cloneDeep(this.Offer);
    const meta = _.cloneDeep(offer.meta);
    offer.description = this.offerDescription;
    offer.meta = incrementMeta(meta);
    offer.version = incrementVersion(meta);
    await httpModule.updateOffer(offer);
    offerModule.toggleEditOfferDescription();
  }

  cancelDescription() {
    this.offerDescription = this.baseOfferDescription;
    this.baseOfferDescription = _.cloneDeep(this.Offer.description || '');
    offerModule.toggleEditOfferDescription();
  }

  get Offer() {
    return offerModule.Offer;
  }

  get EditOfferDescription() {
    return offerModule.EditOfferDescription;
  }

  get Loading() {
    return offerModule.Loading;
  }
}
