import { constants } from '@/utils/constants';

export const permissionMapping = function (value: string) {
  if (value?.toUpperCase() === constants.roles.SUPPLIER_BUSINESS_STAFF) {
    return 'View';
  }
  if (value?.toUpperCase() === constants.roles.SUPPLIER_REDEMPTION_STAFF) {
    return 'Redemption';
  }
  if (value?.toUpperCase() === constants.roles.ORG_MGMT) {
    return 'Admin';
  }
  if (value?.toUpperCase() === constants.roles.SUPPLIER_REPORTING_STAFF) {
    return 'Upcoming Arrival Report';
  }
  if (value?.toUpperCase() === constants.roles.SUPPLIER_REPORTING_ALL_BOOKING_STAFF) {
    return 'All Bookings Report';
  }
  if (value?.toUpperCase() === constants.roles.SUPPLIER_REPORTING_REDEMPTIONS_STAFF) {
    return 'Redemption Report';
  }
  if (
    value?.toUpperCase() === constants.roles.SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF
  ) {
    return 'Customer Manifest Report';
  }
  if (value?.toUpperCase() === constants.roles.REDEAM_TECHNICAL_STAFF) {
    return 'Technical';
  }
  if (value?.toUpperCase() === constants.roles.RC_CONNECTIVITY_MANAGEMENT) {
    return 'Connectivity Management';
  }
  if (value?.toUpperCase() === constants.roles.RC_TICKETING_EDIT) {
    return 'Rate Card Edit';
  }
  if (value?.toUpperCase() === constants.roles.RC_TICKETING_VIEW) {
    return 'Rate Card View';
  }
  return value;
};

export const permissionTooltip = function (value: string) {
  if (value?.toUpperCase() === constants.roles.SUPPLIER_BUSINESS_STAFF) {
    return 'Allows basic access to Redeam Central';
  }
  if (value?.toUpperCase() === constants.roles.SUPPLIER_REDEMPTION_STAFF) {
    return 'Allows the user to web redeem in Redeam Central';
  }
  if (value?.toUpperCase() === constants.roles.ORG_MGMT) {
    return 'Allows the user to manage other users and change application settings';
  }
  if (value?.toUpperCase() === constants.roles.SUPPLIER_REPORTING_STAFF) {
    return 'Allows the user to view reports in Redeam Central';
  }
  if (value?.toUpperCase() === constants.roles.REDEAM_TECHNICAL_STAFF) {
    return 'Technical';
  }
  if (value?.toUpperCase() === constants.roles.RC_CONNECTIVITY_MANAGEMENT) {
    return 'Access connectivity management functions';
  }
  if (value?.toUpperCase() === constants.roles.RC_TICKETING_EDIT) {
    return 'View and modify Rate Card';
  }
  if (value?.toUpperCase() === constants.roles.RC_TICKETING_VIEW) {
    return 'View Rate Card';
  }

  if (value?.toUpperCase() === constants.roles.SUPPLIER_REPORTING_ALL_BOOKING_STAFF) {
    return 'Allows All Bookings Report';
  }
  if (value?.toUpperCase() === constants.roles.SUPPLIER_REPORTING_REDEMPTIONS_STAFF) {
    return 'Redemption Report';
  }
  if (
    value?.toUpperCase() === constants.roles.SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF
  ) {
    return 'Customer Manifest Report';
  }
  return value;
};
