import _ from 'lodash';

export enum RedemptionStatus {
  STATUS_NOT_STARTED = 'STATUS_NOT_STARTED',
  STATUS_NOT_FOUND = 'STATUS_NOT_FOUND',
  STATUS_LOADED = 'STATUS_LOADED',
  STATUS_CLARIFICATION = 'STATUS_CLARIFICATION',
  STATUS_VALIDATED = 'STATUS_VALIDATED',
  STATUS_FAILED = 'STATUS_FAILED',
}

export const AgeBands = {
  UNKNOWN_TRAVELER_AGE: { val: 0, text: 'Unknown traveler age' },
  INFANTS: { val: 1, text: 'Infants' },
  CHILDREN: { val: 2, text: 'Children' },
  YOUTH: { val: 3, text: 'Youth' },
  ADULTS: { val: 4, text: 'Adults' },
  SENIORS: { val: 5, text: 'Seniors' },
  STUDENTS: { val: 6, text: 'Students' },
  MILITARY: { val: 7, text: 'Military' },
  ANY: { val: 99, text: 'Any' },
};

export function GetAgeBandByValue(val: number): string {
  const bands = Object.values(AgeBands);
  const selectedBand = bands.find((b) => b.val === val);
  if (!selectedBand) {
    return AgeBands.UNKNOWN_TRAVELER_AGE.text;
  }

  return selectedBand.text;
}

export function getTravelersCount(travelers: any[]) {
  return travelers.reduce((bands, traveler) => {
    const bandIndex = traveler.age_band;

    if (!bands[bandIndex]) {
      return {
        ...bands,
        [bandIndex]: 1,
      };
    }

    return {
      ...bands,
      [bandIndex]: bands[bandIndex] + 1,
    };
  }, {});
}

export function normalizeTicket(ticket: any, id: number) {
  const travelers = _.get(ticket, 'travelers', []);
  const lead = travelers.find((t: any) => !!t.is_lead);
  const activity = _.get(ticket, 'activity', '[not specified]');
  const product =
    ticket &&
    Array.isArray(ticket.products) &&
    ticket.products.length > 0 &&
    ticket.products[0].title &&
    ticket.products[0].title.length > 0
      ? ticket.products[0].title
      : '[not specified]';

  return {
    id,
    lead: _.get(lead, 'full_name', '[not specified]'),
    travelersCount: travelers.length,
    travelersMap: getTravelersCount(travelers),
    activity:
      activity && activity.length > 0 && activity !== '[not specified]'
        ? activity
        : product,
    reseller: _.get(ticket, 'reseller_name', '[not specified]'),
    supplier: _.get(ticket, 'supplier_code', '[not specified]'),
    resellerRef: _.get(ticket, 'reseller_ref', '[not specified]'),
    supplierRef: _.get(ticket, 'supplier_ref', '[not specified]'),
    status: ticket ? ticket.status : 'unknown',
  };
}

export function normalizePass(pass: any) {
  const travelers = _.get(pass, 'travelers', []);
  const lead = travelers.find((t: any) => !!t.is_lead);

  return {
    lead: _.get(lead, 'full_name', '[not specified]'),
    travelersCount: travelers.length,
    travelersMap: getTravelersCount(travelers),
    activity: _.get(pass, 'activity', '[not specified]'),
    reseller: _.get(pass, 'reseller.code', '[not specified]'),
    resellerRef: _.get(pass, 'reseller_ref', '[not specified]'),
    status: pass ? pass.status : 'unknown',
  };
}

export function normalizePassIndex(pass: any, id: number) {
  const travelers = _.get(pass, 'travelers', []);
  const lead = travelers.find((t: any) => !!t.is_lead);

  return {
    id,
    lead: _.get(lead, 'full_name', '[not specified]'),
    travelersCount: travelers.length,
    travelersMap: getTravelersCount(travelers),
    activity: _.get(pass, 'activity', '[not specified]'),
    reseller: _.get(pass, 'reseller.code', '[not specified]'),
    resellerRef: _.get(pass, 'reseller_ref', '[not specified]'),
    status: pass ? pass.status : 'unknown',
  };
}

export const ticketStatus = {
  STATUS_UNKNOWN: 0,
  STATUS_OPEN: 1,
  STATUS_REDEEMED: 2,
  STATUS_CANCELLED: 3,
  STATUS_REFUNDED: 4,
  STATUS_VOIDED: 5,
  STATUS_INVALIDATED: 6,
  STATUS_PARTIALLY_REDEEMED: 7,
  STATUS_FAUX_TICKET: 8,
  STATUS_EXPIRED: 9,
  STATUS_DELETED: 10,
};

export function getTicketStatusByValue(val: number) {
  switch (val) {
    case 1:
      return 'OPEN';
    case 2:
      return 'REDEEMED';
    case 3:
      return 'CANCELLED';
    case 4:
      return 'REFUNDED';
    case 5:
      return 'VOIDED';
    case 6:
      return 'INVALIDATED';
    case 7:
      return 'PARTIALLY_REDEEMED';
    case 8:
      return 'FAUX_TICKET';
    case 9:
      return 'EXPIRED';
    case 10:
      return 'DELETED';
    default:
      return 'UNKNOWN';
  }
}

export function getVoucherStatusByValue(val: number) {
  switch (val) {
    case 1:
      return 'REDEEMED';
    case 2:
      return 'VOIDED';
    case 10:
      return 'DELETED';
    default:
      return 'UNKNOWN';
  }
}

export const passStatus = {
  STATUS_UNKNOWN: 0,
  STATUS_INVALID: 1,
  STATUS_VALID: 2,
  STATUS_NEEDS_CLARIFICATION: 3,
  STATUS_VOUCHER_NOT_FOUND: 4,
  STATUS_VOUCHER_USED: 5,
  STATUS_VOUCHER_USED_VENDOR: 6,
  STATUS_VOUCHER_EXPIRED: 7,
  STATUS_NO_PRODUCT: 8,
  STATUS_NO_STRATEGY: 9,
  STATUS_WAITING_PERIOD_NOT_EXPIRED: 10,
  STATUS_VOUCHER_ID_DUPLICATION: 11,
  STATUS_VOUCHER_EXCEEDS_MAX_VALIDATION: 12,
  STATUS_INSUFFICIENT_CURRENCY: 13,
  STATUS_INTERNAL_ERROR: 100,
};

export const messages = {
  TICKET_VALID: {
    type: 'success',
    title: 'Ticket Valid',
  },
  TICKET_REDEEMED: {
    type: 'error',
    title: 'Ticket Invalid: Previously Redeemed',
    text: 'This ticket has already been redeemed at your location.',
  },
  TICKET_CANCELLED: {
    type: 'error',
    title: 'Ticket Invalid: Canceled',
    text: 'This ticket has been canceled by the reseller and is no longer valid.',
  },
  TICKET_REFUNDED: {
    type: 'error',
    title: 'Ticket Invalid: Refunded',
    text: 'This ticket has been refunded by the reseller and is no longer valid.',
  },
  TICKET_VOIDED: {
    type: 'error',
    title: 'Ticket Invalid: Voided',
    text: 'This ticket has been voided by the reseller and is no longer valid.',
  },
  TICKET_UNRECOGNIZED: {
    type: 'error',
    title: 'Barcode Unrecognized',
    text: 'Barcode is not currently present in the system. Contact Redeam with this ticket number to resolve.',
  },
  TICKET_INVALIDATED: {
    type: 'error',
    title: 'Ticket Invalid',
  },
  TICKET_EXPIRED: {
    type: 'error',
    title: 'Ticket Invalid: Expired',
    text: 'This ticket is expired and no longer valid.',
  },
  TICKET_DELETED: {
    type: 'error',
    title: 'Ticket Invalid: Deleted',
    text: 'This ticket has been deleted and is no longer valid.',
  },
  TICKET_NOT_VALID: {
    type: 'error',
    title: 'Ticket not valid for redemption',
  },
  PASS_VALID: {
    type: 'success',
    title: 'Pass Valid',
  },
  PASS_NOT_FOUND: {
    type: 'error',
    title: 'Pass Invalid: Inactive',
    text: 'This pass is not recognized as an active order. Do not admit the guest. They may contact Sightseeing Pass if they believe this is an error.',
  },
  PASS_PRODUCT_NOT_FOUND: {
    type: 'error',
    title: 'Pass Invalid: Product Conflict',
    text: 'This pass is not recognized for admission at this location. Do not admit the guest. Please contact Sightseeing Pass if you believe this is an error.',
  },
  PASS_PRODUCT_USED: {
    type: 'error',
    title: 'Pass Invalid: Used or Expired',
    text: 'This pass is expired, used up or has already been redeemed at your location. The guest may contact Sightseeing Pass if they believe this is an error.',
  },
  PASS_WAITING: {
    type: 'error',
    title: 'Passholder Must Wait',
    text: 'The pass was recently scanned at another attraction. The guest must wait before scanning it again.',
  },
  PASS_LACKS_MONEY: {
    type: 'error',
    title: 'Pass Invalid: Insufficient Currency',
    text: 'Please contact Sightseeing Pass.',
  },
  PASS_MAX_USES: {
    type: 'error',
    title: 'Pass Invalid: Used or Expired',
    text: 'This pass is expired, used up or has already been redeemed at your location. The guest may contact Sightseeing Pass if they believe this is an error.',
  },
  PASS_EXPIRED: {
    type: 'error',
    title: 'Pass Invalid: Used or Expired',
    text: 'This pass is expired. Do not admit the guest. They may contact Sightseeing Pass if they believe this is an error.',
  },
  PASS_NOT_VALIDATABLE: {
    type: 'error',
    title: 'Pass Invalid: No Strategy',
    text: 'This pass is not configured correctly. Please contact Sightseeing Pass to correct the issue.',
  },
  PASS_FAILED: {
    type: 'error',
    title: 'Unknown Pass Error',
    text: 'An error has occurred. Please contact Redeam with this pass number to resolve.',
  },
  ERROR: {
    type: 'error',
    title: 'Internal Server Error',
    text: 'An error has occurred. Please try again and contact Redeam with this ticket number if the issue persists.',
  },
};

export function validateTicketStatus(status: any) {
  switch (status) {
    case ticketStatus.STATUS_UNKNOWN:
      return messages.ERROR;

    case ticketStatus.STATUS_REDEEMED:
      return messages.TICKET_REDEEMED;

    case ticketStatus.STATUS_CANCELLED:
      return messages.TICKET_CANCELLED;

    case ticketStatus.STATUS_REFUNDED:
      return messages.TICKET_REFUNDED;

    case ticketStatus.STATUS_VOIDED:
      return messages.TICKET_VOIDED;

    case ticketStatus.STATUS_INVALIDATED:
      return messages.TICKET_INVALIDATED;

    case ticketStatus.STATUS_EXPIRED:
      return messages.TICKET_EXPIRED;

    case ticketStatus.STATUS_DELETED:
      return messages.TICKET_DELETED;

    default:
      return null;
  }
}

export function validatePassStatus(status: any) {
  switch (status) {
    case passStatus.STATUS_VOUCHER_NOT_FOUND:
      return messages.PASS_NOT_FOUND;

    case passStatus.STATUS_NO_PRODUCT:
      return messages.PASS_PRODUCT_NOT_FOUND;

    case passStatus.STATUS_VOUCHER_EXCEEDS_MAX_VALIDATION:
      return messages.PASS_PRODUCT_USED;

    case passStatus.STATUS_WAITING_PERIOD_NOT_EXPIRED:
      return messages.PASS_WAITING;

    case passStatus.STATUS_INSUFFICIENT_CURRENCY:
      return messages.PASS_LACKS_MONEY;

    case passStatus.STATUS_VOUCHER_EXPIRED:
      return messages.PASS_EXPIRED;

    case passStatus.STATUS_NO_STRATEGY:
      return messages.PASS_NOT_VALIDATABLE;

    case passStatus.STATUS_INTERNAL_ERROR:
      return messages.PASS_FAILED;

    default:
      return null;
  }
}
