import faker from 'faker';
import { demoRateCardCommissions } from '@/utils/demoData';
import { ProductStatus } from '@/models/Operator';
import { Product } from '@/models/ModelsOppInventory';

export interface Channel {
  id?: string;
  name?: string;
  rateCard?: RateCard;
  products?: Array<Product>;
  channelId?: string;
  channelName?: string;
  optionIds?: Array<string>;
  productId?: string;
  productName?: string;
  supplierId?: string;
  supplierName?: string;
  accountManager?: string;
}

export class RateCard {
  id? = '';
  name? = '';
  commission? = 0;
  status? = 1;
}

export function fakeRateCard(): RateCard {
  const rc: RateCard = new RateCard();
  rc.id = faker.datatype.uuid();
  rc.commission = faker.random.arrayElement(demoRateCardCommissions);
  rc.name = `Rate Card (${rc.commission}%)`;
  rc.status = faker.random.arrayElement([ProductStatus.ACTIVE, ProductStatus.INACTIVE]);
  return rc;
}

export function fakeRateCardWithCommission(commissionPercent: number): RateCard {
  const rc: RateCard = new RateCard();
  rc.id = faker.datatype.uuid();
  rc.commission = commissionPercent;
  rc.name = `Rate Card (${rc.commission}%)`;
  rc.status = faker.random.arrayElement([ProductStatus.ACTIVE, ProductStatus.INACTIVE]);
  return rc;
}

// OTA Rates, NET rate, Large volume channel Rate
export function fakeRateCards(): Array<RateCard> {
  return [
    {
      id: faker.datatype.uuid(),
      name: 'OTA Rates',
      status: ProductStatus.ACTIVE,
      commission: 4,
    },
    {
      id: faker.datatype.uuid(),
      name: 'NET rate',
      status: ProductStatus.ACTIVE,
      commission: 6,
    },
    {
      id: faker.datatype.uuid(),
      name: 'Large volume channel Rate',
      status: ProductStatus.ACTIVE,
      commission: 6,
    },
  ];
}

export class RateCardPrices {
  rate_id = '';

  rate_name = '';

  date = '';

  adult_net = '';

  adult_retail = '';

  child_net = '';

  child_retail = '';

  senior_net = '';

  senior_retail = '';

  other_net = '';

  other_retail = '';
}
