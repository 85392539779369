















import { Component, Vue } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    message: {
      type: String,
      default: '',
    },
  },
})
export default class SaveState extends Vue {
  isNumeric(value: any): boolean {
    return _.isNumber(value);
  }

  get isValidProgress(): boolean {
    return (
      this.$props.progress &&
      this.isNumeric(this.$props.progress) &&
      this.$props.progress >= 0 &&
      this.$props.progress <= 100
    );
  }

  get isComplete(): boolean {
    return this.isValidProgress && this.$props.progress === 100;
  }

  get isError(): boolean {
    return this.isNumeric(this.$props.progress) && this.$props.progress === -1;
  }
}
