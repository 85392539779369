
































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RemoveTravelerType extends Vue {
  @Prop()
  travelerType!: any;

  mounted() {}

  saveForm() {
    // this.$apollo
    //   .mutate({
    //     mutation: REMOVE_TRAVELER_TYPE,
    //     variables: { id: this.travelerType.id },
    //   })
    //   .then(res => {
    //     console.log(JSON.stringify(res));
    //   });
    this.$emit('success-delete-traveler-type', true);
  }

  cancelForm() {
    this.$emit('cancel-delete-traveler-type', true);
  }
}
