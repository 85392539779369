



























































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import {
  descriptionLimitRules,
  greaterThan1Rules,
  productNameRules,
  requiredFieldRules,
  requiredMultiSelectFieldRules,
} from '@/utils/validation-rules';
import {
  CapacityPool,
  Contact,
  CountryTimezone,
  DeliveryFormats,
  DeliveryMethods,
  PoolStructure,
  Product,
  ProductCapacityPool,
  RedemptionMethods,
  TicketDelivery,
} from '@/models';
import {
  AvailabilityStatus,
  AvailabilityType,
  CapacityType,
} from '@/api/inventory/InventoryModels';
import { productModule } from '@/store/modules/moduleProduct';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import _, { isArray } from 'lodash';
import { constants } from '@/utils/constants';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { handleKeyDownOnlyWholeNumeric } from '@/utils/helpers';
import { userModule } from '@/store/modules/moduleUser';
import { profileModule } from '@/store/modules/moduleProfile';
import { timezoneByCountry } from '@/app-codes/timezones';

@Component({
  components: {
    RdmCollapsableControlCard,
  },
})
export default class ProductCreateDetail extends Vue {
  private timeZones = timezoneByCountry;
  private selectedContacts = [] as Array<string>;
  private routeToCreateTravelerType = constants.routes.TRAVELER_CREATE;
  private routeToCreatePrimaryLocation = constants.routes.LOCATION_ADD;
  private requiredFieldRules = requiredFieldRules;
  private productNameRules = productNameRules;
  private requiredMultipleSelectRules = requiredMultiSelectFieldRules;
  private greaterThan1Rules = greaterThan1Rules;
  private capacityType = CapacityType;
  private descriptionLimitRules = descriptionLimitRules;
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;
  private product = {} as Product;
  private capacityPool = {} as CapacityPool;
  private byUnitPool = {};
  private byUnitPoolArr = [] as Array<PoolStructure>;
  private descriptionLimit = 1000;
  private descriptionPlaceholder = 'Description*';
  private validation = false;
  private createTravelerType = false;
  private createPrimaryLocation = false;

  mounted() {
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      form.reset();
    }
  }

  nextStepValidate1(done: boolean) {
    this.validation = true;
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (isValid) {
        this.createProduct(done);
      }
    }
  }

  toggleAllTravelerTypes() {
    this.$nextTick(() => {
      if (this.DetailsForm.travelers.length === this.TravelerTypes.length) {
        this.DetailsForm.travelers = [];
      } else {
        this.DetailsForm.travelers = this.TravelerTypes;
      }
      this.populateCapacityUnit();
    });
  }

  goToTravelerTypeCreate() {
    this.createTravelerType = false;
    this.$router.push({ name: this.routeToCreateTravelerType }).catch();
  }

  goToPrimaryLocationCreate() {
    this.createPrimaryLocation = false;
    this.$router.push({ name: this.routeToCreatePrimaryLocation }).catch();
  }

  createProduct(done: boolean) {
    const map = new Map(Object.entries(this.DetailsForm));

    const ticket = {} as TicketDelivery;
    ticket.redemptionMethod = RedemptionMethods.DIGITAL;
    ticket.deliveryFormats = [DeliveryFormats.QR_CODE];
    ticket.deliveryMethods =
      ([map.get('deliveryMethods')] as Array<DeliveryMethods>) || [];

    // this.product.id = this.createId;
    this.product.id = String(map.get('id'));
    this.product.orgId = this.Company.orgId;
    this.product.timeZone = String(map.get('timezone'));
    this.product.internalName = String(map.get('internalName'));
    this.product.description = String(map.get('description'));
    this.product.addressId = String(map.get('addressId'));
    this.product.ticketDelivery = ticket;
    this.product.locale = 'en_US';
    const capacityType = (map.get('availabilityType') || 'unlimited') as CapacityType;
    if (capacityType === CapacityType.CapacityLimitUnlimited) {
      this.product.availabilityType = AvailabilityType.OpeningHours;
    } else {
      this.product.availabilityType = AvailabilityType.StartTime;
    }

    // todo save
    productModule.setProduct(this.product);
    const byUnit = {} as Map<string, any>;
    this.byUnitPoolArr.forEach((p) => {
      const val = _.toNumber(p.value);
      if (p.value && _.isNumber(val) && val > 0) {
        _.set(byUnit, p.unitId, val);
      }
    });

    const pool = {
      orgId: this.product.orgId,
      productId: this.product.id,
      capacityPool: {
        unlimited: capacityType === CapacityType.CapacityLimitUnlimited,
        pooled:
          capacityType === CapacityType.CapacityLimitPooled
            ? _.toNumber(this.capacityPool.pooled)
            : 0,
        byUnit: byUnit,
      },
    } as ProductCapacityPool;
    productModule.setProductCapacityPool(pool);
    let travelers = map.get('travelers') as Array<string>;
    if (isArray(travelers)) {
      travelers = travelers.map((traveler: any) =>
        traveler.value ? traveler.value : traveler,
      );
    }
    productModule.setSelectedUnitIds(travelers);
    httpModule
      .updateAddProduct()
      .then(() => {
        // todo create product capacity
        httpModule.updateProductCapacity().then();
        httpModule.createProductContact({
          productId: this.product.id,
          contactIds: this.selectedContacts,
        });
        if (done) {
          this.goToProductDetails();
        } else {
          this.openLocationStep();
        }
      })
      .catch(() => {
        // todo send alert
      });
  }

  discard() {
    this.$router.push({ name: constants.routes.PRODUCT_LIST }).catch();
  }

  goToProductDetails() {
    this.product = _.cloneDeep(this.Product);
    this.$router
      .push({ name: constants.routes.PRODUCT_DETAILS, params: { id: this.product.id } })
      .catch();
  }

  openLocationStep() {
    productModule.setCreateProductDetailsOpen(false);
    productModule.setCreateProductStep(2);
    productModule.setCreateProductLocationOpen(true);
  }

  toggleDetailsOpen() {
    productModule.setCreateProductDetailsOpen(!this.DetailsOpen);
  }

  unitIdToTravelerName(id: string) {
    const i = this.TravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.TravelerTypes[i].text;
    }
    return '';
  }

  showTimeZone(item: CountryTimezone) {
    return `(GMT${
      item && item.offset && item.offset.standard ? item.offset.standard : ''
    }) ${item.name} Time`;
  }

  populateCapacityUnit() {
    this.byUnitPoolArr = [] as Array<PoolStructure>;
    if (this.DetailsForm.travelers && Array.isArray(this.DetailsForm.travelers)) {
      this.DetailsForm.travelers.forEach((traveler: any) => {
        if (traveler.value)
          this.byUnitPoolArr.push({ unitId: traveler.value, value: '' });
        else this.byUnitPoolArr.push({ unitId: traveler, value: '' });
      });
    }
  }

  /* Page Data */

  get Company() {
    return companyModule.Company;
  }

  get OperatorUser() {
    return userModule.OperatorUser;
  }

  get Addresses() {
    if (this.IsOrgAdmin) return companyModule.Addresses;
    else
      return companyModule.Addresses.filter((location: any) => {
        return this.OperatorUser?.config.managedLocation.includes(location.id);
      });
  }

  get IsOrgAdmin() {
    return profileModule.IsOrgAdmin;
  }

  get Product() {
    return productModule.Product;
  }

  get CreateProductFormState(): boolean {
    return productModule.CreateProductFormState;
  }

  set CreateProductFormState(state: boolean) {
    productModule.setCreateProductFormState(state);
  }

  get DetailsForm(): any {
    return productModule.CreateProductDetailsForm;
  }

  set DetailsForm(form: any) {
    productModule.setCreateProductDetailsForm(form);
  }

  get TravelerTypeSelectIcon() {
    if (
      this.DetailsForm.travelers &&
      this.DetailsForm.travelers.length === this.TravelerTypes.length
    ) {
      return 'mdi-close-box';
    }
    if (
      this.DetailsForm.travelers &&
      this.DetailsForm.travelers.length > 0 &&
      this.DetailsForm.travelers &&
      this.DetailsForm.travelers.length < this.TravelerTypes.length
    ) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }

  /* UI State */
  get Step() {
    return productModule.CreateProductStep;
  }

  get Saving() {
    return productModule.CreateProductSaving;
  }

  get DetailsOpen() {
    return productModule.CreateProductDetailsOpen;
  }

  /* UI Data */

  get DeliveryFormats() {
    return [
      DeliveryFormats.CODE39,
      DeliveryFormats.CODE128,
      DeliveryFormats.TEXT,
      DeliveryFormats.QR_CODE,
      DeliveryFormats.UPCA,
      DeliveryFormats.EAN13,
    ];
  }

  get DeliveryMethods() {
    return [
      { value: DeliveryMethods.TICKET, text: 'Single bar code per traveler' },
      { value: DeliveryMethods.VOUCHER, text: 'Single bar code per booking' },
    ];
  }

  get TravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get CapacityTypes() {
    return [
      CapacityType.CapacityLimitUnlimited,
      CapacityType.CapacityLimitPooled,
      CapacityType.CapacityLimitByUnit,
    ];
  }

  get AvailabilityTypes() {
    return [
      AvailabilityStatus.Freesale,
      AvailabilityStatus.Available,
      AvailabilityStatus.Limited,
    ];
  }

  get RedemptionMethods() {
    return [
      RedemptionMethods.MANIFEST,
      RedemptionMethods.DIGITAL,
      RedemptionMethods.PRINT,
    ];
  }

  get Contacts(): Array<Contact> {
    const reservations = companyModule.Contacts.filter(
      (contact: Contact) => contact.role === 'Reservations',
    );
    return reservations.map((contact: Contact) => {
      return {
        ...contact,
        showName: `${contact.firstName} ${contact.lastName}`,
      };
    });
  }

  get CountryTimezones() {
    const address = this.Addresses.find((a) => a.id === this.Product?.addressId);
    if (address) {
      // checking for both 2 and 3-letter country code
      let tz = this.timeZones.filter((t) => t.countryCodeIso === address.countryCode);
      if (tz.length === 0) {
        tz = this.timeZones.filter((t) => t.countryCode === address.countryCode);
      }

      if (tz && tz.length > 0) {
        // todo replace sort with helper function
        return tz.sort((a, b) => {
          const upperA =
            `(GMT${a?.offset?.standard}) ${a?.name} Time`.toUpperCase() || '';
          const upperB =
            `(GMT${b?.offset?.standard}) ${b?.name} Time`.toUpperCase() || '';

          if (upperA < upperB) {
            return -1;
          }

          if (upperA > upperB) {
            return 1;
          }

          return 0;
        });
      }
    }
    return this.timeZones.sort((a, b) => {
      const upperA = `(GMT${a?.offset?.standard}) ${a?.name} Time`.toUpperCase() || '';
      const upperB = `(GMT${b?.offset?.standard}) ${b?.name} Time`.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }
}
