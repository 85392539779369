
































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DeleteUserModal extends Vue {
  @Prop({ type: Object, required: true })
  public user!: any;

  @Prop({ type: String, default: 'Are you sure you want to?' })
  public message!: string;
}
