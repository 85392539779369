






import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { constants } from '@/utils/constants';

@Component({
  props: {
    routeName: {
      type: String,
    },
    routeParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
    returnToText: {
      type: String,
      default: '',
    },
  },
})
export default class RdmReturnToBtn extends Vue {
  get RouteName() {
    if (this.$props.routeName) {
      return this.$props.routeName;
    }
    return constants.routes.HOME;
  }

  get ReturnToText() {
    if (this.$props.returnToText) {
      return `to ${this.$props.returnToText}`;
    }
    return '';
  }
}
