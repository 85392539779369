




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RdmBreadcrumb extends Vue {
  private get breadcrumbs() {
    const b = this.$route.meta?.breadcrumb as any[];
    if (b?.length > 1) {
      return b.map((v, i) => {
        return {
          text: this.$i18n.t(v.i18n),
          // TODO: needs fix, actually param logic must be much more complicated
          to:
            v.param && this.$route.params && this.$route.params.id
              ? `${v.url}${this.$route.params.id}`
              : v.url,
          disabled: i === b.length - 1,
          exact: true,
        };
      });
    }
    return undefined;
  }
}
