









































































import { Component, Vue } from 'vue-property-decorator';
import { httpModule } from '@/store/modules/moduleHttp';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { optionModule } from '@/store/modules/moduleOption';
import OptionViewName from '@/components/product/option/view/OptionViewName.vue';
import OptionViewDescription from '@/components/product/option/view/OptionViewDescription.vue';
import OptionViewChannels from '@/components/product/option/view/OptionViewChannels.vue';
import OptionViewLocation from '@/components/product/option/view/OptionViewLocation.vue';
import OptionViewCapacity from '@/components/product/option/view/OptionViewCapacity.vue';
import OptionViewSchedules from '@/components/product/option/view/OptionViewSchedules.vue';
import OptionViewPolicy from '@/components/product/option/view/OptionViewPolicy.vue';
import { companyModule } from '@/store/modules/moduleCompany';

@Component({
  components: {
    OptionViewPolicy,
    OptionViewSchedules,
    OptionViewCapacity,
    OptionViewLocation,
    OptionViewChannels,
    OptionViewDescription,
    OptionViewName,
    RdmReturnToBtn,
  },
})
export default class OptionView extends Vue {
  private routerOptionList = constants.routes.OPTION_LIST;
  private parentName = constants.titles.OPTIONS;

  mounted() {
    if (this.$route.params?.id) {
      httpModule.getOption(this.$route.params?.id).then(() => {
        httpModule.getOptionTripRoutes().then();
        httpModule.getOptionUnitTravelerTypes(this.$route.params?.id).then();
        const id = companyModule.Organization?.ID || '';
        optionModule.setOptionCapacityPool({
          orgId: id,
          optionId: this.$route.params?.id,
          capacityPool: {
            pooled: 0,
            unlimited: false,
            byUnit: {} as Map<string, string>,
          },
        });
        httpModule.getOptionCapacity().then();
      });
    }
  }

  get Loading() {
    return optionModule.Loading;
  }
}
