


















































































import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import { productModule } from '@/store/modules/moduleProduct';
import { channelModule } from '@/store/modules/moduleChannel';
import { httpModule } from '@/store/modules/moduleHttp';
import {
  ChannelBinding,
  ChannelBindingMode,
  ChannelBindingRQ,
  RateCard,
  RateCardChannel,
} from '@/models';
import {
  constructBindingRequests,
  filterChannelBindingRequestsByMode,
} from '@/utils/channelUtils';

@Component({
  components: {},
})
export default class RdmConnectAvailableChannel extends Vue {
  private loading = false;
  private formValid = false;
  private step = 1;
  private selectedRateCard = '';
  private requiredField = requiredFieldRules;
  private bindingRequests = [] as ChannelBindingRQ[];
  private filteredRateCardsForChannel = [] as RateCard[];

  /**
   * on mount
   * - load all rate cards
   * - load all rate card bindings
   * - filter rate cards that are not already connected to the channel
   */
  async mounted() {
    this.loading = true;
    await httpModule.getRateCards();
    await httpModule.getRateCardsChannel(this.Rates);
    this.filterRateCardsForChannel();
    this.loading = false;
  }

  /**
   * destroyed
   * - reset private vars
   */
  destroyed() {
    this.loading = false;
    this.formValid = false;
    this.step = 1;
    this.selectedRateCard = '';
    this.bindingRequests = [] as ChannelBindingRQ[];
    this.filteredRateCardsForChannel = [] as RateCard[];
  }

  confirmResellerAgreement() {
    this.step = 2;
  }

  confirmConfig() {
    this.step = 3;
  }

  get availableRateCards() {
    return this.filteredRateCardsForChannel;
  }

  /**
   * filterRateCardsForChannel - filters the rate cards down to those that
   * are not connected to the channel
   */
  filterRateCardsForChannel() {
    this.filteredRateCardsForChannel = this.Rates.filter((rateCard: RateCard) => {
      let isConnectedToChannel = false;
      rateCard.channels?.forEach((channel: RateCardChannel) => {
        if (channel.id == this.Channel.id) {
          isConnectedToChannel = true;
        }
      });
      return !isConnectedToChannel;
    });
  }

  /**
   * onRateCardSelect
   * - load the rate card
   * - load the SPR data for the rate card
   * - create the lists of bindings to create and update
   */
  async onRateCardSelect() {
    await httpModule.getRateCardSprs(this.selectedRateCard);
    await httpModule.getRateCard(this.selectedRateCard);

    // construct the binding requests
    // note: we don't care about any current bindings here since this will
    // always be creating new bindings
    const currentBindings = Array<ChannelBinding>();
    this.bindingRequests = constructBindingRequests(
      this.RateCard,
      this.RateCardSpr,
      currentBindings,
      this.Channel,
    );
  }

  /**
   * confirmProceed
   * - iterate through and create any channel bindings
   * - iterate through and update any channel bindings
   */
  async confirmProceed() {
    channelModule.startLoading();

    // create any bindings that need to be created
    const createRQs = filterChannelBindingRequestsByMode(
      this.bindingRequests,
      ChannelBindingMode.CREATE,
    );
    for (const binding of createRQs) {
      await channelModule.createChannelBinding(binding);
    }

    // update any bindings that need to be updated
    const updateRQs = filterChannelBindingRequestsByMode(
      this.bindingRequests,
      ChannelBindingMode.UPDATE,
    );
    for (const binding of updateRQs) {
      await channelModule.updateChannelBinding(binding);
    }

    channelModule.finishLoading();
    this.cancelModal();
  }

  cancelModal() {
    this.$emit('cancel');
  }

  get Loading() {
    return channelModule.Loading;
  }

  get Channel() {
    return channelModule.ChannelToBind;
  }

  get Rates() {
    return channelModule.RateCards;
  }

  get RateCard() {
    return channelModule.RateCard;
  }

  get Products() {
    return productModule.Products;
  }

  get RateCardSpr() {
    return channelModule.RateCardSpr;
  }
}
