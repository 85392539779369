







































import { Component, Vue } from 'vue-property-decorator';
import Dynamsoft from '@/Dynamsoft';

@Component
export default class RdmBarcodeScanner extends Vue {
  private bDestroyed = false;

  private scanner: any = null;

  async mounted() {
    // todo: disable scanner if on a non-mobile device
    try {
      this.scanner = await Dynamsoft.createInstance();

      if (this.bDestroyed) {
        this.scanner.destroy();
        this.$emit('close-scanner', true);
        return;
      }

      const settings = await this.scanner.getRuntimeSettings();
      settings.region.regionMeasuredByPercentage = 1;
      settings.region.regionLeft = 20;
      settings.region.regionTop = 20;
      settings.region.regionRight = 80;
      settings.region.regionBottom = 80;
      await this.scanner.updateRuntimeSettings(settings);

      // await this.scanner.updateRuntimeSettings('speed');

      const scanSettings = await this.scanner.getScanSettings();
      // disregard duplicated results found in a specified time period
      scanSettings.duplicateForgetTime = 3000;
      // set a scan interval so the library may release the CPU from time to time
      scanSettings.intervalTime = 300;
      scanSettings.autoFocus = true;
      // scanSettings.autoZoom = true;

      await this.scanner.updateScanSettings(scanSettings);

      await this.scanner.updateVideoSettings({
        // todo: width/height should take into account the device's resolution
        video: { width: 1280, height: 720, facingMode: 'environment' },
      });

      await this.scanner.setUIElement(this.$el);
      this.scanner.onFrameRead = (results: any) => {
        if (results.length) {
          if (results.length > 0 && results[0].exception) {
            // console.error('exception', results);
            // todo: log error to backend
            this.$emit('close-scanner', true);
          } else {
            this.$emit('receive-result', results);
          }
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.scanner.onUnduplicatedRead = (txt: any, result: any) => {
        this.$emit('close-scanner', true);
      };
      await this.scanner.open();

      // todo: continue adjusting stroke color of canvas box
      // if (this.$refs && this.$refs.opacityCanvas) {
      //   const { opacityCanvas } = this.$refs;
      //   const canvasCtx = <HTMLCanvasElement>opacityCanvas;
      //   const ctx = canvasCtx.getContext('2d');
      //   if (ctx) {
      //     ctx.strokeStyle = '#FF0000';
      //     ctx.fillStyle = '#00FF00';
      //     ctx.save();
      //   }
      // }

      if (this.bDestroyed) {
        this.scanner.destroy();
        this.$emit('close-scanner', true);
        return;
      }
    } catch (ex) {
      this.$emit('close-scanner', true);
      // todo send error to backend
      // console.error(ex);
    }
  }

  closeScanner() {
    this.$emit('close-scanner', true);
  }

  beforeDestroy() {
    this.bDestroyed = true;
    if (this.scanner) {
      this.scanner.destroyContext();
    }
  }
}
