import Vue from 'vue';
import Vuetify from 'vuetify';
// import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: '#2b3278', // '#000B4E',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#D32F2F',
        info: '#2196F3',
        success: '#3CBE6A', // '#50d565', // '#4CAF50',
        warning: '#F0CF71', // '#FFC107',
        grey: '#FAFAFA',
        gray: '#C8C8C8',
        silver: '#DEE2E6',
        white: '#FFFFFF',
        blue: '#0076DF',
        inactive: '#D6D5E3',
        black: '#202020',
        background: '#f0efeb',
        highlight1: '#fca311',
        highlight2: '#3A79BA',
        highlight3: '#489DCB',
        highlight4: '#DB1D4A',
        subtle1: '#0C334C',
        rdmPrimary: '#2b3278',
        rdmSecondary: '#68BFC8',
        rdmTertiary: '#F88075',
        rdmSilver: '#D6D5E3',
        rdmError: '#EB3860',
        rdmHighlight: '#5DABF0',
        rdmBlack: '#303133',
        rdmGrey: '#8d8d8f',
        rdmLightGrey: '#dadada',
        rdmGreen: '#00D3A3',
        rdmYellow: '#F9F871',
        v_primary_base: '#29357A',
      },
      dark: {
        background: '#424242',
        primary: '#0C334C',
        white: '#616161',
        accent: '#68BFC8',
        success: '#616e7c',
        rdmGrey: '#8d8d8f',
        v_primary_base: '#29357A',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
