

































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RdmWelcomeWizard extends Vue {
  private overlay = true;
  private agreeTC = false;
  private welcomeModel = 0;
  private maxModal = 5;

  nextSlide() {
    this.welcomeModel++;
  }

  acceptTerms() {
    // const supplier = companyModule.Supplier;
    // companyModule.updateCompany({
    //   company: {
    //     ...company,
    //     ext: {
    //       terms_conds: true,
    //       base64img: companyModule.Icon ?? undefined,
    //       travelerTypes: companyModule.TravelerTypes ?? undefined,
    //       guestTypes: companyModule.GuestTypes ?? undefined,
    //     },
    //   },
    // });
    // uiModule.toggleWelcomeWizard(false);
  }

  closeWizard() {
    // uiModule.toggleWelcomeWizard(false);
  }
}
