import {
  ChannelBinding,
  ChannelBindingMode,
  ChannelBindingRQ,
  ChannelSpr,
  RateCard,
} from '@/models';
import { Channel } from '@/models/ChannelModels';

export function generateSPRProductID(spr: ChannelSpr): string {
  return `${spr.supplier.id}-${spr.product.id}`;
}

export function generateBindingProductID(binding: ChannelBinding): string {
  return `${binding.supplier.id}-${binding.product.id}`;
}

/**
 * hasChannelBindings - checks to see if there are any bindings for the SPR
 * @param spr
 * @param bindings
 */
export function hasChannelBindings(spr: ChannelSpr, bindings: ChannelBinding[]): boolean {
  const idSet = new Set(bindings.map((binding) => generateBindingProductID(binding)));
  return idSet.has(generateSPRProductID(spr));
}

/**
 * constructBindingRequests - examines the current bindings and SPR data to create
 * a set of channel binding requests
 * @param sprs
 * @param bindings
 * @param channel
 */
export function constructBindingRequests(
  rateCard: RateCard,
  sprs: ChannelSpr[],
  bindings: ChannelBinding[],
  channel: Channel,
): ChannelBindingRQ[] {
  const bindingRequests = new Map<string, ChannelBindingRQ>();
  for (const spr of sprs) {
    const sprID = generateSPRProductID(spr);
    if (hasChannelBindings(spr, bindings)) {
      for (const binding of bindings) {
        if (!bindingRequests.has(sprID)) {
          const rq: ChannelBindingRQ = {
            mode: ChannelBindingMode.UPDATE,
            channelID: binding.channel.id || '',
            channelName: binding.channel.name || '',
            supplierID: spr.supplier.id,
            productID: spr.product.id,
            rateIDs: [...binding.rateIds, spr.rate.id],
            rateCardID: rateCard.id,
            validFrom: '',
            validUntil: '',
          };
          bindingRequests.set(sprID, rq);
        } else {
          bindingRequests.get(sprID)?.rateIDs.push(spr.rate.id);
        }
      }
    } else {
      if (!bindingRequests.has(sprID)) {
        const rq: ChannelBindingRQ = {
          mode: ChannelBindingMode.CREATE,
          channelID: channel.id || '',
          channelName: channel.name || '',
          supplierID: spr.supplier.id,
          productID: spr.product.id,
          rateIDs: [spr.rate.id],
          rateCardID: rateCard.id,
          validFrom: '',
          validUntil: '',
        };
        bindingRequests.set(sprID, rq);
      } else {
        bindingRequests.get(sprID)?.rateIDs.push(spr.rate.id);
      }
    }
  }
  return [...bindingRequests.values()];
}

/**
 * filterChannelBindingRequestsByMode - returns the set of requests for the mode
 * @param bindings
 * @param mode
 */
export function filterChannelBindingRequestsByMode(
  bindings: ChannelBindingRQ[],
  mode: ChannelBindingMode,
): ChannelBindingRQ[] {
  return bindings.filter((binding) => {
    return binding.mode == mode;
  });
}

/**
 * filterBindingsByChannelID - filters the bindings that match the channel ID
 * @param channelID
 * @param bindings
 */
export function filterBindingsByChannelID(
  channelID: string,
  bindings: ChannelBinding[],
): ChannelBinding[] {
  const filtered = bindings.filter((binding: ChannelBinding) => {
    return binding.channel.id === channelID;
  });
  return filtered;
}
