















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RequestConnectionWizardStage4 extends Vue {
  private addProductModel = false;

  private status = '';

  private active = 'Active';

  private draft = 'Draft';

  next() {
    this.$emit('next-step', true);
  }
}
