




















































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RateCardCreateOfferModal from '@/components/rateCard/create/RateCardCreateOfferModal.vue';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { requiredFieldRules } from '@/utils/validation-rules';
import { channelModule } from '@/store/modules/moduleChannel';
import { tablePaginationOptions } from '@/utils/helpers';
import _ from 'lodash';
import { pricingModule } from '@/store/modules/modulePricing';
import { httpModule } from '@/store/modules/moduleHttp';
import faker from 'faker';
import { Address, CompanyRate, Offer } from '@/models';
import { productModule } from '@/store/modules/moduleProduct';
import { companyModule } from '@/store/modules/moduleCompany';
import { constants } from '@/utils/constants';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { profileModule } from '@/store/modules/moduleProfile';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';

@Component({
  components: {
    RdmCollapsableCard,
    RdmCollapsableControlCard,
    RateCardCreateOfferModal,
  },
})
export default class RateCardCreateDetails extends Vue {
  private offerSearch = '';
  private selectAll = false;
  private name = '';
  private offerId = '';
  private requiredFieldRules = requiredFieldRules;
  private modalIsOpen = false;
  private adjustPrice = false;
  private selectedOffers = [] as any;
  private offersNotSelected = false;
  private startDateMenu = false;
  private endDateMenu = false;
  private saving = false;
  private displayDateError = false;
  private orgTypeRC = constants.ORG_TYPE_RC;
  private orgTypeTS = constants.ORG_TYPE_TS;
  private rateCardOffersHeaders: Array<Record<string, any>> = [
    { text: 'Offer', align: 'left', sortable: true, value: 'name' },
  ];
  private offerCapacitiesHeaders: Array<Record<string, any>> = [
    { text: 'Pricing Sets', align: 'left', sortable: true, value: 'name' },
    { text: 'Price Types', align: 'left', sortable: true, value: 'capacityLimit' },
    { text: 'Effective Dates', align: 'left', sortable: true, value: 'validFrom' },
  ];

  async mounted() {
    await httpModule.getOffers();
    this.adjustPrice = channelModule.AdjustPriceState;
  }

  destroyed() {
    channelModule.setFormDetailsState(false);
    channelModule.setCreateRateCardDetailsForm({});
    this.selectedOffers = [];
    this.name = '';
    this.adjustPrice = false;
  }

  discard() {
    this.$router.push({ name: constants.routes.CHANNEL_RATE_CARDS });
  }

  scrollToErrorBanner() {
    this.$nextTick(() =>
      document.getElementById('error_banner')?.scrollIntoView({ behavior: 'smooth' }),
    );
  }

  toggleSelectAll() {
    if (this.selectAll) this.selectedOffers = this.CompanyRates;
    else this.selectedOffers = [];
  }

  async toggleOffer(id: string) {
    if (this.IsTicketSystemEdit) {
      return;
    }

    const updatedRates = this.CompanyRates.map((rate: CompanyRate) => {
      if (id === rate.id)
        return {
          ...rate,
          open: !rate.open,
        };
      return {
        ...rate,
        open: false,
      };
    });
    await httpModule.getOffer({ offerId: id, withCapacity: true });
    if (this.Offer.capacity?.length) {
      await httpModule.getOfferCapacity(this.Offer.capacity);
    }
    channelModule.setCompanyRates(updatedRates);
  }

  unitIdToTravelerName(id: string) {
    const i = this.DisplayTravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.DisplayTravelerTypes[i].text;
    }
    return '';
  }

  getPrimaryAddress(product: any): string {
    const id = product.id;
    const p = this.Products.find((p: any) => p.id === id);
    if (p) {
      const address = this.CompanyAddresses.find((a: Address) => a.id === p.addressId);
      if (address) {
        return `${address.name}`;
      }
    }

    return '';
  }

  getSupplierNameFromRate(rate: CompanyRate): string {
    if (rate && rate.supplier && rate.supplier.name) {
      return rate.supplier.name;
    }
    return '';
  }

  // @Watch('selectedOffers')
  // offerSelected(value: Array<CompanyRate>, oldValue: Array<CompanyRate>) {
  //   console.log('selected', value, oldValue);
  //   // todo query offer pricing list
  //   // todo loop offer pricings to get earliest validFrom and latest ValidUntil
  //   // this.Offers.filter((o: Offer) => {
  //   //   o.id
  //   // });
  //   // this.Offers.filter()
  // }

  clearSelectedDates() {
    this.displayDateError = false;
    this.DetailsForm.validFrom = '';
    if (this.DetailsForm.validUntil) {
      this.DetailsForm.validUntil = '';
    }
  }

  get RateCard() {
    return channelModule.RateCard;
  }

  get DisplayTravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get OfferCapacityList() {
    return pricingModule.OfferCapacityList;
  }

  get Offer() {
    return pricingModule.Offer;
  }

  get DetailsIsOpen() {
    return channelModule.FormDetailsState;
  }
  get DetailsForm(): any {
    if (channelModule.CreateRateCardDetailsForm) {
      return channelModule.CreateRateCardDetailsForm;
    }
    return {};
  }

  get CreateRateCardFormState() {
    return channelModule.CreateRateCardFormState;
  }

  set CreateRateCardFormState(state: boolean) {
    channelModule.setCreateRateCardFormState(state);
  }

  get DateFromFormatted() {
    if (
      this.DetailsForm.validFrom &&
      this.DetailsForm.validFrom.length > 0 &&
      _.isDate(new Date(this.DetailsForm.validFrom))
    ) {
      const date = new Date(this.DetailsForm.validFrom);
      return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
    }
    return '';
  }

  get DateUntilFormatted() {
    if (
      this.DetailsForm.validUntil &&
      this.DetailsForm.validUntil.length > 0 &&
      _.isDate(new Date(this.DetailsForm.validUntil))
    ) {
      const date = new Date(this.DetailsForm.validUntil);
      return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
    }
    return '';
  }

  get paginationOptions() {
    return tablePaginationOptions(this.Offers);
  }

  get Offers() {
    return companyModule.Company.orgType === this.orgTypeRC
      ? pricingModule.ValidatedOffers.filter((offer: Offer) => !offer.error)
      : pricingModule.Offers;
  }

  get CompanyRates() {
    return channelModule.CompanyRates;
  }

  get Products() {
    return productModule.Products;
  }

  get CompanyAddresses() {
    return companyModule.Addresses;
  }

  get ValidatedOffers() {
    return pricingModule.ValidatedOffers;
  }

  get OffersDateRange() {
    return channelModule.OfferDateRange;
  }
  get Company() {
    return companyModule.Company;
  }

  get IsTicketingSystem() {
    return this.Company.orgType === constants.ORG_TYPE_TS;
  }

  get Description() {
    return `Select an offer or offers with the same price type to apply to this rate card. ${
      !this.IsTicketingSystem
        ? 'Please note that offers shown below that failed validation in the previous step are representing the last validated version.'
        : ''
    }`;
  }

  get IsTicketSystemEdit() {
    return profileModule.IsTicketingEditRole;
  }

  async checkDates() {
    this.saving = true;
    try {
      if (Array.isArray(this.selectedOffers)) {
        const offers = this.getValidOfferIds();
        await httpModule.getOfferDateRanges(offers);
        if (
          this.OffersDateRange.validFrom > this.DetailsForm.validFrom ||
          (this.DetailsForm.validUntil === '' &&
            this.OffersDateRange.validUntil &&
            this.OffersDateRange.validUntil.length > 0) ||
          (!this.DetailsForm.validUntil &&
            this.OffersDateRange.validUntil &&
            this.OffersDateRange.validUntil.length > 0) ||
          (this.OffersDateRange.validUntil &&
            this.OffersDateRange.validUntil.length > 0 &&
            this.DetailsForm.validUntil &&
            this.DetailsForm.validUntil.length > 0 &&
            this.OffersDateRange.validUntil < this.DetailsForm.validUntil)
        ) {
          this.displayDateError = true;
          this.scrollToErrorBanner();
        } else {
          await this.validate();
        }
      }
    } catch (e) {
      // todo send alert to backend
    } finally {
      this.saving = false;
    }
  }

  getValidOfferIds() {
    const offerIds = [] as any;
    this.selectedOffers.map((item: any) => {
      if (item.offer.id && item.offer.id.length) {
        offerIds.push(item.offer.id);
      }
    });
    return offerIds;
  }

  async validate() {
    this.displayDateError = false;
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      this.offersNotSelected = this.selectedOffers.length === 0;
      if (isValid && !this.offersNotSelected) {
        this.saving = true;
        this.DetailsForm.offers =
          companyModule.Company.orgType === constants.ORG_TYPE_TS
            ? []
            : this.getValidOfferIds();
        await httpModule.updateRateCard({
          id: faker.datatype.uuid(),
          name: this.DetailsForm.name,
          offers: this.DetailsForm.offers,
          validFrom: this.DetailsForm.validFrom,
          validUntil: this.DetailsForm.validUntil,
        });
        _.delay(async () => {
          await this.selectedOffers.map(async (item: any) => {
            await httpModule.storeRateCardSpr({
              rateCardId: channelModule.RateCard.id,
              sprId: faker.datatype.uuid(),
              payload: {
                supplier: {
                  id: item.supplier.id,
                  name: item.supplier.name,
                },
                product: {
                  id: item.product.id,
                  name: item.product.name,
                },
                rate: {
                  id: item.rate.id,
                  name: item.rate.name,
                },
                rc: {
                  offer: item.offer,
                  product: {
                    id: '',
                    name: '',
                  },
                  option: {
                    id: '',
                    name: '',
                  },
                },
              },
            });
          });
          this.saving = false;
          channelModule.setAdjustPriceState(this.adjustPrice);
          channelModule.setFormDetailsState(false);
          channelModule.setFormPriceAdjustmentsState(true);
          if (!this.adjustPrice) channelModule.setFormConnectChanelState(true);
        }, 3000);
      } else {
        this.scrollToErrorBanner();
      }
    }
  }

  openModal() {
    this.modalIsOpen = true;
  }

  closeModal() {
    this.modalIsOpen = false;
  }

  toggleDetailsOpen() {
    channelModule.setFormDetailsState(!this.DetailsIsOpen);
  }
}
