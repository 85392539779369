var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ConnectedChannels && _vm.ChannelAccess)?_c('div',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"white-space":"pre-line"},attrs:{"loading":_vm.Loading,"headers":_vm.connectedChannelsHeaders,"items":_vm.ConnectedChannels,"items-per-page":10,"search":_vm.SearchText,"hide-default-footer":(_vm.ConnectedChannels && _vm.ConnectedChannels.length === 0) || !_vm.ConnectedChannels,"footer-props":{
        itemsPerPageOptions: _vm.paginationOptions,
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
      }},scopedSlots:_vm._u([{key:"item.channel",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.channel.name)+" ")]}},{key:"item.rateCards",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayRatesName(item.rateCards))+" ")]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){return _vm.toConnectedChannelDetails(item)}}},[_vm._v(" "+_vm._s(_vm.$t('View'))+" ")]),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){return _vm.removeConnection(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Remove'))+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-left"},[_vm._v(" To connect to a channel, you must add a rate card. Click "),_c('router-link',{attrs:{"to":{ name: _vm.routeCreateRateCard }}},[_vm._v("here")]),_vm._v(" to add one. ")],1)]},proxy:true}],null,false,2275327019)})],1):_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"plain":"","color":"primary"},on:{"click":_vm.goToFeedback}},[_vm._v(" Request for making channels visible ")])],1),_c('rdm-generic-message-modal',{attrs:{"type":"warning","title":"Remove Connection","message":("Remove all connections to " + (this.selectedChannelName) + "?"),"state":_vm.removeConnectionDialog},on:{"cancel":_vm.cancelRemoveConnection,"proceed":_vm.proceedRemoveConnection}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }