





















































































import { Component, Vue } from 'vue-property-decorator';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import { Option } from '@/models';
import RdmUnsavedChangesModal from '@/components/common/alert/RdmUnsavedChangesModal.vue';
import RdmMessage from '@/components/common/alert/RdmMessage.vue';
import { constants } from '@/utils/constants';
import { productModule } from '@/store/modules/moduleProduct';
import { httpModule } from '@/store/modules/moduleHttp';
import { optionModule } from '@/store/modules/moduleOption';
import { tablePaginationOptions } from '@/utils/helpers';

@Component({
  components: {
    RdmStatusIndicator,
    RdmUnsavedChangesModal,
    RdmMessage,
  },
})
export default class OptionListingSummary extends Vue {
  private routeOptionDetails = constants.routes.OPTION_DETAILS;
  private routeOptionCreate = constants.routes.OPTION_CREATE;
  private optionToRemove = {} as Option;
  private modalIsOpen = false;
  private optionsHeaders: Array<Record<string, any>> = [
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'internalName',
    },
    { text: '', value: 'view', align: 'right', width: 300, sortable: false },
  ];

  mounted() {
    httpModule.loadOptions();
  }

  private openModal(option: Option) {
    this.optionToRemove = option;
    this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  proceedRemoveOption() {
    this.closeModal();
    httpModule
      .removeOption(this.optionToRemove.id)
      .then()
      .finally(() => {
        // todo send alert
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filterProducts(value: string, search: string, item: any) {
    return (
      value != null &&
      search != null &&
      typeof value === 'string' &&
      value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }

  get Loading() {
    return optionModule.Loading;
  }

  get optionsList() {
    return optionModule.Options.filter((o) => {
      return o.id && o.id.trim().length > 0;
    });
  }

  get productSearch() {
    return productModule.SearchText;
  }

  set productSearch(search: string) {
    productModule.setSearchText(search);
  }

  get paginationOptions() {
    return tablePaginationOptions(this.optionsList);
  }
}
