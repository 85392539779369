class RegistrationPhone {
  phoneCountryCode = '';

  phoneNumber = '';
}

class RegistrationAddress {
  street1 = '';

  street2 = '';

  city = '';

  state = '';

  zip = '';

  country = '';
}

class RegistrationBillingAddress {
  billingStreet1 = '';

  billingStreet2 = '';

  billingCity = '';

  billingState = '';

  billingZip = '';

  billingCountry = '';
}

class RegistrationBilling {
  billingFirstName = '';

  billingLastName = '';

  billingPhone = '';

  billingEmail = '';

  billingAddress: RegistrationBillingAddress = new RegistrationBillingAddress();
}

export class RegistrationModel {
  companyName = '';

  contactEmail = '';

  contactFirstName = '';

  contactLastName = '';

  phoneNumber: RegistrationPhone = new RegistrationPhone();

  companyUrl = '';

  ticketing = '';

  contactPreference = 'contact_both';

  address: RegistrationAddress = new RegistrationAddress();

  billing: RegistrationBilling = new RegistrationBilling();
}
