






import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';

@Component({
  components: {},
})
export default class OptionCreatePricingView extends Vue {
  private openOptionPricing = false;

  toggleOptionPricing() {
    this.openOptionPricing = !this.openOptionPricing;
  }

  discard() {
    this.$router.push({ name: constants.routes.OPTION_LIST }).catch();
  }

  nextStepValidate4(done: boolean) {
    if (this.$refs?.pricingForm) {
      const form: any = this.$refs.pricingForm;
      const isValid = form.validate();
      if (isValid) {
        if (done) {
          // this.addOptionDetails();
        } else {
          // this.step4NextStage();
        }
      }
    }
  }

  get HasOwnData() {
    return true;
    // return (
    //     this.shareLocation === 'yes' ||
    //     this.sharePolicy === 'yes' ||
    //     this.sharePricing === 'yes' ||
    //     this.shareSchedule === 'yes'
    // );
  }

  get TravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get Schedules() {
    return [];
  }
}
