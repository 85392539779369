








import { Component, Vue } from 'vue-property-decorator';
import { OperatorLocationAddress } from '@/models';
import _ from 'lodash';

declare const google: any;

@Component({
  props: {
    lat: {
      type: Number,
      default: 0,
    },
    long: {
      type: Number,
      default: 0,
    },
    address: {
      type: Object,
      default: () => {
        return new OperatorLocationAddress();
      },
    },
  },
})
export default class MapBox extends Vue {
  /* eslint-disable */
  private map: any;

  private displayableAddress: OperatorLocationAddress = new OperatorLocationAddress();

  private mapLat: number = 0;

  private mapLong: number = 0;

  private center: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0,
  };

  private mapOptions = {};

  mounted() {
    this.mapLat = this.$props.lat;
    this.mapLong = this.$props.long;
    this.displayableAddress = this.$props.address;
    const mapContainer = this.$refs.googleMap as Element;
    this.map = new google.maps.Map(mapContainer, {
      center: { lat: this.mapLat, lng: this.mapLong },
      zoom: 12,
      draggable: true,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      disableDefaultUI: false,
    });

    this.setMapCenter();
    this.setMapPin();

    // todo test if this can be removed
    // const place = new google.maps.places.PlacesService(this.map);
    // const request = {
    //   query: `${this.displayableAddress.streetAddress},${this.displayableAddress.city},${this.displayableAddress.stateProvince}`,
    //   fields: ['name', 'geometry'],
    //   locationBias: 'IP_BIAS',
    // };
    //
    // place.findPlaceFromQuery(request, (results: any, status: any) => {
    //   if (status === google.maps.places.PlacesServiceStatus.OK) {
    //     this.mapLat = results[0].geometry.location.lat();
    //     this.mapLong = results[0].geometry.location.lng();
    //     // this.createMarker(results[0]);
    //     this.map.setCenter(results[0].geometry.location);
    //     console.log('set map center');
    //     this.setMapCenter();
    //     console.log('set map pin');
    //     this.setMapPin();
    //   }
    // });
  }

  createMarker(place: any) {
    const marker = new google.maps.Marker({
      map: this.map,
      position: place.geometry.location,
    });
  }

  setMapCenter() {
    this.center.lat = this.mapLat;
    this.center.lng = this.mapLong;
    this.mapOptions = {
      center: this.center,
      zoom: 17,
      draggable: true,
      mapTypeControl: false,
      streetViewControl: true,
      zoomControl: true,
      disableDefaultUI: false,
    };
  }

  setMapPin() {
    try {
      if (window.google && window.google.maps) {
        this.map = new window.google.maps.Map(
          this.$refs['googleMap'] as HTMLElement,
          this.mapOptions,
        );
        if (_.isUndefined(this.map)) {
          return;
        }
        const mappingObject = { map: this.map, center: this.center };

        const map = mappingObject.map;
        const center = mappingObject.center;
        if (map && center) {
          new window.google.maps.Marker({
            position: center,
            map: map,
            animation: window.google.maps.Animation.DROP,
            title: 'me',
          });
        }
      }
    } catch {
      // todo send alert
    }
  }
}
