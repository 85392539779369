


















































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import { constants } from '@/utils/constants';
import { httpModule } from '@/store/modules/moduleHttp';
import { appModule } from '@/store/modules/moduleApp';
import { DeliveryFormats } from '@/models';
import _ from 'lodash';

@Component({
  components: {},
  props: {
    option: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class TicketFormatViewEdit extends Vue {
  private ticketFormatModel = [] as Array<DeliveryFormats> | undefined;
  private baseTicketFormatModel = [] as Array<DeliveryFormats> | undefined;
  private saving = false;
  private ticketFormats = [
    { text: 'QR_Code', value: 'QR_CODE' },
    { text: 'EAN13', value: 'EAN13' },
    { text: 'CODE39', value: 'CODE39' },
    { text: 'CODE128', value: 'CODE128' },
    { text: 'UPCA', value: 'UPCA' },
    { text: 'TEXT', value: 'TEXT' },
  ];

  mounted() {
    this.$root.$on(constants.events.PRODUCT_LOAD_COMPLETE, () => {
      this.ticketFormatModel = _.cloneDeep(this.DeliveryFormats);
      this.baseTicketFormatModel = _.cloneDeep(this.DeliveryFormats);
    });
  }

  toggleTicketFormatEdit() {
    this.ticketFormatModel = _.cloneDeep(this.DeliveryFormats || []);

    if (!this.EditModeDeliveryFormat) {
      this.baseTicketFormatModel = _.cloneDeep(this.DeliveryFormats || []);
    }
    productModule.setEditModeDeliveryFormats(!this.EditModeDeliveryFormat);
  }

  saveChanges() {
    this.saving = true;
    productModule.setDeliveryFormats(this.ticketFormatModel || []);
    const product = _.cloneDeep(productModule.Product);
    const ticketDelivery = product.ticketDelivery;

    if (ticketDelivery && ticketDelivery.deliveryFormats) {
      ticketDelivery.deliveryFormats = this.ticketFormatModel;
      product.ticketDelivery = ticketDelivery;
      productModule.setProduct(product);
    }
    httpModule
      .updateProduct()
      .then(() => {
        productModule.setEditModeDeliveryFormats(false);
      })
      .catch(() => {
        // todo send alert
      })
      .finally(() => {
        this.saving = false;
        this.ticketFormatModel = _.cloneDeep(this.DeliveryFormats || []);
        this.baseTicketFormatModel = _.cloneDeep(this.DeliveryFormats || []);
      });
  }

  cancel() {
    this.ticketFormatModel = _.cloneDeep(this.baseTicketFormatModel);
    this.toggleTicketFormatEdit();
  }

  get Product() {
    return productModule.Product;
  }

  get EditModeDeliveryFormat() {
    return productModule.EditModeDeliveryFormats;
  }

  get DeliveryFormatCodes() {
    return appModule.Codes.deliveryFormats?.map((d) => {
      return { text: d.value.replaceAll('_', ' '), value: d.value };
    });
  }

  get DeliveryFormats() {
    // return productModule.DeliveryFormats;
    return this.Product?.ticketDelivery?.deliveryFormats || [];
  }

  set DeliveryFormats(formats: DeliveryFormats[] | undefined) {
    // productModule.setDeliveryFormats(formats);
    if (this.Product && this.Product.ticketDelivery?.deliveryFormats) {
      this.Product.ticketDelivery.deliveryFormats = formats;
    }
  }
}
