











import { Vue, Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'rdm-sidebar-item',
})
export default class RdmSidebarItem extends Vue {
  @Prop({ default: null })
  public title!: string;

  @Prop({ default: null })
  public lang!: string;

  @Prop()
  public icon!: string;

  @Prop({ default: '#' })
  public href!: string;

  @Prop({ default: null })
  public to!: string;

  @Prop({ default: null })
  public slug!: string;

  @Prop({ default: [String, Number] })
  public index!: string;

  @Prop({ default: '_self' })
  public target!: string;

  @Prop({ default: false })
  public isDisabled!: boolean;

  public activeLink = false;

  get canSee() {
    return true;
  }

  get active() {
    return {
      activeState: this.activeLink,
      inactiveState: !this.activeLink,
    };
  }

  @Watch('$route', { immediate: true, deep: true })
  checkIsActive() {
    if (this.to) {
      this.activeLink = !!(
        (this.$router.currentRoute.path.includes(this.to.toString()) && this.to) ||
        (this.$route.meta && this.$route.meta.parent === this.slug)
      );
    }
  }

  updated() {
    this.checkIsActive();
  }
}
