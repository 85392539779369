var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.PageTitle))])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('v-select',{attrs:{"items":_vm.locations,"outlined":"","color":"primary","label":'Filter by Location',"hide-details":"","background-color":"white","menu-props":{ bottom: true, offsetY: true }},on:{"change":_vm.changeLocation}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Search'),"append-icon":"mdi-magnify","background-color":"white","clearable":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('el-select',{staticClass:"role-filter-offset full-width-important",attrs:{"collapse-tags":"","placeholder":_vm.$t('UserManagementPage.Permissions')},on:{"change":_vm.clearValues},model:{value:(_vm.dateRangeType),callback:function ($$v) {_vm.dateRangeType=$$v},expression:"dateRangeType"}},_vm._l((_vm.dateSearchTypeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"3"}},[_c('el-date-picker',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.dateRangeType === 'dateRange' ||
                  _vm.dateRangeType === 'week' ||
                  _vm.dateRangeType === 'month'
                ),expression:"\n                  dateRangeType === 'dateRange' ||\n                  dateRangeType === 'week' ||\n                  dateRangeType === 'month'\n                "}],staticClass:"full-width-important",attrs:{"disabled":_vm.dateRangeType === 'week' || _vm.dateRangeType === 'month',"type":"daterange","align":"right","unlink-panels":"","range-separator":"-","start-placeholder":_vm.$t('RedemptionReportPage.StartDate'),"end-placeholder":_vm.$t('RedemptionReportPage.EndDate'),"format":"MM/dd/yyyy","value-format":"yyyy-MM-dd","picker-options":_vm.options},on:{"change":function($event){return _vm.changeDates('range')}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('el-date-picker',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.dateRangeType === 'fromDate' ||
                  _vm.dateRangeType === 'untilDate' ||
                  _vm.dateRangeType === 'today'
                ),expression:"\n                  dateRangeType === 'fromDate' ||\n                  dateRangeType === 'untilDate' ||\n                  dateRangeType === 'today'\n                "}],staticClass:"full-width-important",attrs:{"disabled":_vm.dateRangeType === 'today',"type":"date","format":"MM/dd/yyyy","value-format":"yyyy-MM-dd","placeholder":_vm.$t('RedemptionReportPage.PickADay')},on:{"change":function($event){return _vm.changeDates('dateSelect')}},model:{value:(_vm.datePick),callback:function ($$v) {_vm.datePick=$$v},expression:"datePick"}})],1),_c('v-col',{staticClass:"text-left pl-0",attrs:{"cols":"1"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],attrs:{"icon":"","color":"primary","x-large":""},on:{"click":_vm.refresh}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-refresh")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}],attrs:{"text":"","block":"","color":"primary"},on:{"click":_vm.refresh}},[_vm._v(" "+_vm._s(_vm.$t('Global.Refresh'))+" ")])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary",attrs:{"depressed":""},on:{"click":_vm.download}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")]),_vm._v("Download Report ")],1)]}}])},[_c('span',[_vm._v("Be advised, the exported report will not be grouped by location.")])])],1)],1),_c('v-row',_vm._l((_vm.data),function(supplier,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[(supplier)?_c('v-card-title',[_vm._v(" "+_vm._s(supplier.name))]):_vm._e(),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.reportHeaders,"items":supplier ? supplier.items : [],"items-per-page":10,"search":_vm.search,"footer-props":{
            itemsPerPageOptions: _vm.paginationOptions(supplier ? supplier.items : []),
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
          }}})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }