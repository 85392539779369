import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../../index';
import inventoryClient from '../../../api/inventory/InventoryClient';
import { CommonErrHandlers } from '../../../api/ErrHandlers';
import { AlertType } from '../../../utils/alert';
import {
  OppPricing,
  PriceSchedule,
  Pricing,
  PricingWithTaxes,
  Supplier,
  SupplierLocation,
  SupplierWrapper,
  TaxesAndFees,
} from '../../../api/inventory/InventoryModels';
import { map } from 'rxjs/operators';
import { appModule } from '@/store/modules/moduleApp';
import { codes } from '@/utils/codeConstants';
import { companyModule } from '@/store/modules/moduleCompany';
import { pricingModule } from '@/store/modules/modulePricing';

@Module({
  dynamic: true,
  store,
  name: 'inventory',
  namespaced: true,
})
class InventoryModule extends VuexModule {
  /* DATA */

  private loading = false;
  private pricings = [] as Array<OppPricing>;
  private priceSchedules = [] as Array<PriceSchedule>;
  private selectedPriceSchedule = {} as PriceSchedule;
  private searchText = '';
  private locationSearchText = '';
  private contactSearchText = '';
  private suppliers = [] as Array<SupplierWrapper>;
  private supplier = {} as Supplier;
  private locations = [] as Array<SupplierLocation>;
  private selectedLocation = {} as SupplierLocation;
  private companyLoading = false;
  private taxesAndFees = [] as Array<TaxesAndFees>;
  private singleTaxAndFee = {} as TaxesAndFees;

  /* GETTERS */

  get Loading(): boolean {
    return this.loading;
  }

  get Pricings() {
    return this.pricings;
  }

  get PriceSchedules() {
    return this.priceSchedules;
  }

  get PriceSchedule() {
    return this.selectedPriceSchedule;
  }

  get SearchText() {
    return this.searchText;
  }

  get SearchTextLocation() {
    return this.locationSearchText;
  }

  get SearchTextContact() {
    return this.contactSearchText;
  }

  get Suppliers() {
    return this.suppliers;
  }

  get Supplier() {
    return this.supplier;
  }

  get Locations() {
    return this.locations;
  }

  get SelectedLocation() {
    return this.selectedLocation;
  }

  get CompanyLoading() {
    return this.companyLoading;
  }

  get TaxesAndFees() {
    return this.taxesAndFees;
  }

  get SingleTaxAndFee() {
    return this.singleTaxAndFee;
  }

  /* MUTATION */

  @Mutation
  setSearchText(search: string) {
    this.searchText = search;
  }

  @Mutation
  setSearchTextLocation(search: string) {
    this.locationSearchText = search;
  }

  @Mutation
  setSearchTextContact(search: string) {
    this.contactSearchText = search;
  }

  @Mutation
  setPricings(pricings: Array<OppPricing>) {
    this.pricings = pricings;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setPriceSchedules(priceSchedules: Array<PriceSchedule>) {
    this.priceSchedules = priceSchedules;
  }

  @Mutation
  setSelectedPriceSchedule(priceSchedule: PriceSchedule) {
    this.selectedPriceSchedule = priceSchedule;
  }

  @Mutation
  setSuppliers(suppliers: Array<SupplierWrapper>) {
    this.suppliers = suppliers;
  }

  @Mutation
  setSupplier(supplier: Supplier) {
    this.supplier = supplier;
  }

  @Mutation
  setSupplierLocations(locations: Array<SupplierLocation>) {
    this.locations = locations;
  }

  @Mutation
  setSelectedLocation(location: SupplierLocation) {
    this.selectedLocation = location;
  }

  @Mutation
  setCompanyLoading(loading: boolean) {
    this.companyLoading = loading;
  }

  @Mutation
  setTaxesAndFees(taxesAndFees: Array<TaxesAndFees>) {
    this.taxesAndFees = taxesAndFees;
  }
  @Mutation
  setSingleTaxAndFee(taxAndFee: TaxesAndFees) {
    this.singleTaxAndFee = taxAndFee;
  }

  /* ACTIONS */

  @Action
  async loadPricings(errs?: CommonErrHandlers) {
    this.setLoading(true);
    try {
      // const p = await inventoryClient.deprecatedListPricings(undefined, errs).toPromise();
      // await inventoryClient.getPricingById({})
      // this.setPricings(p);
      const pricing = {} as OppPricing;
      pricing.orgId = companyModule.Organization?.ID || 'missing';
      const p = await inventoryClient.getPricing(pricing).toPromise();
      pricingModule.setOpPricing(p);
      pricingModule.mapOpPricingDisplay();
    } catch (err) {
      // todo send alert
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async loadPriceSchedule(id: string) {
    this.setLoading(true);
    try {
      const schedule = this.PriceSchedules.find(
        (priceSchedule: PriceSchedule) => priceSchedule.id === id,
      );
      // const schedule = await inventoryClient.getPriceScheduleById(id, errs).toPromise();
      if (schedule) {
        this.setSelectedPriceSchedule(schedule);
      }
    } catch (err) {
      // todo send alert
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async loadPriceSchedules(errs?: CommonErrHandlers) {
    this.setLoading(true);
    try {
      const schedules = await inventoryClient.getPriceSchedules(errs).toPromise();
      this.setPriceSchedules(schedules);
    } catch (err) {
      // todo send alert
    } finally {
      this.setLoading(false);
    }
  }
  @Action
  async createPricing(pricing: OppPricing, errs?: CommonErrHandlers) {
    this.setLoading(true);
    try {
      // await inventoryClient.deprecatedCreatePricing(Pricing, errs).toPromise();
      await inventoryClient.createPricing(pricing, errs).toPromise();
    } catch (err) {
      // todo send alert
      appModule.addMessageError(codes.PRICE_ERROR_500_CREATE_PRICE);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createPriceSchedules(priceSchedule: PriceSchedule, errs?: CommonErrHandlers) {
    this.setLoading(true);
    try {
      const schedule = await inventoryClient
        .createPriceSchedule(priceSchedule, errs)
        .toPromise();
      this.setSelectedPriceSchedule(schedule);
    } catch (err) {
      // todo send alert
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updatePriceSchedule(priceSchedule: PriceSchedule, errs?: CommonErrHandlers) {
    this.setLoading(true);
    try {
      const schedule = await inventoryClient
        .updatePriceSchedule(priceSchedule, errs)
        .toPromise();
      this.setSelectedPriceSchedule(schedule);
    } catch (err) {
      // todo send alert
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async deletePriceSchedule(id: string, errs?: CommonErrHandlers) {
    this.setLoading(true);
    try {
      await inventoryClient.deletePriceSchedule(id, errs).toPromise();
      this.setSelectedPriceSchedule({});
    } catch (err) {
      // todo send alert
    } finally {
      this.setLoading(false);
    }
  }

  // @Action
  // async loadTaxesAndFees(errs?: CommonErrHandlers) {
  //   try {
  //     this.setLoading(true);
  //     const taxesAndFees = await inventoryClient.getTaxesAndFees().toPromise();
  //     this.setTaxesAndFees(taxesAndFees);
  //   } catch {
  //     // todo send alert
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }
  // @Action
  // async loadTaxesAndFeesById(id: string, errs?: CommonErrHandlers) {
  //   try {
  //     this.setLoading(true);
  //     if (this.taxesAndFees.length === 0) {
  //       const res = await inventoryClient.getTaxesAndFees(errs).toPromise();
  //       this.setTaxesAndFees(res);
  //     }
  //     const selectedTaxAndFee = this.taxesAndFees.filter(
  //       (item: TaxesAndFees) => item.id === id,
  //     );
  //     this.setSingleTaxAndFee(selectedTaxAndFee[0]);
  //   } catch {
  //     // todo send alert
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }
  // @Action
  // async createTaxesAndFees(rq: TaxesAndFees | {}, errs?: CommonErrHandlers) {
  //   try {
  //     this.setLoading(true);
  //     const res = await inventoryClient.createTaxesAndFees(rq, errs).toPromise();
  //     this.setSingleTaxAndFee(res);
  //   } catch {
  //     // todo send alert
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }
  // @Action
  // async updateTaxesAndFees(rq: TaxesAndFees | {}, errs?: CommonErrHandlers) {
  //   try {
  //     this.setLoading(true);
  //     const res = await inventoryClient.updateTaxesAndFees(rq, errs).toPromise();
  //     this.setSingleTaxAndFee(res);
  //   } catch {
  //     // todo send alert
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }
  // @Action
  // async deleteTaxesAndFees(id: string, errs?: CommonErrHandlers) {
  //   try {
  //     this.setLoading(true);
  //     await inventoryClient.deleteTaxesAndFees(id).toPromise();
  //     const taxesAndFees = await inventoryClient.getTaxesAndFees(errs).toPromise();
  //     this.setTaxesAndFees(taxesAndFees);
  //   } catch {
  //     // todo send alert
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }
}

export const inventoryModule = getModule(InventoryModule, store);
