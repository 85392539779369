








































import { Component, Vue, Prop } from 'vue-property-decorator';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import { offerModule } from '@/store/modules/moduleOffer';
import { companyModule } from '@/store/modules/moduleCompany';
import { Address } from '@/models';

@Component({
  components: { RdmCollapsableCard },
})
export default class AvailabilityStatusFilter extends Vue {
  private selectedOffer = '';

  @Prop({ type: Array })
  public offers!: Array<any>;

  get Offers() {
    const items = [] as Array<any>;
    this.offers.forEach((o: any) =>
      items.push({ text: o.name, value: o.id, productId: o.productId }),
    );
    return items;
  }

  get Products() {
    return offerModule.Products;
  }

  get CompanyAddresses() {
    return companyModule.Addresses;
  }

  resetFilters() {
    this.selectedOffer = '';
    this.selectOffer();
  }

  selectOffer() {
    this.$emit(
      'select-offer',
      this.offers.find((o: any) => o.id === this.selectedOffer),
    );
  }

  preventTyping(event: Event) {
    if (this.selectedOffer && this.selectedOffer.length) event.preventDefault();
  }

  getPrimaryAddress(id: string): string {
    const p = this.Products.find((p: any) => p.id === id);
    if (p) {
      const address = this.CompanyAddresses.find((a: Address) => a.id === p.addressId);
      if (address) {
        return `${address.name}`;
      }
    }

    return '';
  }
}
