export const demoChannels = [
  'LuLu Trip',
  'Trusted Tours & Attractions',
  'Tripsters (formerly Reserve Direct)',
  '365 Tickets',
  'Museument',
  'Groupon',
  'Google',
  'Expedia',
  'Get Your Guide',
];

export const demoProductNames = [
  'Golden Gate Cruise',
  'To Alcatraz and Beyond',
  'All around the Bay',
];

export const demoProductOptionSuffixes = [
  ' + Audio Tour',
  '+ Professional Photos',
  ' + Audio Tour & Professional Photos bundle',
];

export const demoRateCardCommissions = [10, 15, 20, 25, 30];

export const demoLocationNames = ['Pier 39', 'Berkeley Marina', 'Tiburon'];

export const demoPermissionRoles = ['Basic', 'Finance', 'Admin'];
