var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('rdm-return-to-btn',{attrs:{"route-name":_vm.routeToCompanyLocations,"return-to-text":'Locations'}})],1)],1),_c('v-row',[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.PageTitle))])])],1),_c('v-row',{staticClass:"max-width"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[(_vm.Loading)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Name")]),_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Google Place ID")]),_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Address")]),_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)],1)],1):_vm._e(),(!_vm.Loading)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[(!_vm.EditModeLocationName)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Name")]),(_vm.address && _vm.address.name)?_c('div',{staticClass:"max-width-400 pl-1 py-1",class:{
                        'editable-section': !_vm.EditModeLocationName && _vm.IsOrgAdmin,
                      },on:{"click":_vm.toggleLocationName}},[_vm._v(" "+_vm._s(_vm._f("emptyDash")(_vm.address.name))+" ")]):_c('div',[_vm._v(_vm._s(_vm._f("emptyNotProvided")('')))])])],1)]:_vm._e(),(_vm.EditModeLocationName)?[_c('v-form',{ref:"editLocationNameForm",attrs:{"lazy-validation":""},model:{value:(_vm.locationNameValid),callback:function ($$v) {_vm.locationNameValid=$$v},expression:"locationNameValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Name*")]),_c('v-text-field',{attrs:{"placeholder":"Name","outlined":"","rules":_vm.requiredFieldRules,"background-color":"white","hide-details":!(
                            !_vm.address.name ||
                            (_vm.address.name && _vm.address.name.length === 0)
                          )},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleKeyDownPreventDefault.apply(null, arguments)}},model:{value:(_vm.address.name),callback:function ($$v) {_vm.$set(_vm.address, "name", $$v)},expression:"address.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-align-right"},[_c('v-btn',{staticClass:"mr-1",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelLocationName}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.locationNameValid,"loading":_vm.savingLocationName},on:{"click":_vm.validateLocationName}},[_vm._v("Save")])],1)])],1)],1)]:_vm._e(),(!_vm.EditModeLocationPlaceId)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Google Place ID")]),_c('div',{staticClass:"max-width-400 pl-1 py-1",class:{
                        'editable-section': !_vm.EditModeLocationPlaceId && _vm.IsOrgAdmin,
                      },on:{"click":_vm.toggleLocationPlaceId}},[(_vm.address && _vm.address.googlePlaceId)?_c('div',[_vm._v(" "+_vm._s(_vm._f("emptyDash")(_vm.address.googlePlaceId))+" ")]):_c('div',[_vm._v(_vm._s(_vm._f("emptyNotProvided")('')))])])])],1)]:_vm._e(),(_vm.EditModeLocationPlaceId)?[_c('v-form',{ref:"editLocationPlaceIdForm",attrs:{"lazy-validation":""},model:{value:(_vm.locationPlaceIdValid),callback:function ($$v) {_vm.locationPlaceIdValid=$$v},expression:"locationPlaceIdValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Google Place ID")]),_c('v-text-field',{attrs:{"placeholder":"Google Place ID","outlined":"","background-color":"white"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleKeyDownPreventDefault.apply(null, arguments)}},model:{value:(_vm.address.googlePlaceId),callback:function ($$v) {_vm.$set(_vm.address, "googlePlaceId", $$v)},expression:"address.googlePlaceId"}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-align-right"},[_c('v-btn',{staticClass:"mr-1",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelLocationPlaceId}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.locationPlaceIdValid,"loading":_vm.savingLocationPlaceId},on:{"click":_vm.validateLocationPlaceId}},[_vm._v("Save")])],1)])],1)],1)]:_vm._e(),(!_vm.EditModeLocationAddress)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Address")]),_c('div',{staticClass:"max-width-400 pl-1 py-1",class:{
                        'editable-section': !_vm.EditModeLocationAddress && _vm.IsOrgAdmin,
                      },on:{"click":_vm.toggleLocationAddress}},[_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.address.streetAddress)))]),_c('div',[_vm._v(" "+_vm._s(_vm._f("emptyDash")(((_vm.address.city) + ", " + (_vm.address.region) + " " + (_vm.address.postalCode))))+" ")]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.findCountryName(_vm.address.countryCode))))])])])],1)]:_vm._e(),(_vm.EditModeLocationAddress)?[_c('v-form',{ref:"editLocationAddressForm",attrs:{"lazy-validation":""},model:{value:(_vm.locationAddressValid),callback:function ($$v) {_vm.locationAddressValid=$$v},expression:"locationAddressValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Street Address*")]),_c('v-text-field',{attrs:{"placeholder":"Street Address*","outlined":"","hide-details":!(
                            !_vm.address.streetAddress ||
                            (_vm.address.streetAddress &&
                              _vm.address.streetAddress.length === 0)
                          ),"background-color":"white","rules":_vm.requiredFieldRules},model:{value:(_vm.address.streetAddress),callback:function ($$v) {_vm.$set(_vm.address, "streetAddress", $$v)},expression:"address.streetAddress"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("City*")]),_c('v-text-field',{attrs:{"placeholder":"City*","outlined":"","hide-details":!(
                            !_vm.address.city ||
                            (_vm.address.city && _vm.address.city.length === 0)
                          ),"background-color":"white","rules":_vm.requiredFieldRules},model:{value:(_vm.address.city),callback:function ($$v) {_vm.$set(_vm.address, "city", $$v)},expression:"address.city"}})],1),_c('v-col',[_c('strong',{staticClass:"text-dark"},[_vm._v("State"),(!!_vm.requiredState(_vm.address.countryCode || '').length)?_c('span',[_vm._v("*")]):_vm._e()]),_c('v-text-field',{attrs:{"placeholder":"State","outlined":"","hide-details":!(
                            !_vm.address.region ||
                            (_vm.address.region && _vm.address.region.length === 0)
                          ),"background-color":"white","rules":_vm.requiredState(_vm.address.countryCode || '')},model:{value:(_vm.address.region),callback:function ($$v) {_vm.$set(_vm.address, "region", $$v)},expression:"address.region"}})],1),_c('v-col',[_c('strong',{staticClass:"text-dark"},[_vm._v("Postal Code*")]),_c('v-text-field',{attrs:{"placeholder":"Postal Code*","outlined":"","hide-details":!(
                            !_vm.address.postalCode ||
                            (_vm.address.postalCode && _vm.address.postalCode.length === 0)
                          ),"background-color":"white","rules":_vm.requiredFieldRules},model:{value:(_vm.address.postalCode),callback:function ($$v) {_vm.$set(_vm.address, "postalCode", $$v)},expression:"address.postalCode"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Country*")]),_c('v-autocomplete',{staticClass:"input",attrs:{"placeholder":"Select a Country","outlined":"","item-value":"code","item-text":"name","background-color":"white","hide-details":!(
                            !_vm.address.countryCode ||
                            (_vm.address.countryCode && _vm.address.countryCode.length === 0)
                          ),"items":_vm.Countries,"filter":_vm.filterCodeAndName,"rules":_vm.requiredFieldRules,"menu-props":{
                          bottom: true,
                          offsetY: true,
                        }},model:{value:(_vm.address.countryCode),callback:function ($$v) {_vm.$set(_vm.address, "countryCode", $$v)},expression:"address.countryCode"}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-align-right"},[_c('v-btn',{staticClass:"mr-1",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelLocationAddress}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.locationAddressValid,"loading":_vm.savingLocationAddress},on:{"click":_vm.validateLocationAddress}},[_vm._v("Save")])],1)])],1)],1)]:_vm._e()],2),(_vm.address && _vm.address.longitude && _vm.address.latitude)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('map-box',{attrs:{"lat":parseFloat(_vm.address.latitude),"long":parseFloat(_vm.address.longitude)}})],1):_vm._e()],1)],1):_vm._e()],1)],1)],1),_c('div',{ref:"placeService"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }