





































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { httpModule } from '@/store/modules/moduleHttp';
import { optionModule } from '@/store/modules/moduleOption';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { GRSchedule, TimeSlot } from '@/models';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import RdmTimeInput from '@/components/common/time/RdmTimeInput.vue';
import OptionCreateSchedule from '@/components/product/option/create/OptionCreateSchedule.vue';
import {
  optionalStartDateBeforeEndDateRules,
  requiredFieldRules,
} from '@/utils/validation-rules';
import { TimeEntryMode } from '@/api/inventory/InventoryModels';
import faker from 'faker';
import _ from 'lodash';

@Component({
  components: {
    RdmCollapsableControlCard,
    RdmTimeInput,
    OptionCreateSchedule,
  },
})
export default class OptionViewSchedules extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private optionalStartBeforeEndField = optionalStartDateBeforeEndDateRules;
  private scheduleLoading = false;
  private startDateMenu = false;
  private endDateMenu = false;
  private structuredSchedule = [] as any;
  private editDateRange = false;
  private editedDateStart = '';
  private editedDateEnd = '' as string | undefined | null;
  private startTime = '';
  private endTime = '';
  private modalIsOpen = false;

  async mounted() {
    if (this.Option && _.isString(this.Option.id) && this.Option.id.length > 0) {
      await httpModule.optionSchedules(this.Option.id);
    }
  }

  async toggleSchedule(i: number, schedule: GRSchedule) {
    await httpModule.getSchedule(schedule.id);
    const updatedStateSchedules = [...this.OptionSchedules];
    updatedStateSchedules.map((item, index) => {
      if (i === index) {
        item.open = !item.open;
      } else {
        item.open = false;
      }
    });
    optionModule.updateOptionSchedules(updatedStateSchedules);
    this.structuringData();
  }

  private openModal() {
    this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  async deleteSchedule() {
    this.scheduleLoading = true;
    await httpModule
      .deleteOptionSchedule({
        option: this.Option,
        schedule: this.OptionSchedule,
      })
      .finally(() => this.closeModal());
    _.delay(async () => {
      await httpModule.optionSchedules(this.Option.id);
      this.structuringData();
      this.scheduleLoading = false;
    }, 5000);
  }

  structuringData() {
    const days = [] as Array<string>;
    let timeSlots = [] as any;
    const structured = [] as any;
    if (this.OptionSchedule && Array.isArray(this.OptionSchedule.timeSlots)) {
      this.OptionSchedule.timeSlots.map((item) => {
        if (!days.includes(item.weekday)) {
          days.push(item.weekday);
        }
      });
      if (Array.isArray(days)) {
        days.map((day) => {
          this.OptionSchedule.timeSlots.map((item) => {
            if (item.weekday === day) {
              item.editMode = false;
              timeSlots.push(item);
            }
          });
          structured.push({
            weekday: day,
            timeSlots: timeSlots,
          });
          timeSlots = [];
        });
      }
    }
    this.structuredSchedule = structured;
  }

  updateSlotStart(slot: any, newStartTime: any) {
    slot.start = newStartTime;
  }

  updateSlotEnd(slot: any, newEndTime: any) {
    slot.end = newEndTime;
  }

  validSlotTime(slot: any) {
    if (slot.start && slot.end) {
      let hourMins = slot.start.split(':');
      const start = {
        hours: Number(hourMins[0]),
        minutes: Number(hourMins[1]),
      };

      hourMins = slot.end.split(':');
      const end = {
        hours: Number(hourMins[0]),
        minutes: Number(hourMins[1]),
      };

      if (start.hours * 60 + start.minutes > end.hours * 60 + end.minutes) {
        return true;
      }
    }

    return false;
  }

  toggleEditDateRange() {
    this.editDateRange = !this.editDateRange;
    this.editedDateStart = this.OptionSchedule.validFrom;
    this.editedDateEnd = this.OptionSchedule.validUntil;
  }

  async saveUpdatedDateRange() {
    const dateRange = {
      validFrom: this.editedDateStart,
      validUntil: this.editedDateEnd,
    };
    await httpModule.updateScheduleDateRange({
      dateRange: dateRange,
      schedule: this.OptionSchedule,
    });
    await httpModule.optionSchedules(this.Option.id);
    this.toggleEditDateRange();
  }

  toggleEditTimeSlot(i: number, slot: TimeSlot, state: boolean) {
    const copy = [...this.structuredSchedule];
    if (copy[i] && Array.isArray(copy[i].timeSlots)) {
      copy[i].timeSlots.map((item: TimeSlot) => {
        item.editMode = item.key === slot.key ? state : item.editMode;
      });
    }
    this.startTime = slot.start;
    this.endTime = slot.end;
    this.structuredSchedule = copy;
  }

  async saveUpdatedTimeSLot(slot: TimeSlot, weekday: string) {
    let payload = {} as TimeSlot;
    payload.end = slot.end;
    payload.start = slot.start;
    payload = { ...payload, key: slot.key, weekday: weekday };

    await httpModule.updateScheduleTimeslot({
      slot: payload,
      schedule: this.OptionSchedule,
    });
    await httpModule.optionSchedules(this.Option.id);
  }

  async deleteTimeSlot(slot: TimeSlot) {
    await httpModule.deleteScheduleTimeslot({
      slot: slot,
      schedule: this.OptionSchedule,
    });
    await httpModule.optionSchedules(this.Option.id);
  }

  toggleScheduleCreate() {
    scheduleModule.toggleCreateSchedule();
  }
  generateTimeSlotsForGrouped() {
    const timeSlots = [] as any;
    if (scheduleModule.GroupedDays && Array.isArray(scheduleModule.GroupedDays)) {
      scheduleModule.GroupedDays.map((group: any) => {
        if (Array.isArray(group.times)) {
          group.times.map((time: any) => {
            if (Array.isArray(group.days)) {
              group.days.map((day: string) => {
                timeSlots.push({
                  weekday: day,
                  start: time.start,
                  end: time.end,
                });
              });
            }
          });
        }
      });
    }
    return timeSlots;
  }

  generateTimeSlotsForIndividual() {
    const timeSlots = [] as any;
    if (scheduleModule.IndividualDays && Array.isArray(scheduleModule.IndividualDays)) {
      scheduleModule.IndividualDays.map((day: any) => {
        if (Array.isArray(day.timeSlots)) {
          day.timeSlots.map((time: any) => {
            if (time.start !== null || time.start?.length > 0) {
              timeSlots.push({
                weekday: day.weekday,
                start: time.start,
                end: time.end,
              });
            }
          });
        }
      });
    }
    return timeSlots;
  }

  async createNewSchedule() {
    let timeSlots = [] as any;
    if (this.TimeEntryMode === TimeEntryMode.Grouped) {
      timeSlots = this.generateTimeSlotsForGrouped();
    }
    if (this.TimeEntryMode === TimeEntryMode.Individual) {
      timeSlots = this.generateTimeSlotsForIndividual();
    }
    const schedule = {
      id: faker.datatype.uuid(),
      name: scheduleModule.ScheduleData.name,
      validFrom: scheduleModule.ScheduleData.validStart,
      validUntil:
        scheduleModule.ScheduleData.validEnd &&
        scheduleModule.ScheduleData.validEnd.length > 0
          ? scheduleModule.ScheduleData.validEnd
          : undefined,
      timeSlots: timeSlots,
    };
    this.scheduleLoading = true;
    await httpModule.updateSchedule(schedule).finally(() => {});
    await this.attachScheduleToOption();
  }

  async attachScheduleToOption() {
    _.delay(async () => {
      await httpModule.addScheduleToOption(scheduleModule.Schedule.id).finally(() => {
        this.toggleScheduleCreate();
        _.delay(() => {
          httpModule.optionSchedules(this.Option.id);
          this.scheduleLoading = false;
        }, 2000);
      });
    }, 2000);
  }

  get TimeEntryMode() {
    return scheduleModule.TimeEntryMode;
  }

  get Loading() {
    return scheduleModule.Loading;
  }

  get ScheduleLoading() {
    return this.scheduleLoading;
  }

  get Option() {
    return optionModule.Option;
  }

  get OptionSchedules() {
    return optionModule.OptionSchedules;
  }

  get OptionSchedule() {
    return optionModule.OptionSchedule;
  }

  get CreateSchedule() {
    return scheduleModule.CreateSchedule;
  }

  get CreateOptionScheduleForm() {
    console.log(optionModule.CreateOptionScheduleForm);
    return optionModule.CreateOptionScheduleForm;
  }
}
