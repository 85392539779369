

























































































import { Component, Vue } from 'vue-property-decorator';
import {
  organizationRules,
  passwordLoginRules,
  userLoginRules,
} from '@/utils/validation-rules';
import { httpModule } from '@/store/modules/moduleHttp';
import { constants } from '@/utils/constants';
import { userModule } from '@/store/modules/moduleUser';

@Component
export default class Login extends Vue {
  private email = '';
  private password = '';
  private validLogin = false;
  private organizationRules = organizationRules;
  private userLoginRules = userLoginRules;
  private passwordLoginRules = passwordLoginRules;
  private loading = false;
  private loginErrorMsg = '';
  private showPassword = false;

  login() {
    // todo: if supporting multiple ORG accounts, check user/password combo against each ORG
    // todo: and then return the multiple ORGs that have been validated
    const loginForm = this.$refs.loginForm as HTMLFormElement;
    if (loginForm.validate()) {
      this.loading = true;
      // const unknownErrMsg = () => {
      //   this.loginErrorMsg =
      //     'Failed to Login, please try again. If this issue persists\n ' +
      //     'please contact support at support@redeam.com';
      // };

      httpModule
        .login({
          email: this.email,
          password: this.password,
        })
        .catch(() => {
          this.loginErrorMsg =
            'Failed to Login, please try again. If this issue persists\n ' +
            'please contact support at support@redeam.com';
        })
        .finally(() => {
          this.loading = false;
          this.$gtag.event('login', { account: this.email });
        });
    }
  }

  forgotPassword() {
    userModule.applyForgotEmail(this.email);
    this.$router.push({ name: constants.routes.FORGOT_PASSWORD }).catch();
  }
}
