var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-row-divider@10, table-tfoot"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.productHeaders,"items":_vm.productsList,"items-per-page":10,"search":_vm.productSearch,"sort-by":"internalName","custom-filter":_vm.filterProducts,"hide-default-footer":(_vm.productsList && _vm.productsList.length === 0) || !_vm.productsList,"footer-props":{
        itemsPerPageOptions: _vm.paginationOptions,
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
      }},scopedSlots:_vm._u([{key:"item.internalName",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.internalName))+" ")]}},{key:"item.addressId",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("emptyNotProvided")(item.addressId ? _vm.convertAddressIdToName(item.addressId) : ''))+" ")]}},{key:"item.view",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: _vm.routerProductDetailsName, params: { id: item.id } },"color":"primary","plain":"","disabled":!item.id}},[_vm._v("View")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"color":"error","plain":""},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" Remove ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-left"},[_vm._v(" You don’t have any Products yet. Click "),_c('router-link',{attrs:{"to":{ name: _vm.routerProductCreate }}},[_vm._v("here")]),_vm._v(" to add one. ")],1)]},proxy:true}])})],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.modalIsOpen),callback:function ($$v) {_vm.modalIsOpen=$$v},expression:"modalIsOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("Remove Product")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Are you sure you want to remove "+_vm._s(_vm._f("capitalize")(_vm.productToRemove.internalName))+"? ")]),_c('p',[_vm._v(" By removing this Product you are potentially changing data associated to a channel. Please validate and republish your data. ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.proceedRemoveProduct}},[_vm._v("Remove")]),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }