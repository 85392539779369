
































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { constants } from '@/utils/constants';
import {
  onlyOneUnitPriceRequired,
  requiredFieldRules,
  validWholeNumbersRules,
} from '@/utils/validation-rules';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import OptionCreatePricingSet from '@/components/product/option/create/OptionCreatePricingSet.vue';
import OptionCreatePricingView from '@/components/product/option/create/OptionCreatePricingView.vue';
import { optionModule } from '@/store/modules/moduleOption';
import {
  handleKeyDownOnlyNumeric,
  convertMinorUnitToCurrency,
  convertCurrencyToMinorUnit,
} from '@/utils/helpers';
import { DisplayUnitPrice, OptionUnitPrice, OptionUnitPriceAmount } from '@/models';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';

@Component({
  components: {
    OptionCreatePricingView,
    OptionCreatePricingSet,
    RdmCollapsableCard,
    RdmCollapsableControlCard,
  },
})
export default class OptionCreateUnitPricing extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private validWholeNumbersRules = validWholeNumbersRules;
  private oneRequiredUnitPrice = onlyOneUnitPriceRequired;
  private handleKeyDown = handleKeyDownOnlyNumeric;
  private convertMinorUnitToCurrency = convertMinorUnitToCurrency;
  private convertCurrencyToMinorUnit = convertCurrencyToMinorUnit;
  private openOptionPricing = false;
  private createOptionPricingForm = false;
  private createPricingSet = true;
  private currency = 'USD';
  private step = 1;
  private saving = false;
  private selectedTravelers = [] as Array<string>;
  // private displayUnits = new Map<string, DisplayUnitPrice>();
  private displayUnits = [] as Array<DisplayUnitPrice>;
  private selectedSchedule = '';
  private validFrom = '';
  private validUntil = '';
  private selectedDateTimes = [];

  mounted() {
    this.toggleAddPricingSet();
    const travelers = optionModule.SelectedUnitIds || ([] as Array<string>);
    this.handleChangeTravelerType(travelers);
  }

  toggleOptionPricing() {
    optionModule.setOpenCreatePricing(!this.OpenCreatePricing);
  }

  toggleAddPricingSet() {
    optionModule.setCreateModeOptionCreatePricingSet(true);
  }

  discard() {
    this.$router.push({ name: constants.routes.OPTION_LIST }).catch();
  }

  nextStepValidate4() {
    if (this.$refs?.pricingForm) {
      const form: any = this.$refs.pricingForm;
      const isValid = form.validate();
      if (isValid && this.HasValidUnits) {
        this.addUnitPrices();
      }
    }
  }

  unitIdToTravelerName(id: string) {
    const i = this.TravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.TravelerTypes[i].text;
    }
    return '';
  }

  removeSelectedTraveler(id: string) {
    if (this.selectedTravelers && Array.isArray(this.selectedTravelers)) {
      const i = this.selectedTravelers.findIndex((index) => index === id);
      if (i > -1) {
        this.selectedTravelers.splice(i, 1);
      }
    }
    this.handleChangeTravelerType(this.selectedTravelers);
  }

  handleChangeTravelerType(travelers: Array<string>) {
    this.selectedTravelers = _.cloneDeep(travelers);
    const oldUnits = _.cloneDeep(this.displayUnits);
    // const newUnits = new Map<string, DisplayUnitPrice>();
    const newUnits = [] as Array<DisplayUnitPrice>;
    const emptyUnit = {
      retail: '',
      original: '',
      net: '',
    } as DisplayUnitPrice;
    for (const t of travelers) {
      emptyUnit.unitId = t;
      const index = oldUnits.findIndex((o) => o.unitId === t);
      if (index > -1) {
        const u = oldUnits[index] || _.cloneDeep(emptyUnit);
        newUnits.push(u);
      } else {
        const u = _.cloneDeep(emptyUnit);
        newUnits.push(u);
      }
    }
    this.displayUnits = _.cloneDeep(newUnits);
  }

  isValidUnitPrice(unit: DisplayUnitPrice) {
    return (
      !!(unit.net && _.isNumber(+unit.net) && _.toNumber(unit.net) >= 0) ||
      !!(unit.original && _.isNumber(+unit.original) && _.toNumber(unit.original) >= 0) ||
      !!(unit.retail && _.isNumber(+unit.retail) && _.toNumber(unit.retail) >= 0)
    );
  }

  addUnitPrices() {
    const unitPrices = [] as Array<OptionUnitPrice>;
    this.selectedTravelers.forEach((t: string) => {
      const du = this.displayUnits.find((du: DisplayUnitPrice) => du.unitId === t);
      if (du) {
        const optionUnitPriceAmount = {
          currency: this.currency,
        } as OptionUnitPriceAmount;
        const net = _.toNumber(du.net);
        const retail = _.toNumber(du.retail);
        const original = _.toNumber(du.original);
        if (net > 0) {
          optionUnitPriceAmount.net = this.convertCurrencyToMinorUnit(net);
        }

        if (retail > 0) {
          optionUnitPriceAmount.retail = this.convertCurrencyToMinorUnit(retail);
        }

        if (original > 0) {
          optionUnitPriceAmount.original = this.convertCurrencyToMinorUnit(original);
        }

        unitPrices.push({
          unitId: du.unitId,
          amount: optionUnitPriceAmount,
        } as OptionUnitPrice);
      }
    });
    const option = _.cloneDeep(optionModule.Option);
    option.unitPrices = unitPrices;

    optionModule.setOption(option);
    try {
      httpModule
        .createOption(option)
        .then(() => {
          // httpModule.addScheduleToOption(scheduleModule.Schedule.id).then();
        })
        .catch(() => {
          // todo send alert
        })
        .finally(() => {
          this.navigateOptionDetails(optionModule.Option.id);
        });
    } catch (e) {
      console.log(e);
      // todo send alert to backend
    }
  }

  navigateOptionDetails(id: string) {
    this.saving = false;
    this.$router
      .push({ name: constants.routes.OPTION_DETAILS, params: { id: id } })
      .catch();
  }

  get HasValidUnits() {
    const unitCount = this.displayUnits.length;
    if (unitCount === 0) {
      return false;
    }

    let isValid = true;

    this.displayUnits.forEach((u) => {
      if (isValid) {
        const valid = this.isValidUnitPrice(u);
        if (!valid) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  get HasOwnData() {
    return true;
    // return (
    //     this.shareLocation === 'yes' ||
    //     this.sharePolicy === 'yes' ||
    //     this.sharePricing === 'yes' ||
    //     this.shareSchedule === 'yes'
    // );
  }

  get TravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get Schedules() {
    return [];
  }

  get CreateModeOptionCreatePricingSet() {
    return optionModule.CreateModeOptionCreatePricingSet;
  }

  get OpenCreatePricing() {
    return optionModule.OpenCreatePricing;
  }
}
