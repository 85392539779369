var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.Loading && _vm.TaxesAndFees.length === 0)?_c('inventory-skeleton'):_c('div',[_c('v-data-table',{staticClass:"elevation-0 table-no-wrap",attrs:{"loading":_vm.Loading,"headers":_vm.taxAndFeesHeaders,"items":_vm.TaxesAndFees,"items-per-page":10,"search":_vm.search,"sort-by":['name'],"footer-props":{
        itemsPerPageOptions: _vm.paginationOptions,
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
      }},scopedSlots:_vm._u([{key:"item.chargeType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("lowercase")(item.chargeType)))+" ")]}},{key:"item.rateType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.rateType === 'FLAT' ? ("" + (_vm.centsToDollars(item.amount))) : item.amount + '%')+" ")]}},{key:"item.calcText",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("lowercase")(item.calcText.replace('|', ', ').replaceAll('_', ' '))))+" ")]}},{key:"item.validFrom",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(((item.validFrom) + " - " + (item.validUntil ? item.validUntil : 'Ongoing')))+" ")]}},{key:"item.view",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: _vm.routerTaxesAndFeesViewName, params: { id: item.id } },"color":"primary","plain":"","disabled":!item.id}},[_vm._v(" View ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"color":"error","plain":"","disabled":!item.id},on:{"click":function($event){return _vm.deleteTaxesAndFees(item)}}},[_vm._v("Remove")])]}},{key:"no-data",fn:function(){return [_vm._v(" You don’t have any Taxes and Fees yet. Click "),_c('router-link',{attrs:{"to":{ name: _vm.routerTaxesAndFeesViewCreate }}},[_vm._v("here")]),_vm._v(" to add one. ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }