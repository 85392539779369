


























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { productModule } from '@/store/modules/moduleProduct';
import _ from 'lodash';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import { TravelerPrices } from '@/api/inventory/InventoryModels';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import RdmEditNavBtn from '@/components/common/button/RdmEditNavBtn.vue';
import { pricingModule } from '@/store/modules/modulePricing';
import { appModule } from '@/store/modules/moduleApp';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { optionModule } from '@/store/modules/moduleOption';
import { Option, Product } from '@/models';

@Component({
  components: {
    RdmCollapsableCard,
    RdmEditNavBtn,
    RdmReturnToBtn,
  },
})
export default class PricingView extends Vue {
  private routerPricingList = constants.routes.PRICING_LIST;
  private routerPricingEdit = constants.routes.PRICING_EDIT;
  private parentName = constants.titles.PRICING;
  private priceScheduleId = '';
  private selectedProduct = {} as Product;
  private selectedOption = {} as Option;
  private travelerPrices = { travelers: [] } as TravelerPrices;

  mounted() {
    this.travelerPrices = _.cloneDeep(this.GlobalTravelerPrices);
    this.priceScheduleId = this.$route.params.id;
    // todo load selected pricing
    this.getPriceSchedule();
  }

  getPriceSchedule() {
    pricingModule.getPriceSchedule(this.priceScheduleId);
    // this.findProductAndOptions();
    // inventoryModule.loadPriceSchedule(this.priceScheduleId).then(() => {
    //   this.findProductAndOptions();
    // });
  }

  get RouterName() {
    if (
      appModule.PreviousRoute &&
      appModule.PreviousRoute.name &&
      appModule.PreviousRoute.name === constants.routes.PRODUCT_DETAILS
    ) {
      return appModule.PreviousRoute.name;
    }
    return this.routerPricingList;
  }

  get ButtonText() {
    if (
      appModule.PreviousRoute &&
      appModule.PreviousRoute.name &&
      appModule.PreviousRoute.name === constants.routes.PRODUCT_DETAILS
    ) {
      return constants.titles.PRODUCT;
    }
    return constants.titles.PRICING;
  }

  get RouterParams() {
    if (
      appModule.PreviousRoute &&
      appModule.PreviousRoute.params &&
      !_.isEmpty(appModule.PreviousRoute.params) &&
      appModule.PreviousRoute.params.id
    ) {
      return { id: appModule.PreviousRoute.params.id };
    }
    return undefined;
  }

  get Currencies() {
    return appModule.Codes?.currency || [];
  }

  get TravelerTypes() {
    return travelerTypeModule.TravelerTypes;
  }

  get GlobalTravelerPrices() {
    return travelerTypeModule.TravelerPrices;
  }

  get Products() {
    return productModule.Products;
  }

  get Options() {
    return optionModule.Options;
  }

  get SelectedPriceSchedule() {
    // return inventoryModule.PriceSchedule;
    return pricingModule.PriceSchedule;
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }
}
