var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.Loading),expression:"Loading"}],attrs:{"type":"table-thead, table-row-divider@10, table-tfoot"}}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.Loading),expression:"!Loading"}],attrs:{"loading":_vm.Loading,"headers":_vm.productHeaders,"items":_vm.TravelerTypes,"items-per-page":10,"search":_vm.ProductSearch,"sort-by":"unit.internalName","hide-default-footer":(_vm.TravelerTypes && _vm.TravelerTypes.length === 0) || !_vm.TravelerTypes,"footer-props":{
        itemsPerPageOptions: _vm.paginationOptions,
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
      }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){return _vm.editTravelerType(item)}}},[_vm._v("View")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"color":"error","plain":""},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v("Remove")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-left"},[_vm._v(" You don’t have any Traveler Types yet. Click "),_c('router-link',{attrs:{"to":{ name: _vm.routeCreateTravelerType }}},[_vm._v("here")]),_vm._v(" to add one. ")],1)]},proxy:true}])})],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.modalIsOpen),callback:function ($$v) {_vm.modalIsOpen=$$v},expression:"modalIsOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("Remove Traveler Type")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Are you sure you want to remove "+_vm._s(_vm._f("capitalize")(_vm.travelerTypeToRemove.internalName))+" Traveler Type? ")]),_c('p',[_vm._v(" By removing this Traveler Type you are potentially changing data associated to a channel. Please validate and republish your data. ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.proceedRemove}},[_vm._v("Remove")]),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }