import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  CapacityPool,
  DisplayDate,
  Offer,
  OfferCapacityList,
  OfferPricing,
  OfferPricingList,
  Option,
  OptionCapacityPool,
  Product,
  ProductCapacityPool,
} from '@/models';
import { CapacityByUnit, CapacityType, TaxesAndFees } from '@/api/inventory/InventoryModels';
import _ from 'lodash';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-offer',
  namespaced: true,
})
class ModuleOffer extends VuexModule {
  /* Data */
  private loading = false;
  private productOfferPricingSetLoading = false;
  private search = '';
  private offer = {} as Offer;
  private offerPricing = {} as OfferPricing;
  private offers = [] as Array<Offer>;
  private offersCapacity = {} as OfferCapacityList;
  private product = {} as Product;
  private products = [] as Array<Product>;
  private productCapacity = {} as ProductCapacityPool;
  private option = {} as Option;
  private options = [] as Array<Option>;
  private optionCapacity = {} as OptionCapacityPool;
  private selectedCapacityType = '';
  private selectedCapacityProductOption = '';
  private selectedCapacityPulledFrom = '';
  private selectedCapacityAmount = 0;
  private selectedCapacityByUnits = [] as Array<CapacityByUnit>;
  private offerPricingList = {} as OfferPricingList;
  private selectedDisplayDate = null as DisplayDate | null;
  private selectedTnF = [] as Array<TaxesAndFees>;
  private editOfferTicket = false;
  private editOfferDescription = false;
  private editOfferPricing = false;
  private editOfferCalendar = false;
  private selectedOffersToPublish = [] as string[];

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get OffersCapacity(): OfferCapacityList {
    return this.offersCapacity;
  }

  get ProductOfferPricingSetLoading(): boolean {
    return this.productOfferPricingSetLoading;
  }

  get OffersToPublish() {
    return this.selectedOffersToPublish;
  }

  get Search(): string {
    return this.search;
  }

  get Offer() {
    return this.offer;
  }

  get Offers() {
    return this.offers;
  }

  get OfferPricing() {
    return this.offerPricing;
  }

  get Product(): Product {
    return this.product;
  }

  get Products(): Array<Product> {
    return this.products;
  }

  get ProductCapacityPool() {
    return this.productCapacity;
  }

  get Option(): Option {
    return this.option;
  }

  get Options(): Array<Option> {
    return this.options;
  }

  get OptionCapacityPool() {
    return this.optionCapacity;
  }

  get OfferPricings() {
    return this.offerPricingList;
  }

  get SelectedDisplayDate() {
    return this.selectedDisplayDate;
  }

  get SelectedCapacityType() {
    return this.selectedCapacityType;
  }

  get SelectedCapacityAmount() {
    return this.selectedCapacityAmount;
  }

  get SelectedCapacityProductOption() {
    return this.selectedCapacityProductOption;
  }

  get SelectedCapacityPulledFrom() {
    return this.selectedCapacityPulledFrom;
  }

  get SelectedCapacityByUnits() {
    return this.selectedCapacityByUnits;
  }

  get SelectedTaxesAndFees() {
    return this.selectedTnF;
  }

  get EditOfferTicketing() {
    return this.editOfferTicket;
  }

  get EditOfferDescription() {
    return this.editOfferDescription;
  }

  get EditOfferPricing() {
    return this.editOfferPricing;
  }

  get EditOfferCalendar() {
    return this.editOfferCalendar;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setProductOfferPricingSetLoading(loading: boolean) {
    this.productOfferPricingSetLoading = loading;
  }

  @Mutation
  setOffersCapacity(capacity: OfferCapacityList) {
    this.offersCapacity = capacity;
  }

  @Mutation
  setOffersToPublish(offers: string[]) {
    this.selectedOffersToPublish = offers;
  }

  @Mutation
  setSearch(search: string) {
    this.search = search;
  }

  @Mutation
  setOffer(offer: Offer) {
    this.offer = offer;
  }

  @Mutation
  setOffers(offers: Array<Offer> | null) {
    if (offers === null) this.offers = [];
    else
      this.offers = offers.map((offer: Offer) => {
        return {
          ...offer,
          open: offer.open ? offer.open : false,
        };
      });
  }

  @Mutation
  setOfferPricing(offerPricing: OfferPricing) {
    this.offerPricing = offerPricing;
  }

  @Mutation
  setProduct(product: Product) {
    this.product = product;
  }

  @Mutation
  setProducts(products: Array<Product>) {
    this.products = products;
  }

  @Mutation
  setProductCapacityPool(pool: ProductCapacityPool) {
    this.productCapacity = pool;
  }

  @Mutation
  setOptionCapacityPool(capacity: OptionCapacityPool) {
    this.optionCapacity = capacity;
  }

  @Mutation
  setOption(option: Option) {
    this.option = option;
  }

  @Mutation
  setOptions(options: Array<Option>) {
    this.options = options;
  }

  @Mutation
  setOfferPricings(offerPricingList: OfferPricingList) {
    if (offerPricingList) {
      if (Object.keys(offerPricingList).length === 0) {
        this.offerPricingList = {} as OfferPricingList;
        return;
      }
      this.offerPricingList = {
        filter: offerPricingList.filter,
        data: offerPricingList.data.map((item) => {
          return {
            ...item,
            open: item.open || false,
          };
        }),
      };
    }
  }

  @Mutation
  setSelectedTaxesAndFees(taxes: Array<TaxesAndFees>) {
    this.selectedTnF = taxes;
  }

  @Mutation
  setSelectedCapacityType(type: string) {
    this.selectedCapacityType = type;
  }

  @Mutation
  setSelectedCapacityProductOption(type: string) {
    this.selectedCapacityProductOption = type;
  }

  @Mutation
  setSelectedCapacityPulledFrom(pulledFrom: string) {
    this.selectedCapacityPulledFrom = pulledFrom;
  }

  @Mutation
  setSelectedCapacityAmount(amount: number) {
    this.selectedCapacityAmount = amount;
  }

  @Mutation
  setSelectedCapacityByUnits(capacity: Array<CapacityByUnit>) {
    this.selectedCapacityByUnits = capacity;
  }

  @Mutation
  setSelectedDisplayDate(date: DisplayDate | null) {
    this.selectedDisplayDate = date;
  }

  @Mutation
  setEditOfferTicketing(status: boolean) {
    this.editOfferTicket = status;
  }

  @Mutation
  setEditOfferDescription(status: boolean) {
    this.editOfferDescription = status;
  }

  @Mutation
  setEditOfferPricing(status: boolean) {
    this.editOfferPricing = status;
  }

  @Mutation
  setEditOfferCalendar(status: boolean) {
    this.editOfferCalendar = status;
  }

  /* Actions */
  @Action
  chooseOffer(offer: Offer) {
    this.setOffer(offer);
  }

  @Action
  removeOffer(id: string) {
    const i = this.offers.findIndex((offer: Offer) => offer.id === id);
    if (i > -1) {
      this.offers.splice(i, 1);
    }
  }

  @Action
  updateOffer(offer: Offer) {
    // todo http call to update offer
    const i = this.offers.findIndex((o: Offer) => o.id === offer.id);
    this.offers[i] = offer;
    this.chooseOffer(offer);
  }

  @Action
  chooseCapacityType() {
    try {
      const productCapacity =
        this.ProductCapacityPool && this.ProductCapacityPool.capacityPool
          ? this.ProductCapacityPool.capacityPool
          : ({} as CapacityPool);
      const optionCapacity =
        this.OptionCapacityPool && this.OptionCapacityPool.capacityPool
          ? this.OptionCapacityPool.capacityPool
          : ({} as CapacityPool);

      let productType = '';
      let optionType = '';
      let productAmount = 0;
      let optionAmount = 0;
      const productByUnitAmount = [] as Array<CapacityByUnit>;
      const optionByUnitAmount = [] as Array<CapacityByUnit>;

      if (_.isObjectLike(productCapacity.byUnit) && !_.isEmpty(productCapacity.byUnit)) {
        const keys = _.keys(productCapacity.byUnit);
        for (const key of keys) {
          const v = _.get(productCapacity.byUnit, key);
          const value = _.toNumber(v) || 0;
          // productAmount += value;
          productByUnitAmount.push({ id: key, amount: value } as CapacityByUnit);
        }
        productType = CapacityType.CapacityLimitByUnit;
      } else if (productCapacity.pooled && _.isNumber(productCapacity.pooled) && productCapacity.pooled > 0) {
        productAmount = productCapacity.pooled;
        productType = CapacityType.CapacityLimitPooled;
      } else {
        productAmount = -1;
        productType = CapacityType.CapacityLimitUnlimited;
      }

      if (_.isObjectLike(optionCapacity.byUnit) && !_.isEmpty(optionCapacity.byUnit)) {
        const keys = _.keys(optionCapacity.byUnit);
        for (const key of keys) {
          const v = _.get(optionCapacity.byUnit, key);
          const value = _.toNumber(v) || 0;
          // optionAmount += value;
          optionByUnitAmount.push({ id: key, amount: value } as CapacityByUnit);
        }
        optionType = CapacityType.CapacityLimitByUnit;
      } else if (optionCapacity.pooled && _.isNumber(optionCapacity.pooled) && optionCapacity.pooled > 0) {
        optionAmount = optionCapacity.pooled;
        optionType = CapacityType.CapacityLimitPooled;
      } else if (optionCapacity.unlimited) {
        optionAmount = -1;
        optionType = CapacityType.CapacityLimitUnlimited;
      }

      if (optionAmount > 0) {
        this.setSelectedCapacityAmount(optionAmount);
        this.setSelectedCapacityProductOption('option');
        this.setSelectedCapacityPulledFrom('option');
      } else if (productAmount > 0) {
        this.setSelectedCapacityAmount(productAmount);
        this.setSelectedCapacityProductOption('product');
        this.setSelectedCapacityPulledFrom('product');
      }

      if (optionByUnitAmount.length > 0) {
        this.setSelectedCapacityByUnits(optionByUnitAmount);
        this.setSelectedCapacityProductOption('option');
        this.setSelectedCapacityPulledFrom('option');
      } else if (productByUnitAmount.length > 0) {
        this.setSelectedCapacityByUnits(productByUnitAmount);
        this.setSelectedCapacityProductOption('product');
        this.setSelectedCapacityPulledFrom('product');
      }

      if (productType === optionType) {
        this.setSelectedCapacityType(productType);
      } else {
        if (productType === CapacityType.CapacityLimitByUnit || optionType === CapacityType.CapacityLimitByUnit) {
          this.setSelectedCapacityType(CapacityType.CapacityLimitByUnit);
        } else if (
          productType === CapacityType.CapacityLimitPooled ||
          optionType === CapacityType.CapacityLimitPooled
        ) {
          this.setSelectedCapacityType(CapacityType.CapacityLimitPooled);
        } else {
          this.setSelectedCapacityType(CapacityType.CapacityLimitUnlimited);
        }
      }
    } catch (e) {
      // todo send alert to backend
    }
  }

  @Action
  selectDisplayDate(date: DisplayDate) {
    this.setSelectedDisplayDate(date);
  }

  @Action
  toggleEditOfferTicketing() {
    this.setEditOfferTicketing(!this.EditOfferTicketing);
  }

  @Action
  toggleEditOfferDescription() {
    this.setEditOfferDescription(!this.EditOfferDescription);
  }

  @Action
  toggleEditOfferPricing() {
    this.setEditOfferPricing(!this.EditOfferPricing);
  }

  @Action
  toggleEditOfferCalendar() {
    this.setEditOfferCalendar(!this.EditOfferCalendar);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setProductOfferPricingSetLoading(false);
    this.setSearch('');
    this.setOffer({} as Offer);
    this.setOffers([]);
    this.setOfferPricing({} as OfferPricing);
    this.setProduct({} as Product);
    this.setProducts([]);
    this.setProductCapacityPool({} as ProductCapacityPool);
    this.setOptionCapacityPool({} as OptionCapacityPool);
    this.setOption({} as Option);
    this.setOptions([]);
    this.setSelectedCapacityProductOption('');
    this.setSelectedCapacityPulledFrom('');
    this.setSelectedCapacityAmount(0);
    this.setSelectedCapacityType('');
    this.setSelectedCapacityByUnits([]);
    this.setOfferPricings({} as OfferPricingList);
    this.setSelectedTaxesAndFees([]);
    this.setSelectedDisplayDate(null);
    this.setEditOfferTicketing(false);
    this.setEditOfferDescription(false);
    this.setEditOfferPricing(false);
    this.setEditOfferCalendar(false);
  }
}

export const offerModule = getModule(ModuleOffer, store);
