import { TravelerType } from './index';
import { OperatorLocationSchedule } from '@/models/OperatorLocation';

export interface IOperator {
  id: string;
  name: string;
  locations: IOperatorLocation[];
  travelerTypes: TravelerType[];
  contact: IOperatorMainContact;
  contacts: IOperatorContact[];
  products: IOperatorProduct[];
  options: IOperatorProductOption[];
  description: string;
  ext?: object;
}

export interface IOperatorProduct {
  id: string;
  name: string;
  status: ProductStatus;
  description: string;
  locationId: string;
  code: string;
  type: string; // todo enum freesell, timed
  activityTypeId: string;
}

export interface IOperatorProductOption {
  id: string;
  name: string;
  status: ProductStatus;
  description: string;
  productId: string;
  locationId: string;
  code: string;
  type: string; // todo enum freesell, timed
  activityTypeId: string;
}

export interface IOperatorLocation {
  id: string;
  name: string;
  type: string;
  description: string;
  streetAddress: string;
  city: string;
  stateProvince?: string;
  country: string;
  zipPostal: string;
  latitude?: string;
  longitude?: string;
  contact: IOperatorContact;
  schedules: OperatorLocationSchedule[];
}

export interface IOperatorMainContact {
  name: string;
  phone: string;
  email: string;
  website: string;
}

export interface IOperatorContact {
  id: string;
  firstName: string;
  lastName: string;
  description: string;
  contactType: string;
  email: string;
  countryCode: string;
  phone: string;
}

export interface IContactType {
  id: string;
  name: string;
  description: string;
}

export interface IGuestType {
  id: string;
  name: string;
  description: string;
}

export class GuestType implements IGuestType {
  id = '';
  name = '';
  description = '';

  constructor() {}
}

export enum ProductStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  PENDING = 3,
  DRAFT = -1,
}

export enum ConnectionStatus {
  ACTIVE = 1,
  DEACTIVATED = 2,
  NOT_CONNECTED = 0,
}

export class OperatorContact implements IOperatorContact {
  id = '';
  firstName = '';
  lastName = '';
  description = '';
  contactType = '';
  email = '';
  countryCode = '';
  phone = '';

  constructor() {}
}

export class OperatorLocationAddress implements IOperatorLocation {
  id = '';
  name = '';
  type = '';
  description = '';
  streetAddress = '';
  city = '';
  stateProvince = '';
  zipPostal = '';
  country = '';
  contact = new OperatorContact();
  schedules = new Array<OperatorLocationSchedule>();

  constructor() {}
}

export class OperatorLocationImpl implements IOperatorLocation {
  city = '';
  contact = new OperatorContact();
  country = '';
  description = '';
  id = '';
  name = '';
  schedules = [] as OperatorLocationSchedule[];
  stateProvince = '';
  streetAddress = '';
  type = '';
  zipPostal = '';

  constructor() {}
}

export class Operator implements IOperator {
  id = '';
  name = '';
  locations = [] as IOperatorLocation[];
  travelerTypes = [] as TravelerType[];
  contact = {} as IOperatorMainContact;
  contacts = [] as IOperatorContact[];
  products = [] as IOperatorProduct[];
  options = [] as IOperatorProductOption[];
  description = '';
  ext = {};
  constructor() {}
}
