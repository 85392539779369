






















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import { format, parseISO } from 'date-fns';
import { InventoryType } from '@/models';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { pricingModule } from '@/store/modules/modulePricing';
import { httpModule } from '@/store/modules/moduleHttp';
import AvailabilityStatusDayInventoryCard from '@/components/inventory/availability/AvailabilityStatusDayInventoryCard.vue';

@Component({
  components: {
    AvailabilityStatusDayInventoryCard,
  },
})
export default class AvailabilityStatusDayInventory extends Vue {
  private isLoading = false;
  private allBlackOut = false;

  mounted() {
    this.checkForAllBlackedOut();
  }

  checkForAllBlackedOut() {
    if (this.AvailItems) {
      this.allBlackOut =
        this.AvailItems.find((avail: any) => !avail.isBlackedOut) === undefined;
    } else this.allBlackOut = false;
  }

  closeSidebar() {
    availabilityStatusModule.toggleShowSidebar();
  }

  formatDate(d: string) {
    return format(parseISO(d), 'hh:mm a');
  }

  async toggleBlackout() {
    if (this.AvailItems) {
      this.isLoading = true;
      const reqs: any[] = [];
      const status = this.allBlackOut ? InventoryType.CLOSED : InventoryType.AVAILABLE;
      this.AvailItems.forEach((avail: any) => {
        const req = httpModule.updateAvailabilityStatus({ avail, status });
        reqs.push(req);
      });
      await Promise.all(reqs).finally(() => {
        this.isLoading = false;
        availabilityStatusModule.finishLoading();
      });
    }
  }

  get IsoDate() {
    if (this.CurrentDate) {
      return format(this.CurrentDate, 'yyyy-MM-dd');
    }
    return '';
  }

  @Watch('IsoDate')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onIsoDateChange(_value: string, _oldValue: string) {
    this.checkForAllBlackedOut();
  }

  get DayOfWeek() {
    if (this.CurrentDate) {
      return format(this.CurrentDate, 'eeee');
    }
    return '';
  }

  get CurrentDate() {
    if (
      availabilityStatusModule.SelectedDate &&
      availabilityStatusModule.SelectedDate.length > 0
    ) {
      return parseISO(availabilityStatusModule.SelectedDate);
    }
    return null;
  }

  get ScheduleName() {
    return scheduleModule.Schedules.find((s) => s.id === this.OfferCapacity.scheduleId)
      ?.name;
  }

  get InventoryName() {
    return this.OfferCapacity.name;
  }

  get AvailItems() {
    return availabilityStatusModule.AvailabilityItems.get(this.IsoDate);
  }
  get OfferCapacity() {
    return (
      pricingModule.OfferCapacityList.find(
        (oc) => oc.id === pricingModule.SelectedOfferCapacityId,
      ) || pricingModule.OfferCapacity
    );
  }
}
