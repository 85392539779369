





































































































































import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import { constants } from '@/utils/constants';
import { pricingModule } from '@/store/modules/modulePricing';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import _ from 'lodash';
import { Address, Option, Product } from '@/models';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';

@Component
export default class OfferCreateDetail extends Vue {
  private routeToCreateProduct = constants.routes.PRODUCT_CREATE;
  private routeToCreateOption = constants.routes.OPTION_CREATE;
  private requiredField = requiredFieldRules;
  private createProductDialog = false;
  private createOptionDialog = false;

  async mounted() {
    if (this.$refs && this.$refs.offerDetailForm) {
      const { offerDetailForm } = this.$refs as any;
      offerDetailForm.reset();
    }
    await httpModule.getOffers();
  }

  async onProductChange() {
    pricingModule.configurePolicies();
    await httpModule.productSchedules(this.SelectedOfferProductId);
  }

  async onOptionChange() {
    pricingModule.configurePolicies();
    await httpModule.optionSchedules(this.SelectedOfferOptionId);
  }

  goToProductCreate() {
    this.createProductDialog = false;
    this.$router.push({ name: this.routeToCreateProduct }).catch();
  }

  goToOptionCreate() {
    this.createOptionDialog = false;
    this.$router.push({ name: this.routeToCreateOption }).catch();
  }

  getPrimaryAddress(id: string): string {
    const address = this.CompanyAddresses.find((a: Address) => a.id === id);
    if (address) {
      return `${address.name}`;
    }

    return '';
  }

  get Step1Open() {
    return pricingModule.CreateStep1Open;
  }

  get Step1Done() {
    return pricingModule.CreateStep1Done;
  }

  get Step2Done() {
    return pricingModule.CreateStep2Done;
  }

  get Form1Valid() {
    return pricingModule.CreateForm1Valid;
  }

  set Form1Valid(valid: boolean) {
    pricingModule.setCreateForm1Valid(valid);
  }

  get Products() {
    return productModule.Products.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get Options() {
    return optionModule.Options.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get SelectedProductName() {
    if (this.SelectedProduct && !_.isEmpty(this.SelectedProduct)) {
      const product = this.Products.find(
        (product) => product.id === this.SelectedOfferProductId,
      );
      if (product) {
        return product.internalName || '';
      }
    }
    return '';
  }

  get SelectedOptionName() {
    if (this.SelectedOption && !_.isEmpty(this.SelectedOption)) {
      const option = this.Options.find((o) => o.id === this.SelectedOfferOptionId);
      if (option) {
        return option.internalName || '';
      }
    }
    return '';
  }

  get SelectedProduct() {
    return pricingModule.SelectedOfferProduct;
  }

  set SelectedProduct(product: Product) {
    pricingModule.setSelectedProduct(product);
  }

  get SelectedOption() {
    return pricingModule.SelectedOfferOption;
  }

  set SelectedOption(option: Option) {
    pricingModule.setSelectedOption(option);
  }

  get SelectedOfferProductId() {
    return pricingModule.SelectedOfferProductId;
  }

  set SelectedOfferProductId(id: string) {
    pricingModule.setSelectedOfferProductId(id);
  }

  get SelectedOfferOptionId() {
    return pricingModule.SelectedOfferOptionId;
  }

  set SelectedOfferOptionId(id: string) {
    pricingModule.setSelectedOfferOptionId(id);
  }

  get Offers() {
    return pricingModule.Offers;
  }

  get OfferExists() {
    return pricingModule.HasOfferProductOptionCombo;
  }

  get CompanyAddresses() {
    return companyModule.Addresses;
  }
}
