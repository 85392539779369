























































































































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import { channelModule } from '@/store/modules/moduleChannel';
import _ from 'lodash';
import {
  incrementMeta,
  centsToDollars,
  dollarsToCents,
  incrementVersion,
  decimalToPercent,
  percentToDecimal,
} from '@/utils/helpers';
import { httpModule } from '@/store/modules/moduleHttp';
import { requiredNotLessThenPrice } from '@/utils/validation-rules';

@Component
export default class RateCardAdjustmentsView extends Vue {
  private formState = false;
  private editModal = false;
  private source: string | undefined = '';
  private destination: string | undefined = '';
  private type: string | undefined = '';
  private value: number | null = 0;
  private requiredNotLessThenPrice = requiredNotLessThenPrice;

  mounted() {
    this.source = this.RateCard.adjustment?.source;
    this.destination = this.RateCard.adjustment?.destination;
    this.type = this.RateCard.adjustment?.calculationType;
    this.value = this.AdjustmentValue;
  }

  openEditModal() {
    this.editModal = true;
  }

  closeEditModal() {
    this.editModal = false;
  }

  async validate() {
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (isValid) {
        await this.saveChanges();
      }
    }
  }

  async saveChanges() {
    const value =
      this.type === 'FLAT'
        ? dollarsToCents(Number(this.value))
        : percentToDecimal(Number(this.value));
    const updatedRateCard = {
      ...this.RateCard,
      meta: incrementMeta(this.RateCard.meta),
      adjustment: {
        source: this.source,
        destination: this.destination,
        calculationType: this.type,
        value: value,
      },
      version: incrementVersion(this.RateCard.meta),
    };
    await httpModule.updateRateCard(updatedRateCard);
    await httpModule.publishRateCard(updatedRateCard.id);
    await httpModule.getRateCard(this.$route.params?.id);
  }

  get Product() {
    return productModule.Product;
  }

  get RateCard() {
    return channelModule.RateCard;
  }

  get Source() {
    if (
      this.RateCard &&
      this.RateCard.adjustment &&
      _.isString(this.RateCard.adjustment.source)
    ) {
      return this.RateCard.adjustment.source;
    }
    return '';
  }

  get Destination() {
    if (
      this.RateCard &&
      this.RateCard.adjustment &&
      _.isString(this.RateCard.adjustment.destination)
    ) {
      return this.RateCard.adjustment.destination;
    }
    return '';
  }

  get AdjustmentType() {
    if (
      this.RateCard &&
      this.RateCard.adjustment &&
      _.isString(this.RateCard.adjustment.calculationType)
    ) {
      return this.RateCard.adjustment.calculationType;
    }
    return '';
  }

  get AdjustmentValue() {
    if (
      this.RateCard &&
      this.RateCard.adjustment &&
      _.isNumber(this.RateCard.adjustment.value)
    ) {
      return this.AdjustmentType === 'FLAT'
        ? centsToDollars(this.RateCard.adjustment.value)
        : decimalToPercent(this.RateCard.adjustment.value);
    }
    return 0;
  }
}
