import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { GRSchedule, Schedule } from '@/models';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-schedule',
  namespaced: true,
})
class ModuleSchedule extends VuexModule {
  private loading = false;
  private schedule = {} as Schedule;
  private schedules = {} as Array<GRSchedule>;
  private default = {} as GRSchedule;
  private createSchedule = false;
  private groupedDays = [] as any;
  private individualDays = [] as any;
  private timeEntryMode = '';
  private schedulesData = [] as any;
  private scheduleData = {
    name: '',
    validStart: '',
    validEnd: '',
  };

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get TimeEntryMode(): string {
    return this.timeEntryMode;
  }

  get ScheduleData() {
    return this.scheduleData;
  }

  get Schedule(): Schedule {
    return this.schedule;
  }

  get Schedules(): Array<GRSchedule> {
    return this.schedules;
  }

  get DefaultSchedule(): GRSchedule {
    return this.default;
  }

  get CreateSchedule(): boolean {
    return this.createSchedule;
  }

  get GroupedDays() {
    return this.groupedDays;
  }

  get IndividualDays() {
    return this.individualDays;
  }

  /* Setters */

  @Mutation
  setTimeEntryMode(mode: string) {
    this.timeEntryMode = mode;
  }

  @Mutation
  setScheduleData(data: any) {
    this.scheduleData = data;
  }

  @Mutation
  setGroupedDays(groupedDays: any) {
    this.groupedDays = groupedDays;
  }

  @Mutation
  setIndividualDays(individualDays: any) {
    this.individualDays = individualDays;
  }

  @Mutation
  setCreateSchedule() {
    this.createSchedule = !this.createSchedule;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setSchedule(schedule: Schedule) {
    this.schedule = schedule;
  }

  @Mutation
  setSchedules(schedules: Array<GRSchedule>) {
    this.schedules = schedules;
  }

  @Mutation
  setDefaultSchedule(schedule: GRSchedule) {
    this.default = schedule;
  }
  /* Actions */

  @Action
  updateTimeEntryMode(mode: string) {
    this.setTimeEntryMode(mode);
  }
  @Action
  updateGroupedDays(grouped: any) {
    this.setGroupedDays(grouped);
  }

  @Action
  updateIndividualDays(individual: any) {
    this.setIndividualDays(individual);
  }

  @Action
  toggleCreateSchedule() {
    this.setCreateSchedule();
    this.setScheduleData({
      name: '',
      validStart: '',
      validEnd: '',
    });
    this.setSchedule({} as Schedule);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }
  @Action
  attachSchedule(schedule: Schedule) {
    this.setSchedule(schedule);
  }
  @Action
  clearState() {
    this.setLoading(false);
    if (this.createSchedule) this.toggleCreateSchedule();
    this.setGroupedDays([]);
    this.setIndividualDays([]);
    // this.setSchedule({} as Schedule);
    this.setScheduleData({
      name: '',
      validStart: '',
      validEnd: '',
    });
  }
}
export const scheduleModule = getModule(ModuleSchedule, store);
