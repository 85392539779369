


































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { constants } from '@/utils/constants';
import { requiredFieldRules, requiredStateRules } from '@/utils/validation-rules';
import _ from 'lodash';
import faker from 'faker';
import { companyModule } from '@/store/modules/moduleCompany';
import { httpModule } from '@/store/modules/moduleHttp';
import { Address } from '@/models/ModelsOppInventory';
import { appModule } from '@/store/modules/moduleApp';
import { filterCodeAndName } from '@/utils/helpers';
import { profileModule } from '@/store/modules/moduleProfile';

@Component({
  components: {
    RdmReturnToBtn,
  },
})
export default class CompanyLocationEdit extends Vue {
  private address = {} as Address;
  private formValid = false;
  private saving = false;
  private routeToCompanyLocations = constants.routes.LOCATIONS;
  private requiredField = requiredFieldRules;
  private requiredState = requiredStateRules;
  private filterCodeAndName = filterCodeAndName;

  mounted() {
    if (
      !this.Company ||
      !this.Company.orgId ||
      (this.Company && this.Company.orgId && this.Company.orgId.length === 0)
    ) {
      this.$router.push({ name: constants.routes.COMPANY }).catch(() => {});
    }

    if (this.$route.name === constants.routes.LOCATION_EDIT) {
      this.address = companyModule.SelectedAddress
        ? _.cloneDeep(companyModule.SelectedAddress)
        : ({} as Address);
      if (!this.address.id || (this.address.id && this.address.id.length === 0)) {
        this.address.id = faker.datatype.uuid();
      }
    }

    if (this.$route.name === constants.routes.LOCATION_ADD) {
      this.address.managed = false;
    }
  }

  validate() {
    if (this.$refs?.editLocationForm) {
      const form: any = this.$refs.editLocationForm;
      const isValid = form.validate();
      if (isValid) {
        this.saveSupplier();
      }
    }
  }

  async saveSupplier() {
    this.saving = true;
    await this.configLatLong();
  }

  async configAddress() {
    try {
      if (_.isEmpty(this.address.id)) {
        this.address.id = faker.datatype.uuid();
      }
      await httpModule
        .updateCompanyAddress(this.address)
        .then(() => {
          this.$router.push({ name: constants.routes.LOCATIONS }).catch(() => {});
        })
        .catch(() => {
          // todo send alert
        });
      // const isEditMode = this.$route.name === constants.routes.LOCATION_EDIT;
      // this.address.version = isEditMode ? this.address.version + 1 : 1;
      // if (isEditMode) {
      //   await httpModule
      //     .updateCompanyAddress(this.address)
      //     .then(() => {
      //       this.$router.push({ name: constants.routes.LOCATIONS }).catch(() => {});
      //     })
      //     .catch(() => {
      //       // todo send alert
      //     });
      // } else {
      //   this.address.id = faker.datatype.uuid();
      //   await httpModule
      //     .createCompanyAddress(this.address)
      //     .then(() => {
      //       this.$router.push({ name: constants.routes.LOCATIONS }).catch(() => {});
      //     })
      //     .catch(() => {
      //       // todo send alert
      //     });
      // }
    } catch {
      // todo call backend
    } finally {
      this.saving = false;
      // todo save done
    }
  }

  convertAddressToString() {
    return `${this.address.streetAddress}, ${this.address.city}, ${this.address.region}`;
  }

  async configLatLong() {
    try {
      if (window.google && window.google.maps) {
        const query = this.convertAddressToString();
        if (!query) return;
        const request = {
          query,
          fields: ['name', 'geometry'],
        };
        const service = new window.google.maps.places.PlacesService(
          this.$refs['placeService'] as HTMLDivElement,
        );
        await service.findPlaceFromQuery(
          request,
          (
            results: google.maps.places.PlaceResult[] | null,
            status: google.maps.places.PlacesServiceStatus,
          ) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
              if (results[0] && results[0].geometry && results[0].geometry.location) {
                this.address.latitude = results[0].geometry?.location.lat();
                this.address.longitude = results[0].geometry?.location.lng();
                // this.configSupplier();
                this.configAddress();
                return;
              }
            }
            // fallthrough condition
            this.address.latitude = 0;
            this.address.longitude = 0;
            this.configAddress();
          },
        );
      }
    } catch {
      this.address.latitude = 0;
      this.address.longitude = 0;
      await this.configAddress();
    }
  }

  discard() {
    // companyModule.setSupplier(this.baseSupplier);
    this.$router.push({ name: constants.routes.LOCATIONS }).catch(() => {});
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Company() {
    return companyModule.Company;
  }

  get Countries() {
    return appModule.CountryData;
  }

  get SelectedAddress() {
    if (!companyModule.SelectedAddress) {
      return {} as Address;
    }
    return companyModule.SelectedAddress;
  }

  get IsAdmin() {
    return profileModule.IsOrgAdmin;
  }
}
