import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Account as UserProfile } from '@/api/wps/BackplaneModels';
import { constants, Role } from '@/utils/constants';
import Vue from 'vue';
import { companyModule } from './moduleCompany';
import _ from 'lodash';

export function IsRole(me: UserProfile | null, role: Role): boolean {
  const userRoles = me?.groups;
  return !!(userRoles && userRoles.findIndex((group) => group.name === role) > -1);
}

export function IsAllRole(me: UserProfile | null, roles: Array<Role> | undefined): boolean {
  const profile = _.map(me?.groups, 'name') as Array<string>;
  const hasRoles = [] as Array<boolean>;
  if (roles) {
    roles.forEach((role) => {
      hasRoles.push(_.includes(profile, role));
    });
  }
  const u = _.uniq(hasRoles);
  return u.length === 1 && u[0];
}

export function HasAnyRole(me: UserProfile | null, roles: Array<Role> | undefined): Array<boolean> {
  const profile = _.map(me?.groups, 'name') as Array<string>;
  const roleExist = [] as Array<boolean>;
  if (roles) {
    roles.forEach((role) => {
      if (_.includes(profile, role)) {
        roleExist.push(true);
      }
    });
  }
  return roleExist;
}

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-profile',
  namespaced: true,
})
class ModuleProfile extends VuexModule {
  /* Data */
  private loading = false;
  private profile: UserProfile | null = null;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get Profile(): UserProfile | null {
    return this.profile;
  }

  get IsRedemptionRole(): boolean {
    return IsRole(this.profile, constants.roles.SUPPLIER_REDEMPTION_STAFF) || this.IsAdmin;
  }

  get IsTicketingEditRole(): boolean {
    return IsRole(this.profile, constants.roles.RC_TICKETING_EDIT) || this.IsAdmin;
  }

  get IsReportUpcomingArrivalsRole(): boolean {
    return IsRole(this.profile, constants.roles.SUPPLIER_REPORTING_STAFF) || this.IsAdmin;
  }

  get IsReportAllBookingRole(): boolean {
    return IsRole(this.profile, constants.roles.SUPPLIER_REPORTING_ALL_BOOKING_STAFF) || this.IsAdmin;
  }

  get IsReportCustomerManifestRole(): boolean {
    return IsRole(this.profile, constants.roles.SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF) || this.IsAdmin;
  }

  get IsReportRedemptionsRole(): boolean {
    return IsRole(this.profile, constants.roles.SUPPLIER_REPORTING_REDEMPTIONS_STAFF) || this.IsAdmin;
  }

  get IsOrgAdmin(): boolean {
    return IsRole(this.profile, constants.roles.ORG_MGMT) || this.IsAdmin;
  }

  get IsAdmin(): boolean {
    return IsRole(this.profile, constants.roles.REDEAM_TECHNICAL_STAFF);
  }

  get IsBetaUser(): boolean {
    return IsRole(this.profile, constants.roles.RC_CONNECTIVITY_MANAGEMENT);
  }

  get IsTicketingUser(): boolean {
    return IsRole(this.profile, constants.roles.RC_TICKETING_EDIT);
  }

  get OrgId(): string | undefined {
    return companyModule.Company.orgId;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setProfile(profile: UserProfile | null) {
    this.profile = profile;
  }

  /* Actions */

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(true);
  }

  @Action
  clearProfile() {
    this.setProfile(null);
    Vue.$cookies.remove(constants.ACCESS_TOKEN);
    Vue.$cookies.remove(constants.REFRESH_TOKEN);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.clearProfile();
  }
}

export const profileModule = getModule(ModuleProfile, store);
