





















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmReturnToBtn from '../../components/common/button/RdmReturnToBtn.vue';
import { constants } from '@/utils/constants';
import RdmEditNavBtn from '@/components/common/button/RdmEditNavBtn.vue';
import MapBox from '@/components/MapBox.vue';
import _ from 'lodash';
import { companyModule } from '@/store/modules/moduleCompany';
import { Address } from '@/models/ModelsOppInventory';
import { requiredFieldRules, requiredStateRules } from '@/utils/validation-rules';
import faker from 'faker';
import { httpModule } from '@/store/modules/moduleHttp';
import {
  handleKeyDownPreventDefault,
  filterCodeAndName,
  findCountryName,
} from '@/utils/helpers';
import { profileModule } from '@/store/modules/moduleProfile';
import { appModule } from '@/store/modules/moduleApp';

@Component({
  components: {
    MapBox,
    RdmEditNavBtn,
    RdmReturnToBtn,
  },
})
export default class CompanyLocationView extends Vue {
  private address = {} as Address;
  private routeToCompanyLocations = constants.routes.LOCATIONS;
  private requiredFieldRules = requiredFieldRules;
  private requiredState = requiredStateRules;
  private handleKeyDownPreventDefault = handleKeyDownPreventDefault;
  private findCountryName = findCountryName;
  private filterCodeAndName = filterCodeAndName;
  private savingLocationName = false;
  private savingLocationAddress = false;
  private savingLocationPlaceId = false;
  private locationNameValid = false;
  private locationAddressValid = false;
  private locationPlaceIdValid = false;

  mounted() {
    if (!companyModule.SelectedAddress || !companyModule.SelectedAddress.id) {
      this.$router.push({ name: this.routeToCompanyLocations });
      return;
    }
    this.address = _.cloneDeep(companyModule.SelectedAddress || {});
  }

  toggleLocationName() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeLocationName();
  }

  cancelLocationName() {
    this.address = _.cloneDeep(companyModule.SelectedAddress || {});
    companyModule.toggleEditModeLocationName();
  }

  async validateLocationName() {
    if (this.$refs?.editLocationNameForm) {
      const form: any = this.$refs.editLocationNameForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingLocationName = true;
        // todo await this.configLatLong();
        // await this.configContact();
        await this.configLatLong();
        companyModule.setEditModeLocationName(false);
        this.savingLocationName = false;
      }
    }
  }

  toggleLocationPlaceId() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeLocationPlaceId();
  }

  cancelLocationPlaceId() {
    this.address = _.cloneDeep(companyModule.SelectedAddress || {});
    companyModule.toggleEditModeLocationPlaceId();
  }

  async validateLocationPlaceId() {
    if (this.$refs?.editLocationPlaceIdForm) {
      const form: any = this.$refs.editLocationPlaceIdForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingLocationPlaceId = true;
        // todo await this.configLatLong();
        // await this.configContact();
        await this.configLatLong();
        companyModule.setEditModeLocationPlaceId(false);
        this.savingLocationPlaceId = false;
      }
    }
  }

  toggleLocationAddress() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeLocationAddress();
  }

  cancelLocationAddress() {
    this.address = _.cloneDeep(companyModule.SelectedAddress || {});
    companyModule.toggleEditModeLocationAddress();
  }

  async validateLocationAddress() {
    if (this.$refs?.editLocationAddressForm) {
      const form: any = this.$refs.editLocationAddressForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingLocationAddress = true;
        // todo await this.configLatLong();
        // await this.configContact();
        await this.configLatLong();
        companyModule.setEditModeLocationAddress(false);
        this.savingLocationAddress = false;
      }
    }
  }

  async configAddress() {
    try {
      if (_.isEmpty(this.address.id)) {
        this.address.id = faker.datatype.uuid();
      }
      await httpModule
        .updateCompanyAddress(this.address)
        .then(() => {
          // this.$router.push({ name: constants.routes.LOCATIONS }).catch(() => {});
        })
        .catch(() => {
          // todo send alert
        });
    } catch {
      // todo call backend
    } finally {
      // this.saving = false;
      // todo save done
    }
  }

  convertAddressToString() {
    return `${this.address.streetAddress}, ${this.address.city}, ${this.address.region}`;
  }

  async configLatLong() {
    try {
      if (window.google && window.google.maps) {
        const query = this.convertAddressToString();
        if (!query) return;
        const request = {
          query,
          fields: ['name', 'geometry'],
        };
        const service = new window.google.maps.places.PlacesService(
          this.$refs['placeService'] as HTMLDivElement,
        );
        await service.findPlaceFromQuery(
          request,
          (
            results: google.maps.places.PlaceResult[] | null,
            status: google.maps.places.PlacesServiceStatus,
          ) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
              if (results[0] && results[0].geometry && results[0].geometry.location) {
                this.address.latitude = results[0].geometry?.location.lat();
                this.address.longitude = results[0].geometry?.location.lng();
                // this.configSupplier();
                this.configAddress();
                return;
              }
            }
            // fallthrough condition
            this.address.latitude = 0;
            this.address.longitude = 0;
            this.configAddress();
          },
        );
      }
    } catch {
      this.address.latitude = 0;
      this.address.longitude = 0;
      await this.configAddress();
    }
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get EditModeLocationName() {
    return companyModule.EditModeLocationName;
  }

  get EditModeLocationPlaceId() {
    return companyModule.EditModeLocationPlaceId;
  }

  get EditModeLocationAddress() {
    return companyModule.EditModeLocationAddress;
  }

  get Countries() {
    return appModule.CountryData;
  }

  get Loading() {
    return companyModule.Loading;
  }

  get IsOrgAdmin() {
    return profileModule.IsOrgAdmin;
  }
}
