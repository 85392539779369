



























































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { requiredFieldRules } from '@/utils/validation-rules';
import { Product, TripRoute } from '@/models';
import { productModule } from '@/store/modules/moduleProduct';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import _ from 'lodash';
import { constants } from '@/utils/constants';
import { profileModule } from '@/store/modules/moduleProfile';
import { userModule } from '@/store/modules/moduleUser';

@Component({
  components: {
    RdmCollapsableControlCard,
  },
})
export default class ProductCreateLocation extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private product = {} as Product;
  private tripRoute = {
    departure: '',
    arrival: '',
    stops: [] as Array<string>,
  } as TripRoute;

  nextStepValidate2(done: boolean) {
    if (this.$refs?.locationForm) {
      const locationForm: any = this.$refs.locationForm;
      const isValid = locationForm.validate();
      if (isValid) {
        this.createProduct(done);
      }
    }
  }

  createProduct(done: boolean) {
    const map = new Map(Object.entries(this.LocationForm));
    this.product = _.cloneDeep(this.Product);
    this.tripRoute = Object(map.get('tripRoute'));

    productModule.setProduct(this.product);

    httpModule
      .updateAddProduct()
      .then(() => {
        if (
          this.tripRoute &&
          this.tripRoute.departure &&
          _.isString(this.tripRoute.departure) &&
          this.tripRoute.departure.length > 0 &&
          this.tripRoute.arrival &&
          _.isString(this.tripRoute.arrival) &&
          this.tripRoute.arrival.length > 0
        ) {
          productModule.setTripRoute(this.tripRoute);
          httpModule
            .updateProductTripRoute()
            .then(() => {
              if (done) {
                this.goToProductDetails();
              } else {
                this.openPolicyStep();
              }
            })
            .catch();
        } else {
          if (done) {
            this.goToProductDetails();
          } else {
            this.openPolicyStep();
          }
        }
      })
      .catch(() => {
        // todo send alert
      });
  }

  discard() {
    this.$router.push({ name: constants.routes.PRODUCT_LIST }).catch();
  }

  openPolicyStep() {
    productModule.nextCreateProductStep();
    productModule.setCreateProductLocationOpen(false);
    productModule.setCreateProductPolicyOpen(true);
  }

  toggleLocationOpen() {
    productModule.setCreateProductLocationOpen(!this.LocationOpen);
    if (
      this.LocationForm &&
      this.LocationForm.timezone &&
      (this.LocationForm.timezone.length === 0 || !this.LocationForm.timezone)
    ) {
      if (this.$refs?.locationForm) {
        const locationForm: any = this.$refs.locationForm;
        locationForm.reset();
      }
    }
  }

  goToProductDetails() {
    this.product = _.cloneDeep(this.Product);
    this.$router
      .push({ name: constants.routes.PRODUCT_DETAILS, params: { id: this.product.id } })
      .catch();
  }

  /* Page Data */

  get Company() {
    return companyModule.Company;
  }

  get Addresses() {
    if (this.IsOrgAdmin) return companyModule.Addresses;
    else
      return companyModule.Addresses.filter((location: any) => {
        return (
          this.OperatorUser?.config.managedLocation.includes(location.id) ||
          !location.managed
        );
      });
  }

  get OperatorUser() {
    return userModule.OperatorUser;
  }

  get IsOrgAdmin() {
    return profileModule.IsOrgAdmin;
  }

  get Product() {
    return productModule.Product;
  }

  get CreateProductLocationFormState(): boolean {
    return productModule.CreateProductLocationFormState;
  }

  set CreateProductLocationFormState(state: boolean) {
    productModule.setCreateProductLocationFormState(state);
  }

  get LocationForm(): any {
    return productModule.CreateProductLocationForm;
  }

  set LocationForm(form: any) {
    productModule.setCreateProductLocationForm(form);
  }

  /* UI State */
  get Step() {
    return productModule.CreateProductStep;
  }

  get Saving() {
    return productModule.CreateProductSaving;
  }

  get LocationOpen() {
    return productModule.CreateProductLocationOpen;
  }

  get HasArrivalDeparture() {
    return (
      (this.LocationForm &&
        this.LocationForm.tripRoute &&
        _.isString(this.LocationForm.tripRoute.departure) &&
        this.LocationForm.tripRoute.departure.length > 0) ||
      (this.LocationForm &&
        this.LocationForm.tripRoute &&
        _.isString(this.LocationForm.tripRoute.arrival) &&
        this.LocationForm.tripRoute.arrival.length > 0)
    );
  }
}
