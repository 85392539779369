


























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { constants } from '@/utils/constants';
import _ from 'lodash';
import {
  emailRules,
  requiredFieldRules,
  requiredStateRules,
} from '@/utils/validation-rules';
import faker from 'faker';
import { companyModule } from '@/store/modules/moduleCompany';
import { httpModule } from '@/store/modules/moduleHttp';
import { OrganizationRS } from '@/api/wps/WpsModels';
import {
  Address,
  Contact,
  ContactEmail,
  ContactPhone,
  Company,
  MessageType,
} from '@/models';
import { appModule } from '@/store/modules/moduleApp';
import { handleKeyDownValidPhoneCharacter } from '@/utils/helpers';

@Component({
  components: {
    RdmReturnToBtn,
  },
})
export default class CompanyEdit extends Vue {
  private routeCompanyAbout = constants.routes.COMPANY;
  private formValid = false;
  private companyName = '';
  private companyWebsite = '';
  private contact = {} as Contact;
  private email = {} as ContactEmail;
  private phone = {} as ContactPhone;
  private address = {} as Address;
  private showDialog = false;
  private resultList = [] as google.maps.places.PlaceResult[];
  private selectedResult = {} as google.maps.places.PlaceResult;
  private handleValidPhoneCharacters = handleKeyDownValidPhoneCharacter;
  // private baseSupplier = {} as Supplier;
  // private contact = {} as SupplierContact;
  // private location = {} as SupplierLocation;
  private requiredField = requiredFieldRules;
  private emailFieldRules = emailRules;
  private requiredState = requiredStateRules;

  mounted() {
    const editCompanyForm = this.$refs.editCompanyForm as any;
    if (!_.isUndefined(editCompanyForm)) {
      editCompanyForm.reset();
    }
    this.companyName = _.cloneDeep(this.Company.name);
    this.companyWebsite = _.cloneDeep(this.Company.website);

    this.email =
      this.PrimaryContact.emails && this.PrimaryContact.emails.length > 0
        ? _.cloneDeep(this.PrimaryContact.emails[0])
        : ({} as ContactEmail);
    this.phone =
      this.PrimaryContact.phones && this.PrimaryContact.phones.length > 0
        ? _.cloneDeep(this.PrimaryContact.phones[0])
        : ({} as ContactPhone);
    this.address =
      this.PrimaryContact.addresses && this.PrimaryContact.addresses.length > 0
        ? _.cloneDeep(this.PrimaryContact.addresses[0])
        : ({} as Address);
    this.contact =
      this.PrimaryContact && this.PrimaryContact.id && this.PrimaryContact.id.length > 0
        ? this.PrimaryContact
        : ({} as Contact);
  }

  validate() {
    if (this.$refs?.editCompanyForm) {
      const form: any = this.$refs.editCompanyForm;
      const isValid = form.validate();
      if (isValid) {
        this.saveCompany();
      }
    }
  }

  async saveCompany() {
    await this.configLatLong();
  }

  async configCompany() {
    // todo create company ID
    const orgId = this.Org && this.Org.ID ? this.Org.ID : faker.datatype.uuid();
    const company = {
      orgId: orgId,
      name: this.companyName,
      website: this.companyWebsite,
      meta: _.cloneDeep(this.Company.meta),
    } as Company;
    companyModule.configCompany(company);
    httpModule
      .updateCompany()
      .then(() => {
        this.updateContact();
      })
      .catch();
  }

  updateContact() {
    if (this.email && this.email.email && this.email.email.length > 0) {
      this.email.type = 'PRIMARY';
      this.contact.emails = [this.email];
    }

    if (this.phone && this.phone.number && this.phone.number.length > 0) {
      this.phone.type = 'PRIMARY';
      this.contact.phones = [this.phone];
    }

    if (
      this.address &&
      this.address.streetAddress &&
      this.address.streetAddress.length > 0
    ) {
      this.address.id = faker.datatype.uuid();
      this.address.version = 1;
      this.contact.addresses = [this.address];
    }

    if (!this.contact.id || (this.contact.id && this.contact.id.length === 0)) {
      this.contact.id = faker.datatype.uuid();
    }

    this.contact.primary = true;

    httpModule.updateContact(this.contact).then(() => {
      this.$router.push({ name: constants.routes.COMPANY }).catch();
    });
  }

  convertAddressToString() {
    return `${this.address.streetAddress}, ${this.address.city}, ${this.address.region}`;
  }

  selectConfigLatLong() {
    this.showDialog = false;
    this.address.latitude = this.selectedResult.geometry?.location?.lat() || 0;
    this.address.longitude = this.selectedResult.geometry?.location?.lng() || 0;
    this.configCompany();
  }

  async configLatLong() {
    try {
      if (window.google && window.google.maps) {
        const query = this.convertAddressToString();
        if (!query) return;
        const request = {
          query,
          fields: ['name', 'geometry'],
        };
        const service = new window.google.maps.places.PlacesService(
          this.$refs['placeService'] as HTMLDivElement,
        );
        await service.findPlaceFromQuery(
          request,
          (
            results: google.maps.places.PlaceResult[] | null,
            status: google.maps.places.PlacesServiceStatus,
          ) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
              this.resultList = results;
              if (this.resultList.length > 1) {
                this.showDialog = true;
              } else if (
                results &&
                results[0] &&
                results[0].geometry &&
                results[0].geometry.location
              ) {
                this.address.latitude = results[0].geometry?.location.lat();
                this.address.longitude = results[0].geometry?.location.lng();
                this.configCompany();
              } else {
                appModule.addRdmMessage({
                  id: faker.datatype.uuid(),
                  active: true,
                  message: {
                    code: 99999,
                    text: 'Location Not found',
                    type: MessageType.ERROR,
                  },
                  permanent: false,
                });
              }
            }
          },
        );
      }
    } catch {
      this.address.latitude = 0;
      this.address.longitude = 0;
    }
  }

  discard() {
    // companyModule.setSupplier(this.baseSupplier); // todo base company
    this.$router.push({ name: constants.routes.COMPANY }).catch();
  }

  get Company() {
    return companyModule.Company;
  }

  get PrimaryContact() {
    return companyModule.PrimaryContact;
  }

  get Org() {
    if (!companyModule.Organization) {
      return {} as OrganizationRS;
    }

    return companyModule.Organization;
  }

  get Loading() {
    return companyModule.Loading;
  }

  get HasSelectedAddress() {
    return (
      this.selectedResult &&
      this.selectedResult.geometry &&
      this.selectedResult.geometry.location &&
      this.selectedResult.geometry.location.lat() &&
      this.selectedResult.geometry.location.lng()
    );
  }
}
