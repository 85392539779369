








































import { Component, Vue } from 'vue-property-decorator';
import { capacityTypeLabel } from '@/filters/hints';
import { CapacityPool } from '@/models';
import { CapacityType } from '@/api/inventory/InventoryModels';
import { offerModule } from '@/store/modules/moduleOffer';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { isEmpty } from '@/utils/helpers';

@Component
export default class OfferEditInventoryType extends Vue {
  private capacityUnlimited = CapacityType.CapacityLimitUnlimited;
  private capacityLimitPooled = CapacityType.CapacityLimitPooled;
  private capacityLimitByUnit = CapacityType.CapacityLimitByUnit;
  private isEmpty = isEmpty;

  unitIdToTravelerName(id: string) {
    const i = this.DisplayTravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.DisplayTravelerTypes[i].text;
    }
    return '';
  }

  get Offer() {
    return offerModule.Offer;
  }

  get Product() {
    return offerModule.Product;
  }

  get Option() {
    return offerModule.Option;
  }

  get Loading() {
    return offerModule.Loading;
  }

  get InventoryType() {
    if (this.Product.availabilityType) {
      return capacityTypeLabel(this.Product.availabilityType);
    } else return '';
  }

  get DisplayTravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get CapacityPool() {
    if (offerModule.ProductCapacityPool && offerModule.ProductCapacityPool.capacityPool) {
      return offerModule.ProductCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get CapacityPoolByUnit() {
    if (this.CapacityPool && this.CapacityPool.byUnit) {
      return this.CapacityPool.byUnit;
    }
    return {};
  }

  get OptionCapacityPool() {
    if (offerModule.OptionCapacityPool && offerModule.OptionCapacityPool.capacityPool) {
      return offerModule.OptionCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get OptionCapacityPoolByUnit() {
    if (this.OptionCapacityPool && this.OptionCapacityPool.byUnit) {
      return this.OptionCapacityPool.byUnit;
    }
    return {};
  }

  get SelectedCapacityAmount() {
    return offerModule.SelectedCapacityAmount;
  }

  get SelectedCapacityType() {
    return offerModule.SelectedCapacityType;
  }

  get SelectedCapacityByUnit() {
    return offerModule.SelectedCapacityByUnits;
  }

  get SelectedCapacityProductOption() {
    return offerModule.SelectedCapacityProductOption;
  }

  get SelectedCapacityPulledFrom() {
    return offerModule.SelectedCapacityPulledFrom;
  }
}
