








































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { Pricing, Product, ProductOption } from '@/api/inventory/InventoryModels';
import { Address, Offer } from '@/models';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import { offerModule } from '@/store/modules/moduleOffer';
import { tablePaginationOptions } from '@/utils/helpers';

type PricingWithProduct = Pricing | { product?: Product; option?: ProductOption };

@Component({
  components: {
    RdmStatusIndicator,
  },
})
export default class OfferListingSummary extends Vue {
  /* DATA */
  private offerLink = constants.routes.OFFER_CREATE;
  private offerToRemove = {} as Offer;
  private modalIsOpen = false;
  private offerHeaders: Array<Record<string, any>> = [
    {
      text: 'Offer',
      value: 'name',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];
  private routerOfferEditName = constants.routes.OFFER_EDIT;

  get paginationOptions() {
    return tablePaginationOptions(this.Offers);
  }

  /* METHODS */

  async mounted() {
    await httpModule.getOffers();
  }

  getOfferRoute(offer: Offer) {
    return { name: this.routerOfferEditName, params: { id: offer.id } };
  }

  getPrimaryAddress(id: string): string {
    const p = this.Products.find((p: any) => p.id === id);
    if (p) {
      const address = this.CompanyAddresses.find((a: Address) => a.id === p.addressId);
      if (address) {
        return `${address.name}`;
      }
    }

    return '';
  }

  private openModal(offer: Offer) {
    this.offerToRemove = offer;
    this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  async removeOffer() {
    this.closeModal();
    await httpModule.deleteOffer(this.offerToRemove);
  }

  get Offers() {
    return offerModule.Offers.sort((a, b) => {
      const upperA = a.name?.toUpperCase() || '';
      const upperB = b.name?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get Loading(): boolean {
    return offerModule.Loading;
  }

  get Search() {
    return offerModule.Search;
  }

  get Products() {
    return offerModule.Products;
  }

  get CompanyAddresses() {
    return companyModule.Addresses;
  }
}
