























import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    largeModal: {
      type: Boolean,
      default: false,
    },
    saveAction: {
      type: Function,
    },
    saveEnable: {
      type: Boolean,
      default: false,
    },
    selfRef: {
      type: Object,
      default: null,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    requestConnectionDialog: {
      type: Boolean,
      default: false,
    },
  },
})
export default class RequestConnectionModal extends Vue {
  mounted() {}

  closeDialog() {
    this.$emit('close-modal', false);
  }
}
