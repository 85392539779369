











































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  optionalNumberMinimumRules,
  requiredFieldRules,
  validWholeNumbersRules,
} from '@/utils/validation-rules';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import {
  BookingCutoffOptions,
  CancellationPolicyOptions,
  ProductPolicy,
  RefundablePolicyOptions,
  Option,
} from '@/models';
import { optionModule } from '@/store/modules/moduleOption';
import { handleKeyDownOnlyWholeNumeric } from '@/utils/helpers';

@Component
export default class OptionViewPolicyModal extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private validWholeNumbersRules = validWholeNumbersRules;
  private option = {} as Option;
  private cancellationType = 0 as CancellationPolicyOptions;
  private refundable = 0 as RefundablePolicyOptions;
  private bookingCutoff = 0 as BookingCutoffOptions;
  private optionalNumberMin = optionalNumberMinimumRules;
  private cutoffForm = false;
  private cancellationCutoff = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private bookingCutoffTimes = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;

  mounted() {
    // this.$root.$on(
    //   constants.events.PRODUCT_LOCATION_EDIT_MODAL_CLEAR_VALIDATION,
    //   this.clearValidateState,
    // );
  }

  destroyed() {
    // this.$root.$off(constants.events.PRODUCT_LOCATION_EDIT_MODAL_CLEAR_VALIDATION);
  }

  clearValidateState() {
    if (this.$refs?.policyForm) {
      const policyForm: any = this.$refs.policyForm;
      policyForm.reset();
    }
  }

  validate() {
    if (this.$refs?.policyForm) {
      const policyForm: any = this.$refs.policyForm;
      const isValid = policyForm.validate();
      if (isValid) {
        this.createPolicy();
      }
    }
  }

  closeEditModal() {
    // productModule.clearEditProductLocationForm();
    optionModule.setEditOptionPolicyModal(false);
  }

  createPolicy() {
    this.option = _.cloneDeep(this.Option);
    const minutes = this.bookingCutoffTimes.minutes;
    const minutesFromHours = this.bookingCutoffTimes.hours * 60;
    const minutesFromDays = this.bookingCutoffTimes.days * 24 * 60;
    const cancelCutoff = +minutes + +minutesFromHours + +minutesFromDays || 0;
    const cancellable = this.cancellationType === CancellationPolicyOptions.YES;
    const refundable = this.refundable === RefundablePolicyOptions.YES;
    this.option.policy = {} as ProductPolicy;
    this.option.policy.refundable = refundable;
    this.option.policy.cancellable = cancellable;
    this.option.policy.cutOffTime = cancelCutoff;
    this.updateOption();
  }

  async updateOption() {
    optionModule.setOption(this.option);
    await httpModule.updateOption(this.option).then().catch();
    this.closeEditModal();
  }

  get EditOptionPolicyFormState() {
    return optionModule.EditOptionPolicyFormState;
  }

  set EditOptionPolicyFormState(state: boolean) {
    optionModule.setEditOptionPolicyFormState(state);
  }

  get Option() {
    return optionModule.Option;
  }

  get Loading() {
    return optionModule.Loading;
  }

  /* UI Data */

  get CancellationTypes() {
    return [CancellationPolicyOptions.YES, CancellationPolicyOptions.NO];
  }

  get RefundValues() {
    return [RefundablePolicyOptions.YES, RefundablePolicyOptions.NO];
  }

  get BookingCutoff() {
    return [BookingCutoffOptions.YES, BookingCutoffOptions.NO];
  }
}
