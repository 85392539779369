



















































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import { Contact } from '@/models';
import { companyModule } from '@/store/modules/moduleCompany';
import { httpModule } from '@/store/modules/moduleHttp';

@Component
export default class ProductViewReservationContacts extends Vue {
  private hoverCard = false as boolean;
  private contacts = [] as string[];
  private isEdit = false as boolean;
  get ProductContacts() {
    return productModule.ProductContacts;
  }

  get Contacts(): Array<Contact> {
    const reservations = companyModule.Contacts.filter(
      (contact: Contact) => contact.role === 'Reservations',
    );
    return reservations.map((contact: Contact) => {
      return {
        ...contact,
        showName: `${contact.firstName} ${contact.lastName}`,
      };
    });
  }
  get HoverCard() {
    return this.hoverCard;
  }

  mouseEnterEvent() {
    this.hoverCard = true;
  }

  mouseLeaveEvent() {
    this.hoverCard = false;
  }

  openEditModal() {
    this.contacts = this.ProductContacts.map((contact: Contact) => contact.id);
    this.isEdit = true;
  }

  closeEditModal() {
    this.contacts = [] as string[];
    this.isEdit = false;
  }
  async saveContacts() {
    const prevContacts = this.ProductContacts.map((contact: Contact) => contact.id);
    await httpModule.deleteProductContact({
      productId: this.$route.params.id,
      contactIds: prevContacts,
    });
    await httpModule.createProductContact({
      productId: this.$route.params.id,
      contactIds: this.contacts,
    });
    await httpModule.listProductContacts(this.$route.params.id);
  }
}
