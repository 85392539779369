
















import { Component, Vue } from 'vue-property-decorator';
import RdmMessage from './RdmMessage.vue';

@Component({
  components: {
    RdmMessage,
  },
  props: {
    state: {
      type: Boolean,
      default: false,
    },
  },
})
export default class RdmUnsavedChangesModal extends Vue {
  cancel() {
    this.$emit('cancel');
  }

  proceed() {
    this.$emit('proceed');
  }
}
