






































import { Component, Vue } from 'vue-property-decorator';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfWeek,
  format,
  getDay,
  isSameMonth,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { DayAvailability, DisplayDate } from '@/models';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import { constants } from '@/utils/constants';
import { inventoryModule } from '@/store/modules/moduleInventory';
import { pricingModule } from '@/store/modules/modulePricing';
import moment from 'moment';
import { daysOfWeek } from '@/utils/helpers';

@Component({
  props: {
    month: {
      type: Number,
      default: 1,
    },
    year: {
      type: Number,
      default: 2000,
    },
    effectiveStart: {
      type: String,
      default: '',
    },
    effectiveEnd: {
      type: String || null,
      default: null,
    },
    slots: {
      type: Array,
      default() {
        return [];
      },
    },
  },
})
export default class RdmCalendarMonthly extends Vue {
  private weekDays = daysOfWeek;
  showDay(day: DisplayDate) {
    pricingModule.setSelectedOfferCapacityId(day.capacityId);
    inventoryModule.toggleEvent();
    if (day.isInMonth) {
      availabilityStatusModule.setSelectedDate(day.isoDate);
      availabilityStatusModule.changeShowSidebarState(true);
      if (availabilityStatusModule.AvailabilityDates.has(day.isoDate)) {
        const d = availabilityStatusModule.AvailabilityDates.get(day.isoDate);
        availabilityStatusModule.setSelectedDayAvailability(d || ({} as DayAvailability));
        availabilityStatusModule.setBaseSelectedDayAvailability(
          d || ({} as DayAvailability),
        );
      }
      this.generateWeeks();
    }
    this.eventSelectDay(day);
  }

  eventSelectDay(day: DisplayDate) {
    this.$emit(constants.events.SELECT_CALENDAR_DAY, day);
  }

  get MonthName() {
    return format(new Date(this.$props.year, this.$props.month - 1, 1), 'MMMM');
  }

  get Weeks() {
    return this.generateWeeks();
  }

  private isInventoryDay(day: Date) {
    let matchSlots = [];
    const currentDate = moment();
    this.OfferCapacityList.map((oc) => {
      const end = oc.validUntil ? moment(oc.validUntil) : null;
      const start = moment(oc.validFrom);

      if (
        end &&
        moment(day).isSameOrAfter(start, 'day') &&
        moment(day).isSameOrBefore(end, 'day') &&
        moment(day).isSameOrAfter(currentDate, 'day')
      ) {
        matchSlots = oc.timeSlots.filter((slot) => {
          return slot.weekday === moment(day).format('dddd');
        });
      }
    });
    return !!matchSlots.length;
  }

  private assignCapacityId(day: Date) {
    let capacityId = '';
    const currentDate = moment();
    this.OfferCapacityList.map((oc) => {
      const end = oc.validUntil ? moment(oc.validUntil) : null;
      const start = moment(oc.validFrom);

      if (
        end &&
        moment(day).isSameOrAfter(start, 'day') &&
        moment(day).isSameOrBefore(end, 'day') &&
        moment(day).isSameOrAfter(currentDate, 'day')
      ) {
        const matchSlots = oc.timeSlots.filter((slot) => {
          return slot.weekday === moment(day).format('dddd');
        });
        if (matchSlots.length) capacityId = oc.id;
      }
    });
    return capacityId;
  }

  private generateWeeks() {
    const weeks = [];
    const date = new Date(this.$props.year, this.$props.month - 1, 1);
    const s = startOfMonth(date);
    const e = lastDayOfMonth(date);
    const w = eachWeekOfInterval({ start: s, end: e });
    if (w.length > 0) {
      for (let i = 0; i < w.length; i++) {
        const n = i + 1;
        if (n < w.length) {
          const d = eachDayOfInterval({ start: w[i], end: w[n] });
          const dSlice = d.slice(0, 7);
          const days = [] as Array<DisplayDate>;
          dSlice.forEach((day) => {
            days.push({
              capacityId: this.assignCapacityId(day),
              value: day.getDate(),
              dayOfWeek: day.getDay(),
              isBlackout: this.BlackoutMap.has(day.toISOString()),
              isInMonth: isSameMonth(day, s),
              isInventoryItem: !!this.assignCapacityId(day).length && isSameMonth(day, s),
              isNoInventory: false,
              isSoldout: false,
              isoDate: day.toISOString(),
            });
          });
          weeks.push(days);
        }
      }

      // adding last week of the month
      const lastDay = w[w.length - 1];
      const endOfWeekLastDay = endOfWeek(lastDay);
      const ldDay = getDay(lastDay);
      if (ldDay !== 6) {
        const d = eachDayOfInterval({ start: lastDay, end: endOfWeekLastDay });
        const dSlice = d.slice(0, 7);
        const days = [] as Array<DisplayDate>;
        dSlice.forEach((day) => {
          days.push({
            capacityId: this.assignCapacityId(day),
            value: day.getDate(),
            dayOfWeek: day.getDay(),
            isBlackout: this.BlackoutMap.has(day.toISOString()),
            isInMonth: isSameMonth(day, s),
            isInventoryItem: !!this.assignCapacityId(day).length,
            isNoInventory: false,
            isSoldout: false,
            isoDate: day.toISOString(),
          });
        });
        weeks.push(days);
      }
    }

    // todo add remain weeks
    const remainingWeeks = 6 - weeks.length;
    for (let i = 0; i < remainingWeeks; i++) {
      const days = [] as Array<DisplayDate>;
      for (let j = 0; j < 7; j++) {
        days.push({
          value: 0,
          dayOfWeek: 0,
          isBlackout: false,
          isInMonth: false,
          isInventoryItem: false,
          isNoInventory: false,
          isSoldout: false,
          isoDate: '',
        });
      }
      weeks.push(days);
    }
    return weeks;
  }

  get BlackoutMap() {
    return availabilityStatusModule.Blackout;
  }

  get OfferCapacityList() {
    return pricingModule.OfferCapacityList;
  }
}
