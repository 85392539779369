export function DateSearchTypeList(i18n: any) {
  return [
    {
      label: i18n.t('RedemptionReportPage.DateRange'),
      value: 'dateRange',
    },
    {
      label: i18n.t('RedemptionReportPage.From'),
      value: 'fromDate',
    },
    {
      label: i18n.t('RedemptionReportPage.Until'),
      value: 'untilDate',
    },
  ];
}

export function DatePickerOptionsDate(i18n: any) {
  return {
    shortcuts: [
      {
        text: i18n.t('RedemptionReportPage.Today'),
        onClick(picker: any) {
          picker.$emit('pick', new Date());
        },
      },
      {
        text: i18n.t('RedemptionReportPage.Yesterday'),
        onClick(picker: any) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit('pick', date);
        },
      },
      {
        text: i18n.t('RedemptionReportPage.WeekAgo'),
        onClick(picker: any) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', date);
        },
      },
    ],
  };
}

export function DatePickerOptionsDateRange(i18n: any) {
  return {
    shortcuts: [
      {
        text: i18n.t('RedemptionReportPage.LastWeek'),
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', [start, end]);
        },
      },
      {
        text: i18n.tc('RedemptionReportPage.LastXWeek', 2),
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
          picker.$emit('pick', [start, end]);
        },
      },
      {
        text: i18n.t('RedemptionReportPage.LastMonth'),
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit('pick', [start, end]);
        },
      },
    ],
  };
}
