























































































































































import { Component, Vue } from 'vue-property-decorator';
import { pricingModule } from '@/store/modules/modulePricing';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import _ from 'lodash';
import OfferEditDescription from '@/components/offer/edit/OfferEditDescription.vue';
import OfferEditName from '@/components/offer/edit/OfferEditName.vue';
import OfferEditTicket from '@/components/offer/edit/OfferEditTicket.vue';
import OfferEditLocation from '@/components/offer/edit/OfferEditLocation.vue';
import OfferEditChannel from '@/components/offer/edit/OfferEditChannel.vue';
import OfferEditInventoryType from '@/components/offer/edit/OfferEditInventoryType.vue';
import OfferEditPolicy from '@/components/offer/edit/OfferEditPolicy.vue';
import OfferEditSchedules from '@/components/offer/edit/OfferEditSchedules.vue';
import OfferEditPricing from '@/components/offer/edit/OfferEditPricing.vue';
import OfferEditDetails from '@/components/offer/edit/OfferEditDetails.vue';
import OfferEditTaxes from '@/components/offer/edit/OfferEditTaxes.vue';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import { offerModule } from '@/store/modules/moduleOffer';

@Component({
  components: {
    OfferEditDetails,
    OfferEditPricing,
    OfferEditSchedules,
    OfferEditPolicy,
    OfferEditInventoryType,
    OfferEditChannel,
    OfferEditLocation,
    OfferEditTicket,
    OfferEditName,
    OfferEditDescription,
    OfferEditTaxes,
    RdmReturnToBtn,
  },
  props: {
    close: {
      type: Function,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
  },
})
export default class RateCardCreateOfferModal extends Vue {
  private routeToOfferList = constants.routes.OFFER_LIST;

  async mounted() {
    pricingModule.setProductOfferLoading(true);
    await httpModule.getOffer({ offerId: this.$props.id });
    if (this.Offer.productId) {
      await httpModule.getProduct(this.Offer.productId).then();
      await httpModule.getProductTripRoutes().then();
      let productId = '';
      if (
        this.$route.params &&
        this.$route.params.id &&
        _.isString(this.$route.params.id) &&
        this.$route.params.id.length > 0
      ) {
        productId = this.$route.params.id;
      } else if (this.Offer.productId && this.Offer.productId.length > 0) {
        productId = this.Offer.productId;
      }
      if (productId && productId.length > 0) {
        await httpModule.getProductUnitTravelerTypes(productId).then();
      }
      const id = companyModule.Organization?.ID || '';
      productModule.setProductCapacityPool({
        orgId: id,
        productId: this.Offer.productId,
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      offerModule.setProductCapacityPool({
        orgId: id,
        productId: this.Offer.productId,
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      await httpModule.getProductCapacity().then();
    }
    if (this.Offer.optionId) {
      const optionId = this.Offer.optionId || '';
      await httpModule.getOption(optionId).then();
      await httpModule.getOptionTripRoutes().then();
      await httpModule.getOptionUnitTravelerTypes(optionId).then();
      const id = companyModule.Organization?.ID || '';
      optionModule.setOptionCapacityPool({
        orgId: id,
        optionId: optionId,
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      offerModule.setOptionCapacityPool({
        orgId: id,
        optionId: optionId,
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      await httpModule.getOptionCapacity().then();
    }
    await httpModule.getTaxesAndFees();
    if (!this.Offer || (this.Offer && _.isEmpty(this.Offer.id))) {
      this.$router.push({ name: this.routeToOfferList }).catch();
    }
    await offerModule.chooseCapacityType();
    // todo set product offers
    pricingModule.setProductOfferLoading(false);
  }

  getTravelerTypeName(id: string): string {
    const traveler = this.TravelerTypes.find((t) => t.id === id);
    if (traveler && traveler.internalName) {
      return traveler.internalName;
    }
    return '';
  }

  toggleName() {
    pricingModule.toggleEditOfferName();
  }

  toggleInventoryType() {
    pricingModule.toggleEditOfferInventoryType();
  }

  get Loading() {
    return pricingModule.ProductOfferLoading;
  }

  get Offer() {
    return pricingModule.Offer;
  }

  get Product() {
    return productModule.Product;
  }

  get Option() {
    return optionModule.Option;
  }

  get OfferPricing() {
    return pricingModule.OfferPricing;
  }

  get OfferPrices() {
    return pricingModule.OfferPricings;
  }

  // get PrimaryAddress() {
  //   return productModule.PrimaryLocation;
  // }

  get OptionPrimaryAddress() {
    return {};
  }

  get TravelerTypes() {
    return travelerTypeModule.TravelerTypes;
  }

  get EditOfferName() {
    return pricingModule.EditOfferName;
  }
}
