




















import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import { offerModule } from '@/store/modules/moduleOffer';

@Component
export default class OfferEditDetails extends Vue {
  private requiredField = requiredFieldRules;

  get Offer() {
    return offerModule.Offer;
  }
  get Product() {
    return offerModule.Product;
  }
  get Option() {
    return offerModule.Option;
  }
}
