




















































import { Component, Vue } from 'vue-property-decorator';

import { AlertType } from '@/utils/alert';
import { getErrorText } from '@/utils/helpers';

@Component
export default class RdmAlertFloat extends Vue {
  private getErrorText = getErrorText;
  get Alert() {
    return {} as any;
  }

  get IsAlert() {
    return false;
  }

  private get Icon() {
    switch (this.Alert?.Type) {
      case AlertType.ERROR:
        return 'alert-circle';
      case AlertType.WARNING:
        return 'alert';
      case AlertType.SUCCESS:
        return 'check-circle';
      case AlertType.INFO:
      default:
        return 'information';
    }
  }
}
