






































































































































































































































































































import { Vue, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import RdmSidebarGroup from './RdmSidebarGroup.vue';
import RdmSidebarItem from './RdmSidebarItem.vue';

import { constants } from '@/utils/constants';
import { profileModule } from '@/store/modules/moduleProfile';
import { httpModule } from '@/store/modules/moduleHttp';
import { appModule } from '@/store/modules/moduleApp';

@Component({
  components: {
    RdmSidebarGroup, // TODO
    RdmSidebarItem, // TODO
  },
})
export default class RdmSidebar extends Vue {
  /* PROPERTIES */

  @Prop()
  items!: [];
  @Prop()
  childItems!: [];
  @Prop()
  supportItems!: [];
  @Prop()
  supportChildItems!: [];
  @Prop()
  noGroupItem!: [];
  @Prop()
  adminItems!: [];
  @Prop()
  adminChildItems!: [];
  @Prop()
  helpCenterItems!: [];

  /* DATA */

  private mini = false;
  private selectedItem = 1;

  /* COMPUTED */

  get IsSidebarShown() {
    return appModule.IsSidebarActive;
  }
  set IsSidebarShown(flag: boolean) {
    appModule.setIsSidebarActive(flag);
  }

  get Me() {
    return profileModule.Profile;
  }

  get Loading() {
    return appModule.Loading;
  }

  /* METHODS */

  private async goToProfile() {
    if (this.$route.name !== constants.routes.PROFILE) {
      await this.$router.push({ name: constants.routes.PROFILE });
    }
  }

  private async logout() {
    await httpModule.logout();
  }
}
