import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { ActionButton, ActionQueue, DisplayTimezone, MessageQueue, MessageType, RdmAction, RdmMessage } from '@/models';
import { profileModule } from '@/store/modules/moduleProfile';
import { companyModule } from '@/store/modules/moduleCompany';
import { codes } from '@/utils/codeConstants';
import { httpModule } from '@/store/modules/moduleHttp';
import { codeConfiguration } from '@/app-codes/codeConfiguration';
import faker from 'faker';
import router from '@/router';
import { constants } from '@/utils/constants';
import { Codes, CountryData } from '@/api/inventory/InventoryModels';
import { PublicFeaturesRS } from '@/api/wps/WpsModels';
import _ from 'lodash';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { userModule } from '@/store/modules/moduleUser';
import { channelModule } from '@/store/modules/moduleChannel';
import { inventoryModule } from '@/store/modules/moduleInventory';
import { reportModule } from '@/store/modules/moduleReport';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import { Route } from 'vue-router';
import moment from 'moment-timezone';
import { formatInTimeZone } from 'date-fns-tz';

export function IsFeature(features: PublicFeaturesRS[], feature: string): boolean {
  return !!features?.find((v) => v.name === feature)?.active;
}

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-app',
  namespaced: true,
})
class ModuleApp extends VuexModule {
  /* Data */
  private loading = false;
  private features: PublicFeaturesRS[] = [];
  private isSidebarActive = false;
  private showAboutPage = false;
  private messageQueue = {} as MessageQueue;
  private actionQueue = {} as ActionQueue;
  private codes = {} as Codes;
  private countryData = {} as Array<CountryData>;
  private actionEventId = '';
  private previousRoute = {} as Route;
  private displayTimezones = [] as Array<DisplayTimezone>;
  private roles = [] as Array<string>;
  private version = '';

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get Messages(): MessageQueue {
    return this.messageQueue;
  }

  get Actions(): ActionQueue {
    return this.actionQueue;
  }

  get Codes() {
    return this.codes;
  }

  get SortedCurrencies() {
    return (
      this.codes?.currency?.sort((a: any, b: any) =>
        a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
      ) || []
    );
  }

  get CountryData() {
    return this.countryData;
  }

  get Features() {
    return this.features;
  }

  get IsSidebarActive() {
    return this.isSidebarActive;
  }

  get ShowAboutPage() {
    return this.showAboutPage;
  }

  get ActionEventId() {
    return this.actionEventId;
  }

  get PreviousRoute() {
    return this.previousRoute;
  }

  get DisplayTimezone() {
    return this.displayTimezones;
  }

  get Roles(): Array<string> {
    return this.roles;
  }

  get Version() {
    return this.version;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setMessages(messages: MessageQueue) {
    this.messageQueue = messages;
  }

  @Mutation
  setActions(actions: ActionQueue) {
    this.actionQueue = actions;
  }

  @Mutation
  setIsSidebarActive(active: boolean) {
    this.isSidebarActive = active;
  }

  @Mutation
  setPublicFeatures(features: PublicFeaturesRS[]) {
    this.features = features;
  }

  @Mutation
  setCodes(codes: Codes) {
    this.codes = codes;
  }

  @Mutation
  setCountryCodes(data: Array<CountryData>) {
    this.countryData = data;
  }

  @Mutation
  setShowAboutPage(show: boolean) {
    this.showAboutPage = show;
  }

  @Mutation
  setActionEventId(id: string) {
    this.actionEventId = id;
  }

  @Mutation
  setPreviousRoute(route: Route) {
    this.previousRoute = route;
  }

  @Mutation
  setDisplayTimezone(times: Array<DisplayTimezone>) {
    this.displayTimezones = times;
  }

  @Mutation
  setRoles(roles: Array<string>) {
    this.roles = roles;
  }

  @Mutation
  setVersion(version: string) {
    this.version = version;
  }

  /* Actions */
  @Action
  addRdmMessage(message: RdmMessage) {
    if (this.messageQueue && this.messageQueue.messages) {
      this.messageQueue.messages.push(message);
      if (!message.permanent) {
        this.timeoutMessage(message);
      }
      return;
    }

    this.setMessages({ messages: [] } as MessageQueue);
    this.messageQueue.messages.push(message);
    if (!message.permanent) {
      this.timeoutMessage(message);
    }
  }

  @Action
  addRdmAction(action: RdmAction) {
    if (this.actionQueue && this.actionQueue.actions) {
      this.actionQueue.actions.push(action);
      return;
    }

    this.setActions({ actions: [] } as ActionQueue);
    this.actionQueue.actions.push(action);
    this.setActionEventId(action.id);
  }

  @Action
  addMessageError(code: number | Array<any>) {
    let c = 0;
    let m = undefined;
    if (_.isNumber(code)) {
      c = code;
    }

    if (_.isArray(code) && code.length > 0) {
      c = code[0];
      if (code.length > 1) {
        m = code[1];
      }
    }
    const id = faker.datatype.uuid();
    this.addRdmAction({
      id: id,
      detail: {
        code: c,
      },
    });
    const action = codeConfiguration.get(c);
    const message = m || action?.message || 'Unknown error';
    this.addRdmMessage({
      id: id,
      permanent: true,
      active: true,
      message: {
        code: c,
        text: message,
        type: MessageType.ERROR,
      },
      action: {
        actionId: id,
        undoable: action?.undoable,
        retryable: action?.retryable,
        viewable: action?.viewable,
        helpId: action?.helpId,
        hasHelp: action?.hasHelp,
        helpLink: action?.helpLink,
      },
    });
  }

  @Action
  addMessageSuccess(code: number | Array<any>) {
    let c = 0;
    let m = undefined;
    if (_.isNumber(code)) {
      c = code;
    }

    if (_.isArray(code) && code.length > 0) {
      c = code[0];
      if (code.length > 1) {
        m = code[1];
      }
    }
    // const id = this.ActionEventId && this.ActionEventId.length > 0 ? this.ActionEventId : faker.datatype.uuid();
    const id = faker.datatype.uuid();
    const action = codeConfiguration.get(c);
    const message = m ? m : action ? action.message : 'Completed';
    this.addRdmMessage({
      id: id,
      permanent: false,
      active: true,
      message: {
        code: c,
        text: message,
        type: MessageType.INFO,
      },
      action: {
        actionId: id,
        undoable: action?.undoable,
        retryable: action?.retryable,
        viewable: action?.viewable,
        helpId: action?.helpId,
        hasHelp: action?.hasHelp,
        helpLink: action?.helpLink,
      },
    });
  }

  @Action
  timeoutMessage(message: RdmMessage) {
    setTimeout(() => {
      this.removeMessage(message.id);
    }, 4000);
  }

  @Action
  removeMessage(id: string) {
    if (this.messageQueue && this.messageQueue.messages) {
      const i = this.messageQueue.messages.findIndex((message: RdmMessage) => message.id === id);
      if (i > -1) {
        this.messageQueue.messages.splice(i, 1);
      }
    }
  }

  @Action
  removeAction(id: string) {
    if (this.actionQueue && this.actionQueue.actions) {
      const i = this.actionQueue.actions.findIndex((action: RdmAction) => action.id === id);
      if (i > -1) {
        this.actionQueue.actions.splice(i, 1);
      }
    }
  }

  @Action
  getAction(id: string): RdmAction | undefined {
    if (this.actionQueue && this.actionQueue.actions) {
      return this.actionQueue.actions.find((action: RdmAction) => action.id === id);
    }
    return undefined;
  }

  @Action
  clearMessages() {
    if (this.messageQueue && this.messageQueue.messages) {
      this.messageQueue.messages = [];
    }
  }

  @Action
  clearActions() {
    if (this.actionQueue && this.actionQueue.actions) {
      this.actionQueue.actions = [];
    }
  }

  @Action
  appendDisplayTime(displayTime: DisplayTimezone) {
    this.displayTimezones.push(displayTime);
  }

  @Action
  configDisplayTime() {
    const currentDate = new Date();
    const tzNames = moment.tz.names();
    const displayNames = [] as Array<DisplayTimezone>;
    tzNames.forEach((tz) => {
      const tzName = formatInTimeZone(currentDate, tz, 'zzzz');
      const t = tz.replaceAll('_', ' ');
      displayNames.push({ value: tz, text: `${tzName} (${t})` });
    });
    this.setDisplayTimezone(
      displayNames.sort((a, b) => {
        const lowerA = a.text?.toLowerCase() || '';
        const lowerB = b.text?.toLowerCase() || '';

        if (lowerA < lowerB) {
          return -1;
        }

        if (lowerA > lowerB) {
          return 1;
        }

        return 0;
      }),
    );
  }

  @Action
  configureContactRoles() {
    this.setRoles(['Finance', 'Reservations', 'Sales', 'Technical', 'Customer Service', 'Other']);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setVersion('');
    this.setRoles([]);
    this.clearMessages();
    this.clearActions();
    profileModule.clearState();
    companyModule.clearState();
    productModule.clearState();
    optionModule.clearState();
    travelerTypeModule.clearState();
    userModule.clearState();
    channelModule.clearState();
    inventoryModule.clearState();
    reportModule.clearState();
    availabilityStatusModule.clearState();
  }

  /**
   * Function to handle the retry, undo events
   * @param action
   */
  @Action
  async codeAction(action: RdmAction) {
    try {
      if (action.detail) {
        const code = action.detail.code;
        switch (code) {
          case codes.COMPANY_LOAD:
            await router.push({ name: constants.routes.CONTACTS });
            break;
          case codes.COMPANY_ERROR_500_LOAD_COMPANY:
            await httpModule.getCompany();
            break;
          case codes.PRODUCT_ERROR_500_LOAD_COMPANY:
            await httpModule.loadProducts();
            break;
          case codes.OPTION_ERROR_500_LOAD_OPTION:
            await httpModule.loadOptions();
            break;
          case codes.TRAVELER_TYPE_ERROR_500_LOAD:
            await httpModule.getTravelerTypes();
            break;
          case codes.PRICE_SCHEDULE_ERROR_500_LOAD_PRICES:
            await httpModule.getPriceSchedules();
            break;
          case codes.PRICE_AND_TAXES_ERROR_500_LOAD_PRICES:
            await httpModule.getPricings();
            break;
          case codes.TAXES_AND_FEES_ERROR_500_LOAD_TAXES:
            await httpModule.getTaxesAndFees();
            break;
          case codes.USER_ACTIVATED:
            if (action.detail.button === ActionButton.VIEW && action.detail.body && action.detail.body.id) {
              const id = action.detail.body.id;
              await router.push({ name: constants.routes.USER_DETAILS_VIEW, params: { id: id } }).catch();
            }
            break;
          case codes.USER_CREATED:
            if (action.detail.button === ActionButton.VIEW && action.detail.body && action.detail.body.id) {
              const id = action.detail.body.id;
              await router.push({ name: constants.routes.USER_DETAILS_VIEW, params: { id: id } }).catch();
            }
            break;
          case codes.USER_DEACTIVATED:
            if (action.detail.button === ActionButton.UNDO && action.detail.body && action.detail.body.id) {
              const id = action.detail.body.id;
              await userModule.chooseUserIndexToEdit(id);
              await httpModule.activateUser();
            }
            break;
          default:
            break;
        }
      }
    } catch {
      // todo send message to backend
    }
  }
}

export const appModule = getModule(ModuleApp, store);
