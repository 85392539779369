import * as faker from 'faker';

export class LocationContact {
  firstName = '';

  lastName = '';

  email = '';

  phone = '';
}

class LocationTime {
  effectiveDate = '';

  endDate = '';

  locationTimeZone = '';

  timeFormat: TimeFormat = 0;
}

enum TimeFormat {
  Format24Hour,
  FormatAmPm,
}

export class OperatorLocationAddress {
  street1 = '';

  street2 = '';

  city = '';

  state = '';

  zip = '';

  country = '';

  lat = '';

  long = '';
}

export class OperatorLocation {
  id = faker.datatype.uuid();
  operatorID = '';

  companyName = '';

  businessType = '';

  locationName = '';

  locationDescription = '';

  operatorAddress: OperatorLocationAddress = new OperatorLocationAddress();

  locationContact: LocationContact = new LocationContact();

  locationSchedules: OperatorLocationSchedule[] = [];

  locationTimes: LocationTime[] = [];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum BusinessType {
  Tour,
  Activity,
}

export class OperatorLocationSchedule {
  id = '';

  name = '';

  startDate = new Date().toISOString().substr(0, 10);

  endDate: string = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .substr(0, 10);

  isPromoOrHoliday = false;

  timezone = '';

  hours: OperatorLocationHours[] = [];
}

export class OperatorLocationHours {
  id = '';

  day_of_week = '';

  open = '00:00';

  close = '23:59';

  active = true;
}
