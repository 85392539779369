































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';

import RdmEditNavBtn from '@/components/common/button/RdmEditNavBtn.vue';

@Component({
  components: {
    RdmEditNavBtn,
  },
})
export default class CompanyHome extends Vue {
  /* DATA */

  private activeTab = '/company';
  private tabs: Array<any> = [
    { id: 1, name: 'About', route: '/company' },
    { id: 2, name: 'Contacts', i18n: 'Link.Contacts', route: `/company/contacts` },
  ];
  private routeCompanyEdit = constants.routes.COMPANY_EDIT;

  get IsAboutRoute() {
    return this.$route.name === constants.routes.COMPANY;
  }

  /* HOOKS */

  private async mounted() {}

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }
}
