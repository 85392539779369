


























import { Component, Vue } from 'vue-property-decorator';
import { Alert } from '@/utils/alert';

@Component
export default class RdmAlert extends Vue {
  private get Alerts(): Array<[number, Alert]> {
    return [];
  }

  // eslint-disable-next-line
  private removeAlert(i: number) {
    // alertModule.removeAlert(i);
  }
}
