import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { AvailabilityItem, DayAvailability } from '@/models';
import { pricingModule } from './modulePricing';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-availability-status',
  namespaced: true,
})
class ModuleAvailabilityStatus extends VuexModule {
  /* Data */
  private loading = false;
  private showSidebar = false;
  private editMode = false;
  private selectedDate = '';
  private blackout = new Map<string, boolean>();
  private dayAvailability = new Map<string, DayAvailability>();
  private selectedDayAvailability = {} as DayAvailability;
  private baseSelectedDayAvailability = {} as DayAvailability;
  private currentDate = new Date();
  private availabilityItems = new Map<string, AvailabilityItem[]>();

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get ShowSidebar(): boolean {
    return this.showSidebar;
  }

  get SelectedDate(): string {
    return this.selectedDate;
  }

  get CurrentDate(): Date {
    return this.currentDate;
  }

  get Blackout(): Map<string, boolean> {
    return this.blackout;
  }

  get AvailabilityDates(): Map<string, DayAvailability> {
    return this.dayAvailability;
  }

  get SelectedDayAvailability(): DayAvailability {
    return this.selectedDayAvailability;
  }

  get BaseSelectedDayAvailability(): DayAvailability {
    return this.baseSelectedDayAvailability;
  }

  get AvailabilityItems(): Map<string, AvailabilityItem[]> {
    return this.availabilityItems;
  }

  get EditMode(): boolean {
    return this.editMode;
  }

  /* Setters */
  @Mutation
  setShowSidebar(show: boolean) {
    this.showSidebar = show;
  }

  @Mutation
  setSelectedDate(date: string) {
    this.selectedDate = date;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setEditMode(mode: boolean) {
    this.editMode = mode;
  }

  @Mutation
  setCurrentDate(date: Date) {
    this.currentDate = date;
  }

  @Mutation
  setBlackoutMap(blackout: Map<string, boolean>) {
    this.blackout = blackout;
  }

  @Mutation
  setDayAvailability(availabilityDays: Map<string, DayAvailability>) {
    this.dayAvailability = availabilityDays;
  }

  @Mutation
  setSelectedDayAvailability(selectedAvailability: DayAvailability) {
    this.selectedDayAvailability = selectedAvailability;
  }

  @Mutation
  setAvailabilityItems(items: Map<string, AvailabilityItem[]>) {
    this.availabilityItems = items;
  }

  @Mutation
  setBaseSelectedDayAvailability(selectedAvailability: DayAvailability) {
    this.baseSelectedDayAvailability = selectedAvailability;
  }

  /* Actions */
  @Action
  appendBlackout(key: string) {
    this.blackout.set(key, true);
  }

  @Action
  appendDayAvailability(availability: DayAvailability) {
    this.dayAvailability.set(availability.date, availability);
  }

  @Action
  appendAvailabilityItem(avail: { day: string; item: AvailabilityItem }) {
    const items = this.availabilityItems.get(avail.day) || [];
    items.push(avail.item);
    this.availabilityItems.set(avail.day, items);
  }

  @Action
  updateAvailabilityItem(avail: { day: string; item: AvailabilityItem }) {
    const items = this.availabilityItems.get(avail.day) || [];
    const idx = items.findIndex((a: AvailabilityItem) => a.id == avail.item.id);
    if (items[idx]) {
      items[idx] = avail.item;
      this.availabilityItems.set(avail.day, items);
    }
  }

  @Action
  removeBlackout(key: string) {
    this.blackout.delete(key);
  }

  @Action
  removeDayAvailability(key: string) {
    this.dayAvailability.delete(key);
  }

  @Action
  toggleShowSidebar() {
    this.setShowSidebar(!this.ShowSidebar);
  }

  @Action
  changeShowSidebarState(state: boolean) {
    this.setShowSidebar(state);
  }

  @Action
  changeEditModeState(state: boolean) {
    this.setEditMode(state);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(true);
    this.setLoading(false);
    this.setAvailabilityItems(new Map<string, AvailabilityItem[]>());
    this.setDayAvailability(new Map<string, DayAvailability>());
    this.setBlackoutMap(new Map<string, boolean>());
    this.setSelectedDayAvailability({} as DayAvailability);
    this.setBaseSelectedDayAvailability({} as DayAvailability);
    this.setSelectedDate('');
    this.setShowSidebar(false);
    pricingModule.setOfferCapacityList([]);
  }
}

export const availabilityStatusModule = getModule(ModuleAvailabilityStatus, store);
