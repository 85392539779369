var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{model:{value:(_vm.Form3Valid),callback:function ($$v) {_vm.Form3Valid=$$v},expression:"Form3Valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Available Taxes & Fees")]),_c('div',[_vm._v(" Select available taxes and fees to apply them to all traveler type pricing. Adding compound calculations will enable the ability to order applied items. ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":"","flat":""}},[_c('v-data-table',{staticClass:"elevation-0 margin-bottom-16",attrs:{"headers":_vm.taxAndFeesHeaders,"items":_vm.TaxesAndFees,"items-per-page":10,"sort-by":['name'],"disabled":_vm.Step3Done,"hide-default-footer":"","show-select":!_vm.Step3Done,"footer-props":{
            itemsPerPageOptions: [10, 25, 50, -1],
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
          }},scopedSlots:_vm._u([{key:"item.chargeType",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("lowercase")(item.chargeType)))+" ")]}},{key:"item.rateType",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.rateType === 'FLAT' ? ("" + (_vm.centsToDollars(item.amount))) : item.amount + '%')+" ")]}},{key:"item.calcText",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("lowercase")(item.calcText.replace('|', ', ').replaceAll('_', ' '))))+" ")]}},{key:"item.effectiveDateRange",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.validFrom ? item.validFrom : '-')+" - "+_vm._s(item.validUntil ? item.validUntil : 'Ongoing')+" ")]}},{key:"item.view",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.Step3Done),expression:"!Step3Done"}],attrs:{"to":{
                name: _vm.routerTaxesAndFeesViewEdit,
                params: {
                  id: item.id,
                  isPricing: 'true',
                },
                query: {
                  backTo: _vm.routerProductOfferCreate,
                },
              },"color":"primary","plain":"","disabled":!item.id}},[_vm._v(" Edit ")])]}}]),model:{value:(_vm.selectedTnF),callback:function ($$v) {_vm.selectedTnF=$$v},expression:"selectedTnF"}})],1),_c('v-btn',{staticClass:"remove-padding mt-6",attrs:{"color":"primary","depressed":"","disabled":_vm.selectedTnF.length === 0},on:{"click":_vm.applyTaxesAndFees}},[_vm._v(" Apply ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Applied Taxes & Fees")]),(_vm.AppliedTnF.length === 0)?_c('div',[_vm._v("Not applied")]):_vm._e(),(_vm.AppliedTnF.length)?_c('div',[_c('div',[_vm._v(" Move taxes and fees up and down to adjust the ascending order that they are applied to pricing. ")]),_vm._l((_vm.AppliedTnF),function(item,index){return _c('v-card',{key:((item.id) + "-" + index),staticClass:"appliedTax",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"align-self-center"},[_c('div',[_c('span',[_vm._v(_vm._s(item.name)+": ")]),_c('span',[_vm._v(_vm._s(item.rateType === 'FLAT' ? _vm.centsToDollars(item.amount) : item.amount))]),_c('span',[_vm._v(_vm._s(item.rateType === 'FLAT' ? ' USD' : '%')+" ")])])]),_c('v-col',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"to":{
                    name: _vm.routerTaxesAndFeesViewEdit,
                    params: {
                      id: item.id,
                      isPricing: 'true',
                    },
                    query: {
                      backTo: _vm.routerProductOfferCreate,
                    },
                  },"color":"primary","plain":"","disabled":!item.id || _vm.Step3Done}},[_vm._v(" Edit ")]),(_vm.IndependentOnlyApplied)?_c('v-btn',{attrs:{"color":"primary","plain":"","disabled":index === 0 || _vm.Step3Done},on:{"click":function($event){return _vm.moveAppliedUp(index)}}},[_vm._v(" Move Up ")]):_vm._e(),(_vm.IndependentOnlyApplied)?_c('v-btn',{attrs:{"color":"primary","plain":"","disabled":index === _vm.AppliedTnF.length - 1 || _vm.Step3Done},on:{"click":function($event){return _vm.moveAppliedDown(index)}}},[_vm._v(" Move Down ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","plain":"","disabled":!item.id || _vm.Step3Done},on:{"click":function($event){return _vm.removeAppliedTax(item)}}},[_vm._v(" Remove ")])],1)])],1)],1)})],2):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }