








































import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import { optionModule } from '@/store/modules/moduleOption';

@Component
export default class OptionViewName extends Vue {
  private saving = false;
  private formValid = false;
  private optionName = '';
  private baseOptionName = '';
  private requiredField = requiredFieldRules;

  mounted() {}

  close() {
    this.optionName = _.cloneDeep(this.baseOptionName);
    this.toggleNameEdit();
  }

  toggleNameEdit() {
    this.optionName = _.cloneDeep(this.Option.internalName || '');

    if (!this.EditModeName) {
      this.baseOptionName = _.cloneDeep(this.Option.internalName || '');
    }
    optionModule.setEditModeName(!this.EditModeName);
  }

  validate() {
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (isValid) {
        this.saveChanges();
      }
    }
  }

  saveChanges() {
    this.saving = true;
    if (this.Option && this.Option.internalName) {
      this.Option.internalName = _.cloneDeep(this.optionName);
    }

    optionModule.setOption(_.cloneDeep(this.Option));
    httpModule
      .updateOption(this.Option)
      .then()
      .finally(() => {
        this.optionName = _.cloneDeep(this.Option?.internalName || '');
        this.baseOptionName = _.cloneDeep(this.Option?.internalName || '');
        this.saving = false;
        this.close();
        httpModule.loadOptions().then(() => {});
      });
  }

  get EditModeName() {
    return optionModule.EditModeName;
  }

  get Option() {
    return optionModule.Option;
  }

  get Loading() {
    return optionModule.Loading;
  }
}
