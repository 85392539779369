












import { Component, Vue } from 'vue-property-decorator';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import RdmInventoryType from '@/components/product/detail/components/additional-details/RdmInventoryType.vue';
import ProductAvailabilitySummary from '@/components/product/detail/components/availability/ProductAvailabilitySummary.vue';

@Component({
  components: { ProductAvailabilitySummary, RdmStatusIndicator, RdmInventoryType },
})
export default class ProductInventorySummary extends Vue {}
