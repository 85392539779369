














import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import { pricingModule } from '@/store/modules/modulePricing';
import _ from 'lodash';

@Component
export default class OfferEditName extends Vue {
  private requiredField = requiredFieldRules;
  private offerName = '';
  private baseOfferName = '';

  mounted() {
    this.offerName = _.cloneDeep(this.Offer.name);
    this.baseOfferName = _.cloneDeep(this.Offer.name);
  }

  saveName() {
    // todo update name
    const offer = _.cloneDeep(this.Offer);
    offer.name = this.offerName;
    pricingModule.updateOffer(offer);
    // todo close edit name
    pricingModule.toggleEditOfferName();
  }

  cancelEditName() {
    this.offerName = this.baseOfferName;
    pricingModule.toggleEditOfferName();
  }

  get Offer() {
    return pricingModule.Offer;
  }
}
