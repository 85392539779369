
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { RedemptionStatus } from '@/utils/web-redemption';
import VoucherInfo from './VoucherInfo.vue';

@Component({
  components: {
    'rdm-voucher-info': VoucherInfo,
  },
})
export default class VoucherAlert extends Vue {
  @Prop({ type: Object, required: true })
  public message!: any;

  @Prop({ type: String, required: true })
  public status!: string;

  @Prop({ type: Object, required: false })
  public validated!: any;

  private get displayVoucherInfo() {
    const isFailedOrValidated =
      (RedemptionStatus.STATUS_FAILED === this.status ||
        RedemptionStatus.STATUS_VALIDATED === this.status) &&
      !!this.validated;

    return isFailedOrValidated && !!this.validated;
  }

  private get messageStyle() {
    return this.message.type === 'error' ? 'failed' : 'validated';
  }
}
