
















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RequestConnectionWizardStage2 extends Vue {
  confirmed() {
    this.$emit('no-agreement-confirmed', true);
  }
}
