

































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { taxesAndFeesModule } from '@/store/modules/moduleTaxAndFees';
import { offerModule } from '@/store/modules/moduleOffer';

@Component
export default class InventoryListing extends Vue {
  /* DATA */

  private tabs: Array<any> = [];
  private inventoryTab = 0;
  private selectedTabId = 1;

  /* GETTERS */

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }
  get TaxesAndFees() {
    return taxesAndFeesModule.TaxesAndFees.map((item) => {
      return { ...item };
    });
  }

  get IsPricingTab() {
    return this.$route.name === constants.routes.PRICING_LIST;
  }

  get Search() {
    return offerModule.Search;
  }
  set Search(search: string) {
    offerModule.setSearch(search);
  }

  /* HOOKS */

  mounted() {
    this.tabs = [
      { id: 1, name: 'Offers', route: `/prices` },
      { id: 2, name: 'Taxes & Fees', route: `/prices/taxes-and-fees` },
      { id: 3, name: 'Inventory', route: `/prices/inventory` },
      { id: 4, name: 'Availability Statuses', route: `/prices/availability-status` },
    ];
  }

  /*METHODS*/
  private onTabChange(id: number) {
    this.selectedTabId = id;
  }
  private navigateToNewOffer() {
    this.$router.push({ name: constants.routes.OFFER_CREATE }).catch(() => {});
  }
  private navigateToNewTaxAndFee() {
    this.$router.push({ name: constants.routes.TAXES_AND_FEES_CREATE }).catch(() => {});
  }
  private navigateToNewInventory() {
    this.$router.push({ name: constants.routes.AVAILABILITY_CREATE }).catch(() => {});
  }
  private needMoreTaxes() {
    this.$router.push({ name: constants.routes.FEEDBACK }).catch(() => {});
  }
}
