

















































































































import { Component, Vue } from 'vue-property-decorator';
import { companyModule } from '@/store/modules/moduleCompany';
import { httpModule } from '@/store/modules/moduleHttp';
import { constants } from '@/utils/constants';
import { tablePaginationOptions, buttonTextRegardingToStatus } from '@/utils/helpers';
import { Contact } from '@/models';
import { profileModule } from '@/store/modules/moduleProfile';

@Component({})
export default class CompanyContacts extends Vue {
  private contactToRemove = {} as Contact;
  private modalIsOpen = false;
  private routeContactAdd = constants.routes.CONTACTS_ADD;
  private contactsHeaders: Array<Record<string, any>> = [
    {
      text: 'First',
      value: 'firstName',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    {
      text: 'Last',
      value: 'lastName',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    {
      text: 'Job Type',
      value: 'role',
      sortable: true,
      filterable: true,
      align: 'left',
      width: 150,
    },
    { text: '', value: 'view', align: 'right', width: 350, sortable: false },
  ];

  /* COMPUTED */

  get Company() {
    return companyModule.Company;
  }

  get Contacts() {
    return companyModule.Contacts;
  }

  get Loading() {
    return companyModule.Loading;
  }

  get paginationOptions() {
    return tablePaginationOptions(this.Contacts);
  }

  /* HOOKS */

  private async mounted() {
    // await httpModule.getCompany();
    await httpModule.getContacts();
  }

  private async getCont() {
    await httpModule.getContacts();
  }

  private openModal(contact: Contact) {
    if (!this.IsOrgAdmin) {
      return;
    }
    this.contactToRemove = contact;
    this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  /* METHODS */
  private navigateToEditContact(contact: Contact) {
    this.$router
      .push({
        name: constants.routes.CONTACTS_EDIT,
        params: { id: contact.id },
      })
      .catch();
  }

  private navigateToView(contact: Contact) {
    this.$router
      .push({
        name: constants.routes.CONTACTS_VIEW,
        params: { id: contact.id },
      })
      .catch();
  }

  private navigateToAddContacts() {
    if (!this.IsOrgAdmin) {
      return;
    }
    this.$router.push({ name: constants.routes.CONTACTS_ADD }).catch(() => {});
  }

  private async removeContact() {
    if (this.Company) {
      this.closeModal();
      await httpModule.removeContact(this.contactToRemove);
      await httpModule.getContacts();
    }
  }

  private reactivationButtonText(status: string) {
    return buttonTextRegardingToStatus(status);
  }

  get Search() {
    return companyModule.SearchTextContact;
  }
  set Search(search: string) {
    companyModule.setSearchTextContact(search);
  }

  get IsOrgAdmin() {
    return profileModule.IsOrgAdmin;
  }
}
