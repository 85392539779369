













import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonMessage: {
      type: String,
      default: '',
    },
    buttonTo: {
      type: String,
      default: '',
    },
  },
})
export default class RdmDashboardCard extends Vue {
  private goTo(link: string) {
    this.$router.push({ name: link }).catch(() => {});
  }
}
