










































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import RdmEditNavBtn from '@/components/common/button/RdmEditNavBtn.vue';
import { companyModule } from '@/store/modules/moduleCompany';
import {
  Address,
  Contact,
  ContactEmail,
  ContactPhone,
} from '@/models/ModelsOppInventory';
import {
  emailRules,
  requiredFieldRules,
  requiredStateRules,
} from '@/utils/validation-rules';
import {
  handleKeyDownPreventDefault,
  handleKeyDownValidPhoneCharacter,
  findCountryName,
  filterCodeAndName,
} from '@/utils/helpers';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import { appModule } from '@/store/modules/moduleApp';
import faker from 'faker';
import { MessageType } from '@/models';
import { profileModule } from '@/store/modules/moduleProfile';

@Component({
  components: {
    RdmCollapsableCard,
    RdmEditNavBtn,
    RdmReturnToBtn,
  },
})
export default class OtherContactsView extends Vue {
  private routerContactsList = constants.routes.CONTACTS;
  private parentName = constants.titles.CONTACTS;
  private requiredFieldRules = requiredFieldRules;
  private requiredState = requiredStateRules;
  private emailFieldRules = emailRules;
  private handleValidPhoneCharacters = handleKeyDownValidPhoneCharacter;
  private handleKeyDownPreventDefault = handleKeyDownPreventDefault;
  private findCountryName = findCountryName;
  private filterCodeAndName = filterCodeAndName;
  private resultList = [] as google.maps.places.PlaceResult[];
  private selectedResult = {} as google.maps.places.PlaceResult;
  private showDialog = false;
  private contactNameValid = false;
  private contactAddressValid = false;
  private contactPhoneValid = false;
  private contactEmailValid = false;
  private contactJobTypeValid = false;
  private savingContactName = false;
  private savingContactAddress = false;
  private savingContactPhone = false;
  private savingContactEmail = false;
  private savingContactJobType = false;
  private contact = {} as Contact;
  private location = {} as Address;
  private email = {} as ContactEmail;
  private phone = {} as ContactPhone;
  private role = '';
  private roles = [
    'Finance',
    'Reservations',
    'Sales',
    'Technical',
    'Customer Service',
    'Other',
  ];

  mounted() {
    this.contact = _.cloneDeep(this.SelectedContact);
    if (this.contact && this.contact.addresses && this.contact.addresses.length > 0) {
      this.location = _.cloneDeep(this.contact.addresses[0]);
    }
    if (this.contact && this.contact.phones && this.contact.phones.length > 0) {
      this.phone = _.cloneDeep(this.contact.phones[0]);
    }
    if (this.contact && this.contact.emails && this.contact.emails.length > 0) {
      this.email = _.cloneDeep(this.contact.emails[0]);
    }

    if (this.contact.role) {
      this.role = this.contact.role;
    }
  }

  toggleContactName() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeContactName();
  }

  cancelContactName() {
    this.contact = _.cloneDeep(this.SelectedContact);
    companyModule.toggleEditModeContactName();
  }

  async validateContactName() {
    if (this.$refs?.editContactNameForm) {
      const form: any = this.$refs.editContactNameForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingContactName = true;
        // todo await this.configLatLong();
        await this.configContact();
        companyModule.setEditModeContactName(false);
        this.savingContactName = false;
      }
    }
  }

  toggleContactAddress() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeContactAddress();
  }

  cancelContactAddress() {
    this.contact = _.cloneDeep(this.SelectedContact);
    if (this.contact && this.contact.addresses && this.contact.addresses.length > 0) {
      this.location = _.cloneDeep(this.contact.addresses[0]);
    }
    companyModule.toggleEditModeContactAddress();
  }

  async validateContactAddress() {
    if (this.$refs?.editContactAddressForm) {
      const form: any = this.$refs.editContactAddressForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingContactAddress = true;
        // todo await this.configLatLong();
        // await this.configContact();
        await this.configLatLong();
        companyModule.setEditModeContactAddress(false);
        this.savingContactAddress = false;
      }
    }
  }

  convertAddressToString() {
    return `${this.location.streetAddress}, ${this.location.city}, ${this.location.region}`;
  }

  selectConfigLatLong() {
    this.showDialog = false;
    this.location.latitude = this.selectedResult.geometry?.location?.lat() || 0;
    this.location.longitude = this.selectedResult.geometry?.location?.lng() || 0;
    this.configContact();
  }

  async configLatLong() {
    try {
      if (window.google && window.google.maps) {
        const query = this.convertAddressToString();
        if (!query) return;
        const request = {
          query,
          fields: ['name', 'geometry'],
        };
        const service = new window.google.maps.places.PlacesService(
          this.$refs['placeService'] as HTMLDivElement,
        );
        await service.findPlaceFromQuery(
          request,
          (
            results: google.maps.places.PlaceResult[] | null,
            status: google.maps.places.PlacesServiceStatus,
          ) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
              this.resultList = results;
              if (this.resultList.length > 1) {
                this.showDialog = true;
              } else if (
                results[0] &&
                results[0].geometry &&
                results[0].geometry.location
              ) {
                this.location.latitude = results[0].geometry?.location.lat();
                this.location.longitude = results[0].geometry?.location.lng();
                // this.configCompany();
                // this.configContact();
              } else {
                appModule.addRdmMessage({
                  id: faker.datatype.uuid(),
                  active: true,
                  message: {
                    code: 99999,
                    text: 'Location Not found',
                    type: MessageType.ERROR,
                  },
                  permanent: false,
                });
              }
            }
          },
        );
        await this.configContact();
      }
    } catch {
      this.location.latitude = 0;
      this.location.longitude = 0;
    }
  }

  async configContact() {
    this.contact.phones = [this.phone];
    this.contact.emails = [this.email];
    this.contact.addresses = [this.location];
    if (this.role && this.role.length > 0) {
      this.contact.role = this.role;
    }
    httpModule.updateContact(this.contact).then().catch();
  }

  toggleContactEmail() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeContactEmail();
  }

  cancelContactEmail() {
    if (this.contact && this.contact.emails && this.contact.emails.length > 0) {
      this.email = _.cloneDeep(this.contact.emails[0]);
    }
    companyModule.toggleEditModeContactEmail();
  }

  async validateContactEmail() {
    if (this.$refs?.editContactEmailForm) {
      const form: any = this.$refs.editContactEmailForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingContactEmail = true;
        await this.configContact();
        companyModule.setEditModeContactEmail(false);
        this.savingContactEmail = false;
      }
    }
  }

  toggleContactPhone() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeContactPhone();
  }

  cancelContactPhone() {
    if (this.contact && this.contact.phones && this.contact.phones.length > 0) {
      this.phone = _.cloneDeep(this.contact.phones[0]);
    }
    companyModule.toggleEditModeContactPhone();
  }

  async validateContactPhone() {
    if (this.$refs?.editContactPhoneForm) {
      const form: any = this.$refs.editContactPhoneForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingContactPhone = true;
        await this.configContact();
        companyModule.setEditModeContactPhone(false);
        this.savingContactPhone = false;
      }
    }
  }

  toggleContactType() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeContactJobType();
  }

  cancelContactType() {
    if (this.contact.role) {
      this.role = this.contact.role;
    }
    companyModule.toggleEditModeContactJobType();
  }

  async validateContactType() {
    if (this.$refs?.editContactTypeForm) {
      const form: any = this.$refs.editContactTypeForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingContactJobType = true;
        await this.configContact();
        companyModule.setEditModeContactJobType(false);
        this.savingContactJobType = false;
      }
    }
  }

  get PageTitle() {
    return this.$route?.meta?.title || this.parentName;
  }

  get Contacts() {
    return companyModule.Contacts;
  }

  get Countries() {
    return appModule.CountryData;
  }

  get SelectedContact() {
    if (this.Contacts) {
      const contactFiltered = this.Contacts.filter(
        (contact) => contact.id === this.$route.params.id,
      );
      if (contactFiltered && contactFiltered.length > 0) {
        return contactFiltered[0];
      }
    }
    return {} as Contact;
  }

  get SelectedAddress() {
    if (
      this.SelectedContact &&
      this.SelectedContact.addresses &&
      this.SelectedContact.addresses.length > 0
    ) {
      return this.SelectedContact.addresses[0];
    }
    return {} as Address;
  }

  get SelectedPhone() {
    if (
      this.SelectedContact &&
      this.SelectedContact.phones &&
      this.SelectedContact.phones.length > 0
    ) {
      return this.SelectedContact.phones[0];
    }
    return {} as ContactPhone;
  }

  get SelectedEmail() {
    if (
      this.SelectedContact &&
      this.SelectedContact.emails &&
      this.SelectedContact.emails.length > 0
    ) {
      return this.SelectedContact.emails[0];
    }
    return {} as ContactEmail;
  }

  get HasSelectedAddress() {
    return (
      this.selectedResult &&
      this.selectedResult.geometry &&
      this.selectedResult.geometry.location &&
      this.selectedResult.geometry.location.lat() &&
      this.selectedResult.geometry.location.lng()
    );
  }

  get IsOrgAdmin() {
    return profileModule.IsOrgAdmin;
  }

  get EditModeContactName() {
    return companyModule.EditModeContactName;
  }

  get EditModeContactAddress() {
    return companyModule.EditModeContactAddress;
  }

  get EditModeContactPhone() {
    return companyModule.EditModeContactPhone;
  }

  get EditModeContactEmail() {
    return companyModule.EditModeContactEmail;
  }

  get EditModeContactJobType() {
    return companyModule.EditModeContactJobType;
  }

  get Loading() {
    return companyModule.Loading;
  }
}
