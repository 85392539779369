



























































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { constants } from '@/utils/constants';
import { requiredFieldRules } from '@/utils/validation-rules';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import OptionCreatePricingSet from '@/components/product/option/create/OptionCreatePricingSet.vue';
import OptionCreatePricingView from '@/components/product/option/create/OptionCreatePricingView.vue';
import { optionModule } from '@/store/modules/moduleOption';

@Component({
  components: {
    OptionCreatePricingView,
    OptionCreatePricingSet,
    RdmCollapsableCard,
    RdmCollapsableControlCard,
  },
})
export default class OptionCreatePricing extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private openOptionPricing = false;
  private createOptionPricingForm = false;
  private createPricingSet = true;
  private step = 1;
  private saving = false;
  private selectedTravelers = [];
  private selectedSchedule = '';
  private validFrom = '';
  private validUntil = '';
  private selectedDateTimes = [];
  private dateTimes = [
    {
      id: '1002',
      time: 'Sunday',
      times: [
        { id: 2, day: 'Sunday', time: '08:00 - 09:00' },
        { id: 3, day: 'Sunday', time: '09:00 - 10:00' },
      ],
    },
    {
      id: '1003',
      time: 'Monday',
      times: [{ id: 4, day: 'Monday', time: '11:00 - 13:00' }],
    },
  ];

  mounted() {
    this.toggleAddPricingSet();
  }

  toggleOptionPricing() {
    this.openOptionPricing = !this.openOptionPricing;
  }

  toggleAddPricingSet() {
    optionModule.setCreateModeOptionCreatePricingSet(true);
  }

  discard() {
    this.$router.push({ name: constants.routes.OPTION_LIST }).catch();
  }

  nextStepValidate4(done: boolean) {
    if (this.$refs?.pricingForm) {
      const form: any = this.$refs.pricingForm;
      const isValid = form.validate();
      if (isValid) {
        if (done) {
          // this.addOptionDetails();
        } else {
          // this.step4NextStage();
        }
      }
    }
  }

  get HasOwnData() {
    return true;
    // return (
    //     this.shareLocation === 'yes' ||
    //     this.sharePolicy === 'yes' ||
    //     this.sharePricing === 'yes' ||
    //     this.shareSchedule === 'yes'
    // );
  }

  get TravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get Schedules() {
    return [];
  }

  get CreateModeOptionCreatePricingSet() {
    return optionModule.CreateModeOptionCreatePricingSet;
  }
}
