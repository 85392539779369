var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.Loading),expression:"Loading"}]},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-row-divider@10, table-tfoot"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.Loading),expression:"!Loading"}],staticClass:"elevation-0",attrs:{"headers":_vm.optionsHeaders,"items":_vm.optionsList,"items-per-page":10,"search":_vm.productSearch,"loading":_vm.Loading,"sort-by":"internalName","custom-filter":_vm.filterProducts,"hide-default-footer":(_vm.optionsList && _vm.optionsList.length === 0) || !_vm.optionsList,"footer-props":{
        itemsPerPageOptions: _vm.paginationOptions,
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
      }},scopedSlots:_vm._u([{key:"item.internalName",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.internalName ? item.internalName : 'unknown'))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('rdm-status-indicator',{attrs:{"status":item.status}}),_vm._v(" "+_vm._s(_vm._f("capitalize")(item.status))+" ")]}},{key:"item.view",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: _vm.routeOptionDetails, params: { id: item.id } },"color":"primary","plain":"","disabled":!item.id}},[_vm._v("View")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"color":"error","plain":""},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v("Remove")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-left"},[_vm._v(" You don’t have any Options yet. Click "),_c('router-link',{attrs:{"to":{ name: _vm.routeOptionCreate }}},[_vm._v("here")]),_vm._v(" to add one. ")],1)]},proxy:true}])})],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.modalIsOpen),callback:function ($$v) {_vm.modalIsOpen=$$v},expression:"modalIsOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("Remove Option")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Are you sure you want to remove "+_vm._s(_vm._f("capitalize")(_vm.optionToRemove.internalName))+"? ")]),_c('p',[_vm._v(" By removing this Option you are potentially changing data associated to a channel. Please validate and republish your data. ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.proceedRemoveOption}},[_vm._v("Remove")]),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }