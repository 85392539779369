































































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import WebRedemptionModule from '@/store/modules/.deprecated/moduleWebRedemption';

@Component
export default class VoucherClarification extends Vue {
  private webRedemption = getModule(WebRedemptionModule);

  private selectedItem = null;

  private selectProduct(name: any) {
    this.webRedemption.setSelectedTicket(null);
    this.webRedemption.setProduct(name);
  }

  private get selectedProduct() {
    return this.webRedemption.getSelectedProduct;
  }

  private get products() {
    return this.webRedemption.products.map((product) => product.name);
  }
}
