

























































































import { Emit, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { VuetifyThemeItem } from 'vuetify/types/services/theme';

import { constants } from '@/utils/constants';
import { appModule } from '@/store/modules/moduleApp';
import { httpModule } from '@/store/modules/moduleHttp';
import { profileModule } from '@/store/modules/moduleProfile';

@Component({
  name: 'the-navbar',
})
export default class TheNavbar extends Vue {
  private backgroundColor: VuetifyThemeItem = this.$vuetify.theme.themes.light.white;
  private primaryColor: VuetifyThemeItem =
    this.$vuetify.theme.themes.light.v_primary_base;
  private windowSize: number = window.innerWidth;
  private profileName = constants.routes.PROFILE;
  private helpEmail = process.env.REDEAM_HELP_EMAIL || 'support@redeam.com';

  @Emit()
  updateLocale(locale: string) {
    this.$i18n.locale = locale;
  }

  @Emit()
  showSidebar() {
    appModule.setIsSidebarActive(true);
  }

  @Emit()
  async logout() {
    await httpModule.logout();

    // TODO: this possibly can work badly in some very rare cases
    // e.g.
    // 1. there is async operation in userModule that changes array of users IN PROGRESS
    // 2. clearState launches and finishes clearing the array of users
    // 3. async operation finishes and put data in the array of users
    // 4. after logout we have not clear (and even INCONSISTENT!!!) state of userModule
    //
    // the proper solution would be to recreate (unregister -> register) a module,
    // but it's not so straightforward with 'vuex-module-decorators'... :(

    // this.alertModule.clearAlerts();
    // this.userModule.clearState();
    // this.webArrivalModule.clearState();
  }

  @Emit()
  toggleSidebar(state: boolean) {
    appModule.setIsSidebarActive(state);
  }

  created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  goHome() {
    this.$router.push({ name: constants.routes.BASE }).catch(() => {});
  }

  private async goToProfile() {
    if (this.$route.name !== constants.routes.PROFILE) {
      await this.$router.push({ name: constants.routes.PROFILE }).catch(() => {});
    }
  }

  showAbout() {
    appModule.setShowAboutPage(true);
  }

  get isMobileView() {
    return this.windowSize < 960;
  }

  get User() {
    return profileModule.Profile;
  }

  handleResize() {
    this.windowSize = window.innerWidth;
    this.backgroundColor =
      window.innerWidth < 960
        ? this.$vuetify.theme.themes.light.primary
        : this.$vuetify.theme.themes.light.white;
  }
}
