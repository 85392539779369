var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.Loading,"headers":_vm.offerHeaders,"items":_vm.Offers,"items-per-page":10,"search":_vm.Search,"sort-by":['name'],"hide-default-footer":(_vm.Offers && _vm.Offers.length === 0) || !_vm.Offers,"footer-props":{
      itemsPerPageOptions: _vm.paginationOptions,
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
    }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("titleize")(item.name)))]),_c('div',{staticClass:"caption sub-text"},[_vm._v(" "+_vm._s(_vm._f("titleize")(_vm.getPrimaryAddress(item.productId)))+" ")])]}},{key:"item.view",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"to":_vm.getOfferRoute(item),"color":"primary","plain":"","disabled":!item.id}},[_vm._v("View")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"color":"error","plain":"","disabled":!item.id},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v("Remove")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-left"},[_vm._v(" You don’t have any Offers yet. Click "),_c('router-link',{attrs:{"to":{ name: _vm.offerLink }}},[_vm._v("here")]),_vm._v(" to add one. ")],1)]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.modalIsOpen),callback:function ($$v) {_vm.modalIsOpen=$$v},expression:"modalIsOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("Remove Offer")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Are you sure you want to remove "+_vm._s(_vm._f("capitalize")(_vm.offerToRemove.name))+" Offer? ")]),_c('p',[_vm._v(" By removing this Offer you are potentially changing data associated to a channel. Please validate and republish your data. ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.removeOffer}},[_vm._v("Remove")]),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }