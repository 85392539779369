export enum InventoryType {
  FREESALE = 'FREESALE',
  AVAILABLE = 'AVAILABLE',
  LIMITED = 'LIMITED',
  SOLD_OUT = 'SOLD_OUT',
  CLOSED = 'CLOSED',
}

export enum DeliveryFormats {
  EAN13 = 'EAN13',
  CODE39 = 'CODE39',
  CODE128 = 'CODE128',
  QR_CODE = 'QR_CODE',
  TEXT = 'TEXT',
  UPCA = 'UPCA',
}

export enum DeliveryMethods {
  TICKET = 'TICKET',
  VOUCHER = 'VOUCHER',
}

export enum RedemptionMethods {
  MANIFEST = 'MANIFEST',
  DIGITAL = 'DIGITAL',
  PRINT = 'PRINT',
}

export enum AgeClassification {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  INFANT = 'INFANT',
  YOUTH = 'YOUTH',
  STUDENT = 'STUDENT',
  SENIOR = 'SENIOR',
}

export enum CancellationPolicyOptions {
  NO,
  YES,
}

export enum RefundablePolicyOptions {
  NO,
  YES,
}

export enum BookingCutoffOptions {
  NO,
  YES,
}

export interface CancellationPolicy {
  cancellationType: CancellationPolicyOptions;
  cancellationCutoff: number;
  refundable: RefundablePolicyOptions;
  bookingCutoff: number;
  bookingCutoffType: BookingCutoffOptions;
}

export interface ICancellationPolicy {
  cancellable: boolean;
  refundable: boolean;
  cutoff: number; // Minutes/Hours/Days of time in seconds
}

export interface IAddress {
  LocationName: string;
  LocationType: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  latitude: string;
  longitude: string;
}

export interface IProduct {
  id: string; // uuid
  name: string;
  code: string;
  locale: string;
  timezone: string;
  description: string;
  activityType: string; // enum?
  instantConfirmation: boolean;
  instantDelivery: boolean;
  inventoryType: InventoryType;
  deliveryFormats: Array<DeliveryFormats>;
  deliveryMethods: Array<DeliveryMethods>;
  redemptionMethod: RedemptionMethods;
  locations: Array<IAddress>;
  purchaseAddress: IAddress;
  departureAddress: IAddress;
  cancellationPolicy: ICancellationPolicy;
  ext?: any;
}

export interface IOption {
  id: string; // uuid
  productId: string;
  name: string;
  code: string;
  locale: string;
  timezone: string;
  description: string;
  activityType: string; // enum?
  instantConfirmation: boolean;
  instantDelivery: boolean;
  inventoryType: InventoryType;
  deliveryFormats: Array<DeliveryFormats>;
  deliveryMethods: Array<DeliveryMethods>;
  redemptionMethod: RedemptionMethods;
  purchaseAddress: IAddress;
  departureAddress: IAddress;
  cancellationPolicy: ICancellationPolicy;
  ext?: any;
}

export interface RdmAvailabilityItemDates {
  localDateTimeStart: string;
  localDateTimeEnd: string;
}

export interface RdmAvailabilityItem {
  open: boolean;
  times: Array<RdmAvailabilityItemDates>;
  capacity: number;
}

export interface AvailabilityItem {
  id: string;
  optionId: string;
  unitId?: string;
  localDateTimeStart: string;
  localDateTimeEnd: string;
  status: InventoryType;
  vacancies: number;
  maxCapacity?: number;
  isFreesale?: boolean;
  isBlackedOut?: boolean;
}

export interface IRdmAvailabilityItems {
  '0': RdmAvailabilityItem;
  '1': RdmAvailabilityItem;
  '2': RdmAvailabilityItem;
  '3': RdmAvailabilityItem;
  '4': RdmAvailabilityItem;
  '5': RdmAvailabilityItem;
  '6': RdmAvailabilityItem;
}

export interface AvailChangeReq {
  AvailID: string;
  UnitID?: string;
  Reason: string;
  Delta?: number;
  Capacity?: number;
  Status?: string;
}
