import moment from 'moment';
import _ from 'lodash';
import { intervalToDuration, format, parseISO } from 'date-fns';

export const formatDate = function (value: string) {
  if (value) {
    return moment(value).format('MMMM Do YYYY');
  }

  return value;
};

export const formatDateTime = function (value: string) {
  if (value) {
    return moment(value).format('MM/DD/YYYY h:mm:ss a');
  }
  return value;
};

export const formatDateMmDdYyyy = function (value: string) {
  if (value) {
    return moment(value).format('MM/DD/YYYY');
  }

  return value;
};

export const formatTime24Hours = function (value: string) {
  if (value) {
    return moment(`2020-01-31 ${value}`).format('HH:mm');
  }

  return value;
};

export const formatTime12Hours = function (value: string) {
  if (value) {
    return moment(`2020-01-31 ${value}`).format('h:mm a');
  }

  return value;
};

export const formatTime12HoursFromTS = function (value: string) {
  if (value) {
    return moment(value).format('h:mm a');
  }

  return value;
};

export const formatTime12HourOnly = function (value: string) {
  if (value) {
    return moment().hour(_.toNumber(value)).format('h a');
  }

  return value;
};

export const formatDayOfTheWeek = function (value: number) {
  if (value) {
    return moment().startOf('week').day(value).format('ddd');
  }

  return value;
};

export const formatIsoToDayName = function (value: string) {
  if (value) {
    try {
      const d = parseISO(value);
      return format(d, 'eeee');
    } catch (e) {
      return value;
    }
  }

  return value;
};

export const formatMinutesToTime = function (value: number) {
  if (value) {
    const duration = intervalToDuration({ start: 0, end: value * 60 * 1000 });
    if (duration.years !== undefined && duration.years > 0) {
      return `${duration.years} year${duration.years > 1 ? 's' : ''} ${
        duration.months || 0
      } month${duration.months && duration.months > 1 ? 's' : ''} ${
        duration.days || 0
      } day${duration.days && duration.days > 1 ? 's' : ''} ${duration.hours || 0} hour${
        duration.hours && duration.hours > 1 ? 's' : ''
      } ${duration.minutes || 0} minute${
        duration.minutes && duration.minutes > 1 ? 's' : ''
      }`;
    } else if (duration.months !== undefined && duration.months > 0) {
      return `${duration.months || 0} month${
        duration.months && duration.months > 1 ? 's' : ''
      } ${duration.days || 0} day${duration.days && duration.days > 1 ? 's' : ''} ${
        duration.hours || 0
      } hour${duration.hours && duration.hours > 1 ? 's' : ''} ${
        duration.minutes || 0
      } minute${duration.minutes && duration.minutes > 1 ? 's' : ''}`;
    } else if (duration.days !== undefined && duration.days > 0) {
      return `${duration.days || 0} day${duration.days && duration.days > 1 ? 's' : ''} ${
        duration.hours || 0
      } hour${duration.hours && duration.hours > 1 ? 's' : ''} ${
        duration.minutes || 0
      } minute${duration.minutes && duration.minutes > 1 ? 's' : ''}`;
    } else if (duration.hours !== undefined && duration.hours > 0) {
      return `${duration.hours || 0} hour${
        duration.hours && duration.hours > 1 ? 's' : ''
      } ${duration.minutes || 0} minute${
        duration.minutes && duration.minutes > 1 ? 's' : ''
      }`;
    } else if (duration.minutes !== undefined && duration.minutes > 0) {
      return `${duration.minutes || 0} minute${
        duration.minutes && duration.minutes > 1 ? 's' : ''
      }`;
    } else if (duration.minutes !== undefined && duration.minutes === 0) {
      return '-';
    }
  }

  return value;
};
