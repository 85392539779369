






































































































































































import { Component, Vue } from 'vue-property-decorator';
import { pricingModule } from '@/store/modules/modulePricing';
import { requiredFieldRules } from '@/utils/validation-rules';
import { TaxesAndFees } from '@/api/inventory/InventoryModels';
import { arrayMove } from '@/utils/helpers';
import { inventoryModule } from '@/store/modules/.deprecated/moduleInventory';
import { constants } from '@/utils/constants';
import { httpModule } from '@/store/modules/moduleHttp';
import { taxesAndFeesModule } from '@/store/modules/moduleTaxAndFees';
import { appModule } from '@/store/modules/moduleApp';
import { centsToDollars } from '@/utils/helpers';
import { Fee } from '@/models';

@Component
export default class OfferCreateTaxes extends Vue {
  private centsToDollars = centsToDollars;
  private routerTaxesAndFeesViewEdit = constants.routes.TAXES_AND_FEES_EDIT;
  private routerProductOfferCreate = constants.routes.OFFER_CREATE;
  private requiredField = requiredFieldRules;
  private taxAndFeesHeaders: Array<Record<string, any>> = [
    { text: 'Name', value: 'name', align: 'left', sortable: false },
    { text: 'Type', value: 'chargeType', align: 'left', sortable: false },
    { text: 'Rate', value: 'rateType', align: 'left', sortable: false },
    { text: 'Calculation', value: 'calcText', align: 'left', sortable: false },
    {
      text: 'Effective Dates',
      value: 'effectiveDateRange',
      align: 'left',
      sortable: false,
    },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];
  private taxesAndFees = [] as Array<TaxesAndFees>;
  private selectedTnF = [] as Array<TaxesAndFees>;
  private selectedCurrency = 'USD';
  // private appliedTnF = [] as Array<TaxesAndFees>;

  private async mounted() {
    await httpModule.getTaxesAndFees();
  }

  destroyed() {
    this.AppliedTnF = [];
  }

  private applyTaxesAndFees() {
    this.taxesAndFees = this.taxesAndFees?.filter(
      (x) => !this.selectedTnF.some((y) => x.id === y.id),
    );
    this.AppliedTnF = [...this.selectedTnF, ...this.AppliedTnF];
    this.selectedTnF = [];
    this.$root.$emit(constants.events.APPLY_TAXES_AND_FEES);
  }

  private removeAppliedTax(tax: TaxesAndFees) {
    this.taxesAndFees = [...this.taxesAndFees, tax];
    this.selectedTnF = [];
    this.AppliedTnF = this.AppliedTnF.filter((item) => item.id !== tax.id);
  }

  private formattedCurrencies() {
    return this.Currencies.map((item: any) => {
      return { ...item, itemText: `${item.description} (${item.value})` };
    });
  }

  private moveAppliedUp(index: number) {
    this.AppliedTnF = arrayMove(this.AppliedTnF, index, index - 1);
  }

  private moveAppliedDown(index: number) {
    this.AppliedTnF = arrayMove(this.AppliedTnF, index, index + 1);
  }

  private updateSelectedCurrency() {
    taxesAndFeesModule.updateSelectedCurrency(this.selectedCurrency);
  }

  get TaxesAndFees() {
    return taxesAndFeesModule.TaxesAndFees.map((item: Fee) => {
      return {
        ...item,
        calcText: `${item.includedInPricing ? 'Included in Pricing,' : ''} ${
          item.ruleType
        }, ${item.applicationLevel}`,
      };
    }).filter((fee) => !this.AppliedTnF.find((f) => f.id === fee.id));
  }

  get IndependentOnlyApplied() {
    return this.AppliedTnF.filter((item) => {
      return item.ruleType !== 'INDEPENDENT';
    }).length;
  }

  get Step3Done() {
    return pricingModule.CreateStep3Done;
  }

  get Form3Valid() {
    return pricingModule.CreateForm3Valid;
  }

  set Form3Valid(valid: boolean) {
    pricingModule.setCreateForm3Valid(valid);
  }

  get LoadingInventory(): boolean {
    return inventoryModule.Loading;
  }

  get AppliedTnF(): Array<TaxesAndFees> {
    return pricingModule.SelectedTaxesAndFees;
  }

  set AppliedTnF(t: Array<TaxesAndFees>) {
    pricingModule.setSelectedTaxesAndFees(t);
  }

  get Currencies() {
    return appModule.SortedCurrencies;
  }
}
