import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import _ from 'lodash';
import { GRSchedule, InventoryType, Option, OptionCapacityPool, Schedule, TripRoute } from '@/models';
import { Unit } from '@/api/inventory/InventoryModels';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-option',
  namespaced: true,
})
class ModuleOption extends VuexModule {
  /* Data */
  private loading = false;
  private createOptionFormState = false;
  private options = [] as Array<Option>;
  private option = {} as Option;
  private baseOption = {} as Option;
  private units = [] as Array<Unit>;
  private selectedUnitIds = [] as Array<string>;
  private tripRoute = {} as TripRoute;
  private baseTripRoute = {} as TripRoute;
  private inventoryType = '' as InventoryType;
  private editOptionLocationForm = {} as any;
  private optionCapacity = {} as OptionCapacityPool;
  private baseOptionCapacity = {} as OptionCapacityPool;
  private description = '';
  private availability: boolean | undefined;
  private capacity: boolean | undefined;
  private cost: boolean | undefined;
  private availabilityValid = false;
  private createModeOptionCreatePricingSet = false;
  private createOptionScheduleForm = false;
  private editOptionLocationModal = false;
  private editOptionLocationFormState = false;
  private editOptionPolicyModal = false;
  private editOptionPolicyFormState = false;
  private editModeName = false;
  private editModeDescription = false;
  private editModeAvailabilityType = false;
  private editModeCapacityType = false;
  private editModeInventoryType = false;
  private editModeCost = false;
  private editModeRedemptionMethod = false;
  private editModeDeliveryMethods = false;
  private editModeDeliveryFormats = false;
  private editModePrimaryLocation = false;
  private editModeDepartureLocation = false;
  private editModeArrivalLocation = false;
  private editModeVisitedLocation = false;
  private editModeCancellationPolicy = false;
  private editModeOptionGuestType = false;
  private editModeTravelerType = false;
  private editModePriceOverride = false;
  private optionSchedules = [] as Array<GRSchedule>;
  private optionSchedule = {} as Schedule;
  private openCreatePricing = false;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get OpenCreatePricing() {
    return this.openCreatePricing;
  }

  get OptionSchedule(): Schedule {
    return this.optionSchedule;
  }

  get OptionSchedules(): Array<GRSchedule> {
    return this.optionSchedules;
  }

  get CreateOptionFormState(): boolean {
    return this.createOptionFormState;
  }

  get Options(): Array<Option> {
    return this.options;
  }

  get Option(): Option {
    return this.option;
  }

  get BaseOption(): Option {
    return this.baseOption;
  }

  get TravelerTypes(): Array<Unit> {
    return this.units;
  }

  get SelectedUnitIds(): Array<string> {
    return this.selectedUnitIds;
  }

  get TripRoute(): TripRoute {
    return this.tripRoute;
  }

  get BaseTripRoute(): TripRoute {
    return this.baseTripRoute;
  }

  get Description(): string {
    return this.description;
  }

  get InventoryType(): InventoryType {
    return this.inventoryType;
  }

  get Availability(): boolean | undefined {
    return this.availability;
  }

  get Capacity(): boolean | undefined {
    return this.capacity;
  }

  get Cost(): boolean | undefined {
    return this.cost;
  }

  get OptionCapacityPool(): OptionCapacityPool {
    return this.optionCapacity;
  }

  get BaseOptionCapacityPool(): OptionCapacityPool {
    return this.baseOptionCapacity;
  }

  get AvailabilityValid(): boolean {
    return this.availabilityValid;
  }

  get CreateModeOptionCreatePricingSet(): boolean {
    return this.createModeOptionCreatePricingSet;
  }

  get CreateOptionScheduleForm(): boolean {
    return this.createOptionScheduleForm;
  }

  get EditModeName(): boolean {
    return this.editModeName;
  }

  get EditModeDescription(): boolean {
    return this.editModeDescription;
  }

  get EditModeAvailabilityType(): boolean {
    return this.editModeAvailabilityType;
  }

  get EditModeCapacityType(): boolean {
    return this.editModeCapacityType;
  }

  get EditModeInventoryType(): boolean {
    return this.editModeInventoryType;
  }

  get EditModeCost(): boolean {
    return this.editModeCost;
  }

  get EditModeRedemptionMethod(): boolean {
    return this.editModeRedemptionMethod;
  }

  get EditModeDeliveryMethods(): boolean {
    return this.editModeDeliveryMethods;
  }

  get EditModeDeliveryFormats(): boolean {
    return this.editModeDeliveryFormats;
  }

  get EditModePrimaryLocation(): boolean {
    return this.editModePrimaryLocation;
  }

  get EditModeDepartureLocation(): boolean {
    return this.editModeDepartureLocation;
  }

  get EditModeArrivalLocation(): boolean {
    return this.editModeArrivalLocation;
  }

  get EditModeVisitedLocation(): boolean {
    return this.editModeVisitedLocation;
  }

  get EditModeCancellationPolicy(): boolean {
    return this.editModeCancellationPolicy;
  }

  get EditModeOptionGuestType(): boolean {
    return this.editModeOptionGuestType;
  }

  get EditOptionLocationForm(): any {
    return this.editOptionLocationForm;
  }

  get EditOptionLocationFormState(): boolean {
    return this.editOptionLocationFormState;
  }

  get EditOptionLocationModal(): boolean {
    return this.editOptionLocationModal;
  }

  get EditOptionPolicyModal(): boolean {
    return this.editOptionPolicyModal;
  }

  get EditOptionPolicyFormState(): boolean {
    return this.editOptionPolicyFormState;
  }

  get EditModeTravelerType(): boolean {
    return this.editModeTravelerType;
  }

  get EditModePriceOverride(): boolean {
    return this.editModePriceOverride;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setOpenCreatePricing(state: boolean) {
    this.openCreatePricing = state;
  }

  @Mutation
  setOptionSchedules(schedules: Array<GRSchedule>) {
    this.optionSchedules = schedules;
  }

  @Mutation
  setOptionSchedule(schedule: Schedule) {
    this.optionSchedule = schedule;
  }

  @Mutation
  setCreateOptionFormState(state: boolean) {
    this.createOptionFormState = state;
  }

  @Mutation
  setCreateModeOptionCreatePricingSet(state: boolean) {
    this.createModeOptionCreatePricingSet = state;
  }

  @Mutation
  setCreateOptionScheduleForm(state: boolean) {
    this.createOptionScheduleForm = state;
  }

  @Mutation
  setOptions(options: Array<Option>) {
    this.options = options;
  }

  @Mutation
  setOption(option: Option) {
    this.option = option;
  }

  @Mutation
  setBaseOption(option: Option) {
    this.baseOption = option;
  }

  @Mutation
  setUnits(units: Array<Unit>) {
    this.units = units;
  }

  @Mutation
  setSelectedUnitIds(units: Array<string>) {
    this.selectedUnitIds = units;
  }

  @Mutation
  setTripRoute(tripRoute: TripRoute) {
    this.tripRoute = tripRoute;
  }

  @Mutation
  setBaseTripRoute(tripRoute: TripRoute) {
    this.baseTripRoute = tripRoute;
  }

  @Mutation
  setDescription(description: string) {
    this.description = description;
  }

  @Mutation
  setInventoryType(inventoryType: InventoryType) {
    this.inventoryType = inventoryType;
  }

  @Mutation
  setAvailability(active: boolean) {
    this.availability = active;
  }

  @Mutation
  setCapacity(active: boolean) {
    this.capacity = active;
  }

  @Mutation
  setCost(active: boolean | undefined) {
    this.cost = active;
  }

  @Mutation
  setAvailabilityValid(valid: boolean) {
    this.availabilityValid = valid;
  }

  @Mutation
  setOptionCapacityPool(optionCapacity: OptionCapacityPool) {
    this.optionCapacity = optionCapacity;
  }

  @Mutation
  setBaseOptionCapacityPool(optionCapacity: OptionCapacityPool) {
    this.baseOptionCapacity = optionCapacity;
  }

  @Mutation
  setEditModeName(mode: boolean) {
    this.editModeName = mode;
  }

  @Mutation
  setEditModeDescription(mode: boolean) {
    this.editModeDescription = mode;
  }

  @Mutation
  setEditModeAvailabilityType(mode: boolean) {
    this.editModeAvailabilityType = mode;
  }

  @Mutation
  setEditModeCapacityType(mode: boolean) {
    this.editModeCapacityType = mode;
  }

  @Mutation
  setEditModeInventoryType(mode: boolean) {
    this.editModeInventoryType = mode;
  }

  @Mutation
  setEditModeCost(mode: boolean) {
    this.editModeCost = mode;
  }

  @Mutation
  setEditModeCancellationPolicy(mode: boolean) {
    this.editModeCancellationPolicy = mode;
  }

  @Mutation
  setEditModeOptionGuestType(mode: boolean) {
    this.editModeOptionGuestType = mode;
  }

  @Mutation
  setEditModeRedemptionMethod(mode: boolean) {
    this.editModeRedemptionMethod = mode;
  }

  @Mutation
  setEditModeDeliveryMethods(mode: boolean) {
    this.editModeDeliveryMethods = mode;
  }

  @Mutation
  setEditModeDeliveryFormats(mode: boolean) {
    this.editModeDeliveryFormats = mode;
  }

  @Mutation
  setEditModePrimaryLocation(mode: boolean) {
    this.editModePrimaryLocation = mode;
  }

  @Mutation
  setEditModeDepartureLocation(mode: boolean) {
    this.editModeDepartureLocation = mode;
  }

  @Mutation
  setEditModeArrivalLocation(mode: boolean) {
    this.editModeArrivalLocation = mode;
  }

  @Mutation
  setEditModeVisitedLocation(mode: boolean) {
    this.editModeVisitedLocation = mode;
  }

  @Mutation
  setEditOptionLocationForm(form: any) {
    this.editOptionLocationForm = form;
  }

  @Mutation
  setEditOptionLocationModal(state: boolean) {
    this.editOptionLocationModal = state;
  }

  @Mutation
  setEditOptionLocationFormState(state: boolean) {
    this.editOptionLocationFormState = state;
  }

  @Mutation
  setEditOptionPolicyModal(state: boolean) {
    this.editOptionPolicyModal = state;
  }

  @Mutation
  setEditOptionPolicyFormState(state: boolean) {
    this.editOptionPolicyFormState = state;
  }

  @Mutation
  setEditModeTravelerType(mode: boolean) {
    this.editModeTravelerType = mode;
  }

  @Mutation
  setEditModePriceOverride(mode: boolean) {
    this.editModePriceOverride = mode;
  }

  /* Actions */
  @Action
  selectedOption(id: string) {
    const option = this.Options.find((o) => o.id === id);
    if (option) {
      this.setOption(_.cloneDeep(option));
      this.setBaseOption(_.cloneDeep(option));
      // this.setDescription(_.cloneDeep(this.Option?.option?.shortDescription || ''));
      this.setAvailability(
        option.sharesAvailabilityWithProduct !== undefined ? option.sharesAvailabilityWithProduct : false,
      );
      this.setCapacity(option.sharesCapacityWithProduct !== undefined ? option.sharesCapacityWithProduct : false);
    }
  }

  @Action
  createOption(option: Option) {
    const options = _.cloneDeep(this.Options);
    options.push(option);
    this.setOptions(options);
  }

  @Action
  updateOption(option: Option) {
    const options = _.cloneDeep(this.Options);
    const index = options.findIndex((o) => o.id === option.id);
    if (index > -1) {
      options[index] = option;
      this.setOptions(options);
      if (option && option.id) {
        this.selectedOption(option.id);
      }
    }
  }

  @Action
  removeOption(id: string) {
    const options = _.cloneDeep(this.Options);
    const index = options.findIndex((o) => o.id === id);
    if (index > -1) {
      options.splice(index, 1);
      this.setOptions(options);
    }
  }
  @Action
  updateOptionSchedules(schedules: Array<GRSchedule>) {
    schedules.map((item: GRSchedule) => {
      if (item.open === undefined) item.open = false;
    });
    this.setOptionSchedules(schedules);
  }

  @Action
  updateOptionSchedule(schedule: Schedule) {
    this.setOptionSchedule(schedule);
  }

  @Action
  appendPricingSetAndNext() {
    // todo add to pricing set list
    this.setCreateModeOptionCreatePricingSet(false);
    setTimeout(() => {
      this.setCreateModeOptionCreatePricingSet(true);
    }, 500);
  }

  @Action
  buildOption(o: Option) {
    // this.setOption(o);
    const option = _.cloneDeep(o);
    // if (!option.version) {
    //   option.version = 0;
    // }
    // option.version += 1;
    //
    // console.log('build', this.Availability, this.Capacity);
    // option.sharesAvailabilityWithProduct = _.cloneDeep(this.Availability);
    // option.sharesCapacityWithProduct = _.cloneDeep(this.Capacity);
    // if (option.option) {
    //   option.option.shortDescription = _.cloneDeep(this.Description);
    //   // todo: locations?
    //   // product.product.locations = [];
    //   // if (!_.isEmpty(this.PrimaryLocation)) {
    //   //   product.product.locations.push(_.clone(this.PrimaryLocation));
    //   // }
    //   // if (!_.isEmpty(this.DepartureLocation)) {
    //   //   product.product.locations.push(_.clone(this.DepartureLocation));
    //   // }
    //   // if (!_.isEmpty(this.ArrivalLocation)) {
    //   //   product.product.locations.push(_.clone(this.ArrivalLocation));
    //   // }
    //   // if (!_.isEmpty(this.VisitedLocations)) {
    //   //   product.product.locations.push(..._.clone(this.VisitedLocations));
    //   // }
    // }

    this.setOption(option);
  }

  @Action
  configOption() {
    if (this.Option && this.Option.id) {
      this.setDescription(_.cloneDeep(this.Option.description || ''));
      this.setAvailability(_.cloneDeep(this.Option.sharesAvailabilityWithProduct));
      this.setCapacity(_.cloneDeep(this.Option.sharesCapacityWithProduct));
    }
  }

  @Action
  configureLocationForm() {
    const departure =
      this.TripRoute && _.isString(this.TripRoute.departure) && this.TripRoute.departure.length > 0
        ? this.TripRoute.departure
        : '';
    const arrival =
      this.TripRoute && _.isString(this.TripRoute.arrival) && this.TripRoute.arrival.length > 0
        ? this.TripRoute.arrival
        : '';
    const stops =
      this.TripRoute && _.isArray(this.TripRoute.stops) && this.TripRoute.stops.length > 0 ? this.TripRoute.stops : [];
    this.setEditOptionLocationForm({
      tripRoute: {
        departure: departure,
        arrival: arrival,
        stops: stops,
      } as TripRoute,
    });
  }

  @Action
  appendUnit(unit: Unit) {
    this.units.push(unit);
  }

  @Action
  appendUnitFromTravelerList(id: string) {
    const units = _.cloneDeep(travelerTypeModule.TravelerTypes);
    const index = units.findIndex((t) => t.id === id);
    if (index > -1) {
      this.units.push(units[index]);
    }
  }

  @Action
  updateSelectedUnit() {
    this.setSelectedUnitIds([] as Array<string>);
    this.setSelectedUnitIds(this.units.map((u) => u.id));
  }

  @Action
  removeUnit(id: string) {
    const units = _.cloneDeep(this.TravelerTypes);
    const index = units.findIndex((u) => u.id === id);
    if (index > -1) {
      units.splice(index, 1);
      this.setUnits(units);
    }
  }

  @Action
  updateOptionTripRoute(tripRoute: TripRoute) {
    this.setTripRoute(tripRoute);
    this.setBaseTripRoute(tripRoute);
  }

  @Action
  clearEditOptionLocationForm() {
    this.setEditOptionLocationForm({
      addressId: '',
      timezone: '',
      tripRoute: {} as TripRoute,
    });
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setEditModeDescription(false);
    this.setEditModeCapacityType(false);
    this.setEditModeOptionGuestType(false);
    this.setEditModeCost(false);
    this.setEditModeCancellationPolicy(false);
    this.setEditModeInventoryType(false);
    this.setEditModeAvailabilityType(false);
    this.setEditModeArrivalLocation(false);
    this.setEditModeDeliveryFormats(false);
    this.setEditModeDeliveryMethods(false);
    this.setEditModeDepartureLocation(false);
    this.setEditModePrimaryLocation(false);
    this.setEditModeRedemptionMethod(false);
    this.setEditModeVisitedLocation(false);
    this.setEditOptionLocationForm({} as any);
    this.setEditOptionLocationModal(false);
    this.setEditOptionLocationFormState(false);
    this.setEditOptionPolicyModal(false);
    this.setEditOptionPolicyFormState(false);
    this.setEditModeTravelerType(false);
    this.setEditModeCapacityType(false);
    this.setEditModePriceOverride(false);
    this.setCreateModeOptionCreatePricingSet(false);
    this.setCreateOptionScheduleForm(false);
    this.setOption({} as Option);
    this.setBaseOption({} as Option);
    this.setOptions([] as Array<Option>);
    this.setTripRoute({} as TripRoute);
    this.setAvailability(false);
    this.setCapacity(false);
    this.setDescription('');
    this.setOptionCapacityPool({} as OptionCapacityPool);
    this.setBaseOptionCapacityPool({} as OptionCapacityPool);
    this.setOptionSchedules([] as Array<GRSchedule>);
    this.setOptionSchedule({} as Schedule);
  }
}

export const optionModule = getModule(ModuleOption, store);
