import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import inventoryClient from '@/api/inventory/InventoryClient';
import { companyModule } from '@/store/modules/moduleCompany';
import { appModule } from '@/store/modules/moduleApp';
import { codes } from '@/utils/codeConstants';
import {
  AvailabilitySchedule,
  Codes,
  GroupedAvailabilitySchedule,
  OfferDateRangeReq,
  OppAddressQueryCriteria,
  OppPricing,
  OppProductAddressWrapper,
  PriceSchedule,
  ScheduleView,
  Unit,
} from '@/api/inventory/InventoryModels';
import { map, switchMap } from 'rxjs/operators';
import { pricingModule } from '@/store/modules/modulePricing';
import { taxesAndFeesModule } from '@/store/modules/moduleTaxAndFees';
import { userModule } from '@/store/modules/moduleUser';
import { QueryListUsers } from '@/api/wps/QueryModels';
import { Account as UserProfile } from '@/api/wps/BackplaneModels';
import { constants } from '@/utils/constants';
import { FeedbackMessage, OrganizationRQ, WpsApiLoginRQ } from '@/api/wps/WpsModels';
import { profileModule } from '@/store/modules/moduleProfile';
import router from '@/router';
import _ from 'lodash';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { optionModule } from '@/store/modules/moduleOption';
import { productModule } from '@/store/modules/moduleProduct';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import moment from 'moment';
import {
  ActionButton,
  Address,
  Company,
  Contact,
  Meta,
  Offer,
  OfferPricing,
  Option,
  Product,
  ProductUnit,
  RateCard,
  TripRoute,
  Fee,
  ProductCapacityPool,
  OptionCapacityPool,
  Schedule,
  GRSchedule,
  EffectiveDateRange,
  TimeSlot,
  OfferPricingList,
  OfferCapacity,
  OfferCapacityList,
  OfferCapacityInList,
  CompanyRate,
  ChannelBinding,
  OfferCapacityInventory,
  AvailabilityItem,
  DayAvailability,
  AvailChangeReq,
  InventoryType,
  OperatorUser,
} from '@/models';
import faker from 'faker';
import { BookingsReport, BookingsReportFilter } from '@/models/Report';
import { reportModule } from './moduleReport';
import reportsClient from '@/api/reporting/ReportsClient';
import { AuditMethod, AuditRecord } from '@/api/models/General';
import { incrementMeta, incrementVersion } from '@/utils/helpers';
import { channelModule } from '@/store/modules/moduleChannel';
import { appCodes } from '@/app-codes/appCodes';
import { offerModule } from '@/store/modules/moduleOffer';
import { countryData } from '@/app-codes/countryData';
import { availabilityStatusModule } from './moduleAvailabilityStatus';
import { format, parseISO } from 'date-fns';
import { eventsModule } from '@/store/modules/moduleEvents';
import backplaneClient from '@/api/backplane/BackplaneClient';

function auditLog(before: any, after: any, method: AuditMethod) {
  const audit = {} as AuditRecord;
  audit.dataBefore = before;
  audit.dataAfter = after;
  audit.changeType = method;
  inventoryClient.createAuditLog(audit).toPromise().then();
}

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-http',
  namespaced: true,
})
class ModuleHttp extends VuexModule {
  /**
   *  App
   **/
  @Action
  async getPublicFeatures() {
    //TODO: save these commented sections to restore public features when we will be ready RE-7998
    // appModule.startLoading();
    // appModule.setPublicFeatures(await wpsClient.listPublicFeatures().toPromise());
    // appModule.finishLoading();
  }

  @Action
  async getCodes() {
    appModule.startLoading();
    appModule.setCodes(appCodes as Codes);
    appModule.setCountryCodes(countryData);
    appModule.finishLoading();
  }

  @Action
  async sendEmail(feedback: FeedbackMessage) {
    console.log(feedback);
    //todo replace to a voucher service RE-7999
    /* await wpsClient.sendSupportEmail(feedback).toPromise(); */
  }

  /**
   *  Profile
   **/
  @Action
  async login(credentials: WpsApiLoginRQ) {
    const user = await backplaneClient
      .login(credentials)
      .pipe(switchMap(() => backplaneClient.me()))
      .toPromise();
    profileModule.setProfile(user);
    const redirect = router.currentRoute.query.redirect;
    if (redirect && _.isString(redirect) && redirect.length > 0) {
      await router.push({ name: constants.routes.BASE });
      // await router.push(redirect as string); // todo fix redirect to user real redirect value
    } else {
      await router.push({ name: constants.routes.BASE });
    }
  }

  @Action
  async logout() {
    try {
      await backplaneClient.logout().toPromise();
    } finally {
      // clear state in all modules which registered a root 'clearState' action
      await appModule.clearState();
      // await store.dispatch('clearState');
      // todo - fullPath includes query + no redirects to login
      await router.push({ name: constants.routes.LOGIN, query: { redirect: router.currentRoute.fullPath } });
    }
  }

  @Action
  async getProfile() {
    try {
      profileModule.setProfile(await backplaneClient.me().toPromise());
    } catch (e) {
      await router.push({ name: constants.routes.LOGIN, query: { redirect: router.currentRoute.fullPath } });
    }
  }

  @Action
  async getVersion() {
    try {
      const version = await inventoryClient.getVersion().toPromise();
      appModule.setVersion(version);
    } catch (e) {
      appModule.setVersion('v0.0.1');
    }
  }

  /**
   * Company
   **/
  @Action
  async getCompany() {
    try {
      companyModule.startLoading();
      // Checking backplane for ORG data
      // Must have organization created in backplane organizations table before calling.
      // Use backplane POST /organization to create
      if (profileModule?.Profile?.org_code) {
        const org = await backplaneClient.getOrganizationByCode(profileModule?.Profile?.org_code).toPromise();
        if (!org || (org && !org.organizations)) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error('no ORG found');
        }
        companyModule.configOrganization(org);
      }
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const company = await inventoryClient.getCompanyById(id).toPromise();
        companyModule.configCompany(company);
        // Creating backplane ORG if not already existing, but is within OPP Supplier
        if (!companyModule.Organization && companyModule.Company.orgId && profileModule?.Profile?.org_code) {
          const id = companyModule.Company.orgId;
          const name = profileModule.Profile.org_code;
          const request = {
            id: id,
            name: name,
            disabled: false,
          } as OrganizationRQ;
          await backplaneClient.createOrganization(request).toPromise();
        }
      } else {
        appModule.addMessageError(codes.COMPANY_ERROR_NO_COMPANY_ID);
      }
    } catch (e) {
      // if (e.status === 404) {
      //   appModule.addMessageError(codes.COMPANY_ERROR_404_LOAD_COMPANY);
      // }

      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_LOAD_COMPANY);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async createCompany() {
    try {
      companyModule.startLoading();
      const company = companyModule.Company;
      if (
        companyModule.Organization &&
        companyModule.Organization.ID &&
        companyModule.Organization.ID.length > 0 &&
        company
      ) {
        company.orgId = companyModule.Organization.ID;
      }
      await inventoryClient
        .createCompany(company)
        .pipe(
          map((c: Company) => {
            companyModule.configCompany(c);
            auditLog({}, c, AuditMethod.CREATE);
          }),
        )
        .toPromise();
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_CREATE_COMPANY);
      }
      if (e.status === 8000) {
        appModule.addMessageError(codes.COMPANY_ERROR_NO_COMPANY_ID);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async updateCompany() {
    try {
      companyModule.startLoading();
      const company = _.cloneDeep(companyModule.Company);
      const baseCompany = _.cloneDeep(companyModule.Company);
      const meta = _.cloneDeep(company.meta);

      company.meta = incrementMeta(meta);
      company.version = incrementVersion(meta);

      const message = `Updated "${company.name}"`;
      await inventoryClient
        .updateCompany(company)
        .pipe(
          map((c: Company) => {
            auditLog(baseCompany, c, AuditMethod.EDIT);
            companyModule.configCompany(c);
            appModule.addMessageSuccess([codes.COMPANY_UPDATED, message]);
          }),
        )
        .toPromise();
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_UPDATE_COMPANY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_UPDATE_COMPANY);
      }
      if (e.status === 8000) {
        appModule.addMessageError(codes.COMPANY_ERROR_NO_COMPANY_ID);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  /**
   * Contact
   **/
  @Action
  async getContacts() {
    try {
      companyModule.startLoading();
      // Checking backplane for ORG data
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const contacts = await inventoryClient.getContactsByOrg(id).toPromise();
        companyModule.setContacts(contacts);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_LOAD_CONTACT);
      }

      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_LOAD_CONTACT);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async getContactById(id: string) {
    try {
      companyModule.startLoading();
      // Checking backplane for ORG data
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const c = {} as Contact;
        c.id = id;
        const contact = await inventoryClient.getContactById(orgId, c).toPromise();
        companyModule.removeContact(contact);
        companyModule.appendContact(contact);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_LOAD_CONTACT);
      }

      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_LOAD_CONTACT);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async createContact(contact: Contact) {
    try {
      companyModule.startLoading();
      const baseContact = _.cloneDeep(contact);
      const meta = _.cloneDeep(contact.meta);
      const id = companyModule.Organization?.ID || '';

      if (!_.isEmpty(id)) {
        contact.meta = incrementMeta(meta);
        contact.version = incrementVersion(meta);

        await inventoryClient
          .createContact(id, contact)
          .pipe(
            map((c: Contact) => {
              auditLog(baseContact, c, AuditMethod.CREATE);
              companyModule.appendContact(c);
              appModule.addMessageSuccess(codes.COMPANY_CREATE_CONTACT);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_UPDATE_CONTACT);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_UPDATE_CONTACT);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async updateContact(contact: Contact) {
    try {
      companyModule.startLoading();
      const baseContact = _.cloneDeep(contact);
      const meta = _.cloneDeep(contact.meta);
      const id = companyModule.Organization?.ID || '';

      if (!_.isEmpty(id)) {
        contact.meta = incrementMeta(meta);
        contact.version = incrementVersion(meta);

        await inventoryClient
          .updateContact(id, contact)
          .pipe(
            map((c: Contact) => {
              auditLog(baseContact, c, AuditMethod.EDIT);
              companyModule.removeContact(c);
              companyModule.appendContact(c);
              appModule.addMessageSuccess(codes.COMPANY_CREATE_CONTACT);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_UPDATE_CONTACT);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_UPDATE_CONTACT);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async removeContact(contact: Contact) {
    try {
      companyModule.startLoading();
      const baseContact = _.cloneDeep(contact);
      const meta = _.cloneDeep(contact.meta);
      const id = companyModule.Organization?.ID || '';

      if (!_.isEmpty(id)) {
        contact.meta = incrementMeta(meta);
        contact.version = incrementVersion(meta);

        await inventoryClient
          .deleteContact(id, contact)
          .pipe(
            map((c: Contact) => {
              auditLog(baseContact, {}, AuditMethod.DELETE);
              companyModule.removeContact(c);
              companyModule.appendContact(c);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_DELETED_CONTACT);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_DELETED_CONTACT);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  /**
   * Offers
   **/

  @Action
  async updateOffer(offer: Offer) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .updateOffer(id, offer)
          .pipe(
            map((o: Offer) => {
              auditLog(o, offer, AuditMethod.CREATE);
              pricingModule.setOffer(o);
              offerModule.setOffer(o);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 400) {
        appModule.addMessageError(codes.OFFER_ERROR_400_CREATE);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.OFFER_ERROR_500);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getOffer(rb: { offerId: string; withCapacity?: boolean }) {
    try {
      pricingModule.startLoading();
      offerModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getOffer(id, rb.offerId)
          .pipe(
            map((o: Offer) => {
              pricingModule.setOffer(o);
              offerModule.setOffer(o);
            }),
          )
          .toPromise();
        if (rb.withCapacity) {
          await inventoryClient
            .getOfferCapacityList(id, pricingModule.Offer.id)
            .pipe(
              map((c: OfferCapacityList) => {
                const offer = {
                  ...pricingModule.Offer,
                  capacity: c.data.map((item: OfferCapacityInList) => {
                    return {
                      ...item,
                      open: false,
                    };
                  }),
                };
                pricingModule.setOffer(offer);
                offerModule.setOffer(offer);
              }),
            )
            .toPromise();
        }
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OFFER_ERROR_404_GET);
      }
    } finally {
      offerModule.finishLoading();
      pricingModule.finishLoading();
    }
  }

  @Action
  async getOffersByProductId(offer: Offer) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getOffersByProductId(id, offer)
          .pipe(
            map((offers: Array<Offer>) => {
              pricingModule.setOffers(offers);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OFFERS_ERROR_404_GET);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getOffers(withCapacity?: boolean) {
    try {
      pricingModule.startLoading();
      offerModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getOffers(id)
          .pipe(
            map((offers: Array<Offer>) => {
              pricingModule.setOffers(offers);
              offerModule.setOffers(offers);
              if (!withCapacity) {
                pricingModule.finishLoading();
                offerModule.finishLoading();
              }
            }),
          )
          .toPromise();
        if (withCapacity) {
          const promises = pricingModule.Offers.map((offer: Offer) =>
            inventoryClient.getOfferCapacityList(id, offer.id).toPromise(),
          );
          await Promise.all(promises)
            .then(async (values) => {
              const offersWithCapacity = pricingModule.Offers.map((offer: Offer) => {
                const capacity = values.find((item) => {
                  if (item.filter && Array.isArray(item.filter.offerId) && item.filter.offerId.length > 0) {
                    return item.filter.offerId[0] === offer.id;
                  } else if (item.filter && _.isString(item.filter.offerId) && item.filter.offerId.length > 0) {
                    return item.filter.offerId;
                  }
                  return false;
                });
                if (capacity && capacity.data.length) return { ...offer, capacity: capacity.data };
                return offer;
              }).filter((item) => item.capacity?.length);
              offersWithCapacity.forEach((o: Offer) => {
                o.inventoryName = '';
                o.inventoryNameList = [];
                if (o.capacity) {
                  o.inventoryNameList = o.capacity.map((c) => {
                    return { id: c.id, name: c.name } as OfferCapacityInventory;
                  });
                  o.inventoryName = o.inventoryNameList.map((c) => c.name).join(', ');
                }
              });
              pricingModule.setOffersWithCapacity(offersWithCapacity);
              pricingModule.finishLoading();
              offerModule.finishLoading();
            })
            .catch(() => {
              pricingModule.finishLoading();
              offerModule.finishLoading();
            });
        }
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OFFERS_ERROR_404_GET);
      }
      pricingModule.finishLoading();
      offerModule.finishLoading();
    }
  }

  @Action
  async deleteOffer(offer: Offer) {
    try {
      offerModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.deleteOffer(id, offer).toPromise();
        offerModule.removeOffer(offer.id);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OFFER_REMOVED_ERROR_500);
      }
    } finally {
      offerModule.finishLoading();
      appModule.addMessageSuccess(codes.OFFER_REMOVED);
    }
  }

  /**
   * Offer Capacity (Inventory)
   **/
  @Action
  async updateOfferCapacity(capacity: OfferCapacity) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .updateOfferCapacity(id, capacity)
          .pipe(
            map((offerCapacity: OfferCapacity) => {
              pricingModule.setOfferCapacity(offerCapacity);
              appModule.addMessageSuccess(codes.INVENTORY_CREATED);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 409) {
        if (
          e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.code &&
          e.response.data.error.message
        ) {
          appModule.addMessageError([99999, `[${e.response.data.error.code}]: ${e.response.data.error.message}`]);
        } else {
          appModule.addMessageError(codes.OFFER_CAPACITY_CONFLICT);
        }
      } else {
        console.log(e);
      }
      throw new Error('Unable to create inventory');
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getOfferCapacity(capacityId: string | Array<OfferCapacityInList>) {
    try {
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        if (!_.isArray(capacityId)) {
          await inventoryClient
            .getOfferCapacity(id, capacityId)
            .pipe(
              map((offerCapacity: OfferCapacity) => {
                pricingModule.setOfferCapacity(offerCapacity);
              }),
            )
            .toPromise();
        } else {
          const promises = capacityId.map((capacity: OfferCapacityInList) =>
            inventoryClient.getOfferCapacity(id, capacity.id).toPromise(),
          );
          Promise.all(promises)
            .then((capacities) => {
              pricingModule.setOfferCapacityList(capacities);
              if (capacities.length > 0) pricingModule.setOfferCapacity(capacities[0]);
            })
            .catch();
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  async getOfferCapacityList(offerId: string) {
    try {
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getOfferCapacityList(id, offerId)
          .pipe(
            map((offerCapacityList: OfferCapacityList) => {
              console.log(offerCapacityList);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  async getOffersCapacity() {
    try {
      offerModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getOffersCapacity(id)
          .pipe(
            map((offersCapacityList: OfferCapacityList) => {
              offerModule.setOffersCapacity(offersCapacityList);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      console.log(e);
    } finally {
      offerModule.finishLoading();
    }
  }

  @Action
  async getOfferCapacityTimeSlot(capacityId: string, timeslotKey: number) {
    try {
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getOfferCapacityTimeSlot(id, capacityId, timeslotKey)
          .pipe(
            map((offerCapacityTimeSlot: OfferCapacity) => {
              console.log(offerCapacityTimeSlot);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  async deleteOfferCapacity(offer: Offer) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const capacities = offer.inventoryNameList;
        if (Array.isArray(capacities) && capacities.length > 0) {
          const promises = [] as Array<Promise<void>>;
          capacities.forEach((c) => {
            pricingModule.removeOfferWithCapacity(offer.id);
            promises.push(inventoryClient.deleteOfferCapacity(id, c.id).toPromise());
          });
          await Promise.allSettled(promises).then();
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async deleteOfferCapacityById(offerCapacityId: string) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.deleteOfferCapacity(id, offerCapacityId).toPromise();
      }
    } catch (e) {
      console.log(e);
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async deleteOfferCapacityTimeSlot(capacityId: string, timeslotKey: number) {
    try {
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .deleteOfferCapacityTimeSlot(id, capacityId, timeslotKey)
          .pipe(
            map((offerCapacityList: OfferCapacityList) => {
              console.log(offerCapacityList);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Offer Pricing
   **/
  @Action
  async updateOfferPricing(offer: OfferPricing) {
    pricingModule.startLoading();
    try {
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .updateOfferPricing(id, offer)
          .pipe(
            map((o: OfferPricing) => {
              pricingModule.setOfferPricing(o);
              offerModule.setOfferPricing(o);
              appModule.addMessageSuccess(codes.OFFER_PRICING_UPDATED);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 400) {
        appModule.addMessageError(codes.OFFER_ERROR_400_CREATE);
      }
      if (e.status === 409) {
        appModule.addMessageError([codes.OFFER_ERROR_400_CREATE, e.response.data.error.message]);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }
  @Action
  async updateOfferPricingWithPublish(data: { new: OfferPricing; old: OfferPricing }) {
    let updated = false;
    const id = companyModule.Organization?.ID || '';
    pricingModule.startLoading();
    try {
      if (!_.isEmpty(id)) {
        await inventoryClient
          .updateOfferPricing(id, data.new)
          .pipe(
            map((o: OfferPricing) => {
              pricingModule.setOfferPricing(o);
              offerModule.setOfferPricing(o);
              updated = true;
            }),
          )
          .toPromise();
        if (updated) {
          await inventoryClient
            .offerValidation(id, 'false', [data.new.offerId])
            .pipe(
              map((response: any) => {
                if (response.details) {
                  pricingModule.setOfferPublished(false);
                  response.details.publishErrors.map((error: any) =>
                    appModule.addMessageError([codes.OFFER_PRICING_REMOVED, error.message]),
                  );
                } else {
                  pricingModule.setOfferPublished(true);
                }
              }),
            )
            .toPromise();
        }
        if (!pricingModule.OfferPublished) {
          const prices = {
            ...data.old,
            version: incrementVersion(pricingModule.OfferPricing.version),
          };
          await inventoryClient
            .updateOfferPricing(id, prices)
            .pipe(
              map((o: OfferPricing) => {
                pricingModule.setOfferPricing(o);
                offerModule.setOfferPricing(o);
              }),
            )
            .toPromise();
        }
      }
    } catch (e) {
      if (e.status === 400) {
        appModule.addMessageError(codes.OFFER_ERROR_400_CREATE);
      }
      if (e.status === 409) {
        appModule.addMessageError([codes.OFFER_ERROR_400_CREATE, e.response.data.error.message]);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getOfferPricingList(offerId: string) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getOfferPricingList(id, offerId)
          .pipe(
            map((data: OfferPricingList) => {
              pricingModule.setOfferPricings(data);
              offerModule.setOfferPricings(data);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 400) {
        appModule.addMessageError(codes.OFFER_ERROR_400_CREATE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getOfferPricing(offerPricingId: string) {
    try {
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getOfferPricing(id, offerPricingId)
          .pipe(
            map((data: OfferPricing) => {
              pricingModule.setOfferPricing(data);
              offerModule.setOfferPricing(data);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 400) {
        appModule.addMessageError(codes.OFFER_ERROR_400_CREATE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async deleteOfferPricing(offerPricingId: string) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .deleteOfferPricing(id, offerPricingId)
          .pipe(
            map(() => {
              appModule.addMessageSuccess(codes.OFFER_PRICING_REMOVED);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OFFERS_ERROR_404_GET);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  /**
   * Events
   **/
  @Action
  async getUnpublishedChanges(offerId: string) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getUnpublishedChanges(id, offerId)
          .pipe(
            map((data: any) => {
              if (data.offerUnpublishedChanges[0]?.events.length) {
                eventsModule.setOfferUnpublishedEvents(data.offerUnpublishedChanges[0]?.events);
              }
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 400) {
        appModule.addMessageError(codes.OFFER_ERROR_400_CREATE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }
  /**
   * Offer validation
   **/
  @Action
  async validateOffer(offers: Array<string>) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .validateOffer(id, offers)
          .pipe(
            map((response: any) => {
              clearInterval(channelModule.ValidationInterval);
              const validatedOffers = pricingModule.Offers.filter(
                (offer: Offer) => response && response.offers && response.offers.includes(offer.id),
              );
              pricingModule.setValidatedOffers(validatedOffers);
              channelModule.setValidationProgress(100);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      channelModule.setValidationError(true);
      clearInterval(channelModule.ValidationInterval);
      if (e.status === 400) {
        const { publishErrors } = e.response.data.details;
        const selectedOffers = pricingModule.Offers.filter((offer: Offer) => offers.includes(offer.id));
        const validatedOffers = selectedOffers.map((offer: Offer) => {
          const errors = publishErrors.filter(
            (error: any) => error && error.details && error.details['x-rc.OfferID'] === offer.id,
          );
          return {
            ...offer,
            error: !!errors.length,
            errorDetails: errors,
          };
        });
        pricingModule.setValidatedOffers(validatedOffers);
        channelModule.setValidationProgress(100);
        channelModule.setValidationErrorDetails(e.response.data.details.publishErrors);
      }
      if (e.status === 404) {
        appModule.addMessageError(codes.OFFERS_ERROR_404_GET);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async offerValidation(rq: { offers: string[]; dryRun: string }) {
    try {
      pricingModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .offerValidation(id, rq.dryRun, rq.offers)
          .pipe(
            map((response: any) => {
              if (response.details) {
                channelModule.setValidationError(true);
                const { publishErrors } = response.details;
                const selectedOffers = pricingModule.Offers.filter((offer: Offer) => rq.offers.includes(offer.id));
                const validatedOffers = selectedOffers.map((offer: Offer) => {
                  const errors = publishErrors.filter(
                    (error: any) => error && error.details && error.details['x-rc.OfferID'] === offer.id,
                  );
                  return {
                    ...offer,
                    error: !!errors.length,
                    errorDetails: errors,
                  };
                });
                pricingModule.setValidatedOffers(validatedOffers);
                channelModule.setValidationProgress(100);
              } else {
                channelModule.setValidationError(false);
                clearInterval(channelModule.ValidationInterval);
                const validatedOffers = pricingModule.Offers.filter(
                  (offer: Offer) => response && response.offers && response.offers.includes(offer.id),
                );
                pricingModule.setValidatedOffers(validatedOffers);
                channelModule.setValidationProgress(100);
              }
            }),
          )
          .toPromise();
      }
    } catch (err) {
      channelModule.setValidationError(true);
      clearInterval(channelModule.ValidationInterval);
      if (err.status === 400) {
        const { publishErrors } = err.response.data.details;
        const selectedOffers = pricingModule.Offers.filter((offer: Offer) => rq.offers.includes(offer.id));
        const validatedOffers = selectedOffers.map((offer: Offer) => {
          const errors = publishErrors.filter(
            (error: any) => error && error.details && error.details['x-rc.OfferID'] === offer.id,
          );
          return {
            ...offer,
            error: !!errors.length,
            errorDetails: errors,
          };
        });
        pricingModule.setValidatedOffers(validatedOffers);
        channelModule.setValidationProgress(100);
        channelModule.setValidationErrorDetails(err.response.data.details.publishErrors);
      }
      if (err.status === 404) {
        appModule.addMessageError(codes.OFFERS_ERROR_404_GET);
      }
      if (err.status === 400) {
        //todo: add logger
        // const { publishErrors } = err.response.data.details;
        // await wpsClient
        //   .logUIErr(
        //     {
        //       severity: 'error',
        //       message: JSON.stringify(publishErrors), // err.toString ? err.toString() : 'UI error';
        //     },
        //     err,
        //   )
        //   .toPromise();
      }
    } finally {
      pricingModule.finishLoading();
    }
  }
  /**
   * Schedules
   **/
  @Action
  async updateSchedule(schedule: Schedule) {
    try {
      scheduleModule.startLoading();
      productModule.startProductCreateSaving();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .updateSchedule(id, schedule)
          .pipe(
            map((schdl: Schedule) => {
              scheduleModule.attachSchedule(schdl);
              appModule.addMessageSuccess(codes.SCHEDULE_CREATED);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        //TODO: error messages
        appModule.addMessageError(codes.SCHEDULE_CREATED_ERROR);
      } else if (e.status === 409) {
        appModule.addMessageError(codes.SCHEDULE_CREATED_409_ERROR);
      }
    } finally {
      productModule.finishProductCreateSaving();
      scheduleModule.finishLoading();
    }
  }
  @Action
  async addScheduleToProduct(scheduleId: string) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.addScheduleToProduct(id, scheduleId, productModule.Product.id).toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        //TODO: error messages
        appModule.addMessageError(codes.PRODUCT_ERROR_404_ADD_SCHEDULE);
      }
    } finally {
      productModule.finishProductCreateSaving();
      scheduleModule.finishLoading();
    }
  }
  @Action
  async addScheduleToOption(scheduleId: string) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.addScheduleToOption(id, scheduleId, optionModule.Option.id).toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        //TODO: error messages
        appModule.addMessageError(codes.OPTION_ERROR_404_ADD_SCHEDULE);
      } else if (e.status === 409) {
        appModule.addMessageError(codes.OPTION_ERROR_409_ADD_SCHEDULE);
      }
      console.log(e);
    } finally {
      optionModule.finishLoading();
      scheduleModule.finishLoading();
    }
  }
  @Action
  async productSchedules(productId: string) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .productSchedules(id, productId)
          .pipe(
            map((schedules: Array<GRSchedule>) => {
              productModule.setProductSchedules(schedules);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRODUCT_SCHEDULE_ERROR_404_CREATE_SCHEDULE);
      }
    } finally {
      productModule.finishProductCreateSaving();
      scheduleModule.finishLoading();
    }
  }
  @Action
  async optionSchedules(optionId: string) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .optionSchedules(id, optionId)
          .pipe(
            map((schedules: Array<GRSchedule>) => {
              optionModule.updateOptionSchedules(schedules);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      //TODO: error messages
    } finally {
      scheduleModule.finishLoading();
    }
  }
  @Action
  async getSchedule(scheduleId: string) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getSchedule(id, scheduleId)
          .pipe(
            map((schedule: Schedule) => {
              scheduleModule.setSchedule(schedule);
              productModule.setProductSchedule(schedule);
              optionModule.updateOptionSchedule(schedule);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        //TODO: error messages
        appModule.addMessageError(codes.SCHEDULE_ERROR);
      }
    } finally {
      productModule.finishProductCreateSaving();
      scheduleModule.finishLoading();
    }
  }

  @Action
  async getSchedules() {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getScheduleList(id)
          .pipe(
            map((schedules: { data: Array<GRSchedule> }) => {
              scheduleModule.setSchedules(schedules.data);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 404) {
        //TODO: error messages
        appModule.addMessageError(codes.SCHEDULE_ERROR);
      }
    } finally {
      scheduleModule.finishLoading();
    }
  }

  @Action
  async updateScheduleDateRange(data: { dateRange: EffectiveDateRange; schedule: Schedule }) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .updateScheduleDateRange(id, data.schedule, data.dateRange)
          .pipe(
            map((sched: Schedule) => {
              optionModule.updateOptionSchedule(sched);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      //TODO handle error
    } finally {
      scheduleModule.finishLoading();
    }
  }
  @Action
  async updateScheduleTimeslot(data: { slot: TimeSlot; schedule: Schedule }) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.updateScheduleTimeslot(id, data.schedule, data.slot).toPromise();
        appModule.addMessageSuccess(codes.SCHEDULE_UPDATED);
      }
    } catch (e) {
      //TODO handle error
    } finally {
      scheduleModule.finishLoading();
    }
  }
  @Action
  async deleteScheduleTimeslot(data: { slot: TimeSlot; schedule: Schedule }) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.deleteScheduleTimeslot(id, data.schedule, data.slot).toPromise();
        appModule.addMessageSuccess(codes.SCHEDULE_UPDATED);
      }
    } catch (e) {
      //TODO handle error
    } finally {
      scheduleModule.finishLoading();
    }
  }
  @Action
  async deleteProductSchedule(data: { product: Product; schedule: Schedule }) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.deleteProductSchedule(id, data.schedule, data.product).toPromise();
        appModule.addMessageSuccess(codes.SCHEDULE_DELETED);
      }
    } catch (e) {
      //TODO handle error
    } finally {
      scheduleModule.finishLoading();
    }
  }
  @Action
  async deleteOptionSchedule(data: { option: Option; schedule: Schedule }) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.deleteOptionSchedule(id, data.schedule, data.option).toPromise();
        appModule.addMessageSuccess(codes.SCHEDULE_DELETED);
      }
    } catch (e) {
      //TODO handle error
    } finally {
      scheduleModule.finishLoading();
    }
  }
  @Action
  async deleteSchedule(schedule: Schedule) {
    try {
      scheduleModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient.deleteSchedule(id, schedule).toPromise();
      }
    } catch (e) {
      //TODO handle error
    } finally {
      scheduleModule.finishLoading();
    }
  }

  /**
   * Rate Cards
   **/

  @Action
  async storeRateCardChannel(rateCard: { rateCardId: string; channelId: string; payload: any }) {
    try {
      channelModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .storeRateCardChannel(id, rateCard.rateCardId, rateCard.channelId, rateCard.payload)
          .toPromise()
          .then(() => appModule.addMessageSuccess(codes.RATE_CARD_CHANNEL_CONNECTED))
          .then(() => this.getRateCardChannel(rateCard.rateCardId));
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    } finally {
      channelModule.finishLoading();
    }
  }

  @Action
  async getRateCardChannel(rateCardId: string) {
    try {
      channelModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getRateCardChannel(id, rateCardId)
          .pipe(
            map((channels: any) => {
              channelModule.setRateCardChannels(channels);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    } finally {
      channelModule.finishLoading();
    }
  }

  @Action
  async getRateCardsChannel(rateCards: Array<RateCard>) {
    try {
      channelModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const promises = rateCards.map((rateCard: RateCard) =>
          inventoryClient.getRateCardChannel(id, rateCard.id).toPromise(),
        );
        await Promise.all(promises)
          .then((responses) => {
            const rateCardsChannels = rateCards.map((rateCard, i) => {
              return {
                ...rateCard,
                channels: responses[i],
              };
            });
            channelModule.setRateCards(rateCardsChannels);
          })
          .catch();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    } finally {
      channelModule.finishLoading();
    }
  }

  @Action
  async deleteRateCardChannel(rateCard: { rateCardId: string; channelId: string }) {
    try {
      channelModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .deleteRateCardChannel(id, rateCard.rateCardId, rateCard.channelId)
          .toPromise()
          .then(() => appModule.addMessageSuccess(codes.RATE_CARD_CHANNEL_DELETED))
          .then(() => this.getRateCardChannel(rateCard.rateCardId));
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    } finally {
      channelModule.finishLoading();
    }
  }

  @Action
  async publishRateCard(rateCardId: string) {
    try {
      channelModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .publishRateCard(id, rateCardId)
          .pipe(
            map((response: any) => {
              console.log(response);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    } finally {
      channelModule.finishLoading();
    }
  }

  @Action
  async updateRateCard(rateCard: RateCard) {
    try {
      channelModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .updateRateCard(id, rateCard)
          .pipe(
            map((rateCard: RateCard) => {
              channelModule.setRateCard(rateCard);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    } finally {
      channelModule.finishLoading();
    }
  }

  @Action
  async getRateCards() {
    try {
      channelModule.setLoading(true);
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getRateCards(id)
          .pipe(
            map((rateCards: Array<RateCard>) => {
              channelModule.setRateCards(rateCards);
              channelModule.setLoading(false);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    }
  }

  @Action
  async getRateCardsWithOfferDetails() {
    try {
      channelModule.setLoading(true);
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const offers = await inventoryClient.getOffers(id).toPromise();
        await inventoryClient
          .getRateCards(id)
          .pipe(
            map((rateCards: Array<RateCard>) => {
              rateCards.map((item: RateCard) => {
                item.offerNames = [];
                item.offerLocations = [];
                item.offers.map((offerId: string) => {
                  const relatedOffer = offers.find((offer: Offer) => offer.id === offerId);
                  if (relatedOffer) {
                    let primaryAddress = '-';
                    const p = productModule.Products.find((p: any) => p.id === relatedOffer.productId);
                    if (p) {
                      const address = companyModule.Addresses.find((a: Address) => a.id === p.addressId);
                      if (address) {
                        primaryAddress = `${address.name}`;
                      }
                    }
                    item.offerNames?.push(`${relatedOffer.name}`);
                    item.offerLocations?.push(`${primaryAddress}`);
                  }
                });
              });
              channelModule.setRateCards(rateCards);
              channelModule.setLoading(false);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    }
  }

  @Action
  async getRateCard(id: string) {
    try {
      channelModule.setLoading(true);
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        await inventoryClient
          .getRateCard(orgId, id)
          .pipe(
            map((rateCard: RateCard) => {
              channelModule.setRateCard(rateCard);
              channelModule.setLoading(false);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
    }
  }

  /**
   *  channel bindings
   **/
  @Action
  async getCompanyRates() {
    try {
      channelModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .getCompanyRates(orgId)
          .pipe(
            map((rates: Array<CompanyRate>) => {
              channelModule.setChannelAccess(true);
              channelModule.setCompanyRates(
                rates.map((item: CompanyRate, index: number) => {
                  return {
                    ...item,
                    open: item.open ? item.open : false,
                    id:
                      item.offer.id && item.offer.id.length
                        ? `${item.offer.id}-${index}`
                        : `${item.product.id}-${index}`,
                    name: item.offer.name && item.offer.name.length ? item.offer.name : item.product.name,
                  };
                }),
              );
              channelModule.finishLoading();
            }),
          )
          .toPromise();
      }
    } catch (e) {
      channelModule.finishLoading();
      if (e.status == 403) {
        channelModule.setChannelAccess(false);
        appModule.addMessageSuccess([codes.UNAUTHORIZED_CHANNEL_API, constants.messages.UNAUTHORIZED_CHANNEL_API]);
      } else {
        appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
      }
    }
  }
  @Action
  async getCompanyChannels() {
    try {
      channelModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .getCompanyChannels(orgId)
          .pipe(
            map((channels: any) => {
              channelModule.setChannelAccess(true);
              channelModule.setAvailableChannels(channels);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status === 403) {
        channelModule.setChannelAccess(false);
        appModule.addMessageSuccess([codes.UNAUTHORIZED_CHANNEL_API, constants.messages.UNAUTHORIZED_CHANNEL_API]);
      }
    } finally {
      channelModule.finishLoading();
    }
  }
  @Action
  async getCompanyChannelsBindings() {
    try {
      channelModule.setLoading(true);
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .getCompanyChannelsBindings(orgId)
          .pipe(
            map((channelBindings: Array<ChannelBinding>) => {
              channelModule.setChannelAccess(true);
              channelModule.setConnectedChannels(channelBindings || []);
              channelModule.setLoading(false);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      if (e.status == 403) {
        channelModule.setChannelAccess(false);
        appModule.addMessageSuccess([codes.UNAUTHORIZED_CHANNEL_API, constants.messages.UNAUTHORIZED_CHANNEL_API]);
      } else {
        appModule.addMessageError([codes.RATE_CARD_ERROR, e.response.data.message]);
      }
    }
  }
  @Action
  async createChannelBinding(data: { supplierId: string; productId: string; channelId: string; payload: any }) {
    try {
      channelModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .postChannelRates(orgId, data.supplierId, data.productId, data.channelId, data.payload)
          .pipe(
            map(() => {
              channelModule.setChannelConnected(true);
              appModule.addMessageSuccess(codes.RATE_CARD_CHANNEL_BINDING_CREATED);
              channelModule.finishLoading();
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_CHANNEL_BINDING_ERROR, e.response.data.message]);
      channelModule.finishLoading();
      channelModule.setChannelConnected(false);
    }
  }

  @Action
  async updateChannelBindings(data: { supplierId: string; productId: string; channelId: string; payload: any }) {
    try {
      channelModule.setLoading(true);
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .updateChannelRates(orgId, data.supplierId, data.productId, data.channelId, data.payload)
          .pipe(
            map(() => {
              channelModule.setChannelConnected(true);
              appModule.addMessageSuccess(codes.RATE_CARD_CHANNEL_BINDING_UPDATED);
              channelModule.setLoading(false);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_CHANNEL_BINDING_ERROR, e.response.data.message]);
      channelModule.setLoading(false);
      channelModule.setChannelConnected(false);
    }
  }

  @Action
  async deleteChannelBinding(data: { supplierId: string; productId: string; channelId: string }) {
    try {
      channelModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .deleteChannelRates(orgId, data.supplierId, data.productId, data.channelId)
          .pipe(
            map(() => {
              appModule.addMessageSuccess(codes.RATE_CARD_CHANNEL_BINDING_DELETED);
              channelModule.finishLoading();
            }),
          )
          .toPromise();
      }
    } catch (e) {
      appModule.addMessageError([codes.RATE_CARD_CHANNEL_BINDING_ERROR, e.response.data.message]);
      channelModule.finishLoading();
      channelModule.setChannelConnected(false);
    }
  }

  /**
   *  Rate Card SPR
   **/
  @Action
  async storeRateCardSpr(rq: { rateCardId: string; sprId: string; payload: any }) {
    try {
      channelModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .storeRateCardSpr(orgId, rq.rateCardId, rq.sprId, rq.payload)
          .pipe(
            map((spr: any) => {
              channelModule.addCreateRateCard(spr);
              channelModule.setRateCardSpr(spr);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      console.log(e);
    } finally {
      channelModule.finishLoading();
    }
  }

  @Action
  async getRateCardSprs(rateCardId: string) {
    try {
      channelModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .getRateCardSprs(orgId, rateCardId)
          .pipe(
            map((spr: any) => {
              channelModule.setRateCardSpr(spr);
            }),
          )
          .toPromise();
      }
    } catch (e) {
      console.log(e);
    } finally {
      channelModule.finishLoading();
    }
  }

  /**
   *  Price Schedules
   **/
  @Action
  async getPriceSchedules() {
    try {
      pricingModule.startLoading();
      const schedules = await inventoryClient.getPriceSchedules().toPromise();
      pricingModule.setPriceSchedules(schedules);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_SCHEDULE_ERROR_500_LOAD_PRICES);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getPriceSchedulesByProduct(id: string) {
    try {
      pricingModule.startLoading();
      const schedules = await inventoryClient.getPriceSchedules().toPromise();
      const filtered = schedules.filter((s) => s.productId === id);
      pricingModule.setPriceSchedulesByProduct(filtered);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_SCHEDULE_ERROR_500_LOAD_PRICES);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async createPriceSchedules(pricing: PriceSchedule) {
    // pricing.priceSchedules[0].Sunday;
    // pricing.priceSchedules[0] = {Sunday: 's': []
    try {
      pricingModule.startLoading();
      await inventoryClient
        .createPriceSchedule(pricing)
        .toPromise()
        .then((price) => {
          auditLog({}, pricing, AuditMethod.CREATE);
          return price;
        });
      // todo save pricing to list
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_SCHEDULE_ERROR_500_CREATE_PRICE_SCHEDULE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async updatePriceSchedules(pricing: PriceSchedule) {
    try {
      pricingModule.startLoading();
      const schedule = await inventoryClient
        .updatePriceSchedule(pricing)
        .toPromise()
        .then((price) => {
          auditLog({}, pricing, AuditMethod.EDIT);
          return price;
        });
      pricingModule.setSelectedPriceSchedule(schedule);
      appModule.addMessageSuccess(codes.PRICE_SCHEDULE_UPDATED);
      // todo update item in list
      // todo create action queue item
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRICE_SCHEDULE_ERROR_404_UPDATE_PRICE_SCHEDULE);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_SCHEDULE_ERROR_500_UPDATE_PRICE_SCHEDULE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async deletePriceSchedules(pricing: PriceSchedule) {
    try {
      pricingModule.startLoading();
      if (pricing.id) {
        await inventoryClient
          .deletePriceSchedule(pricing.id)
          .toPromise()
          .then(() => {
            auditLog(pricing, {}, AuditMethod.DELETE);
          });
        appModule.addMessageSuccess(codes.PRICE_SCHEDULE_DELETED);
      }
      // todo remove pricing from list
      // todo create action queue undo item
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRICE_SCHEDULE_ERROR_404_REMOVE_PRICE_SCHEDULE);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_SCHEDULE_ERROR_500_REMOVE_PRICE_SCHEDULE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getPricings() {
    try {
      pricingModule.startLoading();
      const pricing = {} as OppPricing;
      pricing.orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(pricing.orgId)) {
        const p = await inventoryClient.getPricing(pricing).toPromise();
        pricingModule.setOpPricing(p);
        pricingModule.mapOpPricingDisplay();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_ERROR_500_LOAD_PRICES);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getPricingByPriceId() {
    try {
      pricingModule.startLoading();
      const pricing = {} as OppPricing;
      pricing.orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(pricing.orgId)) {
        pricing.id = '';
        const p = await inventoryClient.getPricingById(pricing).toPromise();
        pricingModule.setOpPricing(p);
        pricingModule.mapOpPricingDisplay();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_ERROR_500_LOAD_PRICES);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getPricingBySupplierId() {
    try {
      pricingModule.startLoading();
      const pricing = {} as OppPricing;
      pricing.orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(pricing.orgId)) {
        pricing.supplierId = '';
        const p = await inventoryClient.getPricingBySupplierId(pricing).toPromise();
        pricingModule.setOpPricing(p);
        pricingModule.mapOpPricingDisplay();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_ERROR_500_LOAD_PRICES);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getPricingBySupplierAndProductId(productId?: string) {
    try {
      pricingModule.startLoading();
      const pricing = {} as OppPricing;
      pricing.orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(pricing.orgId)) {
        pricing.supplierId = '';
        pricing.productId = productId || 'missing';
        const p = await inventoryClient.getPricingBySupplierAndProductId(pricing).toPromise();
        pricingModule.setOpPricing(p);
        pricingModule.mapOpPricingDisplay();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_ERROR_500_LOAD_PRICES);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async getPricingBySupplierAndProductAndOptionId(productId?: string, optionId?: string) {
    try {
      pricingModule.startLoading();
      const pricing = {} as OppPricing;
      pricing.orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(pricing.orgId)) {
        pricing.supplierId = '';
        pricing.productId = productId || 'missing';
        pricing.optionId = optionId || 'missing';
        const p = await inventoryClient.getPricingBySupplierAndProductAndOptionId(pricing).toPromise();
        pricingModule.setOpPricing(p);
        pricingModule.mapOpPricingDisplay();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_ERROR_500_LOAD_PRICES);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async createPricing(pricing: OppPricing) {
    try {
      pricingModule.startLoading();
      await inventoryClient
        .createPricing(pricing)
        .toPromise()
        .then(() => {
          auditLog({}, pricing, AuditMethod.CREATE);
        });
      appModule.addMessageSuccess(codes.PRICE_CREATED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_ERROR_500_CREATE_PRICE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async updatePricing(pricing: OppPricing) {
    try {
      pricingModule.startLoading();
      await inventoryClient
        .updatePricing(pricing)
        .toPromise()
        .then(() => {
          auditLog({}, pricing, AuditMethod.EDIT);
        });
      appModule.addMessageSuccess(codes.PRICE_UPDATED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_ERROR_500_UPDATE_PRICE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  @Action
  async deletePricing(pricing: OppPricing) {
    try {
      pricingModule.startLoading();
      await inventoryClient
        .deletePricing(pricing)
        .toPromise()
        .then(() => {
          auditLog(pricing, {}, AuditMethod.DELETE);
        });
      appModule.addMessageSuccess(codes.PRICE_CREATED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRICE_ERROR_500_REMOVE_PRICE);
      }
    } finally {
      pricingModule.finishLoading();
    }
  }

  /**
   *  Taxes and Fees
   **/
  @Action
  async getTaxesAndFees() {
    try {
      taxesAndFeesModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const taxesAndFees = await inventoryClient.getTaxesAndFees(id).toPromise();
        taxesAndFeesModule.setTaxesAndFees(taxesAndFees);
        taxesAndFeesModule.finishLoading();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TAXES_AND_FEES_ERROR_500_LOAD_TAXES);
      }
    } finally {
      taxesAndFeesModule.finishLoading();
    }
  }

  @Action
  async selectTaxesAndFees(fee: Fee) {
    try {
      taxesAndFeesModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const taxesAndFees = await inventoryClient.getTaxesAndFees(id).toPromise();
        taxesAndFeesModule.setTaxesAndFees(taxesAndFees);
        taxesAndFeesModule.selectTaxAndFee(fee.id);
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TAXES_AND_FEES_ERROR_500_LOAD_TAXES);
      }
    } finally {
      taxesAndFeesModule.finishLoading();
    }
  }

  @Action
  async createTaxesAndFees(rq: Fee) {
    try {
      taxesAndFeesModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const taxAndFee = await inventoryClient
          .createTaxesAndFees(id, rq)
          .toPromise()
          .then((taxes) => {
            auditLog({}, rq, AuditMethod.CREATE);
            return taxes;
          });
        taxesAndFeesModule.setSelectedTaxesAndFees(taxAndFee);
        appModule.addMessageSuccess([codes.TAXES_AND_FEES_CREATED, '']);
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TAXES_AND_FEES_ERROR_500_CREATE_TAXES);
      }
    } finally {
      taxesAndFeesModule.finishLoading();
    }
  }

  @Action
  async updateTaxesAndFees(rq: Fee) {
    try {
      const message = `Updated "${rq.name || 'taxes'}"`;
      taxesAndFeesModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const meta = _.cloneDeep(rq.meta) || ({} as Meta);
        rq.meta = incrementMeta(meta);
        rq.version = incrementVersion(meta);
        const taxAndFee = await inventoryClient
          .updateTaxesAndFees(id, rq)
          .toPromise()
          .then((taxes) => {
            auditLog({}, rq, AuditMethod.EDIT);
            return taxes;
          });
        taxesAndFeesModule.setSelectedTaxesAndFees(taxAndFee);
        appModule.addMessageSuccess([codes.TAXES_AND_FEES_UPDATED, message]);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.TAXES_AND_FEES_ERROR_404_UPDATE_TAXES);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.TAXES_AND_FEES_ERROR_500_UPDATE_TAXES);
      }
    } finally {
      taxesAndFeesModule.finishLoading();
    }
  }

  @Action
  async deleteTaxesAndFees(rq: Fee) {
    try {
      const message = `Removed taxes and fees`;
      taxesAndFeesModule.startLoading();
      const id = companyModule.Organization?.ID || '';
      if (!_.isEmpty(id)) {
        const taxId = rq.id;
        await inventoryClient
          .deleteTaxesAndFees(id, rq)
          .toPromise()
          .then(() => {
            auditLog(rq, {}, AuditMethod.DELETE);
          });
        taxesAndFeesModule.removeTaxAndFee(taxId);
        appModule.addMessageSuccess([codes.TAXES_AND_FEES_DELETED, message]);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.TAXES_AND_FEES_ERROR_404_DELETE_TAXES);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.TAXES_AND_FEES_ERROR_500_DELETE_TAXES);
      }
    } finally {
      taxesAndFeesModule.finishLoading();
    }
  }

  /**
   *  User
   **/
  @Action
  async getUsers(userStatus?: number) {
    try {
      userModule.startLoading();
      const query: QueryListUsers = {};
      switch (userStatus) {
        case 1:
          query.active = true;
          break;
        case 2:
          query.inactive = true;
          break;
        default:
          break;
      }
      const res = await backplaneClient.listUsers(query).toPromise();
      userModule.setUsers(res.accounts);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_LOAD_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async createUser(user: UserProfile) {
    try {
      const message = `Added "${user.email || 'user'}"`;
      userModule.startLoading();
      const res = await backplaneClient.createUser({ account: user }).toPromise();
      userModule.addUser(res.account);
      if (res.account?.id && res.account.id.length > 0) {
        const id = res.account.id;
        const actionId = faker.datatype.uuid();
        appModule.addRdmAction({
          id: actionId,
          detail: {
            code: codes.USER_CREATED,
            button: ActionButton.VIEW,
            body: {
              id: id,
            },
          },
        });
        const operatorUser = _.cloneDeep(userModule.OperatorUser);
        if (operatorUser) {
          operatorUser.id = id;
          setTimeout(async () => {
            await this.updateOperatorUser(operatorUser);
          }, 5000);
        }
      }
      appModule.addMessageSuccess([codes.USER_CREATED, message]);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_CREATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async updateUser(user: UserProfile) {
    try {
      const message = `Updated "${user.email || 'user'}"`;
      userModule.startLoading();
      await backplaneClient.updateUser(userModule.Users[userModule.UserToEdit.i].id, { account: user }).toPromise();
      const userGroups = user.groups;
      user.groups = userModule.Users[userModule.UserToEdit.i].groups;
      userModule.editUser(user);
      user.groups = userGroups;

      await backplaneClient
        .updateUserRole(userModule.Users[userModule.UserToEdit.i].id, {
          groups: user?.groups?.map((v) => v.id) as string[],
        })
        .toPromise();
      userModule.editUser(user);
      appModule.addMessageSuccess([codes.USER_UPDATED, message]);
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.USER_ERROR_404_UPDATE_USER);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async deleteUser() {
    try {
      const message = `Removed user`;
      userModule.startLoading();
      await backplaneClient.deleteUser(userModule.Users[userModule.UserToEdit.i].id).toPromise();
      userModule.dropUser();
      appModule.addMessageSuccess([codes.USER_DELETED, message]);
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.USER_ERROR_404_DELETE_USER);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_DELETE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async deactivateUser() {
    try {
      userModule.startLoading();
      const id = userModule.Users[userModule.UserToEdit.i].id;
      const actionId = faker.datatype.uuid();
      await backplaneClient.deactivateUser(id).toPromise();
      userModule.updateUserStatus(2);
      appModule.addRdmAction({
        id: actionId,
        detail: {
          code: codes.USER_DEACTIVATED,
          button: ActionButton.UNDO,
          body: {
            id: id,
          },
        },
      });
      appModule.addMessageSuccess(codes.USER_DEACTIVATED);
    } catch (e) {
      userModule.dropUser();
      if (e.status === 404) {
        appModule.addMessageError(codes.USER_ERROR_404_DELETE_USER);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_DEACTIVATED_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async activateUser() {
    try {
      userModule.startLoading();
      const id = userModule.Users[userModule.UserToEdit.i].id;
      const actionId = faker.datatype.uuid();
      await backplaneClient.activateUser(id).toPromise();
      userModule.updateUserStatus(1);
      appModule.addRdmAction({
        id: actionId,
        detail: {
          code: codes.USER_ACTIVATED,
          button: ActionButton.VIEW,
          body: {
            id: id,
          },
        },
      });
      appModule.addMessageSuccess(codes.USER_ACTIVATED);
    } catch (e) {
      userModule.dropUser();
      if (e.status === 404) {
        appModule.addMessageError(codes.USER_ERROR_404_UPDATE_USER);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_ACTIVATED_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async deleteBulkUser() {
    try {
      userModule.startLoading();
      await backplaneClient.deleteUser(userModule.Users[userModule.UserIndexToDelete].id).toPromise();
      userModule.dropUser();
      appModule.addMessageSuccess(codes.USER_DELETED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_DELETE_USER);
      }
    } finally {
      userModule.dropBulkUser();
      userModule.finishLoading();
    }
  }

  @Action
  async updateSelectedUserGroups(selectedGroups: any[]) {
    try {
      userModule.startLoading();
      for (const user of userModule.SelectedUsers) {
        await userModule.chooseUserToUpdate(user.i);
        const nonKnownRoles = userModule.ExistingGroups || [];
        if (userModule.BulkAction === constants.ROLE_ADD) {
          user.groups = nonKnownRoles.concat(
            selectedGroups.filter((g) => nonKnownRoles.findIndex((n) => n.id === g.id) === -1),
          );
        } else if (userModule.BulkAction === constants.ROLE_REMOVE) {
          user.groups = nonKnownRoles.filter((n) => selectedGroups.findIndex((g) => g.id === n.id) === -1);
        }
        await this.updateUser(user);
      }
      userModule.setSelectedUsers([]);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async deleteSelectedUsers() {
    try {
      userModule.startLoading();
      for (const user of userModule.SelectedUsers) {
        await userModule.chooseUserIndexToRemove(user.id);
        await this.deleteBulkUser();
      }
      userModule.setSelectedUsers([]);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_DELETE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async deactivateSelectedUsers() {
    try {
      userModule.startLoading();
      for (const user of userModule.SelectedUsers) {
        await userModule.chooseUserIndexToEdit(user.id);
        await this.deactivateUser();
      }
      userModule.setSelectedUsers([]);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async activateSelectedUsers() {
    try {
      userModule.startLoading();
      for (const user of userModule.SelectedUsers) {
        await userModule.chooseUserIndexToEdit(user.id);
        await this.activateUser();
      }
      userModule.setSelectedUsers([]);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async toggleUserStatus() {
    try {
      userModule.startLoading();
      const users: any[] = [];
      Object.assign(users, userModule.Users);
      userModule.setUsers([]);

      if (users[userModule.UserToEdit] && users[userModule.UserToEdit.i].deactivated_at) {
        delete users[userModule.UserToEdit.i].deactivated_at;
      } else if (users[userModule.UserToEdit.i] && !users[userModule.UserToEdit.i].deactivated_at) {
        users[userModule.UserToEdit.i].deactivated_at = Date.now();
      }

      userModule.setUsers(users);
      userModule.chooseUserToEdit(-1);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  /**
   *  Traveler Types
   **/
  @Action
  async getTravelerTypes() {
    try {
      travelerTypeModule.startLoading();
      const orgId = companyModule.Organization?.ID;

      if (!orgId) {
        return;
      }
      if (!_.isEmpty(orgId)) {
        const travelerTypes = await inventoryClient.getTravelerTypes(orgId).toPromise();
        // travelerTypeModule.setTravelerTypes(travelerTypes);
        travelerTypeModule.configTravelerPrices(travelerTypes);
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      travelerTypeModule.finishLoading();
    }
  }

  @Action
  async getTravelerTypeById(id: string) {
    try {
      travelerTypeModule.startLoading();
      travelerTypeModule.selectTravelerTypeById(id);
      const traveler = travelerTypeModule.SelectedTravelerType;
      const t = await inventoryClient.getTravelerTypesById(traveler).toPromise();
      travelerTypeModule.setSelectedTravelerType(t);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      travelerTypeModule.finishLoading();
    }
  }

  @Action
  async getProductUnitTravelerTypes(productId: string) {
    try {
      productModule.startLoading();
      const orgId = companyModule.Company.orgId;
      if (!_.isEmpty(orgId)) {
        const units = await inventoryClient.getTravelerTypesByProductId(orgId, productId).toPromise();
        productModule.setUnits(units);
        productModule.setSelectedUnitIds(units.map((u) => u.id));
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async getOptionUnitTravelerTypes(optionId: string) {
    try {
      optionModule.startLoading();
      const orgId = companyModule.Company.orgId;
      if (!_.isEmpty(orgId)) {
        const units = await inventoryClient.getTravelerTypesByOptionId(orgId, optionId).toPromise();
        optionModule.setUnits(units);
        optionModule.setSelectedUnitIds(units.map((u) => u.id));
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async createProductUnitRelationship(productUnit: ProductUnit) {
    try {
      productModule.startLoading();
      const orgId = companyModule.Company.orgId;
      if (!_.isEmpty(orgId)) {
        await inventoryClient.createProductUnitRelationship(orgId, productUnit).toPromise();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async deleteProductUnitRelationship(productUnit: ProductUnit) {
    try {
      productModule.startLoading();
      const orgId = companyModule.Company.orgId;
      if (!_.isEmpty(orgId)) {
        await inventoryClient.deleteProductUnitRelationship(orgId, productUnit).toPromise();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async updateProductUnitRelationship(productUnits: Array<string>) {
    try {
      productModule.startLoading();
      const orgId = companyModule.Company.orgId;
      if (!_.isEmpty(orgId)) {
        const productId = productModule.Product.id;
        const oldRelationships = productModule.TravelerTypes;
        if (Array.isArray(oldRelationships) && oldRelationships.length > 0) {
          const promises = [] as Array<Promise<void>>;
          oldRelationships.forEach((u) => {
            productModule.removeUnit(u.id);
            promises.push(
              inventoryClient.deleteProductUnitRelationship(orgId, { parentId: productId, childId: u.id }).toPromise(),
            );
          });
          await Promise.allSettled(promises).then();
        }
        const createPromises = [] as Array<Promise<ProductUnit>>;
        productUnits.forEach((u) => {
          productModule.appendUnitFromTravelerList(u);
          createPromises.push(
            inventoryClient.createProductUnitRelationship(orgId, { parentId: productId, childId: u }).toPromise(),
          );
        });
        await Promise.allSettled(createPromises).then();
        productModule.updateSelectedUnit();
        // await this.getProductUnitTravelerTypes(productId).then();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async createOptionUnitRelationship(productUnit: ProductUnit) {
    try {
      optionModule.startLoading();
      const orgId = companyModule.Company.orgId;
      if (!_.isEmpty(orgId)) {
        await inventoryClient.createOptionUnitRelationship(orgId, productUnit).toPromise();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async deleteOptionUnitRelationship(productUnit: ProductUnit) {
    try {
      optionModule.startLoading();
      const orgId = companyModule.Company.orgId;
      if (!_.isEmpty(orgId)) {
        await inventoryClient.deleteOptionUnitRelationship(orgId, productUnit).toPromise();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async updateOptionUnitRelationship(optionUnits: Array<string>) {
    try {
      optionModule.startLoading();
      const orgId = companyModule.Company.orgId;
      if (!_.isEmpty(orgId)) {
        const optionId = optionModule.Option.id;
        const oldRelationships = optionModule.TravelerTypes;
        if (Array.isArray(oldRelationships) && oldRelationships.length > 0) {
          const promises = [] as Array<Promise<void>>;
          oldRelationships.forEach((u) => {
            optionModule.removeUnit(u.id);
            // productModule.removeUnit(u.id);
            promises.push(
              inventoryClient.deleteOptionUnitRelationship(orgId, { parentId: optionId, childId: u.id }).toPromise(),
            );
          });
          await Promise.allSettled(promises).then();
        }
        const createPromises = [] as Array<Promise<ProductUnit>>;
        optionUnits.forEach((u) => {
          optionModule.appendUnitFromTravelerList(u);
          createPromises.push(
            inventoryClient.createOptionUnitRelationship(orgId, { parentId: optionId, childId: u }).toPromise(),
          );
        });
        await Promise.allSettled(createPromises).then();
        optionModule.updateSelectedUnit();
        // await this.getProductUnitTravelerTypes(productId).then();
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_LOAD);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async createTravelerTypes(travelerType: Unit) {
    try {
      const name = travelerType.internalName || travelerType.displayName || 'traveler';
      travelerType.orgId = companyModule.Company.orgId;
      if (!_.isEmpty(travelerType.orgId)) {
        travelerTypeModule.startLoading();
        await inventoryClient
          .createTravelerType(travelerType)
          .toPromise()
          .then(() => {
            auditLog({}, travelerType, AuditMethod.CREATE);
            // travelerTypeModule.appendTravelerTypes(unit);
          });
        const message = `Added "${name}"`;
        appModule.addMessageSuccess([codes.TRAVELER_TYPE_CREATED, message]);
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_CREATE_TRAVELER_TYPE);
      }
    } finally {
      travelerTypeModule.finishLoading();
    }
  }

  @Action
  async updateTravelerTypes(travelerType: Unit) {
    try {
      const name = travelerType.internalName || travelerType.displayName || 'traveler';
      travelerType.orgId = companyModule.Company.orgId;
      if (!_.isEmpty(travelerType.orgId)) {
        travelerTypeModule.startLoading();
        travelerTypeModule.setSelectedTravelerType(travelerType);
        const selectedTravelerType = _.cloneDeep(travelerTypeModule.SelectedTravelerType);
        // selectedTravelerType.meta = incrementMeta(selectedTravelerType.meta);
        if (selectedTravelerType && selectedTravelerType.meta) {
          selectedTravelerType.version = incrementVersion(selectedTravelerType.meta);
        }

        await inventoryClient
          .updateTravelerType(selectedTravelerType)
          .toPromise()
          .then((travelerType) => {
            auditLog(selectedTravelerType, travelerType, AuditMethod.EDIT);
            travelerTypeModule.updateTravelerType(travelerType);
            const message = `Updated "${name}"`;
            appModule.addMessageSuccess([codes.TRAVELER_TYPE_UPDATED, message]);
          });
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_404_UPDATE_TRAVELER_TYPE);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_UPDATE_TRAVELER_TYPE);
      }
    } finally {
      travelerTypeModule.finishLoading();
    }
  }

  @Action
  async removeTravelerType(travelerType: Unit) {
    try {
      travelerTypeModule.startLoading();
      travelerTypeModule.setSelectedTravelerType(travelerType);
      const message = `Removed "${travelerType.internalName}"`;
      const selectedTravelerType = _.cloneDeep(travelerTypeModule.SelectedTravelerType);
      if (selectedTravelerType?.id && _.isString(selectedTravelerType.id)) {
        const id = selectedTravelerType.id;
        await inventoryClient
          .deleteTravelerType(selectedTravelerType)
          .toPromise()
          .then(() => {
            auditLog(travelerType, {}, AuditMethod.DELETE);
            travelerTypeModule.removeTravelerType(id);
            appModule.addMessageSuccess([codes.TRAVELER_TYPE_DELETED, message]);
          });
      } else {
        appModule.addMessageSuccess(codes.TRAVELER_TYPE_ERROR_MALFORMED_DELETE_TRAVELER_TYPE);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_404_DELETE_TRAVELER_TYPE);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.TRAVELER_TYPE_ERROR_500_DELETE_TRAVELER_TYPE);
      }
    } finally {
      travelerTypeModule.finishLoading();
    }
  }

  /**
   *  Options
   **/
  @Action
  async loadOptions() {
    try {
      optionModule.startLoading();
      const options = await inventoryClient.listOptions(companyModule.Organization?.ID || '').toPromise();
      optionModule.setOptions(options);
      offerModule.setOptions(options);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_ERROR_500_LOAD_OPTION);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async getOption(id: string) {
    try {
      optionModule.startLoading();
      const rq = {
        id: id,
        orgId: companyModule.Organization?.ID || '',
      } as Option;
      const option = await inventoryClient.getOption(rq).toPromise();
      optionModule.setOption(option);
      optionModule.configOption();
      optionModule.selectedOption(id);
      offerModule.setOption(option);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_ERROR_500_LOAD_OPTION);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async createOption(o: Option) {
    try {
      optionModule.startLoading();
      const option = _.cloneDeep(o);
      const meta = _.cloneDeep(option.meta) || ({} as Meta);
      option.meta = incrementMeta(meta);
      option.version = incrementVersion(meta);
      const orgId = companyModule.Organization?.ID || '';
      if (!_.isEmpty(orgId)) {
        await inventoryClient
          .updateOption(option)
          .toPromise()
          .then(async (optionResponse) => {
            const baseOption = _.cloneDeep(optionModule.BaseOption);
            auditLog(baseOption, option, AuditMethod.EDIT);
            optionModule.updateOption(optionResponse);
            const message = `Option Successfully created`;
            appModule.addMessageSuccess([codes.OPTION_UPDATED, message]);

            const unitIds = optionModule.SelectedUnitIds;
            if (unitIds.length > 0) {
              const productId = optionResponse.id;
              const promises = [] as Array<Promise<ProductUnit>>;
              unitIds.forEach((u) => {
                promises.push(
                  inventoryClient.createOptionUnitRelationship(orgId, { parentId: productId, childId: u }).toPromise(),
                );
              });
              await Promise.allSettled(promises).then((response) => {
                response.forEach((r) => {
                  if (r.status === 'rejected') {
                    return;
                  }
                });
              });
            }
          });
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OPTION_ERROR_500_CREATE_OPTION);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_ERROR_500_CREATE_OPTION);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async updateOption(o: Option) {
    try {
      optionModule.startLoading();
      // optionModule.buildOption(o);
      const option = _.cloneDeep(o);
      const meta = _.cloneDeep(option.meta) || ({} as Meta);
      option.meta = incrementMeta(meta);
      option.version = incrementVersion(meta);
      // if (!option.version) {
      //   option.version = 0;
      // }
      // option.version += 1;
      //
      // option.sharesAvailabilityWithProduct = _.cloneDeep(o.sharesAvailabilityWithProduct);
      // option.sharesCapacityWithProduct = _.cloneDeep(o.sharesCapacityWithProduct);
      // if (option.option) {
      //   option.option.shortDescription = _.cloneDeep(o.option.shortDescription);
      // }
      const name = o.internalName;
      await inventoryClient
        .updateOption(option)
        .toPromise()
        .then((optionResponse) => {
          const baseOption = _.cloneDeep(optionModule.BaseOption);
          auditLog(baseOption, option, AuditMethod.EDIT);
          optionModule.updateOption(optionResponse);
          const message = `Updated "${name}"`;
          appModule.addMessageSuccess([codes.OPTION_UPDATED, message]);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OPTION_ERROR_404_UPDATE_OPTION);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_ERROR_500_UPDATE_OPTION);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async getAvailabilitiesForOffer(req: any) {
    const orgId = companyModule.Organization?.ID || '';
    await Promise.resolve(availabilityStatusModule.startLoading())
      .then(() => this.getOffer({ offerId: req.offer.id, withCapacity: true }))
      .then(() => this.getOfferCapacity(pricingModule.Offer.capacity || []))
      .then(() => this.getSchedules())
      .then(() =>
        inventoryClient
          .getAvailabilityForOffer(orgId, req.offer.productId, req.startDate, req.endDate, req.offer.id)
          .toPromise(),
      )
      .then((avails: AvailabilityItem[]) =>
        avails.forEach((a) => {
          const start = parseISO(a.localDateTimeStart);
          const end = parseISO(a.localDateTimeEnd);
          const currentDate = start;
          a.isFreesale = a.maxCapacity === 2147483647;
          a.isBlackedOut = a.status === InventoryType.CLOSED;
          while (currentDate <= end) {
            availabilityStatusModule.appendAvailabilityItem({ day: format(currentDate, 'yyyy-MM-dd'), item: a });
            currentDate.setDate(currentDate.getDate() + 1);
          }
        }),
      )
      .then(() => {
        const dAvails: DayAvailability[] = [];
        availabilityStatusModule.AvailabilityItems.forEach((items, date) => {
          const avails = items.map((a) => {
            return {
              date: date,
              original: a.maxCapacity ? a.maxCapacity : 0,
              remaining: a.vacancies,
              sold: a.maxCapacity && a.vacancies ? a.maxCapacity - a.vacancies : 0,
            } as DayAvailability;
          });
          dAvails.push(...avails);
        });
        return dAvails;
      })
      .then((dAvails: DayAvailability[]) => dAvails.forEach((a) => availabilityStatusModule.appendDayAvailability(a)))
      .finally(() => availabilityStatusModule.finishLoading());
  }

  @Action
  async updateAvailabilityVacanies(_: { avail: AvailabilityItem; vacancies: number }) {
    availabilityStatusModule.startLoading();
    const orgId = companyModule.Organization?.ID || '';
    const req: AvailChangeReq = {
      AvailID: _.avail.id,
      UnitID: _.avail.unitId,
      Reason: 'n/a',
    };

    const diff = Math.abs(_.avail.vacancies - Number(_.vacancies));
    req.Delta = _.avail.vacancies >= _.vacancies ? Math.abs(diff) * -1 : diff;
    await inventoryClient
      .updateAvailabilityVacanies(orgId, _.avail.optionId, req)
      .toPromise()
      .then(() => (_.avail.vacancies = _.vacancies))
      .catch((e) => {
        if (e.status === 404) {
          appModule.addMessageError(codes.AVAILABILITY_STATUS_404_UPDATE);
        }
        if (e.status === 500) {
          appModule.addMessageError(codes.AVAILABILITY_STATUS_500_UPDATE);
        }
        throw e;
      })
      .finally(() => availabilityStatusModule.finishLoading());
  }

  @Action
  async updateAvailabilityStatus(_: { avail: AvailabilityItem; status: string }) {
    availabilityStatusModule.startLoading();
    const orgId = companyModule.Organization?.ID || '';
    const req: AvailChangeReq = {
      AvailID: _.avail.id,
      UnitID: _.avail.unitId,
      Reason: 'n/a',
      Status: _.status,
    };
    await inventoryClient
      .updateAvailabilityStatus(orgId, _.avail.optionId, req)
      .toPromise()
      .then(() => {
        if (_.status === InventoryType.CLOSED) {
          _.avail.isBlackedOut = true;
          _.avail.status = InventoryType.CLOSED;
        } else {
          _.avail.isBlackedOut = false;
          _.avail.status = InventoryType.AVAILABLE;
        }
        const dt = parseISO(_.avail.localDateTimeStart);
        availabilityStatusModule.updateAvailabilityItem({ day: format(dt, 'yyyy-MM-dd'), item: _.avail });
      })
      .catch((e) => {
        if (e.status === 404) {
          appModule.addMessageError(codes.AVAILABILITY_STATUS_404_UPDATE);
        }
        if (e.status === 500) {
          appModule.addMessageError(codes.AVAILABILITY_STATUS_500_UPDATE);
        }
        throw e;
      });
  }

  @Action
  async removeOption(id: string) {
    try {
      optionModule.startLoading();
      if (id && _.isString(id) && id.trim().length > 0) {
        const rq = {
          id: id,
          orgId: companyModule.Organization?.ID || '',
        } as Option;
        const i = optionModule.Options.findIndex((o) => o.id === id);
        const o = i > -1 ? optionModule.Options[i] : rq;
        await inventoryClient
          .deleteOption(rq)
          .toPromise()
          .then(() => {
            auditLog(o, {}, AuditMethod.DELETE);
            optionModule.removeOption(id);
            appModule.addMessageSuccess(codes.OPTION_DELETED);
          });
      } else {
        appModule.addMessageSuccess(codes.TRAVELER_TYPE_ERROR_MALFORMED_DELETE_TRAVELER_TYPE);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OPTION_ERROR_404_DELETE_OPTION);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_ERROR_500_DELETE_OPTION);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  /**
   *  Products
   **/
  @Action
  async loadProducts() {
    try {
      productModule.startLoading();
      const products = await inventoryClient.listProducts(companyModule.Organization?.ID || '').toPromise();
      productModule.setProducts(products);
      offerModule.setProducts(products);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_ERROR_500_LOAD_COMPANY);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async getProduct(id: string) {
    try {
      productModule.startLoading();
      const rq = {
        id: id,
        orgId: companyModule.Organization?.ID || '',
      } as Product;
      const product = await inventoryClient.getProduct(rq).toPromise();

      if (product && product.id) {
        productModule.selectedProduct(product);
        productModule.configureProduct();
        offerModule.setProduct(product);
      } else {
        await router.push({ name: constants.routes.PRODUCT_LIST }).catch();
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRODUCT_ERROR_500_LOAD_COMPANY);
        await router.push({ name: constants.routes.PRODUCT_LIST }).catch();
      }

      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_ERROR_500_LOAD_COMPANY);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async createProduct(p: Product) {
    try {
      productModule.startLoading();

      const name = p.internalName || '';
      await inventoryClient
        .createProduct(p)
        .toPromise()
        .then(() => {
          auditLog({}, p, AuditMethod.CREATE);
          productModule.createProduct(p);
          // productModule.setProduct(p);
          // productModule.configureProduct();
          const message = `Added "${name}"`;
          appModule.addMessageSuccess([codes.PRODUCT_CREATED, message]);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRODUCT_ERROR_500_CREATE_COMPANY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_ERROR_500_CREATE_COMPANY);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async updateAddProduct() {
    try {
      productModule.startProductCreateSaving();
      const product = _.cloneDeep(productModule.Product);
      const meta = _.cloneDeep(product.meta) || ({} as Meta);
      product.meta = incrementMeta(meta);
      product.version = incrementVersion(meta);
      const orgId = companyModule.Organization?.ID || '';

      await inventoryClient
        .updateProduct(product)
        .toPromise()
        .then(async (p: Product) => {
          const unitIds = productModule.SelectedUnitIds;
          if (unitIds.length > 0) {
            const productId = p.id;
            const promises = [] as Array<Promise<ProductUnit>>;
            unitIds.forEach((u) => {
              promises.push(
                inventoryClient.createProductUnitRelationship(orgId, { parentId: productId, childId: u }).toPromise(),
              );
            });
            await Promise.allSettled(promises).then((response) => {
              response.forEach((r) => {
                if (r.status === 'rejected') {
                  return;
                }
              });
            });
          }

          const baseProduct = _.cloneDeep(productModule.BaseProduct);
          auditLog(baseProduct, product, AuditMethod.CREATE);
          productModule.setProduct(p);
          productModule.setBaseProduct(p);
          productModule.finishProductCreateSaving();
        });
    } catch (e) {
      productModule.setCreateProductSaveProgressError(true);
      productModule.finishProductCreateSaving();
    }
  }

  @Action
  async updateProduct(skipLoading?: boolean) {
    try {
      if (!skipLoading) {
        productModule.startLoading();
      }
      const product = _.cloneDeep(productModule.Product);
      const meta = _.cloneDeep(product.meta) || ({} as Meta);
      product.meta = incrementMeta(meta);
      product.version = incrementVersion(meta);

      // const ticket = {} as TicketDelivery;
      // ticket.redemptionMethod = _.cloneDeep(productModule.RedemptionMethod);
      // ticket.deliveryMethods = _.cloneDeep(productModule.DeliveryMethods);
      // ticket.deliveryFormats = _.cloneDeep(productModule.DeliveryFormats);

      // product.description = _.cloneDeep(productModule.Description);
      // product.availabilityType = _.cloneDeep(productModule.SelectedInventoryType); // todo change this to use correct AvailabilityStatus type
      // product.ticketDelivery = ticket;
      // product.product.locations = [];
      // if (!_.isEmpty(productModule.PrimaryLocation)) {
      //   product.product.locations.push(_.cloneDeep(productModule.PrimaryLocation));
      // }
      // if (!_.isEmpty(productModule.DepartureLocation)) {
      //   product.product.locations.push(_.cloneDeep(productModule.DepartureLocation));
      // }
      // if (!_.isEmpty(productModule.ArrivalLocation)) {
      //   product.product.locations.push(_.cloneDeep(productModule.ArrivalLocation));
      // }
      // if (!_.isEmpty(productModule.VisitedLocations)) {
      //   product.product.locations.push(..._.cloneDeep(productModule.VisitedLocations));
      // }
      // if (!product.version) {
      //   product.version = 0;
      // }
      // product.version += 1;
      // if (product.product) {
      //   product.product.description = _.cloneDeep(productModule.Description);
      //   product.product.deliveryFormats = _.cloneDeep(productModule.DeliveryFormats);
      //   product.product.deliveryMethods = _.cloneDeep(productModule.DeliveryMethods);
      //   product.product.redemptionMethod = _.cloneDeep(productModule.RedemptionMethod);
      //   product.product.availabilityType = _.cloneDeep(productModule.SelectedInventoryType); // todo change this to use correct AvailabilityStatus type
      //   product.product.locations = [];
      //   if (!_.isEmpty(productModule.PrimaryLocation)) {
      //     product.product.locations.push(_.cloneDeep(productModule.PrimaryLocation));
      //   }
      //   if (!_.isEmpty(productModule.DepartureLocation)) {
      //     product.product.locations.push(_.cloneDeep(productModule.DepartureLocation));
      //   }
      //   if (!_.isEmpty(productModule.ArrivalLocation)) {
      //     product.product.locations.push(_.cloneDeep(productModule.ArrivalLocation));
      //   }
      //   if (!_.isEmpty(productModule.VisitedLocations)) {
      //     product.product.locations.push(..._.cloneDeep(productModule.VisitedLocations));
      //   }
      // }
      await inventoryClient
        .updateProduct(product)
        .toPromise()
        .then(() => {
          const baseProduct = _.cloneDeep(productModule.BaseProduct);
          auditLog(baseProduct, product, AuditMethod.EDIT);
          productModule.updateProduct(product);
          const message = `Updated "${product.internalName || 'company'}"`;
          appModule.addMessageSuccess([codes.PRODUCT_UPDATED, message]);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRODUCT_ERROR_404_UPDATE_COMPANY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_ERROR_500_UPDATE_COMPANY);
      }
    } finally {
      if (!skipLoading) {
        productModule.finishLoading();
      }
    }
  }

  @Action
  async removeProduct(id: string) {
    try {
      productModule.startLoading();

      const rq = {
        id: id,
        orgId: companyModule.Organization?.ID || '',
      } as Product;

      await inventoryClient
        .deleteProduct(rq)
        .toPromise()
        .then(() => {
          auditLog(rq, {}, AuditMethod.DELETE);
          productModule.removeProduct(id);
          appModule.addMessageSuccess(codes.PRODUCT_DELETED);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRODUCT_ERROR_404_DELETE_COMPANY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_ERROR_500_DELETE_COMPANY);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async getProductTripRoutes() {
    try {
      productModule.startLoading();
      const product = _.cloneDeep(productModule.Product);
      await inventoryClient
        .getProductTripRoute(product)
        .toPromise()
        .then((tripRoute) => {
          productModule.setTripRoute(tripRoute);
        });
    } catch (e) {
      if (e.status === 404) {
        // appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_500_LOAD_COMPANY);
      }

      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_500_LOAD_COMPANY);
      }
      productModule.setTripRoute({} as TripRoute);
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async updateProductTripRoute() {
    try {
      productModule.startLoading();
      const product = _.cloneDeep(productModule.Product);
      const tripRoute = _.cloneDeep(productModule.TripRoute);
      const meta = _.cloneDeep(tripRoute.meta) || ({} as Meta);
      tripRoute.meta = incrementMeta(meta);
      tripRoute.version = incrementVersion(meta);

      await inventoryClient
        .createProductTripRoute(product, tripRoute)
        .toPromise()
        .then(() => {
          const baseTripRoute = _.cloneDeep(productModule.BaseTripRoute);
          auditLog(baseTripRoute, tripRoute, AuditMethod.EDIT);
          productModule.updateProductTripRoue(tripRoute);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_404_UPDATE_COMPANY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_500_UPDATE_COMPANY);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async deleteProductTripRoute() {
    try {
      productModule.startLoading();
      const product = _.cloneDeep(productModule.Product);

      await inventoryClient
        .deleteProductTripRoute(product)
        .toPromise()
        .then(() => {
          const baseTripRoute = _.cloneDeep(productModule.BaseTripRoute);
          auditLog(baseTripRoute, {}, AuditMethod.EDIT);
          productModule.updateProductTripRoue({} as TripRoute);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_404_DELETE_COMPANY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_500_DELETE_COMPANY);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async listProductContacts(productId: string) {
    try {
      productModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      await inventoryClient
        .listProductContacts({ productId: productId, orgId: orgId })
        .toPromise()
        .then((contacts) => {
          productModule.setProductContacts(contacts);
        });
    } catch (e) {
      return;
      // add GCP logger when it will be implemented
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async createProductContact(rq: { productId: string; contactIds: Array<string> }) {
    try {
      productModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      const promises = [] as Array<Promise<any>>;
      rq.contactIds.forEach((id) => {
        promises.push(
          inventoryClient.createProductContact({ productId: rq.productId, contactId: id, orgId: orgId }).toPromise(),
        );
      });
      await Promise.allSettled(promises).then((response) => {
        response.forEach((r) => {
          if (r.status === 'rejected') {
            // todo alert on failure
            return;
          }
        });
      });
    } catch (e) {
      return;
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async getOptionTripRoutes() {
    try {
      optionModule.startLoading();
      const option = _.cloneDeep(optionModule.Option);
      await inventoryClient
        .getOptionTripRoute(option)
        .toPromise()
        .then((tripRoute) => {
          optionModule.setTripRoute(tripRoute);
        });
    } catch (e) {
      if (e.status === 404) {
        // appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_500_LOAD_COMPANY);
      }

      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_TRIP_ROUTE_ERROR_500_LOAD_TRIP_ROUTE);
      }
      optionModule.setTripRoute({} as TripRoute);
    } finally {
      optionModule.finishLoading();
    }
  }
  @Action
  async deleteProductContact(rq: { productId: string; contactIds: Array<string> }) {
    try {
      productModule.startLoading();
      const orgId = companyModule.Organization?.ID || '';
      const promises = [] as Array<Promise<any>>;
      rq.contactIds.forEach((id) => {
        promises.push(
          inventoryClient.deleteProductContact({ productId: rq.productId, contactId: id, orgId: orgId }).toPromise(),
        );
      });
      await Promise.allSettled(promises).then((response) => {
        response.forEach((r) => {
          if (r.status === 'rejected') {
            // todo alert on failure
            return;
          }
        });
      });
    } catch (e) {
      return;
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async updateOptionTripRoute() {
    try {
      optionModule.startLoading();
      const option = _.cloneDeep(optionModule.Option);
      const tripRoute = _.cloneDeep(optionModule.TripRoute);
      const meta = _.cloneDeep(tripRoute.meta) || ({} as Meta);
      tripRoute.meta = incrementMeta(meta);
      tripRoute.version = incrementVersion(meta);

      await inventoryClient
        .createOptionTripRoute(option, tripRoute)
        .toPromise()
        .then(() => {
          const baseTripRoute = _.cloneDeep(optionModule.BaseTripRoute);
          auditLog(baseTripRoute, tripRoute, AuditMethod.EDIT);
          optionModule.updateOptionTripRoute(tripRoute);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OPTION_TRIP_ROUTE_ERROR_404_UPDATE_TRIP_ROUTE);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_TRIP_ROUTE_ERROR_500_UPDATE_TRIP_ROUTE);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async createProductAddress(body: OppProductAddressWrapper) {
    try {
      productModule.startLoading();
      // await inventoryClient.createProductAddress(body.productAddress, body.criteria).toPromise();
      await inventoryClient.createProductAddress({} as Address, body.criteria).toPromise();
      // productModule.setProducts(products);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_ERROR_500_LOAD_COMPANY);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async getProductCapacity() {
    try {
      productModule.startLoading();
      const pool = _.cloneDeep(productModule.ProductCapacityPool);
      await inventoryClient
        .getProductCapacity(pool)
        .toPromise()
        .then((p) => {
          const capacityPool = {
            orgId: pool.orgId,
            productId: pool.productId,
            capacityPool: p,
          } as ProductCapacityPool;
          productModule.setProductCapacityPool(capacityPool);
          productModule.setBaseProductCapacityPool(capacityPool);
          offerModule.setProductCapacityPool(capacityPool);
        });
    } catch (e) {
      if (e.status === 404) {
        // appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_500_LOAD_COMPANY);
      }

      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_CAPACITY_ERROR_500_LOAD_CAPACITY);
      }
      productModule.setProductCapacityPool({} as ProductCapacityPool);
      offerModule.setProductCapacityPool({} as ProductCapacityPool);
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async updateProductCapacity() {
    try {
      productModule.startLoading();
      const pool = _.cloneDeep(productModule.ProductCapacityPool);
      // const product = _.cloneDeep(productModule.Product);
      // const tripRoute = _.cloneDeep(productModule.TripRoute);
      // const meta = _.cloneDeep(tripRoute.meta) || ({} as Meta);
      // tripRoute.meta = incrementMeta(meta);
      // tripRoute.version = incrementVersion(meta);

      await inventoryClient
        .updateProductCapacity(pool)
        .toPromise()
        .then(() => {
          const basePool = _.cloneDeep(productModule.BaseProductCapacityPool);
          auditLog(basePool, pool, AuditMethod.EDIT);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.PRODUCT_CAPACITY_ERROR_404_UPDATE_CAPACITY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.PRODUCT_CAPACITY_ERROR_500_UPDATE_CAPACITY);
      }
    } finally {
      productModule.finishLoading();
    }
  }

  @Action
  async getOptionCapacity() {
    try {
      optionModule.startLoading();
      const pool = _.cloneDeep(optionModule.OptionCapacityPool);
      await inventoryClient
        .getOptionCapacity(pool)
        .toPromise()
        .then((p) => {
          const capacityPool = {
            orgId: pool.orgId,
            optionId: pool.optionId,
            capacityPool: p,
          } as OptionCapacityPool;
          optionModule.setOptionCapacityPool(capacityPool);
          pricingModule.setOptionCapacityPool(capacityPool);
          optionModule.setBaseOptionCapacityPool(capacityPool);
          offerModule.setOptionCapacityPool(capacityPool);
        });
    } catch (e) {
      if (e.status === 404) {
        // appModule.addMessageError(codes.PRODUCT_TRIP_ROUTE_ERROR_500_LOAD_COMPANY);
      }

      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_CAPACITY_ERROR_500_LOAD_CAPACITY);
      }
      optionModule.setOptionCapacityPool({} as OptionCapacityPool);
      offerModule.setOptionCapacityPool({} as OptionCapacityPool);
    } finally {
      optionModule.finishLoading();
    }
  }

  @Action
  async updateOptionCapacity() {
    try {
      optionModule.startLoading();
      const pool = _.cloneDeep(optionModule.OptionCapacityPool);
      // const product = _.cloneDeep(productModule.Product);
      // const tripRoute = _.cloneDeep(productModule.TripRoute);
      // const meta = _.cloneDeep(tripRoute.meta) || ({} as Meta);
      // tripRoute.meta = incrementMeta(meta);
      // tripRoute.version = incrementVersion(meta);

      await inventoryClient
        .updateOptionCapacity(pool)
        .toPromise()
        .then(() => {
          const basePool = _.cloneDeep(optionModule.BaseOptionCapacityPool);
          auditLog(basePool, pool, AuditMethod.EDIT);
        });
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OPTION_CAPACITY_ERROR_404_UPDATE_CAPACITY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.OPTION_CAPACITY_ERROR_500_UPDATE_CAPACITY);
      }
    } finally {
      optionModule.finishLoading();
    }
  }

  // @Action
  // async getProductAndRelationships(productId: string) {
  //   const product = this.getProduct(productId);
  //   const relationships = this.getProductRelationships();
  //   return Promise.all([product, relationships]);
  // }

  @Action
  async loadAvailabilitySchedules(byOption?: boolean) {
    const colors = [
      '#489DCB',
      '#DB1D4A',
      '#fca311',
      '#3CBE6A',
      '#0C334C',
      // '#8d8d8f',
      // '#202020',
    ];
    const availabilitySchedule = {
      supplierId: companyModule.Company.orgId,
      productId: byOption ? undefined : productModule.Product.id,
      optionId: byOption ? optionModule.Option?.id : undefined,
    } as AvailabilitySchedule;
    await inventoryClient
      .getAvailabilitySchedulesBySupplierIDAndProductID(availabilitySchedule)
      .toPromise()
      .then((availabilitySchedules) => {
        const a = _.sortBy(
          availabilitySchedules,
          [(availabilitySchedules) => availabilitySchedules.name?.toLowerCase()],
          ['name', 'startTime'],
        );

        productModule.setAvailabilitySchedules(a);
        const scheduleViews = [] as Array<ScheduleView>;
        const groupedAvailSchedules = [] as Array<GroupedAvailabilitySchedule>;
        let colorLoopIndex = 0;
        a.forEach((availabilitySchedule) => {
          /* configure colors */
          const colorIndex = productModule.ScheduleColorMappings.findIndex(
            (scheduleColor) => scheduleColor.id === availabilitySchedule.groupId,
          );
          if (colorIndex === -1) {
            if (colorLoopIndex > colors.length - 1) {
              colorLoopIndex = 0;
            }
            const color = colors[colorLoopIndex];
            productModule.ScheduleColorMappings.push({ id: availabilitySchedule.groupId || '', color: color });
            colorLoopIndex += 1;
          }
          /* End configure colors */

          const group = scheduleViews.find((scheduleView) => scheduleView.id === availabilitySchedule.groupId);

          if (group) {
            group.schedules.push(availabilitySchedule);
          } else {
            const schedule = {
              id: availabilitySchedule.groupId || availabilitySchedule.id || '',
              productId: availabilitySchedule.productId || '',
              optionId: availabilitySchedule.optionId || '',
              scheduleName: availabilitySchedule.name,
              validFrom: availabilitySchedule.validFrom || '',
              validUntil: availabilitySchedule.validUntil || '',
              schedules: [availabilitySchedule],
            } as ScheduleView;
            scheduleViews.push(schedule);
          }
        });

        scheduleViews.forEach((scheduleView) => {
          scheduleView.schedules.sort((a, b) => {
            if (!(a.daysOfWeek && a.daysOfWeek.length > 0) || !(b.daysOfWeek && b.daysOfWeek.length > 0)) {
              return 0;
            }
            const aVal = moment().day(a.daysOfWeek[0]).day();
            const bVal = moment().day(b.daysOfWeek[0]).day();
            if (aVal < bVal) {
              return -1;
            }

            if (aVal > bVal) {
              return 1;
            }

            return 0;
          });
        });
        productModule.setScheduleViews(scheduleViews);
        productModule.setGroupedAvailabilitySchedules(groupedAvailSchedules);
      })
      .catch(() => {
        // todo send error
      });
  }

  @Action
  async loadAvailabilitySchedulesForOption() {
    // const company = companyModule?.Supplier;
    // if (company) {
    //   const reqAvailSchedule = {
    //     supplierId: company.id,
    //     productId: '', // productModule.Product.product.id, // todo get product-option relationship
    //     optionId: optionModule.Option.option.id,
    //   } as AvailabilitySchedule;
    //   await inventoryClient
    //     .getAvailabilitySchedulesBySupplierIDAndProductIDAndOptionID(reqAvailSchedule)
    //     .toPromise()
    //     .then((schedules: Array<AvailabilitySchedule>) => {
    //       productModule.setAvailabilitySchedules(schedules);
    //     })
    //     .catch(() => {
    //       // todo send alert
    //     })
    //     .finally(() => {});
    // }
  }

  @Action
  async createAvailabilitySchedules(schedules: Array<AvailabilitySchedule>) {
    const promises = [] as Array<Promise<AvailabilitySchedule>>;
    schedules.forEach((schedule) => {
      promises.push(inventoryClient.createAvailabilitySchedule(schedule).toPromise());
    });
    await Promise.allSettled(promises).then((response) => {
      response.forEach((r) => {
        if (r.status === 'rejected') {
          // todo alert on failure
          return;
        }
      });
      // todo set loading
      auditLog({}, schedules, AuditMethod.CREATE);
    });
  }

  @Action
  async updateAvailabilitySchedule(schedule: AvailabilitySchedule) {
    if (!schedule.version) {
      schedule.version = 0;
    }
    schedule.version += 1;
    await inventoryClient
      .updateAvailabilitySchedule(schedule)
      .toPromise()
      .then(() => {
        auditLog({}, schedule, AuditMethod.EDIT); // todo find before body
      })
      .catch(() => {
        // todo send alert
      })
      .finally(() => {
        this.loadAvailabilitySchedules().then();
      });
  }

  @Action
  async updateAvailabilitySchedules(schedules: Array<AvailabilitySchedule>) {
    const promises = [] as Array<Promise<AvailabilitySchedule>>;
    schedules.forEach((schedule) => {
      if (schedule.id && schedule.id.length > 0) {
        if (!schedule.version) {
          schedule.version = 0;
        }
        schedule.version += 1;
        promises.push(inventoryClient.updateAvailabilitySchedule(schedule).toPromise());
      }
    });
    await Promise.allSettled(promises)
      .then((response) => {
        response.forEach((r) => {
          if (r.status === 'rejected') {
            // todo alert on failure
            return;
          }
        });
        auditLog({}, schedules, AuditMethod.EDIT); // todo find before body
      })
      .finally(() => {
        this.loadAvailabilitySchedules().then();
      });
  }

  @Action
  async removeAvailabilitySchedule(schedule: AvailabilitySchedule) {
    if (schedule?.id && _.isString(schedule.id)) {
      await inventoryClient
        .deleteAvailabilitySchedule(schedule.id)
        .toPromise()
        .then(() => {
          auditLog(schedule, {}, AuditMethod.DELETE);
        })
        .catch(() => {
          // todo send alert
        })
        .finally(() => {
          this.loadAvailabilitySchedules().then();
        });
    }
  }

  @Action
  async removeAvailabilitySchedules(schedules: Array<AvailabilitySchedule>) {
    const promises = [] as Array<Promise<AvailabilitySchedule>>;
    schedules.forEach((schedule) => {
      if (schedule.id && schedule.id.length > 0) {
        promises.push(inventoryClient.deleteAvailabilitySchedule(schedule.id).toPromise());
      }
    });
    await Promise.allSettled(promises)
      .then((response) => {
        response.forEach((r) => {
          if (r.status === 'rejected') {
            // todo alert on failure
            return;
          }
        });
        auditLog(schedules, {}, AuditMethod.DELETE);
      })
      .finally(() => {
        this.loadAvailabilitySchedules().then();
      });
  }

  @Action
  async searchBookingsReport(filters: BookingsReportFilter) {
    if (_.isEmpty(filters.target)) {
      reportModule.setReportData({} as BookingsReport);
      appModule.addMessageError(codes.REPORTS_ERROR_400_MISSING_EID);
      return;
    }
    try {
      reportModule.startLoading();
      const reports = await reportsClient.search(filters).toPromise();
      reportModule.setReportData(reports);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.REPORTS_ERROR_500_SEARCH);
      }
    } finally {
      reportModule.finishLoading();
    }
  }

  @Action
  async getOfferDateRanges(offers: Array<string>) {
    const promises = [] as Array<Promise<EffectiveDateRange>>;
    offers.forEach((offerId) => {
      if (offerId && offerId.length > 0) {
        const offerDateRange = {
          companyId: companyModule.Organization?.ID || '',
          offerId: offerId,
        } as OfferDateRangeReq;
        promises.push(inventoryClient.getOfferDateRanges(offerDateRange).toPromise());
      }
    });
    await Promise.allSettled(promises).then((response) => {
      const effectiveDateRange = {} as EffectiveDateRange;
      response.forEach((r) => {
        if (r.status === 'fulfilled') {
          if (
            r.value &&
            r.value.validFrom &&
            (r.value.validFrom < effectiveDateRange.validFrom || !effectiveDateRange.validFrom)
          ) {
            effectiveDateRange.validFrom = r.value.validFrom;
          }
          if (
            r.value &&
            r.value.validUntil &&
            ((effectiveDateRange.validUntil && r.value.validUntil > effectiveDateRange.validUntil) ||
              !effectiveDateRange.validUntil)
          ) {
            effectiveDateRange.validUntil = r.value.validUntil;
          }
        }
      });
      channelModule.setOfferDateRange(effectiveDateRange);
    });
  }

  @Action
  async loadCompanyAddresses() {
    try {
      companyModule.startLoading();
      const org = companyModule.Organization;
      if (org && org.ID) {
        const criteria = {
          companyId: org.ID,
        } as OppAddressQueryCriteria;

        const addresses = await inventoryClient.getCompanyAddresses(criteria).toPromise();
        companyModule.setAddresses(addresses);
      } else {
        appModule.addMessageError(codes.GENERAL_MESSAGE_NO_ORG_ID);
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_LOAD_ADDRESS);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async loadProductAddresses(productId: string) {
    try {
      companyModule.startLoading();
      const criteria = {
        productId: productId,
      } as OppAddressQueryCriteria;
      const addresses = await inventoryClient.getProductAddresses(criteria).toPromise();
      productModule.configProductAddresses(addresses || []);
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_LOAD_PRODUCT_ADDRESS);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async createCompanyAddress(address: Address) {
    try {
      companyModule.startLoading();
      const org = companyModule.Organization;
      if (org && org.ID) {
        const criteria = {
          companyId: org.ID,
        } as OppAddressQueryCriteria;

        const a = await inventoryClient
          .createCompanyAddress(address, criteria)
          .toPromise()
          .then((address) => {
            auditLog({}, address, AuditMethod.CREATE);
            return address;
          });
        companyModule.appendAddress(a);
      } else {
        appModule.addMessageError(codes.GENERAL_MESSAGE_NO_ORG_ID);
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_CREATE_COMPANY_ADDRESS);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async updateCompanyAddress(address: Address) {
    try {
      companyModule.startLoading();
      const org = companyModule.Organization;
      if (org && org.ID) {
        const criteria = {
          companyId: org.ID,
          addressId: address.id,
        } as OppAddressQueryCriteria;

        const meta = _.cloneDeep(address.meta) || ({} as Meta);

        address.meta = incrementMeta(meta);
        address.version = incrementVersion(meta);
        await inventoryClient
          .updateCompanyAddress(address, criteria)
          .toPromise()
          .then((a) => {
            const oldAddress = companyModule.SelectedAddress || {};
            auditLog(oldAddress, a, AuditMethod.EDIT);
            companyModule.upsertAddress(a);
            return a;
          });
      } else {
        appModule.addMessageError(codes.GENERAL_MESSAGE_NO_ORG_ID);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_UPDATE_COMPANY_ADDRESS);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_UPDATE_COMPANY_ADDRESS);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async deleteCompanyAddress(address: Address) {
    try {
      companyModule.startLoading();
      const org = companyModule.Organization;
      if (org && org.ID) {
        const criteria = {
          companyId: org.ID,
          addressId: address.id,
        } as OppAddressQueryCriteria;

        const backAddress = _.cloneDeep(address);
        await inventoryClient
          .deleteCompanyAddress(address, criteria)
          .toPromise()
          .then(() => {
            auditLog(backAddress, {}, AuditMethod.DELETE);
          });
        companyModule.removeAddress(address);
      } else {
        appModule.addMessageError(codes.GENERAL_MESSAGE_NO_ORG_ID);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_DELETED_COMPANY_ADDRESS);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_DELETED_COMPANY_ADDRESS);
      }
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async loadOperatorUser(userId: string) {
    try {
      userModule.startLoadingOperatorUser();
      const org = companyModule.Organization;
      if (org && org.ID) {
        const operatorUser = await inventoryClient
          .getOperatorUser({ companyId: org.ID, userId: userId })
          .toPromise()
          .then();
        userModule.setOperatorUser(operatorUser);
      } else {
        appModule.addMessageError(codes.GENERAL_MESSAGE_NO_ORG_ID);
      }
    } catch (e) {
      if (e.status === 500) {
        appModule.addMessageError(codes.OPERATOR_USER_500_GET);
      }
    } finally {
      userModule.finishLoadingOperatorUser();
    }
  }

  @Action
  async updateOperatorUser(user: OperatorUser) {
    try {
      userModule.startLoadingOperatorUser();
      const org = companyModule.Organization;
      if (org && org.ID) {
        const operatorUser = await inventoryClient
          .storeOperatorUser({ companyId: org.ID, userId: user.id, body: user })
          .toPromise()
          .then();
        userModule.setOperatorUser(operatorUser);
      } else {
        appModule.addMessageError(codes.GENERAL_MESSAGE_NO_ORG_ID);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OPERATOR_USER_404_GET);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.OPERATOR_USER_500_GET);
      }
    } finally {
      userModule.finishLoadingOperatorUser();
    }
  }

  @Action
  async deleteOperatorUser(user: OperatorUser) {
    try {
      userModule.startLoadingOperatorUser();
      const org = companyModule.Organization;
      if (org && org.ID) {
        await inventoryClient.deleteOperatorUser({ companyId: org.ID, userId: user.id }).toPromise().then();
        userModule.setOperatorUser(null);
      } else {
        appModule.addMessageError(codes.GENERAL_MESSAGE_NO_ORG_ID);
      }
    } catch (e) {
      if (e.status === 404) {
        appModule.addMessageError(codes.OPERATOR_USER_404_GET);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.OPERATOR_USER_500_GET);
      }
    } finally {
      userModule.finishLoadingOperatorUser();
    }
  }

  @Action
  async getTimezoneByLatLong(location: any) {
    try {
      const lat = location.lat;
      const long = location.long;

      if (!lat || !long) {
        return;
      }

      //https://developers.google.com/maps/documentation/timezone/requests-timezone
      const tz = await backplaneClient.getTimezoneByLatLong(location.lat, location.long).toPromise();

      console.log(tz);
    } catch (e) {
      if (e.status === 500) {
        // todo general error
      }
    }
  }
}

export const httpModule = getModule(ModuleHttp, store);
