





















































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { AddressType } from '@/api/inventory/InventoryModels';
import { companyModule } from '@/store/modules/moduleCompany';
import { httpModule } from '@/store/modules/moduleHttp';
import { tablePaginationOptions, findCountryName } from '@/utils/helpers';
import { Address } from '@/models';
import { profileModule } from '@/store/modules/moduleProfile';
import { userModule } from '@/store/modules/moduleUser';

@Component({})
export default class CompanyLocation extends Vue {
  /* DATA */

  private removeModal = false;
  private saving = false;
  private routerLocationAdd = constants.routes.LOCATION_ADD;
  private routerLocationViewName = constants.routes.LOCATION_VIEW;
  private routerLocationEditName = constants.routes.LOCATION_EDIT;
  private routerCompanyAboutName = constants.routes.COMPANY;
  private findCountryName = findCountryName;
  private locationsHeaders: Array<Record<string, any>> = [
    {
      text: 'Name',
      value: 'name',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    {
      text: 'Address',
      value: 'streetAddress',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    { text: '', value: 'view', align: 'right', width: 300, sortable: false },
  ];

  /* COMPUTED */

  get paginationOptions() {
    return tablePaginationOptions(this.CompanyAddresses);
  }

  get OperatorUser() {
    return userModule.OperatorUser;
  }
  /* HOOKS */

  mounted() {
    companyModule.setSelectedAddress({} as Address);
  }

  /* METHODS */

  selectAddress(address: Address) {
    companyModule.setSelectedAddress(address);
  }

  navigateToNewLocation() {
    if (!this.IsOrgAdmin) {
      return;
    }
    this.$router.push({ name: constants.routes.LOCATION_ADD }).catch();
  }

  navigateToViewLocation(address: Address) {
    this.selectAddress(address);
    this.$router.push({ name: this.routerLocationViewName }).catch();
  }

  navigateToEditLocation(address: Address) {
    this.selectAddress(address);
    if (address && address.id && address.id.length > 0) {
      this.$router
        .push({
          name: this.routerLocationEditName,
          params: { id: address.id },
        })
        .catch();
    }
  }

  removeLocation(address: Address) {
    if (!this.IsOrgAdmin) {
      return;
    }
    this.selectAddress(address);
    this.removeModal = true;
  }

  cancelRemoveModal() {
    this.removeModal = false;
    setTimeout(() => {
      this.selectAddress({} as Address);
    }, 500);
  }

  proceedRemoveAddress() {
    if (!this.IsOrgAdmin) {
      return;
    }
    try {
      if (this.SelectedAddress) {
        this.saving = true;
        httpModule.deleteCompanyAddress(this.SelectedAddress);
      }
    } catch {
      // todo send error to backend
    } finally {
      this.saving = false;
      this.removeModal = false;
    }
  }

  proceedRemoveLocation() {
    try {
      // this.saving = true;
      // const primaryLocation = _.cloneDeep(this.Supplier.location);
      // const otherLocations = [] as Array<SupplierLocation>;
      // otherLocations.push(...(this.Supplier.otherLocations || []));
      // _.remove(
      //   otherLocations,
      //   (location: SupplierLocation) => location.id === this.SelectedLocation.id,
      // );
      // const supplier = {
      //   id: this.Supplier.id ? this.Supplier.id : faker.datatype.uuid(),
      //   name: this.Supplier.name,
      //   version: this.Supplier.version ? this.Supplier.version + 1 : 1,
      //   location: {
      //     ...primaryLocation,
      //   },
      //   otherLocations: otherLocations,
      //   contact: { ...this.Supplier.contact },
      // } as Supplier;
      // // inventoryModule.setSupplier(supplier);
      // companyModule.setSupplier(supplier);
      // httpModule
      //   .updateCompany()
      //   .then(() => {})
      //   .catch();
      // inventoryModule
      //   .updateSupplier()
      //   .then(() => {
      //     // inventoryModule.loadSupplier().then();
      //   })
      //   .catch();
    } catch {
      // console.error('failed to remove location');
      // todo send error to backend
    } finally {
      this.saving = false;
      this.removeModal = false;
    }
  }

  locationTypeValue(addressType: AddressType): number {
    switch (addressType) {
      case AddressType.PRIMARY:
        return 0;
      case AddressType.DEPARTURE:
        return 1;
      case AddressType.STOP:
        return 2;
      case AddressType.ARRIVAL:
        return 3;
      case AddressType.OTHER:
        return 4;
      default:
        return 5;
    }
  }

  get CompanyAddresses() {
    if (this.IsOrgAdmin) return companyModule.Addresses;
    else
      return companyModule.Addresses.filter((location: any) => {
        return (
          this.OperatorUser?.config.managedLocation.includes(location.id) ||
          !location.managed
        );
      });
  }

  get SelectedAddress() {
    return companyModule.SelectedAddress;
  }

  get Company() {
    return companyModule.Company;
  }

  get Loading() {
    return companyModule.Loading;
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Search() {
    return companyModule.SearchTextLocation;
  }

  set Search(search: string) {
    companyModule.setSearchTextLocation(search);
  }

  get IsOrgAdmin() {
    return profileModule.IsOrgAdmin;
  }
}
