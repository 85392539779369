








































































































import { Component, Vue } from 'vue-property-decorator';
import RdmMessage from '@/components/common/alert/RdmMessage.vue';
import { Unit } from '@/api/inventory/InventoryModels';
import {
  ageStringRules,
  optionalDescriptionLimitRules,
  requiredFieldRules,
} from '@/utils/validation-rules';
import _ from 'lodash';
import faker from 'faker';
import { constants } from '@/utils/constants';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { httpModule } from '@/store/modules/moduleHttp';
import { handleKeyDownOnlyWholeNumeric } from '@/utils/helpers';

@Component({
  components: {
    RdmMessage,
  },
})
export default class AddTravelerType extends Vue {
  private minAge = '';
  private maxAge = '';
  private saving = false;
  private unsavedChangesWarning = false;
  // private hasMaxAge = true;
  private travelerType: Unit = {} as Unit;
  private baseTravelerType: Unit = {} as Unit;
  private requiredFieldRules = requiredFieldRules;
  private ageStringRules = ageStringRules;
  private descriptionLimitRules = optionalDescriptionLimitRules;
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;
  private descriptionLimit = 1000;
  private descriptionPlaceholder = 'Description';

  tryCloseModal() {
    if (this.formChanges) {
      this.unsavedChangesWarning = true;
      return;
    }

    this.proceedUnsaved();
  }

  mounted() {
    this.$root.$on(constants.events.CREATE_TRAVELER_TYPE, () => {
      travelerTypeModule.setCreateTravelerTypeAgeRangeState(false);
      this.validateForm();
    });
  }

  destroy() {
    this.$root.$off(constants.events.CREATE_TRAVELER_TYPE);
  }

  saveForm() {
    try {
      this.saving = true;
      this.travelerType.id = faker.datatype.uuid();

      this.travelerType.minAge = _.round(_.toNumber(this.minAge));
      this.travelerType.maxAge = _.round(_.toNumber(this.maxAge));
      httpModule
        .updateTravelerTypes(this.travelerType)
        .then()
        .finally(() => {
          this.saving = false;
          this.$emit('successful-create');
        });
    } catch {
      this.saving = false;
      // todo send alert
    }
  }

  validateAgeRange() {
    travelerTypeModule.setCreateTravelerTypeAgeRangeState(
      Number.parseInt(this.minAge) >= Number.parseInt(this.maxAge),
    );
    if (this.$refs && this.$refs.travelerTypeForm) {
      const { travelerTypeForm } = this.$refs as any;
      travelerTypeForm.validate();
    }
  }

  validateForm() {
    if (this.$refs && this.$refs.travelerTypeForm) {
      const { travelerTypeForm } = this.$refs as any;
      const valid = travelerTypeForm.validate();
      if (valid) {
        this.saveForm();
      }
    }
  }

  private proceedUnsaved() {
    this.unsavedChangesWarning = false;
    // if (this.addGuestTypeDialog) this.addGuestTypeDialog = false;
    // if (this.addTravelerTypeDialog) this.addTravelerTypeDialog = false;
    this.$emit('close-modal');
  }

  get formChanges() {
    return !_.isEqual(this.travelerType, this.baseTravelerType);
  }

  get AgeClassifications() {
    return travelerTypeModule.AgeClassifications;
  }

  get CreateTravelerTypeFormState(): boolean {
    return travelerTypeModule.CreateTravelerTypeFormState;
  }

  set CreateTravelerTypeFormState(state: boolean) {
    travelerTypeModule.setCreateTravelerTypeFormState(state);
  }

  get CreateTravelerTypeAgeRangeState(): boolean {
    return travelerTypeModule.CreateTravelerTypeAgeRangeState;
  }

  set CreateTravelerTypeAgeRangeState(state: boolean) {
    travelerTypeModule.setCreateTravelerTypeAgeRangeState(state);
  }
}
