import {
  AvailabilityStatus,
  AvailabilityType,
  CapacityType,
  TimeEntryMode,
} from '@/api/inventory/InventoryModels';
import {
  BookingCutoffOptions,
  CancellationPolicyOptions,
  RefundablePolicyOptions,
} from '@/models';
import { getErrorText } from '@/utils/helpers';
import _ from 'lodash';

export const inventoryTypeText = function (value: string) {
  if (value?.toUpperCase() === AvailabilityType.OpeningHours) {
    return 'Always available to sell';
  }
  if (value?.toUpperCase() === AvailabilityType.StartTime) {
    return 'Limited capacity and may sell out';
  }
  return value;
};

export const inventoryTypeLabel = function (value: string) {
  if (value?.toUpperCase() === AvailabilityType.OpeningHours) {
    return 'Freesale';
  }
  if (value?.toUpperCase() === AvailabilityType.StartTime) {
    return 'Limited';
  }
  return value;
};

export const capacityTypeText = function (value: string) {
  if (value?.toUpperCase() === CapacityType.CapacityLimitUnlimited.toUpperCase()) {
    return 'Always available to sell';
  }
  if (value?.toUpperCase() === CapacityType.CapacityLimitPooled.toUpperCase()) {
    return 'Limited capacity and may sell out';
  }
  if (value?.toUpperCase() === CapacityType.CapacityLimitByUnit.toUpperCase()) {
    return 'Limited capacity and may sell out';
  }
  return value;
};

export const capacityTypeHint = function (value: string) {
  if (value?.toUpperCase() === CapacityType.CapacityLimitUnlimited.toUpperCase()) {
    return '';
  }
  if (value?.toUpperCase() === CapacityType.CapacityLimitPooled.toUpperCase()) {
    return 'Capacity not divided by traveler type';
  }
  if (value?.toUpperCase() === CapacityType.CapacityLimitByUnit.toUpperCase()) {
    return 'Specific capacity per traveler type';
  }
  return value;
};

export const capacityTypeLabel = function (value: string) {
  if (value?.toUpperCase() === CapacityType.CapacityLimitUnlimited.toUpperCase()) {
    return 'Freesale';
  }
  if (value?.toUpperCase() === CapacityType.CapacityLimitPooled.toUpperCase()) {
    return 'Limited - Pooled';
  }
  if (value?.toUpperCase() === CapacityType.CapacityLimitByUnit.toUpperCase()) {
    return 'Limited - Per Traveler Type';
  }
  return value;
};

export const availabilityStatusLabel = function (value: number) {
  if (value === AvailabilityStatus.Freesale) {
    return 'Freesale';
  }
  if (value === AvailabilityStatus.Available) {
    return 'Limited - Pooled';
  }
  if (value === AvailabilityStatus.Limited) {
    return 'Limited - Per Traveler Type';
  }
  return value;
};

export const availabilityStatusText = function (value: number) {
  if (value === AvailabilityStatus.Freesale) {
    return 'Always available to sell';
  }
  if (value === AvailabilityStatus.Available) {
    return 'Limited capacity and may sell out\nCapacity not divided by traveler type';
  }
  if (value === AvailabilityStatus.Limited) {
    return 'Limited capacity and may sell out\nSpecific capacity per traveler type';
  }
  return value;
};

export const timeEntryModeLabel = function (value: string) {
  if (value === TimeEntryMode.Grouped) {
    return 'Grouped Days';
  }
  if (value === TimeEntryMode.Individual) {
    return 'Individual Days';
  }
  return '';
};

export const timeEntryModeText = function (value: string) {
  if (value === TimeEntryMode.Grouped) {
    return 'Times apply to a group of one or more days of the week';
  }
  if (value === TimeEntryMode.Individual) {
    return 'Times apply to separate days of the week';
  }
  if (value === TimeEntryMode.Without) {
    return 'My product doesn’t have a schedule';
  }
  return '';
};

export const cancellationTypeText = function (value: number): string {
  if (value === CancellationPolicyOptions.YES) {
    return 'Cancellation can be done at anytime';
  }
  if (value === CancellationPolicyOptions.NO) {
    return 'Cancellation not allowed';
  }
  return 'Unknown';
};

export const cancellationTypeLabel = function (value: number): string {
  if (value === CancellationPolicyOptions.YES) {
    return 'Yes';
  }
  if (value === CancellationPolicyOptions.NO) {
    return 'No';
  }
  return 'Unknown';
};

export const refundTypeText = function (value: number): string {
  if (value === RefundablePolicyOptions.YES) {
    return 'Can be refunded after purchase';
  }
  if (value === RefundablePolicyOptions.NO) {
    return 'No refunds for this product';
  }
  return 'Unknown';
};

export const refundTypeLabel = function (value: number): string {
  if (value === RefundablePolicyOptions.YES) {
    return 'Yes';
  }
  if (value === RefundablePolicyOptions.NO) {
    return 'No';
  }
  return 'Unknown';
};

export const bookingCutoffText = function (value: number): string {
  if (value === BookingCutoffOptions.NO) {
    return 'No booking cutoff';
  }
  if (value === BookingCutoffOptions.YES) {
    return 'Cutoff before';
  }

  return 'Unknown';
};

export const bookingCutoffLabel = function (value: number): string {
  if (value === BookingCutoffOptions.NO) {
    return 'No';
  }
  if (value === BookingCutoffOptions.YES) {
    return 'Yes';
  }
  return 'Unknown';
};

export const errorStatusToMessage = function (value: string): string {
  if (value) {
    const val = _.toNumber(value);
    return getErrorText(val);
  }
  return 'Unknown';
};
