






























































































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import moment from 'moment';
import {
  AvailabilityColorMapping,
  AvailabilitySchedule,
} from '@/api/inventory/InventoryModels';
import _ from 'lodash';
import { constants } from '@/utils/constants';
import { optionModule } from '@/store/modules/moduleOption';

@Component({
  components: {},
  props: {
    option: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class ProductCalendarSummary extends Vue {
  private weekday = [0, 1, 2, 3, 4, 5, 6];
  private type = 'week' as 'month' | 'week' | 'day';
  private events: any[] = [];
  private calendarValue = '';
  private mode = 'stack';
  private hourCount = 24;
  private firstTime = '00:00';
  private lastTime = '23:59';
  private hoverElements = [] as any[];
  private menu = false;

  mounted() {
    this.setToday();
    setTimeout(() => {
      this.getEvents();
    }, 2000);
  }

  private navigateToInventory() {
    this.$router.push({ path: '/prices/inventory' }).catch(() => {});
  }

  getEvents() {
    if (!moment(this.calendarValue).isValid()) {
      return;
    }
    this.events = [];
    this.firstTime = '00:00';
    this.hourCount = 24;
    if (this.type === 'week') {
      this.getWeekEvents();
    } else if (this.type === 'month') {
      this.getMonthEvents();
    } else if (this.type === 'day') {
      this.getDailyEvents();
    }
  }

  getWeekEvents() {
    const startWeek = moment(this.calendarValue).startOf('week');
    const endWeek = moment(this.calendarValue).endOf('week');
    const numDays = endWeek.diff(startWeek, 'days');

    this.createDayEvent(startWeek.format());

    for (let i = 0; i < numDays; i++) {
      this.createDayEvent(startWeek.add(1, 'days').format());
    }
  }

  getMonthEvents() {
    const startMonth = moment(this.calendarValue).startOf('month');
    const endMonth = moment(this.calendarValue).endOf('month');
    const numDays = endMonth.diff(startMonth, 'days');

    this.createDayEvent(startMonth.format());

    for (let i = 0; i < numDays; i++) {
      this.createDayEvent(startMonth.add(1, 'days').format());
    }
  }

  getDailyEvents() {
    const today = moment(this.calendarValue).startOf('day');
    this.createDayEvent(today.format());
  }

  hoverEvent({ event }: any) {
    if (event && _.isBoolean(event.display)) {
      event.display = true;
    }
  }

  hoverExit({ event }: any) {
    if (event && _.isBoolean(event.display)) {
      event.display = false;
    }
  }

  createDayEvent(startDate: string) {
    // let firstTime = '23:59';
    // let lastTime = '09:00';
    this.ScheduleViews.forEach((scheduleView) => {
      const color =
        this.ScheduleColorMappings.find((mapping) => mapping.id === scheduleView.id) ||
        ({ id: '', color: '#489DCB' } as AvailabilityColorMapping);
      scheduleView.schedules.forEach((schedule: AvailabilitySchedule) => {
        if (!schedule.startTime && !schedule.endTime) {
          return;
        }
        schedule?.daysOfWeek?.forEach((day: string) => {
          const selectedDay = moment(startDate);
          const selectedDayNumber = selectedDay.day();
          const scheduleDay = moment().day(day);
          if (scheduleDay.isValid()) {
            const loopDayNumber = scheduleDay.day();
            if (selectedDayNumber === loopDayNumber) {
              const start = selectedDay.format(
                `YYYY-MM-DDT${schedule.startTime || '00:00'}:00`,
              );
              const end = selectedDay.format(
                `YYYY-MM-DDT${schedule.endTime || '23:59'}:00`,
              );
              const validStart = schedule.validFrom;
              const validEnd = schedule.validUntil;
              if (moment(start).isBefore(validStart) || moment(start).isAfter(validEnd)) {
                return;
              }

              // todo removing first and last time check until logic is reworked
              // const firstTimeMoment = selectedDay.format(
              //   `YYYY-MM-DDT${firstTime || '00:00'}:00`,
              // );
              //
              // const lastTimeMoment = selectedDay.format(
              //   `YYYY-MM-DDT${lastTime || '23:59'}:00`,
              // );
              //
              // if (moment(start).isBefore(firstTimeMoment)) {
              //   firstTime = schedule.startTime || '00:00';
              //   this.firstTime = firstTime;
              // }
              //
              // if (moment(end).isAfter(lastTimeMoment)) {
              //   lastTime = schedule.endTime || '23:59';
              //   this.lastTime = lastTime;
              // }

              this.events.push({
                name: schedule.name,
                start: start,
                end: end,
                validStart: validStart,
                validEnd: validEnd,
                color: color.color,
                capacity: schedule.maxCapacity || 0,
                timed: true,
                display: false,
              });
            }
          }
        });
      });
    });
    // this.firstTime = firstTime;
    // this.lastTime = lastTime;
    const s = moment().format(`YYYY-MM-DDT${this.firstTime || '00:00'}:00`);
    const e = moment().format(`YYYY-MM-DDT${this.lastTime || '00:00'}:00`);
    const start = moment(s);
    const end = moment(e);
    const diff = end.diff(start, 'hours');
    if (
      _.isNumber(diff) &&
      ((this.hourCount < 24 && this.hourCount < diff) || this.hourCount === 24)
    ) {
      this.hourCount = diff + 1 > 0 ? diff + 1 : 24;
    }
  }

  setToday() {
    this.calendarValue = moment().format('YYYY-MM-DD');
  }

  prev() {
    const calendar = this.$refs.calendar as any;
    if (!_.isUndefined(calendar)) {
      calendar.prev();
    }
  }
  next() {
    const calendar = this.$refs.calendar as any;
    if (!_.isUndefined(calendar)) {
      calendar.next();
    }
  }

  intervalStyling(interval: any) {
    if (interval && interval.past) {
      return '.disabled-date';
    } else if (interval && (interval.weekday === 0 || interval.weekday === 6)) {
      return '.disabled-date';
    }
    return '.disabled-date';
  }

  selectEvent() {
    this.$root.$emit(constants.events.OPEN_FULLSCREEN_CALENDAR);
  }

  get Product() {
    return productModule.Product;
  }

  get Option() {
    return optionModule.Option;
  }

  get AvailabilitySchedules() {
    if (this.$props.option) {
      return productModule.AvailabilitySchedules.filter((availabilitySchedule) => {
        return availabilitySchedule.optionId === this.Option.id;
      });
    }
    return productModule.AvailabilitySchedules.filter((availabilitySchedule) => {
      return availabilitySchedule.productId === this.Product.id;
    });
  }

  get ScheduleViews() {
    if (this.$props.option) {
      return productModule.ScheduleViews.filter(
        (scheduleViews) => scheduleViews.optionId === this.Option.id,
      );
    }
    return productModule.ScheduleViews.filter(
      (scheduleViews) => scheduleViews.productId === this.Product.id,
    );
  }

  get ScheduleColorMappings() {
    return productModule.ScheduleColorMappings;
  }
}
