import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { DayPrices, TravelerPrices, Unit } from '@/api/inventory/InventoryModels';
import { AgeClassification, DisplayUnit } from '@/models';
import _ from 'lodash';
import { appModule } from '@/store/modules/moduleApp';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-traveler-type',
  namespaced: true,
})
class ModuleTravelerType extends VuexModule {
  /* Data */
  private loading = false;
  private createTravelerTypeFormState = false;
  private createTravelerTypeAgeRangeState = false;
  private travelerTypes = [] as Array<Unit>;
  private displayTravelerTypes = [] as Array<DisplayUnit>;
  private travelerPrices = { travelers: [] } as TravelerPrices;
  private ageClassifications = [] as Array<string>;
  private selectedTravelerType = {} as Unit;
  private travelerTypeDetailValid = false;
  private travelerTypeUnitTypeValid = false;
  private travelerTypeAgeRangeValid = false;
  private travelerTypeDescriptionValid = false;
  private editModeDetails = false;
  private editModeUnitType = false;
  private editModeAgeRange = false;
  private editModeDescription = false;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get AgeClassifications(): Array<string> {
    return this.ageClassifications;
  }

  get CreateTravelerTypeFormState(): boolean {
    return this.createTravelerTypeFormState;
  }

  get CreateTravelerTypeAgeRangeState(): boolean {
    return this.createTravelerTypeAgeRangeState;
  }

  get TravelerTypes(): Array<Unit> {
    return this.travelerTypes;
  }

  get TravelerPrices(): TravelerPrices {
    return this.travelerPrices;
  }

  get DisplayTravelerTypes(): Array<DisplayUnit> {
    return this.displayTravelerTypes;
  }

  get SelectedTravelerType(): Unit {
    return this.selectedTravelerType;
  }

  get EditModeDetails(): boolean {
    return this.editModeDetails;
  }

  get EditModeUnitType(): boolean {
    return this.editModeUnitType;
  }

  get EditModeAgeRange(): boolean {
    return this.editModeAgeRange;
  }

  get EditModeDescription(): boolean {
    return this.editModeDescription;
  }

  get TravelerTypeDetailValid(): boolean {
    return this.travelerTypeDetailValid;
  }

  get TravelerTypeUnitTypeValid(): boolean {
    return this.travelerTypeUnitTypeValid;
  }

  get TravelerTypeAgeRangeValid(): boolean {
    return this.travelerTypeAgeRangeValid;
  }

  get TravelerTypeDescriptionValid(): boolean {
    return this.travelerTypeDescriptionValid;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setCreateTravelerTypeFormState(state: boolean) {
    this.createTravelerTypeFormState = state;
  }

  @Mutation
  setCreateTravelerTypeAgeRangeState(state: boolean) {
    this.createTravelerTypeAgeRangeState = state;
  }

  @Mutation
  setAgeClassification(classifications: Array<AgeClassification>) {
    this.ageClassifications = classifications;
  }

  @Mutation
  appendAgeClassification(classification: string) {
    this.ageClassifications.push(classification);
  }

  @Mutation
  setTravelerTypes(travelerTypes: Array<Unit>) {
    this.travelerTypes = travelerTypes;
  }

  @Mutation
  appendTravelerTypes(travelerTypes: Unit) {
    this.travelerTypes.push(travelerTypes);
  }

  @Mutation
  setDisplayTravelerTypes(displayTravelerTypes: Array<DisplayUnit>) {
    this.displayTravelerTypes = displayTravelerTypes;
  }

  @Mutation
  setSelectedTravelerType(travelerType: Unit) {
    this.selectedTravelerType = travelerType;
  }

  @Mutation
  setTravelerPrices(prices: TravelerPrices) {
    this.travelerPrices = prices;
  }

  @Mutation
  setEditModeDetails(mode: boolean) {
    this.editModeDetails = mode;
  }

  @Mutation
  setEditModeUnitType(mode: boolean) {
    this.editModeUnitType = mode;
  }

  @Mutation
  setEditModeAgeRange(mode: boolean) {
    this.editModeAgeRange = mode;
  }

  @Mutation
  setEditModeDescription(mode: boolean) {
    this.editModeDescription = mode;
  }

  @Mutation
  setTravelerTypeDetailValid(valid: boolean) {
    this.travelerTypeDetailValid = valid;
  }

  @Mutation
  setTravelerTypeUnitTypeValid(valid: boolean) {
    this.travelerTypeUnitTypeValid = valid;
  }

  @Mutation
  setTravelerTypeAgeRangeValid(valid: boolean) {
    this.travelerTypeAgeRangeValid = valid;
  }

  @Mutation
  setTravelerTypeDescriptionValid(valid: boolean) {
    this.travelerTypeDescriptionValid = valid;
  }

  /* Actions */
  @Action
  configAgeClassification() {
    if (appModule.Codes.travellerTypes && appModule.Codes.travellerTypes.length > 0) {
      appModule.Codes.travellerTypes.forEach((travelerTypeCode) => {
        this.appendAgeClassification(travelerTypeCode.value);
      });
      return;
    }
    _.entries(AgeClassification).forEach((age) => {
      this.appendAgeClassification(age[0] as AgeClassification);
    });
  }

  @Action
  updateTravelerType(travelerType: Unit) {
    const travelers = _.cloneDeep(this.TravelerTypes);
    const index = travelers.findIndex((t) => t.id === travelerType.id);
    if (index > -1) {
      travelers[index] = travelerType;
      this.setTravelerTypes(travelers);
    } else {
      travelers.push(travelerType);
      this.setTravelerTypes(travelers);
    }
  }

  @Action
  removeTravelerType(id: string) {
    const travelers = _.cloneDeep(this.TravelerTypes);
    const index = travelers.findIndex((t) => t.id === id);
    if (index > -1) {
      travelers.splice(index, 1);
      this.setTravelerTypes(travelers);
    }
  }

  @Action
  selectTravelerTypeById(id: string) {
    const travelers = _.cloneDeep(this.TravelerTypes);
    const index = travelers.findIndex((t) => t.id === id);

    if (index > -1) {
      this.setSelectedTravelerType(travelers[index]);
    }
  }

  @Action
  configTravelerPrices(travelerTypes: Array<Unit>) {
    const units = travelerTypes.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
    this.setTravelerTypes(units);
    const displayTravelers = [] as Array<DisplayUnit>;
    units.forEach((unit) => {
      displayTravelers.push({ value: unit.id || '', text: `${unit.internalName || ''} (${unit.type || ''})` });
    });
    this.setDisplayTravelerTypes(displayTravelers);

    this.setTravelerPrices({ travelers: [] });
    const unitCount = travelerTypes.length;
    for (let i = 0; i < unitCount; i++) {
      this.travelerPrices.travelers.push({
        sunday: {},
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        default: {},
      } as DayPrices);
    }
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setCreateTravelerTypeFormState(false);
    this.setCreateTravelerTypeAgeRangeState(false);
    this.setTravelerTypes([] as Array<Unit>);
    this.setSelectedTravelerType({} as Unit);
    this.setAgeClassification([] as Array<AgeClassification>);
  }
}

export const travelerTypeModule = getModule(ModuleTravelerType, store);
