





















































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { channelModule } from '@/store/modules/moduleChannel';
import { constants } from '@/utils/constants';
import { httpModule } from '@/store/modules/moduleHttp';
import { RateCard } from '@/models';
import { incrementVersion, dollarsToCents } from '@/utils/helpers';

@Component({
  components: {
    RdmCollapsableControlCard,
  },
})
export default class RateCardCreatePriceAdjustments extends Vue {
  private rateCard = {} as RateCard;
  private calculationTypeFlat = 'FLAT';

  get Loading() {
    return channelModule.Loading;
  }

  get PriceAdjustmentsIsOpen() {
    return channelModule.FormPriceAdjustmentsState;
  }

  get CreateRateCardFormState() {
    return channelModule.CreateRateCardFormState;
  }

  set CreateRateCardFormState(state: boolean) {
    channelModule.setCreateRateCardFormState(state);
  }

  get AdjustForm(): any {
    if (channelModule.CreateRateCardAdjustPriceForm) {
      return channelModule.CreateRateCardAdjustPriceForm;
    }
    return {
      source: '',
      destination: '',
      calculationType: '',
      value: 0,
    };
  }

  discard() {
    channelModule.setCreateRateCardAdjustPriceForm({});
    channelModule.setCreateRateCardDetailsForm({});
    this.$router.push({ name: constants.routes.CHANNEL_RATE_CARDS });
  }

  async nextStepValidate() {
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      const value =
        channelModule.CreateRateCardAdjustPriceForm.calculationType ===
        this.calculationTypeFlat
          ? dollarsToCents(Number(channelModule.CreateRateCardAdjustPriceForm.value))
          : (100 + Number(channelModule.CreateRateCardAdjustPriceForm.value)) / 100;
      if (isValid) {
        this.rateCard = {
          ...channelModule.RateCard,
          adjustment: {
            ...channelModule.CreateRateCardAdjustPriceForm,
            value: value,
          },
          version: incrementVersion(channelModule.RateCard.meta),
        };
        await httpModule.updateRateCard(this.rateCard);
        channelModule.setCreateRateCardAdjustPriceForm(this.AdjustForm);
        channelModule.setFormPriceAdjustmentsState(false);
        channelModule.setFormConnectChanelState(true);
      }
    }
  }

  togglePriceAdjustmentsOpen() {
    channelModule.setFormPriceAdjustmentsState(!this.PriceAdjustmentsIsOpen);
  }
}
