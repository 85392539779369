






















































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { channelModule } from '@/store/modules/moduleChannel';
import { httpModule } from '@/store/modules/moduleHttp';
import { ChannelBinding } from '@/models/ModelsOppInventory';

@Component
export default class ChannelDetails extends Vue {
  private backButtonText = 'Back To Connected Channels';
  private productsHeaders: Array<Record<string, any>> = [
    { text: 'Product', align: 'left', sortable: true, value: 'product' },
    { text: 'Supplier Name', align: 'left', sortable: true, value: 'supplier' },
    { text: '', value: 'action', align: 'right', sortable: false },
  ];
  async mounted() {
    await httpModule.getCompanyChannelsBindings();
  }

  get ConnectedChannels() {
    return channelModule.ConnectedChannels.filter(
      (item: ChannelBinding) => item.channel.id === this.$route.params.id,
    );
  }

  get ChannelName() {
    return this.ConnectedChannels.length ? this.ConnectedChannels[0].channel.name : '';
  }

  navigateBack() {
    if (this.$route.params.isProduct === 'true') {
      this.$router
        .push({
          name: constants.routes.PRODUCT_DETAILS,
          params: { id: this.$route.params.id },
        })
        .catch(() => {});
    } else {
      this.$router.push({ name: constants.routes.CHANNEL_LIST }).catch(() => {});
    }
  }

  // removeBinding(binding: ChannelBinding) {
  //   const rq: ChannelBindingRQ = {
  //     mode: ChannelBindingMode.DELETE,
  //     channelID: binding.channel.id,
  //     channelName: binding.channel.name,
  //     supplierID: binding.supplier.id,
  //     productID: binding.product.id,
  //     rateIDs: [],
  //     rateCardID: faker.datatype.uuid(),
  //     validFrom: '',
  //     validUntil: '',
  //   };
  //   channelModule.deleteChannelBinding(rq);
  // }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Loading() {
    return channelModule.Loading;
  }

  get Channel() {
    return channelModule.Channel;
  }
}
