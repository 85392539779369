import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { BookingsReport } from '@/models/Report';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-report',
  namespaced: true,
})
class ModuleReport extends VuexModule {
  /* Data */
  private loading = false;

  private reports: BookingsReport = {
    totalBookings: 0,
    totalRedemptions: 0,
    totalNetSales: 0,
    totalGrossSales: 0,
    items: [],
  };

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get Reports(): BookingsReport {
    return this.reports;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setReports(reports: BookingsReport) {
    this.reports = reports;
  }

  /* Actions */
  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
  }

  @Action
  setReportData(reports: BookingsReport) {
    this.setReports(reports);
  }
}

export const reportModule = getModule(ModuleReport, store);
