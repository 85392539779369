



































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { GetAgeBandByValue } from '@/utils/web-redemption';
import WebRedemptionModule from '@/store/modules/.deprecated/moduleWebRedemption';

@Component
export default class VoucherInfo extends Vue {
  @Prop({ type: Object, required: true })
  public data!: any;
  private notSpecified = '[not specified]';

  private webRedemption = getModule(WebRedemptionModule);

  private getAgeBand(v: number) {
    return GetAgeBandByValue(Number(v));
  }
}
