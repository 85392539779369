









































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { RegistrationModel } from '@/models';
import router from '@/router';
import { AsYouType } from 'libphonenumber-js';
import {
  companyNameRules,
  firstNameRules,
  lastNameRules,
  emailRules,
  phoneRules,
  urlRules,
  ticketingSystemRules,
  optionalPhoneRules,
  contactRules,
  streetRules,
  cityRules,
  stateRules,
  countryRules,
  zipRules,
  phoneCountryCodeRules,
} from '@/utils/validation-rules';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { countryData } from '@/app-codes/countryData';

declare let google: any;

@Component
export default class Registration extends Vue {
  private isAgree = false;
  private valid = true;
  private registration: RegistrationModel = new RegistrationModel();
  private companyNameRules = companyNameRules;
  private firstNameRules = firstNameRules;
  private lastNameRules = lastNameRules;
  private emailRules = emailRules;
  private phoneRules = phoneRules;
  private optionalPhoneRules = optionalPhoneRules;
  private urlRules = urlRules;
  private ticketingSystemRules = ticketingSystemRules;
  private contactMethodRules = contactRules;
  private streetRules = streetRules;
  private cityRules = cityRules;
  private stateRules = stateRules;
  private countryRules = countryRules;
  private zipRules = zipRules;
  private phoneCountryCode = phoneCountryCodeRules;
  private states: any = require('@/assets/mocks/us_states.json');
  private countries: any = countryData;
  private preselectedCountry = 'US';
  private citySearch: any;
  private registeredStreetAddressElement: any;
  private autocomplete: any;
  private intPhone = '';
  // private asYouType: any;

  mounted() {
    this.registration.address.country = this.preselectedCountry;

    /* Google maps places autocomplete*/
    this.initAutocomplete();
    this.geolocate();
  }

  submitForm() {
    // Map registration data elements
    // submit to backend
    // ajaxPost(`${process.env.VUE_APP_AGG_HOST}/register`, this.registration).subscribe((response: any) => {
    //     router.push('requested');
    // }, error => {});
    router.push('requested');
  }

  initAutocomplete() {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    const autoComplete = document.getElementById(
      'registeredStreetAddress',
    ) as HTMLInputElement;
    if (autoComplete) {
      this.autocomplete = new google.maps.places.Autocomplete(autoComplete, {
        types: ['geocode'],
      });

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      this.autocomplete.setFields(['address_component']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    }
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    const componentForm: { [index: string]: any } = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
    };

    for (const component in componentForm) {
      const comp = document.getElementById(component) as HTMLInputElement;
      if (comp) {
        comp.value = '';
        comp.disabled = false;
      }
    }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    let streetNumber = '';
    let streetName = '';
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];

      if (addressType === 'street_number') {
        streetNumber = place.address_components[i][componentForm[addressType]];
      } else if (addressType === 'route') {
        streetName = place.address_components[i][componentForm[addressType]];
      } else if (addressType === 'locality') {
        this.registration.address.city =
          place.address_components[i][componentForm[addressType]];
      } else if (addressType === 'administrative_area_level_1') {
        this.registration.address.state =
          place.address_components[i][componentForm[addressType]];
      } else if (addressType === 'country') {
        // assigning country code from short_name if available
        if (place.address_components[i]['short_name']) {
          this.registration.address.country = place.address_components[i]['short_name'];
        } else {
          this.registration.address.country =
            place.address_components[i][componentForm[addressType]];
        }
      } else if (addressType === 'postal_code') {
        this.registration.address.zip =
          place.address_components[i][componentForm[addressType]];
      }
    }

    this.registration.address.street1 = streetNumber + ' ' + streetName;
  }

  geolocate() {
    const that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });

        that.autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  validateForm() {
    // Validate form is elements
    if (this.$refs && this.$refs.registrationForm) {
      const registrationForm: any = this.$refs.registrationForm;
      registrationForm.validate();
    }

    if (this.isAgree && this.valid) {
      this.submitForm();
    }
  }

  prependPlus(input: string): string {
    if (input.indexOf('+') > -1) {
      return input;
    }
    return input.replace(/^/, '+');
  }

  phoneInitialChar(event: any) {
    if (event && event.target && event.target.value) {
      // event.target.value = this.prependPlus(event.target.value);
    }
  }

  phoneKeyUp(event: any) {
    const char = event.which || event.keyCode;
    if (char > 31 && (char < 48 || char > 57)) {
      event.preventDefault();
    }
  }

  phoneCountryCodeValidator(event: any) {
    const char = event.which || event.keyCode;
    if (char > 31 && (char < 48 || char > 57)) {
      event.preventDefault();
    }
  }

  phoneValidation(event: any) {
    this.phoneInitialChar(event);
    const formattedNumber = new AsYouType();
    formattedNumber.input(this.registration.phoneNumber.phoneNumber);
  }
}
