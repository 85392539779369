

























































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { channelModule } from '@/store/modules/moduleChannel';
import { tablePaginationOptions } from '@/utils/helpers';
import { constants } from '@/utils/constants';
import { httpModule } from '@/store/modules/moduleHttp';
import { ChannelBindingMode, ChannelBindingRQ } from '@/models';
import {
  constructBindingRequests,
  filterChannelBindingRequestsByMode,
} from '@/utils/channelUtils';
import { Channel } from '@/models/ChannelModels';
import _ from 'lodash';

@Component({
  components: {
    RdmCollapsableControlCard,
  },
})
export default class RateCardCreateConnectChannels extends Vue {
  private selectedChannels = [] as Channel[];
  private bindingRequests = [] as ChannelBindingRQ[];
  private agreement = false;
  private rateCardChannelsHeaders: Array<Record<string, any>> = [
    {
      text: 'Channel Name',
      align: 'left',
      filterable: false,
      value: 'name',
    },
    { text: 'Channel ID', align: 'left', sortable: true, value: 'id' },
  ];

  get Loading() {
    return channelModule.Loading;
  }

  get RateCard() {
    return channelModule.RateCard;
  }

  get ChannelBindings() {
    return channelModule.ConnectedChannels;
  }

  get RateCardSpr() {
    return channelModule.CreateCompanyRates;
  }
  get AvailableChannels() {
    return channelModule.AvailableChannels;
  }

  get ConnectChannelIsOpen() {
    return channelModule.FromConnectChannelState;
  }

  get CreateRateCardFormState() {
    return channelModule.CreateRateCardFormState;
  }

  set CreateRateCardFormState(state: boolean) {
    channelModule.setCreateRateCardFormState(state);
  }

  get paginationOptions() {
    return tablePaginationOptions(this.AvailableChannels);
  }

  private async mounted() {
    await httpModule.getCompanyChannels();
    await httpModule.getCompanyChannelsBindings();
  }

  destroyed() {
    channelModule.setFormConnectChanelState(false);
    channelModule.clearState();
    this.agreement = false;
    this.selectedChannels = [];
  }

  saveAndExit() {
    channelModule.setFormDetailsState(false);
    channelModule.setFormConnectChanelState(false);
    this.$router.push({ name: constants.routes.CHANNEL_RATE_CARDS });
  }
  /**
   * connectChannels
   * - iterate through and create any channel bindings
   * - iterate through and update any channel bindings
   */
  async connectChannels() {
    this.selectedChannels.map(async (channel: Channel) => {
      this.bindingRequests = constructBindingRequests(
        this.RateCard,
        this.RateCardSpr,
        this.ChannelBindings,
        channel,
      );
      // create any bindings that need to be created
      const createRQs = filterChannelBindingRequestsByMode(
        this.bindingRequests,
        ChannelBindingMode.CREATE,
      );
      for (const binding of createRQs) {
        await channelModule.createChannelBinding(binding);
      }

      // update any bindings that need to be updated
      const updateRQs = filterChannelBindingRequestsByMode(
        this.bindingRequests,
        ChannelBindingMode.UPDATE,
      );
      for (const binding of updateRQs) {
        await channelModule.updateChannelBinding(binding);
      }
    });
    _.delay(async () => {
      await httpModule.publishRateCard(this.RateCard.id);
      await this.$router.push({
        name: constants.routes.CHANNEL_RATE_CARD_VIEW,
        params: { id: channelModule.RateCard.id },
      });
    }, 3000);
  }

  toggleConnectChannelOpen() {
    channelModule.setFormConnectChanelState(!this.ConnectChannelIsOpen);
  }
}
