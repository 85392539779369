




















































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { httpModule } from '@/store/modules/moduleHttp';
import { offerModule } from '@/store/modules/moduleOffer';
import { productModule } from '@/store/modules/moduleProduct';
import { tablePaginationOptions } from '@/utils/helpers';
import { Offer } from '@/models';

@Component
export default class ProductViewOffer extends Vue {
  private routeOfferList = constants.routes.OFFER_LIST;
  private routeOfferView = constants.routes.OFFER_EDIT;
  private offerHeaders: Array<Record<string, any>> = [
    {
      text: 'Offer',
      value: 'id',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];

  get Offers() {
    return offerModule.Offers.filter((item) => item.productId === this.Product.id);
  }

  get Product() {
    return productModule.Product;
  }

  get paginationOptions() {
    return tablePaginationOptions(this.Offers);
  }

  get Loading(): boolean {
    return offerModule.Loading;
  }

  async mounted() {
    await httpModule.getOffers();
  }

  getOfferRoute(offer: Offer) {
    return { name: this.routeOfferView, params: { id: offer.id } };
  }
}
