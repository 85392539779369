















import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { pricingModule } from '@/store/modules/modulePricing';

@Component
export default class AvailabilityStatusGetStarted extends Vue {
  private navigateToNewPrice() {
    this.$router.push({ name: constants.routes.PRICING_CREATE }).catch(() => {});
  }

  private navigateToNewInventory() {
    this.$router.push({ name: constants.routes.INVENTORY_MANAGEMENT }).catch(() => {});
  }

  get HasPricing() {
    return pricingModule.Pricing.length > 0;
  }

  get HasInventory() {
    // return pricingModule.
    return true;
  }

  get GetStartedMessage() {
    if (!this.HasPricing && !this.HasInventory) {
      return constants.messages.AVAILABILITY_STATUS_GETTING_STARTED_NO_PRICE_INVENTORY;
    } else if (!this.HasPricing) {
      return constants.messages.AVAILABILITY_STATUS_GETTING_STARTED_NO_PRICE;
    } else if (!this.HasInventory) {
      return constants.messages.AVAILABILITY_STATUS_GETTING_STARTED_NO_INVENTORY;
    }
    return '';
  }
}
