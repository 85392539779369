const data = [
  {
    name: 'Afghanistan',
    code: 'AFG',
    capital: 'Kabul',
    region: 'AS',
    currency: {
      code: 'AFN',
      name: 'Afghan afghani',
      symbol: '؋',
    },
    language: {
      code: 'ps',
      name: 'Pashto',
    },
    flag: 'https://restcountries.eu/data/afg.svg',
    callingCode: '+93',
    isoCode4217: '004',
    timezones: [
      {
        name: 'Kabul',
        countryCode: 'AF',
        value: 'Asia/Kabul',
        offset: {
          standard: '+04:30',
          daylightSaving: '+04:30',
        },
        abbreviation: {
          standard: '+0430',
          daylightSaving: '+0430',
        },
        countryCodeIso: 'AFG',
      },
    ],
  },
  {
    name: 'Albania',
    code: 'ALB',
    capital: 'Tirana',
    region: 'EU',
    currency: {
      code: 'ALL',
      name: 'Albanian lek',
      symbol: 'L',
    },
    language: {
      code: 'sq',
      name: 'Albanian',
    },
    flag: 'https://restcountries.eu/data/alb.svg',
    callingCode: '+355',
    isoCode4217: '008',
    timezones: [
      {
        name: 'Tirane',
        countryCode: 'AL',
        value: 'Europe/Tirane',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'ALB',
      },
    ],
  },
  {
    name: 'Algeria',
    code: 'DZA',
    capital: 'Algiers',
    region: 'AF',
    currency: {
      code: 'DZD',
      name: 'Algerian dinar',
      symbol: 'د.ج',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/dza.svg',
    callingCode: '+213',
    isoCode4217: '012',
    timezones: [
      {
        name: 'Algiers',
        countryCode: 'DZ',
        value: 'Africa/Algiers',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CET',
        },
        countryCodeIso: 'DZA',
      },
    ],
  },
  {
    name: 'American Samoa',
    code: 'ASM',
    capital: 'Pago Pago',
    region: 'OC',
    currency: {
      code: 'USD',
      name: 'United State Dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/asm.svg',
    callingCode: '+1',
    isoCode4217: '016',
    timezones: [
      {
        name: 'Pago Pago',
        countryCode: 'AS',
        value: 'Pacific/Pago_Pago',
        offset: {
          standard: '-11:00',
          daylightSaving: '-11:00',
        },
        abbreviation: {
          standard: 'SST',
          daylightSaving: 'SST',
        },
        countryCodeIso: 'ASM',
      },
    ],
  },
  {
    name: 'Andorra',
    code: 'AND',
    capital: 'Andorra la Vella',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'ca',
      name: 'Catalan',
    },
    flag: 'https://restcountries.eu/data/and.svg',
    callingCode: '+376',
    isoCode4217: '020',
    timezones: [
      {
        name: 'Andorra',
        countryCode: 'AD',
        value: 'Europe/Andorra',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'AND',
      },
    ],
  },
  {
    name: 'Angola',
    code: 'AGO',
    capital: 'Luanda',
    region: 'AF',
    currency: {
      code: 'AOA',
      name: 'Angolan kwanza',
      symbol: 'Kz',
    },
    language: {
      code: 'pt',
      name: 'Portuguese',
    },
    flag: 'https://restcountries.eu/data/ago.svg',
    callingCode: '+244',
    isoCode4217: '024',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'AO',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'AGO',
      },
    ],
  },
  {
    name: 'Anguilla',
    code: 'AIA',
    capital: 'The Valley',
    region: 'NA',
    currency: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/aia.svg',
    callingCode: '+43',
    isoCode4217: '660',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'AI',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'AIA',
      },
    ],
  },
  {
    name: 'Antigua and Barbuda',
    code: 'ATG',
    capital: "Saint John's",
    region: 'NA',
    currency: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/atg.svg',
    callingCode: '+1',
    isoCode4217: '028',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'AG',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'ATG',
      },
    ],
  },
  {
    name: 'Argentina',
    code: 'ARG',
    capital: 'Buenos Aires',
    region: 'SA',
    currency: {
      code: 'ARS',
      name: 'Argentine peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/arg.svg',
    callingCode: '+54',
    isoCode4217: '032',
    timezones: [
      {
        name: 'Argentina (Buenos Aires)',
        countryCode: 'AR',
        value: 'America/Argentina/Buenos_Aires',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (Catamarca)',
        countryCode: 'AR',
        value: 'America/Argentina/Catamarca',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (Cordoba)',
        countryCode: 'AR',
        value: 'America/Argentina/Cordoba',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (Jujuy)',
        countryCode: 'AR',
        value: 'America/Argentina/Jujuy',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (La Rioja)',
        countryCode: 'AR',
        value: 'America/Argentina/La_Rioja',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (Mendoza)',
        countryCode: 'AR',
        value: 'America/Argentina/Mendoza',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (Rio Gallegos)',
        countryCode: 'AR',
        value: 'America/Argentina/Rio_Gallegos',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (Salta)',
        countryCode: 'AR',
        value: 'America/Argentina/Salta',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (San Juan)',
        countryCode: 'AR',
        value: 'America/Argentina/San_Juan',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (San Luis)',
        countryCode: 'AR',
        value: 'America/Argentina/San_Luis',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (Tucuman)',
        countryCode: 'AR',
        value: 'America/Argentina/Tucuman',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
      {
        name: 'Argentina (Ushuaia)',
        countryCode: 'AR',
        value: 'America/Argentina/Ushuaia',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'ARG',
      },
    ],
  },
  {
    name: 'Armenia',
    code: 'ARM',
    capital: 'Yerevan',
    region: 'AS',
    currency: {
      code: 'AMD',
      name: 'Armenian dram',
      symbol: null,
    },
    language: {
      code: 'hy',
      name: 'Armenian',
    },
    flag: 'https://restcountries.eu/data/arm.svg',
    callingCode: '+374',
    isoCode4217: '051',
    timezones: [
      {
        name: 'Yerevan',
        countryCode: 'AM',
        value: 'Asia/Yerevan',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'ARM',
      },
    ],
  },
  {
    name: 'Aruba',
    code: 'ABW',
    capital: 'Oranjestad',
    region: 'SA',
    currency: {
      code: 'AWG',
      name: 'Aruban florin',
      symbol: 'ƒ',
    },
    language: {
      code: 'nl',
      name: 'Dutch',
    },
    flag: 'https://restcountries.eu/data/abw.svg',
    callingCode: '+297',
    isoCode4217: '533',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'AW',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'ABW',
      },
    ],
  },
  {
    name: 'Australia',
    code: 'AUS',
    capital: 'Canberra',
    region: 'OC',
    currency: {
      code: 'AUD',
      name: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/aus.svg',
    callingCode: '+61',
    isoCode4217: '036',
    timezones: [
      {
        name: 'Macquarie',
        countryCode: 'AU',
        value: 'Antarctica/Macquarie',
        offset: {
          standard: '+10:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: 'AEST',
          daylightSaving: 'AEDT',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Adelaide',
        countryCode: 'AU',
        value: 'Australia/Adelaide',
        offset: {
          standard: '+09:30',
          daylightSaving: '+10:30',
        },
        abbreviation: {
          standard: 'ACST',
          daylightSaving: 'ACDT',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Brisbane',
        countryCode: 'AU',
        value: 'Australia/Brisbane',
        offset: {
          standard: '+10:00',
          daylightSaving: '+10:00',
        },
        abbreviation: {
          standard: 'AEST',
          daylightSaving: 'AEST',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Broken Hill',
        countryCode: 'AU',
        value: 'Australia/Broken_Hill',
        offset: {
          standard: '+09:30',
          daylightSaving: '+10:30',
        },
        abbreviation: {
          standard: 'ACST',
          daylightSaving: 'ACDT',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Darwin',
        countryCode: 'AU',
        value: 'Australia/Darwin',
        offset: {
          standard: '+09:30',
          daylightSaving: '+09:30',
        },
        abbreviation: {
          standard: 'ACST',
          daylightSaving: 'ACST',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Eucla',
        countryCode: 'AU',
        value: 'Australia/Eucla',
        offset: {
          standard: '+08:45',
          daylightSaving: '+08:45',
        },
        abbreviation: {
          standard: '+0845',
          daylightSaving: '+0845',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Hobart',
        countryCode: 'AU',
        value: 'Australia/Hobart',
        offset: {
          standard: '+10:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: 'AEST',
          daylightSaving: 'AEDT',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Lindeman',
        countryCode: 'AU',
        value: 'Australia/Lindeman',
        offset: {
          standard: '+10:00',
          daylightSaving: '+10:00',
        },
        abbreviation: {
          standard: 'AEST',
          daylightSaving: 'AEST',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Lord Howe',
        countryCode: 'AU',
        value: 'Australia/Lord_Howe',
        offset: {
          standard: '+10:30',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+1030',
          daylightSaving: '+11',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Melbourne',
        countryCode: 'AU',
        value: 'Australia/Melbourne',
        offset: {
          standard: '+10:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: 'AEST',
          daylightSaving: 'AEDT',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Perth',
        countryCode: 'AU',
        value: 'Australia/Perth',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: 'AWST',
          daylightSaving: 'AWST',
        },
        countryCodeIso: 'AUS',
      },
      {
        name: 'Sydney',
        countryCode: 'AU',
        value: 'Australia/Sydney',
        offset: {
          standard: '+10:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: 'AEST',
          daylightSaving: 'AEDT',
        },
        countryCodeIso: 'AUS',
      },
    ],
  },
  {
    name: 'Austria',
    code: 'AT',
    capital: '',
    region: '',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'de',
      name: 'German',
    },
  },
  {
    name: 'Azerbaijan',
    code: 'AZE',
    capital: 'Baku',
    region: 'AS',
    currency: {
      code: 'AZN',
      name: 'Azerbaijani manat',
      symbol: null,
    },
    language: {
      code: 'az',
      name: 'Azerbaijani',
    },
    flag: 'https://restcountries.eu/data/aze.svg',
    callingCode: '+994',
    isoCode4217: '031',
    timezones: [
      {
        name: 'Baku',
        countryCode: 'AZ',
        value: 'Asia/Baku',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'AZE',
      },
    ],
  },
  {
    name: 'Bahamas',
    code: 'BHS',
    capital: 'Nassau',
    region: 'NA',
    currency: {
      code: 'BSD',
      name: 'Bahamian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/bhs.svg',
    callingCode: '+1',
    isoCode4217: '044',
    timezones: [
      {
        name: 'Toronto',
        countryCode: 'BS',
        value: 'America/Toronto',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'BHS',
      },
    ],
  },
  {
    name: 'Bahrain',
    code: 'BHR',
    capital: 'Manama',
    region: 'AS',
    currency: {
      code: 'BHD',
      name: 'Bahraini dinar',
      symbol: '.د.ب',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/bhr.svg',
    callingCode: '+973',
    isoCode4217: '048',
    timezones: [
      {
        name: 'Qatar',
        countryCode: 'BH',
        value: 'Asia/Qatar',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'BHR',
      },
    ],
  },
  {
    name: 'Bangladesh',
    code: 'BGD',
    capital: 'Dhaka',
    region: 'AS',
    currency: {
      code: 'BDT',
      name: 'Bangladeshi taka',
      symbol: '৳',
    },
    language: {
      code: 'bn',
      name: 'Bengali',
    },
    flag: 'https://restcountries.eu/data/bgd.svg',
    callingCode: '+880',
    isoCode4217: '050',
    timezones: [
      {
        name: 'Dhaka',
        countryCode: 'BD',
        value: 'Asia/Dhaka',
        offset: {
          standard: '+06:00',
          daylightSaving: '+06:00',
        },
        abbreviation: {
          standard: '+06',
          daylightSaving: '+06',
        },
        countryCodeIso: 'BGD',
      },
    ],
  },
  {
    name: 'Barbados',
    code: 'BRB',
    capital: 'Bridgetown',
    region: 'NA',
    currency: {
      code: 'BBD',
      name: 'Barbadian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/brb.svg',
    callingCode: '+1',
    isoCode4217: '052',
    timezones: [
      {
        name: 'Barbados',
        countryCode: 'BB',
        value: 'America/Barbados',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'BRB',
      },
    ],
  },
  {
    name: 'Belarus',
    code: 'BLR',
    capital: 'Minsk',
    region: 'EU',
    currency: {
      code: 'BYN',
      name: 'New Belarusian ruble',
      symbol: 'Br',
    },
    language: {
      code: 'be',
      name: 'Belarusian',
    },
    flag: 'https://restcountries.eu/data/blr.svg',
    callingCode: '+375',
    isoCode4217: '112',
    timezones: [
      {
        name: 'Minsk',
        countryCode: 'BY',
        value: 'Europe/Minsk',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'BLR',
      },
    ],
  },
  {
    name: 'Belgium',
    code: 'BEL',
    capital: 'Brussels',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'nl',
      name: 'Dutch',
    },
    flag: 'https://restcountries.eu/data/bel.svg',
    callingCode: '+32',
    isoCode4217: '056',
    timezones: [
      {
        name: 'Brussels',
        countryCode: 'BE',
        value: 'Europe/Brussels',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'BEL',
      },
    ],
  },
  {
    name: 'Belize',
    code: 'BLZ',
    capital: 'Belmopan',
    region: 'NA',
    currency: {
      code: 'BZD',
      name: 'Belize dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/blz.svg',
    callingCode: '+501',
    isoCode4217: '084',
    timezones: [
      {
        name: 'Belize',
        countryCode: 'BZ',
        value: 'America/Belize',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'BLZ',
      },
    ],
  },
  {
    name: 'Benin',
    code: 'BEN',
    capital: 'Porto-Novo',
    region: 'AF',
    currency: {
      code: 'XOF',
      name: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/ben.svg',
    callingCode: '+229',
    isoCode4217: '204',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'BJ',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'BEN',
      },
    ],
  },
  {
    name: 'Bermuda',
    code: 'BMU',
    capital: 'Hamilton',
    region: 'NA',
    currency: {
      code: 'BMD',
      name: 'Bermudian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/bmu.svg',
    callingCode: '+1',
    isoCode4217: '060',
    timezones: [
      {
        name: 'Bermuda',
        countryCode: 'BM',
        value: 'Atlantic/Bermuda',
        offset: {
          standard: '-04:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'ADT',
        },
        countryCodeIso: 'BMU',
      },
    ],
  },
  {
    name: 'Bhutan',
    code: 'BTN',
    capital: 'Thimphu',
    region: 'AS',
    currency: {
      code: 'BTN',
      name: 'Bhutanese ngultrum',
      symbol: 'Nu.',
    },
    language: {
      code: 'dz',
      name: 'Dzongkha',
    },
    flag: 'https://restcountries.eu/data/btn.svg',
    callingCode: '+975',
    isoCode4217: '064',
    timezones: [
      {
        name: 'Thimphu',
        countryCode: 'BT',
        value: 'Asia/Thimphu',
        offset: {
          standard: '+06:00',
          daylightSaving: '+06:00',
        },
        abbreviation: {
          standard: '+06',
          daylightSaving: '+06',
        },
        countryCodeIso: 'BTN',
      },
    ],
  },
  {
    name: 'Bolivia (Plurinational State of)',
    code: 'BOL',
    capital: 'Sucre',
    region: 'SA',
    currency: {
      code: 'BOB',
      name: 'Bolivian boliviano',
      symbol: 'Bs.',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/bol.svg',
    callingCode: '+591',
    isoCode4217: '068',
    timezones: [
      {
        name: 'La Paz',
        countryCode: 'BO',
        value: 'America/La_Paz',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-04',
        },
        countryCodeIso: 'BOL',
      },
    ],
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BIH',
    capital: 'Sarajevo',
    region: 'EU',
    currency: {
      code: 'BAM',
      name: 'Bosnia and Herzegovina convertible mark',
      symbol: null,
    },
    language: {
      code: 'bs',
      name: 'Bosnian',
    },
    flag: 'https://restcountries.eu/data/bih.svg',
    callingCode: '+387',
    isoCode4217: '070',
    timezones: [
      {
        name: 'Belgrade',
        countryCode: 'BA',
        value: 'Europe/Belgrade',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'BIH',
      },
    ],
  },
  {
    name: 'Botswana',
    code: 'BWA',
    capital: 'Gaborone',
    region: 'AF',
    currency: {
      code: 'BWP',
      name: 'Botswana pula',
      symbol: 'P',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/bwa.svg',
    callingCode: '+267',
    isoCode4217: '072',
    timezones: [
      {
        name: 'Maputo',
        countryCode: 'BW',
        value: 'Africa/Maputo',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'BWA',
      },
    ],
  },
  {
    name: 'Brazil',
    code: 'BRA',
    capital: 'Brasília',
    region: 'SA',
    currency: {
      code: 'BRL',
      name: 'Brazilian real',
      symbol: 'R$',
    },
    language: {
      code: 'pt',
      name: 'Portuguese',
    },
    flag: 'https://restcountries.eu/data/bra.svg',
    callingCode: '+55',
    isoCode4217: '076',
    timezones: [
      {
        name: 'Araguaina',
        countryCode: 'BR',
        value: 'America/Araguaina',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Bahia',
        countryCode: 'BR',
        value: 'America/Bahia',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Belem',
        countryCode: 'BR',
        value: 'America/Belem',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Boa Vista',
        countryCode: 'BR',
        value: 'America/Boa_Vista',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-04',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Campo Grande',
        countryCode: 'BR',
        value: 'America/Campo_Grande',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-04',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Cuiaba',
        countryCode: 'BR',
        value: 'America/Cuiaba',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-04',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Eirunepe',
        countryCode: 'BR',
        value: 'America/Eirunepe',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: '-05',
          daylightSaving: '-05',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Fortaleza',
        countryCode: 'BR',
        value: 'America/Fortaleza',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Maceio',
        countryCode: 'BR',
        value: 'America/Maceio',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Manaus',
        countryCode: 'BR',
        value: 'America/Manaus',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-04',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Noronha',
        countryCode: 'BR',
        value: 'America/Noronha',
        offset: {
          standard: '-02:00',
          daylightSaving: '-02:00',
        },
        abbreviation: {
          standard: '-02',
          daylightSaving: '-02',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Porto Velho',
        countryCode: 'BR',
        value: 'America/Porto_Velho',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-04',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Recife',
        countryCode: 'BR',
        value: 'America/Recife',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Rio Branco',
        countryCode: 'BR',
        value: 'America/Rio_Branco',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: '-05',
          daylightSaving: '-05',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Santarem',
        countryCode: 'BR',
        value: 'America/Santarem',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'BRA',
      },
      {
        name: 'Sao Paulo',
        countryCode: 'BR',
        value: 'America/Sao_Paulo',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'BRA',
      },
    ],
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IOT',
    capital: 'Diego Garcia',
    region: 'AF',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/iot.svg',
    callingCode: '+246',
    isoCode4217: '086',
    timezones: [
      {
        name: 'Almaty',
        countryCode: 'IO',
        value: 'Asia/Almaty',
        offset: {
          standard: '+06:00',
          daylightSaving: '+06:00',
        },
        abbreviation: {
          standard: '+06',
          daylightSaving: '+06',
        },
        countryCodeIso: 'IOT',
      },
    ],
  },
  {
    name: 'Virgin Islands (British)',
    code: 'VGB',
    capital: 'Road Town',
    region: 'NA',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/vgb.svg',
    callingCode: '+1',
    isoCode4217: '092',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'VG',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'VGB',
      },
    ],
  },
  {
    name: 'Virgin Islands (U.S.)',
    code: 'VIR',
    capital: 'Charlotte Amalie',
    region: 'NA',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/vir.svg',
    callingCode: '+1',
    isoCode4217: '850',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'VI',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'VIR',
      },
    ],
  },
  {
    name: 'Brunei Darussalam',
    code: 'BRN',
    capital: 'Bandar Seri Begawan',
    region: 'AS',
    currency: {
      code: 'BND',
      name: 'Brunei dollar',
      symbol: '$',
    },
    language: {
      code: 'ms',
      name: 'Malay',
    },
    flag: 'https://restcountries.eu/data/brn.svg',
    callingCode: '+673',
    isoCode4217: '096',
    timezones: [
      {
        name: 'Kuching',
        countryCode: 'BN',
        value: 'Asia/Kuching',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: '+08',
          daylightSaving: '+08',
        },
        countryCodeIso: 'BRN',
      },
    ],
  },
  {
    name: 'Bulgaria',
    code: 'BGR',
    capital: 'Sofia',
    region: 'EU',
    currency: {
      code: 'BGN',
      name: 'Bulgarian lev',
      symbol: 'лв',
    },
    language: {
      code: 'bg',
      name: 'Bulgarian',
    },
    flag: 'https://restcountries.eu/data/bgr.svg',
    callingCode: '+359',
    isoCode4217: '100',
    timezones: [
      {
        name: 'Sofia',
        countryCode: 'BG',
        value: 'Europe/Sofia',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'BGR',
      },
    ],
  },
  {
    name: 'Burkina Faso',
    code: 'BFA',
    capital: 'Ouagadougou',
    region: 'AF',
    currency: {
      code: 'XOF',
      name: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/bfa.svg',
    callingCode: '+226',
    isoCode4217: '854',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'BF',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'BFA',
      },
    ],
  },
  {
    name: 'Burundi',
    code: 'BDI',
    capital: 'Bujumbura',
    region: 'AF',
    currency: {
      code: 'BIF',
      name: 'Burundian franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/bdi.svg',
    callingCode: '+257',
    isoCode4217: '108',
    timezones: [
      {
        name: 'Maputo',
        countryCode: 'BI',
        value: 'Africa/Maputo',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'BDI',
      },
    ],
  },
  {
    name: 'Cambodia',
    code: 'KHM',
    capital: 'Phnom Penh',
    region: 'AS',
    currency: {
      code: 'KHR',
      name: 'Cambodian riel',
      symbol: '៛',
    },
    language: {
      code: 'km',
      name: 'Khmer',
    },
    flag: 'https://restcountries.eu/data/khm.svg',
    callingCode: '+855',
    isoCode4217: '116',
    timezones: [
      {
        name: 'Bangkok',
        countryCode: 'KH',
        value: 'Asia/Bangkok',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'KHM',
      },
    ],
  },
  {
    name: 'Cameroon',
    code: 'CMR',
    capital: 'Yaoundé',
    region: 'AF',
    currency: {
      code: 'XAF',
      name: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/cmr.svg',
    callingCode: '+237',
    isoCode4217: '120',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'CM',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'CMR',
      },
    ],
  },
  {
    name: 'Canada',
    code: 'CAN',
    capital: 'Ottawa',
    region: 'NA',
    currency: {
      code: 'CAD',
      name: 'Canadian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/can.svg',
    callingCode: '+1',
    isoCode4217: '124',
    timezones: [
      {
        name: 'Cambridge Bay',
        countryCode: 'CA',
        value: 'America/Cambridge_Bay',
        offset: {
          standard: '-07:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Dawson',
        countryCode: 'CA',
        value: 'America/Dawson',
        offset: {
          standard: '-07:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Dawson Creek',
        countryCode: 'CA',
        value: 'America/Dawson_Creek',
        offset: {
          standard: '-07:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Edmonton',
        countryCode: 'CA',
        value: 'America/Edmonton',
        offset: {
          standard: '-07:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Fort Nelson',
        countryCode: 'CA',
        value: 'America/Fort_Nelson',
        offset: {
          standard: '-07:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Glace Bay',
        countryCode: 'CA',
        value: 'America/Glace_Bay',
        offset: {
          standard: '-04:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'ADT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Goose Bay',
        countryCode: 'CA',
        value: 'America/Goose_Bay',
        offset: {
          standard: '-04:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'ADT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Halifax',
        countryCode: 'CA',
        value: 'America/Halifax',
        offset: {
          standard: '-04:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'ADT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Inuvik',
        countryCode: 'CA',
        value: 'America/Inuvik',
        offset: {
          standard: '-07:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Iqaluit',
        countryCode: 'CA',
        value: 'America/Iqaluit',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Moncton',
        countryCode: 'CA',
        value: 'America/Moncton',
        offset: {
          standard: '-04:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'ADT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Panama',
        countryCode: 'CA',
        value: 'America/Panama',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Phoenix',
        countryCode: 'CA',
        value: 'America/Phoenix',
        offset: {
          standard: '-07:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Puerto Rico',
        countryCode: 'CA',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Rankin Inlet',
        countryCode: 'CA',
        value: 'America/Rankin_Inlet',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Regina',
        countryCode: 'CA',
        value: 'America/Regina',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Resolute',
        countryCode: 'CA',
        value: 'America/Resolute',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'St Johns',
        countryCode: 'CA',
        value: 'America/St_Johns',
        offset: {
          standard: '-03:30',
          daylightSaving: '-02:30',
        },
        abbreviation: {
          standard: 'NST',
          daylightSaving: 'NDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Swift Current',
        countryCode: 'CA',
        value: 'America/Swift_Current',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Toronto',
        countryCode: 'CA',
        value: 'America/Toronto',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Vancouver',
        countryCode: 'CA',
        value: 'America/Vancouver',
        offset: {
          standard: '-08:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'PST',
          daylightSaving: 'PDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Whitehorse',
        countryCode: 'CA',
        value: 'America/Whitehorse',
        offset: {
          standard: '-07:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MST',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Winnipeg',
        countryCode: 'CA',
        value: 'America/Winnipeg',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'CAN',
      },
      {
        name: 'Yellowknife',
        countryCode: 'CA',
        value: 'America/Yellowknife',
        offset: {
          standard: '-07:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MDT',
        },
        countryCodeIso: 'CAN',
      },
    ],
  },
  {
    name: 'Cabo Verde',
    code: 'CPV',
    capital: 'Praia',
    region: 'AF',
    currency: {
      code: 'CVE',
      name: 'Cape Verdean escudo',
      symbol: 'Esc',
    },
    language: {
      code: 'pt',
      iso639_2: 'por',
      name: 'Portuguese',
      nativeName: 'Português',
    },
    flag: 'https://restcountries.eu/data/cpv.svg',
    callingCode: '+238',
    isoCode4217: '132',
    timezones: [
      {
        name: 'Cape Verde',
        countryCode: 'CV',
        value: 'Atlantic/Cape_Verde',
        offset: {
          standard: '-01:00',
          daylightSaving: '-01:00',
        },
        abbreviation: {
          standard: '-01',
          daylightSaving: '-01',
        },
        countryCodeIso: 'CPV',
      },
    ],
  },
  {
    name: 'Cayman Islands',
    code: 'CYM',
    capital: 'George Town',
    region: 'NA',
    demonym: 'Caymanian',
    currency: {
      code: 'KYD',
      name: 'Cayman Islands dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/cym.svg',
    callingCode: '+1',
    isoCode4217: '136',
    timezones: [
      {
        name: 'Panama',
        countryCode: 'KY',
        value: 'America/Panama',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EST',
        },
        countryCodeIso: 'CYM',
      },
    ],
  },
  {
    name: 'Central African Republic',
    code: 'CAF',
    capital: 'Bangui',
    region: 'AF',
    currency: {
      code: 'XAF',
      name: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/caf.svg',
    callingCode: '+236',
    isoCode4217: '140',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'CF',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'CAF',
      },
    ],
  },
  {
    name: 'Central African Republic',
    code: 'CAF',
    capital: 'Bangui',
    region: 'AF',
    currency: {
      code: 'XAF',
      name: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/caf.svg',
    callingCode: '+236',
    isoCode4217: '140',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'CF',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'CAF',
      },
    ],
  },
  {
    name: 'Chile',
    code: 'CHL',
    capital: 'Santiago',
    region: 'SA',
    currency: {
      code: 'CLP',
      name: 'Chilean peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      iso639_2: 'spa',
      name: 'Spanish',
      nativeName: 'Español',
    },
    flag: 'https://restcountries.eu/data/chl.svg',
    callingCode: '+56',
    isoCode4217: '152',
    timezones: [
      {
        name: 'Punta Arenas',
        countryCode: 'CL',
        value: 'America/Punta_Arenas',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'CHL',
      },
      {
        name: 'Santiago',
        countryCode: 'CL',
        value: 'America/Santiago',
        offset: {
          standard: '-04:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-03',
        },
        countryCodeIso: 'CHL',
      },
      {
        name: 'Easter',
        countryCode: 'CL',
        value: 'Pacific/Easter',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: '-06',
          daylightSaving: '-05',
        },
        countryCodeIso: 'CHL',
      },
    ],
  },
  {
    name: 'China',
    code: 'CHN',
    capital: 'Beijing',
    region: 'AS',
    currency: {
      code: 'CNY',
      name: 'Chinese yuan',
      symbol: '¥',
    },
    language: {
      code: 'zh',
      name: 'Chinese',
    },
    flag: 'https://restcountries.eu/data/chn.svg',
    callingCode: '+86',
    isoCode4217: '156',
    timezones: [
      {
        name: 'Shanghai',
        countryCode: 'CN',
        value: 'Asia/Shanghai',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'CHN',
      },
      {
        name: 'Urumqi',
        countryCode: 'CN',
        value: 'Asia/Urumqi',
        offset: {
          standard: '+06:00',
          daylightSaving: '+06:00',
        },
        abbreviation: {
          standard: '+06',
          daylightSaving: '+06',
        },
        countryCodeIso: 'CHN',
      },
    ],
  },
  {
    name: 'Colombia',
    code: 'COL',
    capital: 'Bogotá',
    region: 'SA',
    currency: {
      code: 'COP',
      name: 'Colombian peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/col.svg',
    callingCode: '+57',
    isoCode4217: '170',
    timezones: [
      {
        name: 'Bogota',
        countryCode: 'CO',
        value: 'America/Bogota',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: '-05',
          daylightSaving: '-05',
        },
        countryCodeIso: 'COL',
      },
    ],
  },
  {
    name: 'Comoros',
    code: 'COM',
    capital: 'Moroni',
    region: 'AF',
    currency: {
      code: 'KMF',
      name: 'Comorian franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/com.svg',
    callingCode: '+269',
    isoCode4217: '174',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'KM',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'COM',
      },
    ],
  },
  {
    name: 'Congo',
    code: 'COG',
    capital: 'Brazzaville',
    region: 'AF',
    currency: {
      code: 'XAF',
      name: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/cog.svg',
    callingCode: '+242',
    isoCode4217: '178',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'CG',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'COG',
      },
    ],
  },
  {
    name: 'Cook Islands',
    code: 'COK',
    capital: 'Avarua',
    region: 'OC',
    currency: {
      code: 'NZD',
      name: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/cok.svg',
    callingCode: '+682',
    isoCode4217: '184',
    timezones: [
      {
        name: 'Rarotonga',
        countryCode: 'CK',
        value: 'Pacific/Rarotonga',
        offset: {
          standard: '-10:00',
          daylightSaving: '-10:00',
        },
        abbreviation: {
          standard: '-10',
          daylightSaving: '-10',
        },
        countryCodeIso: 'COK',
      },
    ],
  },
  {
    name: 'Costa Rica',
    code: 'CRI',
    capital: 'San José',
    region: 'NA',
    currency: {
      code: 'CRC',
      name: 'Costa Rican colón',
      symbol: '₡',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/cri.svg',
    callingCode: '+506',
    isoCode4217: '188',
    timezones: [
      {
        name: 'Costa Rica',
        countryCode: 'CR',
        value: 'America/Costa_Rica',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'CRI',
      },
    ],
  },
  {
    name: 'Croatia',
    code: 'HRV',
    capital: 'Zagreb',
    region: 'EU',
    currency: {
      code: 'HRK',
      name: 'Croatian kuna',
      symbol: 'kn',
    },
    language: {
      code: 'hr',
      name: 'Croatian',
    },
    flag: 'https://restcountries.eu/data/hrv.svg',
    callingCode: '+385',
    isoCode4217: '191',
    timezones: [
      {
        name: 'Belgrade',
        countryCode: 'HR',
        value: 'Europe/Belgrade',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'HRV',
      },
    ],
  },
  {
    name: 'Cuba',
    code: 'CUB',
    capital: 'Havana',
    region: 'NA',
    currency: {
      code: 'CUC',
      name: 'Cuban convertible peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/cub.svg',
    callingCode: '+53',
    isoCode4217: '192',
    timezones: [
      {
        name: 'Havana',
        countryCode: 'CU',
        value: 'America/Havana',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'CUB',
      },
    ],
  },
  {
    name: 'Cuba',
    code: 'CUB',
    capital: 'Havana',
    region: 'NA',
    currency: {
      code: 'CUC',
      name: 'Cuban convertible peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/cub.svg',
    callingCode: '+53',
    isoCode4217: '192',
    timezones: [
      {
        name: 'Havana',
        countryCode: 'CU',
        value: 'America/Havana',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'CUB',
      },
    ],
  },
  {
    name: 'Curacao',
    code: 'CW',
  },
  {
    name: 'Cyprus',
    code: 'CYP',
    capital: 'Nicosia',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'tr',
      name: 'Turkish',
    },
    flag: 'https://restcountries.eu/data/cyp.svg',
    callingCode: '+357',
    isoCode4217: '196',
    timezones: [
      {
        name: 'Famagusta',
        countryCode: 'CY',
        value: 'Asia/Famagusta',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'CYP',
      },
      {
        name: 'Nicosia',
        countryCode: 'CY',
        value: 'Asia/Nicosia',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'CYP',
      },
    ],
  },
  {
    name: 'Czech Republic',
    code: 'CZE',
    capital: 'Prague',
    region: 'EU',
    currency: {
      code: 'CZK',
      name: 'Czech koruna',
      symbol: 'Kč',
    },
    language: {
      code: 'cs',
      name: 'Czech',
    },
    flag: 'https://restcountries.eu/data/cze.svg',
    callingCode: '+420',
    isoCode4217: '203',
    timezones: [
      {
        name: 'Prague',
        countryCode: 'CZ',
        value: 'Europe/Prague',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'CZE',
      },
    ],
  },
  {
    name: 'Denmark',
    code: 'DNK',
    capital: 'Copenhagen',
    region: 'EU',
    currency: {
      code: 'DKK',
      name: 'Danish krone',
      symbol: 'kr',
    },
    language: {
      code: 'da',
      name: 'Danish',
    },
    flag: 'https://restcountries.eu/data/dnk.svg',
    callingCode: '+45',
    isoCode4217: '208',
    timezones: [
      {
        name: 'Berlin',
        countryCode: 'DK',
        value: 'Europe/Berlin',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'DNK',
      },
    ],
  },
  {
    name: 'Djibouti',
    code: 'DJI',
    capital: 'Djibouti',
    region: 'AF',
    currency: {
      code: 'DJF',
      name: 'Djiboutian franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/dji.svg',
    callingCode: '+253',
    isoCode4217: '262',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'DJ',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'DJI',
      },
    ],
  },
  {
    name: 'Dominica',
    code: 'DMA',
    capital: 'Roseau',
    region: 'NA',
    currency: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/dma.svg',
    callingCode: '+1',
    isoCode4217: '212',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'DM',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'DMA',
      },
    ],
  },
  {
    name: 'Dominican Republic',
    code: 'DOM',
    capital: 'Santo Domingo',
    region: 'NA',
    currency: {
      code: 'DOP',
      name: 'Dominican peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/dom.svg',
    callingCode: '+1',
    isoCode4217: '214',
    timezones: [
      {
        name: 'Santo Domingo',
        countryCode: 'DO',
        value: 'America/Santo_Domingo',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'DOM',
      },
    ],
  },
  {
    name: 'Ecuador',
    code: 'ECU',
    capital: 'Quito',
    region: 'SA',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/ecu.svg',
    callingCode: '+593',
    isoCode4217: '218',
    timezones: [
      {
        name: 'Guayaquil',
        countryCode: 'EC',
        value: 'America/Guayaquil',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: '-05',
          daylightSaving: '-05',
        },
        countryCodeIso: 'ECU',
      },
      {
        name: 'Galapagos',
        countryCode: 'EC',
        value: 'Pacific/Galapagos',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: '-06',
          daylightSaving: '-06',
        },
        countryCodeIso: 'ECU',
      },
    ],
  },
  {
    name: 'Egypt',
    code: 'EGY',
    capital: 'Cairo',
    region: 'AF',
    currency: {
      code: 'EGP',
      name: 'Egyptian pound',
      symbol: '£',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/egy.svg',
    callingCode: '+20',
    isoCode4217: '818',
    timezones: [
      {
        name: 'Cairo',
        countryCode: 'EG',
        value: 'Africa/Cairo',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EET',
        },
        countryCodeIso: 'EGY',
      },
    ],
  },
  {
    name: 'El Salvador',
    code: 'SLV',
    capital: 'San Salvador',
    region: 'NA',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/slv.svg',
    callingCode: '+503',
    isoCode4217: '222',
    timezones: [
      {
        name: 'El Salvador',
        countryCode: 'SV',
        value: 'America/El_Salvador',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'SLV',
      },
    ],
  },
  {
    name: 'Equatorial Guinea',
    code: 'GNQ',
    capital: 'Malabo',
    region: 'AF',
    currency: {
      code: 'XAF',
      name: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'es',
      iso639_2: 'spa',
      name: 'Spanish',
      nativeName: 'Español',
    },
    flag: 'https://restcountries.eu/data/gnq.svg',
    callingCode: '+240',
    isoCode4217: '226',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'GQ',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'GNQ',
      },
    ],
  },
  {
    name: 'Eritrea',
    code: 'ERI',
    capital: 'Asmara',
    region: 'AF',
    currency: {
      code: 'ERN',
      name: 'Eritrean nakfa',
      symbol: 'Nfk',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/eri.svg',
    callingCode: '+291',
    isoCode4217: '232',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'ER',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'ERI',
      },
    ],
  },
  {
    name: 'Estonia',
    code: 'EST',
    capital: 'Tallinn',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'et',
      name: 'Estonian',
    },
    flag: 'https://restcountries.eu/data/est.svg',
    callingCode: '+372',
    isoCode4217: '233',
    timezones: [
      {
        name: 'Tallinn',
        countryCode: 'EE',
        value: 'Europe/Tallinn',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'EST',
      },
    ],
  },
  {
    name: 'Ethiopia',
    code: 'ETH',
    capital: 'Addis Ababa',
    region: 'AF',
    currency: {
      code: 'ETB',
      name: 'Ethiopian birr',
      symbol: 'Br',
    },
    language: {
      code: 'am',
      name: 'Amharic',
    },
    flag: 'https://restcountries.eu/data/eth.svg',
    callingCode: '+251',
    isoCode4217: '231',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'ET',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'ETH',
      },
    ],
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FLK',
    capital: 'Stanley',
    region: 'SA',
    currency: {
      code: 'FKP',
      name: 'Falkland Islands pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/flk.svg',
    callingCode: '+500',
    isoCode4217: '238',
    timezones: [
      {
        name: 'Stanley',
        countryCode: 'FK',
        value: 'Atlantic/Stanley',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'FLK',
      },
    ],
  },
  {
    name: 'Faroe Islands',
    code: 'FRO',
    capital: 'Tórshavn',
    region: 'EU',
    currency: {
      code: 'DKK',
      name: 'Danish krone',
      symbol: 'kr',
    },
    language: {
      code: 'fo',
      name: 'Faroese',
    },
    flag: 'https://restcountries.eu/data/fro.svg',
    callingCode: '+298',
    isoCode4217: '234',
    timezones: [
      {
        name: 'Faroe',
        countryCode: 'FO',
        value: 'Atlantic/Faroe',
        offset: {
          standard: '+00:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WET',
          daylightSaving: 'WEST',
        },
        countryCodeIso: 'FRO',
      },
    ],
  },
  {
    name: 'Fiji',
    code: 'FJI',
    capital: 'Suva',
    region: 'OC',
    currency: {
      code: 'FJD',
      name: 'Fijian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/fji.svg',
    callingCode: '+679',
    isoCode4217: '242',
    timezones: [
      {
        name: 'Fiji',
        countryCode: 'FJ',
        value: 'Pacific/Fiji',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'FJI',
      },
    ],
  },
  {
    name: 'Finland',
    code: 'FIN',
    capital: 'Helsinki',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fi',
      iso639_2: 'fin',
      name: 'Finnish',
      nativeName: 'suomi',
    },
    flag: 'https://restcountries.eu/data/fin.svg',
    callingCode: '+358',
    isoCode4217: '246',
    timezones: [
      {
        name: 'Helsinki',
        countryCode: 'FI',
        value: 'Europe/Helsinki',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'FIN',
      },
    ],
  },
  {
    name: 'France',
    code: 'FRA',
    capital: 'Paris',
    region: 'EU',
    demonym: 'French',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/fra.svg',
    callingCode: '+33',
    isoCode4217: '250',
    timezones: [
      {
        name: 'Paris',
        countryCode: 'FR',
        value: 'Europe/Paris',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'FRA',
      },
    ],
  },
  {
    name: 'French Guiana',
    code: 'GUF',
    capital: 'Cayenne',
    region: 'SA',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/guf.svg',
    callingCode: '+594',
    isoCode4217: '254',
    timezones: [
      {
        name: 'Cayenne',
        countryCode: 'GF',
        value: 'America/Cayenne',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'GUF',
      },
    ],
  },
  {
    name: 'French Polynesia',
    code: 'PYF',
    capital: 'Papeetē',
    region: 'OC',
    currency: {
      code: 'XPF',
      name: 'CFP franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/pyf.svg',
    callingCode: '+689',
    isoCode4217: '258',
    timezones: [
      {
        name: 'Gambier',
        countryCode: 'PF',
        value: 'Pacific/Gambier',
        offset: {
          standard: '-09:00',
          daylightSaving: '-09:00',
        },
        abbreviation: {
          standard: '-09',
          daylightSaving: '-09',
        },
        countryCodeIso: 'PYF',
      },
      {
        name: 'Marquesas',
        countryCode: 'PF',
        value: 'Pacific/Marquesas',
        offset: {
          standard: '-09:30',
          daylightSaving: '-09:30',
        },
        abbreviation: {
          standard: '-0930',
          daylightSaving: '-0930',
        },
        countryCodeIso: 'PYF',
      },
      {
        name: 'Tahiti',
        countryCode: 'PF',
        value: 'Pacific/Tahiti',
        offset: {
          standard: '-10:00',
          daylightSaving: '-10:00',
        },
        abbreviation: {
          standard: '-10',
          daylightSaving: '-10',
        },
        countryCodeIso: 'PYF',
      },
    ],
  },
  {
    name: 'Gabon',
    code: 'GAB',
    capital: 'Libreville',
    region: 'AF',
    currency: {
      code: 'XAF',
      name: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/gab.svg',
    callingCode: '+241',
    isoCode4217: '266',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'GA',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'GAB',
      },
    ],
  },
  {
    name: 'Gambia',
    code: 'GMB',
    capital: 'Banjul',
    region: 'AF',
    currency: {
      code: 'GMD',
      name: 'Gambian dalasi',
      symbol: 'D',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/gmb.svg',
    callingCode: '+220',
    isoCode4217: '270',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'GM',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'GMB',
      },
    ],
  },
  {
    name: 'Georgia',
    code: 'GEO',
    capital: 'Tbilisi',
    region: 'AS',
    currency: {
      code: 'GEL',
      name: 'Georgian Lari',
      symbol: 'ლ',
    },
    language: {
      code: 'ka',
      name: 'Georgian',
    },
    flag: 'https://restcountries.eu/data/geo.svg',
    callingCode: '+995',
    isoCode4217: '268',
    timezones: [
      {
        name: 'Tbilisi',
        countryCode: 'GE',
        value: 'Asia/Tbilisi',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'GEO',
      },
      {
        name: 'London',
        countryCode: 'GE',
        value: 'Europe/London',
        offset: {
          standard: '+00:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'BST',
        },
        countryCodeIso: 'GEO',
      },
    ],
  },
  {
    name: 'Germany',
    code: 'DEU',
    capital: 'Berlin',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'de',
      name: 'German',
    },
    flag: 'https://restcountries.eu/data/deu.svg',
    callingCode: '+49',
    isoCode4217: '276',
    timezones: [
      {
        name: 'Berlin',
        countryCode: 'DE',
        value: 'Europe/Berlin',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'DEU',
      },
    ],
  },
  {
    name: 'Ghana',
    code: 'GHA',
    capital: 'Accra',
    region: 'AF',
    currency: {
      code: 'GHS',
      name: 'Ghanaian cedi',
      symbol: '₵',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/gha.svg',
    callingCode: '+233',
    isoCode4217: '288',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'GH',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'GHA',
      },
    ],
  },
  {
    name: 'Gibraltar',
    code: 'GIB',
    capital: 'Gibraltar',
    region: 'EU',
    currency: {
      code: 'GIP',
      name: 'Gibraltar pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/gib.svg',
    callingCode: '+350',
    isoCode4217: '292',
    timezones: [
      {
        name: 'Gibraltar',
        countryCode: 'GI',
        value: 'Europe/Gibraltar',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'GIB',
      },
    ],
  },
  {
    name: 'Greece',
    code: 'GRC',
    capital: 'Athens',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'el',
      name: 'Greek (modern)',
    },
    flag: 'https://restcountries.eu/data/grc.svg',
    callingCode: '+30',
    isoCode4217: '300',
    timezones: [
      {
        name: 'Athens',
        countryCode: 'GR',
        value: 'Europe/Athens',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'GRC',
      },
    ],
  },
  {
    name: 'Greenland',
    code: 'GRL',
    capital: 'Nuuk',
    region: 'NA',
    currency: {
      code: 'DKK',
      name: 'Danish krone',
      symbol: 'kr',
    },
    language: {
      code: 'kl',
      name: 'Kalaallisut',
    },
    flag: 'https://restcountries.eu/data/grl.svg',
    callingCode: '+299',
    isoCode4217: '304',
    timezones: [
      {
        name: 'Danmarkshavn',
        countryCode: 'GL',
        value: 'America/Danmarkshavn',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'GRL',
      },
      {
        name: 'Nuuk',
        countryCode: 'GL',
        value: 'America/Nuuk',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'GRL',
      },
      {
        name: 'Scoresbysund',
        countryCode: 'GL',
        value: 'America/Scoresbysund',
        offset: {
          standard: '-01:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: '-01',
          daylightSaving: '+00',
        },
        countryCodeIso: 'GRL',
      },
      {
        name: 'Thule',
        countryCode: 'GL',
        value: 'America/Thule',
        offset: {
          standard: '-04:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'ADT',
        },
        countryCodeIso: 'GRL',
      },
    ],
  },
  {
    name: 'Grenada',
    code: 'GRD',
    capital: "St. George's",
    region: 'NA',
    currency: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/grd.svg',
    callingCode: '+1',
    isoCode4217: '308',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'GD',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'GRD',
      },
    ],
  },
  {
    name: 'Guadeloupe',
    code: 'GLP',
    capital: 'Basse-Terre',
    region: 'NA',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/glp.svg',
    callingCode: '+590',
    isoCode4217: '312',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'GP',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'GLP',
      },
    ],
  },
  {
    name: 'Guam',
    code: 'GUM',
    capital: 'Hagåtña',
    region: 'OC',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/gum.svg',
    callingCode: '+1',
    isoCode4217: '316',
    timezones: [
      {
        name: 'Guam',
        countryCode: 'GU',
        value: 'Pacific/Guam',
        offset: {
          standard: '+10:00',
          daylightSaving: '+10:00',
        },
        abbreviation: {
          standard: 'ChST',
          daylightSaving: 'ChST',
        },
        countryCodeIso: 'GUM',
      },
    ],
  },
  {
    name: 'Guatemala',
    code: 'GTM',
    capital: 'Guatemala City',
    region: 'NA',
    currency: {
      code: 'GTQ',
      name: 'Guatemalan quetzal',
      symbol: 'Q',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/gtm.svg',
    callingCode: '+502',
    isoCode4217: '320',
    timezones: [
      {
        name: 'Guatemala',
        countryCode: 'GT',
        value: 'America/Guatemala',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'GTM',
      },
    ],
  },
  {
    name: 'Guinea',
    code: 'GIN',
    capital: 'Conakry',
    region: 'AF',
    currency: {
      code: 'GNF',
      name: 'Guinean franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/gin.svg',
    callingCode: '+224',
    isoCode4217: '324',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'GN',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'GIN',
      },
    ],
  },
  {
    name: 'Guinea-Bissau',
    code: 'GNB',
    capital: 'Bissau',
    region: 'AF',
    currency: {
      code: 'XOF',
      name: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'pt',
      name: 'Portuguese',
    },
    flag: 'https://restcountries.eu/data/gnb.svg',
    callingCode: '+245',
    isoCode4217: '624',
    timezones: [
      {
        name: 'Bissau',
        countryCode: 'GW',
        value: 'Africa/Bissau',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'GNB',
      },
    ],
  },
  {
    name: 'Guyana',
    code: 'GUY',
    capital: 'Georgetown',
    region: 'SA',
    currency: {
      code: 'GYD',
      name: 'Guyanese dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/guy.svg',
    callingCode: '+592',
    isoCode4217: '328',
    timezones: [
      {
        name: 'Guyana',
        countryCode: 'GY',
        value: 'America/Guyana',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-04',
        },
        countryCodeIso: 'GUY',
      },
    ],
  },
  {
    name: 'Haiti',
    code: 'HTI',
    capital: 'Port-au-Prince',
    region: 'Americas',
    currency: {
      code: 'HTG',
      name: 'Haitian gourde',
      symbol: 'G',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/hti.svg',
    callingCode: '+509',
    isoCode4217: '332',
    timezones: [
      {
        name: 'Port-au-Prince',
        countryCode: 'HT',
        value: 'America/Port-au-Prince',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'HTI',
      },
    ],
  },
  {
    name: 'Holy See',
    code: 'VAT',
    capital: 'Rome',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/vat.svg',
    callingCode: '+39',
    isoCode4217: '336',
    timezones: [
      {
        name: 'Rome',
        countryCode: 'VA',
        value: 'Europe/Rome',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'VAT',
      },
    ],
  },
  {
    name: 'Honduras',
    code: 'HND',
    capital: 'Tegucigalpa',
    region: 'NA',
    currency: {
      code: 'HNL',
      name: 'Honduran lempira',
      symbol: 'L',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/hnd.svg',
    callingCode: '+504',
    isoCode4217: '340',
    timezones: [
      {
        name: 'Tegucigalpa',
        countryCode: 'HN',
        value: 'America/Tegucigalpa',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'HND',
      },
    ],
  },
  {
    name: 'Hong Kong',
    code: 'HKG',
    capital: 'City of Victoria',
    region: 'AS',
    currency: {
      code: 'HKD',
      name: 'Hong Kong dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/hkg.svg',
    callingCode: '+852',
    isoCode4217: '344',
    timezones: [
      {
        name: 'Hong Kong',
        countryCode: 'HK',
        value: 'Asia/Hong_Kong',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: 'HKT',
          daylightSaving: 'HKT',
        },
        countryCodeIso: 'HKG',
      },
    ],
  },
  {
    name: 'Hungary',
    code: 'HUN',
    capital: 'Budapest',
    region: 'EU',
    currency: {
      code: 'HUF',
      name: 'Hungarian forint',
      symbol: 'Ft',
    },
    language: {
      code: 'hu',
      name: 'Hungarian',
    },
    flag: 'https://restcountries.eu/data/hun.svg',
    callingCode: '+36',
    isoCode4217: '348',
    timezones: [
      {
        name: 'Budapest',
        countryCode: 'HU',
        value: 'Europe/Budapest',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'HUN',
      },
    ],
  },
  {
    name: 'Iceland',
    code: 'ISL',
    capital: 'Reykjavík',
    region: 'EU',
    currency: {
      code: 'ISK',
      name: 'Icelandic króna',
      symbol: 'kr',
    },
    language: {
      code: 'is',
      name: 'Icelandic',
    },
    flag: 'https://restcountries.eu/data/isl.svg',
    callingCode: '+354',
    isoCode4217: '352',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'IS',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'ISL',
      },
    ],
  },
  {
    name: 'India',
    code: 'IND',
    capital: 'New Delhi',
    region: 'AS',
    currency: {
      code: 'INR',
      name: 'Indian rupee',
      symbol: '₹',
    },
    language: {
      code: 'hi',
      name: 'Hindi',
    },
    flag: 'https://restcountries.eu/data/ind.svg',
    callingCode: '+91',
    isoCode4217: '356',
    timezones: [
      {
        name: 'Kolkata',
        countryCode: 'IN',
        value: 'Asia/Kolkata',
        offset: {
          standard: '+05:30',
          daylightSaving: '+05:30',
        },
        abbreviation: {
          standard: 'IST',
          daylightSaving: 'IST',
        },
        countryCodeIso: 'IND',
      },
    ],
  },
  {
    name: 'Indonesia',
    code: 'IDN',
    capital: 'Jakarta',
    region: 'AS',
    currency: {
      code: 'IDR',
      name: 'Indonesian rupiah',
      symbol: 'Rp',
    },
    language: {
      code: 'id',
      name: 'Indonesian',
    },
    flag: 'https://restcountries.eu/data/idn.svg',
    callingCode: '+62',
    isoCode4217: '360',
    timezones: [
      {
        name: 'Jakarta',
        countryCode: 'ID',
        value: 'Asia/Jakarta',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: 'WIB',
          daylightSaving: 'WIB',
        },
        countryCodeIso: 'IDN',
      },
      {
        name: 'Jayapura',
        countryCode: 'ID',
        value: 'Asia/Jayapura',
        offset: {
          standard: '+09:00',
          daylightSaving: '+09:00',
        },
        abbreviation: {
          standard: 'WIT',
          daylightSaving: 'WIT',
        },
        countryCodeIso: 'IDN',
      },
      {
        name: 'Makassar',
        countryCode: 'ID',
        value: 'Asia/Makassar',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: 'WITA',
          daylightSaving: 'WITA',
        },
        countryCodeIso: 'IDN',
      },
      {
        name: 'Pontianak',
        countryCode: 'ID',
        value: 'Asia/Pontianak',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: 'WIB',
          daylightSaving: 'WIB',
        },
        countryCodeIso: 'IDN',
      },
    ],
  },
  {
    name: "Côte d'Ivoire",
    code: 'CIV',
    capital: 'Yamoussoukro',
    region: 'AF',
    currency: {
      code: 'XOF',
      name: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/civ.svg',
    callingCode: '+225',
    isoCode4217: '384',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'CI',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'CIV',
      },
    ],
  },
  {
    name: 'Iran (Islamic Republic of)',
    code: 'IRN',
    capital: 'Tehran',
    region: 'AS',
    currency: {
      code: 'IRR',
      name: 'Iranian rial',
      symbol: '﷼',
    },
    language: {
      code: 'fa',
      name: 'Persian (Farsi)',
    },
    flag: 'https://restcountries.eu/data/irn.svg',
    callingCode: '+98',
    isoCode4217: '364',
    timezones: [
      {
        name: 'Tehran',
        countryCode: 'IR',
        value: 'Asia/Tehran',
        offset: {
          standard: '+03:30',
          daylightSaving: '+03:30',
        },
        abbreviation: {
          standard: '+0330',
          daylightSaving: '+0330',
        },
        countryCodeIso: 'IRN',
      },
    ],
  },
  {
    name: 'Iraq',
    code: 'IRQ',
    capital: 'Baghdad',
    region: 'AS',
    currency: {
      code: 'IQD',
      name: 'Iraqi dinar',
      symbol: 'ع.د',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/irq.svg',
    callingCode: '+964',
    isoCode4217: '368',
    timezones: [
      {
        name: 'Baghdad',
        countryCode: 'IQ',
        value: 'Asia/Baghdad',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'IRQ',
      },
    ],
  },
  {
    name: 'Ireland',
    code: 'IRL',
    capital: 'Dublin',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'ga',
      name: 'Irish',
    },
    flag: 'https://restcountries.eu/data/irl.svg',
    callingCode: '+353',
    isoCode4217: '372',
    timezones: [
      {
        name: 'Dublin',
        countryCode: 'IE',
        value: 'Europe/Dublin',
        offset: {
          standard: '+01:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'IST',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'IRL',
      },
    ],
  },
  {
    name: 'Israel',
    code: 'ISR',
    capital: 'Jerusalem',
    region: 'AS',
    currency: {
      code: 'ILS',
      name: 'Israeli new shekel',
      symbol: '₪',
    },
    language: {
      code: 'he',
      name: 'Hebrew (modern)',
    },
    flag: 'https://restcountries.eu/data/isr.svg',
    callingCode: '+972',
    isoCode4217: '376',
    timezones: [
      {
        name: 'Jerusalem',
        countryCode: 'IL',
        value: 'Asia/Jerusalem',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'IST',
          daylightSaving: 'IDT',
        },
        countryCodeIso: 'ISR',
      },
    ],
  },
  {
    name: 'Italy',
    code: 'ITA',
    capital: 'Rome',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'it',
      name: 'Italian',
    },
    flag: 'https://restcountries.eu/data/ita.svg',
    callingCode: '+39',
    isoCode4217: '380',
    timezones: [
      {
        name: 'Rome',
        countryCode: 'IT',
        value: 'Europe/Rome',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'ITA',
      },
    ],
  },
  {
    name: 'Jamaica',
    code: 'JAM',
    capital: 'Kingston',
    region: 'NA',
    currency: {
      code: 'JMD',
      name: 'Jamaican dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/jam.svg',
    callingCode: '+1',
    isoCode4217: '388',
    timezones: [
      {
        name: 'Jamaica',
        countryCode: 'JM',
        value: 'America/Jamaica',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EST',
        },
        countryCodeIso: 'JAM',
      },
    ],
  },
  {
    name: 'Japan',
    code: 'JPN',
    capital: 'Tokyo',
    region: 'AS',
    currency: {
      code: 'JPY',
      name: 'Japanese yen',
      symbol: '¥',
    },
    language: {
      code: 'ja',
      name: 'Japanese',
    },
    flag: 'https://restcountries.eu/data/jpn.svg',
    callingCode: '+81',
    isoCode4217: '392',
    timezones: [
      {
        name: 'Tokyo',
        countryCode: 'JP',
        value: 'Asia/Tokyo',
        offset: {
          standard: '+09:00',
          daylightSaving: '+09:00',
        },
        abbreviation: {
          standard: 'JST',
          daylightSaving: 'JST',
        },
        countryCodeIso: 'JPN',
      },
    ],
  },
  {
    name: 'Jordan',
    code: 'JOR',
    capital: 'Amman',
    region: 'AS',
    currency: {
      code: 'JOD',
      name: 'Jordanian dinar',
      symbol: 'د.ا',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/jor.svg',
    callingCode: '+962',
    isoCode4217: '400',
    timezones: [
      {
        name: 'Amman',
        countryCode: 'JO',
        value: 'Asia/Amman',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'JOR',
      },
    ],
  },
  {
    name: 'Kazakhstan',
    code: 'KAZ',
    capital: 'Astana',
    region: 'AS',
    currency: {
      code: 'KZT',
      name: 'Kazakhstani tenge',
      symbol: null,
    },
    language: {
      code: 'kk',
      name: 'Kazakh',
    },
    flag: 'https://restcountries.eu/data/kaz.svg',
    callingCode: '+7',
    isoCode4217: '398',
    timezones: [
      {
        name: 'Almaty',
        countryCode: 'KZ',
        value: 'Asia/Almaty',
        offset: {
          standard: '+06:00',
          daylightSaving: '+06:00',
        },
        abbreviation: {
          standard: '+06',
          daylightSaving: '+06',
        },
        countryCodeIso: 'KAZ',
      },
      {
        name: 'Aqtau',
        countryCode: 'KZ',
        value: 'Asia/Aqtau',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'KAZ',
      },
      {
        name: 'Atyrau',
        countryCode: 'KZ',
        value: 'Asia/Atyrau',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'KAZ',
      },
      {
        name: 'Oral',
        countryCode: 'KZ',
        value: 'Asia/Oral',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'KAZ',
      },
      {
        name: 'Qostanay',
        countryCode: 'KZ',
        value: 'Asia/Qostanay',
        offset: {
          standard: '+06:00',
          daylightSaving: '+06:00',
        },
        abbreviation: {
          standard: '+06',
          daylightSaving: '+06',
        },
        countryCodeIso: 'KAZ',
      },
      {
        name: 'Qyzylorda',
        countryCode: 'KZ',
        value: 'Asia/Qyzylorda',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'KAZ',
      },
    ],
  },
  {
    name: 'Kenya',
    code: 'KEN',
    capital: 'Nairobi',
    region: 'AF',
    currency: {
      code: 'KES',
      name: 'Kenyan shilling',
      symbol: 'Sh',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/ken.svg',
    callingCode: '+254',
    isoCode4217: '404',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'KE',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'KEN',
      },
    ],
  },
  {
    name: 'Kiribati',
    code: 'KIR',
    capital: 'South Tarawa',
    region: 'OC',
    currency: {
      code: 'AUD',
      name: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/kir.svg',
    callingCode: '+686',
    isoCode4217: '296',
    timezones: [
      {
        name: 'Kanton',
        countryCode: 'KI',
        value: 'Pacific/Kanton',
        offset: {
          standard: '+13:00',
          daylightSaving: '+13:00',
        },
        abbreviation: {
          standard: '+13',
          daylightSaving: '+13',
        },
        countryCodeIso: 'KIR',
      },
      {
        name: 'Kiritimati',
        countryCode: 'KI',
        value: 'Pacific/Kiritimati',
        offset: {
          standard: '+14:00',
          daylightSaving: '+14:00',
        },
        abbreviation: {
          standard: '+14',
          daylightSaving: '+14',
        },
        countryCodeIso: 'KIR',
      },
      {
        name: 'Tarawa',
        countryCode: 'KI',
        value: 'Pacific/Tarawa',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'KIR',
      },
    ],
  },
  {
    name: 'Kuwait',
    code: 'KWT',
    capital: 'Kuwait City',
    region: 'AS',
    currency: {
      code: 'KWD',
      name: 'Kuwaiti dinar',
      symbol: 'د.ك',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/kwt.svg',
    callingCode: '+965',
    isoCode4217: '414',
    timezones: [
      {
        name: 'Riyadh',
        countryCode: 'KW',
        value: 'Asia/Riyadh',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'KWT',
      },
    ],
  },
  {
    name: 'Kyrgyzstan',
    code: 'KGZ',
    capital: 'Bishkek',
    region: 'AS',
    currency: {
      code: 'KGS',
      name: 'Kyrgyzstani som',
      symbol: 'с',
    },
    language: {
      code: 'ky',
      name: 'Kyrgyz',
    },
    flag: 'https://restcountries.eu/data/kgz.svg',
    callingCode: '+996',
    isoCode4217: '417',
    timezones: [
      {
        name: 'Bishkek',
        countryCode: 'KG',
        value: 'Asia/Bishkek',
        offset: {
          standard: '+06:00',
          daylightSaving: '+06:00',
        },
        abbreviation: {
          standard: '+06',
          daylightSaving: '+06',
        },
        countryCodeIso: 'KGZ',
      },
    ],
  },
  {
    name: "Lao People's Democratic Republic",
    code: 'LAO',
    capital: 'Vientiane',
    region: 'AS',
    currency: {
      code: 'LAK',
      name: 'Lao kip',
      symbol: '₭',
    },
    language: {
      code: 'lo',
      name: 'Lao',
    },
    flag: 'https://restcountries.eu/data/lao.svg',
    callingCode: '+856',
    isoCode4217: '418',
    timezones: [
      {
        name: 'Bangkok',
        countryCode: 'LA',
        value: 'Asia/Bangkok',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'LAO',
      },
    ],
  },
  {
    name: 'Latvia',
    code: 'LVA',
    capital: 'Riga',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'lv',
      name: 'Latvian',
    },
    flag: 'https://restcountries.eu/data/lva.svg',
    callingCode: '+371',
    isoCode4217: '428',
    timezones: [
      {
        name: 'Riga',
        countryCode: 'LV',
        value: 'Europe/Riga',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'LVA',
      },
    ],
  },
  {
    name: 'Lebanon',
    code: 'LBN',
    capital: 'Beirut',
    region: 'AS',
    currency: {
      code: 'LBP',
      name: 'Lebanese pound',
      symbol: 'ل.ل',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/lbn.svg',
    callingCode: '+961',
    isoCode4217: '422',
    timezones: [
      {
        name: 'Beirut',
        countryCode: 'LB',
        value: 'Asia/Beirut',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'LBN',
      },
    ],
  },
  {
    name: 'Lesotho',
    code: 'LSO',
    capital: 'Maseru',
    region: 'AF',
    currency: {
      code: 'LSL',
      name: 'Lesotho loti',
      symbol: 'L',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/lso.svg',
    callingCode: '+266',
    isoCode4217: '426',
    timezones: [
      {
        name: 'Johannesburg',
        countryCode: 'LS',
        value: 'Africa/Johannesburg',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'SAST',
          daylightSaving: 'SAST',
        },
        countryCodeIso: 'LSO',
      },
    ],
  },
  {
    name: 'Liberia',
    code: 'LBR',
    capital: 'Monrovia',
    region: 'AF',
    currency: {
      code: 'LRD',
      name: 'Liberian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/lbr.svg',
    callingCode: '+231',
    isoCode4217: '430',
    timezones: [
      {
        name: 'Monrovia',
        countryCode: 'LR',
        value: 'Africa/Monrovia',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'LBR',
      },
    ],
  },
  {
    name: 'Libya',
    code: 'LBY',
    capital: 'Tripoli',
    region: 'AF',
    currency: {
      code: 'LYD',
      name: 'Libyan dinar',
      symbol: 'ل.د',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/lby.svg',
    callingCode: '+218',
    isoCode4217: '434',
    timezones: [
      {
        name: 'Tripoli',
        countryCode: 'LY',
        value: 'Africa/Tripoli',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EET',
        },
        countryCodeIso: 'LBY',
      },
    ],
  },
  {
    name: 'Liechtenstein',
    code: 'LIE',
    capital: 'Vaduz',
    region: 'EU',
    currency: {
      code: 'CHF',
      name: 'Swiss franc',
      symbol: 'Fr',
    },
    language: {
      code: 'de',
      name: 'German',
    },
    flag: 'https://restcountries.eu/data/lie.svg',
    callingCode: '+423',
    isoCode4217: '438',
    timezones: [
      {
        name: 'Brussels',
        countryCode: 'LI',
        value: 'Europe/Brussels',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'LIE',
      },
    ],
  },
  {
    name: 'Lithuania',
    code: 'LTU',
    capital: 'Vilnius',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'lt',
      name: 'Lithuanian',
    },
    flag: 'https://restcountries.eu/data/ltu.svg',
    callingCode: '+370',
    isoCode4217: '440',
    timezones: [
      {
        name: 'Vilnius',
        countryCode: 'LT',
        value: 'Europe/Vilnius',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'LTU',
      },
    ],
  },
  {
    name: 'Luxembourg',
    code: 'LUX',
    capital: 'Luxembourg',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/lux.svg',
    callingCode: '+352',
    isoCode4217: '442',
    timezones: [
      {
        name: 'Brussels',
        countryCode: 'LU',
        value: 'Europe/Brussels',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'LUX',
      },
    ],
  },
  {
    name: 'Macao',
    code: 'MAC',
    capital: '',
    region: 'AS',
    currency: {
      code: 'MOP',
      name: 'Macanese pataca',
      symbol: 'P',
    },
    language: {
      code: 'zh',
      name: 'Chinese',
    },
    flag: 'https://restcountries.eu/data/mac.svg',
    callingCode: '+853',
    isoCode4217: '446',
    timezones: [
      {
        name: 'Macau',
        countryCode: 'MO',
        value: 'Asia/Macau',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'MAC',
      },
    ],
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    code: 'MKD',
    capital: 'Skopje',
    region: 'EU',
    currency: {
      code: 'MKD',
      name: 'Macedonian denar',
      symbol: 'ден',
    },
    language: {
      code: 'mk',
      name: 'Macedonian',
    },
    flag: 'https://restcountries.eu/data/mkd.svg',
    callingCode: '+389',
    isoCode4217: '807',
    timezones: [
      {
        name: 'Belgrade',
        countryCode: 'MK',
        value: 'Europe/Belgrade',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'MKD',
      },
    ],
  },
  {
    name: 'Madagascar',
    code: 'MDG',
    capital: 'Antananarivo',
    region: 'AF',
    currency: {
      code: 'MGA',
      name: 'Malagasy ariary',
      symbol: 'Ar',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/mdg.svg',
    callingCode: '+261',
    isoCode4217: '450',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'MG',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'MDG',
      },
    ],
  },
  {
    name: 'Malawi',
    code: 'MWI',
    capital: 'Lilongwe',
    region: 'AF',
    currency: {
      code: 'MWK',
      name: 'Malawian kwacha',
      symbol: 'MK',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/mwi.svg',
    callingCode: '+265',
    isoCode4217: '454',
    timezones: [
      {
        name: 'Maputo',
        countryCode: 'MW',
        value: 'Africa/Maputo',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'MWI',
      },
    ],
  },
  {
    name: 'Malaysia',
    code: 'MYS',
    capital: 'Kuala Lumpur',
    region: 'AS',
    currency: {
      code: 'MYR',
      name: 'Malaysian ringgit',
      symbol: 'RM',
    },
    language: {
      code: 'ZSM',
      name: 'Malaysian',
    },
    flag: 'https://restcountries.eu/data/mys.svg',
    callingCode: '+60',
    isoCode4217: '458',
    timezones: [
      {
        name: 'Kuching',
        countryCode: 'MY',
        value: 'Asia/Kuching',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: '+08',
          daylightSaving: '+08',
        },
        countryCodeIso: 'MYS',
      },
      {
        name: 'Singapore',
        countryCode: 'MY',
        value: 'Asia/Singapore',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: '+08',
          daylightSaving: '+08',
        },
        countryCodeIso: 'MYS',
      },
    ],
  },
  {
    name: 'Maldives',
    code: 'MDV',
    capital: 'Malé',
    region: 'AS',
    currency: {
      code: 'MVR',
      name: 'Maldivian rufiyaa',
      symbol: '.ރ',
    },
    language: {
      code: 'dv',
      name: 'Divehi',
    },
    flag: 'https://restcountries.eu/data/mdv.svg',
    callingCode: '+960',
    isoCode4217: '462',
    timezones: [
      {
        name: 'Oral',
        countryCode: 'MV',
        value: 'Asia/Oral',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'MDV',
      },
    ],
  },
  {
    name: 'Mali',
    code: 'MLI',
    capital: 'Bamako',
    region: 'AF',
    currency: {
      code: 'XOF',
      name: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/mli.svg',
    callingCode: '+223',
    isoCode4217: '466',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'ML',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'MLI',
      },
    ],
  },
  {
    name: 'Malta',
    code: 'MLT',
    capital: 'Valletta',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'mt',
      name: 'Maltese',
    },
    flag: 'https://restcountries.eu/data/mlt.svg',
    callingCode: '+356',
    isoCode4217: '470',
    timezones: [
      {
        name: 'Malta',
        countryCode: 'MT',
        value: 'Europe/Malta',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'MLT',
      },
    ],
  },
  {
    name: 'Marshall Islands',
    code: 'MHL',
    capital: 'Majuro',
    region: 'OC',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/mhl.svg',
    callingCode: '+692',
    isoCode4217: '584',
    timezones: [
      {
        name: 'Kwajalein',
        countryCode: 'MH',
        value: 'Pacific/Kwajalein',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'MHL',
      },
      {
        name: 'Tarawa',
        countryCode: 'MH',
        value: 'Pacific/Tarawa',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'MHL',
      },
    ],
  },
  {
    name: 'Martinique',
    code: 'MTQ',
    capital: 'Fort-de-France',
    region: 'Americas',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/mtq.svg',
    callingCode: '+596',
    isoCode4217: '474',
    timezones: [
      {
        name: 'Martinique',
        countryCode: 'MQ',
        value: 'America/Martinique',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'MTQ',
      },
    ],
  },
  {
    name: 'Mauritania',
    code: 'MRT',
    capital: 'Nouakchott',
    region: 'AF',
    currency: {
      code: 'MRO',
      name: 'Mauritanian ouguiya',
      symbol: 'UM',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/mrt.svg',
    callingCode: '+222',
    isoCode4217: '478',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'MR',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'MRT',
      },
    ],
  },
  {
    name: 'Mauritius',
    code: 'MUS',
    capital: 'Port Louis',
    region: 'AF',
    currency: {
      code: 'MUR',
      name: 'Mauritian rupee',
      symbol: '₨',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/mus.svg',
    callingCode: '+230',
    isoCode4217: '480',
    timezones: [
      {
        name: 'Mauritius',
        countryCode: 'MU',
        value: 'Asia/Baku',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'MUS',
      },
    ],
  },
  {
    name: 'Mayotte',
    code: 'MYT',
    capital: 'Mamoudzou',
    region: 'AF',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/myt.svg',
    callingCode: '+262',
    isoCode4217: '175',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'YT',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'MYT',
      },
    ],
  },
  {
    name: 'Mexico',
    code: 'MEX',
    capital: 'Mexico City',
    region: 'NA',
    currency: {
      code: 'MXN',
      name: 'Mexican peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/mex.svg',
    callingCode: '+52',
    isoCode4217: '484',
    timezones: [
      {
        name: 'Bahia Banderas',
        countryCode: 'MX',
        value: 'America/Bahia_Banderas',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Cancun',
        countryCode: 'MX',
        value: 'America/Cancun',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EST',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Chihuahua',
        countryCode: 'MX',
        value: 'America/Chihuahua',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Ciudad Juarez',
        countryCode: 'MX',
        value: 'America/Ciudad_Juarez',
        offset: {
          standard: '-07:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MDT',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Hermosillo',
        countryCode: 'MX',
        value: 'America/Hermosillo',
        offset: {
          standard: '-07:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MST',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Matamoros',
        countryCode: 'MX',
        value: 'America/Matamoros',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Mazatlan',
        countryCode: 'MX',
        value: 'America/Mazatlan',
        offset: {
          standard: '-07:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MST',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Merida',
        countryCode: 'MX',
        value: 'America/Merida',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Mexico City',
        countryCode: 'MX',
        value: 'America/Mexico_City',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Monterrey',
        countryCode: 'MX',
        value: 'America/Monterrey',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Ojinaga',
        countryCode: 'MX',
        value: 'America/Ojinaga',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'MEX',
      },
      {
        name: 'Tijuana',
        countryCode: 'MX',
        value: 'America/Tijuana',
        offset: {
          standard: '-08:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'PST',
          daylightSaving: 'PDT',
        },
        countryCodeIso: 'MEX',
      },
    ],
  },
  {
    name: 'Micronesia (Federated States of)',
    code: 'FSM',
    capital: 'Palikir',
    region: 'OC',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/fsm.svg',
    callingCode: '+691',
    isoCode4217: '583',
    timezones: [
      {
        name: 'Guadalcanal',
        countryCode: 'FM',
        value: 'Pacific/Guadalcanal',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'FSM',
      },
      {
        name: 'Kosrae',
        countryCode: 'FM',
        value: 'Pacific/Kosrae',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'FSM',
      },
      {
        name: 'Port Moresby',
        countryCode: 'FM',
        value: 'Pacific/Port_Moresby',
        offset: {
          standard: '+10:00',
          daylightSaving: '+10:00',
        },
        abbreviation: {
          standard: '+10',
          daylightSaving: '+10',
        },
        countryCodeIso: 'FSM',
      },
    ],
  },
  {
    name: 'Moldova (Republic of)',
    code: 'MDA',
    capital: 'Chișinău',
    region: 'EU',
    currency: {
      code: 'MDL',
      name: 'Moldovan leu',
      symbol: 'L',
    },
    language: {
      code: 'ro',
      name: 'Romanian',
    },
    flag: 'https://restcountries.eu/data/mda.svg',
    callingCode: '+373',
    isoCode4217: '498',
    timezones: [
      {
        name: 'Chisinau',
        countryCode: 'MD',
        value: 'Europe/Chisinau',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'MDA',
      },
    ],
  },
  {
    name: 'Monaco',
    code: 'MCO',
    capital: 'Monaco',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/mco.svg',
    callingCode: '+377',
    isoCode4217: '492',
    timezones: [
      {
        name: 'Paris',
        countryCode: 'MC',
        value: 'Europe/Paris',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'MCO',
      },
    ],
  },
  {
    name: 'Mongolia',
    code: 'MNG',
    capital: 'Ulan Bator',
    region: 'AS',
    currency: {
      code: 'MNT',
      name: 'Mongolian tögrög',
      symbol: '₮',
    },
    language: {
      code: 'mn',
      name: 'Mongolian',
    },
    flag: 'https://restcountries.eu/data/mng.svg',
    callingCode: '+976',
    isoCode4217: '496',
    timezones: [
      {
        name: 'Choibalsan',
        countryCode: 'MN',
        value: 'Asia/Choibalsan',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: '+08',
          daylightSaving: '+08',
        },
        countryCodeIso: 'MNG',
      },
      {
        name: 'Hovd',
        countryCode: 'MN',
        value: 'Asia/Hovd',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'MNG',
      },
      {
        name: 'Ulaanbaatar',
        countryCode: 'MN',
        value: 'Asia/Ulaanbaatar',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: '+08',
          daylightSaving: '+08',
        },
        countryCodeIso: 'MNG',
      },
    ],
  },
  {
    name: 'Montserrat',
    code: 'MSR',
    capital: 'Plymouth',
    region: 'NA',
    currency: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/msr.svg',
    callingCode: '+1',
    isoCode4217: '500',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'MS',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'MSR',
      },
    ],
  },
  {
    name: 'Morocco',
    code: 'MAR',
    capital: 'Rabat',
    region: 'AF',
    currency: {
      code: 'MAD',
      name: 'Moroccan dirham',
      symbol: 'د.م.',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/mar.svg',
    callingCode: '+212',
    isoCode4217: '504',
    timezones: [
      {
        name: 'Casablanca',
        countryCode: 'MA',
        value: 'Africa/Casablanca',
        offset: {
          standard: '+01:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: '+01',
          daylightSaving: '+00',
        },
        countryCodeIso: 'MAR',
      },
    ],
  },
  {
    name: 'Mozambique',
    code: 'MOZ',
    capital: 'Maputo',
    region: 'AF',
    currency: {
      code: 'MZN',
      name: 'Mozambican metical',
      symbol: 'MT',
    },
    language: {
      code: 'pt',
      name: 'Portuguese',
    },
    flag: 'https://restcountries.eu/data/moz.svg',
    callingCode: '+258',
    isoCode4217: '508',
    timezones: [
      {
        name: 'Maputo',
        countryCode: 'MZ',
        value: 'Africa/Maputo',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'MOZ',
      },
    ],
  },
  {
    name: 'Myanmar',
    code: 'MMR',
    capital: 'Naypyidaw',
    region: 'AS',
    currency: {
      code: 'MMK',
      name: 'Burmese kyat',
      symbol: 'Ks',
    },
    language: {
      code: 'my',
      name: 'Burmese',
    },
    flag: 'https://restcountries.eu/data/mmr.svg',
    callingCode: '+95',
    isoCode4217: '104',
    timezones: [
      {
        name: 'Yangon',
        countryCode: 'MM',
        value: 'Asia/Yangon',
        offset: {
          standard: '+06:30',
          daylightSaving: '+06:30',
        },
        abbreviation: {
          standard: '+0630',
          daylightSaving: '+0630',
        },
        countryCodeIso: 'MMR',
      },
    ],
  },
  {
    name: 'Namibia',
    code: 'NAM',
    capital: 'Windhoek',
    region: 'AF',
    currency: {
      code: 'NAD',
      name: 'Namibian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/nam.svg',
    callingCode: '+264',
    isoCode4217: '516',
    timezones: [
      {
        name: 'Windhoek',
        countryCode: 'NA',
        value: 'Africa/Windhoek',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'NAM',
      },
    ],
  },
  {
    name: 'Nauru',
    code: 'NRU',
    capital: 'Yaren',
    region: 'OC',
    currency: {
      code: 'AUD',
      name: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/nru.svg',
    callingCode: '+674',
    isoCode4217: '520',
    timezones: [
      {
        name: 'Nauru',
        countryCode: 'NR',
        value: 'Pacific/Nauru',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'NRU',
      },
    ],
  },
  {
    name: 'Nepal',
    code: 'NPL',
    capital: 'Kathmandu',
    region: 'AS',
    currency: {
      code: 'NPR',
      name: 'Nepalese rupee',
      symbol: '₨',
    },
    language: {
      code: 'ne',
      name: 'Nepali',
    },
    flag: 'https://restcountries.eu/data/npl.svg',
    callingCode: '+977',
    isoCode4217: '524',
    timezones: [
      {
        name: 'Kathmandu',
        countryCode: 'NP',
        value: 'Asia/Kathmandu',
        offset: {
          standard: '+05:45',
          daylightSaving: '+05:45',
        },
        abbreviation: {
          standard: '+0545',
          daylightSaving: '+0545',
        },
        countryCodeIso: 'NPL',
      },
    ],
  },
  {
    name: 'Netherlands',
    code: 'NLD',
    capital: 'Amsterdam',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'nl',
      name: 'Dutch',
    },
    flag: 'https://restcountries.eu/data/nld.svg',
    callingCode: '+31',
    isoCode4217: '528',
    timezones: [
      {
        name: 'Brussels',
        countryCode: 'NL',
        value: 'Europe/Brussels',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'NLD',
      },
    ],
  },
  {
    name: 'New Caledonia',
    code: 'NCL',
    capital: 'Nouméa',
    region: 'OC',
    currency: {
      code: 'XPF',
      name: 'CFP franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/ncl.svg',
    callingCode: '+687',
    isoCode4217: '540',
    timezones: [
      {
        name: 'Noumea',
        countryCode: 'NC',
        value: 'Pacific/Noumea',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'NCL',
      },
    ],
  },
  {
    name: 'New Zealand',
    code: 'NZL',
    capital: 'Wellington',
    region: 'OC',
    currency: {
      code: 'NZD',
      name: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/nzl.svg',
    callingCode: '+64',
    isoCode4217: '554',
    timezones: [
      {
        name: 'Auckland',
        countryCode: 'NZ',
        value: 'Pacific/Auckland',
        offset: {
          standard: '+12:00',
          daylightSaving: '+13:00',
        },
        abbreviation: {
          standard: 'NZST',
          daylightSaving: 'NZDT',
        },
        countryCodeIso: 'NZL',
      },
      {
        name: 'Chatham',
        countryCode: 'NZ',
        value: 'Pacific/Chatham',
        offset: {
          standard: '+12:45',
          daylightSaving: '+13:45',
        },
        abbreviation: {
          standard: '+1245',
          daylightSaving: '+1345',
        },
        countryCodeIso: 'NZL',
      },
    ],
  },
  {
    name: 'Nicaragua',
    code: 'NIC',
    capital: 'Managua',
    region: 'NA',
    currency: {
      code: 'NIO',
      name: 'Nicaraguan córdoba',
      symbol: 'C$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/nic.svg',
    callingCode: '+505',
    isoCode4217: '558',
    timezones: [
      {
        name: 'Managua',
        countryCode: 'NI',
        value: 'America/Managua',
        offset: {
          standard: '-06:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'NIC',
      },
    ],
  },
  {
    name: 'Niger',
    code: 'NER',
    capital: 'Niamey',
    region: 'AF',
    currency: {
      code: 'XOF',
      name: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/ner.svg',
    callingCode: '+227',
    isoCode4217: '562',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'NE',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'NER',
      },
    ],
  },
  {
    name: 'Nigeria',
    code: 'NGA',
    capital: 'Abuja',
    region: 'AF',
    currency: {
      code: 'NGN',
      name: 'Nigerian naira',
      symbol: '₦',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/nga.svg',
    callingCode: '+234',
    isoCode4217: '566',
    timezones: [
      {
        name: 'Lagos',
        countryCode: 'NG',
        value: 'Africa/Lagos',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WAT',
          daylightSaving: 'WAT',
        },
        countryCodeIso: 'NGA',
      },
    ],
  },
  {
    name: 'Niue',
    code: 'NIU',
    capital: 'Alofi',
    region: 'OC',
    currency: {
      code: 'NZD',
      name: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/niu.svg',
    callingCode: '+683',
    isoCode4217: '570',
    timezones: [
      {
        name: 'Niue',
        countryCode: 'NU',
        value: 'Pacific/Niue',
        offset: {
          standard: '-11:00',
          daylightSaving: '-11:00',
        },
        abbreviation: {
          standard: '-11',
          daylightSaving: '-11',
        },
        countryCodeIso: 'NIU',
      },
    ],
  },
  {
    name: 'Norfolk Island',
    code: 'NFK',
    capital: 'Kingston',
    region: 'OC',
    currency: {
      code: 'AUD',
      name: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/nfk.svg',
    callingCode: '+672',
    isoCode4217: '574',
    timezones: [
      {
        name: 'Norfolk',
        countryCode: 'NF',
        value: 'Pacific/Norfolk',
        offset: {
          standard: '+11:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+12',
        },
        countryCodeIso: 'NFK',
      },
    ],
  },
  {
    name: 'North Korea',
    code: 'PRK',
    capital: 'Pyongyang',
    region: 'AS',
    currency: {
      code: 'KPW',
      name: 'North Korean won',
      symbol: '₩',
    },
    language: {
      code: 'ko',
      name: 'Korean',
    },
    flag: 'https://restcountries.eu/data/prk.svg',
    callingCode: '+850',
    isoCode4217: '408',
    timezones: [
      {
        name: 'Pyongyang',
        countryCode: 'KP',
        value: 'Asia/Pyongyang',
        offset: {
          standard: '+09:00',
          daylightSaving: '+09:00',
        },
        abbreviation: {
          standard: 'KST',
          daylightSaving: 'KST',
        },
        countryCodeIso: 'PRK',
      },
    ],
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MNP',
    capital: 'Saipan',
    region: 'OC',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/mnp.svg',
    callingCode: '+1',
    isoCode4217: '580',
    timezones: [
      {
        name: 'Guam',
        countryCode: 'MP',
        value: 'Pacific/Guam',
        offset: {
          standard: '+10:00',
          daylightSaving: '+10:00',
        },
        abbreviation: {
          standard: 'ChST',
          daylightSaving: 'ChST',
        },
        countryCodeIso: 'MNP',
      },
    ],
  },
  {
    name: 'Norway',
    code: 'NOR',
    capital: 'Oslo',
    region: 'EU',
    currency: {
      code: 'NOK',
      name: 'Norwegian krone',
      symbol: 'kr',
    },
    language: {
      code: 'no',
      name: 'Norwegian',
    },
    flag: 'https://restcountries.eu/data/nor.svg',
    callingCode: '+47',
    isoCode4217: '578',
    timezones: [
      {
        name: 'Berlin',
        countryCode: 'NO',
        value: 'Europe/Berlin',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'NOR',
      },
    ],
  },
  {
    name: 'Oman',
    code: 'OMN',
    capital: 'Muscat',
    region: 'AS',
    currency: {
      code: 'OMR',
      name: 'Omani rial',
      symbol: 'ر.ع.',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/omn.svg',
    callingCode: '+968',
    isoCode4217: '512',
    timezones: [
      {
        name: 'Dubai',
        countryCode: 'OM',
        value: 'Asia/Dubai',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'OMN',
      },
    ],
  },
  {
    name: 'Pakistan',
    code: 'PAK',
    capital: 'Islamabad',
    region: 'AS',
    currency: {
      code: 'PKR',
      name: 'Pakistani rupee',
      symbol: '₨',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/pak.svg',
    callingCode: '+92',
    isoCode4217: '586',
    timezones: [
      {
        name: 'Karachi',
        countryCode: 'PK',
        value: 'Asia/Karachi',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: 'PKT',
          daylightSaving: 'PKT',
        },
        countryCodeIso: 'PAK',
      },
    ],
  },
  {
    name: 'Palau',
    code: 'PLW',
    capital: 'Ngerulmud',
    region: 'OC',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/plw.svg',
    callingCode: '+680',
    isoCode4217: '585',
    timezones: [
      {
        name: 'Palau',
        countryCode: 'PW',
        value: 'Pacific/Palau',
        offset: {
          standard: '+09:00',
          daylightSaving: '+09:00',
        },
        abbreviation: {
          standard: '+09',
          daylightSaving: '+09',
        },
        countryCodeIso: 'PLW',
      },
    ],
  },
  {
    name: 'Panama',
    code: 'PAN',
    capital: 'Panama City',
    region: 'NA',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/pan.svg',
    callingCode: '+507',
    isoCode4217: '591',
    timezones: [
      {
        name: 'Panama',
        countryCode: 'PA',
        value: 'America/Panama',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EST',
        },
        countryCodeIso: 'PAN',
      },
    ],
  },
  {
    name: 'Papua New Guinea',
    code: 'PNG',
    capital: 'Port Moresby',
    region: 'OC',
    currency: {
      code: 'PGK',
      name: 'Papua New Guinean kina',
      symbol: 'K',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/png.svg',
    callingCode: '+675',
    isoCode4217: '598',
    timezones: [
      {
        name: 'Bougainville',
        countryCode: 'PG',
        value: 'Pacific/Bougainville',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'PNG',
      },
      {
        name: 'Port Moresby',
        countryCode: 'PG',
        value: 'Pacific/Port_Moresby',
        offset: {
          standard: '+10:00',
          daylightSaving: '+10:00',
        },
        abbreviation: {
          standard: '+10',
          daylightSaving: '+10',
        },
        countryCodeIso: 'PNG',
      },
    ],
  },
  {
    name: 'Paraguay',
    code: 'PRY',
    capital: 'Asunción',
    region: 'SA',
    currency: {
      code: 'PYG',
      name: 'Paraguayan guaraní',
      symbol: '₲',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/pry.svg',
    callingCode: '+595',
    isoCode4217: '600',
    timezones: [
      {
        name: 'Asuncion',
        countryCode: 'PY',
        value: 'America/Asuncion',
        offset: {
          standard: '-04:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-03',
        },
        countryCodeIso: 'PRY',
      },
    ],
  },
  {
    name: 'Peru',
    code: 'PER',
    capital: 'Lima',
    region: 'SA',
    currency: {
      code: 'PEN',
      name: 'Peruvian sol',
      symbol: 'S/.',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/per.svg',
    callingCode: '+51',
    isoCode4217: '604',
    timezones: [
      {
        name: 'Lima',
        countryCode: 'PE',
        value: 'America/Lima',
        offset: {
          standard: '-05:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: '-05',
          daylightSaving: '-05',
        },
        countryCodeIso: 'PER',
      },
    ],
  },
  {
    name: 'Philippines',
    code: 'PHL',
    capital: 'Manila',
    region: 'AS',
    currency: {
      code: 'PHP',
      name: 'Philippine peso',
      symbol: '₱',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/phl.svg',
    callingCode: '+63',
    isoCode4217: '608',
    timezones: [
      {
        name: 'Manila',
        countryCode: 'PH',
        value: 'Asia/Manila',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: 'PST',
          daylightSaving: 'PST',
        },
        countryCodeIso: 'PHL',
      },
    ],
  },
  {
    name: 'Poland',
    code: 'POL',
    capital: 'Warsaw',
    region: 'EU',
    currency: {
      code: 'PLN',
      name: 'Polish złoty',
      symbol: 'zł',
    },
    language: {
      code: 'pl',
      name: 'Polish',
    },
    flag: 'https://restcountries.eu/data/pol.svg',
    callingCode: '+48',
    isoCode4217: '616',
    timezones: [
      {
        name: 'Warsaw',
        countryCode: 'PL',
        value: 'Europe/Warsaw',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'POL',
      },
    ],
  },
  {
    name: 'Portugal',
    code: 'PRT',
    capital: 'Lisbon',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'pt',
      name: 'Portuguese',
    },
    flag: 'https://restcountries.eu/data/prt.svg',
    callingCode: '+351',
    isoCode4217: '620',
    timezones: [
      {
        name: 'Azores',
        countryCode: 'PT',
        value: 'Atlantic/Azores',
        offset: {
          standard: '-01:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: '-01',
          daylightSaving: '+00',
        },
        countryCodeIso: 'PRT',
      },
      {
        name: 'Madeira',
        countryCode: 'PT',
        value: 'Atlantic/Madeira',
        offset: {
          standard: '+00:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WET',
          daylightSaving: 'WEST',
        },
        countryCodeIso: 'PRT',
      },
      {
        name: 'Lisbon',
        countryCode: 'PT',
        value: 'Europe/Lisbon',
        offset: {
          standard: '+00:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WET',
          daylightSaving: 'WEST',
        },
        countryCodeIso: 'PRT',
      },
    ],
  },
  {
    name: 'Puerto Rico',
    code: 'PRI',
    capital: 'San Juan',
    region: 'NA',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/pri.svg',
    callingCode: '+1',
    isoCode4217: '630',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'PR',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'PRI',
      },
    ],
  },
  {
    name: 'Qatar',
    code: 'QAT',
    capital: 'Doha',
    region: 'AS',
    currency: {
      code: 'QAR',
      name: 'Qatari riyal',
      symbol: 'ر.ق',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/qat.svg',
    callingCode: '+974',
    isoCode4217: '634',
    timezones: [
      {
        name: 'Qatar',
        countryCode: 'QA',
        value: 'Asia/Qatar',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'QAT',
      },
    ],
  },
  {
    name: 'Réunion',
    code: 'REU',
    capital: 'Saint-Denis',
    region: 'AF',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/reu.svg',
    callingCode: '+262',
    isoCode4217: '638',
    timezones: [
      {
        name: 'Dubai',
        countryCode: 'RE',
        value: 'Asia/Dubai',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'REU',
      },
    ],
  },
  {
    name: 'Romania',
    code: 'ROU',
    capital: 'Bucharest',
    region: 'EU',
    currency: {
      code: 'RON',
      name: 'Romanian leu',
      symbol: 'lei',
    },
    language: {
      code: 'ro',
      name: 'Romanian',
    },
    flag: 'https://restcountries.eu/data/rou.svg',
    callingCode: '+40',
    isoCode4217: '642',
    timezones: [
      {
        name: 'Bucharest',
        countryCode: 'RO',
        value: 'Europe/Bucharest',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'ROU',
      },
    ],
  },
  {
    name: 'Russian Federation',
    code: 'RUS',
    capital: 'Moscow',
    region: 'EU',
    currency: {
      code: 'RUB',
      name: 'Russian ruble',
      symbol: '₽',
    },
    language: {
      code: 'ru',
      name: 'Russian',
    },
    flag: 'https://restcountries.eu/data/rus.svg',
    callingCode: '+7',
    isoCode4217: '643',
    timezones: [
      {
        name: 'Anadyr',
        countryCode: 'RU',
        value: 'Asia/Anadyr',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Barnaul',
        countryCode: 'RU',
        value: 'Asia/Barnaul',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Chita',
        countryCode: 'RU',
        value: 'Asia/Chita',
        offset: {
          standard: '+09:00',
          daylightSaving: '+09:00',
        },
        abbreviation: {
          standard: '+09',
          daylightSaving: '+09',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Irkutsk',
        countryCode: 'RU',
        value: 'Asia/Irkutsk',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: '+08',
          daylightSaving: '+08',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Kamchatka',
        countryCode: 'RU',
        value: 'Asia/Kamchatka',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Khandyga',
        countryCode: 'RU',
        value: 'Asia/Khandyga',
        offset: {
          standard: '+09:00',
          daylightSaving: '+09:00',
        },
        abbreviation: {
          standard: '+09',
          daylightSaving: '+09',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Krasnoyarsk',
        countryCode: 'RU',
        value: 'Asia/Krasnoyarsk',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Magadan',
        countryCode: 'RU',
        value: 'Asia/Magadan',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Novokuznetsk',
        countryCode: 'RU',
        value: 'Asia/Novokuznetsk',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Novosibirsk',
        countryCode: 'RU',
        value: 'Asia/Novosibirsk',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Omsk',
        countryCode: 'RU',
        value: 'Asia/Omsk',
        offset: {
          standard: '+06:00',
          daylightSaving: '+06:00',
        },
        abbreviation: {
          standard: '+06',
          daylightSaving: '+06',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Sakhalin',
        countryCode: 'RU',
        value: 'Asia/Sakhalin',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Srednekolymsk',
        countryCode: 'RU',
        value: 'Asia/Srednekolymsk',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Tomsk',
        countryCode: 'RU',
        value: 'Asia/Tomsk',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Ust-Nera',
        countryCode: 'RU',
        value: 'Asia/Ust-Nera',
        offset: {
          standard: '+10:00',
          daylightSaving: '+10:00',
        },
        abbreviation: {
          standard: '+10',
          daylightSaving: '+10',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Vladivostok',
        countryCode: 'RU',
        value: 'Asia/Vladivostok',
        offset: {
          standard: '+10:00',
          daylightSaving: '+10:00',
        },
        abbreviation: {
          standard: '+10',
          daylightSaving: '+10',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Yakutsk',
        countryCode: 'RU',
        value: 'Asia/Yakutsk',
        offset: {
          standard: '+09:00',
          daylightSaving: '+09:00',
        },
        abbreviation: {
          standard: '+09',
          daylightSaving: '+09',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Yekaterinburg',
        countryCode: 'RU',
        value: 'Asia/Yekaterinburg',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Astrakhan',
        countryCode: 'RU',
        value: 'Europe/Astrakhan',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Kaliningrad',
        countryCode: 'RU',
        value: 'Europe/Kaliningrad',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EET',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Kirov',
        countryCode: 'RU',
        value: 'Europe/Kirov',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Moscow',
        countryCode: 'RU',
        value: 'Europe/Moscow',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'MSK',
          daylightSaving: 'MSK',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Samara',
        countryCode: 'RU',
        value: 'Europe/Samara',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Saratov',
        countryCode: 'RU',
        value: 'Europe/Saratov',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Simferopol',
        countryCode: 'RU',
        value: 'Europe/Simferopol',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'MSK',
          daylightSaving: 'MSK',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Ulyanovsk',
        countryCode: 'RU',
        value: 'Europe/Ulyanovsk',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'RUS',
      },
      {
        name: 'Volgograd',
        countryCode: 'RU',
        value: 'Europe/Volgograd',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'RUS',
      },
    ],
  },
  {
    name: 'Rwanda',
    code: 'RWA',
    capital: 'Kigali',
    region: 'AF',
    currency: {
      code: 'RWF',
      name: 'Rwandan franc',
      symbol: 'Fr',
    },
    language: {
      code: 'rw',
      name: 'Kinyarwanda',
    },
    flag: 'https://restcountries.eu/data/rwa.svg',
    callingCode: '+250',
    isoCode4217: '646',
    timezones: [
      {
        name: 'Maputo',
        countryCode: 'RW',
        value: 'Africa/Maputo',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'RWA',
      },
    ],
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SHN',
    capital: 'Jamestown',
    region: 'AF',
    currency: {
      code: 'SHP',
      name: 'Saint Helena pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/shn.svg',
    callingCode: '+290',
    isoCode4217: '654',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'SH',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'SHN',
      },
    ],
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KNA',
    capital: 'Basseterre',
    region: 'NA',
    currency: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/kna.svg',
    callingCode: '+1',
    isoCode4217: '659',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'KN',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'KNA',
      },
    ],
  },
  {
    name: 'Saint Lucia',
    code: 'LCA',
    capital: 'Castries',
    region: 'NA',
    currency: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/lca.svg',
    callingCode: '+1',
    isoCode4217: '662',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'LC',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'LCA',
      },
    ],
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'SPM',
    capital: 'Saint-Pierre',
    region: 'NA',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/spm.svg',
    callingCode: '+508',
    isoCode4217: '666',
    timezones: [
      {
        name: 'Miquelon',
        countryCode: 'PM',
        value: 'America/Miquelon',
        offset: {
          standard: '-03:00',
          daylightSaving: '-02:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-02',
        },
        countryCodeIso: 'SPM',
      },
    ],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VCT',
    capital: 'Kingstown',
    region: 'NA',
    currency: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/vct.svg',
    callingCode: '+1',
    isoCode4217: '670',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'VC',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'VCT',
      },
    ],
  },
  {
    name: 'Samoa',
    code: 'WSM',
    capital: 'Apia',
    region: 'OC',
    currency: {
      code: 'WST',
      name: 'Samoan tālā',
      symbol: 'T',
    },
    language: {
      code: 'sm',
      name: 'Samoan',
    },
    flag: 'https://restcountries.eu/data/wsm.svg',
    callingCode: '+685',
    isoCode4217: '882',
    timezones: [
      {
        name: 'Apia',
        countryCode: 'WS',
        value: 'Pacific/Apia',
        offset: {
          standard: '+13:00',
          daylightSaving: '+13:00',
        },
        abbreviation: {
          standard: '+13',
          daylightSaving: '+13',
        },
        countryCodeIso: 'WSM',
      },
    ],
  },
  {
    name: 'San Marino',
    code: 'SMR',
    capital: 'City of San Marino',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'it',
      name: 'Italian',
    },
    flag: 'https://restcountries.eu/data/smr.svg',
    callingCode: '+378',
    isoCode4217: '674',
    timezones: [
      {
        name: 'Rome',
        countryCode: 'SM',
        value: 'Europe/Rome',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'SMR',
      },
    ],
  },
  {
    name: 'Sao Tome and Principe',
    code: 'STP',
    capital: 'São Tomé',
    region: 'AF',
    currency: {
      code: 'STD',
      name: 'São Tomé and Príncipe dobra',
      symbol: 'Db',
    },
    language: {
      code: 'pt',
      name: 'Portuguese',
    },
    flag: 'https://restcountries.eu/data/stp.svg',
    callingCode: '+239',
    isoCode4217: '678',
    timezones: [
      {
        name: 'Sao Tome',
        countryCode: 'ST',
        value: 'Africa/Sao_Tome',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'STP',
      },
    ],
  },
  {
    name: 'Saudi Arabia',
    code: 'SAU',
    capital: 'Riyadh',
    region: 'AS',
    currency: {
      code: 'SAR',
      name: 'Saudi riyal',
      symbol: 'ر.س',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/sau.svg',
    callingCode: '+966',
    isoCode4217: '682',
    timezones: [
      {
        name: 'Riyadh',
        countryCode: 'SA',
        value: 'Asia/Riyadh',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'SAU',
      },
    ],
  },
  {
    name: 'Senegal',
    code: 'SEN',
    capital: 'Dakar',
    region: 'AF',
    currency: {
      code: 'XOF',
      name: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/sen.svg',
    callingCode: '+221',
    isoCode4217: '686',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'SN',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'SEN',
      },
    ],
  },
  {
    name: 'Seychelles',
    code: 'SYC',
    capital: 'Victoria',
    region: 'AF',
    currency: {
      code: 'SCR',
      name: 'Seychellois rupee',
      symbol: '₨',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/syc.svg',
    callingCode: '+248',
    isoCode4217: '690',
    timezones: [
      {
        name: 'Dubai',
        countryCode: 'SC',
        value: 'Asia/Dubai',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'SYC',
      },
    ],
  },
  {
    name: 'Sierra Leone',
    code: 'SLE',
    capital: 'Freetown',
    region: 'AF',
    currency: {
      code: 'SLL',
      name: 'Sierra Leonean leone',
      symbol: 'Le',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/sle.svg',
    callingCode: '+232',
    isoCode4217: '694',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'SL',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'SLE',
      },
    ],
  },
  {
    name: 'Singapore',
    code: 'SGP',
    capital: 'Singapore',
    region: 'AS',
    currency: {
      code: 'SGD',
      name: 'Singapore dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/sgp.svg',
    callingCode: '+65',
    isoCode4217: '702',
    timezones: [
      {
        name: 'Singapore',
        countryCode: 'SG',
        value: 'Asia/Singapore',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: '+08',
          daylightSaving: '+08',
        },
        countryCodeIso: 'SGP',
      },
    ],
  },
  {
    name: 'Singapore',
    code: 'SGP',
    capital: 'Singapore',
    region: 'AS',
    currency: {
      code: 'SGD',
      name: 'Singapore dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/sgp.svg',
    callingCode: '+65',
    isoCode4217: '702',
    timezones: [
      {
        name: 'Singapore',
        countryCode: 'SG',
        value: 'Asia/Singapore',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: '+08',
          daylightSaving: '+08',
        },
        countryCodeIso: 'SGP',
      },
    ],
  },
  {
    name: 'Slovakia',
    code: 'SVK',
    capital: 'Bratislava',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'sk',
      name: 'Slovak',
    },
    flag: 'https://restcountries.eu/data/svk.svg',
    callingCode: '+421',
    isoCode4217: '703',
    timezones: [
      {
        name: 'Prague',
        countryCode: 'SK',
        value: 'Europe/Prague',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'SVK',
      },
    ],
  },
  {
    name: 'Slovenia',
    code: 'SVN',
    capital: 'Ljubljana',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'sl',
      name: 'Slovene',
    },
    flag: 'https://restcountries.eu/data/svn.svg',
    callingCode: '+386',
    isoCode4217: '705',
    timezones: [
      {
        name: 'Belgrade',
        countryCode: 'SI',
        value: 'Europe/Belgrade',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'SVN',
      },
    ],
  },
  {
    name: 'Solomon Islands',
    code: 'SLB',
    capital: 'Honiara',
    region: 'OC',
    currency: {
      code: 'SBD',
      name: 'Solomon Islands dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/slb.svg',
    callingCode: '+677',
    isoCode4217: '090',
    timezones: [
      {
        name: 'Guadalcanal',
        countryCode: 'SB',
        value: 'Pacific/Guadalcanal',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'SLB',
      },
    ],
  },
  {
    name: 'Somalia',
    code: 'SOM',
    capital: 'Mogadishu',
    region: 'AF',
    currency: {
      code: 'SOS',
      name: 'Somali shilling',
      symbol: 'Sh',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/som.svg',
    callingCode: '+252',
    isoCode4217: '706',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'SO',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'SOM',
      },
    ],
  },
  {
    name: 'South Africa',
    code: 'ZAF',
    capital: 'Pretoria',
    region: 'AF',
    currency: {
      code: 'ZAR',
      name: 'South African rand',
      symbol: 'R',
    },
    language: {
      code: 'en',
      iso639_2: 'eng',
      name: 'English',
      nativeName: 'English',
    },
    flag: 'https://restcountries.eu/data/zaf.svg',
    callingCode: '+27',
    isoCode4217: '710',
    timezones: [
      {
        name: 'Johannesburg',
        countryCode: 'ZA',
        value: 'Africa/Johannesburg',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'SAST',
          daylightSaving: 'SAST',
        },
        countryCodeIso: 'ZAF',
      },
    ],
  },
  {
    name: 'South Korea',
    code: 'KOR',
    capital: 'Seoul',
    region: 'AS',
    currency: {
      code: 'KRW',
      name: 'South Korean won',
      symbol: '₩',
    },
    language: {
      code: 'ko',
      name: 'Korean',
    },
    flag: 'https://restcountries.eu/data/kor.svg',
    callingCode: '+82',
    isoCode4217: '410',
    timezones: [
      {
        name: 'Seoul',
        countryCode: 'KR',
        value: 'Asia/Seoul',
        offset: {
          standard: '+09:00',
          daylightSaving: '+09:00',
        },
        abbreviation: {
          standard: 'KST',
          daylightSaving: 'KST',
        },
        countryCodeIso: 'KOR',
      },
    ],
  },
  {
    name: 'Spain',
    code: 'ESP',
    capital: 'Madrid',
    region: 'EU',
    currency: {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/esp.svg',
    callingCode: '+34',
    isoCode4217: '724',
    timezones: [
      {
        name: 'Ceuta',
        countryCode: 'ES',
        value: 'Africa/Ceuta',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'ESP',
      },
      {
        name: 'Canary',
        countryCode: 'ES',
        value: 'Atlantic/Canary',
        offset: {
          standard: '+00:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'WET',
          daylightSaving: 'WEST',
        },
        countryCodeIso: 'ESP',
      },
      {
        name: 'Madrid',
        countryCode: 'ES',
        value: 'Europe/Madrid',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'ESP',
      },
    ],
  },
  {
    name: 'Sri Lanka',
    code: 'LKA',
    capital: 'Colombo',
    region: 'AS',
    currency: {
      code: 'LKR',
      name: 'Sri Lankan rupee',
      symbol: 'Rs',
    },
    language: {
      code: 'si',
      iso639_2: 'sin',
      name: 'Sinhalese',
      nativeName: 'සිංහල',
    },
    flag: 'https://restcountries.eu/data/lka.svg',
    callingCode: '+94',
    isoCode4217: '144',
    timezones: [
      {
        name: 'Colombo',
        countryCode: 'LK',
        value: 'Asia/Colombo',
        offset: {
          standard: '+05:30',
          daylightSaving: '+05:30',
        },
        abbreviation: {
          standard: '+0530',
          daylightSaving: '+0530',
        },
        countryCodeIso: 'LKA',
      },
    ],
  },
  {
    name: 'Sudan',
    code: 'SDN',
    capital: 'Khartoum',
    region: 'AF',
    currency: {
      code: 'SDG',
      name: 'Sudanese pound',
      symbol: 'ج.س.',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/sdn.svg',
    callingCode: '+249',
    isoCode4217: '729',
    timezones: [
      {
        name: 'Khartoum',
        countryCode: 'SD',
        value: 'Africa/Khartoum',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'SDN',
      },
    ],
  },
  {
    name: 'Suriname',
    code: 'SUR',
    capital: 'Paramaribo',
    region: 'SA',
    currency: {
      code: 'SRD',
      name: 'Surinamese dollar',
      symbol: '$',
    },
    language: {
      code: 'nl',
      name: 'Dutch',
    },
    flag: 'https://restcountries.eu/data/sur.svg',
    callingCode: '+597',
    isoCode4217: '740',
    timezones: [
      {
        name: 'Paramaribo',
        countryCode: 'SR',
        value: 'America/Paramaribo',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'SUR',
      },
    ],
  },
  {
    name: 'Swaziland',
    code: 'SWZ',
    capital: 'Lobamba',
    region: 'AF',
    currency: {
      code: 'SZL',
      name: 'Swazi lilangeni',
      symbol: 'L',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/swz.svg',
    callingCode: '+268',
    isoCode4217: '748',
    timezones: [
      {
        name: 'Johannesburg',
        countryCode: 'SZ',
        value: 'Africa/Johannesburg',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'SAST',
          daylightSaving: 'SAST',
        },
        countryCodeIso: 'SWZ',
      },
    ],
  },
  {
    name: 'Sweden',
    code: 'SWE',
    capital: 'Stockholm',
    region: 'EU',
    currency: {
      code: 'SEK',
      name: 'Swedish krona',
      symbol: 'kr',
    },
    language: {
      code: 'sv',
      name: 'Swedish',
    },
    flag: 'https://restcountries.eu/data/swe.svg',
    callingCode: '+46',
    isoCode4217: '752',
    timezones: [
      {
        name: 'Berlin',
        countryCode: 'SE',
        value: 'Europe/Berlin',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'SWE',
      },
    ],
  },
  {
    name: 'Switzerland',
    code: 'CHE',
    capital: 'Bern',
    region: 'EU',
    currency: {
      code: 'CHF',
      name: 'Swiss franc',
      symbol: 'Fr',
    },
    language: {
      code: 'de',
      name: 'German',
    },
    flag: 'https://restcountries.eu/data/che.svg',
    callingCode: '+41',
    isoCode4217: '756',
    timezones: [
      {
        name: 'Zurich',
        countryCode: 'CH',
        value: 'Europe/Zurich',
        offset: {
          standard: '+01:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CEST',
        },
        countryCodeIso: 'CHE',
      },
    ],
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SYR',
    capital: 'Damascus',
    region: 'AS',
    currency: {
      code: 'SYP',
      name: 'Syrian pound',
      symbol: '£',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/syr.svg',
    callingCode: '+963',
    isoCode4217: '760',
    timezones: [
      {
        name: 'Damascus',
        countryCode: 'SY',
        value: 'Asia/Damascus',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'SYR',
      },
    ],
  },
  {
    name: 'Taiwan',
    code: 'TWN',
    capital: 'Taipei',
    region: 'AS',
    currency: {
      code: 'TWD',
      name: 'New Taiwan dollar',
      symbol: '$',
    },
    language: {
      code: 'zh',
      name: 'Chinese',
    },
    flag: 'https://restcountries.eu/data/twn.svg',
    callingCode: '+886',
    isoCode4217: '158',
    timezones: [
      {
        name: 'Taipei',
        countryCode: 'TW',
        value: 'Asia/Taipei',
        offset: {
          standard: '+08:00',
          daylightSaving: '+08:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CST',
        },
        countryCodeIso: 'TWN',
      },
    ],
  },
  {
    name: 'Tajikistan',
    code: 'TJK',
    capital: 'Dushanbe',
    region: 'AS',
    currency: {
      code: 'TJS',
      name: 'Tajikistani somoni',
      symbol: 'ЅМ',
    },
    language: {
      code: 'tg',
      name: 'Tajik',
    },
    flag: 'https://restcountries.eu/data/tjk.svg',
    callingCode: '+992',
    isoCode4217: '762',
    timezones: [
      {
        name: 'Dushanbe',
        countryCode: 'TJ',
        value: 'Asia/Dushanbe',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'TJK',
      },
    ],
  },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZA',
    capital: 'Dodoma',
    region: 'AF',
    currency: {
      code: 'TZS',
      name: 'Tanzanian shilling',
      symbol: 'Sh',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/tza.svg',
    callingCode: '+255',
    isoCode4217: '834',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'TZ',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'TZA',
      },
    ],
  },
  {
    name: 'Thailand',
    code: 'THA',
    capital: 'Bangkok',
    region: 'AS',
    currency: {
      code: 'THB',
      name: 'Thai baht',
      symbol: '฿',
    },
    language: {
      code: 'th',
      name: 'Thai',
    },
    flag: 'https://restcountries.eu/data/tha.svg',
    callingCode: '+66',
    isoCode4217: '764',
    timezones: [
      {
        name: 'Bangkok',
        countryCode: 'TH',
        value: 'Asia/Bangkok',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'THA',
      },
    ],
  },
  {
    name: 'Togo',
    code: 'TGO',
    capital: 'Lomé',
    region: 'AF',
    currency: {
      code: 'XOF',
      name: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/tgo.svg',
    callingCode: '+228',
    isoCode4217: '768',
    timezones: [
      {
        name: 'Abidjan',
        countryCode: 'TG',
        value: 'Africa/Abidjan',
        offset: {
          standard: '+00:00',
          daylightSaving: '+00:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'GMT',
        },
        countryCodeIso: 'TGO',
      },
    ],
  },
  {
    name: 'Tokelau',
    code: 'TKL',
    capital: 'Fakaofo',
    region: 'OC',
    currency: {
      code: 'NZD',
      name: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/tkl.svg',
    callingCode: '+690',
    isoCode4217: '772',
    timezones: [
      {
        name: 'Fakaofo',
        countryCode: 'TK',
        value: 'Pacific/Fakaofo',
        offset: {
          standard: '+13:00',
          daylightSaving: '+13:00',
        },
        abbreviation: {
          standard: '+13',
          daylightSaving: '+13',
        },
        countryCodeIso: 'TKL',
      },
    ],
  },
  {
    name: 'Tonga',
    code: 'TON',
    capital: "Nuku'alofa",
    region: 'OC',
    currency: {
      code: 'TOP',
      name: 'Tongan paʻanga',
      symbol: 'T$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/ton.svg',
    callingCode: '+676',
    isoCode4217: '776',
    timezones: [
      {
        name: 'Tongatapu',
        countryCode: 'TO',
        value: 'Pacific/Tongatapu',
        offset: {
          standard: '+13:00',
          daylightSaving: '+13:00',
        },
        abbreviation: {
          standard: '+13',
          daylightSaving: '+13',
        },
        countryCodeIso: 'TON',
      },
    ],
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TTO',
    capital: 'Port of Spain',
    region: 'SA',
    currency: {
      code: 'TTD',
      name: 'Trinidad and Tobago dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/tto.svg',
    callingCode: '+1',
    isoCode4217: '780',
    timezones: [
      {
        name: 'Puerto Rico',
        countryCode: 'TT',
        value: 'America/Puerto_Rico',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'AST',
          daylightSaving: 'AST',
        },
        countryCodeIso: 'TTO',
      },
    ],
  },
  {
    name: 'Tunisia',
    code: 'TUN',
    capital: 'Tunis',
    region: 'AF',
    currency: {
      code: 'TND',
      name: 'Tunisian dinar',
      symbol: 'د.ت',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/tun.svg',
    callingCode: '+216',
    isoCode4217: '788',
    timezones: [
      {
        name: 'Tunis',
        countryCode: 'TN',
        value: 'Africa/Tunis',
        offset: {
          standard: '+01:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'CET',
          daylightSaving: 'CET',
        },
        countryCodeIso: 'TUN',
      },
    ],
  },
  {
    name: 'Turkey',
    code: 'TUR',
    capital: 'Ankara',
    region: 'AS',
    currency: {
      code: 'TRY',
      name: 'Turkish lira',
      symbol: null,
    },
    language: {
      code: 'tr',
      name: 'Turkish',
    },
    flag: 'https://restcountries.eu/data/tur.svg',
    callingCode: '+90',
    isoCode4217: '792',
    timezones: [
      {
        name: 'Istanbul',
        countryCode: 'TR',
        value: 'Europe/Istanbul',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'TUR',
      },
    ],
  },
  {
    name: 'Turkmenistan',
    code: 'TKM',
    capital: 'Ashgabat',
    region: 'AS',
    currency: {
      code: 'TMT',
      name: 'Turkmenistan manat',
      symbol: 'm',
    },
    language: {
      code: 'tk',
      name: 'Turkmen',
    },
    flag: 'https://restcountries.eu/data/tkm.svg',
    callingCode: '+993',
    isoCode4217: '795',
    timezones: [
      {
        name: 'Ashgabat',
        countryCode: 'TM',
        value: 'Asia/Ashgabat',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'TKM',
      },
    ],
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TCA',
    capital: 'Cockburn Town',
    region: 'NA',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/tca.svg',
    callingCode: '+1',
    isoCode4217: '796',
    timezones: [
      {
        name: 'Grand Turk',
        countryCode: 'TC',
        value: 'America/Grand_Turk',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'TCA',
      },
    ],
  },
  {
    name: 'Tuvalu',
    code: 'TUV',
    capital: 'Funafuti',
    region: 'OC',
    currency: {
      code: 'AUD',
      name: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/tuv.svg',
    callingCode: '+688',
    isoCode4217: '798',
    timezones: [
      {
        name: 'Tarawa',
        countryCode: 'TV',
        value: 'Pacific/Tarawa',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'TUV',
      },
    ],
  },
  {
    name: 'Uganda',
    code: 'UGA',
    capital: 'Kampala',
    region: 'AF',
    currency: {
      code: 'UGX',
      name: 'Ugandan shilling',
      symbol: 'Sh',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/uga.svg',
    callingCode: '+256',
    isoCode4217: '800',
    timezones: [
      {
        name: 'Nairobi',
        countryCode: 'UG',
        value: 'Africa/Nairobi',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EAT',
          daylightSaving: 'EAT',
        },
        countryCodeIso: 'UGA',
      },
    ],
  },
  {
    name: 'Ukraine',
    code: 'UKR',
    capital: 'Kiev',
    region: 'EU',
    currency: {
      code: 'UAH',
      name: 'Ukrainian hryvnia',
      symbol: '₴',
    },
    language: {
      code: 'uk',
      name: 'Ukrainian',
    },
    flag: 'https://restcountries.eu/data/ukr.svg',
    callingCode: '+380',
    isoCode4217: '980',
    timezones: [
      {
        name: 'Kyiv',
        countryCode: 'UA',
        value: 'Europe/Kyiv',
        offset: {
          standard: '+02:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'EET',
          daylightSaving: 'EEST',
        },
        countryCodeIso: 'UKR',
      },
      {
        name: 'Simferopol',
        countryCode: 'UA',
        value: 'Europe/Simferopol',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: 'MSK',
          daylightSaving: 'MSK',
        },
        countryCodeIso: 'UKR',
      },
    ],
  },
  {
    name: 'United Arab Emirates',
    code: 'ARE',
    capital: 'Abu Dhabi',
    region: 'AS',
    currency: {
      code: 'AED',
      name: 'United Arab Emirates dirham',
      symbol: 'د.إ',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/are.svg',
    callingCode: '+971',
    isoCode4217: '784',
    timezones: [
      {
        name: 'Dubai',
        countryCode: 'AE',
        value: 'Asia/Dubai',
        offset: {
          standard: '+04:00',
          daylightSaving: '+04:00',
        },
        abbreviation: {
          standard: '+04',
          daylightSaving: '+04',
        },
        countryCodeIso: 'ARE',
      },
    ],
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GBR',
    capital: 'London',
    region: 'EU',
    currency: {
      code: 'GBP',
      name: 'British pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/gbr.svg',
    callingCode: '+44',
    isoCode4217: '826',
    timezones: [
      {
        name: 'London',
        countryCode: 'GB',
        value: 'Europe/London',
        offset: {
          standard: '+00:00',
          daylightSaving: '+01:00',
        },
        abbreviation: {
          standard: 'GMT',
          daylightSaving: 'BST',
        },
        countryCodeIso: 'GBR',
      },
    ],
  },
  {
    name: 'United States of America',
    code: 'USA',
    capital: 'Washington, D.C.',
    region: 'NA',
    currency: {
      code: 'USD',
      name: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      iso639_2: 'eng',
      name: 'English',
      nativeName: 'English',
    },
    flag: 'https://restcountries.eu/data/usa.svg',
    callingCode: '+1',
    isoCode4217: '840',
    timezones: [
      {
        name: 'Adak',
        countryCode: 'US',
        value: 'America/Adak',
        offset: {
          standard: '-10:00',
          daylightSaving: '-09:00',
        },
        abbreviation: {
          standard: 'HST',
          daylightSaving: 'HDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Anchorage',
        countryCode: 'US',
        value: 'America/Anchorage',
        offset: {
          standard: '-09:00',
          daylightSaving: '-08:00',
        },
        abbreviation: {
          standard: 'AKST',
          daylightSaving: 'AKDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Boise',
        countryCode: 'US',
        value: 'America/Boise',
        offset: {
          standard: '-07:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Chicago',
        countryCode: 'US',
        value: 'America/Chicago',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Denver',
        countryCode: 'US',
        value: 'America/Denver',
        offset: {
          standard: '-07:00',
          daylightSaving: '-06:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Detroit',
        countryCode: 'US',
        value: 'America/Detroit',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Indiana (Indianapolis)',
        countryCode: 'US',
        value: 'America/Indiana/Indianapolis',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Indiana (Knox)',
        countryCode: 'US',
        value: 'America/Indiana/Knox',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Indiana (Marengo)',
        countryCode: 'US',
        value: 'America/Indiana/Marengo',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Indiana (Petersburg)',
        countryCode: 'US',
        value: 'America/Indiana/Petersburg',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Indiana (Tell City)',
        countryCode: 'US',
        value: 'America/Indiana/Tell_City',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Indiana (Vevay)',
        countryCode: 'US',
        value: 'America/Indiana/Vevay',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Indiana (Vincennes)',
        countryCode: 'US',
        value: 'America/Indiana/Vincennes',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Indiana (Winamac)',
        countryCode: 'US',
        value: 'America/Indiana/Winamac',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Juneau',
        countryCode: 'US',
        value: 'America/Juneau',
        offset: {
          standard: '-09:00',
          daylightSaving: '-08:00',
        },
        abbreviation: {
          standard: 'AKST',
          daylightSaving: 'AKDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Kentucky (Louisville)',
        countryCode: 'US',
        value: 'America/Kentucky/Louisville',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Kentucky (Monticello)',
        countryCode: 'US',
        value: 'America/Kentucky/Monticello',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Los Angeles',
        countryCode: 'US',
        value: 'America/Los_Angeles',
        offset: {
          standard: '-08:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'PST',
          daylightSaving: 'PDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Menominee',
        countryCode: 'US',
        value: 'America/Menominee',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Metlakatla',
        countryCode: 'US',
        value: 'America/Metlakatla',
        offset: {
          standard: '-09:00',
          daylightSaving: '-08:00',
        },
        abbreviation: {
          standard: 'AKST',
          daylightSaving: 'ADST',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'New York',
        countryCode: 'US',
        value: 'America/New_York',
        offset: {
          standard: '-05:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: 'EST',
          daylightSaving: 'EDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Nome',
        countryCode: 'US',
        value: 'America/Nome',
        offset: {
          standard: '-09:00',
          daylightSaving: '-08:00',
        },
        abbreviation: {
          standard: 'AKST',
          daylightSaving: 'AKDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'North Dakota (Beulah)',
        countryCode: 'US',
        value: 'America/North_Dakota/Beulah',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'North Dakota (Center)',
        countryCode: 'US',
        value: 'America/North_Dakota/Center',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'North Dakota (New Salem)',
        countryCode: 'US',
        value: 'America/North_Dakota/New_Salem',
        offset: {
          standard: '-06:00',
          daylightSaving: '-05:00',
        },
        abbreviation: {
          standard: 'CST',
          daylightSaving: 'CDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Phoenix',
        countryCode: 'US',
        value: 'America/Phoenix',
        offset: {
          standard: '-07:00',
          daylightSaving: '-07:00',
        },
        abbreviation: {
          standard: 'MST',
          daylightSaving: 'MST',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Sitka',
        countryCode: 'US',
        value: 'America/Sitka',
        offset: {
          standard: '-09:00',
          daylightSaving: '-08:00',
        },
        abbreviation: {
          standard: 'AKST',
          daylightSaving: 'AKDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Yakutat',
        countryCode: 'US',
        value: 'America/Yakutat',
        offset: {
          standard: '-09:00',
          daylightSaving: '-08:00',
        },
        abbreviation: {
          standard: 'AKST',
          daylightSaving: 'AKDT',
        },
        countryCodeIso: 'USA',
      },
      {
        name: 'Honolulu',
        countryCode: 'US',
        value: 'Pacific/Honolulu',
        offset: {
          standard: '-10:00',
          daylightSaving: '-10:00',
        },
        abbreviation: {
          standard: 'HST',
          daylightSaving: 'HST',
        },
        countryCodeIso: 'USA',
      },
    ],
  },
  {
    name: 'Uruguay',
    code: 'URY',
    capital: 'Montevideo',
    region: 'SA',
    currency: {
      code: 'UYU',
      name: 'Uruguayan peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/ury.svg',
    callingCode: '+598',
    isoCode4217: '858',
    timezones: [
      {
        name: 'Montevideo',
        countryCode: 'UY',
        value: 'America/Montevideo',
        offset: {
          standard: '-03:00',
          daylightSaving: '-03:00',
        },
        abbreviation: {
          standard: '-03',
          daylightSaving: '-03',
        },
        countryCodeIso: 'URY',
      },
    ],
  },
  {
    name: 'Uzbekistan',
    code: 'UZB',
    capital: 'Tashkent',
    region: 'AS',
    currency: {
      code: 'UZS',
      name: "Uzbekistani so'm",
      symbol: null,
    },
    language: {
      code: 'uz',
      name: 'Uzbek',
    },
    flag: 'https://restcountries.eu/data/uzb.svg',
    callingCode: '+998',
    isoCode4217: '860',
    timezones: [
      {
        name: 'Samarkand',
        countryCode: 'UZ',
        value: 'Asia/Samarkand',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'UZB',
      },
      {
        name: 'Tashkent',
        countryCode: 'UZ',
        value: 'Asia/Tashkent',
        offset: {
          standard: '+05:00',
          daylightSaving: '+05:00',
        },
        abbreviation: {
          standard: '+05',
          daylightSaving: '+05',
        },
        countryCodeIso: 'UZB',
      },
    ],
  },
  {
    name: 'Vanuatu',
    code: 'VUT',
    capital: 'Port Vila',
    region: 'OC',
    currency: {
      code: 'VUV',
      name: 'Vanuatu vatu',
      symbol: 'Vt',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/vut.svg',
    callingCode: '+678',
    isoCode4217: '548',
    timezones: [
      {
        name: 'Efate',
        countryCode: 'VU',
        value: 'Pacific/Efate',
        offset: {
          standard: '+11:00',
          daylightSaving: '+11:00',
        },
        abbreviation: {
          standard: '+11',
          daylightSaving: '+11',
        },
        countryCodeIso: 'VUT',
      },
    ],
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    code: 'VEN',
    capital: 'Caracas',
    region: 'SA',
    currency: {
      code: 'VEF',
      name: 'Venezuelan bolívar',
      symbol: 'Bs F',
    },
    language: {
      code: 'es',
      name: 'Spanish',
    },
    flag: 'https://restcountries.eu/data/ven.svg',
    callingCode: '+58',
    isoCode4217: '862',
    timezones: [
      {
        name: 'Caracas',
        countryCode: 'VE',
        value: 'America/Caracas',
        offset: {
          standard: '-04:00',
          daylightSaving: '-04:00',
        },
        abbreviation: {
          standard: '-04',
          daylightSaving: '-04',
        },
        countryCodeIso: 'VEN',
      },
    ],
  },
  {
    name: 'Viet Nam',
    code: 'VNM',
    capital: 'Hanoi',
    region: 'AS',
    currency: {
      code: 'VND',
      name: 'Vietnamese đồng',
      symbol: '₫',
    },
    language: {
      code: 'vi',
      name: 'Vietnamese',
    },
    flag: 'https://restcountries.eu/data/vnm.svg',
    callingCode: '+84',
    isoCode4217: '704',
    timezones: [
      {
        name: 'Bangkok',
        countryCode: 'VN',
        value: 'Asia/Bangkok',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'VNM',
      },
      {
        name: 'Ho Chi Minh',
        countryCode: 'VN',
        value: 'Asia/Ho_Chi_Minh',
        offset: {
          standard: '+07:00',
          daylightSaving: '+07:00',
        },
        abbreviation: {
          standard: '+07',
          daylightSaving: '+07',
        },
        countryCodeIso: 'VNM',
      },
    ],
  },
  {
    name: 'Wallis and Futuna',
    code: 'WLF',
    capital: 'Mata-Utu',
    region: 'OC',
    currency: {
      code: 'XPF',
      name: 'CFP franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/wlf.svg',
    callingCode: '+681',
    isoCode4217: '876',
    timezones: [
      {
        name: 'Tarawa',
        countryCode: 'WF',
        value: 'Pacific/Tarawa',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'WLF',
      },
      {
        name: 'Tarawa',
        countryCode: 'WF',
        value: 'Pacific/Tarawa',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'WLF',
      },
    ],
  },
  {
    name: 'Wallis and Futuna',
    code: 'WLF',
    capital: 'Mata-Utu',
    region: 'OC',
    currency: {
      code: 'XPF',
      name: 'CFP franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      name: 'French',
    },
    flag: 'https://restcountries.eu/data/wlf.svg',
    callingCode: '+681',
    isoCode4217: '876',
    timezones: [
      {
        name: 'Tarawa',
        countryCode: 'WF',
        value: 'Pacific/Tarawa',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'WLF',
      },
      {
        name: 'Tarawa',
        countryCode: 'WF',
        value: 'Pacific/Tarawa',
        offset: {
          standard: '+12:00',
          daylightSaving: '+12:00',
        },
        abbreviation: {
          standard: '+12',
          daylightSaving: '+12',
        },
        countryCodeIso: 'WLF',
      },
    ],
  },
  {
    name: 'Yemen',
    code: 'YEM',
    capital: "Sana'a",
    region: 'AS',
    currency: {
      code: 'YER',
      name: 'Yemeni rial',
      symbol: '﷼',
    },
    language: {
      code: 'ar',
      name: 'Arabic',
    },
    flag: 'https://restcountries.eu/data/yem.svg',
    callingCode: '+967',
    isoCode4217: '887',
    timezones: [
      {
        name: 'Riyadh',
        countryCode: 'YE',
        value: 'Asia/Riyadh',
        offset: {
          standard: '+03:00',
          daylightSaving: '+03:00',
        },
        abbreviation: {
          standard: '+03',
          daylightSaving: '+03',
        },
        countryCodeIso: 'YEM',
      },
    ],
  },
  {
    name: 'Zambia',
    code: 'ZMB',
    capital: 'Lusaka',
    region: 'AF',
    currency: {
      code: 'ZMW',
      name: 'Zambian kwacha',
      symbol: 'ZK',
    },
    language: {
      code: 'en',
      name: 'English',
    },
    flag: 'https://restcountries.eu/data/zmb.svg',
    callingCode: '+260',
    isoCode4217: '894',
    timezones: [
      {
        name: 'Maputo',
        countryCode: 'ZM',
        value: 'Africa/Maputo',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'ZMB',
      },
    ],
  },
  {
    name: 'Zimbabwe',
    code: 'ZWE',
    capital: 'Harare',
    region: 'AF',
    currency: {
      code: 'BWP',
      name: 'Botswana pula',
      symbol: 'P',
    },
    language: {
      code: 'en',
      iso639_2: 'eng',
      name: 'English',
      nativeName: 'English',
    },
    flag: 'https://restcountries.eu/data/zwe.svg',
    callingCode: '+263',
    isoCode4217: '716',
    timezones: [
      {
        name: 'Maputo',
        countryCode: 'ZW',
        value: 'Africa/Maputo',
        offset: {
          standard: '+02:00',
          daylightSaving: '+02:00',
        },
        abbreviation: {
          standard: 'CAT',
          daylightSaving: 'CAT',
        },
        countryCodeIso: 'ZWE',
      },
    ],
  },
];

export const countryData = data;
