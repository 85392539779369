








































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import { requiredFieldRules } from '@/utils/validation-rules';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';

@Component
export default class ProductViewName extends Vue {
  private saving = false;
  private formValid = false;
  private productName = '';
  private baseProductName = '';
  private requiredField = requiredFieldRules;

  mounted() {}

  close() {
    this.productName = _.cloneDeep(this.baseProductName);
    this.toggleNameEdit();
  }

  toggleNameEdit() {
    this.productName = _.cloneDeep(this.Product.internalName || '');

    if (!this.EditModeName) {
      this.baseProductName = _.cloneDeep(this.Product.internalName || '');
    }
    productModule.setEditModeName(!this.EditModeName);
  }

  validate() {
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (isValid) {
        this.saveChanges();
      }
    }
  }

  saveChanges() {
    this.saving = true;
    if (this.Product && this.Product.internalName) {
      this.Product.internalName = _.cloneDeep(this.productName);
    }
    productModule.setProduct(_.cloneDeep(this.Product));
    httpModule
      .updateProduct()
      .then()
      .finally(() => {
        this.productName = _.cloneDeep(this.Product?.internalName || '');
        this.baseProductName = _.cloneDeep(this.Product?.internalName || '');
        this.saving = false;
        this.close();
        httpModule.loadProducts().then(() => {}); // todo remove?
      });
  }

  get Product() {
    return productModule.Product;
  }

  get Loading() {
    return productModule.Loading;
  }

  get EditModeName() {
    return productModule.EditModeName;
  }
}
