





































































import { Component, Vue } from 'vue-property-decorator';
import { tablePaginationOptions } from '@/utils/helpers';
import { constants } from '@/utils/constants';
import { httpModule } from '@/store/modules/moduleHttp';
import { Offer, OfferCapacityInList } from '@/models';
import { offerModule } from '@/store/modules/moduleOffer';

@Component
export default class InventoryListSummary extends Vue {
  private viewInventoryRoute = constants.routes.AVAILABILITY_DETAILS;
  private modalIsOpen = false;
  private selectedCapacity = {} as Offer;
  private inventoryHeaders: Array<Record<string, any>> = [
    {
      text: 'Offer',
      value: 'name',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    {
      text: 'Inventory',
      value: 'capacity',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];

  get Search() {
    return offerModule.Search;
  }

  get paginationOptions() {
    return tablePaginationOptions(this.Offers);
  }

  get OffersCapacity() {
    return offerModule.OffersCapacity;
  }

  get Offers() {
    // todo list of all offers with 1 or more associated offer capacity
    return offerModule.Offers.map((offer: Offer) => {
      const capacity = [] as Array<OfferCapacityInList>;
      if (this.OffersCapacity.data) {
        this.OffersCapacity?.data.map((item) =>
          offer.id === item.offerId ? capacity.push(item) : null,
        );
      }
      return {
        ...offer,
        capacity: capacity,
      };
    }).filter((item) => {
      return item.capacity.length > 0;
    });
  }

  get Loading() {
    return offerModule.Loading;
  }

  async mounted() {
    await httpModule.getOffers();
    await httpModule.getOffersCapacity();
  }

  generateInventoryNameString(capacity: Array<OfferCapacityInList>) {
    return capacity.map((c: OfferCapacityInList) => c.name).join(' ');
  }

  deleteInventory(capacity: Offer) {
    this.modalIsOpen = true;
    this.selectedCapacity = capacity;
  }

  async proceedDelete() {
    if (this.selectedCapacity && Array.isArray(this.selectedCapacity.inventoryNameList)) {
      await httpModule.deleteOfferCapacity(this.selectedCapacity);
      this.modalIsOpen = false;
      this.selectedCapacity = {} as Offer;
    }
  }

  cancelDelete() {
    this.selectedCapacity = {} as Offer;
    this.modalIsOpen = false;
  }
}
