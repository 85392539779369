

































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { taxesAndFeesModule } from '@/store/modules/moduleTaxAndFees';
import { httpModule } from '@/store/modules/moduleHttp';
import InventorySkeleton from '@/components/inventory/InventorySkeleton.vue';
import { centsToDollars, tablePaginationOptions } from '@/utils/helpers';
import { Fee } from '@/models';

@Component({
  components: {
    InventorySkeleton,
  },
})
export default class TaxesAndFeesListing extends Vue {
  private centsToDollars = centsToDollars;
  private routerTaxesAndFeesViewName = constants.routes.TAXES_AND_FEES_VIEW;
  private routerTaxesAndFeesViewCreate = constants.routes.TAXES_AND_FEES_CREATE;
  private routerTaxesAndFeesViewEdit = constants.routes.TAXES_AND_FEES_EDIT;
  private taxAndFeesHeaders: Array<Record<string, any>> = [
    { text: 'Name', value: 'name', align: 'left', sortable: false },
    { text: 'Type', value: 'chargeType', align: 'left', sortable: false },
    { text: 'Rate', value: 'rateType', align: 'left', sortable: false },
    { text: 'Calculation', value: 'calcText', align: 'left', sortable: false },
    {
      text: 'Effective Dates',
      value: 'validFrom',
      align: 'left',
      sortable: false,
    },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];

  private testdata = [
    {
      id: 'b6e54177-0ca8-4754-a0ba-c0e5e45c1299',
      orgId: '8824a85b-30d3-4bf8-a7a0-00ec78aab4ec',
      productId: '',
      optionId: '',
      name: 'еуые',
      chargeType: 'TAX',
      rateType: 'PERCENTAGE',
      currency: 'USD',
      amount: 5,
      ruleType: 'INDEPENDENT',
      applicationLevel: 'PER_PERSON',
      includedInPricing: true,
      disclosureRequired: true,
      collectible: true,
      applicationOrder: 1,
      validFrom: '2022-06-02',
      meta: {
        version: 1,
        status: 'ENABLED',
        createdAt: '2022-06-02T11:39:45.934617Z',
        updatedAt: '2022-06-02T11:39:45.934617Z',
      },
    },
  ];

  /* GETTERS */

  get Loading(): boolean {
    return taxesAndFeesModule.Loading;
  }

  get paginationOptions() {
    return tablePaginationOptions(this.TaxesAndFees);
  }

  get TaxesAndFees() {
    return taxesAndFeesModule.TaxesAndFees.map((item) => {
      return {
        ...item,
        calcText: `${item.includedInPricing ? 'Included in Pricing,' : ''} ${
          item.ruleType
        }, ${item.applicationLevel}`,
      };
    });
  }

  get search() {
    return taxesAndFeesModule.SearchText;
  }
  set search(search: string) {
    taxesAndFeesModule.setSearchText(search);
  }

  private async mounted() {
    await httpModule.getTaxesAndFees();
  }
  /* METHODS */
  private navigateToNewPrice() {
    this.$router.push({ name: constants.routes.PRICING_CREATE }).catch(() => {});
  }
  private navigateToNewTaxAndFee() {
    this.$router.push({ name: this.routerTaxesAndFeesViewCreate }).catch(() => {});
  }
  private async deleteTaxesAndFees(fee: Fee) {
    await httpModule.deleteTaxesAndFees(fee);
  }
  private needMoreTaxes() {
    this.$router.push({ name: constants.routes.FEEDBACK }).catch(() => {});
  }
}
