var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"offerCreatePolicy",model:{value:(_vm.Form2Valid),callback:function ($$v) {_vm.Form2Valid=$$v},expression:"Form2Valid"}},[(!_vm.Step2Done)?_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',{on:{"change":_vm.validateSelection},model:{value:(_vm.SelectedProductPolicyId),callback:function ($$v) {_vm.SelectedProductPolicyId=$$v},expression:"SelectedProductPolicyId"}},[_c('v-row',_vm._l((_vm.Policies),function(p){return _c('v-col',{key:("policy-" + (p.id)),attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-list-item',{key:("policy-" + (p.id)),attrs:{"value":p.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-icon',{attrs:{"color":active ? 'primary' : ''}},[_vm._v(" "+_vm._s(active ? 'mdi-checkbox-blank-circle' : 'mdi-checkbox-blank-circle-outline')+" ")])],1),_c('v-list-item-content',[_c('div',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(p.isProduct ? 'Product Policy' : 'Option Policy')+" ")]),_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Is this cancellable?")]),_c('div',[_vm._v(" "+_vm._s(p.cancellable ? 'Yes' : 'No')+" ")])]),_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Is this refundable?")]),_c('div',[_vm._v(_vm._s(p.refundable ? 'Yes' : 'No'))])]),_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Is there a booking cutoff?")]),_c('div',[_vm._v(" "+_vm._s(p.cutOffTime && p.cutOffTime !== undefined && p.cutOffTime > 0 ? 'Yes,' : 'No')+" "),(
                          p.cutOffTime && p.cutOffTime !== undefined && p.cutOffTime > 0
                        )?_c('span',[_vm._v("within "+_vm._s(_vm._f("formatMinutesToTime")(p.cutOffTime))+"s")]):_vm._e()])]),(p.noPolicy)?_c('div',[_c('span',[_vm._v("No policy configured for "),_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(p.isProduct ? _vm.SelectedProductName : _vm.SelectedOptionName))]),_vm._v(". Add one "),_c('router-link',{attrs:{"to":{
                          name: p.isProduct
                            ? _vm.routeToProductDetails
                            : _vm.routeToOptionDetails,
                          params: { id: p.id },
                        }}},[_vm._v("here")])],1)]):_vm._e()])]}}],null,true)})],1)],1)}),1)],1)],1):_vm._e(),(!_vm.Step2Done && _vm.Policies.length === 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("No policy configured.")])])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(
          _vm.Step2Done && _vm.SelectedProductPolicy.id && _vm.SelectedProductPolicy.id.length > 0
        )?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.SelectedProductPolicy.cancellable !== undefined)?_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Is this cancellable?")]),_c('div',[_vm._v(" "+_vm._s(_vm.SelectedProductPolicy.cancellable ? 'Yes' : 'No')+" ")])]):_vm._e(),_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Is this refundable?")]),_c('div',[_vm._v(_vm._s(_vm.SelectedProductPolicy.refundable ? 'Yes' : 'No'))])]),_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Is there a booking cutoff?")]),_c('div',[_vm._v(" "+_vm._s(_vm.SelectedProductPolicy.cancelCutOff !== undefined && _vm.SelectedProductPolicy.cancelCutOff > 0 ? 'Yes,' : 'No')+" "),(
                  _vm.SelectedProductPolicy.cutOffTime &&
                  _vm.SelectedProductPolicy.cutOffTime !== undefined &&
                  _vm.SelectedProductPolicy.cutOffTime > 0
                )?_c('span',[_vm._v("within "+_vm._s(_vm._f("formatMinutesToTime")(_vm.SelectedProductPolicy.cutOffTime))+"s")]):_vm._e()])]),(_vm.SelectedProductPolicy.noPolicy)?_c('div',[_c('span',[_vm._v("No policy configured for "),_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.SelectedProductPolicy.isProduct ? _vm.SelectedProductName : _vm.SelectedOptionName))]),_vm._v(". Add one "),_c('router-link',{attrs:{"to":{
                  name: _vm.SelectedProductPolicy.isProduct
                    ? _vm.routeToProductDetails
                    : _vm.routeToOptionDetails,
                  params: { id: _vm.SelectedProductPolicy.id },
                }}},[_vm._v("here")])],1)]):_vm._e()])],1):(_vm.Step2Done && !_vm.SelectedProductPolicy.id)?_c('div',[_vm._v(" No policies selected. ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }