var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rdm-collapsable-control-card',{attrs:{"open":_vm.DetailsOpen},on:{"toggle":_vm.toggleDetailsOpen},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Product Details")]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.CreateProductFormState),callback:function ($$v) {_vm.CreateProductFormState=$$v},expression:"CreateProductFormState"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Details")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Product Name*")]),_c('v-text-field',{attrs:{"rules":_vm.validation ? _vm.productNameRules : [],"placeholder":"Product Name*","outlined":"","required":""},model:{value:(_vm.DetailsForm.internalName),callback:function ($$v) {_vm.$set(_vm.DetailsForm, "internalName", $$v)},expression:"DetailsForm.internalName"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Traveler Types*")]),_c('v-select',{staticClass:"input",attrs:{"placeholder":"Select Traveler Types","outlined":"","hide-details":"","multiple":"","clearable":"","background-color":"white","items":_vm.TravelerTypes,"rules":_vm.requiredFieldRules,"menu-props":{ bottom: true, offsetY: true }},on:{"change":_vm.populateCapacityUnit},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleAllTravelerTypes}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.DetailsForm.travelers && _vm.DetailsForm.travelers.length > 0
                        ? 'primary'
                        : ''}},[_vm._v(" "+_vm._s(_vm.TravelerTypeSelectIcon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(
                  _vm.DetailsForm.travelers.length == _vm.TravelerTypes.length && index === 0
                )?_c('div',[_c('span',[_vm._v("All Traveler Types")])]):(_vm.DetailsForm.travelers.length !== _vm.TravelerTypes.length)?_c('div',[_c('div',[_c('span',[_vm._v(_vm._s(item.text)+", ")])])]):_vm._e()]}}]),model:{value:(_vm.DetailsForm.travelers),callback:function ($$v) {_vm.$set(_vm.DetailsForm, "travelers", $$v)},expression:"DetailsForm.travelers"}}),_c('div',{staticClass:"label-spacing-top-10"},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_vm._v("Create New traveler Type")])]}}]),model:{value:(_vm.createTravelerType),callback:function ($$v) {_vm.createTravelerType=$$v},expression:"createTravelerType"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Unsaved changes ")]),_c('v-card-text',[_vm._v(" Are you sure you want to continue? Your progress will be lost ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){_vm.createTravelerType = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.goToTravelerTypeCreate}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Description*")]),_c('v-textarea',{staticClass:"max-height-fit-content",attrs:{"rules":_vm.descriptionLimitRules(_vm.descriptionLimit),"placeholder":_vm.descriptionPlaceholder,"counter":_vm.descriptionLimit,"outlined":"","required":"","no-resize":"","rows":"4","row-height":"30"},model:{value:(_vm.DetailsForm.description),callback:function ($$v) {_vm.$set(_vm.DetailsForm, "description", $$v)},expression:"DetailsForm.description"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","md":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Primary Location*")]),_c('v-select',{attrs:{"items":_vm.Addresses,"rules":_vm.requiredFieldRules,"item-value":"id","item-text":"name","outlined":"","color":"primary","placeholder":"Primary Address*","menu-props":{ bottom: true, offsetY: true },"required":""},model:{value:(_vm.DetailsForm.addressId),callback:function ($$v) {_vm.$set(_vm.DetailsForm, "addressId", $$v)},expression:"DetailsForm.addressId"}}),_c('div',{staticClass:"marginT-10"},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_vm._v("Create New Primary Location")])]}}]),model:{value:(_vm.createPrimaryLocation),callback:function ($$v) {_vm.createPrimaryLocation=$$v},expression:"createPrimaryLocation"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Unsaved changes ")]),_c('v-card-text',[_vm._v(" Are you sure you want to continue? Your progress will be lost ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){_vm.createPrimaryLocation = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.goToPrimaryLocationCreate}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","md":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Timezone*")]),_c('v-autocomplete',{attrs:{"rules":_vm.requiredFieldRules,"items":_vm.CountryTimezones,"item-text":"name","item-value":"value","outlined":"","clearable":"","placeholder":"Timezone*","menu-props":{ bottom: true, offsetY: true },"required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showTimeZone(item))+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showTimeZone(item))+" ")]}}]),model:{value:(_vm.DetailsForm.timezone),callback:function ($$v) {_vm.$set(_vm.DetailsForm, "timezone", $$v)},expression:"DetailsForm.timezone"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('strong',{staticClass:"text-dark label-spacing-bottom-10"},[_vm._v("Reservations contacts")]),_c('v-select',{staticClass:"input",attrs:{"placeholder":"Reservations Contacts","outlined":"","hide-details":"","background-color":"white","items":_vm.Contacts,"item-value":"id","item-text":"showName","menu-props":{ bottom: true, offsetY: true },"multiple":"","clearable":""},model:{value:(_vm.selectedContacts),callback:function ($$v) {_vm.selectedContacts=$$v},expression:"selectedContacts"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Redemption Details")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Delivery Methods*")]),_c('v-select',{attrs:{"items":_vm.DeliveryMethods,"item-value":"value","item-text":"text","outlined":"","color":"primary","placeholder":"Delivery Methods*","rules":_vm.requiredMultipleSelectRules,"menu-props":{ bottom: true, offsetY: true },"required":""},model:{value:(_vm.DetailsForm.deliveryMethods),callback:function ($$v) {_vm.$set(_vm.DetailsForm, "deliveryMethods", $$v)},expression:"DetailsForm.deliveryMethods"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Capacity type")]),_c('v-row',[_c('v-radio-group',{attrs:{"mandatory":"","row":"","hide-details":""},model:{value:(_vm.DetailsForm.availabilityType),callback:function ($$v) {_vm.$set(_vm.DetailsForm, "availabilityType", $$v)},expression:"DetailsForm.availabilityType"}},_vm._l((_vm.CapacityTypes),function(availType,index){return _c('v-col',{key:("radio-capacity-type-" + index),staticClass:"min-width-320",attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"value":availType,"color":(_vm.DetailsForm.availabilityType ===
                      _vm.capacityType.CapacityLimitByUnit &&
                      availType === _vm.capacityType.CapacityLimitByUnit &&
                      _vm.DetailsForm &&
                      _vm.DetailsForm.travelers &&
                      Array.isArray(_vm.DetailsForm.travelers) &&
                      _vm.DetailsForm.travelers.length === 0) ||
                    (availType === _vm.capacityType.CapacityLimitByUnit && !_vm.DetailsForm) ||
                    (availType === _vm.capacityType.CapacityLimitByUnit &&
                      _vm.DetailsForm &&
                      !Array.isArray(_vm.DetailsForm.travelers))
                      ? 'error'
                      : 'primary'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('div',[_vm._v(_vm._s(_vm._f("capacityTypeLabel")(availType)))]),_c('pre',{staticClass:"caption"},[_vm._v(_vm._s(_vm._f("capacityTypeText")(availType)))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                          (_vm.DetailsForm.availabilityType ===
                            _vm.capacityType.CapacityLimitByUnit &&
                            availType === _vm.capacityType.CapacityLimitByUnit &&
                            _vm.DetailsForm &&
                            _vm.DetailsForm.travelers &&
                            Array.isArray(_vm.DetailsForm.travelers) &&
                            _vm.DetailsForm.travelers.length === 0) ||
                          (availType === _vm.capacityType.CapacityLimitByUnit &&
                            _vm.DetailsForm.availabilityType ===
                              _vm.capacityType.CapacityLimitByUnit &&
                            !_vm.DetailsForm) ||
                          (availType === _vm.capacityType.CapacityLimitByUnit &&
                            _vm.DetailsForm.availabilityType ===
                              _vm.capacityType.CapacityLimitByUnit &&
                            _vm.DetailsForm &&
                            !Array.isArray(_vm.DetailsForm.travelers))
                        ),expression:"\n                          (DetailsForm.availabilityType ===\n                            capacityType.CapacityLimitByUnit &&\n                            availType === capacityType.CapacityLimitByUnit &&\n                            DetailsForm &&\n                            DetailsForm.travelers &&\n                            Array.isArray(DetailsForm.travelers) &&\n                            DetailsForm.travelers.length === 0) ||\n                          (availType === capacityType.CapacityLimitByUnit &&\n                            DetailsForm.availabilityType ===\n                              capacityType.CapacityLimitByUnit &&\n                            !DetailsForm) ||\n                          (availType === capacityType.CapacityLimitByUnit &&\n                            DetailsForm.availabilityType ===\n                              capacityType.CapacityLimitByUnit &&\n                            DetailsForm &&\n                            !Array.isArray(DetailsForm.travelers))\n                        "}],staticClass:"caption error--text"},[_vm._v(" Select traveler types to establish capacity ")])])]},proxy:true}],null,true)})],1)}),1)],1),_c('v-row',[_c('v-col',{staticClass:"max-width-320",attrs:{"cols":"4"}}),_c('v-col',{staticClass:"max-width-320",attrs:{"cols":"4"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.DetailsForm.availabilityType === _vm.capacityType.CapacityLimitPooled
                ),expression:"\n                  DetailsForm.availabilityType === capacityType.CapacityLimitPooled\n                "}]},[_c('v-text-field',{attrs:{"rules":_vm.DetailsForm.availabilityType === _vm.capacityType.CapacityLimitPooled
                      ? _vm.greaterThan1Rules
                      : [],"outlined":"","hide-details":"","label":"Capacity","background-color":"white","type":"number","min":"1"},on:{"keydown":_vm.handleKeyDown},model:{value:(_vm.capacityPool.pooled),callback:function ($$v) {_vm.$set(_vm.capacityPool, "pooled", $$v)},expression:"capacityPool.pooled"}})],1)]),_c('v-col',{staticClass:"max-width-320",attrs:{"cols":"4"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.DetailsForm.availabilityType === _vm.capacityType.CapacityLimitByUnit
                ),expression:"\n                  DetailsForm.availabilityType === capacityType.CapacityLimitByUnit\n                "}]},[(
                    _vm.DetailsForm &&
                    _vm.DetailsForm.travelers &&
                    Array.isArray(_vm.DetailsForm.travelers) &&
                    _vm.DetailsForm.travelers.length > 0
                  )?_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_vm._l((_vm.byUnitPoolArr),function(t,i){return [_c('v-row',{key:("traveler-input-" + i)},[_c('v-col',{attrs:{"align-self":"center"}},[_vm._v(_vm._s(_vm.unitIdToTravelerName(t.unitId)))]),_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.DetailsForm.availabilityType ===
                              _vm.capacityType.CapacityLimitByUnit
                                ? _vm.greaterThan1Rules
                                : [],"outlined":"","hide-details":"","label":"Capacity","background-color":"white","type":"number","min":"1"},on:{"keydown":_vm.handleKeyDown},model:{value:(t.value),callback:function ($$v) {_vm.$set(t, "value", $$v)},expression:"t.value"}})],1)],1),(i < _vm.DetailsForm.travelers.length - 1)?_c('v-divider',{key:("traveler-divider-" + i)}):_vm._e()]})],2)],1):_vm._e()],1)])],1)],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"disabled":_vm.Step > 1 || _vm.Saving,"color":"primary","plain":""},on:{"click":_vm.discard}},[_vm._v("Discard")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.CreateProductFormState || _vm.Step > 1,"loading":_vm.Saving,"color":"primary","outlined":""},on:{"click":function($event){return _vm.nextStepValidate1(true)}}},[_vm._v("Save & Exit")]),_c('v-btn',{attrs:{"disabled":!_vm.CreateProductFormState || _vm.Step > 1,"loading":_vm.Saving,"color":"primary","depressed":""},on:{"click":function($event){return _vm.nextStepValidate1(false)}}},[_vm._v("Next")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }