const a = {
  inventoryType: [],
  deliveryFormats: [
    {
      value: 'QR_CODE',
      description: '',
    },
    {
      value: 'EAN13',
      description: '',
    },
    {
      value: 'UPCA',
      description: '',
    },
    {
      value: 'TEXT',
      description: '',
    },
    {
      value: 'CODE128',
      description: '',
    },
    {
      value: 'CODE39',
      description: '',
    },
  ],
  deliveryMethods: [
    {
      value: 'TICKET',
      description: '',
    },
    {
      value: 'VOUCHER',
      description: '',
    },
  ],
  locationType: [],
  status: [],
  currency: [
    {
      value: 'AED',
      description: 'United Arab Emirates Dirham',
    },
    {
      value: 'AFN',
      description: 'Afghan Afghani',
    },
    {
      value: 'ALL',
      description: 'Albanian Lek',
    },
    {
      value: 'AMD',
      description: 'Armenian Dram',
    },
    {
      value: 'ANG',
      description: 'Netherlands Antillean guilder',
    },
    {
      value: 'AOA',
      description: 'Angolan kwanza',
    },
    {
      value: 'ARS',
      description: 'Argentine peso',
    },
    {
      value: 'AUD',
      description: 'Australian dollar',
    },
    {
      value: 'AWG',
      description: 'Aruban florin',
    },
    {
      value: 'AZN',
      description: 'Azerbaijani manat',
    },
    {
      value: 'BAM',
      description: 'Bosnia and Herzegovina convertible mark',
    },
    {
      value: 'BBD',
      description: 'Barbados dollar',
    },
    {
      value: 'BDT',
      description: 'Bangladeshi taka',
    },
    {
      value: 'BGN',
      description: 'Bulgarian lev',
    },
    {
      value: 'BHD',
      description: 'Bahraini dinar',
    },
    {
      value: 'BIF',
      description: 'Burundian franc',
    },
    {
      value: 'BMD',
      description: 'Bermudian dollar',
    },
    {
      value: 'BND',
      description: 'Brunei dollar',
    },
    {
      value: 'BOB',
      description: 'Boliviano',
    },
    {
      value: 'BOV',
      description: 'Bolivian Mvdol',
    },
    {
      value: 'BRL',
      description: 'Brazilian real',
    },
    {
      value: 'BSD',
      description: 'Bahamian dollar',
    },
    {
      value: 'BTN',
      description: 'Bhutanese ngultrum',
    },
    {
      value: 'BWP',
      description: 'Botswana pula',
    },
    {
      value: 'BYN',
      description: 'Belarusian ruble',
    },
    {
      value: 'BZD',
      description: 'Belize dollar',
    },
    {
      value: 'CAD',
      description: 'Canadian dollar',
    },
    {
      value: 'CDF',
      description: 'Congolese franc',
    },
    {
      value: 'CHE',
      description: 'WIR euro',
    },
    {
      value: 'CHF',
      description: 'Swiss franc',
    },
    {
      value: 'CHW',
      description: 'WIR franc',
    },
    {
      value: 'CLF',
      description: 'Unidad de Fomento ',
    },
    {
      value: 'CLP',
      description: 'Chilean peso',
    },
    {
      value: 'COP',
      description: 'Colombian peso',
    },
    {
      value: 'COU',
      description: 'Unidad de Valor Real',
    },
    {
      value: 'CRC',
      description: 'Costa Rican colon',
    },
    {
      value: 'CUC',
      description: 'Cuban convertible peso',
    },
    {
      value: 'CUP',
      description: 'Cuban peso',
    },
    {
      value: 'CVE',
      description: 'Cape Verdean escudo',
    },
    {
      value: 'CZK',
      description: 'Czech koruna',
    },
    {
      value: 'DJF',
      description: 'Djiboutian franc',
    },
    {
      value: 'DKK',
      description: 'Danish krone',
    },
    {
      value: 'DOP',
      description: 'Dominican peso',
    },
    {
      value: 'DZD',
      description: 'Algerian dinar',
    },
    {
      value: 'EGP',
      description: 'Egyptian pound',
    },
    {
      value: 'ERN',
      description: 'Eritrean nakfa',
    },
    {
      value: 'ETB',
      description: 'Ethiopian birr',
    },
    {
      value: 'EUR',
      description: 'Euro',
    },
    {
      value: 'FJD',
      description: 'Fiji dollar',
    },
    {
      value: 'FKP',
      description: 'Falkland Islands pound',
    },
    {
      value: 'GBP',
      description: 'Pound sterling',
    },
    {
      value: 'GEL',
      description: 'Georgian lari',
    },
    {
      value: 'GHS',
      description: 'Ghanaian cedi',
    },
    {
      value: 'GIP',
      description: 'Gibraltar pound',
    },
    {
      value: 'GIP',
      description: 'Gibraltar pound',
    },
    {
      value: 'GMD',
      description: 'Gambian dalasi',
    },
    {
      value: 'GNF',
      description: 'Guinean franc',
    },
    {
      value: 'GTQ',
      description: 'Guatemalan quetzal',
    },
    {
      value: 'GYD',
      description: 'Guyanese dollar',
    },
    {
      value: 'HKD',
      description: 'Hong Kong dollar',
    },
    {
      value: 'HNL',
      description: 'Honduran lempira',
    },
    {
      value: 'HRK',
      description: 'Croatian kuna',
    },
    {
      value: 'HTG',
      description: 'Haitian gourde',
    },
    {
      value: 'HUF',
      description: 'Hungarian forint',
    },
    {
      value: 'IDR',
      description: 'Indonesian rupiah',
    },
    {
      value: 'ILS',
      description: 'Israeli new shekel',
    },
    {
      value: 'INR',
      description: 'Indian rupee',
    },
    {
      value: 'IQD',
      description: 'Iraqi dinar',
    },
    {
      value: 'IRR',
      description: 'Iranian rial',
    },
    {
      value: 'ISK',
      description: 'Icelandic króna',
    },
    {
      value: 'JMD',
      description: 'Jamaican dollar',
    },
    {
      value: 'JOD',
      description: 'Jordanian dinar',
    },
    {
      value: 'JPY',
      description: 'Japanese yen',
    },
    {
      value: 'KES',
      description: 'Kenyan shilling',
    },
    {
      value: 'KGS',
      description: 'Kyrgyzstani som',
    },
    {
      value: 'KHR',
      description: 'Cambodian riel',
    },
    {
      value: 'KMF',
      description: 'Comoro franc',
    },
    {
      value: 'KPW',
      description: 'North Korean won',
    },
    {
      value: 'KRW',
      description: 'South Korean won',
    },
    {
      value: 'KWD',
      description: 'Kuwaiti dinar',
    },
    {
      value: 'KYD',
      description: 'Cayman Islands dollar',
    },
    {
      value: 'KZT',
      description: 'Kazakhstani tenge',
    },
    {
      value: 'LAK',
      description: 'Lao kip',
    },
    {
      value: 'LBP',
      description: 'Lebanese pound',
    },
    {
      value: 'LKR',
      description: 'Sri Lankan rupee',
    },
    {
      value: 'LRD',
      description: 'Liberian dollar',
    },
    {
      value: 'LSL',
      description: 'Lesotho loti',
    },
    {
      value: 'LYD',
      description: 'Libyan dinar',
    },
    {
      value: 'MAD',
      description: 'Moroccan dirham',
    },
    {
      value: 'MDL',
      description: 'Moldovan leu',
    },
    {
      value: 'MGA',
      description: 'Malagasy ariary',
    },
    {
      value: 'MKD',
      description: 'Macedonian denar',
    },
    {
      value: 'MMK',
      description: 'Myanmar kyat',
    },
    {
      value: 'MNT',
      description: 'Mongolian tögrög',
    },
    {
      value: 'MOP',
      description: 'Macanese pataca',
    },
    {
      value: 'MRU',
      description: 'Mauritanian ouguiya',
    },
    {
      value: 'MUR',
      description: 'Mauritian rupee',
    },
    {
      value: 'MVR',
      description: 'Maldivian rufiyaa',
    },
    {
      value: 'MWK',
      description: 'Malawian kwacha',
    },
    {
      value: 'MXN',
      description: 'Mexican peso',
    },
    {
      value: 'MXV',
      description: 'Mexican Unidad de Inversion',
    },
    {
      value: 'MYR',
      description: 'Malaysian ringgit',
    },
    {
      value: 'MZN',
      description: 'Mozambican metical',
    },
    {
      value: 'NAD',
      description: 'Namibian dollar',
    },
    {
      value: 'NGN',
      description: 'Nigerian naira',
    },
    {
      value: 'NIO',
      description: 'Nicaraguan córdoba',
    },
    {
      value: 'NOK',
      description: 'Norwegian krone',
    },
    {
      value: 'NPR',
      description: 'Nepalese rupee',
    },
    {
      value: 'NZD',
      description: 'New Zealand dollar',
    },
    {
      value: 'OMR',
      description: 'Omani rial',
    },
    {
      value: 'PAB',
      description: 'Panamanian balboa',
    },
    {
      value: 'PEN',
      description: 'Peruvian sol',
    },
    {
      value: 'PGK',
      description: 'Papua New Guinean kina',
    },
    {
      value: 'PHP',
      description: 'Philippine peso',
    },
    {
      value: 'PKR',
      description: 'Pakistani rupee',
    },
    {
      value: 'PLN',
      description: 'Polish złoty',
    },
    {
      value: 'PYG',
      description: 'Paraguayan guaraní',
    },
    {
      value: 'QAR',
      description: 'Qatari riyal',
    },
    {
      value: 'RON',
      description: 'Romanian leu',
    },
    {
      value: 'RSD',
      description: 'Serbian dinar',
    },
    {
      value: 'CNY',
      description: 'Renminbi',
    },
    {
      value: 'RUB',
      description: 'Russian ruble',
    },
    {
      value: 'RWF',
      description: 'Rwandan franc',
    },
    {
      value: 'SAR',
      description: 'Saudi riyal',
    },
    {
      value: 'SBD',
      description: 'Solomon Islands dollar',
    },
    {
      value: 'SCR',
      description: 'Seychelles rupee',
    },
    {
      value: 'SDG',
      description: 'Sudanese pound',
    },
    {
      value: 'SEK',
      description: 'Swedish krona',
    },
    {
      value: 'SGD',
      description: 'Singapore dollar',
    },
    {
      value: 'SHP',
      description: 'Saint Helena pound',
    },
    {
      value: 'SLE',
      description: 'Sierra Leonean leone (new leone)',
    },
    {
      value: 'SLL',
      description: 'Sierra Leonean leone (old leone)',
    },
    {
      value: 'SOS',
      description: 'Somali shilling',
    },
    {
      value: 'SRD',
      description: 'Surinamese dollar',
    },
    {
      value: 'SSP',
      description: 'South Sudanese pound',
    },
    {
      value: 'STN',
      description: 'São Tomé and Príncipe dobra',
    },
    {
      value: 'SVC',
      description: 'Salvadoran colón',
    },
    {
      value: 'SYP',
      description: 'Syrian pound',
    },
    {
      value: 'SZL',
      description: 'Swazi lilangeni',
    },
    {
      value: 'THB',
      description: 'Thai baht',
    },
    {
      value: 'TJS',
      description: 'Tajikistani somoni',
    },
    {
      value: 'TMT',
      description: 'Turkmenistan manat',
    },
    {
      value: 'TND',
      description: 'Tunisian dinar',
    },
    {
      value: 'TOP',
      description: 'Tongan paʻanga',
    },
    {
      value: 'TRY',
      description: 'Turkish lira',
    },
    {
      value: 'TTD',
      description: 'Trinidad and Tobago dollar',
    },
    {
      value: 'TWD',
      description: 'New Taiwan dollar',
    },
    {
      value: 'TZS',
      description: 'Tanzanian shilling',
    },
    {
      value: 'UAH',
      description: 'Ukrainian hryvnia',
    },
    {
      value: 'UGX',
      description: 'Ugandan shilling',
    },
    {
      value: 'USD',
      description: 'United States Dollar',
    },
    {
      value: 'USN',
      description: 'United States dollar (next day) (funds code)',
    },
    {
      value: 'UYI',
      description: 'Uruguay Peso en Unidades Indexadas',
    },
    {
      value: 'UYU',
      description: 'Uruguayan peso',
    },
    {
      value: 'UYW',
      description: 'Unidad previsional',
    },
    {
      value: 'UZS',
      description: 'Uzbekistan sum',
    },
    {
      value: 'VED',
      description: 'Venezuelan digital bolívar',
    },
    {
      value: 'VES',
      description: 'Venezuelan sovereign bolívar',
    },
    {
      value: 'VND',
      description: 'Vietnamese đồng',
    },
    {
      value: 'VUV',
      description: 'Vanuatu vatu',
    },
    {
      value: 'WST',
      description: 'Samoan tala',
    },
    {
      value: 'XAF',
      description: 'CFA franc BEAC',
    },
    {
      value: 'XCD',
      description: 'East Caribbean dollar',
    },
    {
      value: 'XPF',
      description: 'CFP franc (franc Pacifique)',
    },
    {
      value: 'YER',
      description: 'Yemeni rial',
    },
    {
      value: 'ZAR',
      description: 'South African rand',
    },
    {
      value: 'ZMW',
      description: 'Zambian kwacha',
    },
    {
      value: 'ZWL',
      description: 'Zimbabwean dollar',
    },
  ],
  locale: [
    {
      value: 'eu',
      description: 'Basque',
    },
    {
      value: 'hr_BA',
      description: 'Croatian (Bosnia & Herzegovina)',
    },
    {
      value: 'en_CM',
      description: 'English (Cameroon)',
    },
    {
      value: 'rw_RW',
      description: 'Kinyarwanda (Rwanda)',
    },
    {
      value: 'en_SZ',
      description: 'English (Swaziland)',
    },
    {
      value: 'tk_Latn',
      description: 'Turkmen (Latin)',
    },
    {
      value: 'he_IL',
      description: 'Hebrew (Israel)',
    },
    {
      value: 'ar',
      description: 'Arabic',
    },
    {
      value: 'uz_Arab',
      description: 'Uzbek (Arabic)',
    },
    {
      value: 'en_PN',
      description: 'English (Pitcairn Islands)',
    },
    {
      value: 'as',
      description: 'Assamese',
    },
    {
      value: 'en_NF',
      description: 'English (Norfolk Island)',
    },
    {
      value: 'rwk_TZ',
      description: 'Rwa (Tanzania)',
    },
    {
      value: 'zh_Hant_TW',
      description: 'Chinese (Traditional, Taiwan)',
    },
    {
      value: 'gsw_LI',
      description: 'Swiss German (Liechtenstein)',
    },
    {
      value: 'th_TH',
      description: 'Thai (Thailand)',
    },
    {
      value: 'ta_IN',
      description: 'Tamil (India)',
    },
    {
      value: 'es_EA',
      description: 'Spanish (Ceuta & Melilla)',
    },
    {
      value: 'fr_GF',
      description: 'French (French Guiana)',
    },
    {
      value: 'ar_001',
      description: 'Arabic (World)',
    },
    {
      value: 'en_RW',
      description: 'English (Rwanda)',
    },
    {
      value: 'tr_TR',
      description: 'Turkish (Turkey)',
    },
    {
      value: 'de_CH',
      description: 'German (Switzerland)',
    },
    {
      value: 'ee_TG',
      description: 'Ewe (Togo)',
    },
    {
      value: 'en_NG',
      description: 'English (Nigeria)',
    },
    {
      value: 'fr_TG',
      description: 'French (Togo)',
    },
    {
      value: 'az',
      description: 'Azerbaijani',
    },
    {
      value: 'fr_SC',
      description: 'French (Seychelles)',
    },
    {
      value: 'es_HN',
      description: 'Spanish (Honduras)',
    },
    {
      value: 'en_AG',
      description: 'English (Antigua & Barbuda)',
    },
    {
      value: 'ru_KZ',
      description: 'Russian (Kazakhstan)',
    },
    {
      value: 'gsw',
      description: 'Swiss German',
    },
    {
      value: 'dyo',
      description: 'Jola-Fonyi',
    },
    {
      value: 'so_ET',
      description: 'Somali (Ethiopia)',
    },
    {
      value: 'zh_Hant_MO',
      description: 'Chinese (Traditional, Macau SAR China)',
    },
    {
      value: 'de_BE',
      description: 'German (Belgium)',
    },
    {
      value: 'km_KH',
      description: 'Khmer (Cambodia)',
    },
    {
      value: 'my_MM',
      description: 'Burmese (Myanmar [Burma])',
    },
    {
      value: 'mgh_MZ',
      description: 'Makhuwa-Meetto (Mozambique)',
    },
    {
      value: 'ee_GH',
      description: 'Ewe (Ghana)',
    },
    {
      value: 'es_EC',
      description: 'Spanish (Ecuador)',
    },
    {
      value: 'kw_GB',
      description: 'Cornish (United Kingdom)',
    },
    {
      value: 'rm_CH',
      description: 'Romansh (Switzerland)',
    },
    {
      value: 'en_ME',
      description: 'English (Montenegro)',
    },
    {
      value: 'nyn',
      description: 'Nyankole',
    },
    {
      value: 'mk_MK',
      description: 'Macedonian (Macedonia)',
    },
    {
      value: 'bs_Cyrl_BA',
      description: 'Bosnian (Cyrillic, Bosnia & Herzegovina)',
    },
    {
      value: 'ar_MR',
      description: 'Arabic (Mauritania)',
    },
    {
      value: 'en_BM',
      description: 'English (Bermuda)',
    },
    {
      value: 'ms_Arab',
      description: 'Malay (Arabic)',
    },
    {
      value: 'en_AI',
      description: 'English (Anguilla)',
    },
    {
      value: 'gl_ES',
      description: 'Galician (Spain)',
    },
    {
      value: 'en_PR',
      description: 'English (Puerto Rico)',
    },
    {
      value: 'ha_Latn_GH',
      description: 'Hausa (Latin, Ghana)',
    },
    {
      value: 'ff_CM',
      description: 'Fulah (Cameroon)',
    },
    {
      value: 'ne_IN',
      description: 'Nepali (India)',
    },
    {
      value: 'or_IN',
      description: 'Oriya (India)',
    },
    {
      value: 'khq_ML',
      description: 'Koyra Chiini (Mali)',
    },
    {
      value: 'en_MG',
      description: 'English (Madagascar)',
    },
    {
      value: 'pt_TL',
      description: 'Portuguese (Timor-Leste)',
    },
    {
      value: 'en_LC',
      description: 'English (St. Lucia)',
    },
    {
      value: 'ta_SG',
      description: 'Tamil (Singapore)',
    },
    {
      value: 'jmc_TZ',
      description: 'Machame (Tanzania)',
    },
    {
      value: 'om_ET',
      description: 'Oromo (Ethiopia)',
    },
    {
      value: 'lv_LV',
      description: 'Latvian (Latvia)',
    },
    {
      value: 'es_US',
      description: 'Spanish (United States)',
    },
    {
      value: 'en_PT',
      description: 'English (Portugal)',
    },
    {
      value: 'vai_Latn_LR',
      description: 'Vai (Latin, Liberia)',
    },
    {
      value: 'en_NL',
      description: 'English (Netherlands)',
    },
    {
      value: 'iu_Cans_CA',
      description: 'Inuktitut (Unified Canadian Aboriginal Syllabics, Canada)',
    },
    {
      value: 'cgg_UG',
      description: 'Chiga (Uganda)',
    },
    {
      value: 'ta',
      description: 'Tamil',
    },
    {
      value: 'en_MH',
      description: 'English (Marshall Islands)',
    },
    {
      value: 'to_TO',
      description: 'Tongan (Tonga)',
    },
    {
      value: 'zu_ZA',
      description: 'Zulu (South Africa)',
    },
    {
      value: 'shi_Latn_MA',
      description: 'Tachelhit (Latin, Morocco)',
    },
    {
      value: 'brx_IN',
      description: 'Bodo (India)',
    },
    {
      value: 'ar_KM',
      description: 'Arabic (Comoros)',
    },
    {
      value: 'en_AL',
      description: 'English (Albania)',
    },
    {
      value: 'te',
      description: 'Telugu',
    },
    {
      value: 'chr_US',
      description: 'Cherokee (United States)',
    },
    {
      value: 'yo_BJ',
      description: 'Yoruba (Benin)',
    },
    {
      value: 'fr_VU',
      description: 'French (Vanuatu)',
    },
    {
      value: 'pa',
      description: 'Punjabi',
    },
    {
      value: 'tg',
      description: 'Tajik',
    },
    {
      value: 'ks_Arab',
      description: 'Kashmiri (Arabic)',
    },
    {
      value: 'kea',
      description: 'Kabuverdianu',
    },
    {
      value: 'ksh_DE',
      description: 'Colognian (Germany)',
    },
    {
      value: 'sw_CD',
      description: 'Swahili (Congo - Kinshasa)',
    },
    {
      value: 'th',
      description: 'Thai',
    },
    {
      value: 'te_IN',
      description: 'Telugu (India)',
    },
    {
      value: 'fr_RE',
      description: 'French (Réunion)',
    },
    {
      value: 'ur_IN',
      description: 'Urdu (India)',
    },
    {
      value: 'yo_NG',
      description: 'Yoruba (Nigeria)',
    },
    {
      value: 'ti',
      description: 'Tigrinya',
    },
    {
      value: 'guz_KE',
      description: 'Gusii (Kenya)',
    },
    {
      value: 'tk',
      description: 'Turkmen',
    },
    {
      value: 'kl_GL',
      description: 'Kalaallisut (Greenland)',
    },
    {
      value: 'ksf_CM',
      description: 'Bafia (Cameroon)',
    },
    {
      value: 'mua_CM',
      description: 'Mundang (Cameroon)',
    },
    {
      value: 'lag_TZ',
      description: 'Langi (Tanzania)',
    },
    {
      value: 'lb',
      description: 'Luxembourgish',
    },
    {
      value: 'fr_TN',
      description: 'French (Tunisia)',
    },
    {
      value: 'es_PA',
      description: 'Spanish (Panama)',
    },
    {
      value: 'pl_PL',
      description: 'Polish (Poland)',
    },
    {
      value: 'to',
      description: 'Tongan',
    },
    {
      value: 'hi_IN',
      description: 'Hindi (India)',
    },
    {
      value: 'dje_NE',
      description: 'Zarma (Niger)',
    },
    {
      value: 'es_GQ',
      description: 'Spanish (Equatorial Guinea)',
    },
    {
      value: 'kok_IN',
      description: 'Konkani (India)',
    },
    {
      value: 'pl',
      description: 'Polish',
    },
    {
      value: 'fr_GN',
      description: 'French (Guinea)',
    },
    {
      value: 'bem',
      description: 'Bemba',
    },
    {
      value: 'ha',
      description: 'Hausa',
    },
    {
      value: 'ckb',
      description: 'Central Kurdish',
    },
    {
      value: 'lg',
      description: 'Ganda',
    },
    {
      value: 'tr',
      description: 'Turkish',
    },
    {
      value: 'en_PW',
      description: 'English (Palau)',
    },
    {
      value: 'en_NO',
      description: 'English (Norway)',
    },
    {
      value: 'nyn_UG',
      description: 'Nyankole (Uganda)',
    },
    {
      value: 'sr_Latn_RS',
      description: 'Serbian (Latin, Serbia)',
    },
    {
      value: 'gsw_FR',
      description: 'Swiss German (France)',
    },
    {
      value: 'pa_Guru',
      description: 'Punjabi (Gurmukhi)',
    },
    {
      value: 'he',
      description: 'Hebrew',
    },
    {
      value: 'sn_ZW',
      description: 'Shona (Zimbabwe)',
    },
    {
      value: 'qu_BO',
      description: 'Quechua (Bolivia)',
    },
    {
      value: 'lu_CD',
      description: 'Luba-Katanga (Congo - Kinshasa)',
    },
    {
      value: 'mgo_CM',
      description: 'Metaʼ (Cameroon)',
    },
    {
      value: 'ps_AF',
      description: 'Pashto (Afghanistan)',
    },
    {
      value: 'en_BS',
      description: 'English (Bahamas)',
    },
    {
      value: 'ug_Arab',
      description: 'Uyghur (Arabic)',
    },
    {
      value: 'da',
      description: 'Danish',
    },
    {
      value: 'ms_Latn_SG',
      description: 'Malay (Latin, Singapore)',
    },
    {
      value: 'ps',
      description: 'Pashto',
    },
    {
      value: 'ln',
      description: 'Lingala',
    },
    {
      value: 'pt',
      description: 'Portuguese',
    },
    {
      value: 'iu_Cans',
      description: 'Inuktitut (Unified Canadian Aboriginal Syllabics)',
    },
    {
      value: 'hi',
      description: 'Hindi',
    },
    {
      value: 'lo',
      description: 'Lao',
    },
    {
      value: 'ebu',
      description: 'Embu',
    },
    {
      value: 'de',
      description: 'German',
    },
    {
      value: 'gu_IN',
      description: 'Gujarati (India)',
    },
    {
      value: 'seh',
      description: 'Sena',
    },
    {
      value: 'en_CX',
      description: 'English (Christmas Island)',
    },
    {
      value: 'en_ZM',
      description: 'English (Zambia)',
    },
    {
      value: 'tzm_Latn_MA',
      description: 'Central Atlas Tamazight (Latin, Morocco)',
    },
    {
      value: 'fr_HT',
      description: 'French (Haiti)',
    },
    {
      value: 'fr_GP',
      description: 'French (Guadeloupe)',
    },
    {
      value: 'lt',
      description: 'Lithuanian',
    },
    {
      value: 'lu',
      description: 'Luba-Katanga',
    },
    {
      value: 'ln_CD',
      description: 'Lingala (Congo - Kinshasa)',
    },
    {
      value: 'vai_Latn',
      description: 'Vai (Latin)',
    },
    {
      value: 'el_GR',
      description: 'Greek (Greece)',
    },
    {
      value: 'lv',
      description: 'Latvian',
    },
    {
      value: 'en_KE',
      description: 'English (Kenya)',
    },
    {
      value: 'sbp',
      description: 'Sangu',
    },
    {
      value: 'hr',
      description: 'Croatian',
    },
    {
      value: 'en_CY',
      description: 'English (Cyprus)',
    },
    {
      value: 'es_GT',
      description: 'Spanish (Guatemala)',
    },
    {
      value: 'twq_NE',
      description: 'Tasawaq (Niger)',
    },
    {
      value: 'zh_Hant_HK',
      description: 'Chinese (Traditional, Hong Kong SAR China)',
    },
    {
      value: 'kln_KE',
      description: 'Kalenjin (Kenya)',
    },
    {
      value: 'fr_GQ',
      description: 'French (Equatorial Guinea)',
    },
    {
      value: 'chr',
      description: 'Cherokee',
    },
    {
      value: 'hu',
      description: 'Hungarian',
    },
    {
      value: 'es_UY',
      description: 'Spanish (Uruguay)',
    },
    {
      value: 'fr_CA',
      description: 'French (Canada)',
    },
    {
      value: 'en_NR',
      description: 'English (Nauru)',
    },
    {
      value: 'mer',
      description: 'Meru',
    },
    {
      value: 'shi',
      description: 'Tachelhit',
    },
    {
      value: 'es_PE',
      description: 'Spanish (Peru)',
    },
    {
      value: 'fr_SN',
      description: 'French (Senegal)',
    },
    {
      value: 'bez',
      description: 'Bena',
    },
    {
      value: 'sw_TZ',
      description: 'Swahili (Tanzania)',
    },
    {
      value: 'wae_CH',
      description: 'Walser (Switzerland)',
    },
    {
      value: 'kkj',
      description: 'Kako',
    },
    {
      value: 'hy',
      description: 'Armenian',
    },
    {
      value: 'kk_Cyrl_KZ',
      description: 'Kazakh (Cyrillic, Kazakhstan)',
    },
    {
      value: 'en_CZ',
      description: 'English (Czech Republic)',
    },
    {
      value: 'teo_KE',
      description: 'Teso (Kenya)',
    },
    {
      value: 'teo',
      description: 'Teso',
    },
    {
      value: 'dz_BT',
      description: 'Dzongkha (Bhutan)',
    },
    {
      value: 'ar_JO',
      description: 'Arabic (Jordan)',
    },
    {
      value: 'mer_KE',
      description: 'Meru (Kenya)',
    },
    {
      value: 'khq',
      description: 'Koyra Chiini',
    },
    {
      value: 'ln_CF',
      description: 'Lingala (Central African Republic)',
    },
    {
      value: 'nn_NO',
      description: 'Norwegian Nynorsk (Norway)',
    },
    {
      value: 'en_MO',
      description: 'English (Macau SAR China)',
    },
    {
      value: 'ar_TD',
      description: 'Arabic (Chad)',
    },
    {
      value: 'dz',
      description: 'Dzongkha',
    },
    {
      value: 'ses',
      description: 'Koyraboro Senni',
    },
    {
      value: 'en_BW',
      description: 'English (Botswana)',
    },
    {
      value: 'en_AS',
      description: 'English (American Samoa)',
    },
    {
      value: 'ar_IL',
      description: 'Arabic (Israel)',
    },
    {
      value: 'ms_Latn_BN',
      description: 'Malay (Latin, Brunei)',
    },
    {
      value: 'bo_CN',
      description: 'Tibetan (China)',
    },
    {
      value: 'nnh',
      description: 'Ngiemboon',
    },
    {
      value: 'teo_UG',
      description: 'Teso (Uganda)',
    },
    {
      value: 'hy_AM',
      description: 'Armenian (Armenia)',
    },
    {
      value: 'ln_CG',
      description: 'Lingala (Congo - Brazzaville)',
    },
    {
      value: 'sr_Latn_BA',
      description: 'Serbian (Latin, Bosnia & Herzegovina)',
    },
    {
      value: 'en_MP',
      description: 'English (Northern Mariana Islands)',
    },
    {
      value: 'ksb_TZ',
      description: 'Shambala (Tanzania)',
    },
    {
      value: 'ar_SA',
      description: 'Arabic (Saudi Arabia)',
    },
    {
      value: 'smn_FI',
      description: 'Inari Sami (Finland)',
    },
    {
      value: 'ar_LY',
      description: 'Arabic (Libya)',
    },
    {
      value: 'en_AT',
      description: 'English (Austria)',
    },
    {
      value: 'so_KE',
      description: 'Somali (Kenya)',
    },
    {
      value: 'fr_CD',
      description: 'French (Congo - Kinshasa)',
    },
    {
      value: 'af_NA',
      description: 'Afrikaans (Namibia)',
    },
    {
      value: 'en_NU',
      description: 'English (Niue)',
    },
    {
      value: 'es_PH',
      description: 'Spanish (Philippines)',
    },
    {
      value: 'en_KI',
      description: 'English (Kiribati)',
    },
    {
      value: 'en_JE',
      description: 'English (Jersey)',
    },
    {
      value: 'lkt',
      description: 'Lakota',
    },
    {
      value: 'en_AU',
      description: 'English (Australia)',
    },
    {
      value: 'fa_IR',
      description: 'Persian (Iran)',
    },
    {
      value: 'ky_Cyrl',
      description: 'Kyrgyz (Cyrillic)',
    },
    {
      value: 'uz_Latn_UZ',
      description: 'Uzbek (Latin, Uzbekistan)',
    },
    {
      value: 'zh_Hans_CN',
      description: 'Chinese (Simplified, China)',
    },
    {
      value: 'ewo_CM',
      description: 'Ewondo (Cameroon)',
    },
    {
      value: 'fr_PF',
      description: 'French (French Polynesia)',
    },
    {
      value: 'ca_IT',
      description: 'Catalan (Italy)',
    },
    {
      value: 'en_BZ',
      description: 'English (Belize)',
    },
    {
      value: 'ar_KW',
      description: 'Arabic (Kuwait)',
    },
    {
      value: 'pt_GW',
      description: 'Portuguese (Guinea-Bissau)',
    },
    {
      value: 'fr_FR',
      description: 'French (France)',
    },
    {
      value: 'am_ET',
      description: 'Amharic (Ethiopia)',
    },
    {
      value: 'en_VC',
      description: 'English (St. Vincent & Grenadines)',
    },
    {
      value: 'fr_DJ',
      description: 'French (Djibouti)',
    },
    {
      value: 'fr_CF',
      description: 'French (Central African Republic)',
    },
    {
      value: 'es_SV',
      description: 'Spanish (El Salvador)',
    },
    {
      value: 'en_MS',
      description: 'English (Montserrat)',
    },
    {
      value: 'pt_ST',
      description: 'Portuguese (São Tomé & Príncipe)',
    },
    {
      value: 'ar_SD',
      description: 'Arabic (Sudan)',
    },
    {
      value: 'luy_KE',
      description: 'Luyia (Kenya)',
    },
    {
      value: 'gd_GB',
      description: 'Scottish Gaelic (United Kingdom)',
    },
    {
      value: 'de_LI',
      description: 'German (Liechtenstein)',
    },
    {
      value: 'fr_CG',
      description: 'French (Congo - Brazzaville)',
    },
    {
      value: 'ckb_IQ',
      description: 'Central Kurdish (Iraq)',
    },
    {
      value: 'zh_Hans_SG',
      description: 'Chinese (Simplified, Singapore)',
    },
    {
      value: 'en_MT',
      description: 'English (Malta)',
    },
    {
      value: 'ewo',
      description: 'Ewondo',
    },
    {
      value: 'af_ZA',
      description: 'Afrikaans (South Africa)',
    },
    {
      value: 'os_GE',
      description: 'Ossetic (Georgia)',
    },
    {
      value: 'om_KE',
      description: 'Oromo (Kenya)',
    },
    {
      value: 'nl_SR',
      description: 'Dutch (Suriname)',
    },
    {
      value: 'es_ES',
      description: 'Spanish (Spain)',
    },
    {
      value: 'es_DO',
      description: 'Spanish (Dominican Republic)',
    },
    {
      value: 'ar_IQ',
      description: 'Arabic (Iraq)',
    },
    {
      value: 'fr_CH',
      description: 'French (Switzerland)',
    },
    {
      value: 'nnh_CM',
      description: 'Ngiemboon (Cameroon)',
    },
    {
      value: 'es_419',
      description: 'Spanish (Latin America)',
    },
    {
      value: 'en_MU',
      description: 'English (Mauritius)',
    },
    {
      value: 'bm_Latn',
      description: 'Bambara (Latin)',
    },
    {
      value: 'en_US_POSIX',
      description: 'English (United States, Computer)',
    },
    {
      value: 'yav_CM',
      description: 'Yangben (Cameroon)',
    },
    {
      value: 'luo_KE',
      description: 'Luo (Kenya)',
    },
    {
      value: 'dua_CM',
      description: 'Duala (Cameroon)',
    },
    {
      value: 'et_EE',
      description: 'Estonian (Estonia)',
    },
    {
      value: 'en_IE',
      description: 'English (Ireland)',
    },
    {
      value: 'ak_GH',
      description: 'Akan (Ghana)',
    },
    {
      value: 'rwk',
      description: 'Rwa',
    },
    {
      value: 'es_CL',
      description: 'Spanish (Chile)',
    },
    {
      value: 'kea_CV',
      description: 'Kabuverdianu (Cape Verde)',
    },
    {
      value: 'fr_CI',
      description: 'French (Côte d’Ivoire)',
    },
    {
      value: 'ckb_IR',
      description: 'Central Kurdish (Iran)',
    },
    {
      value: 'fr_BE',
      description: 'French (Belgium)',
    },
    {
      value: 'se',
      description: 'Northern Sami',
    },
    {
      value: 'en_NZ',
      description: 'English (New Zealand)',
    },
    {
      value: 'ky_Cyrl_KG',
      description: 'Kyrgyz (Cyrillic, Kyrgyzstan)',
    },
    {
      value: 'en_LR',
      description: 'English (Liberia)',
    },
    {
      value: 'en_KN',
      description: 'English (St. Kitts & Nevis)',
    },
    {
      value: 'nb_SJ',
      description: 'Norwegian Bokmål (Svalbard & Jan Mayen)',
    },
    {
      value: 'sg',
      description: 'Sango',
    },
    {
      value: 'sr_Cyrl_RS',
      description: 'Serbian (Cyrillic, Serbia)',
    },
    {
      value: 'ru_RU',
      description: 'Russian (Russia)',
    },
    {
      value: 'en_ZW',
      description: 'English (Zimbabwe)',
    },
    {
      value: 'sv_AX',
      description: 'Swedish (Åland Islands)',
    },
    {
      value: 'si',
      description: 'Sinhala',
    },
    {
      value: 'ga_IE',
      description: 'Irish (Ireland)',
    },
    {
      value: 'en_VG',
      description: 'English (British Virgin Islands)',
    },
    {
      value: 'ff_MR',
      description: 'Fulah (Mauritania)',
    },
    {
      value: 'sk',
      description: 'Slovak',
    },
    {
      value: 'agq_CM',
      description: 'Aghem (Cameroon)',
    },
    {
      value: 'fr_BF',
      description: 'French (Burkina Faso)',
    },
    {
      value: 'naq_NA',
      description: 'Nama (Namibia)',
    },
    {
      value: 'sl',
      description: 'Slovenian',
    },
    {
      value: 'en_MW',
      description: 'English (Malawi)',
    },
    {
      value: 'mr_IN',
      description: 'Marathi (India)',
    },
    {
      value: 'az_Latn',
      description: 'Azerbaijani (Latin)',
    },
    {
      value: 'en_LS',
      description: 'English (Lesotho)',
    },
    {
      value: 'de_AT',
      description: 'German (Austria)',
    },
    {
      value: 'ka',
      description: 'Georgian',
    },
    {
      value: 'sn',
      description: 'Shona',
    },
    {
      value: 'sr_Latn_ME',
      description: 'Serbian (Latin, Montenegro)',
    },
    {
      value: 'fr_NC',
      description: 'French (New Caledonia)',
    },
    {
      value: 'so',
      description: 'Somali',
    },
    {
      value: 'is_IS',
      description: 'Icelandic (Iceland)',
    },
    {
      value: 'twq',
      description: 'Tasawaq',
    },
    {
      value: 'ig_NG',
      description: 'Igbo (Nigeria)',
    },
    {
      value: 'sq',
      description: 'Albanian',
    },
    {
      value: 'fo_FO',
      description: 'Faroese (Faroe Islands)',
    },
    {
      value: 'sr',
      description: 'Serbian',
    },
    {
      value: 'tzm',
      description: 'Central Atlas Tamazight',
    },
    {
      value: 'ga',
      description: 'Irish',
    },
    {
      value: 'om',
      description: 'Oromo',
    },
    {
      value: 'en_LT',
      description: 'English (Lithuania)',
    },
    {
      value: 'bas_CM',
      description: 'Basaa (Cameroon)',
    },
    {
      value: 'se_NO',
      description: 'Northern Sami (Norway)',
    },
    {
      value: 'ki',
      description: 'Kikuyu',
    },
    {
      value: 'nl_BE',
      description: 'Dutch (Belgium)',
    },
    {
      value: 'ar_QA',
      description: 'Arabic (Qatar)',
    },
    {
      value: 'gd',
      description: 'Scottish Gaelic',
    },
    {
      value: 'sv',
      description: 'Swedish',
    },
    {
      value: 'kk',
      description: 'Kazakh',
    },
    {
      value: 'sw',
      description: 'Swahili',
    },
    {
      value: 'es_CO',
      description: 'Spanish (Colombia)',
    },
    {
      value: 'az_Latn_AZ',
      description: 'Azerbaijani (Latin, Azerbaijan)',
    },
    {
      value: 'rn_BI',
      description: 'Rundi (Burundi)',
    },
    {
      value: 'or',
      description: 'Oriya',
    },
    {
      value: 'kl',
      description: 'Kalaallisut',
    },
    {
      value: 'ca',
      description: 'Catalan',
    },
    {
      value: 'en_VI',
      description: 'English (U.S. Virgin Islands)',
    },
    {
      value: 'km',
      description: 'Khmer',
    },
    {
      value: 'os',
      description: 'Ossetic',
    },
    {
      value: 'en_MY',
      description: 'English (Malaysia)',
    },
    {
      value: 'kn',
      description: 'Kannada',
    },
    {
      value: 'en_LU',
      description: 'English (Luxembourg)',
    },
    {
      value: 'fr_SY',
      description: 'French (Syria)',
    },
    {
      value: 'ar_TN',
      description: 'Arabic (Tunisia)',
    },
    {
      value: 'en_JM',
      description: 'English (Jamaica)',
    },
    {
      value: 'fr_PM',
      description: 'French (St. Pierre & Miquelon)',
    },
    {
      value: 'ko',
      description: 'Korean',
    },
    {
      value: 'fr_NE',
      description: 'French (Niger)',
    },
    {
      value: 'fr_MA',
      description: 'French (Morocco)',
    },
    {
      value: 'gl',
      description: 'Galician',
    },
    {
      value: 'ru_MD',
      description: 'Russian (Moldova)',
    },
    {
      value: 'saq_KE',
      description: 'Samburu (Kenya)',
    },
    {
      value: 'ks',
      description: 'Kashmiri',
    },
    {
      value: 'fr_CM',
      description: 'French (Cameroon)',
    },
    {
      value: 'lb_LU',
      description: 'Luxembourgish (Luxembourg)',
    },
    {
      value: 'gv_IM',
      description: 'Manx (Isle of Man)',
    },
    {
      value: 'fr_BI',
      description: 'French (Burundi)',
    },
    {
      value: 'en_LV',
      description: 'English (Latvia)',
    },
    {
      value: 'ks_Arab_IN',
      description: 'Kashmiri (Arabic, India)',
    },
    {
      value: 'es_NI',
      description: 'Spanish (Nicaragua)',
    },
    {
      value: 'en_GB',
      description: 'English (United Kingdom)',
    },
    {
      value: 'kw',
      description: 'Cornish',
    },
    {
      value: 'nl_SX',
      description: 'Dutch (Sint Maarten)',
    },
    {
      value: 'dav_KE',
      description: 'Taita (Kenya)',
    },
    {
      value: 'tr_CY',
      description: 'Turkish (Cyprus)',
    },
    {
      value: 'ky',
      description: 'Kyrgyz',
    },
    {
      value: 'en_UG',
      description: 'English (Uganda)',
    },
    {
      value: 'nus_SD',
      description: 'Nuer (Sudan)',
    },
    {
      value: 'en_TC',
      description: 'English (Turks & Caicos Islands)',
    },
    {
      value: 'tzm_Latn',
      description: 'Central Atlas Tamazight (Latin)',
    },
    {
      value: 'ar_EG',
      description: 'Arabic (Egypt)',
    },
    {
      value: 'fr_BJ',
      description: 'French (Benin)',
    },
    {
      value: 'gu',
      description: 'Gujarati',
    },
    {
      value: 'es_PR',
      description: 'Spanish (Puerto Rico)',
    },
    {
      value: 'fr_RW',
      description: 'French (Rwanda)',
    },
    {
      value: 'sr_Cyrl_BA',
      description: 'Serbian (Cyrillic, Bosnia & Herzegovina)',
    },
    {
      value: 'gv',
      description: 'Manx',
    },
    {
      value: 'fr_MC',
      description: 'French (Monaco)',
    },
    {
      value: 'cs',
      description: 'Czech',
    },
    {
      value: 'bez_TZ',
      description: 'Bena (Tanzania)',
    },
    {
      value: 'es_CR',
      description: 'Spanish (Costa Rica)',
    },
    {
      value: 'asa_TZ',
      description: 'Asu (Tanzania)',
    },
    {
      value: 'ar_EH',
      description: 'Arabic (Western Sahara)',
    },
    {
      value: 'ms_Arab_BN',
      description: 'Malay (Arabic, Brunei)',
    },
    {
      value: 'mn_Cyrl',
      description: 'Mongolian (Cyrillic)',
    },
    {
      value: 'sbp_TZ',
      description: 'Sangu (Tanzania)',
    },
    {
      value: 'en_IL',
      description: 'English (Israel)',
    },
    {
      value: 'ha_Latn_NE',
      description: 'Hausa (Latin, Niger)',
    },
    {
      value: 'lt_LT',
      description: 'Lithuanian (Lithuania)',
    },
    {
      value: 'mfe',
      description: 'Morisyen',
    },
    {
      value: 'en_GD',
      description: 'English (Grenada)',
    },
    {
      value: 'cy',
      description: 'Welsh',
    },
    {
      value: 'ca_FR',
      description: 'Catalan (France)',
    },
    {
      value: 'es_BO',
      description: 'Spanish (Bolivia)',
    },
    {
      value: 'fr_BL',
      description: 'French (St. Barthélemy)',
    },
    {
      value: 'bn_IN',
      description: 'Bengali (India)',
    },
    {
      value: 'uz_Cyrl_UZ',
      description: 'Uzbek (Cyrillic, Uzbekistan)',
    },
    {
      value: 'az_Cyrl',
      description: 'Azerbaijani (Cyrillic)',
    },
    {
      value: 'en_IM',
      description: 'English (Isle of Man)',
    },
    {
      value: 'sw_KE',
      description: 'Swahili (Kenya)',
    },
    {
      value: 'en_SB',
      description: 'English (Solomon Islands)',
    },
    {
      value: 'pa_Arab',
      description: 'Punjabi (Arabic)',
    },
    {
      value: 'ur_PK',
      description: 'Urdu (Pakistan)',
    },
    {
      value: 'haw_US',
      description: 'Hawaiian (United States)',
    },
    {
      value: 'ar_SO',
      description: 'Arabic (Somalia)',
    },
    {
      value: 'en_IN',
      description: 'English (India)',
    },
    {
      value: 'ha_Latn',
      description: 'Hausa (Latin)',
    },
    {
      value: 'fil',
      description: 'Filipino',
    },
    {
      value: 'fr_MF',
      description: 'French (St. Martin)',
    },
    {
      value: 'en_WS',
      description: 'English (Samoa)',
    },
    {
      value: 'es_CU',
      description: 'Spanish (Cuba)',
    },
    {
      value: 'ja_JP',
      description: 'Japanese (Japan)',
    },
    {
      value: 'fy_NL',
      description: 'Western Frisian (Netherlands)',
    },
    {
      value: 'en_SC',
      description: 'English (Seychelles)',
    },
    {
      value: 'en_IO',
      description: 'English (British Indian Ocean Territory)',
    },
    {
      value: 'pt_PT',
      description: 'Portuguese (Portugal)',
    },
    {
      value: 'en_HK',
      description: 'English (Hong Kong SAR China)',
    },
    {
      value: 'en_GG',
      description: 'English (Guernsey)',
    },
    {
      value: 'fr_MG',
      description: 'French (Madagascar)',
    },
    {
      value: 'de_LU',
      description: 'German (Luxembourg)',
    },
    {
      value: 'ms_Latn_MY',
      description: 'Malay (Latin, Malaysia)',
    },
    {
      value: 'tg_Cyrl',
      description: 'Tajik (Cyrillic)',
    },
    {
      value: 'en_SD',
      description: 'English (Sudan)',
    },
    {
      value: 'shi_Tfng',
      description: 'Tachelhit (Tifinagh)',
    },
    {
      value: 'ln_AO',
      description: 'Lingala (Angola)',
    },
    {
      value: 'ug_Arab_CN',
      description: 'Uyghur (Arabic, China)',
    },
    {
      value: 'as_IN',
      description: 'Assamese (India)',
    },
    {
      value: 'en_GH',
      description: 'English (Ghana)',
    },
    {
      value: 'ro_RO',
      description: 'Romanian (Romania)',
    },
    {
      value: 'jgo_CM',
      description: 'Ngomba (Cameroon)',
    },
    {
      value: 'dua',
      description: 'Duala',
    },
    {
      value: 'en_UM',
      description: 'English (U.S. Outlying Islands)',
    },
    {
      value: 'en_SE',
      description: 'English (Sweden)',
    },
    {
      value: 'kn_IN',
      description: 'Kannada (India)',
    },
    {
      value: 'en_KY',
      description: 'English (Cayman Islands)',
    },
    {
      value: 'vun_TZ',
      description: 'Vunjo (Tanzania)',
    },
    {
      value: 'kln',
      description: 'Kalenjin',
    },
    {
      value: 'en_GI',
      description: 'English (Gibraltar)',
    },
    {
      value: 'ca_ES',
      description: 'Catalan (Spain)',
    },
    {
      value: 'rof',
      description: 'Rombo',
    },
    {
      value: 'pt_CV',
      description: 'Portuguese (Cape Verde)',
    },
    {
      value: 'kok',
      description: 'Konkani',
    },
    {
      value: 'pt_BR',
      description: 'Portuguese (Brazil)',
    },
    {
      value: 'ar_DJ',
      description: 'Arabic (Djibouti)',
    },
    {
      value: 'yi_001',
      description: 'Yiddish (World)',
    },
    {
      value: 'fi_FI',
      description: 'Finnish (Finland)',
    },
    {
      value: 'tg_Cyrl_TJ',
      description: 'Tajik (Cyrillic, Tajikistan)',
    },
    {
      value: 'zh',
      description: 'Chinese',
    },
    {
      value: 'es_PY',
      description: 'Spanish (Paraguay)',
    },
    {
      value: 'ar_SS',
      description: 'Arabic (South Sudan)',
    },
    {
      value: 'mua',
      description: 'Mundang',
    },
    {
      value: 'sr_Cyrl_ME',
      description: 'Serbian (Cyrillic, Montenegro)',
    },
    {
      value: 'vai_Vaii_LR',
      description: 'Vai (Vai, Liberia)',
    },
    {
      value: 'en_001',
      description: 'English (World)',
    },
    {
      value: 'nl_NL',
      description: 'Dutch (Netherlands)',
    },
    {
      value: 'en_TK',
      description: 'English (Tokelau)',
    },
    {
      value: 'si_LK',
      description: 'Sinhala (Sri Lanka)',
    },
    {
      value: 'en_SG',
      description: 'English (Singapore)',
    },
    {
      value: 'sv_SE',
      description: 'Swedish (Sweden)',
    },
    {
      value: 'fr_DZ',
      description: 'French (Algeria)',
    },
    {
      value: 'ca_AD',
      description: 'Catalan (Andorra)',
    },
    {
      value: 'pt_AO',
      description: 'Portuguese (Angola)',
    },
    {
      value: 'vi',
      description: 'Vietnamese',
    },
    {
      value: 'xog_UG',
      description: 'Soga (Uganda)',
    },
    {
      value: 'xog',
      description: 'Soga',
    },
    {
      value: 'en_IS',
      description: 'English (Iceland)',
    },
    {
      value: 'nb',
      description: 'Norwegian Bokmål',
    },
    {
      value: 'seh_MZ',
      description: 'Sena (Mozambique)',
    },
    {
      value: 'es_AR',
      description: 'Spanish (Argentina)',
    },
    {
      value: 'sk_SK',
      description: 'Slovak (Slovakia)',
    },
    {
      value: 'en_SH',
      description: 'English (St. Helena)',
    },
    {
      value: 'ti_ER',
      description: 'Tigrinya (Eritrea)',
    },
    {
      value: 'nd',
      description: 'North Ndebele',
    },
    {
      value: 'az_Cyrl_AZ',
      description: 'Azerbaijani (Cyrillic, Azerbaijan)',
    },
    {
      value: 'zu',
      description: 'Zulu',
    },
    {
      value: 'ne',
      description: 'Nepali',
    },
    {
      value: 'nd_ZW',
      description: 'North Ndebele (Zimbabwe)',
    },
    {
      value: 'el_CY',
      description: 'Greek (Cyprus)',
    },
    {
      value: 'en_IT',
      description: 'English (Italy)',
    },
    {
      value: 'nl_BQ',
      description: 'Dutch (Caribbean Netherlands)',
    },
    {
      value: 'da_GL',
      description: 'Danish (Greenland)',
    },
    {
      value: 'ja',
      description: 'Japanese',
    },
    {
      value: 'rm',
      description: 'Romansh',
    },
    {
      value: 'fr_ML',
      description: 'French (Mali)',
    },
    {
      value: 'rn',
      description: 'Rundi',
    },
    {
      value: 'en_VU',
      description: 'English (Vanuatu)',
    },
    {
      value: 'rof_TZ',
      description: 'Rombo (Tanzania)',
    },
    {
      value: 'ro',
      description: 'Romanian',
    },
    {
      value: 'ebu_KE',
      description: 'Embu (Kenya)',
    },
    {
      value: 'ru_KG',
      description: 'Russian (Kyrgyzstan)',
    },
    {
      value: 'en_SI',
      description: 'English (Slovenia)',
    },
    {
      value: 'sg_CF',
      description: 'Sango (Central African Republic)',
    },
    {
      value: 'mfe_MU',
      description: 'Morisyen (Mauritius)',
    },
    {
      value: 'nl',
      description: 'Dutch',
    },
    {
      value: 'brx',
      description: 'Bodo',
    },
    {
      value: 'bs_Latn',
      description: 'Bosnian (Latin)',
    },
    {
      value: 'fa',
      description: 'Persian',
    },
    {
      value: 'zgh_MA',
      description: 'Standard Moroccan Tamazight (Morocco)',
    },
    {
      value: 'en_GM',
      description: 'English (Gambia)',
    },
    {
      value: 'shi_Latn',
      description: 'Tachelhit (Latin)',
    },
    {
      value: 'en_FI',
      description: 'English (Finland)',
    },
    {
      value: 'nn',
      description: 'Norwegian Nynorsk',
    },
    {
      value: 'en_EE',
      description: 'English (Estonia)',
    },
    {
      value: 'ru',
      description: 'Russian',
    },
    {
      value: 'kam_KE',
      description: 'Kamba (Kenya)',
    },
    {
      value: 'fur',
      description: 'Friulian',
    },
    {
      value: 'vai_Vaii',
      description: 'Vai (Vai)',
    },
    {
      value: 'ar_ER',
      description: 'Arabic (Eritrea)',
    },
    {
      value: 'ti_ET',
      description: 'Tigrinya (Ethiopia)',
    },
    {
      value: 'rw',
      description: 'Kinyarwanda',
    },
    {
      value: 'ff',
      description: 'Fulah',
    },
    {
      value: 'luo',
      description: 'Luo',
    },
    {
      value: 'fa_AF',
      description: 'Persian (Afghanistan)',
    },
    {
      value: 'ha_Latn_NG',
      description: 'Hausa (Latin, Nigeria)',
    },
    {
      value: 'nl_CW',
      description: 'Dutch (Curaçao)',
    },
    {
      value: 'en_HR',
      description: 'English (Croatia)',
    },
    {
      value: 'en_FJ',
      description: 'English (Fiji)',
    },
    {
      value: 'fi',
      description: 'Finnish',
    },
    {
      value: 'pt_MO',
      description: 'Portuguese (Macau SAR China)',
    },
    {
      value: 'be',
      description: 'Belarusian',
    },
    {
      value: 'en_US',
      description: 'English (United States)',
    },
    {
      value: 'en_TO',
      description: 'English (Tonga)',
    },
    {
      value: 'en_SK',
      description: 'English (Slovakia)',
    },
    {
      value: 'bg',
      description: 'Bulgarian',
    },
    {
      value: 'ru_BY',
      description: 'Russian (Belarus)',
    },
    {
      value: 'it_IT',
      description: 'Italian (Italy)',
    },
    {
      value: 'ml_IN',
      description: 'Malayalam (India)',
    },
    {
      value: 'gsw_CH',
      description: 'Swiss German (Switzerland)',
    },
    {
      value: 'qu_EC',
      description: 'Quechua (Ecuador)',
    },
    {
      value: 'fo',
      description: 'Faroese',
    },
    {
      value: 'sv_FI',
      description: 'Swedish (Finland)',
    },
    {
      value: 'en_FK',
      description: 'English (Falkland Islands)',
    },
    {
      value: 'nus',
      description: 'Nuer',
    },
    {
      value: 'ta_LK',
      description: 'Tamil (Sri Lanka)',
    },
    {
      value: 'vun',
      description: 'Vunjo',
    },
    {
      value: 'sr_Latn',
      description: 'Serbian (Latin)',
    },
    {
      value: 'fr',
      description: 'French',
    },
    {
      value: 'en_SL',
      description: 'English (Sierra Leone)',
    },
    {
      value: 'bm',
      description: 'Bambara',
    },
    {
      value: 'ar_BH',
      description: 'Arabic (Bahrain)',
    },
    {
      value: 'guz',
      description: 'Gusii',
    },
    {
      value: 'bn',
      description: 'Bengali',
    },
    {
      value: 'bo',
      description: 'Tibetan',
    },
    {
      value: 'ar_SY',
      description: 'Arabic (Syria)',
    },
    {
      value: 'lo_LA',
      description: 'Lao (Laos)',
    },
    {
      value: 'ne_NP',
      description: 'Nepali (Nepal)',
    },
    {
      value: 'uz_Latn',
      description: 'Uzbek (Latin)',
    },
    {
      value: 'be_BY',
      description: 'Belarusian (Belarus)',
    },
    {
      value: 'es_IC',
      description: 'Spanish (Canary Islands)',
    },
    {
      value: 'sr_Latn_XK',
      description: 'Serbian (Latin, Kosovo)',
    },
    {
      value: 'ar_MA',
      description: 'Arabic (Morocco)',
    },
    {
      value: 'pa_Guru_IN',
      description: 'Punjabi (Gurmukhi, India)',
    },
    {
      value: 'br',
      description: 'Breton',
    },
    {
      value: 'luy',
      description: 'Luyia',
    },
    {
      value: 'kde_TZ',
      description: 'Makonde (Tanzania)',
    },
    {
      value: 'bs',
      description: 'Bosnian',
    },
    {
      value: 'fy',
      description: 'Western Frisian',
    },
    {
      value: 'fur_IT',
      description: 'Friulian (Italy)',
    },
    {
      value: 'hu_HU',
      description: 'Hungarian (Hungary)',
    },
    {
      value: 'ar_AE',
      description: 'Arabic (United Arab Emirates)',
    },
    {
      value: 'en_HU',
      description: 'English (Hungary)',
    },
    {
      value: 'sah_RU',
      description: 'Sakha (Russia)',
    },
    {
      value: 'zh_Hans',
      description: 'Chinese (Simplified)',
    },
    {
      value: 'en_FM',
      description: 'English (Micronesia)',
    },
    {
      value: 'sq_AL',
      description: 'Albanian (Albania)',
    },
    {
      value: 'ko_KP',
      description: 'Korean (North Korea)',
    },
    {
      value: 'en_150',
      description: 'English (Europe)',
    },
    {
      value: 'en_DE',
      description: 'English (Germany)',
    },
    {
      value: 'fr_MQ',
      description: 'French (Martinique)',
    },
    {
      value: 'en_CA',
      description: 'English (Canada)',
    },
    {
      value: 'hsb_DE',
      description: 'Upper Sorbian (Germany)',
    },
    {
      value: 'en_TR',
      description: 'English (Turkey)',
    },
    {
      value: 'ro_MD',
      description: 'Romanian (Moldova)',
    },
    {
      value: 'es_VE',
      description: 'Spanish (Venezuela)',
    },
    {
      value: 'fr_WF',
      description: 'French (Wallis & Futuna)',
    },
    {
      value: 'mt_MT',
      description: 'Maltese (Malta)',
    },
    {
      value: 'kab',
      description: 'Kabyle',
    },
    {
      value: 'nmg_CM',
      description: 'Kwasio (Cameroon)',
    },
    {
      value: 'en_GR',
      description: 'English (Greece)',
    },
    {
      value: 'ru_UA',
      description: 'Russian (Ukraine)',
    },
    {
      value: 'fr_MR',
      description: 'French (Mauritania)',
    },
    {
      value: 'tk_Latn_TM',
      description: 'Turkmen (Latin, Turkmenistan)',
    },
    {
      value: 'zh_Hans_MO',
      description: 'Chinese (Simplified, Macau SAR China)',
    },
    {
      value: 'mn_Cyrl_MN',
      description: 'Mongolian (Cyrillic, Mongolia)',
    },
    {
      value: 'ff_GN',
      description: 'Fulah (Guinea)',
    },
    {
      value: 'bs_Cyrl',
      description: 'Bosnian (Cyrillic)',
    },
    {
      value: 'sw_UG',
      description: 'Swahili (Uganda)',
    },
    {
      value: 'ko_KR',
      description: 'Korean (South Korea)',
    },
    {
      value: 'en_DG',
      description: 'English (Diego Garcia)',
    },
    {
      value: 'bo_IN',
      description: 'Tibetan (India)',
    },
    {
      value: 'en_CC',
      description: 'English (Cocos [Keeling] Islands)',
    },
    {
      value: 'shi_Tfng_MA',
      description: 'Tachelhit (Tifinagh, Morocco)',
    },
    {
      value: 'lag',
      description: 'Langi',
    },
    {
      value: 'it_SM',
      description: 'Italian (San Marino)',
    },
    {
      value: 'os_RU',
      description: 'Ossetic (Russia)',
    },
    {
      value: 'en_TT',
      description: 'English (Trinidad & Tobago)',
    },
    {
      value: 'ms_Arab_MY',
      description: 'Malay (Arabic, Malaysia)',
    },
    {
      value: 'sq_MK',
      description: 'Albanian (Macedonia)',
    },
    {
      value: 'ms_Latn',
      description: 'Malay (Latin)',
    },
    {
      value: 'bem_ZM',
      description: 'Bemba (Zambia)',
    },
    {
      value: 'kde',
      description: 'Makonde',
    },
    {
      value: 'ar_OM',
      description: 'Arabic (Oman)',
    },
    {
      value: 'cgg',
      description: 'Chiga',
    },
    {
      value: 'bas',
      description: 'Basaa',
    },
    {
      value: 'bm_Latn_ML',
      description: 'Bambara (Latin, Mali)',
    },
    {
      value: 'kam',
      description: 'Kamba',
    },
    {
      value: 'es_MX',
      description: 'Spanish (Mexico)',
    },
    {
      value: 'sah',
      description: 'Sakha',
    },
    {
      value: 'wae',
      description: 'Walser',
    },
    {
      value: 'en_GU',
      description: 'English (Guam)',
    },
    {
      value: 'zh_Hant',
      description: 'Chinese (Traditional)',
    },
    {
      value: 'fr_MU',
      description: 'French (Mauritius)',
    },
    {
      value: 'fr_KM',
      description: 'French (Comoros)',
    },
    {
      value: 'ar_LB',
      description: 'Arabic (Lebanon)',
    },
    {
      value: 'en_BA',
      description: 'English (Bosnia & Herzegovina)',
    },
    {
      value: 'en_TV',
      description: 'English (Tuvalu)',
    },
    {
      value: 'sr_Cyrl',
      description: 'Serbian (Cyrillic)',
    },
    {
      value: 'dje',
      description: 'Zarma',
    },
    {
      value: 'kab_DZ',
      description: 'Kabyle (Algeria)',
    },
    {
      value: 'fil_PH',
      description: 'Filipino (Philippines)',
    },
    {
      value: 'se_SE',
      description: 'Northern Sami (Sweden)',
    },
    {
      value: 'vai',
      description: 'Vai',
    },
    {
      value: 'hr_HR',
      description: 'Croatian (Croatia)',
    },
    {
      value: 'bs_Latn_BA',
      description: 'Bosnian (Latin, Bosnia & Herzegovina)',
    },
    {
      value: 'nl_AW',
      description: 'Dutch (Aruba)',
    },
    {
      value: 'dav',
      description: 'Taita',
    },
    {
      value: 'so_SO',
      description: 'Somali (Somalia)',
    },
    {
      value: 'ar_PS',
      description: 'Arabic (Palestinian Territories)',
    },
    {
      value: 'en_FR',
      description: 'English (France)',
    },
    {
      value: 'uz_Cyrl',
      description: 'Uzbek (Cyrillic)',
    },
    {
      value: 'ff_SN',
      description: 'Fulah (Senegal)',
    },
    {
      value: 'en_BB',
      description: 'English (Barbados)',
    },
    {
      value: 'ki_KE',
      description: 'Kikuyu (Kenya)',
    },
    {
      value: 'naq',
      description: 'Nama',
    },
    {
      value: 'en_SS',
      description: 'English (South Sudan)',
    },
    {
      value: 'mg_MG',
      description: 'Malagasy (Madagascar)',
    },
    {
      value: 'mas_KE',
      description: 'Masai (Kenya)',
    },
    {
      value: 'en_RO',
      description: 'English (Romania)',
    },
    {
      value: 'en_PG',
      description: 'English (Papua New Guinea)',
    },
    {
      value: 'mgh',
      description: 'Makhuwa-Meetto',
    },
    {
      value: 'dyo_SN',
      description: 'Jola-Fonyi (Senegal)',
    },
    {
      value: 'mas',
      description: 'Masai',
    },
    {
      value: 'agq',
      description: 'Aghem',
    },
    {
      value: 'bn_BD',
      description: 'Bengali (Bangladesh)',
    },
    {
      value: 'haw',
      description: 'Hawaiian',
    },
    {
      value: 'yi',
      description: 'Yiddish',
    },
    {
      value: 'nb_NO',
      description: 'Norwegian Bokmål (Norway)',
    },
    {
      value: 'da_DK',
      description: 'Danish (Denmark)',
    },
    {
      value: 'en_DK',
      description: 'English (Denmark)',
    },
    {
      value: 'saq',
      description: 'Samburu',
    },
    {
      value: 'ug',
      description: 'Uyghur',
    },
    {
      value: 'cy_GB',
      description: 'Welsh (United Kingdom)',
    },
    {
      value: 'fr_YT',
      description: 'French (Mayotte)',
    },
    {
      value: 'jmc',
      description: 'Machame',
    },
    {
      value: 'ses_ML',
      description: 'Koyraboro Senni (Mali)',
    },
    {
      value: 'en_PH',
      description: 'English (Philippines)',
    },
    {
      value: 'de_DE',
      description: 'German (Germany)',
    },
    {
      value: 'ar_YE',
      description: 'Arabic (Yemen)',
    },
    {
      value: 'yo',
      description: 'Yoruba',
    },
    {
      value: 'lkt_US',
      description: 'Lakota (United States)',
    },
    {
      value: 'uz_Arab_AF',
      description: 'Uzbek (Arabic, Afghanistan)',
    },
    {
      value: 'jgo',
      description: 'Ngomba',
    },
    {
      value: 'sl_SI',
      description: 'Slovenian (Slovenia)',
    },
    {
      value: 'uk',
      description: 'Ukrainian',
    },
    {
      value: 'en_CH',
      description: 'English (Switzerland)',
    },
    {
      value: 'asa',
      description: 'Asu',
    },
    {
      value: 'lg_UG',
      description: 'Ganda (Uganda)',
    },
    {
      value: 'qu_PE',
      description: 'Quechua (Peru)',
    },
    {
      value: 'mgo',
      description: 'Metaʼ',
    },
    {
      value: 'id_ID',
      description: 'Indonesian (Indonesia)',
    },
    {
      value: 'en_NA',
      description: 'English (Namibia)',
    },
    {
      value: 'en_GY',
      description: 'English (Guyana)',
    },
    {
      value: 'zgh',
      description: 'Standard Moroccan Tamazight',
    },
    {
      value: 'pt_MZ',
      description: 'Portuguese (Mozambique)',
    },
    {
      value: 'fr_LU',
      description: 'French (Luxembourg)',
    },
    {
      value: 'kk_Cyrl',
      description: 'Kazakh (Cyrillic)',
    },
    {
      value: 'mas_TZ',
      description: 'Masai (Tanzania)',
    },
    {
      value: 'en_DM',
      description: 'English (Dominica)',
    },
    {
      value: 'ta_MY',
      description: 'Tamil (Malaysia)',
    },
    {
      value: 'dsb',
      description: 'Lower Sorbian',
    },
    {
      value: 'en_BE',
      description: 'English (Belgium)',
    },
    {
      value: 'mg',
      description: 'Malagasy',
    },
    {
      value: 'ur',
      description: 'Urdu',
    },
    {
      value: 'fr_GA',
      description: 'French (Gabon)',
    },
    {
      value: 'ka_GE',
      description: 'Georgian (Georgia)',
    },
    {
      value: 'nmg',
      description: 'Kwasio',
    },
    {
      value: 'en_TZ',
      description: 'English (Tanzania)',
    },
    {
      value: 'eu_ES',
      description: 'Basque (Spain)',
    },
    {
      value: 'ar_DZ',
      description: 'Arabic (Algeria)',
    },
    {
      value: 'id',
      description: 'Indonesian',
    },
    {
      value: 'so_DJ',
      description: 'Somali (Djibouti)',
    },
    {
      value: 'hsb',
      description: 'Upper Sorbian',
    },
    {
      value: 'yav',
      description: 'Yangben',
    },
    {
      value: 'mk',
      description: 'Macedonian',
    },
    {
      value: 'pa_Arab_PK',
      description: 'Punjabi (Arabic, Pakistan)',
    },
    {
      value: 'ml',
      description: 'Malayalam',
    },
    {
      value: 'en_ER',
      description: 'English (Eritrea)',
    },
    {
      value: 'ig',
      description: 'Igbo',
    },
    {
      value: 'se_FI',
      description: 'Northern Sami (Finland)',
    },
    {
      value: 'mn',
      description: 'Mongolian',
    },
    {
      value: 'ksb',
      description: 'Shambala',
    },
    {
      value: 'uz',
      description: 'Uzbek',
    },
    {
      value: 'vi_VN',
      description: 'Vietnamese (Vietnam)',
    },
    {
      value: 'ii',
      description: 'Sichuan Yi',
    },
    {
      value: 'qu',
      description: 'Quechua',
    },
    {
      value: 'en_PK',
      description: 'English (Pakistan)',
    },
    {
      value: 'ee',
      description: 'Ewe',
    },
    {
      value: 'mr',
      description: 'Marathi',
    },
    {
      value: 'ms',
      description: 'Malay',
    },
    {
      value: 'en_ES',
      description: 'English (Spain)',
    },
    {
      value: 'sq_XK',
      description: 'Albanian (Kosovo)',
    },
    {
      value: 'it_CH',
      description: 'Italian (Switzerland)',
    },
    {
      value: 'mt',
      description: 'Maltese',
    },
    {
      value: 'en_CK',
      description: 'English (Cook Islands)',
    },
    {
      value: 'br_FR',
      description: 'Breton (France)',
    },
    {
      value: 'sr_Cyrl_XK',
      description: 'Serbian (Cyrillic, Kosovo)',
    },
    {
      value: 'ksf',
      description: 'Bafia',
    },
    {
      value: 'en_SX',
      description: 'English (Sint Maarten)',
    },
    {
      value: 'bg_BG',
      description: 'Bulgarian (Bulgaria)',
    },
    {
      value: 'en_PL',
      description: 'English (Poland)',
    },
    {
      value: 'af',
      description: 'Afrikaans',
    },
    {
      value: 'el',
      description: 'Greek',
    },
    {
      value: 'cs_CZ',
      description: 'Czech (Czech Republic)',
    },
    {
      value: 'fr_TD',
      description: 'French (Chad)',
    },
    {
      value: 'zh_Hans_HK',
      description: 'Chinese (Simplified, Hong Kong SAR China)',
    },
    {
      value: 'is',
      description: 'Icelandic',
    },
    {
      value: 'ksh',
      description: 'Colognian',
    },
    {
      value: 'my',
      description: 'Burmese',
    },
    {
      value: 'en',
      description: 'English',
    },
    {
      value: 'it',
      description: 'Italian',
    },
    {
      value: 'dsb_DE',
      description: 'Lower Sorbian (Germany)',
    },
    {
      value: 'ii_CN',
      description: 'Sichuan Yi (China)',
    },
    {
      value: 'smn',
      description: 'Inari Sami',
    },
    {
      value: 'iu',
      description: 'Inuktitut',
    },
    {
      value: 'eo',
      description: 'Esperanto',
    },
    {
      value: 'en_ZA',
      description: 'English (South Africa)',
    },
    {
      value: 'en_AD',
      description: 'English (Andorra)',
    },
    {
      value: 'ak',
      description: 'Akan',
    },
    {
      value: 'en_RU',
      description: 'English (Russia)',
    },
    {
      value: 'kkj_CM',
      description: 'Kako (Cameroon)',
    },
    {
      value: 'am',
      description: 'Amharic',
    },
    {
      value: 'es',
      description: 'Spanish',
    },
    {
      value: 'et',
      description: 'Estonian',
    },
    {
      value: 'uk_UA',
      description: 'Ukrainian (Ukraine)',
    },
  ],
  travellerTypes: [
    {
      value: 'ADULT',
      description: '',
    },
    {
      value: 'CHILD',
      description: '',
    },
    {
      value: 'INFANT',
      description: '',
    },
    {
      value: 'SENIOR',
      description: '',
    },
    {
      value: 'STUDENT',
      description: '',
    },
    {
      value: 'TRAVELLER',
      description: '',
    },
    {
      value: 'YOUTH',
      description: '',
    },
  ],
  charges: [
    {
      value: 'Tax',
      description: '',
    },
    {
      value: 'Fee',
      description: '',
    },
    {
      value: 'Other',
      description: '',
    },
    {
      value: 'Unknown',
      description: '',
    },
  ],
};

export const appCodes = a;
