




























































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  emailRules,
  firstNameRules,
  lastNameRules,
  locationPeopleRules,
  optionalPhoneRules,
  passwordLoginRules,
  passwordMatchRules,
  requiredAtLeastOneElementFieldRules,
  userLoginRules,
} from '@/utils/validation-rules';
import {
  generateUser,
  handleKeyDownValidPhoneCharacter,
  knownRoles,
} from '@/utils/helpers';
import { Account as UserProfile } from '@/api/wps/BackplaneModels';
import _ from 'lodash';
import RdmUnsavedChangesModal from '@/components/common/alert/RdmUnsavedChangesModal.vue';
import { profileModule } from '@/store/modules/moduleProfile';
import { companyModule } from '@/store/modules/moduleCompany';
import { userModule } from '@/store/modules/moduleUser';
import { OperatorUser } from '@/models';
import { constants } from '@/utils/constants';
import backplaneClient from '@/api/backplane/BackplaneClient';

@Component({
  components: {
    RdmUnsavedChangesModal,
  },
})
export default class AddUserModal extends Vue {
  /* DATA */

  // user to create (ready payload, only org_code is absent)
  private user: UserProfile;
  private baseUser: UserProfile;

  // stages
  private stageNumber = 1;

  private isValidStage1 = true;

  private isValidStage2 = true;

  private isValidStage3 = true;

  // rules
  private firstNameRules = firstNameRules;
  private lastNameRules = lastNameRules;
  private usernameRules = userLoginRules;
  private passwordRules = passwordLoginRules;
  private requiredAtLeastOneFieldRules = requiredAtLeastOneElementFieldRules;

  private verifyPasswordRules = passwordMatchRules;
  private handleValidPhoneCharacters = handleKeyDownValidPhoneCharacter;

  private emailRules = emailRules;
  private optionalPhoneRules = optionalPhoneRules;
  private locationPeopleRules = locationPeopleRules;

  // validation state
  private invalidAccount = false;
  private validAccount = false;
  private validationLoading = false;
  private validateSelectedLocations = false;

  // permissions
  private permissionsArray: Array<any> = [];

  private selectedPermissions: Array<any> = [];
  private selectedLocations: Array<string> = [];

  // other

  private warningDialog = false;
  private unsavedChangesWarning = false;

  private placeholderData: object = generateUser();

  /* HOOKS */

  private created() {
    this.user = {};
    this.baseUser = {};

    const filterRoles = _.cloneDeep(knownRoles);

    if (this.Company.orgType !== constants.ORG_TYPE_TS) {
      // removing ticketing roles
      const ticketEditI = filterRoles.findIndex(
        (v) => v === constants.roles.RC_TICKETING_EDIT,
      );
      filterRoles.splice(ticketEditI, 1);
      const ticketViewI = filterRoles.findIndex(
        (v) => v === constants.roles.RC_TICKETING_VIEW,
      );
      filterRoles.splice(ticketViewI, 1);
    }

    backplaneClient.getRoles().subscribe((res) => {
      this.permissionsArray = res.groups
        .filter((group: any) => {
          return !!filterRoles.find((v) => v === group.name);
        })
        .map((group: any) => {
          if (group.name === constants.roles.SUPPLIER_BUSINESS_STAFF) {
            this.selectedPermissions.push(group.id);
          }
          return {
            key: group.id,
            label: group.name,
            disabled: group.name === constants.roles.SUPPLIER_BUSINESS_STAFF,
          };
        });

      if (this.permissionsArray && Array.isArray(this.permissionsArray)) {
        let connectivityManagement = undefined;
        let viewAccess = undefined;
        this.permissionsArray.forEach((p: any, index) => {
          if (p.label === constants.roles.SUPPLIER_BUSINESS_STAFF) {
            viewAccess = _.cloneDeep(p);
            this.permissionsArray.splice(index, 1);
          }
          if (p.label === constants.roles.RC_CONNECTIVITY_MANAGEMENT) {
            connectivityManagement = _.cloneDeep(p);
            this.permissionsArray.splice(index, 1);
          }
        });
        // sorting roles to the top of permission list
        if (connectivityManagement) {
          this.permissionsArray.unshift(connectivityManagement);
        }
        if (viewAccess) {
          this.permissionsArray.unshift(viewAccess);
        }
      }
    });
  }
  /* TODO:
    private created() {
    backplaneService
      .getAllGroups()
      .pipe(
        switchMap(v => {
          if (v.data && v.data.groups) return from(v.data.groups);
          return from([]);
        }),
        filter((group: any) => {
          return !![
            'SUPPLIER_BUSINESS_STAFF',
            'SUPPLIER_REDEMPTION_STAFF',
            'ORG_MGMT',
          ].find(v => v === group.name);
        }),
        map((group: any) => ({
          key: group.id,
          label: group.name,
          disabled: group.name === 'SUPPLIER_BUSINESS_STAFF',
        })),
        tap(v => {
          if (v.label === 'SUPPLIER_BUSINESS_STAFF') {
            this.selectedPermissions.push(v.key);
          }
        }),
      )
      .subscribe(res => {
        if (res.label === 'SUPPLIER_BUSINESS_STAFF') {
          this.permissionsArray.unshift(res);
        } else {
          this.permissionsArray.push(res);
        }
      });
  }
  */

  /* METHODS */

  private cancelForm() {
    // todo checkEquality if false, popup warning
    if (!this.checkEquality()) {
      this.unsavedChangesWarning = true;
      return;
    }
    this.$emit('user-add-cancelled');
  }

  private proceedUnsaved() {
    this.unsavedChangesWarning = false;
    this.$emit('user-add-cancelled');
  }

  private previousStage() {
    --this.stageNumber;
  }

  private nextStage() {
    ++this.stageNumber;
  }

  private warningProceed() {
    this.warningDialog = false;
    this.nextStage();
  }

  private checkRolesAndNextStage() {
    // Alert if view role not added
    const role = this.permissionsArray.find((p) => {
      return p.label.toUpperCase() === 'SUPPLIER_BUSINESS_STAFF';
    });

    if (
      role &&
      this.selectedPermissions &&
      this.selectedPermissions.findIndex((p) => {
        return p === role.key;
      }) > -1
    ) {
      this.nextStage();
      return;
    }

    this.warningDialog = true;
  }

  private nextOrConfirm() {
    if (this.stageNumber === 1) {
      if (this.$refs && this.$refs.stage1Form) {
        const { stage1Form } = this.$refs as any;
        const valid = stage1Form.validate();
        this.toggleValidateSelectedLocation();
        if (valid && this.HasSelectedLocations) {
          this.nextStage();
        } else {
          // stage1Form
        }
      }
    } else if (this.stageNumber === 2) {
      if (this.$refs && this.$refs.stage2Form) {
        const { stage2Form } = this.$refs as any;
        const valid = stage2Form.validate();
        if (valid) {
          this.nextStage();
        }
      }
    } else if (this.stageNumber === 3) {
      this.checkRolesAndNextStage();
      // Populate username with e-mail
      if (this.user && this.user.email) {
        this.user.username = this.user.email.valueOf();
      }
    } else if (this.stageNumber === 4) {
      if (this.isValidStage1 && this.isValidStage2 && this.isValidStage3) {
        this.saveForm();
      }
    }
  }

  private saveForm() {
    const operatorUser = {
      id: '',
      name: this.user.email,
      config: {
        managedLocation: this.selectedLocations,
      },
    } as OperatorUser;
    userModule.setOperatorUser(operatorUser);
    this.user.groups = this.selectedPermissions.map((id) => ({ id, name: '' }));
    // this.user.id = faker.datatype.uuid();
    this.$emit('user-add-confirmed', this.user);
  }

  private validateEmail() {
    if (this.user?.email?.length === 0) {
      return;
    }

    this.validationLoading = true;
    backplaneClient
      .listUsers({
        email: this.user.email,
        org_code: profileModule.Profile?.org_code,
      })
      .subscribe(
        (results) => {
          if (
            results &&
            results.accounts &&
            results.accounts.length > 0 &&
            results.accounts.find((element) => element.email === this.user.email)
          ) {
            this.invalidAccount = true;
            this.validAccount = false;
            return;
          }
          this.invalidAccount = false;
          this.validAccount = true;
        },
        () => {
          /* TODO */
        },
        () => (this.validationLoading = false),
      );
  }

  private checkEquality(): boolean {
    return _.isEqual(this.user, this.baseUser);
  }

  private prependPlus(input: any) {
    if (input && input.length === 1 && Number.isInteger(+input)) {
      this.user.phone = `+${this.user.phone}`;
    }
  }

  private toggleValidateSelectedLocation() {
    if (!this.validateSelectedLocations) {
      this.validateSelectedLocations = true;
    }
  }

  private toggleAllLocations() {
    if (this.selectedLocations.length === this.Locations.length) {
      this.selectedLocations = [];
    } else if (this.selectedLocations.length < this.Locations.length) {
      this.selectedLocations = [];
      this.Locations.forEach((l) => {
        if (l.id) {
          this.selectedLocations.push(l.id);
        }
      });
    }
  }

  get Locations() {
    return companyModule.Addresses;
  }
  get Company() {
    return companyModule.Company;
  }

  get HasSelectedLocations(): boolean {
    if (this.Locations.length === 0) {
      return true;
    } else if (this.validateSelectedLocations) {
      return this.selectedLocations.length > 0;
    }
    return true;
  }
}
