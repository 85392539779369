

































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmMessage from '@/components/common/alert/RdmMessage.vue';
import { Unit } from '@/api/inventory/InventoryModels';
import {
  ageStringRules,
  optionalDescriptionLimitRules,
  requiredFieldRules,
} from '@/utils/validation-rules';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { constants } from '@/utils/constants';

@Component({
  components: {
    RdmMessage,
  },
})
export default class EditTravelerType extends Vue {
  private minAge: number | string = '';
  private maxAge: number | string = '';
  private saving = false;
  private unsavedChangesWarning = false;
  private travelerTypeForm = false;
  // private invalidAgeBand = false;
  private travelerType: Unit = {} as Unit;
  private baseTravelerType: Unit = {} as Unit;
  private requiredFieldRules = requiredFieldRules;
  private ageStringRules = ageStringRules;
  private descriptionLimitRules = optionalDescriptionLimitRules;
  private descriptionLimit = 1000;
  private descriptionPlaceholder = 'Description';
  private validAmount = [() => 'Enter an age greater than the minimum age!'];

  mounted() {
    travelerTypeModule.setCreateTravelerTypeAgeRangeState(false);
    this.travelerType = _.cloneDeep(travelerTypeModule.SelectedTravelerType);
    this.baseTravelerType = _.cloneDeep(travelerTypeModule.SelectedTravelerType);
    if (this.travelerType?.maxAge) {
      this.maxAge = _.toNumber(this.travelerType.maxAge);
    }
    if (this.travelerType?.minAge) {
      this.minAge = _.toNumber(this.travelerType.minAge);
    }
  }

  validate() {
    if (this.$refs && this.$refs.travelerTypeForm) {
      const { travelerTypeForm } = this.$refs as any;
      travelerTypeForm.validate();
    }
  }

  tryCloseModal() {
    if (this.formChanges) {
      this.unsavedChangesWarning = true;
      return;
    }

    this.proceedUnsaved();
  }

  saveForm() {
    try {
      this.saving = true;

      if (!this.maxAge || this.maxAge === '' || this.maxAge === 0) {
        this.travelerType.maxAge = 150;
      } else {
        this.travelerType.maxAge = _.round(_.toNumber(this.maxAge));
      }

      this.travelerType.minAge = _.round(_.toNumber(this.minAge));
      httpModule
        .updateTravelerTypes(this.travelerType)
        .then()
        .finally(() => {
          this.saving = false;
          this.$router.push({ name: constants.routes.TRAVELER_LIST }).catch();
        });
    } catch {
      this.saving = false;
      // todo send alert
    }
  }

  cancel() {
    this.unsavedChangesWarning = true;
  }

  private proceedUnsaved() {
    this.unsavedChangesWarning = false;
    this.$router.push({ name: constants.routes.TRAVELER_LIST }).catch();
  }

  get formChanges() {
    return !_.isEqual(this.travelerType, this.baseTravelerType);
  }

  get AgeClassifications() {
    return travelerTypeModule.AgeClassifications;
  }

  get InvalidMax() {
    return !!(this.minAge && this.maxAge && +this.maxAge <= +this.minAge);
  }
}
