



















































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import {
  requiredFieldRules,
  optionalNumberMinimumRules,
  validWholeNumbersRules,
} from '@/utils/validation-rules';
import {
  BookingCutoffOptions,
  CancellationPolicyOptions,
  Product,
  ProductPolicy,
  RefundablePolicyOptions,
} from '@/models';
import { productModule } from '@/store/modules/moduleProduct';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import _ from 'lodash';
import { constants } from '@/utils/constants';
import { handleKeyDownOnlyWholeNumeric } from '@/utils/helpers';
import { scheduleModule } from '@/store/modules/moduleSchedule';

@Component({
  components: {
    RdmCollapsableControlCard,
  },
})
export default class ProductCreatePolicy extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private validWholeNumbersRules = validWholeNumbersRules;
  private product = {} as Product;
  private cancellationType = 0 as CancellationPolicyOptions;
  private refundable = 0 as RefundablePolicyOptions;
  private bookingCutoff = 0 as BookingCutoffOptions;
  private optionalNumberMin = optionalNumberMinimumRules;
  private cutoffForm = false;
  private cancellationCutoff = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private bookingCutoffTimes = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;

  nextStepValidate3(done: boolean) {
    if (this.$refs?.policyForm) {
      const policyForm: any = this.$refs.policyForm;
      const isValid = policyForm.validate();
      if (isValid) {
        this.createPolicy(done);
      }
    }
  }

  createPolicy(done: boolean) {
    this.product = _.cloneDeep(this.Product);

    const bookingMinutes = this.bookingCutoffTimes.minutes;
    const bookingMinutesFromHours = this.bookingCutoffTimes.hours * 60;
    const bookingMinutesFromDays = this.bookingCutoffTimes.days * 24 * 60;
    const bookingCutoff =
      +bookingMinutes + +bookingMinutesFromHours + +bookingMinutesFromDays || 0;

    const cancellable = this.cancellationType === CancellationPolicyOptions.YES;
    const refundable = this.refundable === RefundablePolicyOptions.YES;

    this.product.policy = {} as ProductPolicy;
    this.product.policy.refundable = refundable;
    this.product.policy.cancellable = cancellable;
    this.product.policy.cutOffTime = bookingCutoff;
    this.updateProduct(done);
  }

  updateProduct(done: boolean) {
    productModule.setProduct(this.product);
    httpModule
      .updateAddProduct()
      .then(() => {
        if (done) {
          this.goToProductDetails();
        } else {
          this.openScheduleStep();
        }
      })
      .catch(() => {
        // todo send alert
      });
  }

  discard() {
    this.$router.push({ name: constants.routes.PRODUCT_LIST }).catch();
  }

  openScheduleStep() {
    scheduleModule.clearState();
    productModule.setCreateProductPolicyOpen(false);
    productModule.setCreateProductStep(4);
    productModule.setCreateProductScheduleOpen(true);
  }

  togglePolicyOpen() {
    productModule.setCreateProductPolicyOpen(!this.PolicyOpen);
  }

  goToProductDetails() {
    this.product = _.cloneDeep(this.Product);
    this.$router
      .push({ name: constants.routes.PRODUCT_DETAILS, params: { id: this.product.id } })
      .catch();
  }

  /* Page Data */

  get Company() {
    return companyModule.Company;
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get Product() {
    return productModule.Product;
  }

  get CreateProductPolicyFormState(): boolean {
    return productModule.CreateProductPolicyFormState;
  }

  set CreateProductPolicyFormState(state: boolean) {
    productModule.setCreateProductPolicyFormState(state);
  }

  get PolicyForm(): any {
    return productModule.CreateProductPolicyForm;
  }

  set PolicyForm(form: any) {
    productModule.setCreateProductPolicyForm(form);
  }

  /* UI State */
  get Step() {
    return productModule.CreateProductStep;
  }

  get Saving() {
    return productModule.CreateProductSaving;
  }

  get PolicyOpen() {
    return productModule.CreateProductPolicyOpen;
  }

  /* UI Data */

  get CancellationTypes() {
    return [CancellationPolicyOptions.YES, CancellationPolicyOptions.NO];
  }

  get RefundValues() {
    return [RefundablePolicyOptions.YES, RefundablePolicyOptions.NO];
  }

  get BookingCutoff() {
    return [BookingCutoffOptions.YES, BookingCutoffOptions.NO];
  }
}
