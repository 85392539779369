


































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmDateRangePicker from '@/components/RdmDateRangePicker.vue';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import { DatePickerOptionsDateRange, DateSearchTypeList } from '@/utils/componentHelpers';
import { BookingsReportFilter, BookingReport, BookingItem } from '@/models/Report';
import { httpModule } from '@/store/modules/moduleHttp';
import { reportModule } from '@/store/modules/moduleReport';
import { chain } from 'lodash';
import { tablePaginationOptions } from '@/utils/helpers';
import xlsx, { IJsonSheet } from 'json-as-xlsx';
import { companyModule } from '@/store/modules/moduleCompany';

@Component({
  components: {
    RdmStatusIndicator,
    RdmDateRangePicker,
  },
})
export default class CustomerManifestReport extends Vue {
  public search = '';

  public reportHeaders: Array<Record<string, any>> = [
    { text: 'Arrival Date', align: 'left', sortable: true, value: 'arrivalDate' },
    { text: 'Traveler Name', sortable: true, value: 'travelerName' },
    { text: 'Booking ID', sortable: true, value: 'bookingID' },
    { text: 'Product', sortable: true, value: 'product' },
    { text: 'Net Sales', sortable: true, value: 'netSales' },
    { text: 'Retail Sales', sortable: true, value: 'grossSales' },
    { text: 'Channel', align: 'left', sortable: true, value: 'channel' },
  ];

  private data = [] as any[];
  private allData = [] as any[];
  private locations = [] as string[];

  private filters: BookingsReportFilter = new BookingsReportFilter();
  private options = DatePickerOptionsDateRange(this.$i18n);
  private dateRange = [] as Array<Date>;
  private datePick = new Date().toDateString();
  private dateRangeType = 'today';

  private dateSearchTypeList = [
    {
      label: 'Today',
      value: 'today',
    },
    {
      label: 'Last Week',
      value: 'week',
    },
    {
      label: 'Last Month',
      value: 'month',
    },
    ...DateSearchTypeList(this.$i18n),
  ];
  private downloadSettings = {
    fileName: `customer_manifest__${this.datePick}`,
    extraLength: 3,
  };

  get Loading() {
    return reportModule.Loading;
  }

  mounted() {
    this.filters.target = companyModule.Company.externalSystemId || '';
    this.clearValues();
  }

  updateSummaryData() {
    this.getLocations();
  }

  download() {
    const data: IJsonSheet[] = [
      {
        sheet: 'Customer Manifest Report',
        columns: [
          { label: 'Reseller Ref#', value: 'resellerBookingRef' },
          { label: 'Supplier Ref#', value: 'supplierBookingRef' },
          { label: 'Redeam Ref#', value: 'bookingID' },
          { label: 'Booking Date', value: 'bookingDate' },
          { label: 'Arrival Date', value: 'arrivalDate' },
          { label: 'Channel', value: 'channel' },
          { label: 'Location', value: 'location' },
          { label: 'Product', value: 'product' },
          { label: 'Option', value: 'option' },
          { label: 'Traveler Name', value: 'travelerName' },
          { label: 'Traveler Email', value: 'travelerEmail' },
          { label: 'Traveler Phone', value: 'travelerPhone' },
          { label: 'Traveler Type', value: 'travelerType' },
          { label: 'Traveler Count', value: 'travelerCount' },
          { label: 'Retail Sales', value: 'grossSales' },
          { label: 'Net Sales', value: 'netSales' },
          { label: 'Currency', value: 'currency' },
          { label: 'Status', value: 'bookingStatus' },
          { label: 'Redemption Count', value: 'redemptionCount' },
          { label: 'Redemption Time', value: 'timeRedeemed' },
        ],
        content: [{ organization: '' }],
      },
    ];
    this.data.forEach((location) => {
      location.items?.map((item: any) =>
        data[0].content.push({
          resellerBookingRef: item.resellerBookingRef,
          supplierBookingRef: item.supplierBookingRef,
          bookingID: item.bookingID,
          bookingDate: item.bookingDate,
          arrivalDate: item.arrivalDate,
          channel: item.channel,
          location: item.location,
          product: item.product,
          option: item.option,
          travelerName: item.travelerName,
          travelerEmail: item.travelerEmail,
          travelerPhone: item.travelerPhone,
          travelerType: item.travelerType,
          travelerCount: item.travelerCount,
          grossSales: this.formatAmount(item.grossSales, item.currency),
          netSales: this.formatAmount(item.netSales, item.currency),
          currency: item.currency,
          bookingStatus: item.bookingStatus,
          redemptionCount: item.redemptionCount,
          timeRedeemed: item.timeRedeemed,
        }),
      );
    });
    xlsx(data, this.downloadSettings);
  }

  searchBookings() {
    httpModule.searchBookingsReport(this.filters).then(() => {
      const data = reportModule.Reports?.items?.map((report: BookingReport) => {
        return report?.items?.map((item: BookingItem) => {
          return {
            resellerBookingRef: report.resellerBookingRef,
            supplierBookingRef: report.supplierBookingRef,
            bookingID: report.bookingID,
            bookingDate: report.bookingDate,
            arrivalDate: report.arrivalDate,
            channel: report.channel,
            location: item.supplier,
            locationID: item.supplierID,
            product: item.product,
            productID: item.productID,
            option: item.rate,
            travelerName: report.travelerName,
            travelerEmail: report.travelerEmail,
            travelerPhone: report.travelerPhone,
            travelerType: item.travelerType,
            travelerCount: item.quantity,
            grossSales: this.formatAmount(item.grossSales, item.currency),
            netSales: this.formatAmount(item.netSales, item.currency),
            currency: item.currency,
            bookingStatus: report.bookingStatus,
            redemptionCount: item.redemptionCount,
            timeRedeemed: item.timeRedeemed,
          };
        });
      });

      if (data?.length > 0) {
        const flat = data.flat(1);
        const sorted = chain(flat)
          .groupBy('locationID')
          .map((items, id) => ({ id, name: items[0].location, items }))
          .value();
        this.data = sorted;
      }
      this.allData = this.data;
      this.updateSummaryData();
    });
  }

  /**
   * formatAmount - formats the amount value to the 'pretty' value for display
   * @param amount
   * @param currency
   */
  formatAmount(amount: number, currency: string): string {
    return amount.toLocaleString('en-US', { style: 'currency', currency });
  }

  getLocations() {
    const p: string[] = [];
    p.push(...this.allData.map((itm) => itm.name));
    p.unshift('All');
    this.locations = p;
  }

  changeLocation(loc: string) {
    if (loc === 'All') {
      this.data = this.allData;
    } else {
      this.data = this.allData.filter((br) => br.name === loc);
    }
    this.updateSummaryData();
  }

  changeDates(range: string) {
    if (range === 'rangeSelect' && this.dateRangeType === 'dateRange') {
      const from = new Date(this.dateRange[0]);
      const to = new Date(this.dateRange[1]);
      to.setDate(to.getDate() + 1);
      this.filters.start = from.toISOString().slice(0, 10);
      this.filters.end = to.toISOString().slice(0, 10);
      this.searchBookings();
    }

    if (range === 'dateSelect') {
      if (this.dateRangeType === 'fromDate') {
        const fromDate = new Date(this.datePick);
        fromDate.setDate(fromDate.getDate() - 1);
        this.filters.start = fromDate.toISOString().slice(0, 10);
        this.filters.end = '';
        this.searchBookings();
      }

      if (this.dateRangeType === 'untilDate') {
        const untilDate = new Date(this.datePick);
        untilDate.setDate(untilDate.getDate() + 1);
        this.filters.start = '';
        this.filters.end = untilDate.toISOString().slice(0, 10);
        this.searchBookings();
      }
    }
  }

  refresh() {
    this.searchBookings();
  }

  private clearValues() {
    this.dateRange = [];
    this.datePick = '';

    if (this.dateRangeType === 'today') {
      this.datePick = new Date().toDateString();
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.dateRange = [today, tomorrow];
      this.filters.start = today.toISOString().slice(0, 10);
      this.filters.end = tomorrow.toISOString().slice(0, 10);
      this.searchBookings();
    }

    if (this.dateRangeType === 'week') {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 8);
      this.dateRange = [weekAgo, today];
      this.filters.start = weekAgo.toISOString().slice(0, 10);
      this.filters.end = today.toISOString().slice(0, 10);
      this.searchBookings();
    }

    if (this.dateRangeType === 'month') {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const monthAgo = new Date();
      monthAgo.setMonth(monthAgo.getMonth() - 1);
      this.dateRange = [monthAgo, today];
      this.filters.start = monthAgo.toISOString().slice(0, 10);
      this.filters.end = today.toISOString().slice(0, 10);
      this.searchBookings();
    }
  }

  paginationOptions(items: any[]) {
    return tablePaginationOptions(items);
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }
}
