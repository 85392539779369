var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":_vm.getEvents},model:{value:(_vm.schedules),callback:function ($$v) {_vm.schedules=$$v},expression:"schedules"}},[_vm._l((_vm.ScheduleViews),function(schedule,i){return [_c('v-list-item',{key:("list-item-schedule-" + i),attrs:{"value":schedule.id,"active-class":"list-item-no-background"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm._f("capitalize")(schedule.scheduleName))+" ")])]}}],null,true)})]})],2)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{staticClass:"mr-4",attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-select',{staticClass:"short-select",attrs:{"items":_vm.typeOptions,"outlined":"","item-text":"name","item-value":"value","hide-details":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-sheet',{attrs:{"height":"750","width":"750"}},[(_vm.type !== 'year')?_c('v-calendar',{ref:"calendar",attrs:{"weekdays":_vm.weekday,"type":_vm.type,"events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"interval-style":_vm.intervalStyling,"event-color":"error"},on:{"change":_vm.getEvents,"click:event":_vm.selectEvent,"click:more":_vm.selectMore},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var eventParsed = ref.eventParsed;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pl-1"},'div',attrs,false),on),[(event)?_c('strong',[_vm._v(_vm._s(event.name))]):_vm._e(),_c('br'),(eventParsed && eventParsed.start)?_c('span',[_vm._v(_vm._s(_vm._f("formatTime12Hours")(eventParsed.start.time)))]):_vm._e(),_vm._v(" - "),(eventParsed && eventParsed.end)?_c('span',[_vm._v(_vm._s(_vm._f("formatTime12Hours")(eventParsed.end.time)))]):_vm._e()])]}}],null,true),model:{value:(event.display),callback:function ($$v) {_vm.$set(event, "display", $$v)},expression:"event.display"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(event.name)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){event.display = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("emptyNotProvided")(event.validStart))+" - "+_vm._s(event.validEnd || 'Ongoing')+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime12HoursFromTS")(event.start))+" - "+_vm._s(_vm._f("formatTime12HoursFromTS")(event.end))+" ")]),_c('div',[_vm._v(" Maximum Capacity: "+_vm._s(_vm._f("emptyNumberNotProvided")(event.capacity))+" ")])])],1)],1)],1)],1)]}}],null,false,22152737),model:{value:(_vm.calendarValue),callback:function ($$v) {_vm.calendarValue=$$v},expression:"calendarValue"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }