






































import { Component, Vue } from 'vue-property-decorator';
import { daysOfWeek } from '@/utils/helpers';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfWeek,
  format,
  getDay,
  isSameMonth,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { DayAvailability, DisplayDate } from '@/models';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import { constants } from '@/utils/constants';
import moment from 'moment';
import { pricingModule } from '@/store/modules/modulePricing';

@Component({
  props: {
    month: {
      type: Number,
      default: 1,
    },
    year: {
      type: Number,
      default: 2000,
    },
    slots: {
      type: Array,
      default() {
        return [];
      },
    },
  },
})
export default class AvailabilityStatusCalendarMonthly extends Vue {
  private weekDays = daysOfWeek;
  showDay(day: DisplayDate) {
    pricingModule.setSelectedOfferCapacityId(day.capacityId);
    if (day.isInMonth) {
      availabilityStatusModule.setSelectedDate(day.isoDate);
      availabilityStatusModule.changeShowSidebarState(true);
      if (availabilityStatusModule.AvailabilityDates.has(day.isoDate)) {
        const d = availabilityStatusModule.AvailabilityDates.get(day.isoDate);
        availabilityStatusModule.setSelectedDayAvailability(d || ({} as DayAvailability));
        availabilityStatusModule.setBaseSelectedDayAvailability(
          d || ({} as DayAvailability),
        );
      }
      this.generateWeeks();
    }
    this.eventSelectDay(day);
  }

  eventSelectDay(day: DisplayDate) {
    this.$emit(constants.events.SELECT_CALENDAR_DAY, day);
  }

  get MonthName() {
    return format(new Date(this.$props.year, this.$props.month - 1, 1), 'MMMM');
  }

  get Weeks() {
    return this.generateWeeks();
  }

  get AvTest() {
    return availabilityStatusModule.AvailabilityDates;
  }

  get OfferCapacityList() {
    return pricingModule.OfferCapacityList;
  }

  private isInventoryDay(day: Date) {
    const today = moment();
    const d = moment(day);
    return d.isBefore(today, 'day')
      ? false
      : availabilityStatusModule.AvailabilityDates.has(format(day, 'yyyy-MM-dd'));
  }

  private findCapacityId(day: Date) {
    let capacityId = '';
    this.OfferCapacityList.map((oc) => {
      const end = oc.validUntil ? moment(oc.validUntil) : null;
      const start = moment(oc.validFrom);
      if (
        end &&
        moment(day).isSameOrAfter(start, 'day') &&
        moment(day).isSameOrBefore(end, 'day')
      ) {
        capacityId = oc.id;
      }
      if (!end && moment(day).isSameOrAfter(start, 'day')) {
        capacityId = oc.id;
      }
    });
    return capacityId;
  }

  private generateWeeks() {
    const weeks = [];
    const date = new Date(this.$props.year, this.$props.month - 1, 1);
    const s = startOfMonth(date);
    const e = lastDayOfMonth(date);
    const w = eachWeekOfInterval({ start: s, end: e });
    if (w.length > 0) {
      for (let i = 0; i < w.length; i++) {
        const n = i + 1;
        if (n < w.length) {
          const d = eachDayOfInterval({ start: w[i], end: w[n] });
          const dSlice = d.slice(0, 7);
          const days = [] as Array<DisplayDate>;
          dSlice.forEach((day) => {
            days.push({
              value: day.getDate(),
              dayOfWeek: day.getDay(),
              isBlackout: this.isBlackedOut(day),
              isInMonth: isSameMonth(day, s),
              isInventoryItem: this.isInventoryDay(day) && isSameMonth(day, s),
              isNoInventory: false,
              isSoldout: this.isSoldout(day),
              isoDate: day.toISOString(),
              capacityId: this.findCapacityId(day),
            });
          });
          weeks.push(days);
        }
      }

      // adding last week of the month
      const lastDay = w[w.length - 1];
      const endOfWeekLastDay = endOfWeek(lastDay);
      const ldDay = getDay(lastDay);
      if (ldDay !== 6) {
        const d = eachDayOfInterval({ start: lastDay, end: endOfWeekLastDay });
        const dSlice = d.slice(0, 7);
        const days = [] as Array<DisplayDate>;
        dSlice.forEach((day) => {
          days.push({
            capacityId: this.findCapacityId(day),
            value: day.getDate(),
            dayOfWeek: day.getDay(),
            isBlackout: this.isBlackedOut(day),
            isInMonth: isSameMonth(day, s),
            isInventoryItem: this.isInventoryDay(day) && isSameMonth(day, s),
            isNoInventory: false,
            isSoldout: this.isSoldout(day),
            isoDate: day.toISOString(),
          });
        });
        weeks.push(days);
      }
    }

    // todo add remain weeks
    const remainingWeeks = 6 - weeks.length;
    for (let i = 0; i < remainingWeeks; i++) {
      const days = [] as Array<DisplayDate>;
      for (let j = 0; j < 7; j++) {
        days.push({
          value: 0,
          dayOfWeek: 0,
          isBlackout: false,
          isInMonth: false,
          isInventoryItem: false,
          isNoInventory: false,
          isSoldout: false,
          isoDate: '',
        });
      }
      weeks.push(days);
    }
    return weeks;
  }

  get BlackoutMap() {
    return availabilityStatusModule.Blackout;
  }

  private isSoldout(day: Date): boolean {
    const items: any[] | undefined = availabilityStatusModule.AvailabilityItems.get(
      format(day, 'yyyy-MM-dd'),
    );
    if (items === undefined || (items && items.length === 0)) return false;
    return items.find((itm: any) => itm.vacancies > 0) === undefined;
  }

  private isBlackedOut(day: Date): boolean {
    const items: any[] | undefined = availabilityStatusModule.AvailabilityItems.get(
      format(day, 'yyyy-MM-dd'),
    );
    if (items === undefined || (items && items.length === 0)) return false;
    return items.find((itm: any) => itm.isBlackedOut === false) === undefined;
  }
}
