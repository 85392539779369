
































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { channelModule } from '@/store/modules/moduleChannel';
import { pricingModule } from '@/store/modules/modulePricing';
import { httpModule } from '@/store/modules/moduleHttp';
import { constants } from '@/utils/constants';

@Component({
  components: {
    RdmCollapsableControlCard,
  },
})
export default class RateCardCreateValidation extends Vue {
  private isValid = true;
  private validatingOffers = 'Validating Offers...';
  private validationComplete = 'Validation Complete';
  private expand = [0];

  destroyed() {
    channelModule.setValidationState(false);
    channelModule.setValidationError(false);
    channelModule.setValidationProgress(0);
    pricingModule.setValidatedOffers([]);
  }

  discard() {
    this.$router.push({ name: constants.routes.CHANNEL_RATE_CARDS });
  }

  get Loading() {
    return pricingModule.Loading;
  }

  get ValidationState() {
    return channelModule.ValidationState;
  }

  get ValidationString() {
    return channelModule.ValidationProgress < 100
      ? this.validatingOffers
      : this.validationComplete;
  }

  get ValidationProgress() {
    return channelModule.ValidationProgress;
  }

  get ValidationError() {
    return channelModule.ValidationError;
  }

  get ValidationErrorDetails() {
    return channelModule.ValidationErrorDetails;
  }

  get OffersForValidation() {
    if (channelModule.CreateRateCardDetailsForm.offers !== undefined)
      return channelModule.CreateRateCardDetailsForm.offers;
    return [];
  }

  get Offers() {
    return pricingModule.Offers;
  }

  get OffersFromValidation() {
    return pricingModule.ValidatedOffers;
  }

  get ValidatedOffers() {
    return pricingModule.ValidatedOffers.filter((item: any) => !item.error);
  }

  get OffersToPublish() {
    return this.ValidatedOffers.map((item: any) => item.id);
  }

  get HasOffersToPublish() {
    return !!this.OffersToPublish.length;
  }

  private toggleValidationState() {
    channelModule.setValidationState(!this.ValidationState);
  }

  private async goToNextStep() {
    channelModule.setValidationError(false);
    await httpModule.offerValidation({
      dryRun: 'false',
      offers: this.OffersToPublish,
    });
    await httpModule.getCompanyRates();
    if (!this.ValidationError) {
      channelModule.setValidationState(false);
      channelModule.setFormDetailsState(true);
    }
  }
}
