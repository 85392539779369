



































































import { Component, Vue } from 'vue-property-decorator';
import AddTravelerType from '@/components/product/list/AddTravelerType.vue';
import RdmCompanyTravelerType from '@/components/RdmCompanyGuestType.vue';
import RdmCompanyAgeBand from '@/components/RdmCompanyAgeBand.vue';
import RdmMessage from '@/components/common/alert/RdmMessage.vue';
import { Unit } from '@/api/inventory/InventoryModels';
import EditTravelerType from '@/components/product/list/EditTravelerType.vue';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { httpModule } from '@/store/modules/moduleHttp';
import { productModule } from '@/store/modules/moduleProduct';
import { tablePaginationOptions } from '@/utils/helpers';
import { constants } from '@/utils/constants';

@Component({
  components: {
    RdmCompanyTravelerType,
    RdmCompanyAgeBand,
    RdmMessage,
    AddTravelerType,
    EditTravelerType,
  },
})
export default class CompanyTravelerType extends Vue {
  private routeCreateTravelerType = constants.routes.TRAVELER_CREATE;
  private removeTravelerTypeModal = false;
  private travelerTypeToRemove = {} as any;
  private modalIsOpen = false;
  private productHeaders: Array<Record<string, any>> = [
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'internalName',
    },
    {
      text: 'Type',
      align: 'left',
      sortable: true,
      value: 'type',
      width: 175,
    },
    { text: '', value: 'actions', align: 'right', width: 300, sortable: false },
  ];

  mounted() {
    httpModule.getTravelerTypes().then().catch();
  }

  editTravelerType(travelerType: Unit) {
    travelerTypeModule.setSelectedTravelerType(travelerType);
    this.$router
      .push({ name: constants.routes.TRAVELER_EDIT, params: { id: travelerType.id } })
      .catch();
  }

  private openModal(travelerType: any) {
    this.travelerTypeToRemove = travelerType;
    this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  proceedRemove() {
    this.closeModal();
    httpModule
      .removeTravelerType(this.travelerTypeToRemove)
      .then(() => {})
      .finally(() => {
        //TODO
      });
  }

  get Loading(): boolean {
    return travelerTypeModule.Loading;
  }

  get TravelerTypes(): Array<Unit> {
    return travelerTypeModule.TravelerTypes;
  }

  get SelectedTravelerType() {
    return travelerTypeModule.SelectedTravelerType;
  }

  get ProductSearch() {
    return productModule.SearchText;
  }

  get paginationOptions() {
    return tablePaginationOptions(this.TravelerTypes);
  }
}
