import { render, staticRenderFns } from "./DeliveryMethodsViewEdit.vue?vue&type=template&id=29e730ba&scoped=true&"
import script from "./DeliveryMethodsViewEdit.vue?vue&type=script&lang=ts&"
export * from "./DeliveryMethodsViewEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e730ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFadeTransition,VSelect,VSpacer})
