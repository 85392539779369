





















































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import { companyModule } from '@/store/modules/moduleCompany';
import { productModule } from '@/store/modules/moduleProduct';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import ProductCreateDetail from '@/components/product/create/ProductCreateDetail.vue';
import SaveState from '@/components/common/savestate/SaveState.vue';
import ProductCreateLocation from '@/components/product/create/ProductCreateLocation.vue';
import ProductCreatePolicy from '@/components/product/create/ProductCreatePolicy.vue';
import ProductCreateSchedule from '@/components/product/create/ProductCreateSchedule.vue';

@Component({
  components: {
    ProductCreateSchedule,
    ProductCreatePolicy,
    ProductCreateLocation,
    SaveState,
    ProductCreateDetail,
    RdmCollapsableControlCard,
    RdmCollapsableCard,
    RdmReturnToBtn,
  },
})
export default class ProductCreate extends Vue {
  private routeProducts = constants.routes.PRODUCT_LIST;

  mounted() {
    productModule.clearCreateProductDetailsForm();
    productModule.clearCreateProductLocationForm();
    productModule.clearCreateProductPolicyForm();
    productModule.clearCreateProductScheduleForm();
    productModule.setCreateProductDetailsOpen(true);
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Company() {
    return companyModule.Company;
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get ProductSavingProgress() {
    return productModule.CreateProductSaveProgress;
  }

  get ShowSavingProgress() {
    return productModule.CreateProductSaveProgressShow;
  }
}
