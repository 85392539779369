





























































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { constants } from '@/utils/constants';
import {
  emailRules,
  requiredFieldRules,
  requiredStateRules,
} from '@/utils/validation-rules';
import { companyModule } from '@/store/modules/moduleCompany';
import _ from 'lodash';
import faker from 'faker';
import { httpModule } from '@/store/modules/moduleHttp';
import { Address, Contact, ContactEmail, ContactPhone } from '@/models';
import { handleKeyDownValidPhoneCharacter, filterCodeAndName } from '@/utils/helpers';
import { appModule } from '@/store/modules/moduleApp';

@Component({
  components: {
    RdmReturnToBtn,
  },
})
export default class CompanyEdit extends Vue {
  private routeCompanyContacts = constants.routes.CONTACTS;
  private formValid = false;
  private contact = {} as Contact;
  private location = {} as Address;
  private email = {} as ContactEmail;
  private phone = {} as ContactPhone;
  private handleValidPhoneCharacters = handleKeyDownValidPhoneCharacter;
  private filterCodeAndName = filterCodeAndName;
  private requiredField = requiredFieldRules;
  private emailFieldRules = emailRules;
  private requiredState = requiredStateRules;
  private role = '';

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Loading() {
    return companyModule.Loading;
  }

  get Company() {
    return companyModule.Company;
  }

  get Contacts() {
    return companyModule.Contacts;
  }

  get Roles() {
    return appModule.Roles;
  }

  get Countries() {
    return appModule.CountryData;
  }

  mounted() {
    if (this.Contacts) {
      const contactFiltered = this.Contacts.filter(
        (contact) => contact.id === this.$route.params.id,
      );
      if (contactFiltered && contactFiltered.length > 0) {
        this.contact = _.cloneDeep(contactFiltered[0]);
      } else {
        this.contact.id = faker.datatype.uuid();
      }
    }

    if (
      this.$route.name === constants.routes.CONTACTS_EDIT &&
      this.Company &&
      this.Contacts &&
      this.Contacts.length > 0
    ) {
      if (this.contact && this.contact.addresses && this.contact.addresses.length > 0) {
        this.location = _.cloneDeep(this.contact.addresses[0]);
      }
      if (this.contact && this.contact.phones && this.contact.phones.length > 0) {
        this.phone = _.cloneDeep(this.contact.phones[0]);
      }
      if (this.contact && this.contact.emails && this.contact.emails.length > 0) {
        this.email = _.cloneDeep(this.contact.emails[0]);
      }

      if (this.contact.role) {
        this.role = this.contact.role;
      }
    }
  }

  validate() {
    if (this.$refs?.addContactsForm) {
      const form: any = this.$refs.addContactsForm;
      const isValid = form.validate();
      if (isValid) {
        this.configContact();
      }
    }
  }

  async configContact() {
    this.contact.phones = [this.phone];
    this.contact.emails = [this.email];
    this.contact.addresses = [this.location];
    if (this.role && this.role.length > 0) {
      this.contact.role = this.role;
    }
    httpModule
      .updateContact(this.contact)
      .then(() => this.$router.push({ name: constants.routes.CONTACTS }).catch());
  }

  discard() {
    this.$router.push({ name: constants.routes.CONTACTS }).catch();
  }
}
