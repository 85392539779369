




















import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Function,
      default: () => {
        return;
      },
    },
  },
})
export default class RdmPublishButton extends Vue {
  private publish = 'publish';
  private publishing = 'publishing...';
  private published = 'published';
}
