

























































import { Component, Vue } from 'vue-property-decorator';
import { appModule } from '@/store/modules/moduleApp';
import { MessageType, RdmMessage } from '@/models';

@Component
export default class RdmGeneralAlert extends Vue {
  private messageErrorType = MessageType.ERROR;

  removeMessage(message: RdmMessage) {
    message.active = false;
    appModule.removeMessage(message.id);
    // todo remove from actionQueue
    appModule.removeAction(message.action?.actionId || message.id);
  }

  handleTimeout(message: RdmMessage) {
    this.removeMessage(message);
  }

  handleCreate(e: any) {
    if (e) {
      // todo implement handle create
    }
  }

  async view(message: RdmMessage) {
    const id = message.action?.actionId || message.id;
    const action = await appModule.getAction(id);
    if (action) {
      try {
        await appModule.codeAction(action);
      } catch {
        // todo: send error to backend
      } finally {
        appModule.removeAction(action.id);
      }
    }
    this.removeMessage(message);
  }

  async undo(message: RdmMessage) {
    const id = message.action?.actionId || message.id;
    const action = await appModule.getAction(id);
    if (action) {
      try {
        await appModule.codeAction(action);
      } catch {
        // todo: send error to backend
      } finally {
        appModule.removeAction(action.id);
      }
    }
    this.removeMessage(message);
  }

  async retry(message: RdmMessage) {
    const id = message.action?.actionId || message.id;
    const action = await appModule.getAction(id);
    this.removeMessage(message);
    if (action) {
      try {
        await appModule.codeAction(action);
      } catch {
        // todo: send error to backend
      } finally {
        appModule.removeAction(action.id);
      }
    }
  }

  get Messages() {
    return appModule.Messages.messages;
  }
}
