export * from './RegistrationModel';
export * from './TravelerType';
export * from './Operator';
export * from './ApiV2Models';
export * from './UIModels';
export * from './ModelsOppInventory';

export class DateRangeModel {
  start: string;
  end: string;

  constructor(start: string, end: string) {
    this.start = start;
    this.end = end;
  }
}
