var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.Loading,"headers":_vm.inventoryHeaders,"items":_vm.Offers,"search":_vm.Search,"items-per-page":10,"sort-by":['name', 'inventoryName'],"footer-props":{
      itemsPerPageOptions: _vm.paginationOptions,
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
    }},scopedSlots:_vm._u([{key:"item.capacity",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.generateInventoryNameString(item.capacity)))+" ")])]}},{key:"item.view",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: _vm.viewInventoryRoute, params: { id: item.id } },"color":"primary","plain":"","disabled":!item.id}},[_vm._v("View")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"color":"error","plain":"","disabled":!item.id},on:{"click":function($event){return _vm.deleteInventory(item)}}},[_vm._v("Remove")])]}}])}),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.modalIsOpen),callback:function ($$v) {_vm.modalIsOpen=$$v},expression:"modalIsOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("Remove Offer Inventory")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Are you sure you want to remove "+_vm._s(_vm._f("capitalize")(_vm.selectedCapacity.name))+" Inventory? ")]),_c('p',[_vm._v(" By removing this Offer Inventory you are potentially changing data associated to a channel. Please validate and republish your data. ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.Loading,"depressed":""},on:{"click":_vm.proceedDelete}},[_vm._v("Remove")]),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }