



















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { format, parse, differenceInCalendarDays, addDays } from 'date-fns';
import { DateRangeModel } from '@/models';

const isoFormat = 'yyyy-MM-dd';
const defaultDate = format(new Date(), isoFormat);

@Component
export default class RdmDateRangePicker extends Vue {
  @Watch('menu')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMenuChange(value: string, oldValue: string) {
    if (!value) {
      this.closeMenu();
    } else {
      this.highlight();
    }
  }

  @Watch('pickerStart')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPickerStart(value: string, oldValue: string) {}

  @Prop({
    type: DateRangeModel,
    default: () => {
      return new DateRangeModel(defaultDate, defaultDate);
    },
  })
  public value!: DateRangeModel;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public presets!: Array<any>;

  @Prop({ type: Boolean, default: false })
  public noPresets!: boolean;

  // TODO add localization
  @Prop({ type: String, default: 'Start Date' })
  public startLabel!: string;

  // TODO add localization
  @Prop({ type: String, default: 'Start Date' })
  public endLabel!: string;

  // TODO add localization
  @Prop({ type: String, default: 'To' })
  public separatorLabel!: string;

  // TODO add localization
  @Prop({ type: String, default: 'Presets' })
  public presetLabel!: string;

  @Prop({ type: String, default: '' })
  public min!: string;

  @Prop({ type: String, default: '' })
  public max!: string;

  @Prop({ type: String, default: 'en-us' })
  public locale!: string;

  @Prop({ type: [Date, Number], default: 0 })
  public firstDayOfWeek!: [Date, number];

  @Prop({ type: Boolean, default: false })
  public noTitle!: boolean;

  @Prop({ type: String })
  public displayFormat!: string;

  @Prop({ type: String, default: 'primary lighten-5' })
  public highlightColor!: string;

  @Prop({ type: Boolean, default: true })
  public showReset!: boolean;

  @Prop({ type: String, default: 'mdi-chevron-right' })
  public nextIcon!: string;

  @Prop({ type: String, default: 'mdi-chevron-left' })
  public prevIcon!: string;

  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public inputProps!: Record<string, any>;

  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public menuProps!: Record<string, any>;

  public menu = false;

  public pickerStart = this.value.start;

  public pickerEnd = this.value.end;

  public highlightDates: string[] = [];

  public highlightClasses = {};

  applyRange() {
    this.menu = false;
    this.emitRange();
  }

  closeMenu() {
    this.pickerStart = this.value.start;
    this.pickerEnd = this.value.end;
    this.highlight();
    this.$emit('menu-closed');
  }

  formatDate(date: Date, fmt: string) {
    return format(date, fmt);
  }

  get bothSelected() {
    return this.pickerStart && this.pickerEnd;
  }

  get inputValue() {
    if (this.isValueEmpty) {
      return '';
    }
    const start = this.displayFormat
      ? this.formatDate(new Date(this.value.start), this.displayFormat)
      : this.value.start;
    const end = this.displayFormat
      ? this.formatDate(new Date(this.value.end), this.displayFormat)
      : this.value.end;
    return `${start}    ${this.separatorLabel}     ${end}`;
  }

  get placeholder() {
    return `${this.startLabel}    ${this.separatorLabel}    ${this.endLabel}`;
  }

  get isValueEmpty() {
    return !this.value.start;
  }

  get isPresetActive() {
    return this.presets.map(
      (preset) =>
        preset.range[0] === this.pickerStart && preset.range[1] === this.pickerEnd,
    );
  }

  highlight() {
    if (!this.bothSelected) {
      return;
    }
    const dates: any = [];
    const classes: any = {};
    const start: Date = parse(this.pickerStart.toString(), isoFormat, new Date());
    const end: Date = parse(this.pickerEnd.toString(), isoFormat, new Date());
    const diff = Math.abs(differenceInCalendarDays(start, end));

    // Loop though all the days in range.
    for (let i = 0; i <= diff; i++) {
      const date = format(addDays(start, i), isoFormat);
      dates.push(date);
      const classesArr = [];
      classesArr.push(`v-date-range__in-range`);
      classesArr.push(this.highlightColor);
      i === 0 && classesArr.push(`v-date-range__range-start`);
      i === diff && classesArr.push(`v-date-range__range-end`);
      classes[date] = classesArr.join(' ');
    }
    this.highlightDates = dates;
    this.highlightClasses = classes;
  }

  selectPreset(presetIndex: any) {
    this.pickerStart = this.presets[presetIndex].range[0];
    this.pickerEnd = this.presets[presetIndex].range[1];
  }

  reset() {
    // Reset Picker Values
    this.pickerStart = '';
    this.pickerEnd = '';
    this.highlightDates = [];
    this.highlightClasses = {};
    this.emitRange();
  }

  emitRange() {
    this.$emit('input', {
      start: this.pickerStart,
      end: this.pickerEnd,
    });
  }
}
