










































import { Component, Vue } from 'vue-property-decorator';
import { RedemptionMethods } from '@/models/ApiV2Models';
import { productModule } from '@/store/modules/moduleProduct';
import { constants } from '@/utils/constants';
import _ from 'lodash';

import { httpModule } from '@/store/modules/moduleHttp';

@Component({
  components: {},
  props: {
    option: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class RedemptionMethodViewEdit extends Vue {
  private redemptionMethodModel = '' as RedemptionMethods;
  private baseRedemptionMethodModel = '' as RedemptionMethods;
  private saving = false;
  private redemptionMethods = [
    { text: 'Manifest', value: 'MANIFEST' },
    { text: 'Digital', value: 'DIGITAL' },
    { text: 'Print', value: 'PRINT' },
  ];

  mounted() {
    this.$root.$on(constants.events.PRODUCT_LOAD_COMPLETE, () => {
      this.redemptionMethodModel = _.cloneDeep(this.RedemptionMethod);
      this.baseRedemptionMethodModel = _.cloneDeep(this.RedemptionMethod);
    });
  }

  saveChanges() {
    this.saving = true;
    productModule.setRedemptionMethod(this.redemptionMethodModel);
    const product = _.cloneDeep(productModule.Product);
    const ticketDelivery = product.ticketDelivery;

    if (ticketDelivery && ticketDelivery.redemptionMethod) {
      ticketDelivery.redemptionMethod = this.redemptionMethodModel;
      product.ticketDelivery = ticketDelivery;
      productModule.setProduct(product);
    }
    httpModule
      .updateProduct()
      .then(() => {
        productModule.setEditModeRedemptionMethod(false);
      })
      .catch(() => {
        // todo send alert
      })
      .finally(() => {
        this.saving = false;
        this.redemptionMethodModel = _.cloneDeep(this.RedemptionMethod || '');
        this.baseRedemptionMethodModel = _.cloneDeep(this.RedemptionMethod || '');
      });
  }

  cancel() {
    this.redemptionMethodModel = _.cloneDeep(this.baseRedemptionMethodModel);
    this.toggleRedemptionMethodEdit();
  }

  toggleRedemptionMethodEdit() {
    this.redemptionMethodModel = _.cloneDeep(this.RedemptionMethod || []);

    if (!this.EditModeRedemptionMethod) {
      this.baseRedemptionMethodModel = _.cloneDeep(this.RedemptionMethod || []);
    }
    productModule.setEditModeRedemptionMethod(!this.EditModeRedemptionMethod);
  }

  get EditModeRedemptionMethod() {
    return productModule.EditModeRedemptionMethod;
  }

  get RedemptionMethod() {
    return productModule.RedemptionMethod;
  }

  get Product() {
    return productModule.Product;
  }
}
