











































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
// import { channelModule } from '@/store/modules/moduleChannel';
import { pricingModule } from '@/store/modules/modulePricing';
import { httpModule } from '@/store/modules/moduleHttp';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { Offer, Product, Option } from '@/models/ModelsOppInventory';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import {
  centsToDollars,
  dollarsToCents,
  generateProductPrice,
  generateTaxPrice,
  generateOptionAmountInDollars,
} from '@/utils/helpers';
import { channelModule } from '@/store/modules/moduleChannel';
import { appModule } from '@/store/modules/moduleApp';
import { codes } from '@/utils/codeConstants';
import Home from '@/views/main/Home.vue';
import { constants } from '@/utils/constants';

@Component({
  components: {
    Home,
    RdmCollapsableControlCard,
  },
  props: {
    offerId: {
      type: String,
      default: '',
    },
  },
})
export default class RateCardOfferView extends Vue {
  private centsToDollars = centsToDollars;
  private dollarsToCents = dollarsToCents;
  private generateProductPrice = generateProductPrice;
  private generateTaxPrice = generateTaxPrice;
  private generateOptionAmountInDollars = generateOptionAmountInDollars;
  private dayTimeExpand = [];

  get Offer() {
    return (
      pricingModule.Offers.find((offer: Offer) => offer.id === this.$props.offerId) ||
      ({} as Offer)
    );
  }

  get PriceAdjustments() {
    return channelModule.RateCard.adjustment || null;
  }

  get RateCardSpr() {
    return channelModule.RateCardSpr?.find(
      (spr: any) => spr.rc.offer.id === this.$props.offerId,
    );
  }

  get TravelerTypes() {
    const inUse = [] as any;
    if (pricingModule.OfferPricing.id) {
      travelerTypeModule.TravelerTypes.map((unit: any) => {
        pricingModule.OfferPricing.unitPrices.map((traveler) => {
          if (unit.id === traveler.unitId) inUse.push(unit);
        });
      });
    }
    return inUse;
  }

  get Schedule() {
    return scheduleModule.Schedule;
  }

  get OfferPricing() {
    return { ...pricingModule.OfferPricing };
  }

  get OfferPrices() {
    return pricingModule.OfferPricings.data ? [...pricingModule.OfferPricings.data] : [];
  }

  get Product() {
    return (
      productModule.Products.find(
        (product: Product) => product.id === this.Offer.productId,
      ) || ({} as Product)
    );
  }

  get Option() {
    return (
      optionModule.Options.find((option: Option) => option.id === this.Offer.optionId) ||
      ({} as Option)
    );
  }

  async copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
    appModule.addMessageSuccess(codes.GENERAL_MESSAGE_COPIED_CLIPBOARD);
  }

  getTravelerTypeName(id: string): string {
    const traveler = travelerTypeModule.TravelerTypes.find((t) => t.id === id);
    if (traveler && traveler.internalName) {
      return traveler.internalName;
    }
    return '';
  }

  getOfferRoute() {
    return { name: constants.routes.OFFER_VIEW, params: { id: this.$props.offerId } };
  }

  togglePrice(p: any) {
    httpModule
      .getOfferPricing(p.id)
      .then(() => {
        const updatedPriceList = {
          filter: this.$props.offerId,
          data: this.OfferPrices.map((item: any) => {
            return {
              ...item,
              open: item.id === p.id ? !p.open : false,
            };
          }),
        };
        pricingModule.setOfferPricings(updatedPriceList);
        httpModule.getSchedule(pricingModule.OfferPricing.scheduleId).then().catch();
      })
      .catch();
  }

  async toggleAccordion() {
    pricingModule.setOffers(
      pricingModule.Offers.map((offer: Offer) => {
        return {
          ...offer,
          open: false,
        };
      }),
    );
    await httpModule.getOfferPricingList(this.Offer.id);
    if (this.Offer.productId) await httpModule.getProduct(this.Offer.productId);
    if (this.Offer.optionId) await httpModule.getOption(this.Offer.optionId);
    const updatedOffers = pricingModule.Offers.map((offer: Offer) => {
      return {
        ...offer,
        open: offer.id === this.Offer.id ? !offer.open : false,
      };
    });
    pricingModule.setOffers(updatedOffers);
  }
}
