











































import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import _ from 'lodash';
import { channelModule } from '@/store/modules/moduleChannel';
import { httpModule } from '@/store/modules/moduleHttp';
import { incrementMeta } from '@/utils/helpers';
import { RateCard } from '@/models';

@Component
export default class RateCardNameView extends Vue {
  private editNameModal = false;
  private saving = false;
  private rateCardName = '';
  private requiredField = requiredFieldRules;

  mounted() {}

  openEditNameModal() {
    if (this.RateCard && _.isString(this.RateCard.name)) {
      this.rateCardName = _.cloneDeep(this.RateCard.name);
    }
    this.editNameModal = true;
  }

  closeEditNameModal() {
    this.editNameModal = false;
  }

  async saveChanges() {
    const updatedCard = {
      ...this.RateCard,
      name: this.rateCardName,
      meta: incrementMeta(this.RateCard.meta),
      version: this.RateCard.meta?.version ? this.RateCard.meta?.version + 1 : 1,
    };
    await httpModule.updateRateCard(updatedCard);
  }

  get Loading() {
    return channelModule.Loading;
  }

  get RateCard() {
    if (channelModule.RateCard) {
      return channelModule.RateCard;
    }
    return {} as RateCard;
  }
}
