





















































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { optionModule } from '@/store/modules/moduleOption';
import {
  CapacityPool,
  DisplayUnitPrice,
  OptionCapacityPool,
  OptionUnitPrice,
  OptionUnitPriceAmount,
  PoolStructure,
} from '@/models';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import _ from 'lodash';
import {
  AvailabilityStatus,
  AvailabilityType,
  CapacityType,
  Unit,
} from '@/api/inventory/InventoryModels';
import { httpModule } from '@/store/modules/moduleHttp';
import {
  convertCurrencyToMinorUnit,
  convertMinorUnitToCurrency,
  handleKeyDownOnlyNumeric,
  isEmpty,
} from '@/utils/helpers';
import { requiredFieldRules } from '@/utils/validation-rules';
import { offerModule } from '@/store/modules/moduleOffer';

@Component
export default class OptionViewCapacity extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private selectedAvailabilityType = CapacityType.CapacityLimitUnlimited;
  private baseSelectedAvailabilityType = CapacityType.CapacityLimitUnlimited;
  private handleKeyDown = handleKeyDownOnlyNumeric;
  private convertMinorUnitToCurrency = convertMinorUnitToCurrency;
  private convertCurrencyToMinorUnit = convertCurrencyToMinorUnit;
  private isEmpty = isEmpty;
  private selectedTravelerTypes = [] as Array<string>;
  private capacityPoolValue = null as null | number;
  private availType = CapacityType;
  private byUnitPoolArr = [] as Array<PoolStructure>;
  private inventoryType = '' as AvailabilityType;
  private baseInventoryType = '' as AvailabilityType;
  private travelerFormValid = false;
  private priceOverrideValid = false;
  private capacityTypeFormValid = false;
  private saving = false;
  private currency = 'USD';
  private displayUnits = [] as Array<DisplayUnitPrice>;

  mounted() {
    this.selectedAvailabilityType = this.chooseCapacityType();
    this.baseSelectedAvailabilityType = this.chooseCapacityType();
    this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
    this.populateDisplayUnits();
  }

  populateDisplayUnits() {
    this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
    this.displayUnits = [] as Array<DisplayUnitPrice>;
    this.selectedTravelerTypes.forEach((t) => {
      const displayUnit = {
        unitId: t,
      } as DisplayUnitPrice;
      if (
        this.Option &&
        this.Option.unitPrices &&
        Array.isArray(this.Option.unitPrices)
      ) {
        const unit = this.Option.unitPrices.find((u) => u.unitId === t);
        if (unit) {
          if (unit.amount && unit.amount.currency) {
            this.currency = unit.amount.currency;
          }
          displayUnit.net = this.convertMinorUnitToCurrency(unit.amount.net) || '';
          displayUnit.original =
            this.convertMinorUnitToCurrency(unit.amount.original) || '';
          displayUnit.retail = this.convertMinorUnitToCurrency(unit.amount.retail) || '';
        }
      } else {
        this.currency = 'USD';
      }
      this.displayUnits.push(displayUnit);
    });
    // this.Option.unitPrices.forEach((u) => {
    //   if (u.amount && u.amount.currency) {
    //     this.currency = u.amount.currency;
    //   }
    //   this.displayUnits.push({
    //     unitId: u.unitId,
    //     net: this.convertMinorUnitToCurrency(u.amount.net) || '',
    //     original: this.convertMinorUnitToCurrency(u.amount.original) || '',
    //     retail: this.convertMinorUnitToCurrency(u.amount.retail) || '',
    //   });
    // });
  }

  chooseCapacityType() {
    this.capacityPoolValue =
      this.CapacityPool &&
      _.isNumber(this.CapacityPool.pooled) &&
      this.CapacityPool.pooled > 0
        ? _.cloneDeep(this.CapacityPool.pooled)
        : null;

    // this.capacityPool.pooled =
    //   this.CapacityPool && this.CapacityPool.pooled
    //     ? _.cloneDeep(this.CapacityPool.pooled)
    //     : 1;

    this.byUnitPoolArr = [];
    const c = _.cloneDeep(this.CapacityPoolByUnit) as any;
    this.OptionTravelerTypes.forEach((tt) => {
      const v = c[tt.id] || '';
      this.byUnitPoolArr.push({ unitId: tt.id, value: v });
    });

    if (
      this.CapacityPool &&
      _.isNumber(this.CapacityPool.pooled) &&
      this.CapacityPool.pooled > 0
    ) {
      return CapacityType.CapacityLimitPooled;
    } else if (
      this.CapacityPool &&
      _.isObjectLike(this.CapacityPool.byUnit) &&
      _.keys(this.CapacityPool.byUnit).length > 0
    ) {
      return CapacityType.CapacityLimitByUnit;
    }
    return CapacityType.CapacityLimitUnlimited;
  }

  isValidUnitPrice(unit: DisplayUnitPrice) {
    return (
      !!(unit.net && _.isNumber(+unit.net) && _.toNumber(unit.net) >= 0) ||
      !!(unit.original && _.isNumber(+unit.original) && _.toNumber(unit.original) >= 0) ||
      !!(unit.retail && _.isNumber(+unit.retail) && _.toNumber(unit.retail) >= 0)
    );
  }

  populateCapacityUnit() {
    // if (
    //   this.selectedAvailabilityType === this.availType.Limited &&
    //   _.isEmpty(this.CapacityPoolByUnit)
    // ) {
    //   this.byUnitPoolArr = [] as Array<PoolStructure>;
    //   if (this.OptionTravelerTypes && Array.isArray(this.OptionTravelerTypes)) {
    //     this.OptionTravelerTypes.forEach((traveler: Unit) => {
    //       this.byUnitPoolArr.push({ unitId: traveler.id, value: '' });
    //     });
    //   }
    // }

    if (
      this.selectedAvailabilityType === this.availType.CapacityLimitByUnit &&
      _.isEmpty(this.CapacityPoolByUnit)
    ) {
      this.byUnitPoolArr = [] as Array<PoolStructure>;
      if (this.OptionTravelerTypes && Array.isArray(this.OptionTravelerTypes)) {
        this.OptionTravelerTypes.forEach((traveler: Unit) => {
          this.byUnitPoolArr.push({ unitId: traveler.id, value: '' });
        });
      }
    }
  }

  removeSelectedTraveler(unitId: string) {
    const i = this.displayUnits.findIndex((u) => u.unitId === unitId);
    if (i > -1) {
      this.displayUnits.splice(i, 1);
    }
  }

  addUnitPrices() {
    const unitPrices = [] as Array<OptionUnitPrice>;
    this.selectedTravelerTypes.forEach((t: string) => {
      const du = this.displayUnits.find((du: DisplayUnitPrice) => du.unitId === t);
      if (du) {
        const optionUnitPriceAmount = {
          currency: this.currency,
        } as OptionUnitPriceAmount;
        const net = _.toNumber(du.net);
        const retail = _.toNumber(du.retail);
        const original = _.toNumber(du.original);
        if (net > 0) {
          optionUnitPriceAmount.net = this.convertCurrencyToMinorUnit(net);
        }

        if (retail > 0) {
          optionUnitPriceAmount.retail = this.convertCurrencyToMinorUnit(retail);
        }

        if (original > 0) {
          optionUnitPriceAmount.original = this.convertCurrencyToMinorUnit(original);
        }

        unitPrices.push({
          unitId: du.unitId,
          amount: optionUnitPriceAmount,
        } as OptionUnitPrice);
      }
    });
    const option = _.cloneDeep(optionModule.Option);
    option.unitPrices = unitPrices;

    optionModule.setOption(option);
    try {
      httpModule
        .createOption(option)
        .then(() => {
          this.togglePriceOverrideEditMode();
        })
        .catch(() => {
          // todo send alert
        })
        .finally(() => {});
    } catch (e) {
      console.log(e);
      // todo send alert to backend
    }
  }

  unitIdToTravelerName(id: string) {
    const i = this.TravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.TravelerTypes[i].text;
    }
    return '';
  }

  cancel() {
    this.inventoryType = this.baseInventoryType;
    this.toggleCapacityEditMode();
  }

  saveChanges() {
    try {
      const byUnit = {} as Map<string, any>;
      this.byUnitPoolArr.forEach((p) => {
        const val = _.toNumber(p.value);
        if (p.value && _.isNumber(val) && val > 0) {
          _.set(byUnit, p.unitId, val);
        }
      });
      const pool = {
        orgId: this.Option.orgId,
        optionId: this.Option.id,
        capacityPool: {
          unlimited:
            this.selectedAvailabilityType === CapacityType.CapacityLimitUnlimited,
          pooled:
            this.selectedAvailabilityType === CapacityType.CapacityLimitPooled
              ? _.toNumber(this.capacityPoolValue)
              : 0,
          byUnit:
            this.selectedAvailabilityType === CapacityType.CapacityLimitByUnit
              ? byUnit
              : {},
        },
      } as OptionCapacityPool;

      optionModule.setOptionCapacityPool(pool);
      this.saving = true;
      const option = _.cloneDeep(optionModule.Option);
      if (this.selectedAvailabilityType === CapacityType.CapacityLimitUnlimited) {
        option.availabilityType = AvailabilityType.OpeningHours;
      } else {
        option.availabilityType = AvailabilityType.StartTime;
      }
      optionModule.setOption(option);
      httpModule
        .updateOption(option)
        .then(() => {
          httpModule.updateOptionCapacity().then();
        })
        .catch(() => {
          // todo send alert
        })
        .finally(() => {
          this.saving = false;
          this.selectedAvailabilityType = this.chooseCapacityType();
          this.baseSelectedAvailabilityType = this.chooseCapacityType();
          this.toggleCapacityEditMode();
        });
    } catch (e) {
      // todo send error to backend
      console.log('save error', e);
    }
  }

  toggleCapacityEditMode() {
    if (this.OptionOffers && this.OptionOffers.length > 0) {
      return;
    }

    this.selectedAvailabilityType = this.chooseCapacityType();

    if (!this.EditMode) {
      this.baseSelectedAvailabilityType = this.chooseCapacityType();
    }
    optionModule.setEditModeCapacityType(!this.EditMode);
  }

  toggleTravelerTypeEditMode() {
    if (this.OptionOffers && this.OptionOffers.length > 0) {
      return;
    }

    optionModule.setEditModeTravelerType(!this.EditModeTravelerType);

    if (this.$refs && this.$refs.travelerTypeForm) {
      const { travelerTypeForm } = this.$refs as any;
      travelerTypeForm.reset();
      this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
    }
  }

  cancelTravelerType() {
    this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
    this.toggleTravelerTypeEditMode();
  }

  togglePriceOverrideEditMode() {
    if (this.OptionOffers && this.OptionOffers.length > 0) {
      return;
    }

    optionModule.setEditModePriceOverride(!this.EditModePriceOverride);

    this.populateDisplayUnits();
    // if (this.$refs && this.$refs.priceOverrideForm) {
    //   const { priceOverrideForm } = this.$refs as any;
    //   priceOverrideForm.reset();
    //   this.populateDisplayUnits();
    //   // this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
    // }
  }

  cancelPriceOverride() {
    // this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
    this.togglePriceOverrideEditMode();
  }

  validatePriceOverride() {
    if (this.OptionOffers && this.OptionOffers.length > 0) {
      return;
    }

    if (this.$refs && this.$refs.priceOverrideForm) {
      const { priceOverrideForm } = this.$refs as any;
      const valid = priceOverrideForm.validate();
      if (valid) {
        this.savePriceOverride();
      }
    }
  }

  validatePriceForm() {
    if (this.$refs && this.$refs.priceOverrideForm) {
      const { priceOverrideForm } = this.$refs as any;
      priceOverrideForm.validate();
    }
  }

  savePriceOverride() {
    this.addUnitPrices();
  }

  validateCapacity() {
    if (this.OptionOffers && this.OptionOffers.length > 0) {
      return;
    }

    if (this.$refs && this.$refs.form) {
      const { form } = this.$refs as any;
      const valid = form.validate();
      if (valid) {
        this.saveChanges();
      }
    }
  }

  validateTraveler() {
    if (this.OptionOffers && this.OptionOffers.length > 0) {
      return;
    }

    if (this.$refs && this.$refs.travelerTypeForm) {
      const { travelerTypeForm } = this.$refs as any;
      const valid = travelerTypeForm.validate();
      if (valid) {
        this.saveTraveler();
      }
    }
  }

  saveTraveler() {
    httpModule.updateOptionUnitRelationship(this.selectedTravelerTypes).then();
    optionModule.setEditModeTravelerType(false);
  }

  get AvailabilityTypes() {
    return [
      AvailabilityStatus.Freesale,
      AvailabilityStatus.Available,
      AvailabilityStatus.Limited,
    ];
  }

  get CapacityTypes() {
    return [
      CapacityType.CapacityLimitUnlimited,
      CapacityType.CapacityLimitPooled,
      CapacityType.CapacityLimitByUnit,
    ];
  }

  get CapacityPool() {
    if (optionModule.OptionCapacityPool && optionModule.OptionCapacityPool.capacityPool) {
      return optionModule.OptionCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get CapacityPoolByUnit() {
    if (this.CapacityPool && this.CapacityPool.byUnit) {
      return this.CapacityPool.byUnit;
    }
    return {};
  }

  get TravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get Option() {
    return optionModule.Option;
  }

  get SelectedUnitIds() {
    return optionModule.SelectedUnitIds;
  }

  set SelectedUnitIds(units: Array<string>) {
    optionModule.setSelectedUnitIds(units);
  }

  get DisplayProductUnits() {
    return optionModule.TravelerTypes.map((u) => `${u.displayName} (${u.type})`);
  }

  get OptionTravelerTypes() {
    return optionModule.TravelerTypes;
  }

  get EditMode() {
    return optionModule.EditModeCapacityType;
  }

  get EditModeTravelerType() {
    return optionModule.EditModeTravelerType;
  }

  get EditModePriceOverride() {
    return optionModule.EditModePriceOverride;
  }

  get OptionOffers() {
    return offerModule.Offers.filter((item) => item.optionId === this.Option.id);
  }
}
