var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"to":{ name: 'channel-details-contract', params: { id: '432', contract: '0' } },"text":"","color":"primary"}},[_c('i',{staticClass:"mdi mdi-arrow-left"}),_vm._v(" Back to Channel ")])],1),_c('v-col',[_c('v-spacer')],1)],1),_c('v-row',{staticClass:"pb-3"},[_c('v-col',{attrs:{"cols":"9"}},[_c('h1',[_vm._v(_vm._s(_vm.rateCard.name))])])],1),_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6 pt-6 pb-0"}},[_c('h2',[_vm._v("Commission Rate:")])]),_c('v-col',{attrs:{"cols":"3 pb-0"}},[_c('v-text-field',{attrs:{"outlined":"","type":"number","placeholder":"00","rules":_vm.requiredFieldRules,"validate-on-blur":"","required":""},model:{value:(_vm.rateCard.commission),callback:function ($$v) {_vm.$set(_vm.rateCard, "commission", $$v)},expression:"rateCard.commission"}})],1),_c('v-col',{attrs:{"cols":"3 pt-6 pb-0"}},[_c('h2',[_vm._v("%")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"primary"},[_vm._v("Apply")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('h2',[_vm._v("Channels using this Rate Card")]),_vm._l((_vm.channels),function(c,index){return _c('div',{key:index},[_c('pre',[_vm._v(_vm._s(c))])])})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('v-spacer')],1)],1),_vm._l((_vm.products),function(product,index){return _c('div',{key:index},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(product.name))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.productHeaders,"hide-default-footer":"","hide-default-header":"","items":product.product_options,"items-per-page":10,"footer-props":{
            itemsPerPageOptions: _vm.paginationOptions(product.product_options),
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
          }},on:{"click:row":_vm.goToDetails},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm._f("capitalize")(item.name)))]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","text":"","to":{ name: 'channel-rate-card-pricing', params: { id: item.name } }}},[_vm._v(" View Details ")])]}}],null,true)})],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }