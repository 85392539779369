var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"offerDetailForm",model:{value:(_vm.Form1Valid),callback:function ($$v) {_vm.Form1Valid=$$v},expression:"Form1Valid"}},[(!_vm.Step2Done && _vm.OfferExists)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"error--text"},[_vm._v(" An offer already exists for this product/option combination. ")])])],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.Step2Done),expression:"!Step2Done"}]},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',{staticClass:"text-dark label-spacing-bottom-10"},[_vm._v("Products*")]),_c('v-select',{staticClass:"input",attrs:{"placeholder":"Select a product","outlined":"","hide-details":"","background-color":"white","items":_vm.Products,"item-text":"internalName","item-value":"id","rules":_vm.requiredField,"menu-props":{ bottom: true, offsetY: true }},on:{"change":_vm.onProductChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"select-multiline"},[_c('div',[_vm._v(_vm._s(_vm._f("titleize")(item.internalName)))]),_c('div',{staticClass:"caption sub-text"},[_vm._v(" "+_vm._s(_vm._f("titleize")(_vm.getPrimaryAddress(item.addressId)))+" ")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"select-multiline"},[_c('div',[_vm._v(_vm._s(_vm._f("titleize")(item.internalName)))]),_c('div',{staticClass:"caption sub-text"},[_vm._v(" "+_vm._s(_vm._f("titleize")(_vm.getPrimaryAddress(item.addressId)))+" ")])])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"select-multiline"},[_c('div',[_vm._v(_vm._s(_vm._f("titleize")(item.internalName)))]),_c('div',{staticClass:"caption sub-text"},[_vm._v(" "+_vm._s(_vm._f("titleize")(_vm.getPrimaryAddress(item.addressId)))+" ")])])]}}]),model:{value:(_vm.SelectedOfferProductId),callback:function ($$v) {_vm.SelectedOfferProductId=$$v},expression:"SelectedOfferProductId"}}),_c('div',{staticClass:"label-spacing-top-10"},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_vm._v("Create Product")])]}}]),model:{value:(_vm.createProductDialog),callback:function ($$v) {_vm.createProductDialog=$$v},expression:"createProductDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Unsaved changes ")]),_c('v-card-text',[_vm._v(" Are you sure you want to continue? Your progress will be lost ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){_vm.createProductDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.goToProductCreate}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',{staticClass:"text-dark label-spacing-bottom-10"},[_vm._v("Options")]),_c('v-select',{staticClass:"input",attrs:{"placeholder":"Select an option","outlined":"","hide-details":"","clearable":"","background-color":"white","items":_vm.Options,"item-text":"internalName","item-value":"id","menu-props":{ bottom: true, offsetY: true }},on:{"change":_vm.onOptionChange},model:{value:(_vm.SelectedOfferOptionId),callback:function ($$v) {_vm.SelectedOfferOptionId=$$v},expression:"SelectedOfferOptionId"}}),_c('div',{staticClass:"label-spacing-top-10"},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_vm._v("Create Option")])]}}]),model:{value:(_vm.createOptionDialog),callback:function ($$v) {_vm.createOptionDialog=$$v},expression:"createOptionDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Unsaved changes ")]),_c('v-card-text',[_vm._v(" Are you sure you want to continue? Your progress will be lost ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){_vm.createOptionDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.goToOptionCreate}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.Step2Done),expression:"Step2Done"}]},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"body-2 text-dark"},[_vm._v("Product")]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm._f("titleize")(_vm.SelectedProductName))))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"body-2 text-dark"},[_vm._v("Option")]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm._f("titleize")(_vm.SelectedOptionName))))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }