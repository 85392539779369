var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.AvailableChannels && _vm.AvailableChannels.length > 0)?_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.Loading,"headers":_vm.availableChannelsHeaders,"items":_vm.AvailableChannels,"items-per-page":10,"search":_vm.SearchText,"sort-by":"name","footer-props":{
        itemsPerPageOptions: _vm.paginationOptions,
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
      }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm._f("capitalize")(item.name)))]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"plain":"","color":"primary"},on:{"click":function($event){return _vm.selectChannel(item)}}},[_vm._v("CONNECT TO CHANNEL")])]}}],null,false,3493111602)}),_c('v-dialog',{attrs:{"max-width":"880","persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Connect Channel "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.dialog)?_c('rdm-connect-available-channel',{on:{"cancel":_vm.closeDialog}}):_vm._e()],1)],1)],1)],1):_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"plain":"","color":"primary"},on:{"click":_vm.goToFeedback}},[_vm._v("Request for making channels visible")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }