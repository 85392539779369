







import { Component, Vue } from 'vue-property-decorator';
import { appModule } from '@/store/modules/moduleApp';

@Component({
  components: {},
  props: {},
})
export default class RdmLoading extends Vue {
  get Loading() {
    return appModule.Loading;
  }
}
