



















































































import { Component, Vue } from 'vue-property-decorator';
import { DropdownItem } from '@/models';
import {
  getYear,
  addYears,
  subYears,
  format,
  subMonths,
  addMonths,
  getDaysInMonth,
} from 'date-fns';
import RdmCalendarMonthly from '@/components/common/time/RdmCalendarMonthly.vue';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import { pricingModule } from '@/store/modules/modulePricing';
import { inventoryModule } from '@/store/modules/moduleInventory';

@Component({
  components: { RdmCalendarMonthly },
})
export default class InventoryCalendar extends Vue {
  private calendarDates = [] as Array<DropdownItem>;
  private selectedCalendarDate = 'year';
  private weekdays = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  } as any;
  private events = [];

  mounted() {
    this.calendarDates.push({ text: 'Year', value: 'year' });
    this.calendarDates.push({ text: 'Month', value: 'month' });
  }

  openDayLevel() {
    if (!inventoryModule.ItemOpen) inventoryModule.toggleEvent();
  }

  today() {
    this.CurrentDate = new Date();
  }

  previous() {
    if (this.selectedCalendarDate === 'month') {
      this.previousMonth();
    } else if (this.selectedCalendarDate === 'year') {
      this.previousYear();
    }
  }

  next() {
    if (this.selectedCalendarDate === 'month') {
      this.nextMonth();
    } else if (this.selectedCalendarDate === 'year') {
      this.nextYear();
    }
  }

  previousYear() {
    this.CurrentDate = subYears(this.CurrentDate, 1);
  }

  nextYear() {
    this.CurrentDate = addYears(this.CurrentDate, 1);
  }

  previousMonth() {
    this.CurrentDate = subMonths(this.CurrentDate, 1);
  }

  nextMonth() {
    this.CurrentDate = addMonths(this.CurrentDate, 1);
  }

  get Month() {
    return format(this.CurrentDate, 'MMMM');
  }

  get Year() {
    return getYear(this.CurrentDate);
  }

  get MonthYear() {
    if (this.CurrentDate) {
      if (this.selectedCalendarDate === 'month') {
        return format(this.CurrentDate, 'MMMM yyyy');
      } else if (this.selectedCalendarDate === 'year') {
        return format(this.CurrentDate, 'yyyy');
      }
    }
    return '';
  }

  get DaysInMonth() {
    return getDaysInMonth(this.CurrentDate);
  }

  get OfferCapacityList() {
    return pricingModule.OfferCapacityList;
  }

  get CurrentDate() {
    return availabilityStatusModule.CurrentDate;
  }

  set CurrentDate(date: Date) {
    availabilityStatusModule.setCurrentDate(date);
  }
}
