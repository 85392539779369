








































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RequestConnectionWizardStage3 extends Vue {
  private addProductModel = false;

  private startDateMenu = false;

  private endDateMenu = false;

  private invalidDateRange = false;

  public detailsForm = {
    label: '',
    startDate: '',
    endDate: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    rateCard: '',
  };

  next() {
    this.$emit('next-step', true);
  }

  setRateCards() {
    return ['Rate Card A', 'Rate Card B', 'Rate Card C'];
  }

  validateDates() {
    const start = Date.parse(this.detailsForm.startDate);
    const end = Date.parse(this.detailsForm.endDate);
    this.invalidDateRange = start >= end;
  }
}
