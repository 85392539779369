var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"to":{ name: 'channel-rate-card-listing' },"text":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" All Rate Cards ")],1)],1)],1),_c('v-form',{ref:"editRateForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(_vm.RateCard)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.RateCard.name))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Channels using this Rate Card")]),_vm._l((_vm.Channels),function(c,index){return _c('div',{key:index},[_c('pre',{staticClass:"text-subtle-dark"},[_vm._v(_vm._s(c))])])})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('v-spacer')],1)],1):_vm._e(),_vm._l((_vm.products),function(product,index){return _c('div',{key:index},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(product.name))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.productHeaders,"hide-default-footer":"","hide-default-header":"","items":product.product_options,"items-per-page":10,"footer-props":{
              itemsPerPageOptions: _vm.paginationOptions(product.product_options),
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
            }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(_vm._f("capitalize")(item.name)))]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","text":"","to":{
                  name: 'channel-rate-card-pricing',
                  params: { product: item.name, commission: _vm.commission },
                }}},[_vm._v(" View Details ")])]}}],null,true)})],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }