











































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { CapacityType } from '@/api/inventory/InventoryModels';
import { TimeSlot } from '@/models';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { httpModule } from '@/store/modules/moduleHttp';
import { pricingModule } from '@/store/modules/modulePricing';
import _ from 'lodash';
import { companyModule } from '@/store/modules/moduleCompany';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { capacityTypeLabel } from '@/filters/hints';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { format } from 'date-fns';
import {
  requiredFieldRules,
  requiredMaxNumberFieldRules,
} from '@/utils/validation-rules';
import { OfferCapacity, CapacityPool, Schedule } from '@/models';
import { handleKeyDownOnlyWholeNumeric, isEmpty } from '@/utils/helpers';

@Component({
  components: { RdmReturnToBtn },
})
export default class AvailabilityEdit extends Vue {
  private routerAvailabilities = constants.routes.INVENTORY_MANAGEMENT;
  private capacityUnlimited = CapacityType.CapacityLimitUnlimited;
  private capacityLimitPooled = CapacityType.CapacityLimitPooled;
  private capacityLimitByUnit = CapacityType.CapacityLimitByUnit;
  private isValid = true;
  private isEmpty = isEmpty;
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;
  private requiredMaxNumberFieldRules = requiredMaxNumberFieldRules;
  private requiredFieldRules = requiredFieldRules;
  private dayTimeExpand = [0, 1, 2];
  private allDaysSelected = true;
  private startDate = '';
  private endDate = '' as string;
  private dateNow = new Date();
  private startDateMenu = false;
  private endDateMenu = false;

  created() {
    scheduleModule.setSchedule({} as Schedule);
    this.clearModules();
  }

  async mounted() {
    pricingModule.setInventoryLoading(true);
    await httpModule.getOffer({ offerId: this.$route.params.id, withCapacity: true });
    if (this.Offer.productId) {
      await httpModule.productSchedules(this.Offer.productId);
      await httpModule.getProduct(this.Offer.productId);
      await httpModule.getProductTripRoutes();
    }
    if (this.Offer.optionId) {
      await httpModule.productSchedules(this.Offer.optionId);
      await httpModule.getOption(this.Offer.optionId);
      await httpModule.getOptionTripRoutes();
      await httpModule.getOptionUnitTravelerTypes(this.Offer.optionId);
    }
    await this.configureCapacity();
    await this.configSchedule();
    this.$root.$on(constants.events.INVENTORY_EDIT_SAVE, this.saveForm);
    this.$root.$on(constants.events.INVENTORY_EDIT_REMOVE, this.removeOfferCapacity);
    this.checkIfAllSelected();
    pricingModule.setInventoryLoading(false);
  }

  destroyed() {
    this.$root.$off(constants.events.INVENTORY_EDIT_SAVE);
    this.$root.$off(constants.events.INVENTORY_EDIT_REMOVE);
    this.EditOfferCapacity = {} as OfferCapacity;
  }

  async configureCapacity() {
    if (this.Product && this.Product.id) {
      const id = companyModule.Organization?.ID || '';
      productModule.setProductCapacityPool({
        orgId: id,
        productId: this.Offer.productId,
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      await httpModule.getProductCapacity();
    }
    if (this.Option && this.Option.id) {
      const id = companyModule.Organization?.ID || '';
      optionModule.setOptionCapacityPool({
        orgId: id,
        optionId: this.Offer.optionId || '',
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      await httpModule.getOptionCapacity();
    }
    await pricingModule.chooseCapacityType();
    ////////////////////////////////////////////////////////
    this.EditOfferCapacity = _.cloneDeep(this.OfferCapacity);
    this.startDate = _.cloneDeep(this.OfferCapacity.validFrom);
    if (this.OfferCapacity?.validUntil) {
      this.EditOfferCapacity.validUntil = _.cloneDeep(this.OfferCapacity.validUntil);
      this.endDate = _.cloneDeep(this.OfferCapacity.validUntil);
    } else {
      this.endDate = '';
    }
    this.EditOfferCapacity.capacityLimit = {
      pooled: 0,
      unlimited: false,
      byUnit: {} as Map<string, string>,
    };
    if (this.SelectedCapacityType === this.capacityLimitPooled) {
      this.EditOfferCapacity.capacityLimit.pooled = _.cloneDeep(
        this.OfferCapacity.capacityLimit.pooled,
      );
    }
    if (this.SelectedCapacityType === this.capacityUnlimited) {
      this.EditOfferCapacity.capacityLimit.unlimited = true;
    }
    if (this.SelectedCapacityType === this.capacityLimitByUnit) {
      const cap = {} as any;
      if (this.OfferCapacity.capacityLimit.byUnit) {
        Object.keys(this.OfferCapacity.capacityLimit.byUnit).map((item) => {
          cap[item] = String(this.OfferCapacity.capacityLimit.byUnit[item]);
        });
      }
      this.EditOfferCapacity.capacityLimit.byUnit = cap;
    }
  }

  async saveForm() {
    if (this.$refs && this.$refs.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (!isValid) {
        return;
      }
      this.saveOfferCapacity();
    }
  }

  saveOfferCapacity() {
    const until =
      _.isString(this.endDate) && this.endDate.length > 0 ? this.endDate : null;
    const timeslots = [] as any[];
    this.StructuredSchedule.map((day: any) =>
      day.timeSlots.map((slot: any) => {
        if (slot.editMode) timeslots.push(slot);
      }),
    );
    const capacity = {
      id: this.OfferCapacity.id,
      name: this.EditOfferCapacity.name,
      offerId: this.EditOfferCapacity.offerId,
      scheduleId: this.EditOfferCapacity.scheduleId,
      validFrom: this.startDate,
      validUntil: until,
      timeSlots: timeslots,
      capacityLimit: {},
      version: this.OfferCapacity.version ? this.OfferCapacity.version + 1 : 1,
    } as OfferCapacity;
    if (this.SelectedCapacityType === this.capacityLimitPooled) {
      capacity.capacityLimit.pooled = Number(
        this.EditOfferCapacity?.capacityLimit?.pooled,
      );
    }
    if (this.SelectedCapacityType === this.capacityUnlimited) {
      capacity.capacityLimit.unlimited = true;
    }
    if (this.SelectedCapacityType === this.capacityLimitByUnit) {
      const cap = {} as any;
      if (this.EditOfferCapacity?.capacityLimit?.byUnit) {
        Object.keys(this.EditOfferCapacity?.capacityLimit?.byUnit).map((item) => {
          cap[item] = Number(this.EditOfferCapacity?.capacityLimit?.byUnit[item]);
        });
      }
      capacity.capacityLimit.byUnit = cap;
    }
    httpModule
      .updateOfferCapacity(capacity)
      .then(() => {
        this.$root.$emit(
          constants.events.INVENTORY_HOME_MODAL_CLOSE,
          this.OfferCapacity.id,
        );
      })
      .catch((e) => {
        //TODO: error handle
        console.log(e);
      });
  }

  removeOfferCapacity() {
    httpModule.deleteOfferCapacityById(this.OfferCapacity.id).then(() => {
      this.$router.push({
        name: this.routerAvailabilities,
      });
    });
  }

  roundDown(event: any) {
    if (event && event.target && event.target.value) {
      const value = event.target.value;
      if (
        _.isNumber(_.toNumber(value)) &&
        this.EditOfferCapacity &&
        this.EditOfferCapacity.capacityLimit
      ) {
        this.EditOfferCapacity.capacityLimit.pooled = Math.floor(_.toNumber(value));
      }
    }
  }

  roundDownByUnit(event: any, unitId: string) {
    if (event && event.target && event.target.value) {
      const value = event.target.value;
      if (
        _.isNumber(_.toNumber(value)) &&
        this.EditOfferCapacity &&
        this.EditOfferCapacity.capacityLimit
      ) {
        this.EditOfferCapacity.capacityLimit.byUnit[unitId] = _.toString(
          Math.floor(_.toNumber(value)),
        );
      }
    }
  }

  async configSchedule() {
    pricingModule.setSelectedCapacityType('');
    if (this.EditOfferCapacity.scheduleId)
      await httpModule.getSchedule(this.EditOfferCapacity.scheduleId);
    else await httpModule.getSchedules();
    this.dayTimeExpand = [];
    this.StructuredSchedule.map((_: any, index: number) =>
      this.dayTimeExpand.push(index),
    );
    this.dayTimeExpand = [
      ...this.dayTimeExpand,
      this.StructuredSchedule.length,
      this.StructuredSchedule.length + 1,
    ];
    await pricingModule.chooseCapacityType();
  }

  changeWeekDay(slots: Array<TimeSlot>, item: any) {
    item.selectedAll = !item.selectedAll;
    slots.map((itm: any) => (itm.editMode = item.selectedAll));
    this.checkIfAllSelected();
  }

  changeTimeSlot(slot: TimeSlot, item: any) {
    slot.editMode = !slot.editMode;
    const check = item.timeSlots.filter((item: any) => item.editMode === true);
    item.selectedAll = check.length === item.timeSlots.length;
    this.checkIfAllSelected();
  }

  changeAll() {
    if (this.allDaysSelected) {
      this.StructuredSchedule.map((item: any) => {
        item.selectedAll = false;
        item.timeSlots.map((slot: TimeSlot) => (slot.editMode = false));
      });
    } else if (!this.allDaysSelected) {
      this.StructuredSchedule.map((item: any) => {
        item.selectedAll = true;
        item.timeSlots.map((slot: TimeSlot) => (slot.editMode = true));
      });
    }
    this.checkIfAllSelected();
  }
  checkIfAllSelected() {
    const check = this.StructuredSchedule.filter((item: any) => item.selectedAll);
    this.allDaysSelected = check.length === this.StructuredSchedule.length;
  }

  unitIdToTravelerName(id: string) {
    const i = this.DisplayTravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.DisplayTravelerTypes[i].text;
    }
    return '';
  }

  clearModules() {
    productModule.clearState();
    optionModule.clearState();
    pricingModule.setSelectedCapacityType('');
  }

  get StructuredSchedule() {
    if (!this.Schedule.id || this.Schedule.timeSlots === null) return [];

    const weekdays = [...new Set(this.Schedule.timeSlots.map((slot) => slot.weekday))];
    const matchingSlots = [];

    for (const weekday of weekdays) {
      const scheduleSlots = this.Schedule.timeSlots.filter(
        (slot) => slot.weekday === weekday,
      );
      const offerSlots = this.OfferCapacity?.timeSlots?.filter(
        (slot) => slot.weekday === weekday,
      );

      const selectedAll = scheduleSlots.length === offerSlots?.length;

      for (const scheduleSlot of scheduleSlots) {
        scheduleSlot.editMode = offerSlots?.some(
          (offerSlot) => offerSlot.key === scheduleSlot.key,
        );
      }

      matchingSlots.push({
        weekday,
        timeSlots: scheduleSlots,
        selectedAll,
      });
    }

    return matchingSlots;
  }

  get InventoryLoading() {
    return pricingModule.InventoryLoading;
  }

  get OfferCapacity() {
    return pricingModule.OfferCapacity;
  }

  get InventoryType() {
    if (this.Product.availabilityType) {
      return capacityTypeLabel(this.Product.availabilityType);
    } else return '';
  }

  get DisplayTravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get CapacityPoolByUnit() {
    if (this.CapacityPool && this.CapacityPool.byUnit) {
      return this.CapacityPool.byUnit;
    }
    return {};
  }

  get CapacityPool() {
    const productCapacity = this.ProductCapacityPool;
    const optionCapacity = this.OptionCapacityPool;
    if (
      !_.isEmpty(productCapacity) &&
      _.isBoolean(productCapacity.unlimited) &&
      !productCapacity.unlimited
    ) {
      return productCapacity;
    }

    if (
      !_.isEmpty(optionCapacity) &&
      _.isBoolean(optionCapacity.unlimited) &&
      !optionCapacity.unlimited
    ) {
      return optionCapacity;
    }
    return {} as CapacityPool;
  }

  get OptionCapacityPool() {
    if (optionModule.OptionCapacityPool && optionModule.OptionCapacityPool.capacityPool) {
      return optionModule.OptionCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get OptionCapacityPoolByUnit() {
    if (this.OptionCapacityPool && this.OptionCapacityPool.byUnit) {
      return this.OptionCapacityPool.byUnit;
    }
    return {};
  }

  get SelectedCapacityAmount() {
    return pricingModule.SelectedCapacityAmount;
  }

  get SelectedCapacityType() {
    return pricingModule.SelectedCapacityType;
  }

  get SelectedCapacityProductOption() {
    return pricingModule.SelectedCapacityProductOption;
  }

  get SelectedCapacityByUnit() {
    return pricingModule.SelectedCapacityByUnits;
  }

  get PageTitle() {
    return this.$route?.meta?.title || 'Add Inventory';
  }

  get Offer() {
    return pricingModule.Offer;
  }

  get LimitedCapacityOffers() {
    return pricingModule.NonFreesaleOffers;
  }

  get Product() {
    return productModule.Product;
  }

  get Option() {
    return optionModule.Option;
  }

  get Loading() {
    return pricingModule.Loading;
  }

  get Products() {
    return productModule.Products;
  }

  get Options() {
    return optionModule.Options;
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get StartDate() {
    const padTo2Digits = (num: any) => num.toString().padStart(2, '0');
    return [
      this.dateNow.getFullYear(),
      padTo2Digits(this.dateNow.getMonth() + 1),
      padTo2Digits(this.dateNow.getDate() - 1),
    ].join('/');
  }

  get StartDateFormatted() {
    if (this.Schedule && this.Schedule.validFrom && this.Schedule.validFrom.length > 0) {
      const startDate = new Date(this.Schedule.validFrom);
      return format(startDate, 'yyyy-MM-dd');
    }
    return format(this.dateNow, 'yyyy-MM-dd');
  }

  get Schedules() {
    return [
      scheduleModule.DefaultSchedule,
      ...productModule.ProductSchedules,
      ...optionModule.OptionSchedules,
    ];
  }

  get Schedule() {
    return scheduleModule.Schedule;
  }

  get ProductCapacityPool() {
    if (
      productModule.ProductCapacityPool &&
      productModule.ProductCapacityPool.capacityPool
    ) {
      return productModule.ProductCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get IsFreesale() {
    return this.SelectedCapacityType === CapacityType.CapacityLimitUnlimited;
  }

  get EditOfferCapacity() {
    return pricingModule.EditOfferCapacity;
  }

  set EditOfferCapacity(offer: OfferCapacity) {
    pricingModule.setEditOfferCapacity(offer);
  }
}
