var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.PageTitle))])])],1),_c('v-row',{staticClass:"max-width"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Search'),"append-icon":"mdi-magnify","clearable":"","outlined":"","hide-details":"","background-color":"white"},model:{value:(_vm.Search),callback:function ($$v) {_vm.Search=$$v},expression:"Search"}})],1),_c('v-col',{staticClass:"text-align-right",attrs:{"cols":"12","md":"4","offset-md":"4","align-self":"end"}},[(_vm.Company && _vm.Company.orgId && _vm.Company.orgId.length > 0 && _vm.IsOrgAdmin)?_c('v-btn',{staticClass:"pl-0",attrs:{"color":"primary","outlined":""},on:{"click":_vm.navigateToNewLocation}},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-plus")]),_vm._v("Add Location ")],1):_vm._e()],1)],1)],1)],1),(!_vm.Loading)?_c('v-row',[(!_vm.Company && !_vm.Company.orgId && _vm.Company.orgId.length === 0)?[_c('router-link',{attrs:{"to":{ name: _vm.routerCompanyAboutName }}},[_vm._v("Click here to configure company data first")])]:_vm._e()],2):_vm._e(),_c('v-row',{staticClass:"max-width"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.Loading),expression:"Loading"}],attrs:{"type":"table-thead, table-row-divider@10, table-tfoot"}}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.Loading),expression:"!Loading"}],staticClass:"elevation-0",attrs:{"loading":_vm.Loading,"headers":_vm.locationsHeaders,"items":_vm.CompanyAddresses,"items-per-page":10,"search":_vm.Search,"sort-by":['name', 'streetAddress'],"no-data-text":"No addresses","hide-default-footer":(_vm.CompanyAddresses && _vm.CompanyAddresses.length === 0) || !_vm.CompanyAddresses,"footer-props":{
            itemsPerPageOptions: _vm.paginationOptions,
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
          }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("emptyDash")(item.name))+" ")]}},{key:"item.streetAddress",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(((item.streetAddress || 'No street address') + ", " + (item.city || 'No City') + ", " + (item.region || '') + ", " + (item.postalCode || '') + " " + (_vm.findCountryName(item.countryCode) || '') + " "))+" ")]}},{key:"item.view",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","plain":"","disabled":!item.id},on:{"click":function($event){return _vm.navigateToViewLocation(item)}}},[_vm._v("View")]),(_vm.IsOrgAdmin)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"color":"error","plain":"","disabled":!item.id},on:{"click":function($event){return _vm.removeLocation(item)}}},[_vm._v("Remove")]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" You don’t have any Locations yet. Click "),_c('router-link',{attrs:{"to":{ name: _vm.routerLocationAdd }}},[_vm._v("here")]),_vm._v(" to add one. ")]},proxy:true}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"660px"},model:{value:(_vm.removeModal),callback:function ($$v) {_vm.removeModal=$$v},expression:"removeModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Remove Address")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.SelectedAddress)?_c('label',[_vm._v(" Are you sure you want to remove "+_vm._s(_vm.SelectedAddress.name || '')+" address "+_vm._s(((_vm.SelectedAddress.streetAddress || '') + ", " + (_vm.SelectedAddress.city || '') + ", " + (_vm.SelectedAddress.region || '')))+"? ")]):_vm._e(),_c('p',[_vm._v(" By removing this Address Item you are potentially changing data associated to a channel. Please validate and republish your data. ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.saving,"color":"primary","depressed":""},on:{"click":_vm.proceedRemoveAddress}},[_vm._v("Remove")]),_c('v-btn',{attrs:{"disabled":_vm.saving,"plain":""},on:{"click":_vm.cancelRemoveModal}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }