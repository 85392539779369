



























































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { productModule } from '@/store/modules/moduleProduct';
import { httpModule } from '@/store/modules/moduleHttp';
import ProductViewDescription from '@/components/product/view/ProductViewDescription.vue';
import ProductTicketingSummary from '@/components/product/detail/ProductTicketingSummary.vue';
import ProductInventorySummary from '@/components/product/detail/ProductInventorySummary.vue';
import ProductViewSchedule from '@/components/product/view/ProductViewSchedule.vue';
import ProductViewLocation from '@/components/product/view/ProductViewLocation.vue';
import ProductViewOffer from '@/components/product/view/ProductViewOffer.vue';
import ProductViewCapacity from '@/components/product/view/ProductViewCapacity.vue';
import ProductViewPolicy from '@/components/product/view/ProductViewPolicy.vue';
import ProductViewName from '@/components/product/view/ProductViewName.vue';
import ProductViewReservationContacts from '@/components/product/view/ProductViewReservationContacts.vue';
import { companyModule } from '@/store/modules/moduleCompany';

@Component({
  components: {
    ProductViewReservationContacts,
    ProductViewName,
    ProductViewPolicy,
    ProductViewCapacity,
    ProductViewOffer,
    ProductViewLocation,
    ProductViewSchedule,
    ProductInventorySummary,
    ProductTicketingSummary,
    ProductViewDescription,
    RdmReturnToBtn,
  },
})
export default class ProductView extends Vue {
  private routerProductList = constants.routes.PRODUCT_LIST;
  private parentName = constants.titles.PRODUCTS;

  mounted() {
    if (this.$route.params?.id) {
      httpModule.getProduct(this.$route.params?.id).then(() => {
        httpModule.getProductTripRoutes().then();
        httpModule.getProductUnitTravelerTypes(this.$route.params?.id).then();
        const id = companyModule.Organization?.ID || '';
        httpModule.listProductContacts(this.$route.params.id).then();
        productModule.setProductCapacityPool({
          orgId: id,
          productId: this.$route.params?.id,
          capacityPool: {
            pooled: 0,
            unlimited: false,
            byUnit: {} as Map<string, string>,
          },
        });
        httpModule.getProductCapacity().then();
      });
    }
  }

  destroy() {
    productModule.clearState();
  }

  // todo: set @Watch('Loading') and remove false loading check
  toSchedule() {
    setTimeout(() => {
      try {
        const el = this.$refs['sched'];
        if (this.$route.hash === '#schedule' && el) {
          this.$vuetify.goTo('#sched');
        }
      } catch (e) {
        console.log('e', e);
      }
    }, 100);
  }

  get PageTitle() {
    return this.$route?.meta?.title || this.parentName;
  }

  get Loading() {
    if (!productModule.Loading) {
      this.toSchedule();
    }
    return productModule.Loading;
  }

  get Product() {
    return productModule.Product;
  }
}
