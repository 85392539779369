import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Fee } from '@/models';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-taxes-and-fees',
  namespaced: true,
})
class ModuleTaxAndFees extends VuexModule {
  /* Data */
  private loading = false;
  private searchText = '';
  private taxesAndFees = [] as Array<Fee>;
  private selectedTaxAndFees = {} as Fee;
  private selectedCurrency = 'USD';

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get SelectedCurrency() {
    return this.selectedCurrency;
  }

  get SearchText(): string {
    return this.searchText;
  }

  get TaxesAndFees() {
    return this.taxesAndFees;
  }

  get SingleTaxAndFee() {
    return this.selectedTaxAndFees;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setSelectedCurrency(currency: string) {
    this.selectedCurrency = currency;
  }

  @Mutation
  setSearchText(search: string) {
    this.searchText = search;
  }

  @Mutation
  setTaxesAndFees(fee: Array<Fee>) {
    this.taxesAndFees = fee;
  }

  @Mutation
  setSelectedTaxesAndFees(fee: Fee) {
    this.selectedTaxAndFees = fee;
  }

  /* Actions */
  @Action
  selectTaxAndFee(id: string) {
    const taxAndFee = this.TaxesAndFees.find((item: Fee) => item.id === id);
    if (taxAndFee) {
      this.setSelectedTaxesAndFees(taxAndFee);
    }
  }

  @Action
  removeTaxAndFee(id: string) {
    const i = this.TaxesAndFees.findIndex((item: Fee) => item.id === id);
    if (i > -1) {
      this.TaxesAndFees.splice(i, 1);
    }
  }

  @Action
  updateSelectedCurrency(currency: string) {
    this.setSelectedCurrency(currency);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setTaxesAndFees([]);
  }
}

export const taxesAndFeesModule = getModule(ModuleTaxAndFees, store);
