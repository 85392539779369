















































































import { Component, Vue } from 'vue-property-decorator';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { Unit } from '@/api/inventory/InventoryModels';
import _ from 'lodash';
import { requiredFieldRules, ageStringRules } from '@/utils/validation-rules';
import { httpModule } from '@/store/modules/moduleHttp';
import { handleKeyDownOnlyWholeNumeric } from '@/utils/helpers';

@Component
export default class TravelerTypeDetails extends Vue {
  private travelerType = {} as Unit;
  private baseTravelerType = {} as Unit;
  private requiredFieldRules = requiredFieldRules;
  private ageStringRules = ageStringRules;
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;
  private validAmount = [() => 'Enter an age greater than the minimum age!'];

  mounted() {
    this.travelerType = _.cloneDeep(this.TravelerType);
    this.baseTravelerType = _.cloneDeep(this.TravelerType);
  }

  toggleEdit() {
    if (this.Loading) {
      return;
    }

    travelerTypeModule.setEditModeAgeRange(!this.EditMode);
  }

  validateAgeRange() {
    travelerTypeModule.setCreateTravelerTypeAgeRangeState(
      (this?.travelerType?.minAge || 0) >= (this?.travelerType?.maxAge || 0),
    );
    if (this.$refs && this.$refs.travelerTypeAgeRangeForm) {
      const travelerTypeAgeRangeForm: any = this.$refs.travelerTypeAgeRangeForm;
      travelerTypeAgeRangeForm.validate();
    }
  }

  save() {
    if (this.$refs && this.$refs.travelerTypeAgeRangeForm) {
      const travelerTypeAgeRangeForm: any = this.$refs.travelerTypeAgeRangeForm;
      const isValid = travelerTypeAgeRangeForm.validate();
      if (!isValid) {
        return;
      }
    }

    if (
      this.travelerType &&
      this.travelerType.minAge &&
      _.isString(this.travelerType.minAge) &&
      Number.isInteger(_.toNumber(this.travelerType.minAge))
    ) {
      this.travelerType.minAge = _.toNumber(this.travelerType.minAge);
    }
    if (
      this.travelerType &&
      this.travelerType.maxAge &&
      _.isString(this.travelerType.maxAge) &&
      Number.isInteger(_.toNumber(this.travelerType.maxAge))
    ) {
      this.travelerType.maxAge = _.toNumber(this.travelerType.maxAge);
    }
    this.baseTravelerType = _.cloneDeep(this.travelerType);
    httpModule.updateTravelerTypes(this.travelerType).then(() => {
      this.toggleEdit();
    });
  }

  cancel() {
    this.travelerType = _.cloneDeep(this.baseTravelerType);
    this.toggleEdit();
  }

  get Loading() {
    return travelerTypeModule.Loading;
  }

  get EditMode() {
    return travelerTypeModule.EditModeAgeRange;
  }

  get TravelerTypeAgeRangeValid() {
    return travelerTypeModule.TravelerTypeAgeRangeValid;
  }

  set TravelerTypeAgeRangeValid(valid: boolean) {
    travelerTypeModule.setTravelerTypeAgeRangeValid(valid);
  }

  get TravelerType() {
    return travelerTypeModule.SelectedTravelerType;
  }

  get InvalidMax() {
    return !!(
      this.travelerType.minAge &&
      this.travelerType.maxAge &&
      +this.travelerType.maxAge <= +this.travelerType.minAge
    );
  }
}
