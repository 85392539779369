import axios, { AxiosInstance } from 'axios';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AvailabilitySchedule,
  Codes,
  PriceSchedule,
  Pricing,
  Unit,
  PricingWithTaxes,
  OppPricing,
  OppAddressQueryCriteria,
  OfferDateRangeReq,
  OperatorUserReq,
} from '@/api/inventory/InventoryModels';
import { UpdateProductErrHandlers } from '@/api/inventory/ErrHandlers';
import { AxiosErrorWithResponse, intercept } from '@/api/AxiosInterceptors';
import { ApiError, CommonErrHandlers, handleErrs, OppApiError } from '@/api/ErrHandlers';
import { config } from '@/utils/config';
import { constants } from '@/utils/constants';
import { AuditRecord } from '@/api/models/General';
import { companyModule } from '@/store/modules/moduleCompany';
import { profileModule } from '@/store/modules/moduleProfile';
import {
  Address,
  Company,
  Contact,
  Offer,
  OfferPricing,
  Option,
  Product,
  TripRoute,
  RateCard,
  ProductUnit,
  Fee,
  Schedule,
  EffectiveDateRange,
  TimeSlot,
  CapacityPool,
  ProductCapacityPool,
  OptionCapacityPool,
  OfferPricingList,
  OfferCapacity,
  OfferCapacityList,
  GRSchedule,
  CompanyRate,
  ChannelBinding,
  OperatorUser,
  RateCardChannel,
} from '@/models/ModelsOppInventory';
import { AvailabilityItem, AvailChangeReq } from '@/models';

export class InventoryClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.OppApiUrl,
      timeout: 1000 * 60 * 5, // todo replace with config variable
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);

    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(InventoryClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    // if (err.response?.status >= 400) {
    //   console.clear();
    // }

    if (err.response?.status === 401) {
      return InventoryClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data as OppApiError;
      // if (err.response.status === 403) {
      //   appModule.addMessageError(codes.GENERAL_MESSAGE_FORBIDDEN);
      // }
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  /* configure client */

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    InventoryClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  /* products */

  public createProduct(rq: Product, errs?: CommonErrHandlers): Observable<Product> {
    if (rq.policy) {
      rq.policy.cancelCutOffAfter = 0;
      rq.policy.cancelCutOffBefore = 0;
    }
    return from(this.httpClient.post(`/companies/${rq.orgId}/products`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateProduct(rq: Product, errs?: UpdateProductErrHandlers): Observable<Product> {
    if (rq.policy) {
      rq.policy.cancelCutOffAfter = 0;
      rq.policy.cancelCutOffBefore = 0;
    }
    return from(this.httpClient.put(`/companies/${rq.orgId}/products/${rq.id}`, rq)).pipe(
      map((response) => response.data),
      catchError(
        handleErrs((e: ApiError, errs) => {
          if (errs.onProductNotFound && e.status === 404) {
            errs.onProductNotFound(e);
          } else if (errs.onWrongVersion && e.status === 409) {
            errs.onWrongVersion(e);
          }
        }, errs),
      ),
    );
  }

  public deleteProduct(rq: Product, errs?: CommonErrHandlers): Observable<null> {
    return from(this.httpClient.delete(`/companies/${rq.orgId}/products/${rq.id}`)).pipe(
      map(() => null),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public listProducts(orgId: string, errs?: CommonErrHandlers): Observable<Array<Product>> {
    return from(this.httpClient.get<Array<Product>>(`/companies/${orgId}/products`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getProduct(rq: Product, errs?: CommonErrHandlers): Observable<Product> {
    return from(this.httpClient.get<Product>(`/companies/${rq.orgId}/products/${rq.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /*Product Contacts*/
  public createProductContact(
    rq: { productId: string; contactId: string; orgId: string },
    errs?: CommonErrHandlers,
  ): Observable<Product> {
    return from(this.httpClient.post(`/companies/${rq.orgId}/products/${rq.productId}/contacts/${rq.contactId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public deleteProductContact(
    rq: { productId: string; contactId: string; orgId: string },
    errs?: CommonErrHandlers,
  ): Observable<Product> {
    return from(
      this.httpClient.delete(`/companies/${rq.orgId}/products/${rq.productId}/contacts/${rq.contactId}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public listProductContacts(
    rq: { productId: string; orgId: string },
    errs?: CommonErrHandlers,
  ): Observable<Array<Contact>> {
    return from(this.httpClient.get(`/companies/${rq.orgId}/products/${rq.productId}/contacts/`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  /* Options */

  public listOptions(id: string, errs?: CommonErrHandlers): Observable<Array<Option>> {
    return from(this.httpClient.get<Array<Option>>(`/companies/${id}/options`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOption(rq: Option, errs?: CommonErrHandlers): Observable<Option> {
    return from(this.httpClient.get<Option>(`/companies/${rq.orgId}/options/${rq.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createOption(rq: Option, errs?: CommonErrHandlers): Observable<Option> {
    return from(this.httpClient.post(`/companies/${rq.orgId}/options`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateOption(rq: Option, errs?: UpdateProductErrHandlers): Observable<Option> {
    if (rq.policy) {
      rq.policy.cancelCutOffAfter = 0;
      rq.policy.cancelCutOffBefore = 0;
    }
    return from(this.httpClient.put(`/companies/${rq.orgId}/options/${rq.id}`, rq)).pipe(
      map((response) => response.data),
      catchError(
        handleErrs((e: ApiError, errs) => {
          if (errs.onProductNotFound && e.status === 404) {
            errs.onProductNotFound(e);
          } else if (errs.onWrongVersion && e.status === 409) {
            errs.onWrongVersion(e);
          }
        }, errs),
      ),
    );
  }

  public deleteOption(rq: Option, errs?: CommonErrHandlers): Observable<null> {
    return from(this.httpClient.delete(`/companies/${rq.orgId}/options/${rq.id}`)).pipe(
      map(() => null),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Trip Routes */

  public createProductTripRoute(rq: Product, body: TripRoute, errs?: CommonErrHandlers): Observable<TripRoute> {
    return from(this.httpClient.post(`/companies/${rq.orgId}/products/${rq.id}/triproute`, body)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateProductTripRoute(rq: Product, body: TripRoute, errs?: CommonErrHandlers): Observable<TripRoute> {
    return from(this.httpClient.put(`/companies/${rq.orgId}/products/${rq.id}/triproute`, body)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteProductTripRoute(rq: Product, errs?: CommonErrHandlers): Observable<TripRoute> {
    return from(this.httpClient.delete(`/companies/${rq.orgId}/products/${rq.id}/triproute`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getProductTripRoute(rq: Product, errs?: CommonErrHandlers): Observable<TripRoute> {
    return from(this.httpClient.get(`/companies/${rq.orgId}/products/${rq.id}/triproute`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createOptionTripRoute(rq: Option, body: TripRoute, errs?: CommonErrHandlers): Observable<TripRoute> {
    return from(this.httpClient.post(`/companies/${rq.orgId}/options/${rq.id}/triproute`, body)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateOptionTripRoute(rq: Option, body: TripRoute, errs?: CommonErrHandlers): Observable<TripRoute> {
    return from(this.httpClient.put(`/companies/${rq.orgId}/options/${rq.id}/triproute`, body)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteOptionTripRoute(rq: Option, errs?: CommonErrHandlers): Observable<TripRoute> {
    return from(this.httpClient.delete(`/companies/${rq.orgId}/options/${rq.id}/triproute`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOptionTripRoute(rq: Option, errs?: CommonErrHandlers): Observable<TripRoute> {
    return from(this.httpClient.get(`/companies/${rq.orgId}/options/${rq.id}/triproute`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Capacity */

  // product_capacity(
  //   product_id text PK, FK(products.id, on delete cascade)
  //   capacity_id text FK(capacity.id)
  //   created_at
  //   updated_at
  // )
  // option_capacity(
  //   option_id text PK, FK(options.id, on delete cascade)
  //   capacity_id text FK(capacity.id)
  //   created_at
  //   updated_at
  // )
  //
  // -- these may already exist because of RE-2506
  // capacity(
  //   id             text PK
  //   unlimited      bool
  //   pooled         int
  //   created_at
  //   updated_at
  // )
  // capacity_byunit(
  //   capacity_id       text FK(capacity.id)
  //   unit_id           text FK(unit.id)
  //   capacity          int
  //   created_at
  //   updated_at
  //   PK(capacity_id, unit_id)
  // )
  public getProductCapacity(rq: ProductCapacityPool, errs?: CommonErrHandlers): Observable<CapacityPool> {
    return from(this.httpClient.get(`/companies/${rq.orgId}/products/${rq.productId}/capacity`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateProductCapacity(rq: ProductCapacityPool, errs?: CommonErrHandlers): Observable<CapacityPool> {
    return from(this.httpClient.put(`/companies/${rq.orgId}/products/${rq.productId}/capacity`, rq.capacityPool)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  // todo put product capacity

  public getOptionCapacity(rq: OptionCapacityPool, errs?: CommonErrHandlers): Observable<CapacityPool> {
    return from(this.httpClient.get(`/companies/${rq.orgId}/options/${rq.optionId}/capacity`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateOptionCapacity(rq: OptionCapacityPool, errs?: CommonErrHandlers): Observable<CapacityPool> {
    return from(this.httpClient.put(`/companies/${rq.orgId}/options/${rq.optionId}/capacity`, rq.capacityPool)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  // todo put option capacity

  /* Traveler Types */

  public getTravelerTypes(orgId: string, errs?: CommonErrHandlers): Observable<Array<Unit>> {
    return from(this.httpClient.get<Array<Unit>>(`/companies/${orgId}/units`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getTravelerTypesByProductId(
    orgId: string,
    productId: string,
    errs?: CommonErrHandlers,
  ): Observable<Array<Unit>> {
    return from(this.httpClient.get<Array<Unit>>(`/companies/${orgId}/units?productID=${productId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getTravelerTypesByOptionId(
    orgId: string,
    optionId: string,
    errs?: CommonErrHandlers,
  ): Observable<Array<Unit>> {
    return from(this.httpClient.get<Array<Unit>>(`/companies/${orgId}/units?optionID=${optionId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getTravelerTypesById(rq: Unit, errs?: CommonErrHandlers): Observable<Unit> {
    return from(this.httpClient.get<Unit>(`/companies/${rq.orgId}/units/${rq.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createTravelerType(rq: Unit, errs?: CommonErrHandlers): Observable<Unit> {
    return from(this.httpClient.post<Unit>(`/companies/${rq.orgId}/units`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateTravelerType(rq: Unit, errs?: CommonErrHandlers): Observable<Unit> {
    return from(this.httpClient.put<Unit>(`/companies/${rq.orgId}/units/${rq.id}`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteTravelerType(rq: Unit, errs?: CommonErrHandlers): Observable<Unit> {
    return from(this.httpClient.delete(`/companies/${rq.orgId}/units/${rq.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getVersion(errs?: CommonErrHandlers): Observable<string> {
    return from(this.httpClient.get(`/version`, { headers: { 'cache-control': 'no-cache' } })).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Product-Unit Relationship */
  public createProductUnitRelationship(
    orgId: string,
    rq: ProductUnit,
    errs?: CommonErrHandlers,
  ): Observable<ProductUnit> {
    return from(this.httpClient.post<ProductUnit>(`/companies/${orgId}/relationship/product-unit`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteProductUnitRelationship(orgId: string, rq: ProductUnit, errs?: CommonErrHandlers) {
    return from(
      this.httpClient.delete(
        `/companies/${orgId}/relationship/product-unit?productID=${rq.parentId}&unitID=${rq.childId}`,
      ),
    ).pipe(
      map(() => {}),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Option-Unit Relationship */
  public createOptionUnitRelationship(
    orgId: string,
    rq: ProductUnit,
    errs?: CommonErrHandlers,
  ): Observable<ProductUnit> {
    return from(this.httpClient.post<ProductUnit>(`/companies/${orgId}/relationship/option-unit`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteOptionUnitRelationship(orgId: string, rq: ProductUnit, errs?: CommonErrHandlers) {
    return from(
      this.httpClient.delete(
        `/companies/${orgId}/relationship/option-unit?optionID=${rq.parentId}&unitID=${rq.childId}`,
      ),
    ).pipe(
      map(() => {}),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Codes */

  public getCodes(errs?: CommonErrHandlers): Observable<Codes> {
    return from(this.httpClient.get<Codes>(`/codes`)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs(() => {
          return {} as Codes;
        }, errs),
      ),
    );
  }

  /* pricings */

  public deprecatedCreatePricing(rq: PricingWithTaxes | {}, errs?: CommonErrHandlers): Observable<null> {
    return from(this.httpClient.post(`/pricing`, rq)).pipe(
      map(() => null),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deprecatedUpdatePricing(rq: PricingWithTaxes, errs?: CommonErrHandlers): Observable<Pricing> {
    return from(this.httpClient.put(`/pricing`, rq)).pipe(
      map((response) => response.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deprecatedDeletePricing(id: string, errs?: CommonErrHandlers): Observable<null> {
    return from(this.httpClient.delete(`/pricing/${id}`)).pipe(
      map(() => null),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deprecatedListPricings(filter?: string, errs?: CommonErrHandlers): Observable<Array<PricingWithTaxes>> {
    return from(this.httpClient.get<Array<PricingWithTaxes>>(`/pricing${filter ? filter : ''}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deprecatedGetPricing(id: string, errs?: CommonErrHandlers): Observable<Array<Pricing>> {
    return from(this.httpClient.get<Array<Pricing>>(`/pricing/${id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createPricing(pricing: OppPricing, errs?: CommonErrHandlers): Observable<OppPricing> {
    return from(this.httpClient.post(`/companies/${pricing.orgId}/pricing`, pricing)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updatePricing(pricing: OppPricing, errs?: CommonErrHandlers): Observable<OppPricing> {
    return from(this.httpClient.put(`/companies/${pricing.orgId}/pricing`, pricing)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deletePricing(pricing: OppPricing, errs?: CommonErrHandlers): Observable<null> {
    return from(this.httpClient.delete(`/companies/${pricing.orgId}/pricing`)).pipe(
      map(() => null),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getPricing(pricing: OppPricing, errs?: CommonErrHandlers): Observable<Array<OppPricing>> {
    return from(this.httpClient.get(`/companies/${pricing.orgId}/pricing`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getPricingById(pricing: OppPricing, errs?: CommonErrHandlers): Observable<Array<OppPricing>> {
    return from(this.httpClient.get(`/companies/${pricing.orgId}/pricing?pricingID=${pricing.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getPricingBySupplierId(pricing: OppPricing, errs?: CommonErrHandlers): Observable<Array<OppPricing>> {
    return from(this.httpClient.get(`/companies/${pricing.orgId}/pricing?supplierID=${pricing.supplierId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getPricingBySupplierAndProductId(
    pricing: OppPricing,
    errs?: CommonErrHandlers,
  ): Observable<Array<OppPricing>> {
    return from(
      this.httpClient.get(
        `/companies/${pricing.orgId}/pricing?supplierID=${pricing.supplierId}&productID=${pricing.productId}`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getPricingBySupplierAndProductAndOptionId(
    pricing: OppPricing,
    errs?: CommonErrHandlers,
  ): Observable<Array<OppPricing>> {
    return from(
      this.httpClient.get(
        `/companies/${pricing.orgId}/pricing?supplierID=${pricing.supplierId}&productID=${pricing.productId}&optionID=${
          pricing.optionId || 'missing'
        }`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Availability */
  public getAvailabilityForOffer(
    orgId: string,
    productId: string,
    startTime: string,
    endTime: string,
    offerID: string,
    errs?: CommonErrHandlers,
  ): Observable<AvailabilityItem[]> {
    return from(
      this.httpClient.get<AvailabilityItem[]>(
        `/companies/${orgId}/products/${productId}/availability?startTime=${startTime}&endTime=${endTime}&optionID=${offerID}`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateAvailabilityVacanies(orgID: string, offerID: string, req: AvailChangeReq, errs?: CommonErrHandlers) {
    return from(
      this.httpClient.post<AvailabilityItem>(`/companies/${orgID}/offers/${offerID}/availability/vacancy`, req),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateAvailabilityStatus(orgID: string, offerID: string, req: AvailChangeReq, errs?: CommonErrHandlers) {
    return from(
      this.httpClient.post<AvailabilityItem>(`/companies/${orgID}/offers/${offerID}/availability/status`, req),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Availability Schedule */
  public getAvailabilitySchedulesBySupplierIDAndProductID(
    rq: AvailabilitySchedule,
    errs?: CommonErrHandlers,
  ): Observable<Array<AvailabilitySchedule>> {
    if (rq.productId) {
      return from(
        this.httpClient.get<Array<AvailabilitySchedule>>(
          `/availabilitySchedule?supplierID=${rq.supplierId}&productID=${rq.productId}`,
        ),
      ).pipe(
        map((rs) => rs.data),
        catchError(handleErrs(() => {}, errs)),
      );
    } else {
      return from(
        this.httpClient.get<Array<AvailabilitySchedule>>(
          `/availabilitySchedule?supplierID=${rq.supplierId}&optionID=${rq.optionId}`,
        ),
      ).pipe(
        map((rs) => rs.data),
        catchError(handleErrs(() => {}, errs)),
      );
    }
  }

  public getAvailabilitySchedulesBySupplierIDAndProductIDAndOptionID(
    rq: AvailabilitySchedule,
    errs?: CommonErrHandlers,
  ): Observable<Array<AvailabilitySchedule>> {
    return from(
      this.httpClient.get<Array<AvailabilitySchedule>>(
        `/availabilitySchedule?supplierID=${rq.supplierId}&productID=${rq.productId}&optionID=${rq.optionId}`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createAvailabilitySchedule(
    rq: AvailabilitySchedule,
    errs?: CommonErrHandlers,
  ): Observable<AvailabilitySchedule> {
    return from(this.httpClient.post<AvailabilitySchedule>(`/availabilitySchedule`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateAvailabilitySchedule(
    rq: AvailabilitySchedule,
    errs?: CommonErrHandlers,
  ): Observable<AvailabilitySchedule> {
    return from(this.httpClient.put<AvailabilitySchedule>(`/availabilitySchedule`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteAvailabilitySchedule(id: string, errs?: CommonErrHandlers): Observable<AvailabilitySchedule> {
    return from(this.httpClient.delete<AvailabilitySchedule>(`/availabilitySchedule/${id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Pricing Schedule */
  public getPriceSchedules(errs?: CommonErrHandlers): Observable<Array<PriceSchedule>> {
    return from(this.httpClient.get<Array<PriceSchedule>>(`/priceSchedule`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getPriceScheduleById(id: string, errs?: CommonErrHandlers): Observable<PriceSchedule> {
    return from(this.httpClient.get<PriceSchedule>(`/priceSchedule/${id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createPriceSchedule(rq: PriceSchedule, errs?: CommonErrHandlers): Observable<PriceSchedule> {
    return from(this.httpClient.post<PriceSchedule>(`/priceSchedule`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updatePriceSchedule(rq: PriceSchedule, errs?: CommonErrHandlers): Observable<PriceSchedule> {
    return from(this.httpClient.put<PriceSchedule>(`/priceSchedule`, rq)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deletePriceSchedule(id: string, errs?: CommonErrHandlers): Observable<PriceSchedule> {
    return from(this.httpClient.delete<PriceSchedule>(`/priceSchedule/${id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Company */
  public getCompanies(errs?: CommonErrHandlers): Observable<Array<Company>> {
    return from(this.httpClient.get<Array<Company>>(`/companies`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getCompanyById(id: string, errs?: CommonErrHandlers): Observable<Company> {
    return from(this.httpClient.get<Company>(`/companies/${id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteCompanyById(id: string, errs?: CommonErrHandlers): Observable<Company> {
    return from(this.httpClient.delete<Company>(`/companies/${id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createCompany(company: Company, errs?: CommonErrHandlers): Observable<Company> {
    if (!company || (company && !company.orgId) || (company && company.orgId && company.orgId.length === 0)) {
      const e = new Error() as any;
      e.status = 8000;
      throw e;
    }
    return from(this.httpClient.post<Company>(`/companies/${company.orgId}`, company)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateCompany(company: Company, errs?: CommonErrHandlers): Observable<Company> {
    if (!company || (company && !company.orgId) || (company && company.orgId && company.orgId.length === 0)) {
      const e = new Error() as any;
      e.status = 8000;
      throw e;
    }
    return from(this.httpClient.put<Company>(`/companies/${company.orgId}`, company)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Contact */
  public getContactsByOrg(id: string, errs?: CommonErrHandlers): Observable<Array<Contact>> {
    return from(this.httpClient.get<Array<Contact>>(`/companies/${id}/contacts`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getContactById(id: string, contact: Contact, errs?: CommonErrHandlers): Observable<Contact> {
    return from(this.httpClient.get<Contact>(`/companies/${id}/contacts/${contact.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createContact(orgId: string, contact: Contact, errs?: CommonErrHandlers): Observable<Contact> {
    return from(this.httpClient.post<Contact>(`/companies/${orgId}/contacts/${contact.id}`, contact)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateContact(orgId: string, contact: Contact, errs?: CommonErrHandlers): Observable<Contact> {
    return from(this.httpClient.put<Contact>(`/companies/${orgId}/contacts/${contact.id}`, contact)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteContact(orgId: string, contact: Contact, errs?: CommonErrHandlers): Observable<Contact> {
    return from(this.httpClient.delete<Contact>(`/companies/${orgId}/contacts/${contact.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /*Offers*/
  public updateOffer(orgId: string, offer: Offer, errs?: CommonErrHandlers): Observable<Offer> {
    return from(this.httpClient.put<Offer>(`/companies/${orgId}/offers/${offer.id}`, offer)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOffer(orgId: string, offerId: string, errs?: CommonErrHandlers): Observable<Offer> {
    return from(this.httpClient.get<Offer>(`/companies/${orgId}/offers/${offerId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOffersByProductId(orgId: string, offer: Offer, errs?: CommonErrHandlers): Observable<Array<Offer>> {
    return from(this.httpClient.get<Array<Offer>>(`/companies/${orgId}/offers?productID=${offer.productId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOffers(orgId: string, errs?: CommonErrHandlers): Observable<Array<Offer>> {
    return from(this.httpClient.get<Array<Offer>>(`/companies/${orgId}/offers`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteOffer(orgId: string, offer: Offer, errs?: CommonErrHandlers): Observable<Offer> {
    return from(this.httpClient.delete<Offer>(`/companies/${orgId}/offers/${offer.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* offer capacity (inventory)*/
  public updateOfferCapacity(
    orgId: string,
    capacity: OfferCapacity,
    errs?: CommonErrHandlers,
  ): Observable<OfferCapacity> {
    return from(this.httpClient.put<OfferCapacity>(`/companies/${orgId}/offercapacity/${capacity.id}`, capacity)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOfferCapacity(orgId: string, capacityId: string, errs?: CommonErrHandlers): Observable<OfferCapacity> {
    return from(this.httpClient.get<OfferCapacity>(`/companies/${orgId}/offercapacity/${capacityId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOfferCapacityTimeSlot(
    orgId: string,
    capacityId: string,
    timeslotKey: number,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.get<any>(`/companies/${orgId}/offercapacity/${capacityId}/timeslots/${timeslotKey}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOfferCapacityList(orgId: string, offerId: string, errs?: CommonErrHandlers): Observable<OfferCapacityList> {
    return from(this.httpClient.get<OfferCapacityList>(`/companies/${orgId}/offercapacitylist/${offerId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOffersCapacity(orgId: string, errs?: CommonErrHandlers): Observable<OfferCapacityList> {
    return from(this.httpClient.get<OfferCapacityList>(`/companies/${orgId}/offercapacity`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteOfferCapacity(orgId: string, capacityId: string, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.delete<any>(`/companies/${orgId}/offercapacity/${capacityId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteOfferCapacityTimeSlot(
    orgId: string,
    capacityId: string,
    timeslotKey: number,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.delete<any>(`/companies/${orgId}/offercapacity/${capacityId}/timeslots/${timeslotKey}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* offer pricing */
  public updateOfferPricing(orgId: string, offer: OfferPricing, errs?: CommonErrHandlers): Observable<OfferPricing> {
    return from(this.httpClient.put<OfferPricing>(`/companies/${orgId}/offerpricing/${offer.id}`, offer)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public getOfferPricing(orgId: string, offerPricingId: string, errs?: CommonErrHandlers): Observable<OfferPricing> {
    return from(this.httpClient.get<OfferPricing>(`/companies/${orgId}/offerpricing/${offerPricingId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOfferPricingList(orgId: string, offerId: string, errs?: CommonErrHandlers): Observable<OfferPricingList> {
    return from(this.httpClient.get<OfferPricingList>(`/companies/${orgId}/offerpricingreview/${offerId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteOfferPricing(orgId: string, id: string, errs?: CommonErrHandlers): Observable<OfferPricing> {
    return from(this.httpClient.delete<OfferPricing>(`/companies/${orgId}/offerpricing/${id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Events */
  public getUnpublishedChanges(orgId: string, offerId: string, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.get<OfferPricingList>(`/companies/${orgId}/changed/${offerId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* offer validation */
  public validateOffer(orgId: string, offers: Array<string>, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.post<any>(`/companies/${orgId}/publish/offers`, { offers: offers })).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public offerValidation(
    orgId: string,
    dryRun: string,
    offers: Array<string>,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.post<any>(`/companies/${orgId}/offervalidation/${dryRun}/${offers}`, offers)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* Rate card SPR*/
  public storeRateCardSpr(
    orgId: string,
    rateCardId: string,
    sprId: string,
    payload: any,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.put<any>(`/companies/${orgId}/ratecards/${rateCardId}/spr/${sprId}`, payload)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getRateCardSprs(orgId: string, rateCardId: string, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`/companies/${orgId}/ratecards/${rateCardId}/spr`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteRateCardSpr(
    orgId: string,
    rateCardId: string,
    sprId: string,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.get<any>(`/companies/${orgId}/ratecards/${rateCardId}/spr/${sprId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* channel bindings*/
  public getCompanyRates(orgId: string, errs?: CommonErrHandlers): Observable<Array<CompanyRate>> {
    return from(this.httpClient.get<Array<CompanyRate>>(`/companies/${orgId}/chanapi/rates`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getCompanyChannels(orgId: string, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`/companies/${orgId}/chanapi/channels`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getCompanyChannelsBindings(orgId: string, errs?: CommonErrHandlers): Observable<Array<ChannelBinding>> {
    return from(this.httpClient.get<Array<ChannelBinding>>(`/companies/${orgId}/chanapi/bindings`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public postChannelRates(
    orgId: string,
    supplierId: string,
    productId: string,
    channelId: string,
    payload: any,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.post<any>(
        `/companies/${orgId}/chanapi/suppliers/${supplierId}/products/${productId}/channels/${channelId}`,
        payload,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateChannelRates(
    orgId: string,
    supplierId: string,
    productId: string,
    channelId: string,
    payload: any,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.put<any>(
        `/companies/${orgId}/chanapi/suppliers/${supplierId}/products/${productId}/channels/${channelId}`,
        payload,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteChannelRates(
    orgId: string,
    supplierId: string,
    productId: string,
    channelId: string,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.delete<any>(
        `/companies/${orgId}/chanapi/suppliers/${supplierId}/products/${productId}/channels/${channelId}`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  /* Schedule*/
  public updateSchedule(orgId: string, schedule: Schedule, errs?: CommonErrHandlers): Observable<Schedule> {
    return from(this.httpClient.put<Schedule>(`/companies/${orgId}/schedule/${schedule.id}`, schedule)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public getSchedule(orgId: string, scheduleId: string, errs?: CommonErrHandlers): Observable<Schedule> {
    return from(this.httpClient.get<Schedule>(`/companies/${orgId}/schedule/${scheduleId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public getScheduleList(orgId: string, errs?: CommonErrHandlers): Observable<{ data: Array<GRSchedule> }> {
    return from(this.httpClient.get<{ data: Array<GRSchedule> }>(`/companies/${orgId}/schedule`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public updateScheduleDateRange(
    orgId: string,
    schedule: Schedule,
    dateRange: EffectiveDateRange,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.put<any>(`/companies/${orgId}/schedule/${schedule.id}/daterange`, dateRange)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public addScheduleTimeslot(
    orgId: string,
    schedule: Schedule,
    timeSlot: TimeSlot,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.post<any>(`/companies/${orgId}/schedule/${schedule.id}/timeslots`, timeSlot)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public updateScheduleTimeslot(
    orgId: string,
    schedule: Schedule,
    timeSlot: TimeSlot,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.put<any>(`/companies/${orgId}/schedule/${schedule.id}/timeslots/${timeSlot.key}`, timeSlot),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public deleteScheduleTimeslot(
    orgId: string,
    schedule: Schedule,
    timeSlot: TimeSlot,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.delete<any>(`/companies/${orgId}/schedule/${schedule.id}/timeslots/${timeSlot.key}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public deleteSchedule(orgId: string, schedule: Schedule, errs?: CommonErrHandlers): Observable<Schedule> {
    return from(this.httpClient.delete<Schedule>(`/companies/${orgId}/schedule/${schedule.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public addScheduleToProduct(
    orgId: string,
    scheduleId: string,
    productId: string,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.post<any>(`/companies/${orgId}/products/${productId}/schedules/${scheduleId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public productSchedules(orgId: string, productId: string, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`/companies/${orgId}/products/${productId}/schedules`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public deleteProductSchedule(
    orgId: string,
    schedule: Schedule,
    product: Product,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.delete<any>(`/companies/${orgId}/products/${product.id}/schedules/${schedule.id}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public addScheduleToOption(
    orgId: string,
    scheduleId: string,
    optionId: string,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.post<any>(`/companies/${orgId}/options/${optionId}/schedules/${scheduleId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public optionSchedules(orgId: string, optionId: string, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`/companies/${orgId}/options/${optionId}/schedules`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public deleteOptionSchedule(
    orgId: string,
    schedule: Schedule,
    option: Option,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.delete<any>(`/companies/${orgId}/options/${option.id}/schedules/${schedule.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* rate cards*/
  public publishRateCard(orgId: string, rateCardId: string, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.post<any>(`/companies/${orgId}/ratecards/${rateCardId}/publish`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public updateRateCard(orgId: string, rateCard: RateCard, errs?: CommonErrHandlers): Observable<RateCard> {
    return from(this.httpClient.put<RateCard>(`/companies/${orgId}/ratecards/${rateCard.id}`, rateCard)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getRateCard(orgId: string, id: string, errs?: CommonErrHandlers): Observable<RateCard> {
    return from(this.httpClient.get<RateCard>(`/companies/${orgId}/ratecards/${id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public getRateCards(orgId: string, errs?: CommonErrHandlers): Observable<Array<RateCard>> {
    return from(this.httpClient.get<Array<RateCard>>(`/companies/${orgId}/ratecards`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteRateCard(orgId: string, rateCard: RateCard, errs?: CommonErrHandlers): Observable<RateCard> {
    return from(this.httpClient.delete<RateCard>(`/companies/${orgId}/ratecards/${rateCard.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public storeRateCardChannel(
    orgId: string,
    rateCardId: string,
    channelId: string,
    payload: any,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(
      this.httpClient.put<any>(`/companies/${orgId}/ratecards/${rateCardId}/channels/${channelId}`, payload),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getRateCardChannel(
    orgId: string,
    rateCardId: string,
    errs?: CommonErrHandlers,
  ): Observable<Array<RateCardChannel>> {
    return from(this.httpClient.get<any>(`/companies/${orgId}/ratecards/${rateCardId}/channels`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteRateCardChannel(
    orgId: string,
    rateCardId: string,
    channelId: string,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.delete<any>(`/companies/${orgId}/ratecards/${rateCardId}/channels/${channelId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  /* tax and fees*/
  public getTaxesAndFees(orgId: string, errs?: CommonErrHandlers): Observable<Array<Fee>> {
    return from(this.httpClient.get<Array<Fee>>(`/companies/${orgId}/fees`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getTaxesAndFeesById(orgId: string, fee: Fee, errs?: CommonErrHandlers): Observable<Fee> {
    return from(this.httpClient.get<Fee>(`/companies/${orgId}/fees/${fee.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public createTaxesAndFees(orgId: string, fee: Fee, errs?: CommonErrHandlers): Observable<Fee> {
    return from(this.httpClient.put<Fee>(`/companies/${orgId}/fees/${fee.id}`, fee)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public updateTaxesAndFees(orgId: string, fee: Fee, errs?: CommonErrHandlers): Observable<Fee> {
    return from(this.httpClient.put<Fee>(`/companies/${orgId}/fees/${fee.id}`, fee)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public deleteTaxesAndFees(orgId: string, fee: Fee, errs?: CommonErrHandlers): Observable<Fee> {
    return from(this.httpClient.delete<Fee>(`/companies/${orgId}/fees/${fee.id}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getCompanyAddresses(criteria: OppAddressQueryCriteria, errs?: CommonErrHandlers): Observable<Array<Address>> {
    return from(
      this.httpClient.get(`/${constants.endpoints.COMPANIES}/${criteria.companyId}/${constants.endpoints.ADDRESSES}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getCompanyAddressById(
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Array<Address>> {
    return from(
      this.httpClient.get(
        `/${constants.endpoints.COMPANIES}/${criteria.companyId}/${constants.endpoints.ADDRESSES}/${criteria.addressId}`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createCompanyAddress(
    body: Address,
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Address> {
    return from(
      this.httpClient.post(
        `/${constants.endpoints.COMPANIES}/${criteria.companyId}/${constants.endpoints.ADDRESSES}`,
        body,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateCompanyAddress(
    body: Address,
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Address> {
    return from(
      this.httpClient.put(
        `/${constants.endpoints.COMPANIES}/${criteria.companyId}/${constants.endpoints.ADDRESSES}/${criteria.addressId}`,
        body,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteCompanyAddress(
    body: Address,
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<null> {
    return from(
      this.httpClient.delete(
        `/${constants.endpoints.COMPANIES}/${criteria.companyId}/${constants.endpoints.ADDRESSES}/${criteria.addressId}`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOfferDateRanges(criteria: OfferDateRangeReq, errs?: CommonErrHandlers): Observable<EffectiveDateRange> {
    return from(
      this.httpClient.get(
        `/${constants.endpoints.COMPANIES}/${criteria.companyId}/offers/${criteria.offerId}/daterange`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public storeOperatorUser(criteria: OperatorUserReq, errs?: CommonErrHandlers): Observable<OperatorUser> {
    return from(
      this.httpClient.put(
        `/${constants.endpoints.COMPANIES}/${criteria.companyId}/operatoruser/${criteria.userId}`,
        criteria.body,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getOperatorUser(criteria: OperatorUserReq, errs?: CommonErrHandlers): Observable<OperatorUser> {
    return from(
      this.httpClient.get(`/${constants.endpoints.COMPANIES}/${criteria.companyId}/operatoruser/${criteria.userId}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteOperatorUser(criteria: OperatorUserReq, errs?: CommonErrHandlers): Observable<OperatorUser> {
    return from(
      this.httpClient.delete(`/${constants.endpoints.COMPANIES}/${criteria.companyId}/operatoruser/${criteria.userId}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getSupplierAddresses(criteria: OppAddressQueryCriteria, errs?: CommonErrHandlers): Observable<Array<Address>> {
    return from(
      this.httpClient.get(`/${constants.endpoints.SUPPLIERS}/${criteria.supplierId}/${constants.endpoints.ADDRESSES}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createSupplierAddresses(
    body: Address,
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Address> {
    return from(
      this.httpClient.post(
        `/${constants.endpoints.SUPPLIERS}/${criteria.supplierId}/${constants.endpoints.ADDRESSES}`,
        body,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createSupplierAddressesBulk(
    body: Array<Address>,
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Array<Address>> {
    return from(
      this.httpClient.post(
        `/${constants.endpoints.SUPPLIERS}/${criteria.supplierId}/${constants.endpoints.ADDRESSES}`,
        body,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateSupplierAddresses(
    body: Address,
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Address> {
    return from(
      this.httpClient.put(
        `/${constants.endpoints.SUPPLIERS}/${criteria.supplierId}/${constants.endpoints.ADDRESSES}/${criteria.addressId}`,
        body,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteSupplierAddresses(
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Array<Address>> {
    return from(
      this.httpClient.delete(
        `/${constants.endpoints.SUPPLIERS}/${criteria.supplierId}/${constants.endpoints.ADDRESSES}/${criteria.addressId}`,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getProductAddresses(criteria: OppAddressQueryCriteria, errs?: CommonErrHandlers): Observable<Array<Address>> {
    return from(
      this.httpClient.get(`/${constants.endpoints.PRODUCTS}/${criteria.productId}/${constants.endpoints.ADDRESSES}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createProductAddress(
    body: Address,
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Address> {
    return from(
      this.httpClient.post(
        `/${constants.endpoints.PRODUCTS}/${criteria.productId}/${constants.endpoints.ADDRESSES}`,
        body,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateProductAddress(
    body: Address,
    criteria: OppAddressQueryCriteria,
    errs?: CommonErrHandlers,
  ): Observable<Address> {
    return from(
      this.httpClient.put(
        `/${constants.endpoints.PRODUCTS}/${criteria.productId}/${constants.endpoints.ADDRESSES}`,
        body,
      ),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteProductAddress(criteria: OppAddressQueryCriteria, errs?: CommonErrHandlers): Observable<null> {
    return from(
      this.httpClient.delete(`/${constants.endpoints.PRODUCTS}/${criteria.productId}/${constants.endpoints.ADDRESSES}`),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public createAuditLog(rq: AuditRecord, errs?: UpdateProductErrHandlers): Observable<null> {
    rq.orgId = companyModule.Organization?.Name || '';
    rq.actor = profileModule.Profile?.username || '';
    rq.createdAt = new Date().toISOString();
    return from(
      this.httpClient.post(`/companies/${companyModule.Organization?.ID}/${constants.endpoints.AUDIT}`, rq),
    ).pipe(
      map((response) => response.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
}

const inventoryClient = new InventoryClient();
export default inventoryClient;
