import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  AvailabilityType,
  CapacityByUnit,
  CapacityType,
  OfferUnitPrice,
  OppPricing,
  OppPricingDisplay,
  PriceSchedule,
  PricingWithTaxes,
  TaxesAndFees,
} from '@/api/inventory/InventoryModels';
import { productModule } from '@/store/modules/moduleProduct';
import { companyModule } from '@/store/modules/moduleCompany';
import { optionModule } from '@/store/modules/moduleOption';
import moment from 'moment';
import faker from 'faker';
import _ from 'lodash';
import { Address, CapacityPool, DisplayDate, OfferCapacity, Option, Policy, Product } from '@/models';
import { Offer, OfferPricing, OfferPricingList, OptionCapacityPool } from '@/models/ModelsOppInventory';
import { httpModule } from './moduleHttp';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-pricing',
  namespaced: true,
})
class ModulePricing extends VuexModule {
  /* Data */
  private loading = false;
  private productOfferLoading = false;
  private inventoryLoading = false;
  private search = '';
  private prices = [] as Array<PricingWithTaxes>;
  private selectedPrice = {} as PricingWithTaxes;
  private priceSchedules = [] as Array<PriceSchedule>;
  private priceSchedulesByProduct = [] as Array<PriceSchedule>;
  private selectedPriceSchedule = {} as PriceSchedule;
  private opPricing = [] as Array<OppPricing>;
  private opPricingDisplay = [] as Array<OppPricingDisplay>;
  private policies = [] as Array<Policy>;
  private offers = [] as Array<Offer>;
  private nonFreesaleOffers = [] as Array<Offer>;
  private offer = {} as Offer;
  private validatedOffers = [] as Array<Offer>;
  private offerPricing = {} as OfferPricing;
  private offerPricingList = {} as OfferPricingList;
  private offerCapacity = {} as OfferCapacity;
  private editOfferCapacity = {} as OfferCapacity;
  private offerCapacities = {} as Array<OfferCapacity>;
  private offerCapacityList = [] as Array<OfferCapacity>;
  private offersWithCapacity = [] as Array<Offer>;
  private unitPricingList = [] as Array<OfferUnitPrice>;
  private selectedOfferProductId = '';
  private selectedOfferOptionId = '';
  private selectedProductPolicyId = '';
  private selectedOfferPolicy = {} as Policy | undefined;
  private selectedOfferProduct = {} as Product;
  private selectedOfferOption = {} as Option;
  private selectedTnF = [] as Array<TaxesAndFees>;
  private selectedDisplayDate = null as DisplayDate | null;
  private selectedCapacityType = '';
  private selectedCapacityProductOption = '';
  private selectedCapacityPulledFrom = '';
  private selectedCapacityAmount = 0;
  private selectedCapacityByUnits = [] as Array<CapacityByUnit>;
  private selectedOfferCapacityId = '' as string | undefined;
  private createStep1Open = false;
  private createStep2Open = false;
  private createStep3Open = false;
  private createStep4Open = false;
  private createForm1Valid = false;
  private createForm2Valid = false;
  private createForm3Valid = false;
  private createForm4Valid = false;
  private createForm5Valid = false;
  private createStep = 1;
  private editOfferDescription = false;
  private editOfferTicket = false;
  private editOfferName = false;
  private editOfferInventoryType = false;
  private editOfferLocation = false;
  private editOfferPolicy = false;
  private editOfferSchedule = false;
  private editOfferPricing = false;
  private editOfferCalendar = false;
  private optionCapacity = {} as OptionCapacityPool;
  private offerPublished = false;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get InventoryLoading(): boolean {
    return this.inventoryLoading;
  }

  get OfferPublished() {
    return this.offerPublished;
  }

  get OptionCapacityPool() {
    return this.optionCapacity;
  }

  get ProductOfferLoading(): boolean {
    return this.productOfferLoading;
  }

  get Search(): string {
    return this.search;
  }

  get PriceSchedules() {
    return this.priceSchedules;
  }

  get PriceSchedulesByProduct() {
    return this.priceSchedulesByProduct;
  }

  get PriceSchedule() {
    return this.selectedPriceSchedule;
  }

  get Prices() {
    return this.prices;
  }

  get SelectedPrice() {
    return this.selectedPrice;
  }

  get Pricing() {
    return this.opPricing;
  }

  get PricingDisplay() {
    return this.opPricingDisplay;
  }

  get Offers() {
    return this.offers;
  }

  get NonFreesaleOffers() {
    return this.nonFreesaleOffers;
  }

  get ValidatedOffers() {
    return this.validatedOffers;
  }

  get Offer() {
    return this.offer;
  }

  get OfferCapacity() {
    return this.offerCapacity;
  }

  get EditOfferCapacity() {
    return this.editOfferCapacity;
  }

  get OfferCapacities() {
    return this.offerCapacities;
  }

  get OfferCapacityList() {
    return this.offerCapacityList;
  }

  get OffersWithCapacity() {
    return this.offersWithCapacity;
  }

  get OfferPricing() {
    return this.offerPricing;
  }

  get OfferPricings() {
    return this.offerPricingList;
  }

  get Policies() {
    return this.policies;
  }

  get SelectedOfferCapacityId() {
    return this.selectedOfferCapacityId;
  }

  get SelectedOfferPolicy() {
    return this.selectedOfferPolicy;
  }

  get SelectedTaxesAndFees() {
    return this.selectedTnF;
  }

  get SelectedCapacityType() {
    return this.selectedCapacityType;
  }

  get SelectedCapacityAmount() {
    return this.selectedCapacityAmount;
  }

  get SelectedCapacityProductOption() {
    return this.selectedCapacityProductOption;
  }

  get SelectedCapacityPulledFrom() {
    return this.selectedCapacityPulledFrom;
  }

  get SelectedCapacityByUnits() {
    return this.selectedCapacityByUnits;
  }

  get CreateStep1Open() {
    return this.createStep1Open;
  }

  get CreateStep2Open() {
    return this.createStep2Open;
  }

  get CreateStep3Open() {
    return this.createStep3Open;
  }

  get CreateStep4Open() {
    return this.createStep4Open;
  }

  get CreateForm1Valid() {
    return this.createForm1Valid;
  }

  get CreateStep1Done() {
    // return this.CreateStep2Open || this.CreateStep3Open || this.CreateStep4Open;
    return this.createStep > 1;
  }

  get CreateStep2Done() {
    // return this.CreateStep3Open || this.CreateStep4Open;
    return this.createStep > 2;
  }

  get CreateStep3Done() {
    // return this.CreateStep4Open;
    return this.createStep > 3;
  }

  get CreateForm2Valid() {
    return this.createForm2Valid;
  }

  get CreateForm3Valid() {
    return this.createForm3Valid;
  }

  get CreateForm4Valid() {
    return this.createForm4Valid;
  }

  get CreateForm5Valid() {
    return this.createForm5Valid;
  }

  get CreateStep() {
    return this.createStep;
  }

  get SelectedOfferProduct() {
    return this.selectedOfferProduct;
  }

  get SelectedOfferOption() {
    return this.selectedOfferOption;
  }

  get SelectedOfferProductId() {
    return this.selectedOfferProductId;
  }

  get SelectedOfferOptionId() {
    return this.selectedOfferOptionId;
  }

  get SelectedProductPolicyId() {
    return this.selectedProductPolicyId;
  }

  get SelectedDisplayDate() {
    return this.selectedDisplayDate;
  }

  get UnitPricingList() {
    return this.unitPricingList;
  }

  get EditOfferName() {
    return this.editOfferName;
  }

  get EditOfferDescription() {
    return this.editOfferDescription;
  }

  get EditOfferInventoryType() {
    return this.editOfferInventoryType;
  }

  get EditOfferTicketing() {
    return this.editOfferTicket;
  }

  get EditOfferLocation() {
    return this.editOfferLocation;
  }

  get EditOfferPolicy() {
    return this.editOfferPolicy;
  }

  get EditOfferSchedule() {
    return this.editOfferSchedule;
  }

  get EditOfferPricing() {
    return this.editOfferPricing;
  }

  get EditOfferCalendar() {
    return this.editOfferCalendar;
  }

  get HasOfferProductOptionCombo() {
    return (
      this.Offers.findIndex((o) => {
        const option = o.optionId || '';
        const selectedOption = this.SelectedOfferOptionId || '';
        return o.productId === this.SelectedOfferProductId && option === selectedOption;
      }) > -1
    );
  }

  get SelectedProductName() {
    if (this.SelectedOfferProduct && !_.isEmpty(this.SelectedOfferProduct)) {
      const product = productModule.Products.find((product) => product.id === this.SelectedOfferProductId);
      if (product) {
        return product.internalName || '';
      }
    }
    return '';
  }

  get SelectedProductDescription() {
    if (this.SelectedOfferProduct && !_.isEmpty(this.SelectedOfferProduct)) {
      const product = productModule.Products.find((product) => product.id === this.SelectedOfferProductId);
      if (product) {
        return product.description || '';
      }
    }
    return '';
  }

  get SelectedOptionName() {
    if (this.SelectedOfferOption && !_.isEmpty(this.SelectedOfferOption)) {
      const option = optionModule.Options.find((o) => o.id === this.SelectedOfferOptionId);
      if (option) {
        return option.internalName || '';
      }
    }
    return '';
  }

  get SelectedOptionDescription() {
    if (this.SelectedOfferOption && !_.isEmpty(this.SelectedOfferOption)) {
      const option = optionModule.Options.find((o) => o.id === this.SelectedOfferOptionId);
      if (option) {
        return option.description || '';
      }
    }
    return '';
  }

  get TravelerTypes() {
    if (this.SelectedOfferOptionId && optionModule.TravelerTypes.length > 0) {
      return optionModule.TravelerTypes;
    } else if (this.SelectedOfferProductId && productModule.TravelerTypes.length > 0) {
      return productModule.TravelerTypes;
    }

    return [];
  }

  get TravelerTypesForOffer() {
    if (this.offer.optionId && optionModule.TravelerTypes.length > 0) {
      return optionModule.TravelerTypes;
    } else if (this.offer.productId && productModule.TravelerTypes.length > 0) {
      return productModule.TravelerTypes;
    }

    return [];
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setInventoryLoading(loading: boolean) {
    this.inventoryLoading = loading;
  }

  @Mutation
  setOfferPublished(published: boolean) {
    this.offerPublished = published;
  }

  @Mutation
  setOptionCapacityPool(capacity: OptionCapacityPool) {
    this.optionCapacity = capacity;
  }

  @Mutation
  setProductOfferLoading(loading: boolean) {
    this.productOfferLoading = loading;
  }

  @Mutation
  setSearch(search: string) {
    this.search = search;
  }

  @Mutation
  setPriceSchedules(priceSchedules: Array<PriceSchedule>) {
    this.priceSchedules = priceSchedules;
  }

  @Mutation
  setPriceSchedulesByProduct(priceSchedules: Array<PriceSchedule>) {
    this.priceSchedulesByProduct = priceSchedules;
  }

  @Mutation
  setSelectedPriceSchedule(priceSchedule: PriceSchedule) {
    this.selectedPriceSchedule = priceSchedule;
  }

  @Mutation
  setPrices(prices: Array<PricingWithTaxes>) {
    this.prices = prices;
  }

  @Mutation
  setSelectedPrice(price: PricingWithTaxes) {
    this.selectedPrice = price;
  }

  @Mutation
  setOpPricing(pricing: Array<OppPricing>) {
    this.opPricing = pricing;
  }

  @Mutation
  setOpPricingDisplay(pricing: Array<OppPricingDisplay>) {
    this.opPricingDisplay = pricing;
  }

  @Mutation
  setOffers(offers: Array<Offer> | null) {
    if (offers === null) this.offers = [];
    else
      this.offers = offers.map((offer: Offer) => {
        return {
          ...offer,
          open: offer.open ? offer.open : false,
        };
      });
  }

  @Mutation
  setValidatedOffers(offers: Array<Offer>) {
    this.validatedOffers = offers;
  }

  @Mutation
  setNonFreesaleOffers(offers: Array<Offer> | null) {
    if (offers === null) this.nonFreesaleOffers = [];
    else this.nonFreesaleOffers = offers;
  }

  @Mutation
  setOfferCapacityList(capacities: Array<OfferCapacity>) {
    this.offerCapacityList = capacities;
  }

  @Mutation
  setOffersWithCapacity(offers: Array<Offer> | null) {
    if (offers === null) this.offersWithCapacity = [];
    else this.offersWithCapacity = offers;
  }

  @Mutation
  setOffer(offer: Offer) {
    this.offer = offer;
  }

  @Mutation
  setOfferCapacity(capacity: OfferCapacity) {
    this.offerCapacity = capacity;
  }

  @Mutation
  setEditOfferCapacity(capacity: OfferCapacity) {
    this.editOfferCapacity = capacity;
  }

  @Mutation
  setOfferCapacities(capacities: Array<OfferCapacity>) {
    this.offerCapacities = capacities;
  }

  @Mutation
  setOfferPricing(offerPricing: OfferPricing) {
    this.offerPricing = offerPricing;
  }

  @Mutation
  setOfferPricings(offerPricingList: OfferPricingList) {
    if (offerPricingList) {
      if (Object.keys(offerPricingList).length === 0) {
        this.offerPricingList = {} as OfferPricingList;
        return;
      }
      this.offerPricingList = {
        filter: offerPricingList.filter,
        data: offerPricingList.data.map((item) => {
          return {
            ...item,
            open: item.open || false,
          };
        }),
      };
    }
  }

  @Mutation
  setPolicies(policies: Array<Policy>) {
    this.policies = policies;
  }

  @Mutation
  setSelectedOfferPolicy(policy: Policy | undefined) {
    this.selectedOfferPolicy = policy;
  }

  @Mutation
  setSelectedProduct(product: Product) {
    this.selectedOfferProduct = product;
  }

  @Mutation
  setSelectedOption(option: Option) {
    this.selectedOfferOption = option;
  }

  @Mutation
  setSelectedTaxesAndFees(taxes: Array<TaxesAndFees>) {
    this.selectedTnF = taxes;
  }

  @Mutation
  setSelectedDisplayDate(date: DisplayDate | null) {
    this.selectedDisplayDate = date;
  }

  @Mutation
  setSelectedCapacityType(type: string) {
    this.selectedCapacityType = type;
  }

  @Mutation
  setSelectedOfferCapacityId(id: string | undefined) {
    this.selectedOfferCapacityId = id;
  }

  @Mutation
  setSelectedCapacityProductOption(type: string) {
    this.selectedCapacityProductOption = type;
  }

  @Mutation
  setSelectedCapacityPulledFrom(pulledFrom: string) {
    this.selectedCapacityPulledFrom = pulledFrom;
  }

  @Mutation
  setSelectedCapacityAmount(amount: number) {
    this.selectedCapacityAmount = amount;
  }

  @Mutation
  setSelectedCapacityByUnits(capacity: Array<CapacityByUnit>) {
    this.selectedCapacityByUnits = capacity;
  }

  @Mutation
  setCreateStep1Open(active: boolean) {
    this.createStep1Open = active;
  }

  @Mutation
  setCreateStep2Open(active: boolean) {
    this.createStep2Open = active;
  }

  @Mutation
  setCreateStep3Open(active: boolean) {
    this.createStep3Open = active;
  }

  @Mutation
  setCreateStep4Open(active: boolean) {
    this.createStep4Open = active;
  }

  @Mutation
  setCreateForm1Valid(active: boolean) {
    this.createForm1Valid = active;
  }

  @Mutation
  setCreateForm2Valid(active: boolean) {
    this.createForm2Valid = active;
  }

  @Mutation
  setCreateForm3Valid(active: boolean) {
    this.createForm3Valid = active;
  }

  @Mutation
  setCreateForm4Valid(active: boolean) {
    this.createForm4Valid = active;
  }

  @Mutation
  setCreateForm5Valid(active: boolean) {
    this.createForm5Valid = active;
  }

  @Mutation
  setCreateStep(step: number) {
    this.createStep = step;
  }

  @Mutation
  setSelectedOfferProductId(id: string) {
    this.selectedOfferProductId = id;
  }

  @Mutation
  setSelectedOfferOptionId(id: string) {
    this.selectedOfferOptionId = id;
  }

  @Mutation
  setSelectedProductPolicyId(id: string) {
    this.selectedProductPolicyId = id;
  }

  @Mutation
  setOfferUnitPricing(unitPricingList: Array<OfferUnitPrice>) {
    this.unitPricingList = unitPricingList;
  }

  @Mutation
  setEditOfferName(status: boolean) {
    this.editOfferName = status;
  }

  @Mutation
  setEditOfferDescription(status: boolean) {
    this.editOfferDescription = status;
  }

  @Mutation
  setEditOfferInventoryType(status: boolean) {
    this.editOfferInventoryType = status;
  }

  @Mutation
  setEditOfferTicketing(status: boolean) {
    this.editOfferTicket = status;
  }

  @Mutation
  setEditOfferLocation(status: boolean) {
    this.editOfferLocation = status;
  }

  @Mutation
  setEditOfferPolicy(status: boolean) {
    this.editOfferPolicy = status;
  }

  @Mutation
  setEditOfferSchedule(status: boolean) {
    this.editOfferSchedule = status;
  }

  @Mutation
  setEditOfferPricing(status: boolean) {
    this.editOfferPricing = status;
  }

  @Mutation
  setEditOfferCalendar(status: boolean) {
    this.editOfferCalendar = status;
  }

  /* Actions */
  @Action
  getPriceSchedule(id: string) {
    const schedule = this.PriceSchedules.find((priceSchedule: PriceSchedule) => priceSchedule.id === id);
    if (schedule) {
      this.setSelectedPriceSchedule(schedule);
    }
  }

  @Action
  mapOpPricingDisplay() {
    if (this.opPricing && Array.isArray(this.opPricing)) {
      this.opPricing.forEach((pricing) => {
        const companyName = companyModule.Company.name;
        const product = productModule.Products.find((pp) => pp.id === pricing.productId);
        const p = {
          id: pricing.id,
          amount: pricing.amount,
          appliedTaxesFees: pricing.appliedTaxesFees,
          effectiveDateRange: pricing.effectiveDateRange,
          level: pricing.level,
          orgId: pricing.orgId,
          productId: pricing.productId,
          productName: product && product.internalName ? product.internalName : '',
          supplierId: pricing.supplierId,
          supplierName: companyName,
          timeslots: pricing.timeslots,
          unitIds: pricing.unitIds,
        } as OppPricingDisplay;

        if (pricing.optionId) {
          p.optionId = pricing.optionId;
          const option = optionModule.Options.find((o) => o.id === pricing.optionId);
          p.optionName = option && option.internalName ? option.internalName : '';
        }

        p.displayName = `${p.productName} ${p.optionName ? `+ ${p.optionName}` : ''}`;
        p.displayDateRange = `${moment(p.effectiveDateRange.from).format('MM/DD/YYYY')} ${
          p.effectiveDateRange.until ? ` - ${moment(p.effectiveDateRange.until).format('MM/DD/YYYY')}` : '- Ongoing'
        }`;

        this.opPricingDisplay.push(p);
      });
    }
  }

  @Action
  appendOffer(offer: Offer) {
    this.offers.push(offer);
  }

  @Action
  chooseOffer(offer: Offer) {
    this.setOffer(offer);
  }

  @Action
  removeOffer(id: string) {
    const i = this.offers.findIndex((offer: Offer) => offer.id === id);
    if (i > -1) {
      this.offers.splice(i, 1);
    }
  }

  @Action
  updateOffer(offer: Offer) {
    // todo http call to update offer
    const i = this.offers.findIndex((o: Offer) => o.id === offer.id);
    this.offers[i] = offer;
    this.chooseOffer(offer);
  }

  @Action
  appendOfferUnitPricing(unitPricing: OfferUnitPrice) {
    this.unitPricingList.push(unitPricing);
  }

  @Action
  removeOfferUnitPricing(id: string) {
    const i = this.unitPricingList.findIndex((unitPricing: OfferUnitPrice) => unitPricing.unitId === id);
    if (i > -1) {
      this.offers.splice(i, 1);
    }
  }

  @Action
  removeOfferWithCapacity(id: string) {
    const i = this.offersWithCapacity.findIndex((o) => o.id === id);
    if (i > -1) {
      this.offersWithCapacity.splice(i, 1);
    }
  }

  @Action
  appendOfferCapacities(capacity: OfferCapacity) {
    this.offerCapacities.push(capacity);
  }

  @Action
  removeOfferCapacities(capacity: OfferCapacity) {
    const i = this.offerCapacities.findIndex((c: OfferCapacity) => c.id === capacity.id);
    if (i > -1) {
      this.offerCapacities.splice(i, 1);
    }
  }

  @Action
  clearOfferCapacities() {
    this.offerCapacities = [];
  }

  @Action
  getOffer(id: string) {
    const offer = this.Offers.find((o) => o.id === id);
    if (offer) {
      this.setOffer(offer);
      // productModule.selectedProduct(offer.productId);
      if (offer.optionId) {
        optionModule.selectedOption(offer.optionId);
      }
    }
  }

  @Action
  attachAddressesToOffers() {
    this.startLoading();
    let offers = [] as Array<Offer>;
    const { Products } = productModule;
    const { Addresses } = companyModule;
    offers = this.Offers.map((offer: Offer) => {
      const product = Products.find((p: Product) => p.id === offer.productId);
      const address = Addresses.find((a: Address) => a.id === product?.addressId);
      offer.addressName = `${address?.name}: ${address?.streetAddress}, ${address?.city} ${address?.region}`;
      return offer;
    });
    this.setOffers(offers);
    this.finishLoading();
  }

  @Action
  chooseSelectedOfferPolicy(policy: Policy | undefined) {
    this.setSelectedOfferPolicy(policy);
  }

  @Action
  chooseSelectedOfferProduct() {
    const product = productModule.Products.find((pp) => pp.id === this.SelectedOfferProductId);
    if (product) {
      this.setSelectedProduct(product);
    }
  }

  @Action
  chooseSelectedOfferOption() {
    const option = optionModule.Options.find((o) => o.id === this.SelectedOfferOptionId);
    if (option) {
      this.setSelectedOption(option);
    }
  }

  @Action
  async getTravelerTypesForSelectedProductOrOption() {
    if (this.selectedOfferOptionId) {
      await httpModule.getOptionUnitTravelerTypes(this.selectedOfferOptionId);
    }
    if (this.selectedOfferProductId) {
      await httpModule.getProductUnitTravelerTypes(this.selectedOfferProductId);
    }
  }

  @Action
  async loadOfferTravelerTypes() {
    if (this.offer.optionId) {
      await httpModule.getOptionUnitTravelerTypes(this.offer.optionId);
    }
    if (this.offer.productId) {
      await httpModule.getProductUnitTravelerTypes(this.offer.productId);
    }
  }

  @Action
  configurePolicies() {
    const policies = [] as Array<Policy>;
    const product = productModule.Products.find((product) => product.id === this.SelectedOfferProductId);
    if (product && product.policy) {
      const p = product;
      const noProductPolicy =
        p.policy?.refundable === undefined && p.policy?.cancellable === undefined && p.policy?.cutOffTime === undefined;
      if (!noProductPolicy) {
        policies.push({
          id: p.id,
          isProduct: true,
          cancellable: p.policy?.cancellable || false,
          refundable: p.policy?.refundable || false,
          cutOffTime: p.policy?.cutOffTime || 0,
        });
      }
    }

    const option = optionModule.Options.find((o) => o.id === this.SelectedOfferOptionId);
    if (option && option.policy) {
      const o = option.policy;
      const noOptionPolicy = o.refundable === undefined && o.cancellable === undefined && o.cutOffTime === undefined;
      if (!noOptionPolicy) {
        policies.push({
          id: option.id || faker.datatype.uuid(),
          cancellable: o.cancellable || false,
          refundable: o.refundable || false,
          cutOffTime: o.cutOffTime || 0,
        });
      }
    }
    this.setPolicies(policies);
  }

  @Action
  appendTaxAndFee(taxAndFee: TaxesAndFees) {
    this.selectedTnF.push(taxAndFee);
  }

  @Action
  removeTaxAndFee(taxAndFee: TaxesAndFees) {
    const i = this.selectedTnF.findIndex((tax: TaxesAndFees) => tax.id === taxAndFee.id);
    if (i > -1) {
      this.selectedTnF.splice(i, 1);
    }
  }

  @Action
  selectDisplayDate(date: DisplayDate) {
    this.setSelectedDisplayDate(date);
  }

  @Action
  chooseCapacityType() {
    try {
      const productCapacity =
        productModule.ProductCapacityPool && productModule.ProductCapacityPool.capacityPool
          ? productModule.ProductCapacityPool.capacityPool
          : ({} as CapacityPool);
      const optionCapacity =
        optionModule.OptionCapacityPool && optionModule.OptionCapacityPool.capacityPool
          ? optionModule.OptionCapacityPool.capacityPool
          : ({} as CapacityPool);

      let productType = '';
      let optionType = '';
      let productAmount = 0;
      let optionAmount = 0;
      const productByUnitAmount = [] as Array<CapacityByUnit>;
      const optionByUnitAmount = [] as Array<CapacityByUnit>;

      if (_.isObjectLike(productCapacity.byUnit) && !_.isEmpty(productCapacity.byUnit)) {
        const keys = _.keys(productCapacity.byUnit);
        for (const key of keys) {
          const v = _.get(productCapacity.byUnit, key);
          const value = _.toNumber(v) || 0;
          // productAmount += value;
          productByUnitAmount.push({ id: key, amount: value } as CapacityByUnit);
        }
        productType = CapacityType.CapacityLimitByUnit;
      } else if (productCapacity.pooled && _.isNumber(productCapacity.pooled) && productCapacity.pooled > 0) {
        productAmount = productCapacity.pooled;
        productType = CapacityType.CapacityLimitPooled;
      } else {
        productAmount = -1;
        productType = CapacityType.CapacityLimitUnlimited;
      }
      if (_.isObjectLike(optionCapacity.byUnit) && !_.isEmpty(optionCapacity.byUnit)) {
        const keys = _.keys(optionCapacity.byUnit);
        for (const key of keys) {
          const v = _.get(optionCapacity.byUnit, key);
          const value = _.toNumber(v) || 0;
          // optionAmount += value;
          optionByUnitAmount.push({ id: key, amount: value } as CapacityByUnit);
        }
        optionType = CapacityType.CapacityLimitByUnit;
      } else if (optionCapacity.pooled && _.isNumber(optionCapacity.pooled) && optionCapacity.pooled > 0) {
        optionAmount = optionCapacity.pooled;
        optionType = CapacityType.CapacityLimitPooled;
      } else if (optionCapacity.unlimited) {
        optionAmount = -1;
        optionType = CapacityType.CapacityLimitUnlimited;
      }

      if (optionAmount > 0) {
        this.setSelectedCapacityAmount(optionAmount);
        this.setSelectedCapacityProductOption('option');
        this.setSelectedCapacityPulledFrom('option');
      } else {
        this.setSelectedCapacityAmount(productAmount);
        this.setSelectedCapacityProductOption('product');
        this.setSelectedCapacityPulledFrom('product');
      }

      if (optionByUnitAmount.length > 0) {
        this.setSelectedCapacityByUnits(optionByUnitAmount);
        this.setSelectedCapacityProductOption('option');
        this.setSelectedCapacityPulledFrom('option');
      } else {
        this.setSelectedCapacityByUnits(productByUnitAmount);
        this.setSelectedCapacityProductOption('product');
        this.setSelectedCapacityPulledFrom('product');
      }

      if (productType === optionType) {
        this.setSelectedCapacityType(productType);
      } else {
        if (productType === CapacityType.CapacityLimitByUnit || optionType === CapacityType.CapacityLimitByUnit) {
          this.setSelectedCapacityType(CapacityType.CapacityLimitByUnit);
        } else if (
          productType === CapacityType.CapacityLimitPooled ||
          optionType === CapacityType.CapacityLimitPooled
        ) {
          this.setSelectedCapacityType(CapacityType.CapacityLimitPooled);
        } else {
          this.setSelectedCapacityType(CapacityType.CapacityLimitUnlimited);
        }
      }
    } catch (e) {
      // todo send alert to backend
    }
  }

  @Action
  filterNonFreesaleOffers() {
    try {
      this.Offers.forEach((offer) => {
        const product = productModule.Products.find((p) => p.id === offer.productId);
        const option = optionModule.Options.find((o) => o.id === offer.optionId);

        let productType = undefined;
        let optionType = undefined;
        if (product) {
          productType = product.availabilityType;
        }
        if (option) {
          optionType = option.availabilityType;
        }
        offer.isLimitedCapacity =
          productType === AvailabilityType.StartTime || optionType === AvailabilityType.StartTime;
      });
      this.setNonFreesaleOffers(this.Offers.filter((o) => o.isLimitedCapacity === true));
    } catch (e) {
      // todo send alert to backend
    }
  }

  @Action
  clearDisplayDate() {
    this.setSelectedDisplayDate(null);
  }

  @Action
  nextCreateStep() {
    const step = this.CreateStep + 1;
    this.setCreateStep(step);
  }

  @Action
  toggleEditOfferName() {
    this.setEditOfferName(!this.EditOfferName);
  }

  @Action
  toggleEditOfferDescription() {
    this.setEditOfferDescription(!this.EditOfferDescription);
  }

  @Action
  toggleEditOfferInventoryType() {
    this.setEditOfferInventoryType(!this.EditOfferInventoryType);
  }

  @Action
  toggleEditOfferTicketing() {
    this.setEditOfferTicketing(!this.EditOfferTicketing);
  }

  @Action
  toggleEditOfferLocation() {
    this.setEditOfferLocation(!this.EditOfferLocation);
  }

  @Action
  toggleEditOfferPolicy() {
    this.setEditOfferPolicy(!this.EditOfferPolicy);
  }

  @Action
  toggleEditOfferSchedule() {
    this.setEditOfferSchedule(!this.EditOfferSchedule);
  }

  @Action
  toggleEditOfferPricing() {
    this.setEditOfferPricing(!this.EditOfferPricing);
  }

  @Action
  toggleEditOfferCalendar() {
    this.setEditOfferCalendar(!this.EditOfferCalendar);
  }

  @Action
  resetOfferState() {
    this.setOffer({} as Offer);
    this.setCreateStep(1);
    this.setCreateStep1Open(true);
    this.setCreateStep2Open(false);
    this.setCreateStep3Open(false);
    this.setCreateStep4Open(false);
    this.setCreateForm1Valid(false);
    this.setCreateForm2Valid(false);
    this.setCreateForm3Valid(false);
    this.setCreateForm4Valid(false);
    this.setEditOfferName(false);
    this.setEditOfferDescription(false);
    this.setEditOfferInventoryType(false);
    this.setEditOfferTicketing(false);
    this.setEditOfferLocation(false);
    this.setEditOfferPolicy(false);
    this.setEditOfferSchedule(false);
    this.setEditOfferPricing(false);
    this.setPolicies([] as Array<Policy>);
    this.setSelectedProductPolicyId('');
    this.setSelectedOfferProductId('');
    this.setSelectedOfferOptionId('');
    this.setSelectedProduct({} as Product);
    this.setSelectedOption({} as Option);
    this.setOfferCapacities([] as Array<OfferCapacity>);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setProductOfferLoading(false);
    this.setInventoryLoading(false);
    this.setSearch('');
    this.setPriceSchedules([]);
    this.setPriceSchedulesByProduct([]);
    this.setSelectedPriceSchedule({} as PriceSchedule);
    this.setOpPricing([]);
    this.setOpPricingDisplay([]);
    this.setOffers([]);
    this.setOffer({} as Offer);
    this.resetOfferState();
    this.setEditOfferCapacity({} as OfferCapacity);
    this.setOfferPricings({} as OfferPricingList);
    this.setOfferPricing({} as OfferPricing);
    this.setSelectedCapacityProductOption('');
    this.setSelectedCapacityPulledFrom('');
    this.setSelectedCapacityAmount(0);
    this.setSelectedCapacityType('');
    this.setSelectedDisplayDate(null);
    this.setSelectedProduct({} as Product);
    this.setSelectedOption({} as Option);
    this.setSelectedProductPolicyId('');
    this.setSelectedOfferOptionId('');
    this.setSelectedOfferOptionId('');
    this.setSelectedOfferProductId('');
    this.setSelectedOfferPolicy(undefined);
    this.setOfferCapacities([] as Array<OfferCapacity>);
  }
}

export const pricingModule = getModule(ModulePricing, store);
