









import { Component, Vue, Prop } from 'vue-property-decorator';
import { ConnectionStatus, ProductStatus } from '@/models';

@Component
export default class RdmStatusIndicator extends Vue {
  @Prop({ type: [String, Number], required: false })
  public status!: string | number;

  get color() {
    switch (this.status) {
      case ProductStatus.ACTIVE || ConnectionStatus.ACTIVE:
        return this.$vuetify.theme.themes.light.success;
      case ProductStatus.PENDING:
        return this.$vuetify.theme.themes.light.warning;
      case ProductStatus.DRAFT:
        return this.$vuetify.theme.themes.light.blue;
      case ConnectionStatus.NOT_CONNECTED:
        return this.$vuetify.theme.themes.light.black;
      case ProductStatus.INACTIVE:
      case ConnectionStatus.DEACTIVATED:
        return this.$vuetify.theme.themes.light.error;
      default:
        return this.$vuetify.theme.themes.light.inactive;
    }
  }

  get statusName() {
    switch (this.status) {
      case ProductStatus.ACTIVE || ConnectionStatus.ACTIVE:
        return 'ACTIVE';
      case ProductStatus.PENDING:
        return 'PENDING';
      case ProductStatus.DRAFT:
        return 'DRAFT';
      case ConnectionStatus.NOT_CONNECTED:
        return 'NOT CONNECTED';
      case ProductStatus.INACTIVE:
      case ConnectionStatus.DEACTIVATED:
      default:
        return 'UNKNOWN';
    }
  }
}
