




























































































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import { channelModule } from '@/store/modules/moduleChannel';
import { requiredFieldRules } from '@/utils/validation-rules';
import { incrementMeta } from '@/utils/helpers';
import _ from 'lodash';
import { format } from 'date-fns';
import { httpModule } from '@/store/modules/moduleHttp';

@Component
export default class RateCardDetailsView extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private editModal = false;
  private startDateMenu = false;
  private endDateMenu = false;
  private validFrom = '';
  private validUntil = '';

  mounted() {
    this.validFrom = this.RateCard.validFrom;
    this.validUntil = this.RateCard.validUntil ? this.RateCard.validUntil : '';
  }

  async saveChanges() {
    const updatedRateCard = {
      ...this.RateCard,
      validFrom: this.validFrom,
      validUntil: this.validUntil,
      meta: incrementMeta(this.RateCard.meta),
      version: this.RateCard.meta?.version ? this.RateCard.meta.version + 1 : 1,
    };
    await httpModule.updateRateCard(updatedRateCard);
    this.editModal = false;
  }

  openEditModal() {
    this.editModal = true;
  }

  closeEditModal() {
    this.editModal = false;
  }

  get Product() {
    return productModule.Product;
  }

  get RateCard() {
    return channelModule.RateCard;
  }

  get DateFromFormatted() {
    if (
      this.validFrom &&
      this.validFrom.length > 0 &&
      _.isDate(new Date(this.validFrom))
    ) {
      const date = new Date(this.validFrom);
      return format(date, 'MM/dd/yyyy');
    }
    return '';
  }

  get DateUntilFormatted() {
    if (
      this.validUntil &&
      this.validUntil.length > 0 &&
      _.isDate(new Date(this.validUntil))
    ) {
      const date = new Date(this.validUntil);
      return format(date, 'MM/dd/yyyy');
    }
    return '';
  }
}
