






import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  mounted() {
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) appLoading.style.display = 'none';
  }
}
