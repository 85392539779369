















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RdmSidebarGroup extends Vue {
  @Prop({ default: null })
  public title!: string;

  @Prop({ default: null })
  public lang!: string;

  @Prop()
  public icon!: string;

  @Prop({ default: '#' })
  public href!: string;

  @Prop({ default: null })
  public to!: string;

  @Prop({ default: null })
  public slug!: string;

  @Prop({ default: [String, Number] })
  public index!: string;

  @Prop({ default: '_self' })
  public target!: string;

  @Prop({ default: false })
  public isDisabled!: boolean;

  public activeLink = false;

  private showItems = false;

  get active() {
    return {
      activeState: this.activeLink,
      inactiveState: !this.activeLink,
    };
  }

  toggleItems() {
    this.showItems = !this.showItems;
  }
}
