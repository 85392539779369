import faker from 'faker';
import { addDays, randomTime } from '@/utils/helpers';
import {
  demoChannels,
  demoLocationNames,
  demoProductNames,
  demoProductOptionSuffixes,
} from '@/utils/demoData';

export class BookingReference {
  tourDate = '';
  bookingDate = '';
  channel = '';
  bookingID = '';
  product = '';
  leadTraveler = '';
  timeRedeemed = '';
  netCost = '';
  retailCost = '';
}

/**
 * BookingsReport - the response from the reporting service containing the matching booking report data
 */
export class BookingsReport {
  totalBookings: number;
  totalRedemptions: number;
  totalNetSales: number;
  totalGrossSales: number;
  items: BookingReport[];
}

/**
 * BookingReport - contains the report data about a single booking
 */
export class BookingReport {
  bookingID: string;
  bookingStatus: string;
  bookingDate: string;
  arrivalDate: string;
  resellerBookingRef: string;
  supplierBookingRef: string;
  bookingAgentName: string;
  bookingAgentEmail: string;
  organization: string;
  organizationID: string;
  channel: string;
  channelID: string;
  travelerName: string;
  travelerPhone: string;
  travelerEmail: string;
  travelerCount: number;
  totalNetSales: number;
  totalGrossSales: number;
  currency: string;
  timeRedeemed: string;
  totalRedemptions: number;
  items: BookingItem[];
  tickets: BookingTicket[];
}

/**
 * BookingItem - contains report data about each booking line item
 */
export class BookingItem {
  supplier: string;
  supplierID: string;
  product: string;
  productID: string;
  rate: string;
  rateID: string;
  checkInTime: string;
  quantity: number;
  travelerType: string;
  netSales: number;
  grossSales: number;
  currency: string;
  timeRedeemed: string;
  redemptionCount: number;
}

/**
 * BookingTicket - contains the ticket information for the booking
 */
export class BookingTicket {
  barcodeValue: string;
  barcodeFormat: string;
  status: string;
  guests: TicketGuest[];
}

/**
 * TicketGuest - contains the guest type and quantity associated to a ticket
 * @param bookings
 */
export class TicketGuest {
  quantity: number;
  travelerType: string;
}

export class BookingsReportFilter {
  orgID = '';
  supplierID = '';
  productID = '';
  target = '';
  channelID = '';
  start = '';
  end = '';
  arrivalStart = '';
  arrivalEnd = '';

  isEmpty() {
    return Object.values(this).every((x) => x === null || x === '');
  }
}

export class CustomerManifest {
  tourDate = '';
  channel = '';
  bookingID = '';
  productOption = '';
  leadTraveler = '';
  timeSlot = '';
  netCost = '';
}

export function getReportChannels(): string[] {
  return demoChannels;
}

export function getReportLocations(): string[] {
  return demoLocationNames;
}

export function getReportProducts(): string[] {
  return demoProductNames;
}

export function getReportProductOptions(): string[] {
  return demoProductOptionSuffixes;
}

export function generateBookingReference(): BookingReference {
  const br: BookingReference = new BookingReference();
  const d: Date = faker.date.past();
  br.bookingDate = d.toISOString().substr(0, 10);
  br.tourDate = addDays(d, faker.datatype.number({ min: 1, max: 30 }))
    .toISOString()
    .substr(0, 10);
  br.channel = faker.random.arrayElement(getReportChannels());
  br.bookingID = faker.datatype.uuid();
  br.product = faker.random.arrayElement(getReportProducts());
  br.leadTraveler = faker.name.findName();
  br.timeRedeemed = addDays(d, faker.datatype.number({ min: 31, max: 50 })).toISOString();
  br.netCost = faker.commerce.price(20, 1000, 2, '$');
  br.retailCost = faker.commerce.price(20, 1000, 2, '$');
  return br;
}

export function generateBookingReferences(): BookingReference[] {
  const data: BookingReference[] = [];
  for (let i = 0; i < faker.datatype.number({ min: 20, max: 25 }); i++) {
    data.push(generateBookingReference());
  }
  return data;
}

export function generateCustomerManifest(product: string): CustomerManifest {
  const cm: CustomerManifest = new CustomerManifest();
  const d: Date = faker.date.past();
  cm.tourDate = d.toISOString().substr(0, 10);
  cm.channel = faker.random.arrayElement(getReportChannels());
  cm.bookingID = faker.datatype.uuid();
  cm.productOption = `${product} ${faker.random.arrayElement(getReportProductOptions())}`;
  cm.leadTraveler = faker.name.findName();
  cm.timeSlot = randomTime();
  cm.netCost = faker.commerce.price(20, 1000, 2, '$');
  return cm;
}

export function generateCustomerManifests(product: string): CustomerManifest[] {
  const data: CustomerManifest[] = [];
  for (let i = 0; i < faker.datatype.number({ min: 5, max: 12 }); i++) {
    data.push(generateCustomerManifest(product));
  }
  return data;
}
