export enum AlertType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

export type StringI18n = string | [string, boolean];
export interface AlertOptions {
  type: AlertType;
  title?: string;
  disappearing?: boolean;
}
export type NewAlertParams = StringI18n[] | [AlertOptions, ...StringI18n[]];

export class Alert {
  private type = AlertType.ERROR;
  private message: StringI18n[];
  private title?: string;
  private disappearing = false;

  constructor(...params: NewAlertParams) {
    if ((params[0] as any)?.type) {
      this.message = params.slice(1) as StringI18n[];
      const opts = params[0] as AlertOptions;
      this.type = opts.type;
      this.title = opts.title;
      this.disappearing = opts.disappearing ?? false;
    } else {
      this.message = params as StringI18n[];
      this.type = AlertType.ERROR;
    }
  }

  public withType(type: AlertType): Alert {
    this.type = type;
    return this;
  }
  public withTitle(title: string): Alert {
    this.title = title;
    return this;
  }
  public disappear(): Alert {
    this.disappearing = true;
    return this;
  }

  public get Type(): AlertType {
    return this.type;
  }
  public get Message(): StringI18n[] {
    return this.message;
  }
  public get Title(): string | undefined {
    return this.title;
  }
  public get Disappearing(): boolean {
    return this.disappearing;
  }
}
