








































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import MapBox from '@/components/MapBox.vue';
import { appModule } from '@/store/modules/moduleApp';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import _ from 'lodash';
import {
  Address,
  Company,
  Contact,
  ContactEmail,
  ContactPhone,
  MessageType,
} from '@/models';
import {
  emailRules,
  requiredFieldRules,
  requiredStateRules,
} from '@/utils/validation-rules';
import faker from 'faker';
import { OrganizationRS } from '@/api/wps/WpsModels';
import {
  findCountryName,
  handleKeyDownPreventDefault,
  handleKeyDownValidPhoneCharacter,
} from '@/utils/helpers';
import { profileModule } from '@/store/modules/moduleProfile';

@Component({
  components: {
    MapBox,
  },
})
export default class CompanyAbout extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private emailFieldRules = emailRules;
  private requiredState = requiredStateRules;
  private handleValidPhoneCharacters = handleKeyDownValidPhoneCharacter;
  private handleKeyDownPreventDefault = handleKeyDownPreventDefault;
  private findCountryName = findCountryName;
  private companyName = '';
  private companyWebsite = '';
  private contact = {} as Contact;
  private email = {} as ContactEmail;
  private phone = {} as ContactPhone;
  private address = {} as Address;
  private role = '';
  private resultList = [] as google.maps.places.PlaceResult[];
  private selectedResult = {} as google.maps.places.PlaceResult;
  private showDialog = false;
  private companyNameValid = false;
  private companyDetailsValid = false;
  private savingCompanyName = false;
  private savingCompanyDetails = false;

  mounted() {
    this.initializeData();
  }

  initializeData() {
    this.companyName = _.cloneDeep(this.Company.name);
    this.companyWebsite = _.cloneDeep(this.Company.website);

    this.email =
      this.PrimaryContact.emails && this.PrimaryContact.emails.length > 0
        ? _.cloneDeep(this.PrimaryContact.emails[0])
        : ({} as ContactEmail);
    this.phone =
      this.PrimaryContact.phones && this.PrimaryContact.phones.length > 0
        ? _.cloneDeep(this.PrimaryContact.phones[0])
        : ({} as ContactPhone);
    this.address =
      this.PrimaryContact.addresses && this.PrimaryContact.addresses.length > 0
        ? _.cloneDeep(this.PrimaryContact.addresses[0])
        : ({} as Address);
    this.contact =
      this.PrimaryContact && this.PrimaryContact.id && this.PrimaryContact.id.length > 0
        ? this.PrimaryContact
        : ({} as Contact);
    if (this.contact.role) {
      this.role = this.contact.role;
    }
  }

  loadCompany() {
    httpModule.getCompany();
  }

  async validateCompanyName() {
    await this.configCompany();
  }

  toggleCompanyDetails() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeCompanyDetails();
  }

  toggleCompanyName() {
    if (!this.IsOrgAdmin) {
      return;
    }
    companyModule.toggleEditModeCompanyName();
  }

  cancelCompanyName() {
    this.companyName = _.cloneDeep(this.Company.name);
    this.companyWebsite = _.cloneDeep(this.Company.website);
    companyModule.toggleEditModeCompanyName();
  }

  cancelCompanyAddress() {
    this.address =
      this.PrimaryContact.addresses && this.PrimaryContact.addresses.length > 0
        ? _.cloneDeep(this.PrimaryContact.addresses[0])
        : ({} as Address);
    // companyModule.setEditModeCompanyAddress(false);
  }

  cancelCompanyEdit() {
    this.cancelCompanyAddress();
    this.cancelCompanyContact();
    companyModule.setEditModeCompanyDetails(false);
  }

  async validateCompanyEdit() {
    if (this.$refs?.companyAboutDetailsForm) {
      const form: any = this.$refs.companyAboutDetailsForm;
      const isValid = form.validate();
      if (isValid) {
        await this.configLatLong();
        companyModule.setEditModeCompanyDetails(false);
      }
    }
  }

  cancelCompanyContact() {
    this.contact =
      this.PrimaryContact && this.PrimaryContact.id && this.PrimaryContact.id.length > 0
        ? this.PrimaryContact
        : ({} as Contact);
    // companyModule.setEditModeCompanyContact(false);
  }

  async configCompany() {
    if (this.$refs?.companyAboutNameForm) {
      const form: any = this.$refs.companyAboutNameForm;
      const isValid = form.validate();
      if (isValid) {
        this.savingCompanyName = true;
        const orgId = this.Org && this.Org.ID ? this.Org.ID : faker.datatype.uuid();
        const company = {
          orgId: orgId,
          name: this.companyName,
          website: this.companyWebsite,
          meta: _.cloneDeep(this.Company.meta),
        } as Company;
        companyModule.configCompany(company);
        httpModule
          .updateCompany()
          .then(() => {
            // this.updateContact();
            // this.toggleCompanyName();
          })
          .catch()
          .finally(() => {
            this.savingCompanyName = false;
            companyModule.setEditModeCompanyName(false);
          });
      }
    }
  }

  convertAddressToString() {
    return `${this.address.streetAddress}, ${this.address.city}, ${this.address.region}`;
  }

  selectConfigLatLong() {
    this.showDialog = false;
    this.address.latitude = this.selectedResult.geometry?.location?.lat() || 0;
    this.address.longitude = this.selectedResult.geometry?.location?.lng() || 0;
    this.configCompany();
  }

  async configLatLong() {
    try {
      if (window.google && window.google.maps) {
        const query = this.convertAddressToString();
        if (!query) return;
        const request = {
          query,
          fields: ['name', 'geometry'],
        };
        const service = new window.google.maps.places.PlacesService(
          this.$refs['placeService'] as HTMLDivElement,
        );
        await service.findPlaceFromQuery(
          request,
          (
            results: google.maps.places.PlaceResult[] | null,
            status: google.maps.places.PlacesServiceStatus,
          ) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
              this.resultList = results;
              if (this.resultList.length > 1) {
                this.showDialog = true;
              } else if (
                results &&
                results[0] &&
                results[0].geometry &&
                results[0].geometry.location
              ) {
                this.address.latitude = results[0].geometry?.location.lat();
                this.address.longitude = results[0].geometry?.location.lng();
                // this.configCompany();
                this.updateContact();
              } else {
                appModule.addRdmMessage({
                  id: faker.datatype.uuid(),
                  active: true,
                  message: {
                    code: 99999,
                    text: 'Location Not found',
                    type: MessageType.ERROR,
                  },
                  permanent: false,
                });
              }
            }
          },
        );
      }
    } catch {
      this.address.latitude = 0;
      this.address.longitude = 0;
    }
  }

  updateContact() {
    if (this.email && this.email.email && this.email.email.length > 0) {
      this.email.type = 'PRIMARY';
      this.contact.emails = [this.email];
    }

    if (this.phone && this.phone.number && this.phone.number.length > 0) {
      this.phone.type = 'PRIMARY';
      this.contact.phones = [this.phone];
    }

    if (
      this.address &&
      this.address.streetAddress &&
      this.address.streetAddress.length > 0
    ) {
      this.address.id = faker.datatype.uuid();
      this.address.version = 1;
      this.contact.addresses = [this.address];
    }

    if (!this.contact.id || (this.contact.id && this.contact.id.length === 0)) {
      this.contact.id = faker.datatype.uuid();
    }

    if (this.role && this.role.length > 0) {
      this.contact.role = this.role;
    }

    this.contact.primary = true;

    httpModule.updateContact(this.contact).then(() => {
      // this.$router.push({ name: constants.routes.COMPANY }).catch();
    });
  }

  get Loading() {
    return companyModule.Loading;
  }

  get Company() {
    return companyModule.Company;
  }

  get Org() {
    if (!companyModule.Organization) {
      return {} as OrganizationRS;
    }

    return companyModule.Organization;
  }

  get PrimaryContact() {
    return companyModule.PrimaryContact;
  }

  get Roles() {
    return appModule.Roles;
  }

  get Latitude(): number | null {
    if (
      this.PrimaryContact &&
      this.PrimaryContact.addresses &&
      this.PrimaryContact.addresses.length > 0 &&
      this.PrimaryContact.addresses[0].latitude
    ) {
      return this.PrimaryContact.addresses[0].latitude;
    }
    return null;
  }

  get Longitude(): number | null {
    if (
      this.PrimaryContact &&
      this.PrimaryContact.addresses &&
      this.PrimaryContact.addresses.length > 0 &&
      this.PrimaryContact.addresses[0].longitude
    ) {
      return this.PrimaryContact.addresses[0].longitude;
    }
    return null;
  }

  get HasSelectedAddress() {
    return (
      this.selectedResult &&
      this.selectedResult.geometry &&
      this.selectedResult.geometry.location &&
      this.selectedResult.geometry.location.lat() &&
      this.selectedResult.geometry.location.lng()
    );
  }

  get Countries() {
    return appModule.CountryData;
  }

  get Messages() {
    return appModule.Messages;
  }

  get Actions() {
    return appModule.Actions;
  }

  get EditModeCompanyDetails() {
    return companyModule.EditModeCompanyDetails && this.IsOrgAdmin;
  }

  get EditModeCompanyName() {
    return companyModule.EditModeCompanyName && this.IsOrgAdmin;
  }

  get EditModeCompanyWebsite() {
    return companyModule.EditModeCompanyWebsite && this.IsOrgAdmin;
  }

  get EditModeCompanyAddress() {
    return companyModule.EditModeCompanyAddress && this.IsOrgAdmin;
  }

  get EditModeCompanyContact() {
    return companyModule.EditModeCompanyContact && this.IsOrgAdmin;
  }

  get IsOrgAdmin() {
    return profileModule.IsOrgAdmin;
  }
}
