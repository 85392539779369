













import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
})
export default class RdmBanner extends Vue {
  private deactivated = false;

  get Show() {
    return this.$props.show;
  }

  private close() {
    this.deactivated = true;
  }
}
