








































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { httpModule } from '@/store/modules/moduleHttp';
import TravelerTypeDetails from '@/components/product/unit/TravelerTypeDetails.vue';
import TravelerTypeUnitType from '@/components/product/unit/TravelerTypeUnitType.vue';
import TravelerTypeAgeRange from '@/components/product/unit/TravelerTypeAgeRange.vue';
import TravelerTypeDescription from '@/components/product/unit/TravelerTypeDescription.vue';

@Component({
  components: {
    TravelerTypeDescription,
    TravelerTypeAgeRange,
    TravelerTypeUnitType,
    TravelerTypeDetails,
    RdmReturnToBtn,
  },
})
export default class TravelerTypeView extends Vue {
  private routeTravelers = constants.routes.TRAVELER_LIST;

  mounted() {
    if (this.$route.params?.id) {
      httpModule.getTravelerTypeById(this.$route.params?.id).then();
    }
  }

  discard() {
    this.goToTravelerTypes();
  }

  validate() {
    this.$root.$emit(constants.events.EDIT_TRAVELER_TYPE);
  }

  goToTravelerTypes() {
    this.$router.push({ name: this.routeTravelers }).catch();
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Loading(): boolean {
    return travelerTypeModule.Loading;
  }

  get CreateTravelerTypeFormState(): boolean {
    return travelerTypeModule.CreateTravelerTypeFormState;
  }

  get TravelerType() {
    return travelerTypeModule.SelectedTravelerType;
  }
}
