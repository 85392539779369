




























import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    hasActionTitle: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    hideOutline: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    slimPadding: {
      type: Boolean,
      default: false,
    },
  },
})
export default class RdmCollapsableCard extends Vue {
  private isOpen = this.$props.open;

  toggleOpenAction() {
    this.isOpen = !this.isOpen;
  }

  toggleOpen() {
    if (this.$props.hasActionTitle) {
      return;
    }
    this.toggleOpenAction();
  }

  get isDisabled() {
    return this.$props.disabled;
  }

  set isDisabled(disabled) {
    this.$emit('toggle-disabled', disabled);
  }
}
