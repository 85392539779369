import { AxiosError } from 'axios';
import { ErrorResponse as BackplaneApiError } from '@/api/wps/BackplaneModels';

export enum StatusEnum {
  UNKNOWN = -1,
  CANCELLED = -2, // request is manually cancelled with axios cancel token
  ABORTED = -3, // request exceeded a timeout or is aborted by browser
  NETWORK_FAILED = -4, // there is a network error
}

export interface ApiError extends AxiosError {
  status: number;
  wpsErr?: OppApiError;
  backplaneErr?: BackplaneApiError;
}

// both wps & opp-inventory
export interface OppApiError {
  code: number;
  message: string;
  details?: any;
}

export type errHandler = (err: ApiError) => void;

export interface CommonErrHandlers {
  onValidationNotFound?: errHandler; // 404
  onCancelled?: (err: any) => void;
  onAborted?: errHandler;
  onNetworkFailed?: errHandler;
  on403?: errHandler;
}

function handleCommonErrs(e: any, errs: CommonErrHandlers) {
  if (errs.onCancelled && e?.status === StatusEnum.CANCELLED) {
    errs.onCancelled(e);
  } else if (errs.onAborted && e?.status === StatusEnum.ABORTED) {
    errs.onAborted(e);
  } else if (errs.onNetworkFailed && e?.status === StatusEnum.NETWORK_FAILED) {
    errs.onNetworkFailed(e);
  } else if (errs.on403 && e?.status === 403) {
    errs.on403(e);
  } else {
    return true;
  }
  return false;
}

export function handleErrs<T extends CommonErrHandlers>(
  handle: (e: any, errs: T) => void,
  errs: T | undefined,
) {
  return (e: any) => {
    if (errs && handleCommonErrs(e, errs)) {
      handle(e, errs);
    }
    throw e;
  };
}
