import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { config } from '@/utils/config';
import { ApiError, StatusEnum } from '@/api/ErrHandlers';
import * as faker from 'faker';

const headerRequestID = 'X-Request-ID';

export interface AxiosErrorWithResponse<T = any> extends AxiosError<T> {
  response: AxiosResponse<T>;
}

export const intercept = {
  Request(config: AxiosRequestConfig) {
    if (!config.headers[headerRequestID]) {
      config.headers[headerRequestID] = faker.datatype.uuid();
    }
    return config;
  },

  Response(response: AxiosResponse) {
    if (config.IsLocalEnv) {
      //console.log('Response: ', JSON.stringify(response, null, 2));
    }

    return response;
  },

  Error(
    responseErrorHandler: (err: AxiosErrorWithResponse) => Promise<any>,
  ): (e: any) => Promise<any> {
    return (e: any) => {
      // prettier-ignore

      if (axios.isCancel(e)) {
        e.status = StatusEnum.CANCELLED;
        return Promise.reject(e);
      }

      if (axios.isAxiosError(e)) {
        const err = e as AxiosError;

        // The request was made and the server responded with not a 2xx status code
        if (err.response) {
          return responseErrorHandler(err as AxiosErrorWithResponse);
        } else {
          // prettier-ignore

          if (err.code === 'ECONNABORTED') {
            const e: ApiError = {
              status: StatusEnum.ABORTED,
              ...err,
            };
            return Promise.reject(e);
          }

          if (err.code === null) {
            const e: ApiError = {
              status: StatusEnum.NETWORK_FAILED,
              ...err,
            };
            return Promise.reject(e);
          }
        }
      }

      e.status = StatusEnum.UNKNOWN;
      return Promise.reject(e);
    };
  },
};
