




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import VoucherInfo from './VoucherInfo.vue';
import WebRedemptionModule from '@/store/modules/.deprecated/moduleWebRedemption';

@Component({
  components: {
    'rdm-voucher-info': VoucherInfo,
  },
})
export default class VoucherTickets extends Vue {
  @Prop({ type: Array, required: true })
  public tickets!: any[];

  private webRedemption = getModule(WebRedemptionModule);

  private selectedItem = null;

  private selectTicket(ticket: any) {
    this.webRedemption.setSelectedPass(null);
    this.webRedemption.setProduct(null);
    this.webRedemption.setSelectedTicket(ticket);
  }

  private selectRawTicket(ticket: any) {
    this.webRedemption.setSelectedRawPass(null);
    this.webRedemption.setProduct(null);
    const t = this.webRedemption.rawTickets[ticket.id];
    this.webRedemption.setSelectedRawTicket(t);
  }

  private get resellerRef() {
    return (
      this.webRedemption.getSelectedRawTicket &&
      this.webRedemption.getSelectedRawTicket.resellerRef
    );
  }

  private set resellerRef(ref) {
    const ticket = this.webRedemption.rawTickets.find((t) => t.reseller_ref === ref);
    this.webRedemption.setSelectedRawTicket(ticket);
  }

  private get selectedTicket() {
    return this.webRedemption.getSelectedTicket;
  }

  private get selectedRawTicket() {
    return this.webRedemption.getSelectedRawTicket;
  }
}
