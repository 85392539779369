

























import { Component, Prop, Vue } from 'vue-property-decorator';
import RemoveTravelerType from './company/RemoveTravelerType.vue';
import RdmGenericEditRefactorCard from './RdmGenericEditRefactorCard.vue';
import { TravelerType } from '@/models';

@Component({
  components: {
    RdmGenericEditRefactorCard,
    RemoveTravelerType,
  },
})
export default class RdmCompanyAgeBand extends Vue {
  @Prop()
  travelerType!: TravelerType;
  @Prop({
    required: false,
    default: false,
  })
  editable!: boolean;
  @Prop({
    required: false,
    default: false,
  })
  standalone!: boolean;

  mounted() {}

  toEdit() {
    this.$router.push({
      name: 'edit-company-guest-age-range',
      params: { id: this.travelerType.id },
    });
  }

  removeTravelerType() {
    // TODO
    // this.operatorModule.chooseWarningMessage({
    //   translationMessage: '',
    //   dismissible: false,
    //   title: `Remove ${this.travelerType.description}?`,
    //   message: `Are you sure you want to remove ${this.travelerType.label}?`,
    //   type: 'warning',
    // });
  }

  closeModal() {
    let element: any;
    for (let i = 0; i < this.$children.length; i++) {
      element = this.$children[i];
      if (element.closeDialog) {
        element.closeDialog();
      } else if (this.$children[i].$children) {
        for (let j = 0; j < this.$children[i].$children.length; j++) {
          let childElement: any;
          // eslint-disable-next-line prefer-const
          childElement = this.$children[i].$children[j];
          if (childElement.closeDialog) {
            childElement.closeDialog();
          }
        }
      }
    }
  }
}
