















































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RequestConnectionWizardStage4 extends Vue {
  private addProductModel = false;

  private products: any[] = [];

  private loading = false;

  private action: any = function () {};

  private selectAll = false;

  private productOpts: string[] = [
    'General Admission',
    'General Admission + Audio Tour',
    'General Admission + Professional Photos',
    'General Admission + Audio Tour + Professional Photos',
  ];

  public choices = [
    {
      prod: '',
      opt: [{ name: '' }],
    },
  ];

  public selected = [
    {
      arr: [''],
    },
  ];

  private headers = [
    {
      text: '',
      align: 'left',
      sortable: false,
      value: 'name',
    },
  ];

  mounted() {
    this.loading = true;
    this.action = function () {
      // Return true to save
      return true;
    };

    // this.$apollo
    //   .subscribe({
    //     query: SUBSCRIBE_PRODUCTS_LISTINGS,
    //     variables: {
    //       id: userState.user.operatorId,
    //     },
    //   })
    //   .subscribe(
    //     (res: FetchResult<ProductListingResponse>) => {
    //       setTimeout(() => {
    //         this.loading = false;
    //       }, 1000);
    //
    //       if (res.data) {
    //         this.products = res.data.operator_by_pk.products;
    //         this.choices.pop();
    //         this.selected.pop();
    //
    //         for (let i = 0; i < res.data.operator_by_pk.products.length; i++) {
    //           this.selected.push({
    //             arr: [],
    //           });
    //           this.choices.push({
    //             prod: res.data.operator_by_pk.products[i].name,
    //             opt: [],
    //           });
    //           for (let j = 0; j < this.productOpts.length; j++) {
    //             this.choices[i].opt.push({ name: this.productOpts[j] });
    //           }
    //         }
    //       }
    //     },
    //     error => {
    //       console.log(`use vuex to dispatch error message: ${JSON.stringify(error)}`);
    //     },
    //   );
  }

  next() {
    this.$emit('next-step', true);
  }
}
