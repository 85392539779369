import {
  Address,
  DeliveryFormats,
  DeliveryMethods,
  Meta,
  RedemptionMethods,
} from '@/models';

export enum AvailabilityType {
  StartTime = 'START_TIME', // LIMITED
  OpeningHours = 'OPENING_HOURS', // FREESALE
}

// 	// AvailabilityStatusUnknown is used to represent an invalid AvailabilityStatus.
// 	AvailabilityStatusUnknown AvailabilityStatus = 0
// 	// AvailabilityStatusFreesale is used to represent an AvailabilityStatus of type FREESALE.
// 	AvailabilityStatusFreesale AvailabilityStatus = 1
// 	// AvailabilityStatusAvailable is used to represent an AvailabilityStatus of type AVAILABLE.
// 	AvailabilityStatusAvailable AvailabilityStatus = 2
// 	// AvailabilityStatusLimited is used to represent an AvailabilityStatus of type LIMITED.
// 	AvailabilityStatusLimited AvailabilityStatus = 3
// 	// AvailabilityStatusSoldOut is used to represent an AvailabilityStatus of type SOLD_OUT.
// 	AvailabilityStatusSoldOut AvailabilityStatus = 4
// 	// AvailabilityStatusClosed is used to represent an AvailabilityStatus of type CLOSED.
// 	AvailabilityStatusClosed AvailabilityStatus = 5

export enum AvailabilityStatus {
  Freesale = 1,
  Available = 2,
  Limited = 3,
  SoldOut = 4,
  Closed = 5,
}

export enum CapacityType {
  CapacityLimitUnlimited = 'unlimited',
  CapacityLimitPooled = 'pooled',
  CapacityLimitByUnit = 'byUnit',
}

export enum DeliveryFormat {
  Unknown,
  QRCode,
  EAN13,
  UPCA,
  TEXT,
  Code128,
  Code39,
}

export enum DeliveryMethod {
  Unknown,
  Ticket,
  Voucher,
}

export enum RedemptionMethod {
  Unknown,
  Manifest,
  Digital,
  Print,
}

export enum TravelerType {
  Unknown = 'UNKNOWN',
  Traveler = 'TRAVELER',
  Adult = 'ADULT',
  Child = 'CHILD',
  Infant = 'INFANT',
  Youth = 'YOUTH',
  Student = 'STUDENT',
  Senior = 'SENIOR',
  Resource = 'RESOURCE',
  Other = 'OTHER',
}

export enum ItineraryType {
  Unknown,
  Start,
  POI,
  Event,
  End,
}

export enum LocationType {
  Unknown = 'UNKNOWN',
  Start = 'START',
  End = 'END',
  Visited = 'VISITED',
  Primary = 'PRIMARY',
}

export enum AddressType {
  PRIMARY = 'PRIMARY',
  OTHER = 'OTHER',
  DEPARTURE = 'DEPARTURE',
  ARRIVAL = 'ARRIVAL',
  STOP = 'STOP',
}

export enum Days {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum TimeEntryMode {
  Grouped = 'Grouped',
  Individual = 'Individual',
  Without = 'Without',
}

export enum ProductLevel {
  Product = 'PRODUCT',
  Option = 'OPTION',
  Combined = 'COMBINED',
}

export enum PricingDayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export interface Capability {
  id: string;
  revision?: number;
  required?: boolean;
}

export interface IncludedTaxes {
  name?: string;
  retail?: number;
  net?: number;
}

export interface PricingFrom {
  original?: number;
  net?: number;
  retail?: number;
  currency?: string;
  includedTaxes?: IncludedTaxes[];
}

export interface Unit {
  id: string;
  orgId: string;
  internalName: string;
  displayName?: string;
  description?: string;
  type?: TravelerType;
  minAge?: number;
  maxAge?: number;
  meta?: Meta;
  version?: number;
}

export interface Itinerary {
  name?: string;
  type?: ItineraryType;
  description?: string;
  address?: string;
  googlePlaceId?: string;
  latitude?: number;
  longitude?: number;
  travelTime?: string;
  travelTimeAmount?: number;
  travelTimeUnit?: string;
  duration?: string;
  durationAmount?: number;
  durationUnit?: string;
}

export interface Time {
  open?: string;
  close?: string;
}

export interface Valid {
  from?: string;
  until?: string;
}

export interface Hours {
  exclusionDates: string[];
  daysOfWeek: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  times: Time[];
  valid: Valid;
}

export interface TimeCapacity {
  start: string;
  end: string;
  maxCapacity: number | undefined;
  timeStartMenu: boolean;
  timeEndMenu: boolean;
}

export interface SelectedDay {
  id?: string;
  day: Array<Days>;
  times: Array<TimeCapacity>;
}

export interface SelectedDays {
  days: Array<SelectedDay>;
}

export interface Policy {
  cancellable?: boolean;
  refundable?: boolean;
  cutOffTime?: number; // time up until activity date before tickets can purchased in minutes (0 allowed)
}

export interface ProductOption {
  id?: string;
  org?: string;
  internalName?: string;
  createdAt?: string;
  updatedAt?: string;
  version?: number;
  status?: number;
  sharesAvailabilityWithProduct?: boolean;
  sharesCapacityWithProduct?: boolean;
  option: {
    id: string;
    internalName?: string;
    reference?: string;
    units?: Unit[];
    title?: string;
    subtitle?: string;
    shortDescription?: string;
    duration?: string;
    itinerary?: Itinerary[];
    hours?: Hours[];
    policy?: Policy;
    Policy?: Policy;
  };
}

export interface DestinationContact {
  website?: string;
  email?: string;
  telephone?: string;
  address?: string;
}

export interface Destination {
  id?: string;
  name?: string;
  country?: string;
  contact?: DestinationContact;
}

export interface Categories {
  title?: string;
  subtitle?: string;
  shortDescription?: string;
  coverImageUrl?: string;
  bannerImageUrl?: string;
  productIds?: string[];
}

export interface FAQ {
  question?: string;
  answer?: string;
}

export interface ProductImage {
  url?: string;
  title?: string;
  caption?: string;
}

export interface Location {
  id?: string;
  name?: string;
  locationType?: LocationType;
  streetAddress?: string;
  city?: string;
  country?: string;
  region?: string;
  postalCode?: string;
  longitude?: number;
  latitude?: number;
}

export interface UnitPricing {
  id?: string;
  supplier_id?: string;
  unit_id?: string;
  product_id?: string;
  option_id?: string;
  createdAt?: string;
  updatedAt?: string;
  version?: number;
  pricing?: PricingFrom;
}
export interface TaxesForPricing {
  taxFeeId: string;
  applicationOrder: number;
}

export interface OppPricingEffectiveDateRange {
  from: string;
  until?: string;
}

export interface OppPricingAmount {
  original?: number;
  net?: number;
  retail?: number;
  currency: string;
}

export interface OppPricingTaxesForPricing {
  taxFeeId: string;
  applicationOrder: number;
}

export interface OppPricingTimeSlot {
  dayOfWeek: PricingDayOfWeek;
  startTime: string;
  EndTime: string;
}

export interface OppPricing {
  id: string;
  level: ProductLevel;
  orgId: string;
  supplierId: string;
  productId: string;
  optionId?: string;
  unitIds: string[];
  effectiveDateRange: OppPricingEffectiveDateRange;
  amount: OppPricingAmount;
  appliedTaxesFees: OppPricingTaxesForPricing[];
  timeslots: OppPricingTimeSlot[];
}

export interface OppPricingDisplay {
  id: string;
  level: ProductLevel;
  orgId: string;
  supplierId: string;
  supplierName: string;
  productId: string;
  productName: string;
  optionId?: string;
  optionName?: string;
  displayName?: string;
  displayDateRange?: string;
  currency?: string;
  unitIds: string[];
  effectiveDateRange: OppPricingEffectiveDateRange;
  amount: OppPricingAmount;
  appliedTaxesFees: OppPricingTaxesForPricing[];
  timeslots: OppPricingTimeSlot[];
}

export interface OppAddress {
  id: string;
  name: string;
  streetAddress: string;
  city: string;
  countryCode: string;
  region: string;
  postalCode: string;
  longitude: number;
  latitude: number;
  googlePlaceId: string;
  type?: AddressType;
  version: number;
}

export interface OppAddressQueryCriteria {
  addressId: string;
  companyId: string;
  supplierId: string;
  productId: string;
}

export interface OppSupplierAddress {
  type: string;
  addressId: string;
  version: number;
  status: number;
  order: number | null;
}

export interface OppProductAddress {
  type: string;
  addressId: string;
  version: number;
  status: number;
  order: number | null;
}

export interface OppProductAddressWrapper {
  productAddress: OppProductAddress;
  criteria: OppAddressQueryCriteria;
}

export interface PricingWithTaxes {
  id: string;
  supplier_id: string;
  unit_id?: string;
  product_id?: string;
  option_id?: string;
  createdAt?: string;
  updatedAt?: string;
  version: number;
  pricing: {
    original: number;
    net: number;
    retail: number;
    currency: string;
  };
  effectiveDateRange: {
    from: string;
    until?: string;
  };
  appliedTaxesFees?: TaxesForPricing[];
}

export interface PricingFrom {
  original?: number;
  net?: number;
  retail?: number;
  currency?: string;
  includedTaxes?: IncludedTaxes[];
}

export interface IncludedTaxes {
  name?: string;
  retail?: number;
  net?: number;
}

export interface Product {
  id?: string;
  org?: string;
  internalName?: string;
  createdAt?: string;
  updatedAt?: string;
  version?: number;
  status?: number;
  product: {
    id: string;
    internalName: string;
    reference?: string;
    locale?: string;
    timeZone?: string;
    instantConfirmation?: boolean;
    instantDelivery?: boolean;
    availabilityType?: AvailabilityType; // InventoryType
    deliveryFormats?: DeliveryFormats[];
    deliveryMethods?: DeliveryMethods[];
    redemptionMethod?: RedemptionMethods;
    capabilities?: Capability[];
    options?: ProductOption[];
    defaultCurrency?: string;
    availableCurrencies?: string[];
    title?: string;
    subtitle?: string;
    country?: string;
    shortDescription?: string;
    description?: string;
    highlights?: string[];
    inclusions?: string[];
    exclusions?: string[];
    redemptionInstructions?: string;
    bookingTerms?: string;
    cancellationPolicy?: string;
    destination?: Destination;
    categories?: Categories[];
    faqs?: FAQ[];
    coverImageUrl?: string;
    bannerImageUrl?: string;
    videoUrl?: string;
    galleryImages?: ProductImage[];
    bannerImages?: ProductImage[];
    locations?: Address[];
    cancellable?: boolean;
    refundable?: boolean;
    cutOffTime?: number;
  };
}

export interface DeliveryFormatCode {
  value: DeliveryFormats;
  description?: string;
}

export interface DeliveryMethodCode {
  value: DeliveryMethods;
  description?: string;
}

export interface CurrencyCode {
  value: string;
  description?: string;
}

export interface LocaleCode {
  value: string;
  description?: string;
}

export interface ChargesCode {
  value: string;
  description?: string;
}

export interface TravelerTypeCodes {
  value: string;
  description?: string;
}

export interface Codes {
  charges?: ChargesCode[];
  currency?: CurrencyCode[];
  deliveryFormats?: DeliveryFormatCode[];
  deliveryMethods?: DeliveryMethodCode[];
  inventoryType?: [];
  locale?: LocaleCode[];
  locationType?: [];
  status?: [];
  travellerTypes?: TravelerTypeCodes[];
}

export interface CurrencyCountryData {
  code: string;
  name: string;
  symbol: string | null;
}

export interface LanguageCountryData {
  code: string;
  name: string;
}

export interface CountryData {
  name: string;
  code: string;
  capital?: string;
  region?: string;
  currency?: CurrencyCountryData;
  language?: LanguageCountryData;
  flag?: string;
  callingCode?: string;
  isoCode4217?: string;
}

export interface ProductOptionRelationship {
  parentId: string;
  childId: string;
}

export interface AvailabilitySchedule {
  id?: string;
  org?: string;
  supplierId?: string;
  productId?: string;
  optionId?: string;
  groupId?: string;
  name?: string;
  validFrom?: string;
  validUntil?: string;
  daysOfWeek?: Array<string>;
  excludedDays?: Array<string>;
  startTime?: string;
  endTime?: string;
  eventDuration?: number;
  eventPadding?: number;
  maxCapacity?: number;
  sharesAvailabilityWithParent?: boolean;
  sharesCapacityWithParent?: boolean;
  version?: number;
  status?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface ScheduleView {
  id: string;
  productId?: string;
  optionId?: string;
  scheduleName: string;
  validFrom?: string;
  validUntil?: string;
  schedules: Array<AvailabilitySchedule>;
}

export interface GroupedAvailabilitySchedule {
  id: string;
  name: string;
  validFrom?: string;
  validUntil?: string;
  startTime?: string;
  endTime?: string;
  daysOfWeek?: Array<string>;
  schedules: Array<AvailabilitySchedule>;
}

export interface AvailabilityColorMapping {
  id: string;
  color: string;
}

export interface Pricing {
  original: number;
  net: number;
  retail: number;
  currency: string;
}

export interface PricingObj {
  [key: string]: Array<Pricing>;
}

export interface PriceScheduleMap {
  Sunday?: PricingObj; // map[string] Array<Pricing>
  Monday?: PricingObj;
  Tuesday?: PricingObj;
  Wednesday?: PricingObj;
  Thursday?: PricingObj;
  Friday?: PricingObj;
  Saturday?: PricingObj;
}

export interface PriceSchedule {
  id?: string;
  org?: string;
  productId?: string;
  optionId?: string;
  effectiveStartDate?: string;
  effectiveEndDate?: string;
  priceSchedules?: Array<PriceScheduleMap>;
  version?: number;
  status?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface OfferPriceSchedule {
  id: string;
  org: string;
  productId: string;
  optionId?: string;
  effectiveStartDate: string;
  effectiveEndDate?: string;
  priceSchedules?: Array<PriceScheduleMap>;
  version?: number;
  status?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface DisplayPriceSchedule {
  id: string;
  name: string;
}

export interface Prices {
  original: number;
  net: number;
  retail: number;
}

export interface DayPrices {
  sunday: Prices;
  monday: Prices;
  tuesday: Prices;
  wednesday: Prices;
  thursday: Prices;
  friday: Prices;
  saturday: Prices;
  default: Prices;
}

export interface TravelerPrices {
  travelers: Array<DayPrices>;
}

export interface SupplierContact {
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
  address?: string;
  website?: string;
  role?: string;
}

export interface OtherContacts {
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
  address?: string;
  website?: string;
  role?: string;
  id?: string;
}

export interface SupplierLocation {
  id?: string;
  name?: string;
  locationType?: string;
  streetAddress?: string;
  city?: string;
  region?: string;
  countryCode?: string;
  postalCode?: string;
  longitude?: number;
  latitude?: number;
}

export interface Supplier {
  id?: string;
  org?: string;
  name?: string;
  endpoint?: string;
  contact?: SupplierContact;
  otherContacts?: Array<OtherContacts>;
  location?: SupplierLocation;
  otherLocations?: Array<SupplierLocation>;
  version?: number;
  status?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface SupplierWrapper {
  id?: string;
  supplier: Supplier;
  version?: number;
  status?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface TaxesAndFees {
  id: string;
  org?: string;
  supplierId: string;
  productId: string;
  optionId: string;
  name: string;
  description?: string;
  chargeType: string;
  rateType: string;
  currency: string;
  amount: number;
  ruleType: string;
  applicationLevel: string;
  includedInPricing: boolean;
  effectiveDateRange: {
    from: string;
    until: string;
  };
  validFrom: string;
  validUntil?: string;
  disclosureRequired: boolean;
  collectible: boolean;
  applicationOrder: number;
  version: number;
  status: number;
  createdAt: string;
  updatedAt: string;
}

export interface OfferPolicy {
  id: string;
  cancellable?: boolean;
  cutOffTime?: number;
  refundable?: boolean;
}

export interface OfferMeta {
  createdAt?: string;
  status?: string;
  updatedAt?: string;
  version: number;
}

export interface Offer {
  id: string;
  orgId?: string;
  name: string;
  description?: string;
  productId: string;
  optionId?: string;
  policyId?: string;
  availabilityScheduleId?: string;
  effectiveDateRange?: OppPricingEffectiveDateRange;
  useProductPolicy: boolean;
  useOptionPolicy: boolean;
  version?: number;
  status?: number;
  meta?: OfferMeta;
  policy?: OfferPolicy;
}

export interface OfferPricing {
  id: string;
  offerId: string;
  name: string;
  dateRange: OppPricingEffectiveDateRange;
  timeSlots: OfferTimeSlots;
  unitPrices: Array<OfferUnitPrice>;
  taxes: any;
}

export interface OfferTimeSlots {
  Sunday?: Array<string>;
  Monday?: Array<string>;
  Tuesday?: Array<string>;
  Wednesday?: Array<string>;
  Thursday?: Array<string>;
  Friday?: Array<string>;
  Saturday?: Array<string>;
}

export interface OfferUnitPrice {
  unitId: string;
  productId: string;
  optionId?: string;
  amount: OppPricingAmount;
}

export interface OfferUnitPriceMap {
  [key: string]: OfferUnitPrice;
}

export interface ProductPolicy {
  id: string;
  noPolicy: boolean;
  isProduct: boolean;
  isOption: boolean;
  cancellable?: boolean;
  refundable?: boolean;
  cutOffTime?: number;
  cancellationPolicy?: string;
}

export interface DayTimeSchedule {
  sunday: Array<DayTimeScheduleTime>;
  monday: Array<DayTimeScheduleTime>;
  tuesday: Array<DayTimeScheduleTime>;
  wednesday: Array<DayTimeScheduleTime>;
  thursday: Array<DayTimeScheduleTime>;
  friday: Array<DayTimeScheduleTime>;
  saturday: Array<DayTimeScheduleTime>;
}

export interface DayTimeScheduleTime {
  startTime: string;
  endTime: string;
  day: string;
}

export interface CapacityByUnit {
  id: string;
  amount: number;
}

export interface OfferDateRangeReq {
  companyId: string;
  offerId: string;
}

export interface OperatorUserReq {
  companyId: string;
  userId: string;
  body?: any;
}
