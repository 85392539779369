export const codes = {
  /* Profile (10000 - 10999) */
  PROFILE_LOAD: 10000,
  PROFILE_CREATED: 10001,
  PROFILE_UPDATED: 10002,
  PROFILE_DELETED: 10003,
  PROFILE_ERROR_500_LOAD_PROFILE: 10101,
  PROFILE_ERROR_500_CREATED_PROFILE: 10102,
  PROFILE_ERROR_404_UPDATED_PROFILE: 10103,
  PROFILE_ERROR_500_UPDATED_PROFILE: 10104,
  PROFILE_ERROR_404_DELETED_PROFILE: 10105,
  PROFILE_ERROR_500_DELETED_PROFILE: 10106,
  PROFILE_ERROR_UNAUTHORIZED: 10107,
  /* User (11000 - 11999) */
  USER_LOAD: 11000,
  USER_CREATED: 11001,
  USER_UPDATED: 11002,
  USER_DELETED: 11003,
  USER_DEACTIVATED: 11004,
  USER_ACTIVATED: 11005,
  USER_ERROR_500_LOAD_USER: 11101,
  USER_ERROR_500_CREATE_USER: 11102,
  USER_ERROR_404_UPDATE_USER: 11103,
  USER_ERROR_500_UPDATE_USER: 11104,
  USER_ERROR_404_DELETE_USER: 11105,
  USER_ERROR_500_DELETE_USER: 11106,
  USER_ERROR_500_DEACTIVATED_USER: 11107,
  USER_ERROR_500_ACTIVATED_USER: 11108,
  /* Company (20000 - 20999) */
  COMPANY_LOAD: 20000,
  COMPANY_CREATED: 20001,
  COMPANY_UPDATED: 20002,
  COMPANY_DELETED: 20003,
  COMPANY_LOAD_ADDRESS: 20004,
  COMPANY_LOAD_SUPPLIER_ADDRESS: 20005,
  COMPANY_LOAD_PRODUCT_ADDRESS: 20006,
  COMPANY_CREATE_COMPANY_ADDRESS: 20007,
  COMPANY_UPDATE_COMPANY_ADDRESS: 20008,
  COMPANY_DELETE_COMPANY_ADDRESS: 20009,
  COMPANY_CREATE_SUPPLIER_ADDRESS: 20010,
  COMPANY_CREATE_SUPPLIER_BULK_ADDRESS: 20011,
  COMPANY_UPDATE_SUPPLIER_ADDRESS: 20012,
  COMPANY_DELETE_SUPPLIER_ADDRESS: 20013,
  COMPANY_CREATE_PRODUCT_ADDRESS: 20014,
  COMPANY_UPDATE_PRODUCT_ADDRESS: 20015,
  COMPANY_DELETE_PRODUCT_ADDRESS: 20016,
  COMPANY_LOAD_CONTACT: 20017,
  COMPANY_CREATE_CONTACT: 20018,
  COMPANY_UPDATE_CONTACT: 20019,
  COMPANY_DELETE_CONTACT: 20020,
  COMPANY_ERROR_404_LOAD_COMPANY: 20101,
  COMPANY_ERROR_500_LOAD_COMPANY: 20102,
  COMPANY_ERROR_500_CREATE_COMPANY: 20103,
  COMPANY_ERROR_404_UPDATE_COMPANY: 20104,
  COMPANY_ERROR_500_UPDATE_COMPANY: 20105,
  COMPANY_ERROR_404_DELETED_COMPANY: 20106,
  COMPANY_ERROR_500_DELETED_COMPANY: 20107,
  COMPANY_ERROR_404_LOAD_ADDRESS: 20108,
  COMPANY_ERROR_500_LOAD_ADDRESS: 20109,
  COMPANY_ERROR_404_LOAD_SUPPLIER_ADDRESS: 20110,
  COMPANY_ERROR_500_LOAD_SUPPLIER_ADDRESS: 20111,
  COMPANY_ERROR_404_LOAD_PRODUCT_ADDRESS: 20112,
  COMPANY_ERROR_500_LOAD_PRODUCT_ADDRESS: 20113,
  COMPANY_ERROR_404_CREATE_COMPANY_ADDRESS: 20114,
  COMPANY_ERROR_500_CREATE_COMPANY_ADDRESS: 20115,
  COMPANY_ERROR_404_UPDATE_COMPANY_ADDRESS: 20116,
  COMPANY_ERROR_500_UPDATE_COMPANY_ADDRESS: 20117,
  COMPANY_ERROR_404_DELETED_COMPANY_ADDRESS: 20118,
  COMPANY_ERROR_500_DELETED_COMPANY_ADDRESS: 20119,
  COMPANY_ERROR_404_CREATE_SUPPLIER_ADDRESS: 20120,
  COMPANY_ERROR_500_CREATE_SUPPLIER_ADDRESS: 20121,
  COMPANY_ERROR_404_CREATE_SUPPLIER_BULK_ADDRESS: 20122,
  COMPANY_ERROR_500_CREATE_SUPPLIER_BULK_ADDRESS: 20123,
  COMPANY_ERROR_404_UPDATE_SUPPLIER_ADDRESS: 20124,
  COMPANY_ERROR_500_UPDATE_SUPPLIER_ADDRESS: 20125,
  COMPANY_ERROR_404_DELETED_SUPPLIER_ADDRESS: 20126,
  COMPANY_ERROR_500_DELETED_SUPPLIER_ADDRESS: 20127,
  COMPANY_ERROR_404_CREATE_PRODUCT_ADDRESS: 20128,
  COMPANY_ERROR_500_CREATE_PRODUCT_ADDRESS: 20129,
  COMPANY_ERROR_404_UPDATE_PRODUCT_ADDRESS: 20130,
  COMPANY_ERROR_500_UPDATE_PRODUCT_ADDRESS: 20131,
  COMPANY_ERROR_404_DELETED_PRODUCT_ADDRESS: 20132,
  COMPANY_ERROR_500_DELETED_PRODUCT_ADDRESS: 20133,
  COMPANY_ERROR_404_LOAD_CONTACT: 20134,
  COMPANY_ERROR_500_LOAD_CONTACT: 20135,
  COMPANY_ERROR_500_CREATE_CONTACT: 20136,
  COMPANY_ERROR_404_UPDATE_CONTACT: 20137,
  COMPANY_ERROR_500_UPDATE_CONTACT: 20138,
  COMPANY_ERROR_404_DELETED_CONTACT: 20139,
  COMPANY_ERROR_500_DELETED_CONTACT: 20140,
  COMPANY_ERROR_NO_COMPANY_ID: 20141,

  /* Product (21000 - 21999) */
  PRODUCT_LOAD: 21000,
  PRODUCT_CREATED: 21001,
  PRODUCT_UPDATED: 21002,
  PRODUCT_DELETED: 21003,
  PRODUCT_ERROR_500_LOAD_COMPANY: 21101,
  PRODUCT_ERROR_500_CREATE_COMPANY: 21102,
  PRODUCT_ERROR_404_UPDATE_COMPANY: 21103,
  PRODUCT_ERROR_500_UPDATE_COMPANY: 21104,
  PRODUCT_ERROR_404_DELETE_COMPANY: 21105,
  PRODUCT_ERROR_500_DELETE_COMPANY: 21106,
  PRODUCT_TRIP_ROUTE_LOAD: 21004,
  PRODUCT_TRIP_ROUTE_CREATED: 21005,
  PRODUCT_TRIP_ROUTE_UPDATED: 21006,
  PRODUCT_TRIP_ROUTE_DELETED: 21007,
  PRODUCT_TRIP_ROUTE_ERROR_500_LOAD_COMPANY: 21107,
  PRODUCT_TRIP_ROUTE_ERROR_500_CREATE_COMPANY: 21108,
  PRODUCT_TRIP_ROUTE_ERROR_404_UPDATE_COMPANY: 21109,
  PRODUCT_TRIP_ROUTE_ERROR_500_UPDATE_COMPANY: 21110,
  PRODUCT_TRIP_ROUTE_ERROR_404_DELETE_COMPANY: 21111,
  PRODUCT_TRIP_ROUTE_ERROR_500_DELETE_COMPANY: 21112,
  PRODUCT_CAPACITY_LOAD: 21008,
  PRODUCT_CAPACITY_UPDATED: 21009,
  PRODUCT_CAPACITY_ERROR_500_LOAD_CAPACITY: 21113,
  PRODUCT_CAPACITY_ERROR_404_UPDATE_CAPACITY: 21114,
  PRODUCT_CAPACITY_ERROR_500_UPDATE_CAPACITY: 21115,
  PRODUCT_SCHEDULE_ERROR_404_CREATE_SCHEDULE: 21116,
  PRODUCT_ERROR_404_ADD_SCHEDULE: 21117,
  /* Option (22000 - 22999) */
  OPTION_LOAD: 22000,
  OPTION_CREATED: 22001,
  OPTION_UPDATED: 22002,
  OPTION_DELETED: 22003,
  OPTION_ERROR_500_LOAD_OPTION: 22101,
  OPTION_ERROR_500_CREATE_OPTION: 22102,
  OPTION_ERROR_404_UPDATE_OPTION: 22103,
  OPTION_ERROR_500_UPDATE_OPTION: 22104,
  OPTION_ERROR_404_DELETE_OPTION: 22105,
  OPTION_ERROR_500_DELETE_OPTION: 22106,
  OPTION_TRIP_ROUTE_LOAD: 22004,
  OPTION_TRIP_ROUTE_CREATED: 22005,
  OPTION_TRIP_ROUTE_UPDATED: 22006,
  OPTION_TRIP_ROUTE_DELETED: 22007,
  OPTION_TRIP_ROUTE_ERROR_500_LOAD_TRIP_ROUTE: 22107,
  OPTION_TRIP_ROUTE_ERROR_500_CREATE_TRIP_ROUTE: 22108,
  OPTION_TRIP_ROUTE_ERROR_404_UPDATE_TRIP_ROUTE: 22109,
  OPTION_TRIP_ROUTE_ERROR_500_UPDATE_TRIP_ROUTE: 22110,
  OPTION_TRIP_ROUTE_ERROR_404_DELETE_TRIP_ROUTE: 22111,
  OPTION_TRIP_ROUTE_ERROR_500_DELETE_TRIP_ROUTE: 22112,
  OPTION_CAPACITY_LOAD: 22008,
  OPTION_CAPACITY_UPDATED: 22009,
  OPTION_CAPACITY_ERROR_500_LOAD_CAPACITY: 22113,
  OPTION_CAPACITY_ERROR_404_UPDATE_CAPACITY: 22114,
  OPTION_CAPACITY_ERROR_500_UPDATE_CAPACITY: 22115,
  OPTION_ERROR_404_ADD_SCHEDULE: 22116,
  OPTION_ERROR_409_ADD_SCHEDULE: 22117,
  /* Traveler Types (23000 - 23999) */
  TRAVELER_TYPE_LOAD: 23000,
  TRAVELER_TYPE_CREATED: 23001,
  TRAVELER_TYPE_UPDATED: 23002,
  TRAVELER_TYPE_DELETED: 23003,
  TRAVELER_TYPE_ERROR_500_LOAD: 23100,
  TRAVELER_TYPE_ERROR_500_CREATE_TRAVELER_TYPE: 23101,
  TRAVELER_TYPE_ERROR_404_UPDATE_TRAVELER_TYPE: 23102,
  TRAVELER_TYPE_ERROR_500_UPDATE_TRAVELER_TYPE: 23103,
  TRAVELER_TYPE_ERROR_404_DELETE_TRAVELER_TYPE: 23104,
  TRAVELER_TYPE_ERROR_500_DELETE_TRAVELER_TYPE: 23105,
  TRAVELER_TYPE_ERROR_MALFORMED_DELETE_TRAVELER_TYPE: 23106,
  /* Price Schedules (24000 - 24999) */
  PRICE_SCHEDULE_LOAD: 24000,
  PRICE_SCHEDULE_CREATED: 24001,
  PRICE_SCHEDULE_UPDATED: 24002,
  PRICE_SCHEDULE_DELETED: 24003,
  PRICE_AND_TAXES_LOAD: 24004,
  PRICE_AND_TAXES_CREATED: 24005,
  PRICE_AND_TAXES_UPDATED: 24006,
  PRICE_AND_TAXES_DELETED: 24007,
  PRICE_LOAD: 24008,
  PRICE_CREATED: 24009,
  PRICE_UPDATED: 24010,
  PRICE_DELETED: 24011,
  PRICE_SCHEDULE_ERROR_500_LOAD_PRICES: 24100,
  PRICE_SCHEDULE_ERROR_500_CREATE_PRICE_SCHEDULE: 24101,
  PRICE_SCHEDULE_ERROR_404_UPDATE_PRICE_SCHEDULE: 24102,
  PRICE_SCHEDULE_ERROR_500_UPDATE_PRICE_SCHEDULE: 24103,
  PRICE_SCHEDULE_ERROR_404_REMOVE_PRICE_SCHEDULE: 24104,
  PRICE_SCHEDULE_ERROR_500_REMOVE_PRICE_SCHEDULE: 24105,
  PRICE_AND_TAXES_ERROR_500_LOAD_PRICES: 24106,
  PRICE_AND_TAXES_ERROR_500_CREATE_PRICE: 24107,
  PRICE_AND_TAXES_ERROR_404_UPDATE_PRICE: 24108,
  PRICE_AND_TAXES_ERROR_500_UPDATE_PRICE: 24109,
  PRICE_AND_TAXES_ERROR_404_REMOVE_PRICE: 24110,
  PRICE_AND_TAXES_ERROR_500_REMOVE_PRICE: 24111,
  PRICE_ERROR_500_LOAD_PRICES: 24112,
  PRICE_ERROR_500_CREATE_PRICE: 24113,
  PRICE_ERROR_404_UPDATE_PRICE: 24114,
  PRICE_ERROR_500_UPDATE_PRICE: 24115,
  PRICE_ERROR_404_REMOVE_PRICE: 24116,
  PRICE_ERROR_500_REMOVE_PRICE: 24117,
  /* Taxes and Fees (25000 - 25999) */
  TAXES_AND_FEES_LOAD: 25000,
  TAXES_AND_FEES_CREATED: 25001,
  TAXES_AND_FEES_UPDATED: 25002,
  TAXES_AND_FEES_DELETED: 25003,
  TAXES_AND_FEES_ERROR_500_LOAD_TAXES: 25100,
  TAXES_AND_FEES_ERROR_500_CREATE_TAXES: 25101,
  TAXES_AND_FEES_ERROR_404_UPDATE_TAXES: 25102,
  TAXES_AND_FEES_ERROR_500_UPDATE_TAXES: 25103,
  TAXES_AND_FEES_ERROR_404_DELETE_TAXES: 25104,
  TAXES_AND_FEES_ERROR_500_DELETE_TAXES: 25105,
  TAXES_AND_FEES_FEEDBACK_SENT: 25106,
  /* Inventory (26000 - 26999) */
  /* Availability Status (27000 - 27999) */
  AVAILABILITY_STATUS_404_UPDATE: 27000,
  AVAILABILITY_STATUS_500_UPDATE: 27001,
  /* Channels (28000 - 28999) */
  CHANNELS_FEEDBACK_SENT: 28000,
  /* Reports (29000 - 29999) */
  REPORTS_ERROR_400_SEARCH: 29000,
  REPORTS_ERROR_500_SEARCH: 29001,
  REPORTS_ERROR_400_MISSING_EID: 29002,
  /* Offers (30000 - 30999) */
  OFFER_ERROR_400_CREATE: 30000,
  OFFER_ERROR_404_GET: 30001,
  OFFERS_ERROR_404_GET: 30002,
  OFFER_REMOVED: 30003,
  OFFER_ERROR_500: 30004,
  OFFER_CAPACITY_CONFLICT: 30005,
  OFFER_REMOVED_ERROR_500: 30006,
  OFFER_PRICING_REMOVED: 30007,
  OFFER_PRICING_UPDATED: 30009,
  /* Schedules (40000 - 40999)*/
  SCHEDULE_DELETED: 40000,
  SCHEDULE_UPDATED: 40001,
  SCHEDULE_CREATED: 40002,
  SCHEDULE_ERROR: 40003,
  SCHEDULE_CREATED_ERROR: 40004,
  SCHEDULE_CREATED_409_ERROR: 40005,
  /* Inventory (50000 - 50999) */
  INVENTORY_CREATED: 50000,
  /* Rate Card (60000 - 60999) */
  RATE_CARD_ERROR: 60000,
  RATE_CARD_CHANNEL_DELETED: 60001,
  UNAUTHORIZED_CHANNEL_API: 60002,
  RATE_CARD_CHANNEL_BINDING_CREATED: 60003,
  RATE_CARD_CHANNEL_BINDING_UPDATED: 60004,
  RATE_CARD_CHANNEL_BINDING_DELETED: 60005,
  RATE_CARD_CHANNEL_BINDING_ERROR: 60006,
  RATE_CARD_CHANNEL_CONNECTED: 60007,
  /* Operator User (80000 - 80999) */
  OPERATOR_USER_404_GET: 80000,
  OPERATOR_USER_404_CREATE: 80001,
  OPERATOR_USER_404_DELETE: 80002,
  OPERATOR_USER_500_GET: 80003,
  OPERATOR_USER_500_CREATE: 80004,
  OPERATOR_USER_500_DELETE: 80005,
  OPERATOR_USER_REMOVED: 80100,
  OPERATOR_USER_CREATED: 80101,
  /* General Messages */
  GENERAL_MESSAGE_NO_ORG_ID: 90000,
  GENERAL_MESSAGE_NO_SUPPLIER_ID: 90001,
  GENERAL_MESSAGE_FORBIDDEN: 90002,
  GENERAL_MESSAGE_COPIED_CLIPBOARD: 90003,
};
