import Vue from 'vue';
import VueI18n from 'vue-i18n';
import i18nData from './data';
import { numberFormats } from './i18nNumberFormats';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: i18nData,
  numberFormats,
});
