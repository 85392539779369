









































































































































































import { Component, Vue } from 'vue-property-decorator';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import { inventoryModule } from '@/store/modules/moduleInventory';
import { pricingModule } from '@/store/modules/modulePricing';
import { format, parseISO } from 'date-fns';
import {
  requiredNumberFieldRules,
  validWholeNumbersRules,
} from '@/utils/validation-rules';
import _ from 'lodash';
import {
  CapacityPool,
  DayAvailability,
  TimeSlot,
  OfferCapacity,
  CapacityLimit,
} from '@/models';
import { productModule } from '@/store/modules/moduleProduct';
import { handleKeyDownOnlyWholeNumeric, isEmpty } from '@/utils/helpers';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { formatTime12Hours } from '@/filters/date';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { httpModule } from '@/store/modules/moduleHttp';
import { constants } from '@/utils/constants';
import { CapacityType } from '@/api/inventory/InventoryModels';

@Component({
  methods: { formatTime12Hours },
})
export default class InventoryDayLevel extends Vue {
  private capacityUnlimited = CapacityType.CapacityLimitUnlimited;
  private capacityLimitPooled = CapacityType.CapacityLimitPooled;
  private capacityLimitByUnit = CapacityType.CapacityLimitByUnit;
  private requiredNumberFieldRules = requiredNumberFieldRules;
  private validWholeNumbersRules = validWholeNumbersRules;
  private selectedAvailabilityDay = {} as DayAvailability;
  private editCapacity = {} as OfferCapacity;
  private isEmpty = isEmpty;
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;

  mounted() {
    const buffer = 20;
    const el = document.getElementById('inventory-calendar-section') as HTMLElement;
    const card = document.getElementById('inventory-day-level') as HTMLElement;
    window.addEventListener('scroll', () => {
      const scrollPosition = window.scrollY + buffer;
      if (scrollPosition >= el?.offsetTop) {
        card?.classList.add('fixed');
      } else if (scrollPosition < el?.offsetTop + buffer) {
        card?.classList.remove('fixed');
      }
    });
  }
  destroyed() {
    window.removeEventListener('scroll', () => {});
  }

  closeSidebar() {
    inventoryModule.toggleEvent();
    // this.toggleEdit();
  }

  toggleEdit() {
    const offerCapacity = _.cloneDeep(this.OfferCapacity);
    const unitMap = {} as any;
    const units = Object.keys(this.CapacityPool?.byUnit || {});
    units.forEach((u: string) => {
      unitMap[u] = this.CapacityPool?.byUnit?.get(u) || 0;
    });
    const editCapacity = {} as OfferCapacity;
    editCapacity.capacityLimit = {
      pooled: offerCapacity?.capacityLimit?.pooled,
      unlimited: offerCapacity?.capacityLimit?.unlimited,
      byUnit: units.length > 0 ? unitMap : null,
    } as CapacityLimit;
    this.editCapacity = _.cloneDeep(this.OfferCapacity);
    availabilityStatusModule.changeEditModeState(!this.EditMode);
  }

  unitIdToTravelerName(id: string) {
    const i = this.DisplayTravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.DisplayTravelerTypes[i].text;
    }
    return '';
  }

  async save() {
    // todo append day
    this.selectedAvailabilityDay = {
      date: this.IsoDate,
      original: this.SelectedDayAvailability.original,
      remaining: this.SelectedDayAvailability.remaining,
      sold: this.SelectedDayAvailability.sold,
      blackout: this.SelectedDayAvailability.blackout,
    };
    availabilityStatusModule.appendDayAvailability(this.selectedAvailabilityDay);
    const capacity = {
      ...this.OfferCapacity,
      version: this.OfferCapacity.version ? this.OfferCapacity.version + 1 : 1,
    } as OfferCapacity;
    if (this.SelectedCapacityType === this.capacityLimitPooled) {
      capacity.capacityLimit.pooled = Number(this.editCapacity?.capacityLimit?.pooled);
    }
    if (this.SelectedCapacityType === this.capacityUnlimited) {
      capacity.capacityLimit.unlimited = true;
    }
    if (this.SelectedCapacityType === this.capacityLimitByUnit) {
      const cap = {} as any;
      if (this.editCapacity?.capacityLimit?.byUnit) {
        Object.keys(this.editCapacity?.capacityLimit?.byUnit).map((item) => {
          cap[item] = Number(this.editCapacity?.capacityLimit?.byUnit[item]);
        });
      }
      capacity.capacityLimit.byUnit = cap;
    }
    await httpModule.updateOfferCapacity(capacity);
    this.$root.$emit(constants.events.INVENTORY_HOME_MODAL_CLOSE, this.OfferCapacity.id);
    this.toggleEdit();
  }

  discard() {
    availabilityStatusModule.setSelectedDayAvailability(this.BaseSelectedDayAvailability);
    this.toggleEdit();
  }

  getScheduleName(id: string) {
    const schedule = this.Schedules.find((s) => s.id === id);
    if (schedule) {
      return schedule.name;
    }
    return '';
  }

  get Schedules() {
    return scheduleModule.Schedules;
  }

  get SelectedCapacityType() {
    return pricingModule.SelectedCapacityType;
  }

  get OfferCapacity() {
    return (
      pricingModule.OfferCapacityList.find(
        (oc) => oc.id === pricingModule.SelectedOfferCapacityId,
      ) || pricingModule.OfferCapacity
    );
  }

  get CapacityPool() {
    if (
      productModule.ProductCapacityPool &&
      productModule.ProductCapacityPool.capacityPool
    ) {
      return productModule.ProductCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get Slots() {
    if (
      pricingModule.OfferCapacity &&
      Array.isArray(pricingModule.OfferCapacity.timeSlots)
    ) {
      return pricingModule.OfferCapacity.timeSlots.filter(
        (slot) => slot.weekday === this.DayOfWeek,
      );
    }
    return [] as Array<TimeSlot>;
  }

  get DisplayTravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get IsoDate() {
    if (this.CurrentDate) {
      return format(this.CurrentDate, 'yyyy-MM-dd');
    }
    return '';
  }

  get DayOfWeek() {
    if (this.CurrentDate) {
      return format(this.CurrentDate, 'eeee');
    }
    return '';
  }

  get CurrentDate() {
    if (
      availabilityStatusModule.SelectedDate &&
      availabilityStatusModule.SelectedDate.length > 0
    ) {
      return parseISO(availabilityStatusModule.SelectedDate);
    }
    return null;
  }

  get Blackout() {
    if (this.CurrentDate)
      return availabilityStatusModule.Blackout.has(this.CurrentDate.toISOString());
    return false;
  }

  set Blackout(val: boolean) {
    if (this.CurrentDate) {
      const key = this.CurrentDate.toISOString();
      if (val) {
        availabilityStatusModule.appendBlackout(key);
      } else {
        availabilityStatusModule.removeBlackout(key);
      }
    }
  }

  get BlackoutMap() {
    return _.keys(availabilityStatusModule.AvailabilityDates);
  }

  get SelectedDayAvailability() {
    return availabilityStatusModule.SelectedDayAvailability;
  }

  get BaseSelectedDayAvailability() {
    return availabilityStatusModule.BaseSelectedDayAvailability;
  }

  get ShowSidebar() {
    return availabilityStatusModule.ShowSidebar;
  }

  get EditMode() {
    return availabilityStatusModule.EditMode;
  }
}
