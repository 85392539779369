





























import { Component, Vue } from 'vue-property-decorator';
import RdmMessage from './common/alert/RdmMessage.vue';
import { profileModule } from '@/store/modules/moduleProfile';

@Component({
  components: {
    RdmMessage,
  },
  props: {
    warnHeader: {
      type: String,
      default: 'Remove User',
    },
    warnMessage: {
      type: String,
      default: 'Are you sure you want to remove this user?',
    },
    clickMainBody: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
})
export default class RdmGenericEditCard extends Vue {
  private errorModal = false;

  editClick() {
    this.$emit('edit', true);
  }

  warningCheck() {
    // const warn: any = this.$refs.warn;
    // warn.dialog = true;
    // this.errorModal = true;
    this.$emit('remove');
  }

  removeClick(accept: boolean) {
    if (accept) {
      this.$emit('remove', accept);
      this.errorModal = false;
    }
  }

  clickBody() {
    if (this.$props.clickMainBody) {
      this.$emit('main');
    }
  }

  get IsEditAccess(): boolean {
    return profileModule.IsOrgAdmin;
  }
}
