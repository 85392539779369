import { render, staticRenderFns } from "./RdmDashboardCard.vue?vue&type=template&id=75476316&scoped=true&"
import script from "./RdmDashboardCard.vue?vue&type=script&lang=ts&"
export * from "./RdmDashboardCard.vue?vue&type=script&lang=ts&"
import style0 from "./RdmDashboardCard.vue?vue&type=style&index=0&id=75476316&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75476316",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
