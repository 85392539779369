export enum AuditMethod {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export interface AuditRecord {
  id: string;
  createdAt: string;
  changeType: AuditMethod;
  actor: string;
  orgId: string;
  dataBefore: any;
  dataAfter: any;
}
