



































































import { Component, Vue } from 'vue-property-decorator';
import { CompanyFeaturesRS, InternalFeaturesRS } from '@/api/wps/WpsModels';

@Component
export default class AppAdminCompany extends Vue {
  private features: Array<InternalFeaturesRS> = [];
  private companyFeatures: Array<CompanyFeaturesRS> = [];
  private id = '';
  private f_id = 0;

  get Companies() {
    const m = new Map<string, string>();
    return this.companyFeatures.filter((v) => {
      if (!m.has(v.company_id)) {
        m.set(v.company_id, '');
        return true;
      }
      return false;
    });
  }

  get Features() {
    return this.companyFeatures.filter(
      (v) => v.company_id === this.id && v.feature_id !== 0,
    );
  }

  get FeaturesExt() {
    return this.Features.map((v) => {
      const f = this.features.find((v2) => v2.id === v.feature_id);
      return {
        ...f,
        active: v.active,
      };
    });
  }

  get NewFeatures() {
    return this.features.filter((v) => {
      return !this.Features.map((v) => v.feature_id).includes(v.id);
    });
  }

  private mounted() {
    //TODO: save these commented sections to restore public features when we will be ready RE-7998
    // wpsClient.listInternalFeatures().subscribe((result: Array<InternalFeaturesRS>) => {
    //   this.features = result.sort(
    //     (a, b) => -compareDateStrings(a.updated_at, b.updated_at),
    //   );
    // });
    //
    // wpsClient.allCompanyFeatures().subscribe((result) => {
    //   this.companyFeatures = result;
    // });
  }

  async add() {
    //TODO: save these commented sections to restore public features when we will be ready RE-7998
    // await wpsClient.createCompanyFeature(this.id, this.f_id.toString(), false);
    //
    // wpsClient.allCompanyFeatures().subscribe((result) => {
    //   this.companyFeatures = result;
    // });
  }

  /* METHODS */

  async updateFeatureState(feature: InternalFeaturesRS) {
    feature.active = !feature.active;
    //TODO: save these commented sections to restore public features when we will be ready RE-7998
    // await wpsClient.updateCompanyFeature(this.id, feature.id.toString(), feature.active);
    //
    // wpsClient.allCompanyFeatures().subscribe((result) => {
    //   this.companyFeatures = result;
    // });
  }
}
