






















































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  emailRules,
  firstNameRules,
  lastNameRules,
  locationPeopleRules,
  optionalPhoneRules,
  orgCodeRules,
} from '@/utils/validation-rules';
import DeleteUserModal from '@/components/user/DeleteUserModal.vue';
import { handleKeyDownValidPhoneCharacter, knownRoles } from '@/utils/helpers';
import _ from 'lodash';
import { Account as UserProfile, Group } from '@/api/wps/BackplaneModels';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { constants } from '@/utils/constants';
import { userModule } from '@/store/modules/moduleUser';
import { profileModule } from '@/store/modules/moduleProfile';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import { Address, OperatorUser } from '@/models';
import backplaneClient from '@/api/backplane/BackplaneClient';

@Component({
  components: {
    DeleteUserModal,
    RdmReturnToBtn,
  },
})
export default class UserDetails extends Vue {
  /* DATA */

  // modules
  private parentName = constants.titles.USERS;
  private routerUserList = constants.routes.USERS;

  // dialog appearance
  private deleteDialog = false;

  private warningDialog = false;

  // user to edit
  private user: UserProfile = {};

  private fName = '';
  private lName = '';
  private validateSelectedLocations = false;
  private selectedLocations: Array<string> = [];
  private managedLocations: Array<Address> = [];
  private bakManagedLocations: Array<Address> = [];

  // rules
  private firstNameRules = firstNameRules;
  private lastNameRules = lastNameRules;
  private emailRules = emailRules;
  private locationPeopleRules = locationPeopleRules;
  private optionalPhoneRules = optionalPhoneRules;
  private orgCodeRules = orgCodeRules;
  private handleValidPhoneCharacters = handleKeyDownValidPhoneCharacter;

  // permissions
  private permissionsArray: Array<any> = [];

  private selectedPermissions: Array<any> = [];

  // other
  private userFormValid = false;

  private snackbar = false;

  private message =
    'An error has occurred. Please contact Redeam to resolve the issue or try later.';

  /* COMPUTED */

  get Loading(): boolean {
    return userModule.Loading;
  }

  get isAdmin() {
    return profileModule.IsAdmin;
  }

  get currentUser() {
    return profileModule.Profile;
  }

  get HasSelectedLocations(): boolean {
    if (this.validateSelectedLocations) {
      return this.selectedLocations.length > 0;
    }
    return true;
  }

  get Locations() {
    return companyModule.Addresses;
  }

  get Company() {
    return companyModule.Company;
  }

  /* HOOKS */

  private created() {
    this.user = _.cloneDeep(userModule.UserToEdit);

    if (this.user && this.user.given_name) {
      this.fName = this.user.given_name.valueOf();
    }

    if (this.user && this.user.surname) {
      this.lName = this.user.surname.valueOf();
    }

    const filterRoles = _.cloneDeep(knownRoles);

    if (this.Company.orgType !== constants.ORG_TYPE_TS) {
      // removing ticketing roles
      const ticketEditI = filterRoles.findIndex(
        (v) => v === constants.roles.RC_TICKETING_EDIT,
      );
      filterRoles.splice(ticketEditI, 1);
      const ticketViewI = filterRoles.findIndex(
        (v) => v === constants.roles.RC_TICKETING_VIEW,
      );
      filterRoles.splice(ticketViewI, 1);
    }

    backplaneClient.getRoles().subscribe(
      (res) => {
        this.permissionsArray = res.groups
          .filter((group: any) => {
            return !!filterRoles.find((v) => v === group.name);
          })
          .map((group: any) => {
            if (group.name === 'SUPPLIER_BUSINESS_STAFF') {
              this.selectedPermissions.push(group.id);
            }
            return {
              key: group.id,
              label: group.name,
              disabled: group.name === 'SUPPLIER_BUSINESS_STAFF',
            };
          });
      },
      () => {},
    );

    if (this.user && this.user.groups && Array.isArray(this.user.groups)) {
      this.selectedPermissions = this.user.groups.map((group) => group.id);
    }
    httpModule.loadOperatorUser(this.$route.params.id).then(() => {
      const operatorUser = _.cloneDeep(userModule.OperatorUser);
      if (
        operatorUser &&
        operatorUser.config &&
        Array.isArray(operatorUser.config.managedLocation)
      ) {
        operatorUser.config.managedLocation.forEach((l: string) => {
          const location = this.Locations.find((location) => location.id === l);
          if (location) {
            this.managedLocations.push(location);
            this.bakManagedLocations.push(location);
            this.selectedLocations.push(location.id || '');
          }
        });
      }
    });
  }

  /* METHODS */

  private async deleteUser() {
    this.deleteDialog = false;
    try {
      await httpModule.deleteUser();
      this.$router.push({ name: 'users' }).catch(() => {});
    } catch {
      // todo send message to backend
    }
  }

  private checkRoles() {
    // Alert if view role not added
    const role = this.permissionsArray.find((p) => {
      return p.label.toUpperCase() === 'SUPPLIER_BUSINESS_STAFF';
    });

    if (
      role &&
      this.selectedPermissions &&
      this.selectedPermissions.findIndex((p) => {
        return p === role.key;
      }) > -1
    ) {
      this.saveForm();
      return;
    }

    this.warningDialog = true;
  }

  private closeAndSave() {
    this.warningDialog = false;
    this.saveForm();
  }

  private async saveForm() {
    try {
      const selectedGroups = this.permissionsArray
        .filter((v) => {
          return !!this.selectedPermissions.find((selected) => selected === v.key);
        })
        .map((v) => ({
          id: v.key,
          name: v.label,
        }));
      const nonKnownRoles = this.getExistingRoles();
      this.user.groups = nonKnownRoles.concat(selectedGroups);
      await httpModule.updateUser(this.user);
      const operatorUser = {
        id: this.user.id,
        name: this.user.email,
        config: {
          managedLocation: this.selectedLocations,
        },
      } as OperatorUser;
      await httpModule.updateOperatorUser(operatorUser);
      // this.snackbar = true;
      // this.message = 'User information has been updated';
    } catch {
      // todo send error to backend
    }
  }

  private toggleAllLocations() {
    if (this.selectedLocations.length === this.Locations.length) {
      this.selectedLocations = [];
    } else if (this.selectedLocations.length < this.Locations.length) {
      this.selectedLocations = [];
      this.Locations.forEach((l) => {
        if (l.id) {
          this.selectedLocations.push(l.id);
        }
      });
    }
  }

  private toggleValidateSelectedLocation() {
    if (!this.validateSelectedLocations) {
      this.validateSelectedLocations = true;
    }
  }

  private getExistingRoles(): Array<Group> {
    if (this.user && this.user.groups && Array.isArray(this.user.groups)) {
      return this.user.groups.filter((role: any) => {
        return knownRoles.findIndex((knownRole: any) => knownRole === role.name) === -1;
      });
    }
    return [];
  }

  private async toUserManagement() {
    userModule.chooseUserToUpdate(-1);
    await httpModule.getUsers();
    // await this.$router.push({ name: 'users' });
  }
}
