var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.Loading),expression:"Loading"}],attrs:{"type":"table-thead, table-row-divider@10, table-tfoot"}}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.Loading),expression:"!Loading"}],ref:"rateCardList",staticClass:"elevation-0 rate-card-table",attrs:{"headers":_vm.rateCardHeaders,"items":_vm.RateCards,"items-per-page":10,"footer-props":{
      itemsPerPageOptions: _vm.paginationOptions,
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
    }},scopedSlots:_vm._u([{key:"item.offerNames",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("titleize")(item.offerNames.join(', '))))]),_c('div',{staticClass:"caption sub-text"},[_vm._v(" "+_vm._s(_vm._f("titleize")(item.offerLocations.join(', ')))+" ")])]}},{key:"item.channels",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("titleize")(_vm.displayChannelsName(item.channels))))])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","plain":"","to":{ name: _vm.routeToRateCardView, params: { id: item.id } }}},[_vm._v(" View ")]),_c('v-btn',{attrs:{"color":"primary","plain":"","to":{ name: _vm.routeToRateCardView, params: { id: item.id } }}},[_vm._v(" Edit ")]),_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){return _vm.duplicate(item)}}},[_vm._v(" Duplicate ")])]}},{key:"no-data",fn:function(){return [(_vm.ChannelAccess)?[_c('div',{staticClass:"text-left"},[_vm._v(" You don’t have any Rate Card yet. Click "),_c('router-link',{attrs:{"to":{ name: _vm.routeToAddRateCardView }}},[_vm._v("here")]),_vm._v(" to add one. ")],1)]:[_c('div',{staticClass:"text-left"},[_vm._v(" You don’t have any Rate Card yet. "+_vm._s(_vm.notAuthorizedMessage)+". ")])]]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }