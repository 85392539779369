import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VueCookies from 'vue-cookies';
import { VuetifyPlugin } from '@/plugins';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import 'flag-icon-css/css/flag-icon.css';
import 'element-ui/lib/theme-chalk/index.css';
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import filters from '@/filters';
import App from '@/App.vue';
import { config } from '@/utils/config';
import VueGtag from 'vue-gtag';
import { localStorageSrv } from '@/service/LocalStorageSrv';
import backplaneClient from '@/api/backplane/BackplaneClient';

/*** global error handling ***/

Object.defineProperty(Error.prototype, 'toJSON', {
  value: function () {
    const alt: Record<string, any> = {};
    Object.getOwnPropertyNames(this).forEach((key) => {
      alt[key] = this[key];
    });
    return alt;
  },
  configurable: true,
  writable: true,
});

async function handleGlobalError(err: any) {
  try {
    if (err.isHandled) return;
    err.version = config.Version;
    err.env = config.Env;
    if (config.IsLocalEnv) {
      console.error(`ERROR:`, err);
    }
    if (!config.IsProductionEnv) {
      // todo send message to backend
    }
    if (!config.IsLocalEnv) {
      //TODO: Add logger RE-7999
    }
    //   const message =
    //     _.isObjectLike(err) && err.response && err.response.data
    //       ? `[${err.response.status}]: ${err.response.data}`
    //       : err.toString
    //       ? err.toString()
    //       : 'UI error';
    // }
  } catch {
    if (!config.IsProductionEnv) {
      // todo send message to backend
    }
  }
}

Vue.config.errorHandler = function (err, vm, info) {
  const e = err as any;
  e.vueinfo = info;
  handleGlobalError(e);
};

window.onerror = function (msg, url, line, col, err) {
  handleGlobalError(err);
};

/*** logout on any 401 ***/

backplaneClient.handle401((err: any) => {
  err.isHandled = true;
  store.dispatch('rdm-rc-http/logout');
});
// inventoryClient.handle401((err: any) => {
//   err.isHandled = true;
//   store.dispatch('rdm-rc-http/logout');
// });

/*** set up Vue ***/

Vue.config.productionTip = false;
Vue.config.devtools = !config.IsProductionEnv;

// todo: doesn't work because filter.name in production mode differs from the name in code and build process doesn't
// handle this well. so the registered filter has one name, but in component there is used the other name.
// below there is a workaround for now/
// filters.forEach((filter) => Vue.filter(filter.name, filter));
filters.forEach((filter) => Vue.filter(filter.filterName, filter.filter));

Vue.use(VueTheMask);
Vue.use(VueCookies);
Vue.use(ElementUI, { locale });
Vue.use(require('vue-moment'));

/** Google Analytics **/
Vue.use(
  VueGtag,
  {
    appName: 'Redeam Central',
    config: { id: config.GoogleAnalyticsTagId },
    pageTrackerScreenviewEnabled: true,
    enabled: !config.IsLocalEnv,
  },
  router,
);

/*** run migrations (based on app version) ***/

localStorageSrv.runMigrations();

/*** validate configuration ***/

// window.onerror handles throws only in next macrotask after it was set
setTimeout(() => {
  config.Validate();
}, 0);

/*** run app ***/

new Vue({
  store,
  router,
  i18n,
  vuetify: VuetifyPlugin,
  render: (h) => h(App),
}).$mount('#app');
