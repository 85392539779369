




































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import {
  optionalNumberMinimumRules,
  requiredFieldRules,
  requiredMultiSelectFieldRules,
  requiredNumberFieldRules,
} from '@/utils/validation-rules';
import { productModule } from '@/store/modules/moduleProduct';
import _ from 'lodash';
import moment from 'moment/moment';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import {
  DayPrices,
  PriceSchedule,
  TravelerPrices,
  Unit,
  Prices,
  Days,
  AvailabilityType,
} from '@/api/inventory/InventoryModels';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { pricingModule } from '@/store/modules/modulePricing';
import { httpModule } from '@/store/modules/moduleHttp';
import { appModule } from '@/store/modules/moduleApp';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { optionModule } from '@/store/modules/moduleOption';
import { Option, Product } from '@/models';

@Component({
  components: {
    RdmCollapsableCard,
    RdmReturnToBtn,
  },
})
export default class PricingEdit extends Vue {
  private routerPricingList = constants.routes.PRICING_LIST;
  private parentName = constants.titles.PRICING;
  private addTravelerTypeModal = false;
  private pricingForm = false;
  private displayTaxes = true;
  private corePrice = {} as any;
  private core = {} as any;
  private requiredField = requiredFieldRules;
  private requiredNumberField = requiredNumberFieldRules;
  private requiredMultipleSelect = requiredMultiSelectFieldRules;
  private optionalMinNumber = optionalNumberMinimumRules;
  private timedAvailability = AvailabilityType.OpeningHours;
  private daySunday = Days.Sunday;
  private dayMonday = Days.Monday;
  private dayTuesday = Days.Tuesday;
  private dayWednesday = Days.Wednesday;
  private dayThursday = Days.Thursday;
  private dayFriday = Days.Friday;
  private daySaturday = Days.Saturday;
  private minimumPrice = 0;
  private selectedOptionId = '';
  private selectedProductId = '';
  private priceScheduleId = '';
  private menuValidDates = false;
  private effectiveDates: string[] = [];
  private travelerPrices = { travelers: [] } as TravelerPrices;
  private selectedProduct = {} as Product;
  private selectedOption = {} as Option;
  private days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  private dateMsg = '';
  private selectedCurrency = 'USD';

  mounted() {
    this.travelerPrices = _.cloneDeep(this.GlobalTravelerPrices);
    this.priceScheduleId = this.$route.params.id;
    // todo load selected pricing
    this.getPriceSchedule();
  }

  getPriceSchedule() {
    pricingModule.getPriceSchedule(this.priceScheduleId);
    this.findProductAndOptions();
    // inventoryModule.loadPriceSchedule(this.priceScheduleId).then(() => {
    //   this.findProductAndOptions();
    // });
  }

  findProductAndOptions() {
    const product = this.Products.find(
      (product: Product) => product.id === this.SelectedPriceSchedule?.productId,
    );
    if (product) {
      this.selectedProduct = product;
      this.selectedProductId = this.selectedProduct.id || '';
      if (this.SelectedPriceSchedule.optionId) {
        const option = this.Options.find(
          (option: Option) => option.id === this.SelectedPriceSchedule.optionId,
        );
        if (option) {
          this.selectedOption = option;
          this.selectedOptionId = this.selectedOption.id || '';
        }
      }

      // set date
      this.effectiveDates = [
        this.SelectedPriceSchedule.effectiveStartDate || '',
        this.SelectedPriceSchedule.effectiveEndDate || '',
      ];
      this.changeDate();

      this.findPrices();
      return;
    }

    // alertFloatModule.setNewAlert(
    //   {
    //     type: AlertType.ERROR,
    //     disappearing: true,
    //     title: `Error`,
    //   },
    //   `Product not found`,
    // );
    this.$router.push({ name: this.routerPricingList }).catch(() => {});
  }

  findPrices() {
    // todo set travelerPrices with existing prices
    // console.log(this.SelectedPriceSchedule.priceSchedules);
    const scheduleTypes = {};
    if (this.TravelerTypes && Array.isArray(this.TravelerTypes)) {
      this.TravelerTypes.forEach((travelerType: Unit) => {
        _.set(scheduleTypes, travelerType.internalName || '', [] as Array<DayPrices>);
      });
    }
    if (
      this.SelectedPriceSchedule &&
      this.SelectedPriceSchedule.priceSchedules &&
      Array.isArray(this.SelectedPriceSchedule.priceSchedules)
    ) {
      for (let i = 0; i < this.SelectedPriceSchedule.priceSchedules.length; i++) {
        // days
        const keysSunday = _.keys(this.SelectedPriceSchedule.priceSchedules[i].Sunday);
        for (const key of keysSunday) {
          const values = _.get(this.SelectedPriceSchedule.priceSchedules[i].Sunday, key);
          if (values && _.isArray(values)) {
            _.set(scheduleTypes, [key, '0', 'sunday'], {
              original: values[0]?.original || 0,
              net: values[0]?.net || 0,
              retail: values[0]?.retail || 0,
            } as Prices);
          }
        }
        const keysMonday = _.keys(this.SelectedPriceSchedule.priceSchedules[i].Monday);
        for (const key of keysMonday) {
          const values = _.get(this.SelectedPriceSchedule.priceSchedules[i].Monday, key);
          if (values && _.isArray(values)) {
            _.set(scheduleTypes, [key, '0', 'monday'], {
              original: values[0]?.original || 0,
              net: values[0]?.net || 0,
              retail: values[0]?.retail || 0,
            } as Prices);
          }
        }
        const keysTuesday = _.keys(this.SelectedPriceSchedule.priceSchedules[i].Tuesday);
        for (const key of keysTuesday) {
          const values = _.get(this.SelectedPriceSchedule.priceSchedules[i].Tuesday, key);
          if (values && _.isArray(values)) {
            _.set(scheduleTypes, [key, '0', 'tuesday'], {
              original: values[0]?.original || 0,
              net: values[0]?.net || 0,
              retail: values[0]?.retail || 0,
            } as Prices);
          }
        }
        const keysWednesday = _.keys(
          this.SelectedPriceSchedule.priceSchedules[i].Wednesday,
        );
        for (const key of keysWednesday) {
          const values = _.get(
            this.SelectedPriceSchedule.priceSchedules[i].Wednesday,
            key,
          );
          if (values && _.isArray(values)) {
            _.set(scheduleTypes, [key, '0', 'wednesday'], {
              original: values[0]?.original || 0,
              net: values[0]?.net || 0,
              retail: values[0]?.retail || 0,
            } as Prices);
          }
        }
        const keysThursday = _.keys(
          this.SelectedPriceSchedule.priceSchedules[i].Thursday,
        );
        for (const key of keysThursday) {
          const values = _.get(
            this.SelectedPriceSchedule.priceSchedules[i].Thursday,
            key,
          );
          if (values && _.isArray(values)) {
            _.set(scheduleTypes, [key, '0', 'thursday'], {
              original: values[0]?.original || 0,
              net: values[0]?.net || 0,
              retail: values[0]?.retail || 0,
            } as Prices);
          }
        }
        const keysFriday = _.keys(this.SelectedPriceSchedule.priceSchedules[i].Friday);
        for (const key of keysFriday) {
          const values = _.get(this.SelectedPriceSchedule.priceSchedules[i].Friday, key);
          if (values && _.isArray(values)) {
            _.set(scheduleTypes, [key, '0', 'friday'], {
              original: values[0]?.original || 0,
              net: values[0]?.net || 0,
              retail: values[0]?.retail || 0,
            } as Prices);
          }
        }
        const keysSaturday = _.keys(
          this.SelectedPriceSchedule.priceSchedules[i].Saturday,
        );
        for (const key of keysSaturday) {
          const values = _.get(
            this.SelectedPriceSchedule.priceSchedules[i].Saturday,
            key,
          );
          if (values && _.isArray(values)) {
            _.set(scheduleTypes, [key, '0', 'saturday'], {
              original: values[0]?.original || 0,
              net: values[0]?.net || 0,
              retail: values[0]?.retail || 0,
            } as Prices);
          }
        }
        // for (let [key, v] in this.SelectedPriceSchedule.priceSchedules[i].Monday) {
        //
        // }
      }
    }
    // console.log(scheduleTypes);
    // console.log(this.travelerPrices);
    const travelTypes = _.keys(scheduleTypes);
    for (let i = 0; i < travelTypes.length; i++) {
      // this.travelerPrices.travelers[i] = _.get(
      //   scheduleTypes,
      //   travelTypes[i],
      // ) as DayPrices;
      const schedules = _.get(scheduleTypes, travelTypes[i]) as Array<DayPrices>;
      const schedule = schedules[0];
      // this.travelerPrices.travelers[i] = schedule;
      // console.log('schedule', schedule);
      if (schedule) {
        if (
          schedule.sunday &&
          (schedule.sunday.net > 0 ||
            schedule.sunday.original > 0 ||
            schedule.sunday.retail > 0)
        ) {
          this.travelerPrices.travelers[i].sunday.net = schedule.sunday.net * 0.01;
          this.travelerPrices.travelers[i].sunday.retail = schedule.sunday.retail * 0.01;
          this.travelerPrices.travelers[i].sunday.original =
            schedule.sunday.original * 0.01;
        }
        if (
          schedule.monday &&
          (schedule.monday.net > 0 ||
            schedule.monday.original > 0 ||
            schedule.monday.retail > 0)
        ) {
          this.travelerPrices.travelers[i].monday.net = schedule.monday.net * 0.01;
          this.travelerPrices.travelers[i].monday.retail = schedule.monday.retail * 0.01;
          this.travelerPrices.travelers[i].monday.original =
            schedule.monday.original * 0.01;
        }
        if (
          schedule.tuesday &&
          (schedule.tuesday.net > 0 ||
            schedule.tuesday.original > 0 ||
            schedule.tuesday.retail > 0)
        ) {
          this.travelerPrices.travelers[i].tuesday.net = schedule.tuesday.net * 0.01;
          this.travelerPrices.travelers[i].tuesday.retail =
            schedule.tuesday.retail * 0.01;
          this.travelerPrices.travelers[i].tuesday.original =
            schedule.tuesday.original * 0.01;
        }
        if (
          schedule.wednesday &&
          (schedule.wednesday.net > 0 ||
            schedule.wednesday.original > 0 ||
            schedule.wednesday.retail > 0)
        ) {
          this.travelerPrices.travelers[i].wednesday.net = schedule.wednesday.net * 0.01;
          this.travelerPrices.travelers[i].wednesday.retail =
            schedule.wednesday.retail * 0.01;
          this.travelerPrices.travelers[i].wednesday.original =
            schedule.wednesday.original * 0.01;
        }
        if (
          schedule.thursday &&
          (schedule.thursday.net > 0 ||
            schedule.thursday.original > 0 ||
            schedule.thursday.retail > 0)
        ) {
          this.travelerPrices.travelers[i].thursday.net = schedule.thursday.net * 0.01;
          this.travelerPrices.travelers[i].thursday.retail =
            schedule.thursday.retail * 0.01;
          this.travelerPrices.travelers[i].thursday.original =
            schedule.thursday.original * 0.01;
        }
        if (
          schedule.friday &&
          (schedule.friday.net > 0 ||
            schedule.friday.original > 0 ||
            schedule.friday.retail > 0)
        ) {
          this.travelerPrices.travelers[i].friday.net = schedule.friday.net * 0.01;
          this.travelerPrices.travelers[i].friday.retail = schedule.friday.retail * 0.01;
          this.travelerPrices.travelers[i].friday.original =
            schedule.friday.original * 0.01;
        }
        if (
          schedule.saturday &&
          (schedule.saturday.net > 0 ||
            schedule.saturday.original > 0 ||
            schedule.saturday.retail > 0)
        ) {
          this.travelerPrices.travelers[i].saturday.net = schedule.saturday.net * 0.01;
          this.travelerPrices.travelers[i].saturday.retail =
            schedule.saturday.retail * 0.01;
          this.travelerPrices.travelers[i].saturday.original =
            schedule.saturday.original * 0.01;
        }
      }
    }
  }

  addTravelerType() {
    this.addTravelerTypeModal = true;
  }

  handleCloseTravelerType() {
    this.addTravelerTypeModal = false;
  }

  changeProduct() {
    // todo filter options?
  }

  changeDate() {
    this.dateMsg = '';
    if (this.effectiveDates && _.isArray(this.effectiveDates)) {
      if (this.effectiveDates.length === 1) {
        this.dateMsg = `${moment(this.effectiveDates[0]).format('MM/DD/YYYY')}`;
      } else if (this.effectiveDates.length === 2) {
        const startDt = moment(this.effectiveDates[0]).format('MM/DD/YYYY');
        const endDt = moment(this.effectiveDates[1]).format('MM/DD/YYYY');
        this.dateMsg = `${startDt} to ${endDt}`;
      } else {
        this.dateMsg = 'No date range selected!';
      }
    }
  }

  discardChanges() {
    // todo: display discard changes alert if form has been changed
    this.$router.push({ name: this.routerPricingList }).catch(() => {});
  }

  savePricing() {
    const pricing = {};
    _.set(pricing, ['id'], this.SelectedPriceSchedule.id);
    _.set(pricing, ['version'], (this.SelectedPriceSchedule.version || 0) + 1);
    _.set(pricing, ['productId'], this.selectedProductId);
    if (this.selectedOptionId && this.selectedOptionId.length > 0) {
      _.set(pricing, ['optionId'], this.selectedOptionId);
    }
    _.set(pricing, ['effectiveStartDate'], this.effectiveDates[0]);
    _.set(pricing, ['effectiveEndDate'], this.effectiveDates[1]);
    for (let i = 0; i < this.TravelerPrices.travelers.length; i++) {
      if (
        (this.TravelerPrices.travelers[i].sunday &&
          this.TravelerPrices.travelers[i].sunday.retail > 0) ||
        this.TravelerPrices.travelers[i].sunday.net > 0 ||
        this.TravelerPrices.travelers[i].sunday.original > 0
      ) {
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Sunday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'net',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].sunday.net) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Sunday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'retail',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].sunday.retail) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Sunday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'original',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].sunday.original) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Sunday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'currency',
          ],
          this.selectedCurrency,
        );
      }
      if (
        (this.TravelerPrices.travelers[i].monday &&
          this.TravelerPrices.travelers[i].monday.retail > 0) ||
        this.TravelerPrices.travelers[i].monday.net > 0 ||
        this.TravelerPrices.travelers[i].monday.original > 0
      ) {
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Monday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'net',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].monday.net) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Monday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'retail',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].monday.retail) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Monday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'original',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].monday.original) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Monday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'currency',
          ],
          this.selectedCurrency,
        );
      }

      if (
        (this.TravelerPrices.travelers[i].tuesday &&
          this.TravelerPrices.travelers[i].tuesday.retail > 0) ||
        this.TravelerPrices.travelers[i].tuesday.net > 0 ||
        this.TravelerPrices.travelers[i].tuesday.original > 0
      ) {
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Tuesday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'net',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].tuesday.net) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Tuesday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'retail',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].tuesday.retail) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Tuesday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'original',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].tuesday.original) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Tuesday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'currency',
          ],
          this.selectedCurrency,
        );
      }

      if (
        (this.TravelerPrices.travelers[i].wednesday &&
          this.TravelerPrices.travelers[i].wednesday.retail > 0) ||
        this.TravelerPrices.travelers[i].wednesday.net > 0 ||
        this.TravelerPrices.travelers[i].wednesday.original > 0
      ) {
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Wednesday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'net',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].wednesday.net) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Wednesday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'retail',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].wednesday.retail) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Wednesday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'original',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].wednesday.original) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Wednesday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'currency',
          ],
          this.selectedCurrency,
        );
      }

      if (
        (this.TravelerPrices.travelers[i].thursday &&
          this.TravelerPrices.travelers[i].thursday.retail > 0) ||
        this.TravelerPrices.travelers[i].thursday.net > 0 ||
        this.TravelerPrices.travelers[i].thursday.original > 0
      ) {
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Thursday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'net',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].thursday.net) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Thursday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'retail',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].thursday.retail) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Thursday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'original',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].thursday.original) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Thursday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'currency',
          ],
          this.selectedCurrency,
        );
      }

      if (
        (this.TravelerPrices.travelers[i].friday &&
          this.TravelerPrices.travelers[i].friday.retail > 0) ||
        this.TravelerPrices.travelers[i].friday.net > 0 ||
        this.TravelerPrices.travelers[i].friday.original > 0
      ) {
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Friday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'net',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].friday.net) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Friday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'retail',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].friday.retail) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Friday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'original',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].friday.original) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Friday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'currency',
          ],
          this.selectedCurrency,
        );
      }

      if (
        (this.TravelerPrices.travelers[i].saturday &&
          this.TravelerPrices.travelers[i].saturday.retail > 0) ||
        this.TravelerPrices.travelers[i].saturday.net > 0 ||
        this.TravelerPrices.travelers[i].saturday.original > 0
      ) {
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Saturday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'net',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].saturday.net) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Saturday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'retail',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].saturday.retail) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Saturday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'original',
          ],
          _.round(_.toNumber(this.TravelerPrices.travelers[i].saturday.original) * 100),
        ); // multiplying by 100 to convert to minor units
        _.set(
          pricing,
          [
            'priceSchedules',
            '0',
            'Saturday',
            this.TravelerTypes[i].internalName || '',
            '0',
            'currency',
          ],
          this.selectedCurrency,
        );
      }
    }
    const priceSchedule = this.convertToPriceSchedule(pricing);
    httpModule
      .updatePriceSchedules(priceSchedule)
      .then(() => {
        this.navigateToPriceList();
      })
      .catch(() => {})
      .finally(() => {});
  }
  //   inventoryModule
  //     .updatePriceSchedule(priceSchedule)
  //     .then(() => {
  //       this.navigateToPriceList();
  //     })
  //     .catch(() => {
  //       // todo display try again alert?
  //     })
  //     .finally(() => {
  //       this.saving = false;
  //     });
  // }

  convertToPriceSchedule(input: any): PriceSchedule {
    return input as PriceSchedule;
  }

  navigateToPriceList() {
    this.$router.push({ name: constants.routes.PRICING_LIST }).catch(() => {});
  }

  saveValidDates() {
    this.sortValidDates();
    const validDateRef = this.$refs.validDates as any;
    if (!_.isUndefined(validDateRef)) {
      validDateRef.save(this.effectiveDates);
    }
  }

  sortValidDates() {
    this.effectiveDates.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });
  }

  calculateTotal(amount: any): string {
    const a = _.isUndefined(amount) ? 0 : amount;
    const percent = 0; //  (amount / 100) * 5 // todo taxes and fees
    const total = _.toNumber(a) + percent;
    return _.round(total, 2).toFixed(2);
  }

  isScheduled(day: Days): boolean {
    let hasDay = false;
    if (this.Schedules && Array.isArray(this.Schedules)) {
      this.Schedules.forEach((s) => {
        if (s.daysOfWeek) {
          const index = s.daysOfWeek.findIndex((d) => {
            return d === day;
          });
          if (index > -1) {
            hasDay = true;
            return;
          }
        }
      });
    }
    return hasDay;
  }

  get SelectedPriceSchedule() {
    // return inventoryModule.PriceSchedule;
    return pricingModule.PriceSchedule;
  }

  get Currencies() {
    return appModule.Codes?.currency || [];
  }

  get TravelerTypes() {
    return travelerTypeModule.TravelerTypes;
  }

  get TravelerPrices() {
    return this.travelerPrices;
  }

  get GlobalTravelerPrices() {
    return travelerTypeModule.TravelerPrices;
  }

  get Schedules() {
    return productModule.AvailabilitySchedules.filter((availabilitySchedule) => {
      return availabilitySchedule.productId === this.Product.id;
    });
  }

  get Products() {
    return productModule.Products.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get Options() {
    return optionModule.Options.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Product() {
    return productModule.Product;
  }

  get IsTimed() {
    return this.Product?.availabilityType === this.timedAvailability;
  }

  get Loading() {
    return pricingModule.Loading;
  }
}
