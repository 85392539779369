












































import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: Boolean,
      default: false,
    },
  },
})
export default class RdmMessage extends Vue {}
