import {
  emptyDash,
  emptyNone,
  emptyNotProvided,
  emptyNumberNotProvided,
  emptyOngoing,
  minorNumberToCurrencyNumber,
  numberToCurrency,
  phoneFormat,
  productStatusName,
  truncate,
  underscoreToSpace,
} from '@/filters/filters';
import { capitalize, lowercase, titleize, uppercase } from '@/filters/lettercase';
import {
  formatDate,
  formatDateMmDdYyyy,
  formatDateTime,
  formatDayOfTheWeek,
  formatIsoToDayName,
  formatMinutesToTime,
  formatTime12HourOnly,
  formatTime12Hours,
  formatTime12HoursFromTS,
  formatTime24Hours,
} from '@/filters/date';
import { permissionMapping, permissionTooltip } from '@/filters/permission';
import {
  availabilityStatusLabel,
  availabilityStatusText,
  bookingCutoffLabel,
  bookingCutoffText,
  cancellationTypeLabel,
  cancellationTypeText,
  capacityTypeHint,
  capacityTypeLabel,
  capacityTypeText,
  errorStatusToMessage,
  inventoryTypeLabel,
  inventoryTypeText,
  refundTypeLabel,
  refundTypeText,
  timeEntryModeLabel,
  timeEntryModeText,
} from '@/filters/hints';

export default [
  {
    filter: capitalize,
    filterName: 'capitalize',
  },
  {
    filter: titleize,
    filterName: 'titleize',
  },
  {
    filter: uppercase,
    filterName: 'uppercase',
  },
  {
    filter: lowercase,
    filterName: 'lowercase',
  },
  {
    filter: emptyDash,
    filterName: 'emptyDash',
  },
  {
    filter: emptyNone,
    filterName: 'emptyNone',
  },
  {
    filter: emptyNotProvided,
    filterName: 'emptyNotProvided',
  },
  {
    filter: emptyOngoing,
    filterName: 'emptyOngoing',
  },
  {
    filter: emptyNumberNotProvided,
    filterName: 'emptyNumberNotProvided',
  },
  {
    filter: truncate,
    filterName: 'truncate',
  },
  {
    filter: numberToCurrency,
    filterName: 'numberToCurrency',
  },
  {
    filter: minorNumberToCurrencyNumber,
    filterName: 'minorNumberToCurrencyNumber',
  },
  {
    filter: phoneFormat,
    filterName: 'phoneFormat',
  },
  {
    filter: productStatusName,
    filterName: 'productStatusName',
  },
  {
    filter: formatDate,
    filterName: 'formatDate',
  },
  {
    filter: formatDateTime,
    filterName: 'formatDateTime',
  },
  {
    filter: formatTime24Hours,
    filterName: 'formatTime24Hours',
  },
  {
    filter: formatTime12Hours,
    filterName: 'formatTime12Hours',
  },
  {
    filter: formatTime12HoursFromTS,
    filterName: 'formatTime12HoursFromTS',
  },
  {
    filter: formatTime12HourOnly,
    filterName: 'formatTime12HourOnly',
  },
  {
    filter: formatDayOfTheWeek,
    filterName: 'formatDayOfTheWeek',
  },
  {
    filter: formatDateMmDdYyyy,
    filterName: 'formatDateMmDdYyyy',
  },
  {
    filter: formatMinutesToTime,
    filterName: 'formatMinutesToTime',
  },
  {
    filter: formatIsoToDayName,
    filterName: 'formatIsoToDayName',
  },
  {
    filter: permissionMapping,
    filterName: 'permissionMapping',
  },
  {
    filter: permissionTooltip,
    filterName: 'permissionTooltip',
  },
  {
    filter: inventoryTypeText,
    filterName: 'inventoryTypeText',
  },
  {
    filter: inventoryTypeLabel,
    filterName: 'inventoryTypeLabel',
  },
  {
    filter: capacityTypeText,
    filterName: 'capacityTypeText',
  },
  {
    filter: availabilityStatusLabel,
    filterName: 'availabilityStatusLabel',
  },
  {
    filter: availabilityStatusText,
    filterName: 'availabilityStatusText',
  },
  {
    filter: capacityTypeHint,
    filterName: 'capacityTypeHint',
  },
  {
    filter: capacityTypeLabel,
    filterName: 'capacityTypeLabel',
  },
  {
    filter: cancellationTypeText,
    filterName: 'cancellationTypeText',
  },
  {
    filter: cancellationTypeLabel,
    filterName: 'cancellationTypeLabel',
  },
  {
    filter: refundTypeText,
    filterName: 'refundTypeText',
  },
  {
    filter: refundTypeLabel,
    filterName: 'refundTypeLabel',
  },
  {
    filter: bookingCutoffText,
    filterName: 'bookingCutoffText',
  },
  {
    filter: bookingCutoffLabel,
    filterName: 'bookingCutoffLabel',
  },
  {
    filter: timeEntryModeLabel,
    filterName: 'timeEntryModeLabel',
  },
  {
    filter: timeEntryModeText,
    filterName: 'timeEntryModeText',
  },
  {
    filter: errorStatusToMessage,
    filterName: 'errorStatusToMessage',
  },
  {
    filter: underscoreToSpace,
    filterName: 'underscoreToSpace',
  },
];
