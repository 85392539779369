




















































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { pricingModule } from '@/store/modules/modulePricing';
import { productModule } from '@/store/modules/moduleProduct';
import { companyModule } from '@/store/modules/moduleCompany';
import { httpModule } from '@/store/modules/moduleHttp';
import {
  requiredFieldRules,
  requiredMaxNumberFieldRules,
} from '@/utils/validation-rules';
import { Offer, TimeSlot, OfferCapacity, CapacityPool, Schedule } from '@/models';
import { optionModule } from '@/store/modules/moduleOption';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import faker from 'faker';
import { handleKeyDownOnlyWholeNumeric, isEmpty } from '@/utils/helpers';
import { capacityTypeLabel } from '@/filters/hints';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import _ from 'lodash';
import { format } from 'date-fns';
import { CapacityType } from '@/api/inventory/InventoryModels';

@Component({
  components: {
    RdmReturnToBtn,
  },
})
export default class AvailabilityCreate extends Vue {
  private capacityUnlimited = CapacityType.CapacityLimitUnlimited;
  private capacityLimitPooled = CapacityType.CapacityLimitPooled;
  private capacityLimitByUnit = CapacityType.CapacityLimitByUnit;
  private isUnlimitedCapacity = false;
  private inventoryLoading = false;
  private showInventorySet = false;
  private isValid = true;
  private isEmpty = isEmpty;
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;
  private requiredMaxNumberFieldRules = requiredMaxNumberFieldRules;
  private requiredFieldRules = requiredFieldRules;
  private routerAvailabilities = constants.routes.INVENTORY_MANAGEMENT;
  private routeAddOffer = constants.routes.OFFER_CREATE;
  private selectedScheduleDays = [] as Array<TimeSlot>;
  private dayTimeExpand = [0, 1, 2];
  private allDaysSelected = true;
  private selectedOffer = '';
  private startDate = '';
  private endDate = '' as string;
  private dateNow = new Date();
  private startDateMenu = false;
  private endDateMenu = false;
  private name = '';
  private selectedScheduleId = '';
  private capacity = '';
  private capacityByUnit = {} as any;

  created() {
    scheduleModule.setSchedule({} as Schedule);
  }

  async mounted() {
    this.clearModules();
    await httpModule.getOffers(true);
    await httpModule.loadProducts();
    await httpModule.loadOptions();
    await httpModule.loadCompanyAddresses();
    pricingModule.attachAddressesToOffers();
    pricingModule.filterNonFreesaleOffers();
  }

  preventTyping(event: Event) {
    if (this.selectedOffer && this.selectedOffer.length) event.preventDefault();
  }

  saveForm(next: boolean) {
    if (this.$refs && this.$refs.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (!isValid) {
        return;
      }
      this.saveOfferCapacity(next);
    }
  }

  saveOfferCapacity(next: boolean) {
    const until =
      _.isString(this.endDate) && this.endDate.length > 0 ? this.endDate : null;
    const capacity = {
      id: faker.datatype.uuid(),
      name: this.name,
      offerId: this.selectedOffer,
      scheduleId: this.selectedScheduleId,
      validFrom: this.startDate,
      validUntil: until,
      timeSlots: this.selectedScheduleDays.filter((item) => item.editMode === true),
      capacityLimit: {},
    } as OfferCapacity;
    if (this.SelectedCapacityType === this.capacityLimitPooled) {
      capacity.capacityLimit.pooled = Number(this.capacity);
    }
    if (this.SelectedCapacityType === this.capacityUnlimited) {
      capacity.capacityLimit.unlimited = true;
    }
    if (this.SelectedCapacityType === this.capacityLimitByUnit) {
      const cap = {} as any;
      if (this.capacityByUnit) {
        Object.keys(this.capacityByUnit).map((item) => {
          cap[item] = Number(this.capacityByUnit[item]);
        });
      }
      capacity.capacityLimit.byUnit = cap;
    }
    httpModule
      .updateOfferCapacity(capacity)
      .then(() => {
        if (next) {
          const form: any = this.$refs.form;
          form.reset();
          scheduleModule.setSchedule({} as Schedule);
        } else {
          this.$router.push({
            name: constants.routes.AVAILABILITY_DETAILS,
            params: { id: this.selectedOffer },
          });
        }
      })
      .catch((e) => {
        //TODO: error handle
        console.log(e);
      });
  }

  roundDown(event: any) {
    if (event && event.target && event.target.value) {
      const value = event.target.value;
      if (_.isNumber(_.toNumber(value))) {
        this.capacity = _.toString(Math.floor(_.toNumber(value)));
      }
    }
  }

  roundDownByUnit(event: any, unitId: string) {
    if (event && event.target && event.target.value) {
      const value = event.target.value;
      if (_.isNumber(_.toNumber(value))) {
        this.capacityByUnit[unitId] = _.toString(Math.floor(_.toNumber(value)));
      }
    }
  }

  async onOfferSelect() {
    this.inventoryLoading = true;
    const offer = this.Offers.find((offer: Offer) => offer.id === this.selectedOffer);
    if (offer && offer.id) await httpModule.getOfferPricingList(offer.id);
    if (offer && offer?.productId) {
      await httpModule.productSchedules(offer.productId);
      await httpModule.getProduct(offer.productId);
      await httpModule.getProductTripRoutes();
      const id = companyModule.Organization?.ID || '';
      productModule.setProductCapacityPool({
        orgId: id,
        productId: offer.productId,
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      await httpModule.getProductCapacity();
    }
    if (offer && offer?.optionId) {
      await httpModule.optionSchedules(offer.optionId);
      await httpModule.getOption(offer.optionId);
      await httpModule.getOptionTripRoutes();
      await httpModule.getOptionUnitTravelerTypes(offer.optionId || '');
      const id = companyModule.Organization?.ID || '';
      optionModule.setOptionCapacityPool({
        orgId: id,
        optionId: offer.optionId || '',
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      await httpModule.getOptionCapacity();
    }
    await pricingModule.chooseCapacityType();
    this.showInventorySet = true;
    this.inventoryLoading = false;
  }

  async changeSchedule() {
    pricingModule.setSelectedCapacityType('');
    await httpModule.getSchedule(this.selectedScheduleId);
    this.startDate = this.Schedule.validFrom;
    this.endDate = this.Schedule.validUntil || '';
    this.selectedScheduleDays = this.Schedule.timeSlots;
    this.dayTimeExpand = [];
    this.StructuredSchedule.map((_: any, index: number) =>
      this.dayTimeExpand.push(index),
    );
    this.dayTimeExpand = [
      ...this.dayTimeExpand,
      this.StructuredSchedule.length,
      this.StructuredSchedule.length + 1,
    ];
    await pricingModule.chooseCapacityType();
  }

  changeWeekDay(slots: Array<TimeSlot>, item: any) {
    item.selectedAll = !item.selectedAll;
    slots.map((itm: any) => (itm.editMode = item.selectedAll));
    this.checkIfAllSelected();
  }

  changeTimeSlot(slot: TimeSlot, item: any) {
    slot.editMode = !slot.editMode;
    const check = item.timeSlots.filter((item: any) => item.editMode === true);
    item.selectedAll = check.length === item.timeSlots.length;
    this.checkIfAllSelected();
  }

  changeAll() {
    if (this.allDaysSelected) {
      this.StructuredSchedule.map((item: any) => {
        item.selectedAll = false;
        item.timeSlots.map((slot: TimeSlot) => (slot.editMode = false));
      });
    } else if (!this.allDaysSelected) {
      this.StructuredSchedule.map((item: any) => {
        item.selectedAll = true;
        item.timeSlots.map((slot: TimeSlot) => (slot.editMode = true));
      });
    }
    this.checkIfAllSelected();
  }
  checkIfAllSelected() {
    const check = this.StructuredSchedule.filter((item: any) => {
      return item.selectedAll === true;
    });

    this.allDaysSelected = check.length === this.StructuredSchedule.length;
  }

  unitIdToTravelerName(id: string) {
    const i = this.DisplayTravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.DisplayTravelerTypes[i].text;
    }
    return '';
  }

  clearModules() {
    productModule.clearState();
    optionModule.clearState();
    pricingModule.setSelectedCapacityType('');
  }

  get StructuredSchedule() {
    if (!this.Schedule.id || this.Schedule.timeSlots === null) return [];
    const days = [] as Array<string>;
    let timeSlots = [] as any;
    const structured = [] as any;
    this.Schedule.timeSlots.map((item) => {
      if (!days.includes(item.weekday)) {
        days.push(item.weekday);
      }
    });
    days.map((day) => {
      this.Schedule.timeSlots.map((item) => {
        if (item.weekday === day) {
          item.editMode = true;
          timeSlots.push(item);
        }
      });
      structured.push({
        weekday: day,
        selectedAll: true,
        timeSlots: timeSlots,
      });
      timeSlots = [];
    });
    return structured;
  }

  get OfferCapacity() {
    return pricingModule.OfferCapacity;
  }

  get InventoryType() {
    if (this.Product.availabilityType) {
      return capacityTypeLabel(this.Product.availabilityType);
    } else return '';
  }

  get DisplayTravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get CapacityPoolByUnit() {
    if (this.CapacityPool && this.CapacityPool.byUnit) {
      return this.CapacityPool.byUnit;
    }
    return {};
  }

  get CapacityPool() {
    const productCapacity = this.ProductCapacityPool;
    const optionCapacity = this.OptionCapacityPool;
    if (
      !_.isEmpty(productCapacity) &&
      _.isBoolean(productCapacity.unlimited) &&
      !productCapacity.unlimited
    ) {
      return productCapacity;
    }

    if (
      !_.isEmpty(optionCapacity) &&
      _.isBoolean(optionCapacity.unlimited) &&
      !optionCapacity.unlimited
    ) {
      return optionCapacity;
    }
    return {} as CapacityPool;
  }

  get OptionCapacityPool() {
    if (optionModule.OptionCapacityPool && optionModule.OptionCapacityPool.capacityPool) {
      return optionModule.OptionCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get OptionCapacityPoolByUnit() {
    if (this.OptionCapacityPool && this.OptionCapacityPool.byUnit) {
      return this.OptionCapacityPool.byUnit;
    }
    return {};
  }

  get SelectedCapacityAmount() {
    return pricingModule.SelectedCapacityAmount;
  }

  get SelectedCapacityType() {
    return pricingModule.SelectedCapacityType;
  }

  get SelectedCapacityProductOption() {
    return pricingModule.SelectedCapacityProductOption;
  }

  get SelectedCapacityByUnit() {
    return pricingModule.SelectedCapacityByUnits;
  }

  get PageTitle() {
    return this.$route?.meta?.title || 'Add Inventory';
  }

  get Offers() {
    return pricingModule.Offers.sort((a, b) => {
      const upperA = a.name?.toUpperCase() || '';
      const upperB = b.name?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get LimitedCapacityOffers() {
    return pricingModule.NonFreesaleOffers;
  }

  get Product() {
    return productModule.Product;
  }

  get Option() {
    return optionModule.Option;
  }

  get OfferPrices() {
    return pricingModule.OfferPricings;
  }
  get Loading() {
    return pricingModule.Loading;
  }

  get Products() {
    return productModule.Products;
  }

  get Options() {
    return optionModule.Options;
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get StartDate() {
    const padTo2Digits = (num: any) => num.toString().padStart(2, '0');
    return [
      this.dateNow.getFullYear(),
      padTo2Digits(this.dateNow.getMonth() + 1),
      padTo2Digits(this.dateNow.getDate() - 1),
    ].join('/');
  }

  get StartDateFormatted() {
    if (this.Schedule && this.Schedule.validFrom && this.Schedule.validFrom.length > 0) {
      const startDate = new Date(this.Schedule.validFrom);
      return format(startDate, 'yyyy-MM-dd');
    }
    return format(this.dateNow, 'yyyy-MM-dd');
  }

  get Schedules() {
    return [
      scheduleModule.DefaultSchedule,
      ...productModule.ProductSchedules,
      ...optionModule.OptionSchedules,
    ];
  }

  get Schedule() {
    return scheduleModule.Schedule;
  }

  get ProductCapacityPool() {
    if (
      productModule.ProductCapacityPool &&
      productModule.ProductCapacityPool.capacityPool
    ) {
      return productModule.ProductCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get IsFreesale() {
    return this.SelectedCapacityType === CapacityType.CapacityLimitUnlimited;
  }
}
