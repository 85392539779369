var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""},on:{"mouseenter":_vm.mouseEnterEvent,"mouseleave":_vm.mouseLeaveEvent}},[_c('v-card-title',{staticClass:"min-height-68"},[_c('span',[_vm._v("Locations")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.HoverCard),expression:"HoverCard"}],attrs:{"icon":"","plain":"","color":"primary"},on:{"click":_vm.openEditModal}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-card-text',[(_vm.TripRoute && _vm.TripRoute.departure && _vm.TripRoute.departure.length > 0)?_c('v-row',[_c('v-col',[_c('strong',{staticClass:"text-dark"},[_vm._v("Departure: "+_vm._s(_vm.convertAddressIdToName(_vm.TripRoute.departure)))]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.convertAddressIdToAddress(_vm.TripRoute.departure))))]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.convertAddressIdToLatLong(_vm.TripRoute.departure))))])])],1):_vm._e(),(_vm.TripRoute && _vm.TripRoute.stops && _vm.TripRoute.stops.length > 0)?_vm._l((_vm.TripRoute.stops),function(s,i){return _c('v-row',{key:("trip-stop-" + i)},[_c('v-col',[[_c('div',[_c('strong',{staticClass:"text-dark"},[_vm._v("Stop "+_vm._s(i + 1)+": "+_vm._s(_vm._f("emptyDash")(_vm.convertAddressIdToName(s))))]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.convertAddressIdToAddress(s))))]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.convertAddressIdToLatLong(s))))])])]],2)],1)}):_vm._e(),(_vm.TripRoute && _vm.TripRoute.arrival && _vm.TripRoute.arrival.length > 0)?_c('v-row',[_c('v-col',[_c('strong',{staticClass:"text-dark"},[_vm._v("Arrival: "+_vm._s(_vm.convertAddressIdToName(_vm.TripRoute.arrival)))]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.convertAddressIdToAddress(_vm.TripRoute.arrival))))]),_c('div',[_vm._v(_vm._s(_vm._f("emptyDash")(_vm.convertAddressIdToLatLong(_vm.TripRoute.arrival))))])])],1):_vm._e(),(
          !_vm.TripRoute ||
          _vm.TripRouteEmpty ||
          (_vm.TripRoute &&
            _vm.TripRoute.departure &&
            _vm.TripRoute.departure.length === 0 &&
            _vm.TripRoute.arrival &&
            _vm.TripRoute.arrival.length === 0 &&
            _vm.TripRoute.stops &&
            _vm.TripRoute.stops.length === 0)
        )?_c('v-row',[_c('v-col',[_c('div',[_vm._v("None")])])],1):_vm._e()],2)],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.EditModal),callback:function ($$v) {_vm.EditModal=$$v},expression:"EditModal"}},[_c('option-view-location-modal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }