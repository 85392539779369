




































































































































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import moment from 'moment';
import {
  AvailabilityColorMapping,
  AvailabilitySchedule,
} from '@/api/inventory/InventoryModels';
import _ from 'lodash';
import { constants } from '@/utils/constants';
import { optionModule } from '@/store/modules/moduleOption';

@Component({
  components: {},
  props: {
    option: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class ProductCalendarFullscreen extends Vue {
  private schedules = [] as Array<string>;
  private weekday = [0, 1, 2, 3, 4, 5, 6];
  private type = 'week' as 'year' | 'month' | 'week' | 'day';
  private typeOptions = [
    // { name: 'Year', value: 'year' }, // todo implement year view
    { name: 'Month', value: 'month' },
    { name: 'Week', value: 'week' },
    { name: 'Daily', value: 'day' },
  ];
  private events: any[] = [];
  private calendarValue = '';
  private mode = 'stack';
  private hourCount = 24;
  private firstTime = '00:00';
  private lastTime = '23:59';

  mounted() {
    this.setToday();
    setTimeout(() => {
      this.initScheduleValues();
      this.getEvents();
    }, 500);
  }

  getEvents() {
    if (!moment(this.calendarValue).isValid()) {
      return;
    }
    this.events = [];
    if (this.type === 'week') {
      this.getWeekEvents();
    } else if (this.type === 'month') {
      this.getMonthEvents();
    } else if (this.type === 'day') {
      this.getDailyEvents();
    }
  }

  getWeekEvents() {
    const startWeek = moment(this.calendarValue).startOf('week');
    const endWeek = moment(this.calendarValue).endOf('week');
    const numDays = endWeek.diff(startWeek, 'days');

    this.createDayEvent(startWeek.format());

    for (let i = 0; i < numDays; i++) {
      this.createDayEvent(startWeek.add(1, 'days').format());
    }
  }

  getMonthEvents() {
    const startMonth = moment(this.calendarValue).startOf('month');
    const endMonth = moment(this.calendarValue).endOf('month');
    const numDays = endMonth.diff(startMonth, 'days');

    this.createDayEvent(startMonth.format());

    for (let i = 0; i < numDays; i++) {
      this.createDayEvent(startMonth.add(1, 'days').format());
    }
  }

  getDailyEvents() {
    const today = moment(this.calendarValue).startOf('day');
    this.createDayEvent(today.format());
  }

  initScheduleValues() {
    if (this.ScheduleViews && Array.isArray(this.ScheduleViews)) {
      this.ScheduleViews.forEach((schedule) => {
        if (schedule.id && this.schedules.indexOf(schedule.id) === -1) {
          this.schedules.push(schedule.id);
        }
      });
    }
  }

  createDayEvent(startDate: string) {
    let firstTime = '23:59';
    let lastTime = '00:00';

    this.ScheduleViews.forEach((scheduleView) => {
      const color =
        this.ScheduleColorMappings.find((mapping) => mapping.id === scheduleView.id) ||
        ({ id: '', color: '#489DCB' } as AvailabilityColorMapping);
      const index = this.schedules.findIndex((s) => s === scheduleView.id);
      if (index === -1) {
        return;
      }
      scheduleView.schedules.forEach((availabilitySchedule) => {
        if (!availabilitySchedule.startTime && !availabilitySchedule.endTime) {
          return;
        }
        availabilitySchedule.daysOfWeek?.forEach((day: string) => {
          const selectedDay = moment(startDate);
          const selectedDayNumber = selectedDay.day();
          const scheduleDay = moment().day(day);
          if (scheduleDay.isValid()) {
            const loopDayNumber = scheduleDay.day();
            if (selectedDayNumber === loopDayNumber) {
              const start = selectedDay.format(
                `YYYY-MM-DDT${availabilitySchedule.startTime || '00:00'}:00`,
              );
              const end = selectedDay.format(
                `YYYY-MM-DDT${availabilitySchedule.endTime || '23:59'}:00`,
              );
              const validStart = availabilitySchedule.validFrom;
              const validEnd = availabilitySchedule.validUntil;
              if (moment(start).isBefore(validStart) || moment(start).isAfter(validEnd)) {
                return;
              }

              const firstTimeMoment = selectedDay.format(
                `YYYY-MM-DDT${firstTime || '00:00'}:00`,
              );

              if (moment(start).isBefore(firstTimeMoment)) {
                firstTime = availabilitySchedule.startTime || '00:00';
              }

              const lastTimeMoment = selectedDay.format(
                `YYYY-MM-DDT${lastTime || '23:59'}:00`,
              );

              if (moment(end).isAfter(lastTimeMoment)) {
                lastTime = availabilitySchedule.endTime || '23:59';
              }

              this.events.push({
                name: availabilitySchedule.name,
                start: start,
                end: end,
                color: color.color,
                capacity: availabilitySchedule.maxCapacity || 0,
                timed: true,
                groupId: availabilitySchedule.groupId,
              });
            }
          }
        });
      });
    });

    this.lastTime = lastTime;
    const s = moment().format(`YYYY-MM-DDT${firstTime || '00:00'}:00`);
    const e = moment().format(`YYYY-MM-DDT${lastTime || '23:59'}:00`);
    const globalS = moment().format(`YYYY-MM-DDT${this.firstTime || '00:00'}:00`);
    // const globalE = moment().format(`YYYY-MM-DDT${this.lastTime || '23:59'}:00`);

    if (moment(s).isBefore(globalS)) {
      this.firstTime = firstTime;
    }

    const start = moment(s);
    const end = moment(e);
    const diff = end.diff(start, 'hours');
    if (
      _.isNumber(diff) &&
      ((this.hourCount < 24 && this.hourCount < diff) || this.hourCount === 24)
    ) {
      this.hourCount = diff + 1;
    }
  }

  createDayRepeatingEvents(startDate: string) {
    this.AvailabilitySchedules.forEach((schedule: AvailabilitySchedule) => {
      // todo check if schedule has all elements. If not, skip
      // const color = faker.random.arrayElement(this.colors);
      // const color = this.colors[0];
      const color =
        this.ScheduleColorMappings.find((mapping) => mapping.id === schedule.id) ||
        ({ id: '', color: '#489DCB' } as AvailabilityColorMapping);
      schedule?.daysOfWeek?.forEach((day: string) => {
        const selectedDay = moment(startDate);
        const selectedDayNumber = selectedDay.day();
        const scheduleDay = moment().day(day);
        if (scheduleDay.isValid()) {
          const loopDayNumber = scheduleDay.day();
          if (selectedDayNumber === loopDayNumber) {
            const start = selectedDay.format(`YYYY-MM-DDT${schedule.startTime}:00`);
            const end = selectedDay.format(`YYYY-MM-DDT${schedule.endTime}:00`);
            const startRange = moment(start)
              .add(schedule.eventDuration || 60, 'minutes')
              .format('YYYY-MM-DDTHH:mm:ss');
            const exDate = moment(start).format('YYYY-MM-DD');
            const validStart = schedule.validFrom;
            const validEnd = schedule.validUntil;

            if (
              moment(start).isBefore(validStart) ||
              moment(startRange).isAfter(validEnd)
            ) {
              return;
            }
            if (schedule.excludedDays?.indexOf(exDate) === -1) {
              this.events.push({
                name: schedule.name,
                start: start,
                end: startRange,
                color: color.color,
                capacity: schedule.maxCapacity || 0,
                timed: true,
              });

              let z = 0;
              let current = startRange;
              let currentRange = startRange;

              if (schedule.eventPadding) {
                const paddingRange = moment(startRange)
                  .add(schedule.eventPadding || 0, 'minutes')
                  .format('YYYY-MM-DDTHH:mm:ss');
                current = paddingRange;
                currentRange = paddingRange;
              }
              while (moment(current).isBefore(end)) {
                if (z > 100) {
                  break;
                }

                z++;
                currentRange = moment(current)
                  .add(schedule.eventDuration || 0, 'minutes')
                  .format('YYYY-MM-DDTHH:mm:ss');

                if (
                  moment(current).isBefore(validStart) ||
                  moment(currentRange).isAfter(validEnd)
                ) {
                  continue;
                }

                if (schedule.excludedDays?.indexOf(exDate) === -1) {
                  this.events.push({
                    name: schedule.name,
                    start: current,
                    end: currentRange,
                    color: color.color,
                    capacity: schedule.maxCapacity || 0,
                    timed: true,
                  });
                }
                current = currentRange;

                if (schedule.eventPadding) {
                  current = moment(current)
                    .add(schedule.eventPadding || 0, 'minutes')
                    .format('YYYY-MM-DDTHH:mm:ss');
                }
              }
            }
          }
        }
      });
    });
  }

  setToday() {
    this.calendarValue = moment().format('YYYY-MM-DD');
  }

  prev() {
    const calendar = this.$refs.calendar as any;
    if (!_.isUndefined(calendar)) {
      calendar.prev();
    }
  }
  next() {
    const calendar = this.$refs.calendar as any;
    if (!_.isUndefined(calendar)) {
      calendar.next();
    }
  }

  intervalStyling(interval: any) {
    if (interval && interval.past) {
      return '.disabled-date';
    } else if (interval && (interval.weekday === 0 || interval.weekday === 6)) {
      return '.disabled-date';
    }
    return '.disabled-date';
  }

  selectEvent(event: any) {
    if (event?.day?.date) {
      this.calendarValue = event.day.date;
    }
    const groupId = event?.event?.groupId || '';
    this.toggleType(groupId);
  }

  selectMore(event: any) {
    if (event?.date) {
      this.calendarValue = event.date;
    }
    this.toggleType();
  }

  toggleType(groupId?: string) {
    if (this.type === 'month') {
      this.type = 'week';
    } else if (this.type === 'week') {
      this.type = 'day';
    } else if (this.type === 'day') {
      // todo go to edit page for group
      if (groupId) {
        const scheduleView = this.ScheduleViews.find(
          (scheduleView) => scheduleView.id === groupId,
        );

        if (scheduleView) {
          productModule.setSelectedScheduleView(scheduleView);
          this.$router
            .push({ name: constants.routes.PRODUCT_TIME_CAPACITY_EDIT })
            .catch(() => {});
        }
      }
    }
  }

  get Product() {
    return productModule.Product;
  }

  get Option() {
    return optionModule.Option;
  }

  get AvailabilitySchedules() {
    if (this.$props.option) {
      return productModule.AvailabilitySchedules.filter((availabilitySchedule) => {
        return availabilitySchedule.optionId === this.Option.id;
      });
    }
    return productModule.AvailabilitySchedules.filter((availabilitySchedule) => {
      return availabilitySchedule.productId === this.Product.id;
    });
  }

  get ScheduleColorMappings() {
    return productModule.ScheduleColorMappings;
  }

  get ScheduleViews() {
    if (this.$props.option) {
      return productModule.ScheduleViews.filter(
        (scheduleViews) => scheduleViews.optionId === this.Option.id,
      );
    }
    return productModule.ScheduleViews.filter(
      (scheduleViews) => scheduleViews.productId === this.Product.id,
    );
  }
}
