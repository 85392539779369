















import { Component, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import { DeliveryMethods } from '@/models/ApiV2Models';
import { productModule } from '@/store/modules/moduleProduct';
import { constants } from '@/utils/constants';
import TicketFormatViewEdit from '@/components/product/detail/components/TicketFormatViewEdit.vue';
import DeliveryMethodsViewEdit from '@/components/product/detail/components/DeliveryMethodsViewEdit.vue';
import RedemptionMethodViewEdit from '@/components/product/detail/components/RedemptionMethodViewEdit.vue';

@Component({
  components: {
    TicketFormatViewEdit,
    DeliveryMethodsViewEdit,
    RedemptionMethodViewEdit,
  },
  props: {
    option: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class ProductTicketingSummary extends Vue {
  private deliveryMethodModel = [] as Array<DeliveryMethods>;

  mounted() {
    if (
      !this.$props.option &&
      _.isArray(
        this.SelectedProduct &&
          this.SelectedProduct &&
          this.SelectedProduct.ticketDelivery?.deliveryMethods,
      )
    ) {
      this.deliveryMethodModel =
        this.SelectedProduct.ticketDelivery?.deliveryMethods || [];
    } else {
      // this.deliveryMethodModel = this.SelectedOption.deliveryMethods;
    }

    this.$root.$on(constants.events.PRODUCT_LOAD_COMPLETE, () => {});
  }

  get SelectedProduct() {
    return productModule.Product;
  }

  get DeliveryMethods() {
    if (this.SelectedProduct && this.SelectedProduct.ticketDelivery) {
      return this.SelectedProduct.ticketDelivery.deliveryMethods || [];
    }
    return [];
  }

  set DeliveryMethods(methods: Array<DeliveryMethods>) {
    if (!this.SelectedProduct.ticketDelivery) {
      this.SelectedProduct.ticketDelivery = {};
    }
    this.SelectedProduct.ticketDelivery.deliveryMethods = methods;
  }
}
