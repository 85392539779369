





















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RequestConnectionWizardStage1 extends Vue {
  agreement(result: boolean) {
    if (result) {
      this.$emit('connection-request-confirmation', true);
    } else {
      this.$emit('connection-request-confirmation', false);
    }
  }
}
