import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Event } from '@/models';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-events',
  namespaced: true,
})
class ModuleEvents extends VuexModule {
  /* Data */
  private loading = false;
  private offerUnpublishedEvents: Array<Event> = [];

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get OfferUnpublishedEvents(): Array<Event> {
    return this.offerUnpublishedEvents;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setOfferUnpublishedEvents(events: Array<Event>) {
    this.offerUnpublishedEvents = events;
  }

  /* Actions */
  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
  }
}

export const eventsModule = getModule(ModuleEvents, store);
