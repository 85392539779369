





























































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import RdmEditNavBtn from '@/components/common/button/RdmEditNavBtn.vue';
import { taxesAndFeesModule } from '@/store/modules/moduleTaxAndFees';
import { centsToDollars } from '@/utils/helpers';

@Component({
  components: {
    RdmCollapsableCard,
    RdmEditNavBtn,
    RdmReturnToBtn,
  },
})
export default class TaxesAndFeesView extends Vue {
  private centsToDollars = centsToDollars;
  private routerTaxesAndFeesList = constants.routes.TAXES_AND_FEES;
  private routerTaxesAndFeesEdit = constants.routes.TAXES_AND_FEES_EDIT;
  private parentName = constants.titles.TAXES_AND_FEES;

  get SelectedTaxAndFee() {
    return taxesAndFeesModule.SingleTaxAndFee;
  }
  get PageTitle() {
    return this.$route?.meta?.title || this.parentName;
  }

  private async mounted() {
    await taxesAndFeesModule.selectTaxAndFee(this.$route.params.id);
  }
}
