

































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { codes } from '@/utils/codeConstants';
import { appModule } from '@/store/modules/moduleApp';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { FeedbackMessage } from '@/api/wps/WpsModels';
import { profileModule } from '@/store/modules/moduleProfile';
import { httpModule } from '@/store/modules/moduleHttp';
import { requiredFieldRules } from '@/utils/validation-rules';

@Component({
  components: {
    RdmReturnToBtn,
  },
})
export default class Feedback extends Vue {
  private backRoute = constants.routes.TAXES_AND_FEES as string;
  private parentName = constants.titles.TAXES_AND_FEES as string;
  private requiredFieldRules = requiredFieldRules;
  private feedbackForm = false;
  private topic = 'Additional taxes Required';
  private channelsTopic = 'Request for making channels visible';
  private details = '';
  // todo: use module loader when BE is ready
  private saving = false;
  private code = codes.TAXES_AND_FEES_FEEDBACK_SENT;

  private async mounted() {
    if (
      this.prevRoute.path.includes(constants.routes.CHANNEL_AVAILABLE_LIST) ||
      this.prevRoute.path.includes(constants.routes.CHANNEL_LIST)
    ) {
      this.parentName = constants.titles.AVAILABLE_CHANNELS;
      this.backRoute = constants.routes.CHANNEL_AVAILABLE_LIST;
      this.topic = this.channelsTopic;
      this.code = codes.CHANNELS_FEEDBACK_SENT;
    }
  }

  private showSuccessMessage() {
    appModule.addMessageSuccess(this.code);
  }

  private submit() {
    const org = this.user && this.user.org_code ? this.user.org_code : 'UNKNOWN';
    const user = this.user && this.user.username ? this.user.username : 'UNKNOWN';
    const feedback = {
      org: org,
      user: user,
      subject: this.topic,
      message: this.details,
    } as FeedbackMessage;
    this.saving = true;
    httpModule
      .sendEmail(feedback)
      .then(() => {
        this.showSuccessMessage();
        this.$router.back();
      })
      .catch(() => {})
      .finally(() => {
        this.saving = false;
      });
    // setTimeout(() => {
    //   this.showSuccessMessage();
    //   this.$router.back();
    //   this.saving = false;
    // }, 1200);
  }

  get user() {
    return profileModule.Profile;
  }

  get prevRoute() {
    return appModule.PreviousRoute;
  }
}
