




























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FeatureFlag extends Vue {
  private headers: any = [
    { text: 'Feature', value: 'feature' },
    { text: 'Description', value: 'description', width: '400px' },
    { text: 'State', value: 'state', align: 'center' },
    { text: 'Actions', value: 'actions', align: 'center' },
  ];

  private features: any = [];

  mounted() {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateFeatureState(feature: any) {}
}
