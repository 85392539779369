













































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { httpModule } from '@/store/modules/moduleHttp';
import { channelModule } from '@/store/modules/moduleChannel';
import { pricingModule } from '@/store/modules/modulePricing';
import RateCardNameView from '@/components/rateCard/view/RateCardNameView.vue';
import RateCardDetailsView from '@/components/rateCard/view/RateCardDetailsView.vue';
import RateCardAdjustmentsView from '@/components/rateCard/view/RateCardAdjustmentsView.vue';
import RateCardOfferNameView from '@/components/rateCard/view/RateCardOfferNameView.vue';
import RateCardOfferView from '@/components/rateCard/view/RateCardOfferView.vue';
import RdmBanner from '@/components/common/banner/RdmBanner.vue';
import { Offer } from '@/models';
import { incrementMeta, tablePaginationOptions } from '@/utils/helpers';

@Component({
  components: {
    RdmReturnToBtn,
    RateCardNameView,
    RateCardDetailsView,
    RateCardAdjustmentsView,
    RateCardOfferNameView,
    RateCardOfferView,
    RdmBanner,
  },
})
export default class RateCardView extends Vue {
  private routerProductList = constants.routes.CHANNEL_RATE_CARDS;
  private parentName = constants.titles.RATE_CARDS;
  private selectedOffers = [];
  private channelToRemove = {} as any;
  private modalIsOpen = false;
  private offersHeaders: Array<Record<string, any>> = [
    { text: 'Name', value: 'name', align: 'left', sortable: false },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];

  private rateCardChannelsHeaders: Array<Record<string, any>> = [
    {
      text: 'Channel Name',
      align: 'left',
      filterable: false,
      value: 'name',
    },
    { text: 'Channel ID', align: 'left', sortable: true, value: 'id' },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];

  async mounted() {
    if (this.$route.params?.id) {
      await httpModule.getRateCard(this.$route.params?.id);
      await httpModule.getOffers();
      await httpModule.getRateCardChannel(this.$route.params?.id);
      await httpModule.getRateCardSprs(this.$route.params?.id);
    }
  }

  get paginationOptions() {
    return tablePaginationOptions(this.RateCardChannels);
  }

  get RateCardSpr() {
    return channelModule.RateCardSpr[0] || null;
  }

  get RateCardChannels() {
    return channelModule.RateCardChannels;
  }

  get PageTitle() {
    return this.$route?.meta?.title || this.parentName;
  }

  get Offers() {
    return pricingModule.Offers;
  }

  get RateCard() {
    return channelModule.RateCard;
  }

  get Loading() {
    return channelModule.Loading;
  }

  get EditOffersState() {
    return channelModule.EditOffersState;
  }

  getOfferRoute(offer: Offer) {
    return { name: constants.routes.OFFER_EDIT, params: { id: offer.id } };
  }

  toggleEditOffersState() {
    channelModule.setEditOffersState();
  }

  private openModal(channel: any) {
    this.channelToRemove = channel;
    this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  async disconnectChannel() {
    if (this.RateCardSpr !== null) {
      await httpModule.deleteChannelBinding({
        supplierId: this.RateCardSpr.supplier.id,
        productId: this.RateCardSpr.product.id,
        channelId: this.channelToRemove.id,
      });
      await httpModule.deleteRateCardChannel({
        rateCardId: channelModule.RateCard.id,
        channelId: this.channelToRemove.id,
      });
      this.closeModal();
    }
  }

  async saveChanges() {
    const offerIds = this.selectedOffers.map((item: Offer) => item.id);
    const updatedRateCard = {
      ...this.RateCard,
      offers: offerIds,
      meta: incrementMeta(this.RateCard.meta),
      version: this.RateCard.meta?.version ? this.RateCard.meta.version + 1 : 1,
    };
    await httpModule.updateRateCard(updatedRateCard);
  }
}
