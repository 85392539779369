var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Offer")]),(_vm.Offers.length > 0)?_c('div',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.Loading,"headers":_vm.offerHeaders,"items":_vm.Offers,"items-per-page":10,"sort-by":['id'],"hide-default-footer":(_vm.Offers && _vm.Offers.length === 0) || !_vm.Offers,"footer-props":{
              itemsPerPageOptions: _vm.paginationOptions,
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
            }},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("titleize")(item.name))+" ")]}},{key:"item.view",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"to":_vm.getOfferRoute(item),"color":"primary","plain":"","disabled":!item.id}},[_vm._v("View")])]}}],null,false,759860000)})],1)],1)],1)],1):_c('div',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Create an offer using this product to make it sellable.")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","color":"primary","to":{ name: _vm.routeOfferList }}},[_vm._v("View Offers")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }