
















































































































import { Component, Vue } from 'vue-property-decorator';
import {
  passwordLoginRules,
  passwordMatchRules,
  requiredFieldRules,
} from '@/utils/validation-rules';
import { RequestResetPassword } from '@/api/wps/BackplaneModels';
import { QueryResetPassword } from '@/api/wps/QueryModels';
import backplaneClient from '@/api/backplane/BackplaneClient';

@Component
export default class ResetPassword extends Vue {
  private password = '';

  private confirmPassword = '';

  private validReset = false;

  private loading = false;

  private passwordLoginRules = passwordLoginRules;
  private requiredFieldRules = requiredFieldRules;
  private verifyPasswordRules = passwordMatchRules;

  private errorAlert = false;

  private successAlert = false;

  private showPassword = false;
  private resetPasswordError = false;

  private hash = '';
  private account = '';
  private ts = '';

  private validateForm() {
    const { resetForm } = this.$refs as any;
    if (resetForm) {
      const valid = resetForm.validate();
      if (valid) {
        // todo call password reset service
        this.updatePassword();
      }
    }
  }
  private updatePassword() {
    console.log('sss');
    this.loading = true;
    this.successAlert = false;
    this.errorAlert = false;
    const doPasswordsMatch = this.password === this.confirmPassword;
    if (doPasswordsMatch) {
      this.hash = (this.$route?.query?.hash as string) ?? '';
      this.account = (this.$route?.query?.account as string) ?? '';
      this.ts = (this.$route?.query?.ts as string) ?? '';

      if (!this.paramState) {
        this.resetPasswordError = true;
        this.loading = false;
        return;
      }

      const query: QueryResetPassword = {
        account: this.account,
        hash: this.hash,
        ts: this.ts,
      };
      const request: RequestResetPassword = {
        newPassword: this.password,
      };
      backplaneClient
        .resetPassword(query, request)
        .toPromise()
        .catch(() => {
          this.resetPasswordError = true;
        })
        .finally(() => {
          this.loading = false;
          this.$router.push('/login');
        });
    }
  }

  private get alertType() {
    return this.errorAlert ? 'error' : 'success';
  }

  private get paramState(): boolean {
    return !!(
      this.hash &&
      this.hash.length > 0 &&
      this.ts &&
      this.ts.length > 0 &&
      this.account &&
      this.account.length > 0
    );
  }
}
