



























































import { Component, Vue } from 'vue-property-decorator';
import AddTravelerType from '@/components/product/list/AddTravelerType.vue';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import ProductListingSummary from '@/views/product/ProductListingSummary.vue';
import OptionListingSummary from '@/views/product/OptionListingSummary.vue';
import { productModule } from '@/store/modules/moduleProduct';
import { constants } from '@/utils/constants';
import RdmUnsavedChangesModal from '@/components/common/alert/RdmUnsavedChangesModal.vue';
import RdmMessage from '@/components/common/alert/RdmMessage.vue';

@Component({
  components: {
    OptionListingSummary,
    ProductListingSummary,
    AddTravelerType,
    RdmMessage,
    RdmStatusIndicator,
    RdmUnsavedChangesModal,
  },
  props: {
    removeHeader: {
      type: String,
      default: 'Remove Product',
    },
    removeMessage: {
      type: String,
      default: 'Are you sure you want to remove this product?',
    },
    dupeHeader: {
      type: String,
      default: 'Duplicate Product',
    },
    dupeMessage: {
      type: String,
      default: 'Are you sure you want to duplicate this product?',
    },
  },
})
export default class ProductListing extends Vue {
  private tabs: Array<any> = [];

  private routeProduct = constants.routes.PRODUCT_LIST;
  private routeOptions = constants.routes.OPTION_LIST;
  private routeTravelerList = constants.routes.TRAVELER_LIST;

  private productTab = 0;

  private action: any = function () {};

  mounted() {
    this.tabs = [
      { id: 1, name: 'Products', route: `/products` },
      { id: 2, name: 'Options', route: `/products/options` },
      { id: 3, name: 'Traveler Types', route: `/products/traveler-types` },
    ];
    this.action = function () {
      // Return true to save
      return true;
    };
  }

  destroyed() {
    // if (!this.subscription.closed) this.subscription.unsubscribe();
  }

  addDialog() {
    // this.addModal = true;
    if (this.CurrentRoute === this.routeProduct) {
      // this.addProductModal = true;
      this.$router.push({ name: constants.routes.PRODUCT_CREATE }).catch();
    } else if (this.CurrentRoute === this.routeOptions) {
      // this.addOptionModal = true;
      this.$router.push({ name: constants.routes.OPTION_CREATE }).catch();
    } else if (this.CurrentRoute === this.routeTravelerList) {
      // this.addTravelerTypeModal = true;
      this.$router.push({ name: constants.routes.TRAVELER_CREATE }).catch();
    }
  }

  get Loading(): boolean {
    return productModule.Loading;
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get productSearch() {
    return productModule.SearchText;
  }

  set productSearch(search: string) {
    productModule.setSearchText(search);
  }

  get CurrentRoute() {
    return this.$route?.name;
  }
}
