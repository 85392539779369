





































































import { Component, Vue } from 'vue-property-decorator';
import { optionModule } from '@/store/modules/moduleOption';
import { constants } from '@/utils/constants';
import OptionViewLocationModal from '@/components/product/modal/OptionViewLocationModal.vue';
import { companyModule } from '@/store/modules/moduleCompany';
import _ from 'lodash';
@Component({
  components: { OptionViewLocationModal },
})
export default class OptionViewLocation extends Vue {
  private hoverCard = false;

  openEditModal() {
    this.$root.$emit(constants.events.OPTION_LOCATION_EDIT_MODAL_CLEAR_VALIDATION);
    optionModule.configureLocationForm();
    optionModule.setEditOptionLocationModal(true);
  }

  convertAddressIdToAddress(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.streetAddress && address.streetAddress.length > 0) {
        return `${address.streetAddress}, ${address.city}, ${address.region} ${address.postalCode}`;
      }
    }
    return '';
  }

  convertAddressIdToName(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.name && address.name.length > 0) {
        return address.name;
      }
    }
    return '';
  }

  convertAddressIdToLatLong(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.latitude && address.longitude) {
        return `${address.latitude}, ${address.longitude}`;
      }
    }
    return '';
  }

  mouseEnterEvent() {
    this.hoverCard = true;
  }

  mouseLeaveEvent() {
    this.hoverCard = false;
  }

  get HoverCard() {
    return this.hoverCard;
  }

  get Option() {
    return optionModule.Option;
  }

  get TripRoute() {
    return optionModule.TripRoute;
  }

  get EditModal() {
    return optionModule.EditOptionLocationModal;
  }

  get TripRouteEmpty() {
    return _.isEmpty(this.TripRoute);
  }
}
