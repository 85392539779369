


















































import { Component, Vue } from 'vue-property-decorator';
import {
  BookingCutoffOptions,
  CancellationPolicyOptions,
  ProductPolicy,
  RefundablePolicyOptions,
} from '@/models';
import { optionModule } from '@/store/modules/moduleOption';
import _ from 'lodash';
import { formatDuration, intervalToDuration } from 'date-fns';
import OptionViewPolicyModal from '@/components/product/modal/OptionViewPolicyModal.vue';
@Component({
  components: { OptionViewPolicyModal },
})
export default class OptionViewPolicy extends Vue {
  private cancellationType = 0 as CancellationPolicyOptions;
  private baseCancellationType = 0 as CancellationPolicyOptions;
  private refundable = 0 as RefundablePolicyOptions;
  private baseRefundable = 0 as RefundablePolicyOptions;
  private bookingCutoff = 0 as BookingCutoffOptions;
  private baseBookingCutoff = 0 as BookingCutoffOptions;
  private hoverCard = false;
  private cancelCutoff = '';
  private bookingCutoffBefore = '';
  private cancellationCutoff = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private bookingCutoffTimes = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private baseCancellationCutoff = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  private baseBookingCutoffTimes = {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  mounted() {
    this.configureCancelPolicy();
  }

  openEditModal() {
    optionModule.setEditOptionPolicyModal(true);
  }

  isBoolean(value: any): boolean {
    return _.isBoolean(value);
  }

  configureCancelPolicy() {
    const policy = this.Policy;
    if (!policy || _.isEmpty(policy)) {
      return;
    }

    this.refundable = policy.refundable
      ? RefundablePolicyOptions.YES
      : RefundablePolicyOptions.NO;
    this.baseRefundable = policy.refundable
      ? RefundablePolicyOptions.YES
      : RefundablePolicyOptions.NO;
    if (policy.cancellable) {
      this.cancellationType = CancellationPolicyOptions.YES;
      this.baseCancellationType = CancellationPolicyOptions.YES;
      if (policy.cutOffTime && policy.cutOffTime > 0) {
        this.cancelCutoff = formatDuration(
          intervalToDuration({ start: 0, end: policy.cutOffTime * 1000 * 60 }),
          { format: ['days', 'hours', 'minutes'], delimiter: ', ' },
        );
        const cancelCutoff = this.convertMinutesToTimes(policy.cutOffTime || 0);
        this.cancellationCutoff = cancelCutoff;
        this.baseCancellationCutoff = cancelCutoff;
      }
    } else if (!policy.cancellable) {
      this.cancellationType = CancellationPolicyOptions.NO;
      this.baseCancellationType = CancellationPolicyOptions.NO;
    }

    if (policy.cutOffTime && policy.cutOffTime > 0) {
      this.bookingCutoff = BookingCutoffOptions.YES;
      this.bookingCutoffBefore = formatDuration(
        intervalToDuration({ start: 0, end: policy.cutOffTime * 1000 * 60 }),
        { format: ['days', 'hours', 'minutes'], delimiter: ', ' },
      );
    } else {
      this.bookingCutoff = BookingCutoffOptions.NO;
    }
  }

  convertMinutesToTimes(timeMinutes: number) {
    const day = 24 * 60;
    const days = timeMinutes / day;
    const daysR = timeMinutes % day;
    const hours = daysR / 60;
    const hoursR = daysR % 60;
    return {
      days: days,
      hours: hours,
      minutes: hoursR,
    };
  }

  mouseEnterEvent() {
    this.hoverCard = true;
  }

  mouseLeaveEvent() {
    this.hoverCard = false;
  }

  get HoverCard() {
    return this.hoverCard;
  }

  get Option() {
    return optionModule.Option;
  }

  get Policy(): ProductPolicy {
    if (this.Option && this.Option.policy) {
      return this.Option.policy;
    }
    return {} as ProductPolicy;
  }

  get CancellationTypes() {
    return [CancellationPolicyOptions.YES, CancellationPolicyOptions.NO];
  }

  get RefundValues() {
    return [RefundablePolicyOptions.YES, RefundablePolicyOptions.NO];
  }

  get BookingCutoff() {
    return [BookingCutoffOptions.YES, BookingCutoffOptions.NO];
  }

  get EditMode() {
    return optionModule.EditModeCancellationPolicy;
  }

  get EditModal() {
    return optionModule.EditOptionPolicyModal;
  }
}
