















































































































































import { Component, Vue } from 'vue-property-decorator';
import { FromSmallestUnit } from '@/utils/currency';
import { getVoucherStatusByValue } from '@/utils/web-redemption';
import moment from 'moment';
import {
  DatePickerOptionsDate,
  DatePickerOptionsDateRange,
  DateSearchTypeList,
} from '@/utils/componentHelpers';
import { getRedemptionReportModule } from '@/store/modules/moduleReportRedemptions';
import { profileModule } from '@/store/modules/moduleProfile';
import { tablePaginationOptions } from '@/utils/helpers';

function dateFormatted(date: moment.Moment | null, filter: any): string {
  try {
    if (!date) return 'Any date';
    return filter(date);
  } catch {
    return '';
  }
}

@Component
export default class WebRedemptionReport extends Vue {
  /* DATA */

  // modules
  private redemptionReportModule = getRedemptionReportModule();

  // dialog appearance
  private dateFromModal = false;
  private dateUntilModal = false;

  // date picker values - OLD todo
  private fromDate = '';
  private untilDate = '';

  // date picker values - NEW todo
  private dateRange = [];
  private datePick = '';
  private dateRangeType = 'dateRange';

  // other
  private search = '';

  private isOnlyMine = true;

  public reportHeaders: Array<Record<string, any>> = [
    { text: 'Supplier', align: 'left', sortable: true, value: 'supplierName' },
    { text: 'Reseller', align: 'left', sortable: true, value: 'resellerName' },
    { text: 'Status', align: 'left', sortable: false, value: 'status' },
    { text: 'Net Amount', align: 'left', sortable: false, value: 'netAmount' },
    { text: 'Retail Amount', align: 'left', sortable: false, value: 'retailAmount' },
    { text: 'Voucher ID', align: 'left', sortable: false, value: 'voucherID' },
    {
      text: 'Time Redeemed',
      align: 'left',
      sortable: true,
      sort: (a: any, b: any) =>
        moment.unix(a.seconds).isBefore(moment.unix(b.seconds)) ? -1 : 1,
      value: 'timestamp',
    },
    { text: 'Operator', align: 'left', sortable: true, value: 'operatorID' },
    // { text: 'ID', align: 'left', sortable: true, value: 'id' },
    // { text: 'Lead Traveler', sortable: true, value: 'leadTraveler' },
    // { text: 'Device', sortable: true, value: 'source.device_id' },
    // { text: 'Currency', sortable: true, value: 'net.currency_code' },
  ];

  private options = DatePickerOptionsDateRange(this.$i18n);

  private optionSingleDay = DatePickerOptionsDate(this.$i18n);

  private dateSearchTypeList = DateSearchTypeList(this.$i18n);

  /* COMPUTED */

  get paginationOptions() {
    return tablePaginationOptions(this.Redemptions);
  }

  private get Redemptions(): any[] {
    return this.redemptionReportModule.Redemptions;
  }

  private get FormattedRedemptions(): any[] {
    const redemptions = [] as Array<any>;
    this.Redemptions.map((r: any) => {
      redemptions.push({
        id: r.id,
        supplierName: r?.supplier?.name || '',
        resellerName: r?.reseller?.name || '',
        status: this.redemptionStatus(r),
        netAmount: this.getAmount(r?.net?.amount || 0, r?.net?.currency_code || 'USD'),
        retailAmount: this.getAmount(
          r?.retail?.amount || 0,
          r?.net?.currency_code || 'USD',
        ),
        voucherID: r?.reseller_ref || r?.supplier_ref || '',
        timestamp: r?.timestamp || { seconds: 0 },
        operatorID: r?.source?.operator_id || '',
        leadTraveler: r?.leadTraveler || '',
        deviceId: r?.source?.deviceId || '',
        currencyCode: r?.net?.currency_code || 'USD',
      });
    });
    return redemptions;
  }

  private get FromDateFormatted() {
    return dateFormatted(
      this.redemptionReportModule.nested.FromDate,
      this.$options && this.$options.filters && this.$options.filters.formatDateMmDdYyyy,
    );
  }

  private get UntilDateFormatted() {
    return dateFormatted(
      this.redemptionReportModule.nested.UntilDate,
      this.$options && this.$options.filters && this.$options.filters.formatDateMmDdYyyy,
    );
  }

  private get Loading(): boolean {
    return this.redemptionReportModule.nested.Loading;
  }

  private get UserName(): string {
    if (this.isOnlyMine) {
      if (!profileModule.Profile?.username) {
        // todo alert no profile
      } else {
        return profileModule.Profile?.username;
      }
    }
    return '';
  }

  /* HOOKS */

  async created() {
    await this.redemptionReportModule.initialize(this.UserName);
  }

  destroyed() {
    this.redemptionReportModule.clearState();
  }

  /* METHODS */

  // event handlers

  private async changeDates(which: string) {
    try {
      this.dateFromModal = false;
      this.dateUntilModal = false;

      const dateRange: {
        fromDate?: moment.Moment | null;
        untilDate?: moment.Moment | null;
      } = {};

      if (which === 'from') {
        dateRange.fromDate = this.fromDate ? moment(this.fromDate) : null;
        this.fromDate = '';
      } else if (which === 'until') {
        dateRange.untilDate = this.untilDate ? moment(this.untilDate) : null;
        this.untilDate = '';
      } else if (which === 'range') {
        if (!this.dateRange) return;

        dateRange.fromDate = this.dateRange[0] ? moment(this.dateRange[0]) : null;
        dateRange.untilDate = this.dateRange[1] ? moment(this.dateRange[1]) : null;
      } else if (which === 'dateSelect') {
        if (this.dateRangeType === 'fromDate') {
          dateRange.fromDate = this.datePick ? moment(this.datePick) : null;
          dateRange.untilDate = null;
        } else if (this.dateRangeType === 'untilDate') {
          dateRange.untilDate = this.datePick ? moment(this.datePick) : null;
          dateRange.fromDate = null;
        }
      }

      await this.redemptionReportModule.changeDates({
        username: this.UserName,
        range: dateRange,
      });
    } catch {
      // todo send alert to backend
    }
  }

  private clearValues() {
    this.dateRange = [];
    this.datePick = '';
  }

  private refresh() {
    this.redemptionReportModule
      .searchRedemptions({ username: this.UserName })
      .catch(() => {
        // todo send alert to backend
      });
  }

  // template helpers

  private redemptionStatus(item: any) {
    try {
      return getVoucherStatusByValue(item.status);
    } catch {
      return '';
    }
  }

  private getAmount(amount: number, currency?: string) {
    try {
      return FromSmallestUnit(amount, currency || 'USD');
    } catch {
      return '';
    }
  }
}
