
























import { Component, Vue } from 'vue-property-decorator';
import { profileModule } from '@/store/modules/moduleProfile';

@Component
export default class ReportHome extends Vue {
  private activeTab = '/reports';

  private tabs: Array<any> = [
    { id: 1, name: 'Customer Manifest', route: '/reports' },
    { id: 2, name: 'All Bookings', route: '/reports/all-bookings' },
  ];

  get user() {
    return profileModule.Profile;
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }
}
