const data = [
  {
    name: 'Abidjan',
    countryCode: 'CI',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'CIV',
  },
  {
    name: 'Abidjan',
    countryCode: 'BF',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'BFA',
  },
  {
    name: 'Abidjan',
    countryCode: 'GH',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'GHA',
  },
  {
    name: 'Abidjan',
    countryCode: 'GM',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'GMB',
  },
  {
    name: 'Abidjan',
    countryCode: 'GN',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'GIN',
  },
  {
    name: 'Abidjan',
    countryCode: 'IS',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'ISL',
  },
  {
    name: 'Abidjan',
    countryCode: 'ML',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'MLI',
  },
  {
    name: 'Abidjan',
    countryCode: 'MR',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'MRT',
  },
  {
    name: 'Abidjan',
    countryCode: 'SH',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
  },
  {
    name: 'Abidjan',
    countryCode: 'SL',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'SLE',
  },
  {
    name: 'Abidjan',
    countryCode: 'SN',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'SEN',
  },
  {
    name: 'Abidjan',
    countryCode: 'TG',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'TGO',
  },
  {
    name: 'Abidjan',
    countryCode: 'SH',
    value: 'Africa/Abidjan',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'SHN',
  },
  {
    name: 'Algiers',
    countryCode: 'DZ',
    value: 'Africa/Algiers',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CET',
    },
    countryCodeIso: 'DZA',
  },
  {
    name: 'Bissau',
    countryCode: 'GW',
    value: 'Africa/Bissau',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'GNB',
  },
  {
    name: 'Cairo',
    countryCode: 'EG',
    value: 'Africa/Cairo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EET',
    },
    countryCodeIso: 'EGY',
  },
  {
    name: 'Casablanca',
    countryCode: 'MA',
    value: 'Africa/Casablanca',
    offset: {
      standard: '+01:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: '+01',
      daylightSaving: '+00',
    },
    countryCodeIso: 'MAR',
  },
  {
    name: 'Ceuta',
    countryCode: 'ES',
    value: 'Africa/Ceuta',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'ESP',
  },
  {
    name: 'El Aaiun',
    countryCode: 'EH',
    value: 'Africa/El_Aaiun',
    offset: {
      standard: '+01:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: '+01',
      daylightSaving: '+00',
    },
    countryCodeIso: 'ESH',
  },
  {
    name: 'Johannesburg',
    countryCode: 'ZA',
    value: 'Africa/Johannesburg',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'SAST',
      daylightSaving: 'SAST',
    },
    countryCodeIso: 'ZAF',
  },
  {
    name: 'Johannesburg',
    countryCode: 'LS',
    value: 'Africa/Johannesburg',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'SAST',
      daylightSaving: 'SAST',
    },
    countryCodeIso: 'LSO',
  },
  {
    name: 'Johannesburg',
    countryCode: 'SZ',
    value: 'Africa/Johannesburg',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'SAST',
      daylightSaving: 'SAST',
    },
    countryCodeIso: 'SWZ',
  },
  {
    name: 'Juba',
    countryCode: 'SS',
    value: 'Africa/Juba',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'SSD',
  },
  {
    name: 'Khartoum',
    countryCode: 'SD',
    value: 'Africa/Khartoum',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'SDN',
  },
  {
    name: 'Lagos',
    countryCode: 'NG',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'NGA',
  },
  {
    name: 'Lagos',
    countryCode: 'AO',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'AGO',
  },
  {
    name: 'Lagos',
    countryCode: 'BJ',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'BEN',
  },
  {
    name: 'Lagos',
    countryCode: 'CD',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'COD',
  },
  {
    name: 'Lagos',
    countryCode: 'CF',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'CAF',
  },
  {
    name: 'Lagos',
    countryCode: 'CG',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'COG',
  },
  {
    name: 'Lagos',
    countryCode: 'CM',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'CMR',
  },
  {
    name: 'Lagos',
    countryCode: 'GA',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'GAB',
  },
  {
    name: 'Lagos',
    countryCode: 'GQ',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'GNQ',
  },
  {
    name: 'Lagos',
    countryCode: 'NE',
    value: 'Africa/Lagos',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'NER',
  },
  {
    name: 'Maputo',
    countryCode: 'MZ',
    value: 'Africa/Maputo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'MOZ',
  },
  {
    name: 'Maputo',
    countryCode: 'BI',
    value: 'Africa/Maputo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'BDI',
  },
  {
    name: 'Maputo',
    countryCode: 'BW',
    value: 'Africa/Maputo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'BWA',
  },
  {
    name: 'Maputo',
    countryCode: 'CD',
    value: 'Africa/Maputo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'COD',
  },
  {
    name: 'Maputo',
    countryCode: 'MW',
    value: 'Africa/Maputo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'MWI',
  },
  {
    name: 'Maputo',
    countryCode: 'RW',
    value: 'Africa/Maputo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'RWA',
  },
  {
    name: 'Maputo',
    countryCode: 'ZM',
    value: 'Africa/Maputo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'ZMB',
  },
  {
    name: 'Maputo',
    countryCode: 'ZW',
    value: 'Africa/Maputo',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'ZWE',
  },
  {
    name: 'Monrovia',
    countryCode: 'LR',
    value: 'Africa/Monrovia',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'LBR',
  },
  {
    name: 'Nairobi',
    countryCode: 'KE',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'KEN',
  },
  {
    name: 'Nairobi',
    countryCode: 'DJ',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'DJI',
  },
  {
    name: 'Nairobi',
    countryCode: 'ER',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'ERI',
  },
  {
    name: 'Nairobi',
    countryCode: 'ET',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'ETH',
  },
  {
    name: 'Nairobi',
    countryCode: 'KM',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'COM',
  },
  {
    name: 'Nairobi',
    countryCode: 'MG',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'MDG',
  },
  {
    name: 'Nairobi',
    countryCode: 'SO',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'SOM',
  },
  {
    name: 'Nairobi',
    countryCode: 'TZ',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'TZA',
  },
  {
    name: 'Nairobi',
    countryCode: 'UG',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'UGA',
  },
  {
    name: 'Nairobi',
    countryCode: 'YT',
    value: 'Africa/Nairobi',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EAT',
      daylightSaving: 'EAT',
    },
    countryCodeIso: 'MYT',
  },
  {
    name: 'Ndjamena',
    countryCode: 'TD',
    value: 'Africa/Ndjamena',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WAT',
      daylightSaving: 'WAT',
    },
    countryCodeIso: 'TCD',
  },
  {
    name: 'Sao Tome',
    countryCode: 'ST',
    value: 'Africa/Sao_Tome',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'STP',
  },
  {
    name: 'Tripoli',
    countryCode: 'LY',
    value: 'Africa/Tripoli',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EET',
    },
    countryCodeIso: 'LBY',
  },
  {
    name: 'Tunis',
    countryCode: 'TN',
    value: 'Africa/Tunis',
    offset: {
      standard: '+01:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CET',
    },
    countryCodeIso: 'TUN',
  },
  {
    name: 'Windhoek',
    countryCode: 'NA',
    value: 'Africa/Windhoek',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CAT',
      daylightSaving: 'CAT',
    },
    countryCodeIso: 'NAM',
  },
  {
    name: 'Adak',
    countryCode: 'US',
    value: 'America/Adak',
    offset: {
      standard: '-10:00',
      daylightSaving: '-09:00',
    },
    abbreviation: {
      standard: 'HST',
      daylightSaving: 'HDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Anchorage',
    countryCode: 'US',
    value: 'America/Anchorage',
    offset: {
      standard: '-09:00',
      daylightSaving: '-08:00',
    },
    abbreviation: {
      standard: 'AKST',
      daylightSaving: 'AKDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Araguaina',
    countryCode: 'BR',
    value: 'America/Araguaina',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Argentina (Buenos Aires)',
    countryCode: 'AR',
    value: 'America/Argentina/Buenos_Aires',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (Catamarca)',
    countryCode: 'AR',
    value: 'America/Argentina/Catamarca',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (Cordoba)',
    countryCode: 'AR',
    value: 'America/Argentina/Cordoba',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (Jujuy)',
    countryCode: 'AR',
    value: 'America/Argentina/Jujuy',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (La Rioja)',
    countryCode: 'AR',
    value: 'America/Argentina/La_Rioja',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (Mendoza)',
    countryCode: 'AR',
    value: 'America/Argentina/Mendoza',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (Rio Gallegos)',
    countryCode: 'AR',
    value: 'America/Argentina/Rio_Gallegos',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (Salta)',
    countryCode: 'AR',
    value: 'America/Argentina/Salta',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (San Juan)',
    countryCode: 'AR',
    value: 'America/Argentina/San_Juan',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (San Luis)',
    countryCode: 'AR',
    value: 'America/Argentina/San_Luis',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (Tucuman)',
    countryCode: 'AR',
    value: 'America/Argentina/Tucuman',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Argentina (Ushuaia)',
    countryCode: 'AR',
    value: 'America/Argentina/Ushuaia',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ARG',
  },
  {
    name: 'Asuncion',
    countryCode: 'PY',
    value: 'America/Asuncion',
    offset: {
      standard: '-04:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-03',
    },
    countryCodeIso: 'PRY',
  },
  {
    name: 'Bahia',
    countryCode: 'BR',
    value: 'America/Bahia',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Bahia Banderas',
    countryCode: 'MX',
    value: 'America/Bahia_Banderas',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Barbados',
    countryCode: 'BB',
    value: 'America/Barbados',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'BRB',
  },
  {
    name: 'Belem',
    countryCode: 'BR',
    value: 'America/Belem',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Belize',
    countryCode: 'BZ',
    value: 'America/Belize',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'BLZ',
  },
  {
    name: 'Boa Vista',
    countryCode: 'BR',
    value: 'America/Boa_Vista',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-04',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Bogota',
    countryCode: 'CO',
    value: 'America/Bogota',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: '-05',
      daylightSaving: '-05',
    },
    countryCodeIso: 'COL',
  },
  {
    name: 'Boise',
    countryCode: 'US',
    value: 'America/Boise',
    offset: {
      standard: '-07:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Cambridge Bay',
    countryCode: 'CA',
    value: 'America/Cambridge_Bay',
    offset: {
      standard: '-07:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Campo Grande',
    countryCode: 'BR',
    value: 'America/Campo_Grande',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-04',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Cancun',
    countryCode: 'MX',
    value: 'America/Cancun',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EST',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Caracas',
    countryCode: 'VE',
    value: 'America/Caracas',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-04',
    },
    countryCodeIso: 'VEN',
  },
  {
    name: 'Cayenne',
    countryCode: 'GF',
    value: 'America/Cayenne',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'GUF',
  },
  {
    name: 'Chicago',
    countryCode: 'US',
    value: 'America/Chicago',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Chihuahua',
    countryCode: 'MX',
    value: 'America/Chihuahua',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Ciudad Juarez',
    countryCode: 'MX',
    value: 'America/Ciudad_Juarez',
    offset: {
      standard: '-07:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MDT',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Costa Rica',
    countryCode: 'CR',
    value: 'America/Costa_Rica',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'CRI',
  },
  {
    name: 'Cuiaba',
    countryCode: 'BR',
    value: 'America/Cuiaba',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-04',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Danmarkshavn',
    countryCode: 'GL',
    value: 'America/Danmarkshavn',
    offset: {
      standard: '+00:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'GRL',
  },
  {
    name: 'Dawson',
    countryCode: 'CA',
    value: 'America/Dawson',
    offset: {
      standard: '-07:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Dawson Creek',
    countryCode: 'CA',
    value: 'America/Dawson_Creek',
    offset: {
      standard: '-07:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Denver',
    countryCode: 'US',
    value: 'America/Denver',
    offset: {
      standard: '-07:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Detroit',
    countryCode: 'US',
    value: 'America/Detroit',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Edmonton',
    countryCode: 'CA',
    value: 'America/Edmonton',
    offset: {
      standard: '-07:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Eirunepe',
    countryCode: 'BR',
    value: 'America/Eirunepe',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: '-05',
      daylightSaving: '-05',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'El Salvador',
    countryCode: 'SV',
    value: 'America/El_Salvador',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'SLV',
  },
  {
    name: 'Fort Nelson',
    countryCode: 'CA',
    value: 'America/Fort_Nelson',
    offset: {
      standard: '-07:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Fortaleza',
    countryCode: 'BR',
    value: 'America/Fortaleza',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Glace Bay',
    countryCode: 'CA',
    value: 'America/Glace_Bay',
    offset: {
      standard: '-04:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'ADT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Goose Bay',
    countryCode: 'CA',
    value: 'America/Goose_Bay',
    offset: {
      standard: '-04:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'ADT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Grand Turk',
    countryCode: 'TC',
    value: 'America/Grand_Turk',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'TCA',
  },
  {
    name: 'Guatemala',
    countryCode: 'GT',
    value: 'America/Guatemala',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'GTM',
  },
  {
    name: 'Guayaquil',
    countryCode: 'EC',
    value: 'America/Guayaquil',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: '-05',
      daylightSaving: '-05',
    },
    countryCodeIso: 'ECU',
  },
  {
    name: 'Guyana',
    countryCode: 'GY',
    value: 'America/Guyana',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-04',
    },
    countryCodeIso: 'GUY',
  },
  {
    name: 'Halifax',
    countryCode: 'CA',
    value: 'America/Halifax',
    offset: {
      standard: '-04:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'ADT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Havana',
    countryCode: 'CU',
    value: 'America/Havana',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'CUB',
  },
  {
    name: 'Hermosillo',
    countryCode: 'MX',
    value: 'America/Hermosillo',
    offset: {
      standard: '-07:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MST',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Indiana (Indianapolis)',
    countryCode: 'US',
    value: 'America/Indiana/Indianapolis',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Indiana (Knox)',
    countryCode: 'US',
    value: 'America/Indiana/Knox',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Indiana (Marengo)',
    countryCode: 'US',
    value: 'America/Indiana/Marengo',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Indiana (Petersburg)',
    countryCode: 'US',
    value: 'America/Indiana/Petersburg',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Indiana (Tell City)',
    countryCode: 'US',
    value: 'America/Indiana/Tell_City',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Indiana (Vevay)',
    countryCode: 'US',
    value: 'America/Indiana/Vevay',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Indiana (Vincennes)',
    countryCode: 'US',
    value: 'America/Indiana/Vincennes',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Indiana (Winamac)',
    countryCode: 'US',
    value: 'America/Indiana/Winamac',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Inuvik',
    countryCode: 'CA',
    value: 'America/Inuvik',
    offset: {
      standard: '-07:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Iqaluit',
    countryCode: 'CA',
    value: 'America/Iqaluit',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Jamaica',
    countryCode: 'JM',
    value: 'America/Jamaica',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EST',
    },
    countryCodeIso: 'JAM',
  },
  {
    name: 'Juneau',
    countryCode: 'US',
    value: 'America/Juneau',
    offset: {
      standard: '-09:00',
      daylightSaving: '-08:00',
    },
    abbreviation: {
      standard: 'AKST',
      daylightSaving: 'AKDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Kentucky (Louisville)',
    countryCode: 'US',
    value: 'America/Kentucky/Louisville',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Kentucky (Monticello)',
    countryCode: 'US',
    value: 'America/Kentucky/Monticello',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'La Paz',
    countryCode: 'BO',
    value: 'America/La_Paz',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-04',
    },
    countryCodeIso: 'BOL',
  },
  {
    name: 'Lima',
    countryCode: 'PE',
    value: 'America/Lima',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: '-05',
      daylightSaving: '-05',
    },
    countryCodeIso: 'PER',
  },
  {
    name: 'Los Angeles',
    countryCode: 'US',
    value: 'America/Los_Angeles',
    offset: {
      standard: '-08:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'PST',
      daylightSaving: 'PDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Maceio',
    countryCode: 'BR',
    value: 'America/Maceio',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Managua',
    countryCode: 'NI',
    value: 'America/Managua',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'NIC',
  },
  {
    name: 'Manaus',
    countryCode: 'BR',
    value: 'America/Manaus',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-04',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Martinique',
    countryCode: 'MQ',
    value: 'America/Martinique',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'MTQ',
  },
  {
    name: 'Matamoros',
    countryCode: 'MX',
    value: 'America/Matamoros',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Mazatlan',
    countryCode: 'MX',
    value: 'America/Mazatlan',
    offset: {
      standard: '-07:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MST',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Menominee',
    countryCode: 'US',
    value: 'America/Menominee',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Merida',
    countryCode: 'MX',
    value: 'America/Merida',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Metlakatla',
    countryCode: 'US',
    value: 'America/Metlakatla',
    offset: {
      standard: '-09:00',
      daylightSaving: '-08:00',
    },
    abbreviation: {
      standard: 'AKST',
      daylightSaving: 'ADST',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Mexico City',
    countryCode: 'MX',
    value: 'America/Mexico_City',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Miquelon',
    countryCode: 'PM',
    value: 'America/Miquelon',
    offset: {
      standard: '-03:00',
      daylightSaving: '-02:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-02',
    },
    countryCodeIso: 'SPM',
  },
  {
    name: 'Moncton',
    countryCode: 'CA',
    value: 'America/Moncton',
    offset: {
      standard: '-04:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'ADT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Monterrey',
    countryCode: 'MX',
    value: 'America/Monterrey',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Montevideo',
    countryCode: 'UY',
    value: 'America/Montevideo',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'URY',
  },
  {
    name: 'New York',
    countryCode: 'US',
    value: 'America/New_York',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Nome',
    countryCode: 'US',
    value: 'America/Nome',
    offset: {
      standard: '-09:00',
      daylightSaving: '-08:00',
    },
    abbreviation: {
      standard: 'AKST',
      daylightSaving: 'AKDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Noronha',
    countryCode: 'BR',
    value: 'America/Noronha',
    offset: {
      standard: '-02:00',
      daylightSaving: '-02:00',
    },
    abbreviation: {
      standard: '-02',
      daylightSaving: '-02',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'North Dakota (Beulah)',
    countryCode: 'US',
    value: 'America/North_Dakota/Beulah',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'North Dakota (Center)',
    countryCode: 'US',
    value: 'America/North_Dakota/Center',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'North Dakota (New Salem)',
    countryCode: 'US',
    value: 'America/North_Dakota/New_Salem',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Nuuk',
    countryCode: 'GL',
    value: 'America/Nuuk',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'GRL',
  },
  {
    name: 'Ojinaga',
    countryCode: 'MX',
    value: 'America/Ojinaga',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Panama',
    countryCode: 'PA',
    value: 'America/Panama',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EST',
    },
    countryCodeIso: 'PAN',
  },
  {
    name: 'Panama',
    countryCode: 'CA',
    value: 'America/Panama',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Panama',
    countryCode: 'KY',
    value: 'America/Panama',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EST',
    },
    countryCodeIso: 'CYM',
  },
  {
    name: 'Paramaribo',
    countryCode: 'SR',
    value: 'America/Paramaribo',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'SUR',
  },
  {
    name: 'Phoenix',
    countryCode: 'US',
    value: 'America/Phoenix',
    offset: {
      standard: '-07:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MST',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Phoenix',
    countryCode: 'CA',
    value: 'America/Phoenix',
    offset: {
      standard: '-07:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Port-au-Prince',
    countryCode: 'HT',
    value: 'America/Port-au-Prince',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'HTI',
  },
  {
    name: 'Porto Velho',
    countryCode: 'BR',
    value: 'America/Porto_Velho',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-04',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'PR',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'PRI',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'AG',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'ATG',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'CA',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'AI',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'AIA',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'AW',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'ABW',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'BL',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'BLM',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'BQ',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
  },
  {
    name: 'Puerto Rico',
    countryCode: 'CW',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'CUW',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'DM',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'DMA',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'GD',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'GRD',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'GP',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'GLP',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'KN',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'KNA',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'LC',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'LCA',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'MF',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'MAF',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'MS',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'MSR',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'SX',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'SXM',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'TT',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'TTO',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'VC',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'VCT',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'VG',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'VGB',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'VI',
    value: 'America/Puerto_Rico',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'VIR',
  },
  {
    name: 'Punta Arenas',
    countryCode: 'CL',
    value: 'America/Punta_Arenas',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'CHL',
  },
  {
    name: 'Rankin Inlet',
    countryCode: 'CA',
    value: 'America/Rankin_Inlet',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Recife',
    countryCode: 'BR',
    value: 'America/Recife',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Regina',
    countryCode: 'CA',
    value: 'America/Regina',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Resolute',
    countryCode: 'CA',
    value: 'America/Resolute',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Rio Branco',
    countryCode: 'BR',
    value: 'America/Rio_Branco',
    offset: {
      standard: '-05:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: '-05',
      daylightSaving: '-05',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Santarem',
    countryCode: 'BR',
    value: 'America/Santarem',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Santiago',
    countryCode: 'CL',
    value: 'America/Santiago',
    offset: {
      standard: '-04:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-04',
      daylightSaving: '-03',
    },
    countryCodeIso: 'CHL',
  },
  {
    name: 'Santo Domingo',
    countryCode: 'DO',
    value: 'America/Santo_Domingo',
    offset: {
      standard: '-04:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'AST',
    },
    countryCodeIso: 'DOM',
  },
  {
    name: 'Sao Paulo',
    countryCode: 'BR',
    value: 'America/Sao_Paulo',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'BRA',
  },
  {
    name: 'Scoresbysund',
    countryCode: 'GL',
    value: 'America/Scoresbysund',
    offset: {
      standard: '-01:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: '-01',
      daylightSaving: '+00',
    },
    countryCodeIso: 'GRL',
  },
  {
    name: 'Sitka',
    countryCode: 'US',
    value: 'America/Sitka',
    offset: {
      standard: '-09:00',
      daylightSaving: '-08:00',
    },
    abbreviation: {
      standard: 'AKST',
      daylightSaving: 'AKDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'St Johns',
    countryCode: 'CA',
    value: 'America/St_Johns',
    offset: {
      standard: '-03:30',
      daylightSaving: '-02:30',
    },
    abbreviation: {
      standard: 'NST',
      daylightSaving: 'NDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Swift Current',
    countryCode: 'CA',
    value: 'America/Swift_Current',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Tegucigalpa',
    countryCode: 'HN',
    value: 'America/Tegucigalpa',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'HND',
  },
  {
    name: 'Thule',
    countryCode: 'GL',
    value: 'America/Thule',
    offset: {
      standard: '-04:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'ADT',
    },
    countryCodeIso: 'GRL',
  },
  {
    name: 'Tijuana',
    countryCode: 'MX',
    value: 'America/Tijuana',
    offset: {
      standard: '-08:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'PST',
      daylightSaving: 'PDT',
    },
    countryCodeIso: 'MEX',
  },
  {
    name: 'Toronto',
    countryCode: 'CA',
    value: 'America/Toronto',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Toronto',
    countryCode: 'BS',
    value: 'America/Toronto',
    offset: {
      standard: '-05:00',
      daylightSaving: '-04:00',
    },
    abbreviation: {
      standard: 'EST',
      daylightSaving: 'EDT',
    },
    countryCodeIso: 'BHS',
  },
  {
    name: 'Vancouver',
    countryCode: 'CA',
    value: 'America/Vancouver',
    offset: {
      standard: '-08:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'PST',
      daylightSaving: 'PDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Whitehorse',
    countryCode: 'CA',
    value: 'America/Whitehorse',
    offset: {
      standard: '-07:00',
      daylightSaving: '-07:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MST',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Winnipeg',
    countryCode: 'CA',
    value: 'America/Winnipeg',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Yakutat',
    countryCode: 'US',
    value: 'America/Yakutat',
    offset: {
      standard: '-09:00',
      daylightSaving: '-08:00',
    },
    abbreviation: {
      standard: 'AKST',
      daylightSaving: 'AKDT',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Yellowknife',
    countryCode: 'CA',
    value: 'America/Yellowknife',
    offset: {
      standard: '-07:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: 'MST',
      daylightSaving: 'MDT',
    },
    countryCodeIso: 'CAN',
  },
  {
    name: 'Casey',
    countryCode: 'AQ',
    value: 'Antarctica/Casey',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Davis',
    countryCode: 'AQ',
    value: 'Antarctica/Davis',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Macquarie',
    countryCode: 'AU',
    value: 'Antarctica/Macquarie',
    offset: {
      standard: '+10:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: 'AEST',
      daylightSaving: 'AEDT',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Mawson',
    countryCode: 'AQ',
    value: 'Antarctica/Mawson',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Palmer',
    countryCode: 'AQ',
    value: 'Antarctica/Palmer',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Rothera',
    countryCode: 'AQ',
    value: 'Antarctica/Rothera',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Troll',
    countryCode: 'AQ',
    value: 'Antarctica/Troll',
    offset: {
      standard: '+00:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: '+00',
      daylightSaving: '+02',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Almaty',
    countryCode: 'KZ',
    value: 'Asia/Almaty',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'KAZ',
  },
  {
    name: 'Almaty',
    countryCode: 'IO',
    value: 'Asia/Almaty',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'IOT',
  },
  {
    name: 'Amman',
    countryCode: 'JO',
    value: 'Asia/Amman',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'JOR',
  },
  {
    name: 'Anadyr',
    countryCode: 'RU',
    value: 'Asia/Anadyr',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Aqtau',
    countryCode: 'KZ',
    value: 'Asia/Aqtau',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'KAZ',
  },
  {
    name: 'Ashgabat',
    countryCode: 'TM',
    value: 'Asia/Ashgabat',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'TKM',
  },
  {
    name: 'Atyrau',
    countryCode: 'KZ',
    value: 'Asia/Atyrau',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'KAZ',
  },
  {
    name: 'Baghdad',
    countryCode: 'IQ',
    value: 'Asia/Baghdad',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'IRQ',
  },
  {
    name: 'Baku',
    countryCode: 'AZ',
    value: 'Asia/Baku',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'AZE',
  },
  {
    name: 'Mauritius',
    countryCode: 'MU',
    value: 'Asia/Baku',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'MUS',
  },
  {
    name: 'Bangkok',
    countryCode: 'TH',
    value: 'Asia/Bangkok',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'THA',
  },
  {
    name: 'Bangkok',
    countryCode: 'CX',
    value: 'Asia/Bangkok',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'CXR',
  },
  {
    name: 'Bangkok',
    countryCode: 'KH',
    value: 'Asia/Bangkok',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'KHM',
  },
  {
    name: 'Bangkok',
    countryCode: 'LA',
    value: 'Asia/Bangkok',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'LAO',
  },
  {
    name: 'Bangkok',
    countryCode: 'VN',
    value: 'Asia/Bangkok',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'VNM',
  },
  {
    name: 'Barnaul',
    countryCode: 'RU',
    value: 'Asia/Barnaul',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Beirut',
    countryCode: 'LB',
    value: 'Asia/Beirut',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'LBN',
  },
  {
    name: 'Bishkek',
    countryCode: 'KG',
    value: 'Asia/Bishkek',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'KGZ',
  },
  {
    name: 'Chita',
    countryCode: 'RU',
    value: 'Asia/Chita',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: '+09',
      daylightSaving: '+09',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Choibalsan',
    countryCode: 'MN',
    value: 'Asia/Choibalsan',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: '+08',
      daylightSaving: '+08',
    },
    countryCodeIso: 'MNG',
  },
  {
    name: 'Colombo',
    countryCode: 'LK',
    value: 'Asia/Colombo',
    offset: {
      standard: '+05:30',
      daylightSaving: '+05:30',
    },
    abbreviation: {
      standard: '+0530',
      daylightSaving: '+0530',
    },
    countryCodeIso: 'LKA',
  },
  {
    name: 'Damascus',
    countryCode: 'SY',
    value: 'Asia/Damascus',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'SYR',
  },
  {
    name: 'Dhaka',
    countryCode: 'BD',
    value: 'Asia/Dhaka',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'BGD',
  },
  {
    name: 'Dili',
    countryCode: 'TL',
    value: 'Asia/Dili',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: '+09',
      daylightSaving: '+09',
    },
    countryCodeIso: 'TLS',
  },
  {
    name: 'Dubai',
    countryCode: 'AE',
    value: 'Asia/Dubai',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'ARE',
  },
  {
    name: 'Dubai',
    countryCode: 'OM',
    value: 'Asia/Dubai',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'OMN',
  },
  {
    name: 'Dubai',
    countryCode: 'RE',
    value: 'Asia/Dubai',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'REU',
  },
  {
    name: 'Dubai',
    countryCode: 'SC',
    value: 'Asia/Dubai',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'SYC',
  },
  {
    name: 'Dubai',
    countryCode: 'TF',
    value: 'Asia/Dubai',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'ATF',
  },
  {
    name: 'Dushanbe',
    countryCode: 'TJ',
    value: 'Asia/Dushanbe',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'TJK',
  },
  {
    name: 'Famagusta',
    countryCode: 'CY',
    value: 'Asia/Famagusta',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'CYP',
  },
  {
    name: 'Gaza',
    countryCode: 'PS',
    value: 'Asia/Gaza',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
  },
  {
    name: 'Hebron',
    countryCode: 'PS',
    value: 'Asia/Hebron',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
  },
  {
    name: 'Ho Chi Minh',
    countryCode: 'VN',
    value: 'Asia/Ho_Chi_Minh',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'VNM',
  },
  {
    name: 'Hong Kong',
    countryCode: 'HK',
    value: 'Asia/Hong_Kong',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: 'HKT',
      daylightSaving: 'HKT',
    },
    countryCodeIso: 'HKG',
  },
  {
    name: 'Hovd',
    countryCode: 'MN',
    value: 'Asia/Hovd',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'MNG',
  },
  {
    name: 'Irkutsk',
    countryCode: 'RU',
    value: 'Asia/Irkutsk',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: '+08',
      daylightSaving: '+08',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Jakarta',
    countryCode: 'ID',
    value: 'Asia/Jakarta',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: 'WIB',
      daylightSaving: 'WIB',
    },
    countryCodeIso: 'IDN',
  },
  {
    name: 'Jayapura',
    countryCode: 'ID',
    value: 'Asia/Jayapura',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: 'WIT',
      daylightSaving: 'WIT',
    },
    countryCodeIso: 'IDN',
  },
  {
    name: 'Jerusalem',
    countryCode: 'IL',
    value: 'Asia/Jerusalem',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'IST',
      daylightSaving: 'IDT',
    },
    countryCodeIso: 'ISR',
  },
  {
    name: 'Kabul',
    countryCode: 'AF',
    value: 'Asia/Kabul',
    offset: {
      standard: '+04:30',
      daylightSaving: '+04:30',
    },
    abbreviation: {
      standard: '+0430',
      daylightSaving: '+0430',
    },
    countryCodeIso: 'AFG',
  },
  {
    name: 'Kamchatka',
    countryCode: 'RU',
    value: 'Asia/Kamchatka',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Karachi',
    countryCode: 'PK',
    value: 'Asia/Karachi',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: 'PKT',
      daylightSaving: 'PKT',
    },
    countryCodeIso: 'PAK',
  },
  {
    name: 'Kathmandu',
    countryCode: 'NP',
    value: 'Asia/Kathmandu',
    offset: {
      standard: '+05:45',
      daylightSaving: '+05:45',
    },
    abbreviation: {
      standard: '+0545',
      daylightSaving: '+0545',
    },
    countryCodeIso: 'NPL',
  },
  {
    name: 'Khandyga',
    countryCode: 'RU',
    value: 'Asia/Khandyga',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: '+09',
      daylightSaving: '+09',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Kolkata',
    countryCode: 'IN',
    value: 'Asia/Kolkata',
    offset: {
      standard: '+05:30',
      daylightSaving: '+05:30',
    },
    abbreviation: {
      standard: 'IST',
      daylightSaving: 'IST',
    },
    countryCodeIso: 'IND',
  },
  {
    name: 'Krasnoyarsk',
    countryCode: 'RU',
    value: 'Asia/Krasnoyarsk',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Kuching',
    countryCode: 'MY',
    value: 'Asia/Kuching',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: '+08',
      daylightSaving: '+08',
    },
    countryCodeIso: 'MYS',
  },
  {
    name: 'Kuching',
    countryCode: 'BN',
    value: 'Asia/Kuching',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: '+08',
      daylightSaving: '+08',
    },
    countryCodeIso: 'BRN',
  },
  {
    name: 'Macau',
    countryCode: 'MO',
    value: 'Asia/Macau',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'MAC',
  },
  {
    name: 'Magadan',
    countryCode: 'RU',
    value: 'Asia/Magadan',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Makassar',
    countryCode: 'ID',
    value: 'Asia/Makassar',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: 'WITA',
      daylightSaving: 'WITA',
    },
    countryCodeIso: 'IDN',
  },
  {
    name: 'Manila',
    countryCode: 'PH',
    value: 'Asia/Manila',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: 'PST',
      daylightSaving: 'PST',
    },
    countryCodeIso: 'PHL',
  },
  {
    name: 'Nicosia',
    countryCode: 'CY',
    value: 'Asia/Nicosia',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'CYP',
  },
  {
    name: 'Novokuznetsk',
    countryCode: 'RU',
    value: 'Asia/Novokuznetsk',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Novosibirsk',
    countryCode: 'RU',
    value: 'Asia/Novosibirsk',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Omsk',
    countryCode: 'RU',
    value: 'Asia/Omsk',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Oral',
    countryCode: 'KZ',
    value: 'Asia/Oral',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'KAZ',
  },
  {
    name: 'Oral',
    countryCode: 'MV',
    value: 'Asia/Oral',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'MDV',
  },
  {
    name: 'Pontianak',
    countryCode: 'ID',
    value: 'Asia/Pontianak',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: 'WIB',
      daylightSaving: 'WIB',
    },
    countryCodeIso: 'IDN',
  },
  {
    name: 'Pyongyang',
    countryCode: 'KP',
    value: 'Asia/Pyongyang',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: 'KST',
      daylightSaving: 'KST',
    },
    countryCodeIso: 'PRK',
  },
  {
    name: 'Qatar',
    countryCode: 'QA',
    value: 'Asia/Qatar',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'QAT',
  },
  {
    name: 'Qatar',
    countryCode: 'BH',
    value: 'Asia/Qatar',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'BHR',
  },
  {
    name: 'Qostanay',
    countryCode: 'KZ',
    value: 'Asia/Qostanay',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'KAZ',
  },
  {
    name: 'Qyzylorda',
    countryCode: 'KZ',
    value: 'Asia/Qyzylorda',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'KAZ',
  },
  {
    name: 'Riyadh',
    countryCode: 'SA',
    value: 'Asia/Riyadh',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'SAU',
  },
  {
    name: 'Riyadh',
    countryCode: 'AQ',
    value: 'Asia/Riyadh',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Riyadh',
    countryCode: 'KW',
    value: 'Asia/Riyadh',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'KWT',
  },
  {
    name: 'Riyadh',
    countryCode: 'YE',
    value: 'Asia/Riyadh',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'YEM',
  },
  {
    name: 'Sakhalin',
    countryCode: 'RU',
    value: 'Asia/Sakhalin',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Samarkand',
    countryCode: 'UZ',
    value: 'Asia/Samarkand',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'UZB',
  },
  {
    name: 'Seoul',
    countryCode: 'KR',
    value: 'Asia/Seoul',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: 'KST',
      daylightSaving: 'KST',
    },
    countryCodeIso: 'KOR',
  },
  {
    name: 'Shanghai',
    countryCode: 'CN',
    value: 'Asia/Shanghai',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'CHN',
  },
  {
    name: 'Singapore',
    countryCode: 'SG',
    value: 'Asia/Singapore',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: '+08',
      daylightSaving: '+08',
    },
    countryCodeIso: 'SGP',
  },
  {
    name: 'Singapore',
    countryCode: 'MY',
    value: 'Asia/Singapore',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: '+08',
      daylightSaving: '+08',
    },
    countryCodeIso: 'MYS',
  },
  {
    name: 'Srednekolymsk',
    countryCode: 'RU',
    value: 'Asia/Srednekolymsk',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Taipei',
    countryCode: 'TW',
    value: 'Asia/Taipei',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: 'CST',
      daylightSaving: 'CST',
    },
    countryCodeIso: 'TWN',
  },
  {
    name: 'Tashkent',
    countryCode: 'UZ',
    value: 'Asia/Tashkent',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'UZB',
  },
  {
    name: 'Tbilisi',
    countryCode: 'GE',
    value: 'Asia/Tbilisi',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'GEO',
  },
  {
    name: 'Tehran',
    countryCode: 'IR',
    value: 'Asia/Tehran',
    offset: {
      standard: '+03:30',
      daylightSaving: '+03:30',
    },
    abbreviation: {
      standard: '+0330',
      daylightSaving: '+0330',
    },
    countryCodeIso: 'IRN',
  },
  {
    name: 'Thimphu',
    countryCode: 'BT',
    value: 'Asia/Thimphu',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'BTN',
  },
  {
    name: 'Tokyo',
    countryCode: 'JP',
    value: 'Asia/Tokyo',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: 'JST',
      daylightSaving: 'JST',
    },
    countryCodeIso: 'JPN',
  },
  {
    name: 'Tomsk',
    countryCode: 'RU',
    value: 'Asia/Tomsk',
    offset: {
      standard: '+07:00',
      daylightSaving: '+07:00',
    },
    abbreviation: {
      standard: '+07',
      daylightSaving: '+07',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Ulaanbaatar',
    countryCode: 'MN',
    value: 'Asia/Ulaanbaatar',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: '+08',
      daylightSaving: '+08',
    },
    countryCodeIso: 'MNG',
  },
  {
    name: 'Urumqi',
    countryCode: 'CN',
    value: 'Asia/Urumqi',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'CHN',
  },
  {
    name: 'Urumqi',
    countryCode: 'AQ',
    value: 'Asia/Urumqi',
    offset: {
      standard: '+06:00',
      daylightSaving: '+06:00',
    },
    abbreviation: {
      standard: '+06',
      daylightSaving: '+06',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Ust-Nera',
    countryCode: 'RU',
    value: 'Asia/Ust-Nera',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: '+10',
      daylightSaving: '+10',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Vladivostok',
    countryCode: 'RU',
    value: 'Asia/Vladivostok',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: '+10',
      daylightSaving: '+10',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Yakutsk',
    countryCode: 'RU',
    value: 'Asia/Yakutsk',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: '+09',
      daylightSaving: '+09',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Yangon',
    countryCode: 'MM',
    value: 'Asia/Yangon',
    offset: {
      standard: '+06:30',
      daylightSaving: '+06:30',
    },
    abbreviation: {
      standard: '+0630',
      daylightSaving: '+0630',
    },
    countryCodeIso: 'MMR',
  },
  {
    name: 'Yangon',
    countryCode: 'CC',
    value: 'Asia/Yangon',
    offset: {
      standard: '+06:30',
      daylightSaving: '+06:30',
    },
    abbreviation: {
      standard: '+0630',
      daylightSaving: '+0630',
    },
    countryCodeIso: 'CCK',
  },
  {
    name: 'Yekaterinburg',
    countryCode: 'RU',
    value: 'Asia/Yekaterinburg',
    offset: {
      standard: '+05:00',
      daylightSaving: '+05:00',
    },
    abbreviation: {
      standard: '+05',
      daylightSaving: '+05',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Yerevan',
    countryCode: 'AM',
    value: 'Asia/Yerevan',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'ARM',
  },
  {
    name: 'Azores',
    countryCode: 'PT',
    value: 'Atlantic/Azores',
    offset: {
      standard: '-01:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: '-01',
      daylightSaving: '+00',
    },
    countryCodeIso: 'PRT',
  },
  {
    name: 'Bermuda',
    countryCode: 'BM',
    value: 'Atlantic/Bermuda',
    offset: {
      standard: '-04:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: 'AST',
      daylightSaving: 'ADT',
    },
    countryCodeIso: 'BMU',
  },
  {
    name: 'Canary',
    countryCode: 'ES',
    value: 'Atlantic/Canary',
    offset: {
      standard: '+00:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WET',
      daylightSaving: 'WEST',
    },
    countryCodeIso: 'ESP',
  },
  {
    name: 'Cape Verde',
    countryCode: 'CV',
    value: 'Atlantic/Cape_Verde',
    offset: {
      standard: '-01:00',
      daylightSaving: '-01:00',
    },
    abbreviation: {
      standard: '-01',
      daylightSaving: '-01',
    },
    countryCodeIso: 'CPV',
  },
  {
    name: 'Faroe',
    countryCode: 'FO',
    value: 'Atlantic/Faroe',
    offset: {
      standard: '+00:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WET',
      daylightSaving: 'WEST',
    },
    countryCodeIso: 'FRO',
  },
  {
    name: 'Madeira',
    countryCode: 'PT',
    value: 'Atlantic/Madeira',
    offset: {
      standard: '+00:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WET',
      daylightSaving: 'WEST',
    },
    countryCodeIso: 'PRT',
  },
  {
    name: 'South Georgia',
    countryCode: 'GS',
    value: 'Atlantic/South_Georgia',
    offset: {
      standard: '-02:00',
      daylightSaving: '-02:00',
    },
    abbreviation: {
      standard: '-02',
      daylightSaving: '-02',
    },
    countryCodeIso: 'SGS',
  },
  {
    name: 'Stanley',
    countryCode: 'FK',
    value: 'Atlantic/Stanley',
    offset: {
      standard: '-03:00',
      daylightSaving: '-03:00',
    },
    abbreviation: {
      standard: '-03',
      daylightSaving: '-03',
    },
    countryCodeIso: 'FLK',
  },
  {
    name: 'Adelaide',
    countryCode: 'AU',
    value: 'Australia/Adelaide',
    offset: {
      standard: '+09:30',
      daylightSaving: '+10:30',
    },
    abbreviation: {
      standard: 'ACST',
      daylightSaving: 'ACDT',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Brisbane',
    countryCode: 'AU',
    value: 'Australia/Brisbane',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: 'AEST',
      daylightSaving: 'AEST',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Broken Hill',
    countryCode: 'AU',
    value: 'Australia/Broken_Hill',
    offset: {
      standard: '+09:30',
      daylightSaving: '+10:30',
    },
    abbreviation: {
      standard: 'ACST',
      daylightSaving: 'ACDT',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Darwin',
    countryCode: 'AU',
    value: 'Australia/Darwin',
    offset: {
      standard: '+09:30',
      daylightSaving: '+09:30',
    },
    abbreviation: {
      standard: 'ACST',
      daylightSaving: 'ACST',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Eucla',
    countryCode: 'AU',
    value: 'Australia/Eucla',
    offset: {
      standard: '+08:45',
      daylightSaving: '+08:45',
    },
    abbreviation: {
      standard: '+0845',
      daylightSaving: '+0845',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Hobart',
    countryCode: 'AU',
    value: 'Australia/Hobart',
    offset: {
      standard: '+10:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: 'AEST',
      daylightSaving: 'AEDT',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Lindeman',
    countryCode: 'AU',
    value: 'Australia/Lindeman',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: 'AEST',
      daylightSaving: 'AEST',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Lord Howe',
    countryCode: 'AU',
    value: 'Australia/Lord_Howe',
    offset: {
      standard: '+10:30',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+1030',
      daylightSaving: '+11',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Melbourne',
    countryCode: 'AU',
    value: 'Australia/Melbourne',
    offset: {
      standard: '+10:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: 'AEST',
      daylightSaving: 'AEDT',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Perth',
    countryCode: 'AU',
    value: 'Australia/Perth',
    offset: {
      standard: '+08:00',
      daylightSaving: '+08:00',
    },
    abbreviation: {
      standard: 'AWST',
      daylightSaving: 'AWST',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Sydney',
    countryCode: 'AU',
    value: 'Australia/Sydney',
    offset: {
      standard: '+10:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: 'AEST',
      daylightSaving: 'AEDT',
    },
    countryCodeIso: 'AUS',
  },
  {
    name: 'Andorra',
    countryCode: 'AD',
    value: 'Europe/Andorra',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'AND',
  },
  {
    name: 'Astrakhan',
    countryCode: 'RU',
    value: 'Europe/Astrakhan',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Athens',
    countryCode: 'GR',
    value: 'Europe/Athens',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'GRC',
  },
  {
    name: 'Belgrade',
    countryCode: 'RS',
    value: 'Europe/Belgrade',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'SRB',
  },
  {
    name: 'Belgrade',
    countryCode: 'BA',
    value: 'Europe/Belgrade',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'BIH',
  },
  {
    name: 'Belgrade',
    countryCode: 'HR',
    value: 'Europe/Belgrade',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'HRV',
  },
  {
    name: 'Belgrade',
    countryCode: 'ME',
    value: 'Europe/Belgrade',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'MNE',
  },
  {
    name: 'Belgrade',
    countryCode: 'MK',
    value: 'Europe/Belgrade',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'MKD',
  },
  {
    name: 'Belgrade',
    countryCode: 'SI',
    value: 'Europe/Belgrade',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'SVN',
  },
  {
    name: 'Berlin',
    countryCode: 'DE',
    value: 'Europe/Berlin',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'DEU',
  },
  {
    name: 'Berlin',
    countryCode: 'DK',
    value: 'Europe/Berlin',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'DNK',
  },
  {
    name: 'Berlin',
    countryCode: 'NO',
    value: 'Europe/Berlin',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'NOR',
  },
  {
    name: 'Berlin',
    countryCode: 'SE',
    value: 'Europe/Berlin',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'SWE',
  },
  {
    name: 'Berlin',
    countryCode: 'SJ',
    value: 'Europe/Berlin',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'SJM',
  },
  {
    name: 'Brussels',
    countryCode: 'BE',
    value: 'Europe/Brussels',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'BEL',
  },
  {
    name: 'Brussels',
    countryCode: 'LI',
    value: 'Europe/Brussels',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'LIE',
  },
  {
    name: 'Brussels',
    countryCode: 'LU',
    value: 'Europe/Brussels',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'LUX',
  },
  {
    name: 'Brussels',
    countryCode: 'NL',
    value: 'Europe/Brussels',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'NLD',
  },
  {
    name: 'Bucharest',
    countryCode: 'RO',
    value: 'Europe/Bucharest',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'ROU',
  },
  {
    name: 'Budapest',
    countryCode: 'HU',
    value: 'Europe/Budapest',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'HUN',
  },
  {
    name: 'Chisinau',
    countryCode: 'MD',
    value: 'Europe/Chisinau',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'MDA',
  },
  {
    name: 'Dublin',
    countryCode: 'IE',
    value: 'Europe/Dublin',
    offset: {
      standard: '+01:00',
      daylightSaving: '+00:00',
    },
    abbreviation: {
      standard: 'IST',
      daylightSaving: 'GMT',
    },
    countryCodeIso: 'IRL',
  },
  {
    name: 'Gibraltar',
    countryCode: 'GI',
    value: 'Europe/Gibraltar',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'GIB',
  },
  {
    name: 'Helsinki',
    countryCode: 'FI',
    value: 'Europe/Helsinki',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'FIN',
  },
  {
    name: 'Helsinki',
    countryCode: 'AX',
    value: 'Europe/Helsinki',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'ALA',
  },
  {
    name: 'Istanbul',
    countryCode: 'TR',
    value: 'Europe/Istanbul',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'TUR',
  },
  {
    name: 'Kaliningrad',
    countryCode: 'RU',
    value: 'Europe/Kaliningrad',
    offset: {
      standard: '+02:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EET',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Kirov',
    countryCode: 'RU',
    value: 'Europe/Kirov',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Kyiv',
    countryCode: 'UA',
    value: 'Europe/Kyiv',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'UKR',
  },
  {
    name: 'Lisbon',
    countryCode: 'PT',
    value: 'Europe/Lisbon',
    offset: {
      standard: '+00:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'WET',
      daylightSaving: 'WEST',
    },
    countryCodeIso: 'PRT',
  },
  {
    name: 'London',
    countryCode: 'GB',
    value: 'Europe/London',
    offset: {
      standard: '+00:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'BST',
    },
    countryCodeIso: 'GBR',
  },
  {
    name: 'London',
    countryCode: 'GG',
    value: 'Europe/London',
    offset: {
      standard: '+00:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'BST',
    },
    countryCodeIso: 'GGY',
  },
  {
    name: 'London',
    countryCode: 'IM',
    value: 'Europe/London',
    offset: {
      standard: '+00:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'BST',
    },
    countryCodeIso: 'IMN',
  },
  {
    name: 'London',
    countryCode: 'GE',
    value: 'Europe/London',
    offset: {
      standard: '+00:00',
      daylightSaving: '+01:00',
    },
    abbreviation: {
      standard: 'GMT',
      daylightSaving: 'BST',
    },
    countryCodeIso: 'GEO',
  },
  {
    name: 'Madrid',
    countryCode: 'ES',
    value: 'Europe/Madrid',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'ESP',
  },
  {
    name: 'Malta',
    countryCode: 'MT',
    value: 'Europe/Malta',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'MLT',
  },
  {
    name: 'Minsk',
    countryCode: 'BY',
    value: 'Europe/Minsk',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'BLR',
  },
  {
    name: 'Moscow',
    countryCode: 'RU',
    value: 'Europe/Moscow',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'MSK',
      daylightSaving: 'MSK',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Paris',
    countryCode: 'FR',
    value: 'Europe/Paris',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'FRA',
  },
  {
    name: 'Paris',
    countryCode: 'MC',
    value: 'Europe/Paris',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'MCO',
  },
  {
    name: 'Prague',
    countryCode: 'CZ',
    value: 'Europe/Prague',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'CZE',
  },
  {
    name: 'Prague',
    countryCode: 'SK',
    value: 'Europe/Prague',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'SVK',
  },
  {
    name: 'Riga',
    countryCode: 'LV',
    value: 'Europe/Riga',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'LVA',
  },
  {
    name: 'Rome',
    countryCode: 'IT',
    value: 'Europe/Rome',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'ITA',
  },
  {
    name: 'Rome',
    countryCode: 'SM',
    value: 'Europe/Rome',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'SMR',
  },
  {
    name: 'Rome',
    countryCode: 'VA',
    value: 'Europe/Rome',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'VAT',
  },
  {
    name: 'Samara',
    countryCode: 'RU',
    value: 'Europe/Samara',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Saratov',
    countryCode: 'RU',
    value: 'Europe/Saratov',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Simferopol',
    countryCode: 'RU',
    value: 'Europe/Simferopol',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'MSK',
      daylightSaving: 'MSK',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Simferopol',
    countryCode: 'UA',
    value: 'Europe/Simferopol',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'MSK',
      daylightSaving: 'MSK',
    },
    countryCodeIso: 'UKR',
  },
  {
    name: 'Sofia',
    countryCode: 'BG',
    value: 'Europe/Sofia',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'BGR',
  },
  {
    name: 'Tallinn',
    countryCode: 'EE',
    value: 'Europe/Tallinn',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'EST',
  },
  {
    name: 'Tirane',
    countryCode: 'AL',
    value: 'Europe/Tirane',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'ALB',
  },
  {
    name: 'Ulyanovsk',
    countryCode: 'RU',
    value: 'Europe/Ulyanovsk',
    offset: {
      standard: '+04:00',
      daylightSaving: '+04:00',
    },
    abbreviation: {
      standard: '+04',
      daylightSaving: '+04',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Vienna',
    countryCode: 'AT',
    value: 'Europe/Vienna',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'AUT',
  },
  {
    name: 'Vilnius',
    countryCode: 'LT',
    value: 'Europe/Vilnius',
    offset: {
      standard: '+02:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: 'EET',
      daylightSaving: 'EEST',
    },
    countryCodeIso: 'LTU',
  },
  {
    name: 'Volgograd',
    countryCode: 'RU',
    value: 'Europe/Volgograd',
    offset: {
      standard: '+03:00',
      daylightSaving: '+03:00',
    },
    abbreviation: {
      standard: '+03',
      daylightSaving: '+03',
    },
    countryCodeIso: 'RUS',
  },
  {
    name: 'Warsaw',
    countryCode: 'PL',
    value: 'Europe/Warsaw',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'POL',
  },
  {
    name: 'Zurich',
    countryCode: 'CH',
    value: 'Europe/Zurich',
    offset: {
      standard: '+01:00',
      daylightSaving: '+02:00',
    },
    abbreviation: {
      standard: 'CET',
      daylightSaving: 'CEST',
    },
    countryCodeIso: 'CHE',
  },
  {
    name: 'Apia',
    countryCode: 'WS',
    value: 'Pacific/Apia',
    offset: {
      standard: '+13:00',
      daylightSaving: '+13:00',
    },
    abbreviation: {
      standard: '+13',
      daylightSaving: '+13',
    },
    countryCodeIso: 'WSM',
  },
  {
    name: 'Auckland',
    countryCode: 'NZ',
    value: 'Pacific/Auckland',
    offset: {
      standard: '+12:00',
      daylightSaving: '+13:00',
    },
    abbreviation: {
      standard: 'NZST',
      daylightSaving: 'NZDT',
    },
    countryCodeIso: 'NZL',
  },
  {
    name: 'Auckland',
    countryCode: 'AQ',
    value: 'Pacific/Auckland',
    offset: {
      standard: '+12:00',
      daylightSaving: '+13:00',
    },
    abbreviation: {
      standard: 'NZST',
      daylightSaving: 'NZDT',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Bougainville',
    countryCode: 'PG',
    value: 'Pacific/Bougainville',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'PNG',
  },
  {
    name: 'Chatham',
    countryCode: 'NZ',
    value: 'Pacific/Chatham',
    offset: {
      standard: '+12:45',
      daylightSaving: '+13:45',
    },
    abbreviation: {
      standard: '+1245',
      daylightSaving: '+1345',
    },
    countryCodeIso: 'NZL',
  },
  {
    name: 'Easter',
    countryCode: 'CL',
    value: 'Pacific/Easter',
    offset: {
      standard: '-06:00',
      daylightSaving: '-05:00',
    },
    abbreviation: {
      standard: '-06',
      daylightSaving: '-05',
    },
    countryCodeIso: 'CHL',
  },
  {
    name: 'Efate',
    countryCode: 'VU',
    value: 'Pacific/Efate',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'VUT',
  },
  {
    name: 'Fakaofo',
    countryCode: 'TK',
    value: 'Pacific/Fakaofo',
    offset: {
      standard: '+13:00',
      daylightSaving: '+13:00',
    },
    abbreviation: {
      standard: '+13',
      daylightSaving: '+13',
    },
    countryCodeIso: 'TKL',
  },
  {
    name: 'Fiji',
    countryCode: 'FJ',
    value: 'Pacific/Fiji',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'FJI',
  },
  {
    name: 'Galapagos',
    countryCode: 'EC',
    value: 'Pacific/Galapagos',
    offset: {
      standard: '-06:00',
      daylightSaving: '-06:00',
    },
    abbreviation: {
      standard: '-06',
      daylightSaving: '-06',
    },
    countryCodeIso: 'ECU',
  },
  {
    name: 'Gambier',
    countryCode: 'PF',
    value: 'Pacific/Gambier',
    offset: {
      standard: '-09:00',
      daylightSaving: '-09:00',
    },
    abbreviation: {
      standard: '-09',
      daylightSaving: '-09',
    },
    countryCodeIso: 'PYF',
  },
  {
    name: 'Guadalcanal',
    countryCode: 'SB',
    value: 'Pacific/Guadalcanal',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'SLB',
  },
  {
    name: 'Guadalcanal',
    countryCode: 'FM',
    value: 'Pacific/Guadalcanal',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'FSM',
  },
  {
    name: 'Guam',
    countryCode: 'GU',
    value: 'Pacific/Guam',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: 'ChST',
      daylightSaving: 'ChST',
    },
    countryCodeIso: 'GUM',
  },
  {
    name: 'Guam',
    countryCode: 'MP',
    value: 'Pacific/Guam',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: 'ChST',
      daylightSaving: 'ChST',
    },
    countryCodeIso: 'MNP',
  },
  {
    name: 'Honolulu',
    countryCode: 'US',
    value: 'Pacific/Honolulu',
    offset: {
      standard: '-10:00',
      daylightSaving: '-10:00',
    },
    abbreviation: {
      standard: 'HST',
      daylightSaving: 'HST',
    },
    countryCodeIso: 'USA',
  },
  {
    name: 'Honolulu',
    countryCode: 'UM',
    value: 'Pacific/Honolulu',
    offset: {
      standard: '-10:00',
      daylightSaving: '-10:00',
    },
    abbreviation: {
      standard: 'HST',
      daylightSaving: 'HST',
    },
    countryCodeIso: 'UMI',
  },
  {
    name: 'Kanton',
    countryCode: 'KI',
    value: 'Pacific/Kanton',
    offset: {
      standard: '+13:00',
      daylightSaving: '+13:00',
    },
    abbreviation: {
      standard: '+13',
      daylightSaving: '+13',
    },
    countryCodeIso: 'KIR',
  },
  {
    name: 'Kiritimati',
    countryCode: 'KI',
    value: 'Pacific/Kiritimati',
    offset: {
      standard: '+14:00',
      daylightSaving: '+14:00',
    },
    abbreviation: {
      standard: '+14',
      daylightSaving: '+14',
    },
    countryCodeIso: 'KIR',
  },
  {
    name: 'Kosrae',
    countryCode: 'FM',
    value: 'Pacific/Kosrae',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'FSM',
  },
  {
    name: 'Kwajalein',
    countryCode: 'MH',
    value: 'Pacific/Kwajalein',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'MHL',
  },
  {
    name: 'Marquesas',
    countryCode: 'PF',
    value: 'Pacific/Marquesas',
    offset: {
      standard: '-09:30',
      daylightSaving: '-09:30',
    },
    abbreviation: {
      standard: '-0930',
      daylightSaving: '-0930',
    },
    countryCodeIso: 'PYF',
  },
  {
    name: 'Nauru',
    countryCode: 'NR',
    value: 'Pacific/Nauru',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'NRU',
  },
  {
    name: 'Niue',
    countryCode: 'NU',
    value: 'Pacific/Niue',
    offset: {
      standard: '-11:00',
      daylightSaving: '-11:00',
    },
    abbreviation: {
      standard: '-11',
      daylightSaving: '-11',
    },
    countryCodeIso: 'NIU',
  },
  {
    name: 'Norfolk',
    countryCode: 'NF',
    value: 'Pacific/Norfolk',
    offset: {
      standard: '+11:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+12',
    },
    countryCodeIso: 'NFK',
  },
  {
    name: 'Noumea',
    countryCode: 'NC',
    value: 'Pacific/Noumea',
    offset: {
      standard: '+11:00',
      daylightSaving: '+11:00',
    },
    abbreviation: {
      standard: '+11',
      daylightSaving: '+11',
    },
    countryCodeIso: 'NCL',
  },
  {
    name: 'Pago Pago',
    countryCode: 'AS',
    value: 'Pacific/Pago_Pago',
    offset: {
      standard: '-11:00',
      daylightSaving: '-11:00',
    },
    abbreviation: {
      standard: 'SST',
      daylightSaving: 'SST',
    },
    countryCodeIso: 'ASM',
  },
  {
    name: 'Pago Pago',
    countryCode: 'UM',
    value: 'Pacific/Pago_Pago',
    offset: {
      standard: '-11:00',
      daylightSaving: '-11:00',
    },
    abbreviation: {
      standard: 'SST',
      daylightSaving: 'SST',
    },
    countryCodeIso: 'UMI',
  },
  {
    name: 'Palau',
    countryCode: 'PW',
    value: 'Pacific/Palau',
    offset: {
      standard: '+09:00',
      daylightSaving: '+09:00',
    },
    abbreviation: {
      standard: '+09',
      daylightSaving: '+09',
    },
    countryCodeIso: 'PLW',
  },
  {
    name: 'Pitcairn',
    countryCode: 'PN',
    value: 'Pacific/Pitcairn',
    offset: {
      standard: '-08:00',
      daylightSaving: '-08:00',
    },
    abbreviation: {
      standard: '-08',
      daylightSaving: '-08',
    },
    countryCodeIso: 'PCN',
  },
  {
    name: 'Port Moresby',
    countryCode: 'PG',
    value: 'Pacific/Port_Moresby',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: '+10',
      daylightSaving: '+10',
    },
    countryCodeIso: 'PNG',
  },
  {
    name: 'Port Moresby',
    countryCode: 'AQ',
    value: 'Pacific/Port_Moresby',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: '+10',
      daylightSaving: '+10',
    },
    countryCodeIso: 'ATA',
  },
  {
    name: 'Port Moresby',
    countryCode: 'FM',
    value: 'Pacific/Port_Moresby',
    offset: {
      standard: '+10:00',
      daylightSaving: '+10:00',
    },
    abbreviation: {
      standard: '+10',
      daylightSaving: '+10',
    },
    countryCodeIso: 'FSM',
  },
  {
    name: 'Rarotonga',
    countryCode: 'CK',
    value: 'Pacific/Rarotonga',
    offset: {
      standard: '-10:00',
      daylightSaving: '-10:00',
    },
    abbreviation: {
      standard: '-10',
      daylightSaving: '-10',
    },
    countryCodeIso: 'COK',
  },
  {
    name: 'Tahiti',
    countryCode: 'PF',
    value: 'Pacific/Tahiti',
    offset: {
      standard: '-10:00',
      daylightSaving: '-10:00',
    },
    abbreviation: {
      standard: '-10',
      daylightSaving: '-10',
    },
    countryCodeIso: 'PYF',
  },
  {
    name: 'Tarawa',
    countryCode: 'KI',
    value: 'Pacific/Tarawa',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'KIR',
  },
  {
    name: 'Tarawa',
    countryCode: 'MH',
    value: 'Pacific/Tarawa',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'MHL',
  },
  {
    name: 'Tarawa',
    countryCode: 'TV',
    value: 'Pacific/Tarawa',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'TUV',
  },
  {
    name: 'Tarawa',
    countryCode: 'UM',
    value: 'Pacific/Tarawa',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'UMI',
  },
  {
    name: 'Tarawa',
    countryCode: 'WF',
    value: 'Pacific/Tarawa',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'WLF',
  },
  {
    name: 'Tarawa',
    countryCode: 'WF',
    value: 'Pacific/Tarawa',
    offset: {
      standard: '+12:00',
      daylightSaving: '+12:00',
    },
    abbreviation: {
      standard: '+12',
      daylightSaving: '+12',
    },
    countryCodeIso: 'WLF',
  },
  {
    name: 'Tongatapu',
    countryCode: 'TO',
    value: 'Pacific/Tongatapu',
    offset: {
      standard: '+13:00',
      daylightSaving: '+13:00',
    },
    abbreviation: {
      standard: '+13',
      daylightSaving: '+13',
    },
    countryCodeIso: 'TON',
  },
];

export const timezoneByCountry = data;
