import { constants } from '@/utils/constants';
import { config } from '@/utils/config';
import Vue from 'vue';

class LocalStorageService {
  private getKnownVersion(): string | null {
    return localStorage.getItem(constants.localstorage.KNOWN_VERSION);
  }

  private setKnownVersion(version: string) {
    localStorage.setItem(constants.localstorage.KNOWN_VERSION, version);
  }

  private versionOnAppLoad = '';

  private migrations = [
    {
      version: '1.6.0',
      description: 'starting from scratch',
      run() {
        localStorage.clear();
        // actually we have httpOnly cookies, so this doesn't affect anything
        Vue.$cookies.remove(constants.ACCESS_TOKEN);
        Vue.$cookies.remove(constants.REFRESH_TOKEN);
      },
    },
  ];

  get isNewVersion(): boolean {
    return this.versionOnAppLoad !== config.Version;
  }

  runMigrations() {
    const known = this.getKnownVersion();
    this.versionOnAppLoad = known ?? '';
    const to = config.Version;

    if (to) {
      this.migrations.reduce((prev, current) => {
        if (current.version === known) return true;
        if (prev) {
          current.run();
          !config.IsProductionEnv &&
            console.log(
              `Local Storage migration for ${current.version}: ${current.description}`,
            );
          return true;
        }
        return false;
      }, !known);

      this.setKnownVersion(to);
    }
  }
}

export const localStorageSrv = new LocalStorageService();
