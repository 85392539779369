
































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import { AvailabilityType, CapacityType, Unit } from '@/api/inventory/InventoryModels';
import { productModule } from '@/store/modules/moduleProduct';
import { httpModule } from '@/store/modules/moduleHttp';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { offerModule } from '@/store/modules/moduleOffer';
import {
  greaterThan1Rules,
  requiredFieldRules,
  validWholeNumbersRules,
} from '@/utils/validation-rules';
import { CapacityPool, PoolStructure, ProductCapacityPool } from '@/models';
import { handleKeyDownOnlyWholeNumeric, isEmpty } from '@/utils/helpers';

@Component
export default class ProductViewCapacity extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private validWholeNumbersRules = validWholeNumbersRules;
  private greaterThan1Rules = greaterThan1Rules;
  private inventoryType = '' as AvailabilityType;
  private baseInventoryType = '' as AvailabilityType;
  private availType = CapacityType;
  private selectedAvailabilityType = CapacityType.CapacityLimitUnlimited;
  private baseSelectedAvailabilityType = CapacityType.CapacityLimitUnlimited;
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;
  private isEmpty = isEmpty;
  private selectedTravelerTypes = [] as Array<string>;
  private capacityPoolValue = null as null | number;
  private byUnitPoolArr = [] as Array<PoolStructure>;
  private travelerFormValid = false;
  private capacityTypeFormValid = false;
  private saving = false;

  mounted() {
    this.selectedAvailabilityType = this.chooseCapacityType();
    this.baseSelectedAvailabilityType = this.chooseCapacityType();
    this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
  }

  unitIdToTravelerName(id: string) {
    const i = this.DisplayTravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.DisplayTravelerTypes[i].text;
    }
    return '';
  }

  validateTraveler() {
    if (this.ProductOffers && this.ProductOffers.length > 0) {
      return;
    }

    if (this.$refs && this.$refs.travelerTypeForm) {
      const { travelerTypeForm } = this.$refs as any;
      const valid = travelerTypeForm.validate();
      if (valid) {
        this.saveTraveler();
      }
    }
  }

  validateCapacity() {
    if (this.ProductOffers && this.ProductOffers.length > 0) {
      return;
    }

    if (this.$refs && this.$refs.form) {
      const { form } = this.$refs as any;
      const valid = form.validate();
      if (valid) {
        this.saveChanges();
      }
    }
  }

  saveChanges() {
    try {
      const byUnit = {} as Map<string, any>;
      this.byUnitPoolArr.forEach((p) => {
        const val = _.toNumber(p.value);
        if (p.value && _.isNumber(val) && val > 0) {
          _.set(byUnit, p.unitId, val);
        }
      });
      const pool = {
        orgId: this.Product.orgId,
        productId: this.Product.id,
        capacityPool: {
          unlimited:
            this.selectedAvailabilityType === CapacityType.CapacityLimitUnlimited,
          pooled:
            this.selectedAvailabilityType === CapacityType.CapacityLimitPooled
              ? _.toNumber(this.capacityPoolValue)
              : 0,
          byUnit:
            this.selectedAvailabilityType === CapacityType.CapacityLimitByUnit
              ? byUnit
              : {},
        },
      } as ProductCapacityPool;
      productModule.setProductCapacityPool(pool);
      this.saving = true;
      productModule.setSelectedCapacityType(this.selectedAvailabilityType);
      const product = _.cloneDeep(productModule.Product);
      if (this.selectedAvailabilityType === CapacityType.CapacityLimitUnlimited) {
        product.availabilityType = AvailabilityType.OpeningHours;
      } else {
        product.availabilityType = AvailabilityType.StartTime;
      }
      productModule.setProduct(product);
      httpModule
        .updateProduct()
        .then(() => {
          httpModule.updateProductCapacity().then();
        })
        .catch(() => {
          // todo send alert
        })
        .finally(() => {
          this.saving = false;
          this.selectedAvailabilityType = this.chooseCapacityType();
          this.baseSelectedAvailabilityType = this.chooseCapacityType();
          this.toggleCapacityEditMode();
        });
    } catch (e) {
      // todo send error to backend
      console.log('save error', e);
    }
  }

  saveTraveler() {
    httpModule.updateProductUnitRelationship(this.selectedTravelerTypes).then();
    productModule.setEditModeTravelerType(false);
  }

  cancel() {
    this.inventoryType = this.baseInventoryType;
    this.toggleCapacityEditMode();
  }

  cancelTravelerType() {
    this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
    this.toggleTravelerTypeEditMode();
  }

  toggleCapacityEditMode() {
    if (this.ProductOffers && this.ProductOffers.length > 0) {
      return;
    }

    this.selectedAvailabilityType = this.chooseCapacityType();

    if (!this.EditMode) {
      this.baseSelectedAvailabilityType = this.chooseCapacityType();
    }
    productModule.setEditModeCapacityType(!this.EditMode);
  }

  toggleTravelerTypeEditMode() {
    if (this.ProductOffers && this.ProductOffers.length > 0) {
      return;
    }

    productModule.setEditModeTravelerType(!this.EditModeTraveler);

    if (this.$refs && this.$refs.travelerTypeForm) {
      const { travelerTypeForm } = this.$refs as any;
      travelerTypeForm.reset();
      this.selectedTravelerTypes = _.cloneDeep(this.SelectedUnitIds);
    }
  }

  chooseCapacityType() {
    this.capacityPoolValue =
      this.CapacityPool &&
      _.isNumber(this.CapacityPool.pooled) &&
      this.CapacityPool.pooled > 0
        ? _.cloneDeep(this.CapacityPool.pooled)
        : null;

    this.byUnitPoolArr = [];
    const c = _.cloneDeep(this.CapacityPoolByUnit) as any;
    this.ProductUnits.forEach((u) => {
      const v = c[u.id] || '';
      this.byUnitPoolArr.push({ unitId: u.id, value: v });
    });

    if (
      this.CapacityPool &&
      _.isNumber(this.CapacityPool.pooled) &&
      this.CapacityPool.pooled > 0
    ) {
      return CapacityType.CapacityLimitPooled;
    } else if (
      this.CapacityPool &&
      _.isObjectLike(this.CapacityPool.byUnit) &&
      _.keys(this.CapacityPool.byUnit).length > 0
    ) {
      return CapacityType.CapacityLimitByUnit;
    }
    return CapacityType.CapacityLimitUnlimited;
  }

  populateCapacityUnit() {
    if (
      this.selectedAvailabilityType === this.availType.CapacityLimitByUnit &&
      _.isEmpty(this.CapacityPoolByUnit)
    ) {
      this.byUnitPoolArr = [] as Array<PoolStructure>;
      if (this.ProductUnits && Array.isArray(this.ProductUnits)) {
        this.ProductUnits.forEach((traveler: Unit) => {
          this.byUnitPoolArr.push({ unitId: traveler.id, value: '' });
        });
      }
    }
  }

  isTrue(unlimited: any) {
    return _.isBoolean(unlimited) && unlimited === true;
  }

  toggleAllTravelerTypes() {
    this.$nextTick(() => {
      if (this.selectedTravelerTypes.length === this.TravelerTypes.length) {
        this.selectedTravelerTypes = [];
      } else {
        const typeIds = [] as Array<string>;
        this.TravelerTypes.forEach((t) => typeIds.push(t.id || ''));
        this.selectedTravelerTypes = typeIds.slice();
      }
    });
  }

  get TravelerTypeSelectIcon() {
    if (
      this.selectedTravelerTypes &&
      this.selectedTravelerTypes.length === this.TravelerTypes.length
    ) {
      return 'mdi-close-box';
    }
    if (
      this.selectedTravelerTypes &&
      this.selectedTravelerTypes.length > 0 &&
      this.selectedTravelerTypes &&
      this.selectedTravelerTypes.length < this.TravelerTypes.length
    ) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }

  get CapacityPool() {
    if (
      productModule.ProductCapacityPool &&
      productModule.ProductCapacityPool.capacityPool
    ) {
      return productModule.ProductCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get CapacityPoolByUnit() {
    if (this.CapacityPool && this.CapacityPool.byUnit) {
      return this.CapacityPool.byUnit;
    }
    return {};
  }

  get Product() {
    return productModule.Product;
  }

  get CapacityTypes() {
    return [
      CapacityType.CapacityLimitUnlimited,
      CapacityType.CapacityLimitPooled,
      CapacityType.CapacityLimitByUnit,
    ];
  }

  get ProductOffers() {
    return offerModule.Offers.filter((item) => item.productId === this.Product.id);
  }

  get DisplayTravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get TravelerTypes() {
    return travelerTypeModule.TravelerTypes;
  }

  get DisplayProductUnits() {
    return productModule.TravelerTypes.map((u) => `${u.displayName} (${u.type})`);
  }

  get SelectedUnitIds() {
    return productModule.SelectedUnitIds;
  }

  set SelectedUnitIds(units: Array<string>) {
    productModule.setSelectedUnitIds(units);
  }

  get ProductUnits() {
    return productModule.TravelerTypes;
  }

  get EditMode() {
    return productModule.EditModeCapacityType;
  }

  get EditModeTraveler() {
    return productModule.EditModeTravelerType;
  }
}
