import { render, staticRenderFns } from "./RdmWelcomeWizard.vue?vue&type=template&id=88dc8cc2&scoped=true&"
import script from "./RdmWelcomeWizard.vue?vue&type=script&lang=ts&"
export * from "./RdmWelcomeWizard.vue?vue&type=script&lang=ts&"
import style0 from "./RdmWelcomeWizard.vue?vue&type=style&index=0&id=88dc8cc2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88dc8cc2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCarousel,VCarouselItem,VCheckbox,VCol,VDialog,VIcon,VImg,VItem,VItemGroup,VRow,VSheet})
