import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  AvailabilityColorMapping,
  AvailabilitySchedule,
  AvailabilityType,
  CapacityType,
  GroupedAvailabilitySchedule,
  ProductOptionRelationship,
  ScheduleView,
  TravelerPrices,
  Unit,
} from '@/api/inventory/InventoryModels';
import {
  AgeClassification,
  Contact,
  DeliveryFormats,
  DeliveryMethods,
  DisplayUnit,
  GRSchedule,
  ProductCapacityPool,
  RedemptionMethods,
  Schedule,
  TripRoute,
} from '@/models';
import _ from 'lodash';
import { constants } from '@/utils/constants';
import { Address, Product } from '@/models/ModelsOppInventory';
import faker from 'faker';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-product',
  namespaced: true,
})
class ModuleProduct extends VuexModule {
  /* Data */
  private loading = false;
  private searchText = '';
  private products = [] as Array<Product>;
  private productAddresses = [] as Array<Address>;
  private product = {} as Product;
  private productSchedules = [] as Array<GRSchedule>;
  private productSchedule = {} as Schedule;
  private productContacts = {} as Array<Contact>;
  private baseProduct = {} as Product;
  private tripRoute = {} as TripRoute;
  private baseTripRoute = {} as TripRoute;
  private productCapacity = {} as ProductCapacityPool;
  private baseProductCapacity = {} as ProductCapacityPool;
  private units = [] as Array<Unit>;
  private displayUnits = [] as Array<DisplayUnit>;
  private availabilitySchedule = [] as Array<AvailabilitySchedule>;
  private groupedAvailabilitySchedule = [] as Array<GroupedAvailabilitySchedule>;
  private selectedScheduleView = {} as ScheduleView;
  private scheduleColorMappings = [] as Array<AvailabilityColorMapping>;
  private scheduleViews = [] as Array<ScheduleView>;
  private description = '';
  private redemptionMethod = '' as RedemptionMethods;
  private deliveryMethods = {} as DeliveryMethods;
  private deliveryFormats = [] as Array<DeliveryFormats>;
  private primaryLocation = {} as Address;
  private departureLocation = {} as Address;
  private arrivalLocation = {} as Address;
  private visitedLocations = {} as Array<Address>;
  private otherLocations = {} as Array<Address>;
  private selectedInventoryType = '' as AvailabilityType;
  private selectedCapacityType = 'unlimited' as CapacityType;
  private travelerPrices = { travelers: [] } as TravelerPrices;
  private ageClassifications = [] as Array<AgeClassification>;
  private productRelationships = [] as Array<ProductOptionRelationship>;
  private baseProductRelationships = [] as Array<ProductOptionRelationship>;
  private selectedUnitIds = [] as Array<string>;
  private createProductDetailsForm = {} as any;
  private createProductLocationForm = {} as any;
  private createProductPolicyForm = {} as any;
  private createProductScheduleForm = {} as any;
  private createProductSaveProgress = 0;
  private editProductLocationForm = {} as any;
  private createProductSaveProgressShow = false;
  private createProductSaveProgressError = false;
  private createProductStep = 1;
  private createProductSaving = false;
  private createProductFormState = false;
  private createProductLocationFormState = false;
  private createProductPolicyFormState = false;
  private createProductScheduleFormState = false;
  private createProductDetailsOpen = false;
  private createProductLocationOpen = false;
  private createProductPolicyOpen = false;
  private createProductScheduleOpen = false;
  private editProductLocationModal = false;
  private editProductPolicyModal = false;
  private editProductLocationFormState = false;
  private editProductPolicyFormState = false;
  private editModeName = false;
  private editModeDescription = false;
  private editModeRedemptionMethod = false;
  private editModeDeliveryMethods = false;
  private editModeDeliveryFormats = false;
  private editModePrimaryLocation = false;
  private editModeDepartureLocation = false;
  private editModeArrivalLocation = false;
  private editModeVisitedLocation = false;
  private editModeInventoryType = false;
  private editModeCapacityType = false;
  private editModeTravelerType = false;
  private editModeCancellationPolicy = false;
  private editModeAvailabilitySchedule = false;
  private editModePriceList = false;
  private loadingName = false;
  private loadingDescription = false;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get SearchText(): string {
    return this.searchText;
  }

  get ProductSchedules(): Array<GRSchedule> {
    return this.productSchedules;
  }

  get ProductContacts(): Array<Contact> {
    return this.productContacts;
  }

  get ProductSchedule(): Schedule {
    return this.productSchedule;
  }

  get CreateProductSaveProgress(): number {
    return this.createProductSaveProgress;
  }

  get CreateProductSaveProgressShow(): boolean {
    return this.createProductSaveProgressShow;
  }

  get CreateProductSaveProgressError(): boolean {
    return this.createProductSaveProgressError;
  }

  get CreateProductSaving(): boolean {
    return this.createProductSaving;
  }

  get CreateProductStep(): number {
    return this.createProductStep;
  }

  get CreateProductFormState(): boolean {
    return this.createProductFormState;
  }

  get CreateProductLocationFormState(): boolean {
    return this.createProductLocationFormState;
  }

  get EditProductLocationFormState(): boolean {
    return this.editProductLocationFormState;
  }

  get EditProductPolicyFormState(): boolean {
    return this.editProductPolicyFormState;
  }

  get CreateProductLocationOpen(): boolean {
    return this.createProductLocationOpen;
  }

  get CreateProductPolicyFormState(): boolean {
    return this.createProductPolicyFormState;
  }

  get CreateProductScheduleFormState(): boolean {
    return this.createProductScheduleFormState;
  }

  get CreateProductDetailsForm(): any {
    return this.createProductDetailsForm;
  }

  get CreateProductDetailsOpen(): boolean {
    return this.createProductDetailsOpen;
  }

  get CreateProductPolicyOpen(): boolean {
    return this.createProductPolicyOpen;
  }

  get CreateProductScheduleOpen(): boolean {
    return this.createProductScheduleOpen;
  }

  get CreateProductLocationForm(): any {
    return this.createProductLocationForm;
  }

  get CreateProductPolicyForm(): any {
    return this.createProductPolicyForm;
  }

  get CreateProductScheduleForm(): any {
    return this.createProductScheduleForm;
  }

  get EditProductLocationForm(): any {
    return this.editProductLocationForm;
  }

  get EditProductLocationModal(): boolean {
    return this.editProductLocationModal;
  }

  get EditProductPolicyModal(): boolean {
    return this.editProductPolicyModal;
  }

  get Products(): Array<Product> {
    return this.products;
  }

  get Product(): Product {
    return this.product;
  }

  get BaseProduct(): Product {
    return this.baseProduct;
  }

  get TravelerTypes(): Array<Unit> {
    return this.units;
  }

  get DisplayUnits(): Array<DisplayUnit> {
    return this.displayUnits;
  }

  get TripRoute(): TripRoute {
    return this.tripRoute;
  }

  get BaseTripRoute(): TripRoute {
    return this.baseTripRoute;
  }

  get ProductCapacityPool(): ProductCapacityPool {
    return this.productCapacity;
  }

  get BaseProductCapacityPool(): ProductCapacityPool {
    return this.baseProductCapacity;
  }

  get ProductAddresses(): Array<Address> {
    return this.productAddresses;
  }

  get SelectedUnitIds(): Array<string> {
    return this.selectedUnitIds;
  }

  get Description(): string {
    return this.description;
  }

  get RedemptionMethod(): RedemptionMethods {
    return this.redemptionMethod;
  }

  get DeliveryMethods(): DeliveryMethods {
    return this.deliveryMethods;
  }

  get DeliveryFormats(): Array<DeliveryFormats> {
    return this.deliveryFormats;
  }

  get PrimaryLocation(): Address {
    return this.primaryLocation;
  }

  get DepartureLocation(): Address {
    return this.departureLocation;
  }

  get ArrivalLocation(): Address {
    return this.arrivalLocation;
  }

  get VisitedLocations(): Array<Address> {
    return this.visitedLocations;
  }

  get OtherLocations(): Array<Address> {
    return this.otherLocations;
  }

  get AgeClassification(): Array<AgeClassification> {
    return this.ageClassifications;
  }

  get SelectedInventoryType(): AvailabilityType {
    return this.selectedInventoryType;
  }

  get SelectedCapacityType(): CapacityType {
    return this.selectedCapacityType;
  }

  get SelectedScheduleView(): ScheduleView {
    return this.selectedScheduleView;
  }

  get ScheduleColorMappings(): Array<AvailabilityColorMapping> {
    return this.scheduleColorMappings;
  }

  get ScheduleViews(): Array<ScheduleView> {
    return this.scheduleViews;
  }

  get AvailabilitySchedules(): Array<AvailabilitySchedule> {
    return this.availabilitySchedule;
  }

  get GroupedAvailabilitySchedules(): Array<GroupedAvailabilitySchedule> {
    return this.groupedAvailabilitySchedule;
  }

  get ProductRelationships(): Array<ProductOptionRelationship> {
    return this.productRelationships;
  }

  get BaseProductRelationships(): Array<ProductOptionRelationship> {
    return this.baseProductRelationships;
  }

  get EditModeName(): boolean {
    return this.editModeName;
  }

  get EditModeDescription(): boolean {
    return this.editModeDescription;
  }

  get EditModeRedemptionMethod(): boolean {
    return this.editModeRedemptionMethod;
  }

  get EditModeDeliveryMethods(): boolean {
    return this.editModeDeliveryMethods;
  }

  get EditModeDeliveryFormats(): boolean {
    return this.editModeDeliveryFormats;
  }

  get EditModePrimaryLocation(): boolean {
    return this.editModePrimaryLocation;
  }

  get EditModeDepartureLocation(): boolean {
    return this.editModeDepartureLocation;
  }

  get EditModeArrivalLocation(): boolean {
    return this.editModeArrivalLocation;
  }

  get EditModeVisitedLocation(): boolean {
    return this.editModeVisitedLocation;
  }

  get EditModeInventoryType(): boolean {
    return this.editModeInventoryType;
  }

  get EditModeCapacityType(): boolean {
    return this.editModeCapacityType;
  }

  get EditModeTravelerType(): boolean {
    return this.editModeTravelerType;
  }

  get EditModeCancellationPolicy(): boolean {
    return this.editModeCancellationPolicy;
  }

  get EditModeAvailabilitySchedule(): boolean {
    return this.editModeAvailabilitySchedule;
  }

  get EditModePriceList(): boolean {
    return this.editModePriceList;
  }

  get LoadingName(): boolean {
    return this.loadingName;
  }

  get LoadingDescription(): boolean {
    return this.loadingDescription;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setSearchText(search: string) {
    this.searchText = search;
  }

  @Mutation
  setProductSchedules(schedules: Array<GRSchedule>) {
    schedules.map((item: GRSchedule) => {
      if (item.open === undefined) item.open = false;
    });
    this.productSchedules = schedules;
  }

  @Mutation
  setProductContacts(contacts: Array<Contact>) {
    this.productContacts = contacts;
  }

  @Mutation
  setProductSchedule(schedule: Schedule) {
    this.productSchedule = schedule;
  }

  @Mutation
  setCreateProductDetailsForm(details: any) {
    this.createProductDetailsForm = details;
  }

  @Mutation
  setCreateProductLocationForm(details: any) {
    this.createProductLocationForm = details;
  }

  @Mutation
  setCreateProductPolicyForm(details: any) {
    this.createProductPolicyForm = details;
  }

  @Mutation
  setCreateProductScheduleForm(details: any) {
    this.createProductScheduleForm = details;
  }

  @Mutation
  setEditProductLocationForm(details: any) {
    this.editProductLocationForm = details;
  }

  @Mutation
  setEditProductLocationModal(show: boolean) {
    this.editProductLocationModal = show;
  }

  @Mutation
  setEditProductPolicyModal(show: boolean) {
    this.editProductPolicyModal = show;
  }

  @Mutation
  setCreateProductSaving(state: boolean) {
    this.createProductSaving = state;
  }

  @Mutation
  setCreateProductSaveProgress(progress: number) {
    this.createProductSaveProgress = progress;
  }

  @Mutation
  setCreateProductSaveProgressShow(show: boolean) {
    this.createProductSaveProgressShow = show;
  }

  @Mutation
  setCreateProductSaveProgressError(error: boolean) {
    this.createProductSaveProgressError = error;
  }

  @Mutation
  setCreateProductStep(step: number) {
    this.createProductStep = step;
  }

  @Mutation
  setCreateProductDetailsOpen(open: boolean) {
    this.createProductDetailsOpen = open;
  }

  @Mutation
  setCreateProductLocationOpen(open: boolean) {
    this.createProductLocationOpen = open;
  }

  @Mutation
  setCreateProductPolicyOpen(open: boolean) {
    this.createProductPolicyOpen = open;
  }

  @Mutation
  setCreateProductScheduleOpen(open: boolean) {
    this.createProductScheduleOpen = open;
  }

  @Mutation
  setCreateProductFormState(state: boolean) {
    this.createProductFormState = state;
  }

  @Mutation
  setCreateProductLocationFormState(state: boolean) {
    this.createProductLocationFormState = state;
  }

  @Mutation
  setEditProductLocationFormState(state: boolean) {
    this.editProductLocationFormState = state;
  }

  @Mutation
  setEditProductPolicyFormState(state: boolean) {
    this.editProductPolicyFormState = state;
  }

  @Mutation
  setCreateProductPolicyFormState(state: boolean) {
    this.createProductPolicyFormState = state;
  }

  @Mutation
  setCreateProductScheduleFormState(state: boolean) {
    this.createProductScheduleFormState = state;
  }

  @Mutation
  setProducts(products: Array<Product>) {
    this.products = products;
  }

  @Mutation
  setProduct(product: Product) {
    this.product = product;
  }

  @Mutation
  setBaseProduct(product: Product) {
    this.baseProduct = product;
  }

  @Mutation
  setProductCapacityPool(pool: ProductCapacityPool) {
    this.productCapacity = pool;
  }

  @Mutation
  setBaseProductCapacityPool(pool: ProductCapacityPool) {
    this.baseProductCapacity = pool;
  }

  @Mutation
  setUnits(units: Array<Unit>) {
    this.units = units;
  }

  @Mutation
  setSelectedUnitIds(units: Array<string>) {
    this.selectedUnitIds = units;
  }

  @Mutation
  setDisplayUnits(units: Array<DisplayUnit>) {
    this.displayUnits = units;
  }

  @Mutation
  setTripRoute(tripRoute: TripRoute) {
    this.tripRoute = tripRoute;
  }

  @Mutation
  setBaseTripRoute(tripRoute: TripRoute) {
    this.baseTripRoute = tripRoute;
  }

  @Mutation
  setProductAddresses(addresses: Array<Address>) {
    this.productAddresses = addresses;
  }

  @Mutation
  setProductRelationships(relationships: Array<ProductOptionRelationship>) {
    this.productRelationships = relationships;
  }

  @Mutation
  setBaseProductRelationships(relationships: Array<ProductOptionRelationship>) {
    this.baseProductRelationships = relationships;
  }

  @Mutation
  setDescription(description: string) {
    this.description = description;
  }

  @Mutation
  setSelectedScheduleView(schedule: ScheduleView) {
    this.selectedScheduleView = schedule;
  }

  @Mutation
  setAvailabilityColorMapping(scheduleColors: Array<AvailabilityColorMapping>) {
    this.scheduleColorMappings = scheduleColors;
  }

  @Mutation
  setScheduleViews(scheduleViews: Array<ScheduleView>) {
    this.scheduleViews = scheduleViews;
  }

  @Mutation
  setRedemptionMethod(redemptionMethod: RedemptionMethods) {
    this.redemptionMethod = redemptionMethod;
  }

  @Mutation
  setDeliveryMethods(deliveryMethods: DeliveryMethods) {
    this.deliveryMethods = deliveryMethods;
  }

  @Mutation
  setDeliveryFormats(deliveryFormats: Array<DeliveryFormats>) {
    this.deliveryFormats = deliveryFormats;
  }

  @Mutation
  setPrimaryLocation(location: Address) {
    this.primaryLocation = location;
  }

  @Mutation
  setDepartureLocation(location: Address) {
    this.departureLocation = location;
  }

  @Mutation
  setArrivalLocation(location: Address) {
    this.arrivalLocation = location;
  }

  @Mutation
  setVisitedLocations(location: Array<Address>) {
    this.visitedLocations = location;
  }

  @Mutation
  setOtherLocations(location: Array<Address>) {
    this.otherLocations = location;
  }

  @Mutation
  setAgeClassification(classifications: Array<AgeClassification>) {
    this.ageClassifications = classifications;
  }

  @Mutation
  appendAgeClassification(classification: AgeClassification) {
    this.ageClassifications.push(classification);
  }

  @Mutation
  setSelectedInventoryType(inventoryType: AvailabilityType) {
    this.selectedInventoryType = inventoryType;
  }

  @Mutation
  setSelectedCapacityType(capacityType: CapacityType) {
    this.selectedCapacityType = capacityType;
  }

  @Mutation
  setAvailabilitySchedules(schedules: Array<AvailabilitySchedule>) {
    this.availabilitySchedule = schedules;
  }

  @Mutation
  setGroupedAvailabilitySchedules(schedules: Array<GroupedAvailabilitySchedule>) {
    this.groupedAvailabilitySchedule = schedules;
  }

  @Mutation
  setEditModeName(mode: boolean) {
    this.editModeName = mode;
  }

  @Mutation
  setEditModeDescription(mode: boolean) {
    this.editModeDescription = mode;
  }

  @Mutation
  setEditModeRedemptionMethod(mode: boolean) {
    this.editModeRedemptionMethod = mode;
  }

  @Mutation
  setEditModeDeliveryMethods(mode: boolean) {
    this.editModeDeliveryMethods = mode;
  }

  @Mutation
  setEditModeDeliveryFormats(mode: boolean) {
    this.editModeDeliveryFormats = mode;
  }

  @Mutation
  setEditModePrimaryLocation(mode: boolean) {
    this.editModePrimaryLocation = mode;
  }

  @Mutation
  setEditModeDepartureLocation(mode: boolean) {
    this.editModeDepartureLocation = mode;
  }

  @Mutation
  setEditModeArrivalLocation(mode: boolean) {
    this.editModeArrivalLocation = mode;
  }

  @Mutation
  setEditModeVisitedLocation(mode: boolean) {
    this.editModeVisitedLocation = mode;
  }

  @Mutation
  setEditModeInventoryType(mode: boolean) {
    this.editModeInventoryType = mode;
  }

  @Mutation
  setEditModeCapacityType(mode: boolean) {
    this.editModeCapacityType = mode;
  }

  @Mutation
  setEditModeCancellationPolicy(mode: boolean) {
    this.editModeCancellationPolicy = mode;
  }

  @Mutation
  setEditModeAvailabilitySchedule(mode: boolean) {
    this.editModeAvailabilitySchedule = mode;
  }

  @Mutation
  setEditModeTravelerType(mode: boolean) {
    this.editModeTravelerType = mode;
  }

  @Mutation
  setEditModePriceList(mode: boolean) {
    this.editModeAvailabilitySchedule = mode;
  }

  @Mutation
  setLoadingName(loading: boolean) {
    this.loadingName = loading;
  }

  @Mutation
  setLoadingDescription(loading: boolean) {
    this.loadingName = loading;
  }

  /* Actions */
  @Action
  selectedProduct(product: Product) {
    this.setProduct(_.cloneDeep(product));
    this.setBaseProduct(_.cloneDeep(product));
    this.configureProduct();
  }

  @Action
  createProduct(product: Product) {
    const products = _.cloneDeep(this.Products);
    products.push(product);
    this.setProducts(products);
  }

  @Action
  updateProduct(product: Product) {
    const products = _.cloneDeep(this.Products);
    const index = products.findIndex((p) => p.id === product.id);
    if (index > -1) {
      products[index] = product;
      this.setProducts(products);
      this.setProduct(_.cloneDeep(product));
      this.setBaseProduct(_.cloneDeep(product));
      this.configureProduct();
    }
  }

  @Action
  removeProduct(id: string) {
    const products = _.cloneDeep(this.Products);
    const index = products.findIndex((p) => p.id === id);
    if (index > -1) {
      products.splice(index, 1);
      this.setProducts(products);
    }
  }

  @Action
  configureProduct() {
    this.setDescription(_.clone(this.Product?.description || ''));
    if (this.Product && this.Product.ticketDelivery) {
      this.setDeliveryFormats(_.clone(this.Product?.ticketDelivery.deliveryFormats || ([] as Array<DeliveryFormats>)));
      this.setDeliveryMethods(
        _.clone(
          Array.isArray(this.Product.ticketDelivery.deliveryMethods) &&
            this.Product.ticketDelivery.deliveryMethods.length > 0
            ? this.Product.ticketDelivery.deliveryMethods[0]
            : ({} as DeliveryMethods),
        ),
      );
      this.setRedemptionMethod(_.clone(this.Product?.ticketDelivery?.redemptionMethod || ('' as RedemptionMethods)));
    }
    // this.setSelectedCapacityType(_.clone(this.Product?.availabilityType || ('unlimited' as CapacityType)));

    this.extractLocations();
  }

  @Action
  clearCreateProductDetailsForm() {
    this.setCreateProductDetailsForm({
      id: faker.datatype.uuid(),
      availabilityType: CapacityType.CapacityLimitUnlimited,
      cancellable: true,
      refundable: true,
      addressId: '',
      address: {},
      travelers: [],
    });
  }

  @Action
  clearCreateProductLocationForm() {
    this.setCreateProductLocationForm({
      addressId: '',
      timezone: '',
      tripRoute: {} as TripRoute,
    });
  }

  @Action
  clearCreateProductPolicyForm() {
    this.setCreateProductPolicyForm({
      addressId: '',
      timezone: '',
    });
  }

  @Action
  clearCreateProductScheduleForm() {
    this.setCreateProductScheduleForm({
      addressId: '',
      timezone: '',
    });
  }

  @Action
  clearEditProductLocationForm() {
    this.setEditProductLocationForm({
      addressId: '',
      timezone: '',
      tripRoute: {} as TripRoute,
    });
  }

  @Action
  clearProductCapacityPool() {
    this.setProductCapacityPool({} as ProductCapacityPool);
    this.setBaseProductCapacityPool({} as ProductCapacityPool);
  }

  @Action
  appendUnit(unit: Unit) {
    this.units.push(unit);
  }

  @Action
  appendUnitFromTravelerList(id: string) {
    const units = _.cloneDeep(travelerTypeModule.TravelerTypes);
    const index = units.findIndex((t) => t.id === id);
    if (index > -1) {
      this.units.push(units[index]);
    }
  }

  @Action
  updateSelectedUnit() {
    this.setSelectedUnitIds([] as Array<string>);
    this.setSelectedUnitIds(this.units.map((u) => u.id));
  }

  @Action
  removeUnit(id: string) {
    const units = _.cloneDeep(this.TravelerTypes);
    const index = units.findIndex((u) => u.id === id);
    if (index > -1) {
      units.splice(index, 1);
      this.setUnits(units);
    }
  }

  @Action
  appendDisplayUnit(unit: DisplayUnit) {
    this.displayUnits.push(unit);
  }

  @Action
  removeDisplayUnit(id: string) {
    const units = _.cloneDeep(this.DisplayUnits);
    const index = units.findIndex((u) => u.value === id);
    if (index > -1) {
      units.splice(index, 1);
      this.setDisplayUnits(units);
    }
  }

  @Action
  nextCreateProductStep() {
    const step = _.cloneDeep(this.CreateProductStep) + 1;
    this.setCreateProductStep(step);
  }

  @Action
  goCreateProductStep(step: number) {
    this.setCreateProductStep(step);
  }

  @Action
  startProductCreateSaving() {
    this.setCreateProductSaveProgress(this.CreateProductSaveProgressError ? -1 : 0);
    this.setCreateProductSaveProgressShow(true);
    this.setCreateProductSaving(true);
    setTimeout(() => {
      this.setCreateProductSaveProgress(this.CreateProductSaveProgressError ? -1 : 15);
    }, 500);
    setTimeout(() => {
      this.setCreateProductSaveProgress(this.CreateProductSaveProgressError ? -1 : 35);
    }, 800);
    setTimeout(() => {
      this.setCreateProductSaveProgress(this.CreateProductSaveProgressError ? -1 : 65);
    }, 1000);
  }

  @Action
  finishProductCreateSaving() {
    this.setCreateProductSaving(false);
    setTimeout(() => {
      this.setCreateProductSaveProgress(this.CreateProductSaveProgressError ? -1 : 95);
    }, 500);
    setTimeout(() => {
      this.setCreateProductSaveProgress(this.CreateProductSaveProgressError ? -1 : 100);
    }, 600);

    setTimeout(() => {
      this.setCreateProductSaveProgressShow(false);
      this.setCreateProductSaveProgressError(false);
    }, 4000);
  }

  @Action
  updateProductTripRoue(tripRoute: TripRoute) {
    this.setTripRoute(_.cloneDeep(tripRoute));
    this.setBaseTripRoute(_.cloneDeep(tripRoute));
  }

  @Action
  configureLocationForm() {
    const addressId =
      this.Product && _.isString(this.Product.addressId) && this.Product.addressId.length > 0
        ? this.Product.addressId
        : '';
    const timezone =
      this.Product && _.isString(this.Product.timeZone) && this.Product.timeZone.length > 0
        ? this.Product.timeZone
        : '';
    const departure =
      this.TripRoute && _.isString(this.TripRoute.departure) && this.TripRoute.departure.length > 0
        ? this.TripRoute.departure
        : '';
    const arrival =
      this.TripRoute && _.isString(this.TripRoute.arrival) && this.TripRoute.arrival.length > 0
        ? this.TripRoute.arrival
        : '';
    const stops =
      this.TripRoute && _.isArray(this.TripRoute.stops) && this.TripRoute.stops.length > 0 ? this.TripRoute.stops : [];
    this.setEditProductLocationForm({
      addressId: addressId,
      timezone: timezone,
      tripRoute: {
        departure: departure,
        arrival: arrival,
        stops: stops,
      } as TripRoute,
    });
  }

  // @Action
  // buildProduct(): Product {
  //   const product = _.cloneDeep(this.Product);
  //   if (!product.version) {
  //     product.version = 0;
  //   }
  //   product.version += 1;
  //   if (product.product) {
  //     product.product.description = _.clone(this.Description);
  //     product.product.deliveryFormats = _.clone(this.DeliveryFormats);
  //     product.product.deliveryMethods = _.clone(this.DeliveryMethods);
  //     product.product.redemptionMethod = _.clone(this.RedemptionMethod);
  //     product.product.availabilityType = _.clone(this.SelectedInventoryType); // todo change this to use correct AvailabilityStatus type
  //     product.product.locations = [];
  //     if (!_.isEmpty(this.PrimaryLocation)) {
  //       product.product.locations.push(_.clone(this.PrimaryLocation));
  //     }
  //     if (!_.isEmpty(this.DepartureLocation)) {
  //       product.product.locations.push(_.clone(this.DepartureLocation));
  //     }
  //     if (!_.isEmpty(this.ArrivalLocation)) {
  //       product.product.locations.push(_.clone(this.ArrivalLocation));
  //     }
  //     if (!_.isEmpty(this.VisitedLocations)) {
  //       product.product.locations.push(..._.clone(this.VisitedLocations));
  //     }
  //   }
  //   return product;
  // }

  @Action
  public configProductAddresses(addresses: Array<Address>) {
    addresses.forEach((address) => {
      console.log('config product address', address);
      // if (address.type === AddressType.PRIMARY) {
      //   this.setPrimaryLocation(address);
      // }
      // if (address.type === AddressType.DEPARTURE) {
      //   this.setDepartureLocation(address);
      // }
      // if (address.type === AddressType.ARRIVAL) {
      //   this.setArrivalLocation(address);
      // }
      // if (address.type === AddressType.STOP) {
      //   this.appendVisitedLocation(address);
      // }
      // if (address.type === AddressType.OTHER) {
      //   this.appendVisitedLocation(address);
      // }
    });
  }

  @Action
  public updateProductPrimaryLocation(location: Address) {
    // primary
    this.setPrimaryLocation(location);
  }

  @Action
  extractLocations() {
    // if (!_.isNil(this.Product?.product?.locations)) {
    //   const primary = this.Product.product.locations.find((location: Location) => {
    //     return location.locationType === LocationType.Primary;
    //   });
    //   const departure = this.Product.product.locations.find((location: Location) => {
    //     return location.locationType === LocationType.Start;
    //   });
    //   const arrival = this.Product.product.locations.find((location: Location) => {
    //     return location.locationType === LocationType.End;
    //   });
    //   const visited = this.Product.product.locations.filter((location: Location) => {
    //     return location.locationType === LocationType.Visited;
    //   });
    //   this.setPrimaryLocation(primary || ({} as Address));
    //   this.setDepartureLocation(departure || ({} as Address));
    //   this.setArrivalLocation(arrival || ({} as Address));
    //   this.setVisitedLocations(visited || ([] as Array<Address>));
    // }
  }

  @Action
  async createAvailabilitySchedule(schedule: AvailabilitySchedule) {
    this.availabilitySchedule.push(schedule);
  }

  @Action
  selectProduct(id: string) {
    const product = this.Products.find((product) => product.id === id);
    if (product) {
      this.setProduct(_.cloneDeep(product));
      this.setBaseProduct(_.cloneDeep(product));
    }
  }

  @Action
  appendVisitedLocation(address: Address) {
    this.visitedLocations.push(address);
    // this.visitedLocations.sort((a, b) => {
    //   return 0;
    // });
  }

  @Action
  appendOtherLocation(address: Address) {
    this.otherLocations.push(address);
    // this.otherLocations.sort((a, b) => {
    //   return 0;
    // });
  }

  @Action
  toggleFieldLoading(field?: string) {
    switch (field) {
      case constants.fields.PRODUCT_NAME:
        this.setLoadingName(!this.LoadingName);
        break;
      case constants.fields.PRODUCT_DESCRIPTION:
        this.setLoadingDescription(!this.LoadingDescription);
        break;
      default:
        this.setLoading(!this.Loading);
    }
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setDescription('');
    this.setRedemptionMethod('' as RedemptionMethods);
    this.setDeliveryMethods({} as DeliveryMethods);
    this.setDeliveryFormats([] as Array<DeliveryFormats>);
    this.setPrimaryLocation({} as Address);
    this.setDepartureLocation({} as Address);
    this.setArrivalLocation({} as Address);
    this.setVisitedLocations({} as Array<Address>);
    this.setCreateProductStep(1);
    this.setCreateProductSaveProgress(0);
    this.setCreateProductSaveProgressShow(false);
    this.setCreateProductSaveProgressError(false);
    this.setCreateProductSaving(false);
    this.setCreateProductDetailsOpen(false);
    this.setCreateProductLocationOpen(false);
    this.setCreateProductPolicyOpen(false);
    this.setCreateProductScheduleOpen(false);
    this.setCreateProductFormState(false);
    this.setCreateProductLocationFormState(false);
    this.setCreateProductPolicyFormState(false);
    this.setCreateProductScheduleFormState(false);
    this.clearCreateProductDetailsForm();
    this.clearCreateProductLocationForm();
    this.clearEditProductLocationForm();
    this.setEditProductLocationFormState(false);
    this.setEditProductPolicyFormState(false);
    this.clearCreateProductPolicyForm();
    this.clearCreateProductScheduleForm();
    this.clearProductCapacityPool();
    this.setEditModeCancellationPolicy(false);
    this.setEditModeInventoryType(false);
    this.setEditModeCapacityType(false);
    this.setEditModeVisitedLocation(false);
    this.setEditModeDescription(false);
    this.setEditModeArrivalLocation(false);
    this.setEditModeDepartureLocation(false);
    this.setEditModeDeliveryFormats(false);
    this.setEditModePrimaryLocation(false);
    this.setEditModeRedemptionMethod(false);
    this.setEditModeDeliveryMethods(false);
    this.setEditProductLocationModal(false);
    this.setEditModeTravelerType(false);
    this.setProduct({} as Product);
    this.setBaseProduct({} as Product);
    this.setProducts([] as Array<Product>);
    this.setProductAddresses([] as Array<Address>);
    this.setSearchText('');
    this.setTripRoute({} as TripRoute);
    this.setAgeClassification([] as Array<AgeClassification>);
    this.setUnits([] as Array<Unit>);
    this.setSelectedUnitIds([] as Array<string>);
    this.setSelectedCapacityType('unlimited' as CapacityType);
    this.setDisplayUnits([] as Array<DisplayUnit>);
    this.setProductCapacityPool({} as ProductCapacityPool);
    this.setBaseProductCapacityPool({} as ProductCapacityPool);
    this.setProductSchedules([] as Array<GRSchedule>);
    this.setProductSchedule({} as Schedule);
  }
}

export const productModule = getModule(ModuleProduct, store);
