



























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import {
  optionalStartDateBeforeEndDateRules,
  requiredFieldRules,
  requiredStartDateBeforeTodayDateRules,
} from '@/utils/validation-rules';
import { Product } from '@/models';
import { productModule } from '@/store/modules/moduleProduct';
import { companyModule } from '@/store/modules/moduleCompany';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { constants } from '@/utils/constants';
import RdmTimeInput from '@/components/common/time/RdmTimeInput.vue';
import { TimeEntryMode } from '@/api/inventory/InventoryModels';
import faker from 'faker';
import _ from 'lodash';
import moment from 'moment';
import { httpModule } from '@/store/modules/moduleHttp';

@Component({
  components: {
    RdmCollapsableControlCard,
    RdmTimeInput,
  },
})
export default class ProductCreateSchedule extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private optionalStartBeforeEndField = optionalStartDateBeforeEndDateRules;
  private requiredStartBeforeEndField = requiredStartDateBeforeTodayDateRules;
  private validation = false;
  private dateNow = new Date();
  private minDate = moment(new Date()).format('YYYY-MM-DD');
  private product = {} as Product;
  private startDateMenu = false;
  private endDateMenu = false;
  private timeCapacity = {
    name: '',
    validStart: '',
    validEnd: '',
    includeCapacity: false,
  };
  private timeEntryModes = [
    TimeEntryMode.Grouped,
    TimeEntryMode.Individual,
    TimeEntryMode.Without,
  ];
  private timeEntryMode = '';
  private timeEntryModeGrouped = TimeEntryMode.Grouped;
  private timeEntryModeIndividual = TimeEntryMode.Individual;
  private timeEntryModeWithout = TimeEntryMode.Without;
  private days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ] as Array<string>;
  private availAbleDays = [] as Array<string>;
  private selectedDays = [] as Array<string>;
  private groupedDays = [] as Array<any>;
  private individualDays = [] as any;
  private time = {
    start: null,
    end: null,
    timeStartMenu: false,
    timeEndMenu: false,
  };

  mounted() {
    this.availAbleDays = this.days;
    this.individualDays = this.days.map((day) => {
      return {
        weekday: day,
        timeSlots: [{ ...this.time }],
      };
    });
    if (this.IsEditPage) {
      this.timeEntryModes = [TimeEntryMode.Grouped, TimeEntryMode.Individual];
    }
  }

  updateSlotStart(slot: any, newStartTime: any) {
    slot.start = newStartTime;
  }

  updateSlotEnd(slot: any, newEndTime: any) {
    slot.end = newEndTime;
  }

  removeTimeSlot(i: number, group: any) {
    if (i !== 0) group.times.splice(i, 1);
  }

  generateTimeSlotsForGrouped() {
    const timeSlots = [] as any;
    this.groupedDays.map((group: any) => {
      group.times.map((time: any) => {
        group.days.map((day: string) => {
          timeSlots.push({
            weekday: day,
            start: time.start,
            end: time.end,
          });
        });
      });
    });
    return timeSlots;
  }
  generateTimeSlotsForIndividual() {
    const timeSlots = [] as any;
    this.individualDays.map((day: any) => {
      day.timeSlots.map((time: any) => {
        if (time.start !== null || time.start?.length > 0) {
          timeSlots.push({
            weekday: day.weekday,
            start: time.start,
            end: time.end,
          });
        }
      });
    });
    return timeSlots;
  }

  validateAndSave() {
    this.validation = true;
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (isValid) {
        this.createScheduleAndAttachToProduct();
      }
    }
  }

  async createScheduleAndAttachToProduct() {
    let timeSlots = [] as any;
    if (this.timeEntryMode === this.timeEntryModeGrouped) {
      timeSlots = this.generateTimeSlotsForGrouped();
    }
    if (this.timeEntryMode === this.timeEntryModeIndividual) {
      timeSlots = this.generateTimeSlotsForIndividual();
    }
    if (this.timeEntryMode === this.timeEntryModeWithout) {
      this.product = _.cloneDeep(this.Product);
      this.$router
        .push({ name: constants.routes.PRODUCT_DETAILS, params: { id: this.product.id } })
        .catch();
      return;
    }
    const schedule = {
      id: faker.datatype.uuid(),
      name: this.timeCapacity.name,
      validFrom: this.timeCapacity.validStart,
      validUntil: this.timeCapacity.validEnd || null,
      timeSlots: timeSlots,
    };
    await httpModule.updateSchedule(schedule);
    _.delay(async () => {
      await httpModule
        .addScheduleToProduct(this.Schedule.id)
        .then(() => {
          this.product = _.cloneDeep(this.Product);
          if (this.$route.path.includes('view')) {
            productModule.setProductSchedules([
              ...productModule.ProductSchedules,
              {
                id: schedule.id,
                name: schedule.name,
                open: false,
                path: '',
              },
            ]);
            this.addScheduleFromView();
            this.clearData();
          } else {
            this.$router
              .push({
                name: constants.routes.PRODUCT_DETAILS,
                params: { id: this.product.id },
              })
              .catch();
          }
        })
        .catch();
    }, 2000);
  }

  discard() {
    this.clearData();
    if (this.$route.path.includes('view')) {
      this.addScheduleFromView();
    } else {
      this.$router.push({ name: constants.routes.PRODUCT_LIST }).catch();
    }
  }

  clearData() {
    this.availAbleDays = this.days;
    this.individualDays = this.days.map((day) => {
      return {
        weekday: day,
        timeSlots: [{ ...this.time }],
      };
    });
    this.timeCapacity = {
      name: '',
      validStart: '',
      validEnd: '',
      includeCapacity: false,
    };
    this.time = {
      start: null,
      end: null,
      timeStartMenu: false,
      timeEndMenu: false,
    };
    this.selectedDays = [];
    this.groupedDays = [];
    this.startDateMenu = false;
    this.endDateMenu = false;
  }

  toggleScheduleOpen() {
    productModule.setCreateProductScheduleOpen(!this.ScheduleOpen);
  }

  addScheduleFromView() {
    scheduleModule.toggleCreateSchedule();
    productModule.goCreateProductStep(1);
    productModule.setCreateProductScheduleOpen(false);
  }

  addDayGroup() {
    this.availAbleDays = this.availAbleDays?.filter(
      (el) => !this.selectedDays.includes(el),
    );
    this.groupedDays = [
      ...this.groupedDays,
      {
        days: this.selectedDays,
        times: [{ ...this.time }],
      },
    ];
    this.selectedDays = [];
  }

  sortDays(a: any, b: any) {
    a = this.days.indexOf(a);
    b = this.days.indexOf(b);
    return a < b ? 0 : 1;
  }

  removeGroup(index: number) {
    const groups = this.groupedDays;
    const removed = groups.splice(index, 1);
    this.availAbleDays = [...this.availAbleDays, ...removed[0].days].sort(this.sortDays);
    this.groupedDays = groups;
  }

  addMoreTimeSlot(times: any) {
    times.push({ ...this.time });
  }

  invalidSlotTime(slot: any) {
    if (slot.start && slot.end) {
      let hourMins = slot.start.split(':');
      const start = {
        hours: Number(hourMins[0]),
        minutes: Number(hourMins[1]),
      };

      hourMins = slot.end.split(':');
      const end = {
        hours: Number(hourMins[0]),
        minutes: Number(hourMins[1]),
      };

      if (start.hours * 60 + start.minutes > end.hours * 60 + end.minutes) {
        return true;
      }
    }

    return false;
  }

  /* Page Data */

  get Schedule() {
    return scheduleModule.Schedule;
  }

  get Company() {
    return companyModule.Company;
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get Product() {
    return productModule.Product;
  }

  get CreateProductScheduleFormState(): boolean {
    return productModule.CreateProductScheduleFormState;
  }

  set CreateProductScheduleFormState(state: boolean) {
    productModule.setCreateProductScheduleFormState(state);
  }

  get IsFormValid(): boolean {
    let isValid = true;
    this.groupedDays.forEach((group: any) => {
      if (group.times.length === 0) {
        isValid = false;
      }
      group.times.forEach((time: any) => {
        if (time.start == null || time.end == null || this.invalidSlotTime(time)) {
          isValid = false;
        }
      });
    });
    if (this.timeEntryMode === this.timeEntryModeGrouped)
      return this.groupedDays.length != 0 && isValid;
    else if (this.timeEntryMode === this.timeEntryModeIndividual)
      return (
        this.generateTimeSlotsForIndividual().length &&
        !this.generateTimeSlotsForIndividual().some((time: any) => {
          return time.start == null || time.end == null || this.invalidSlotTime(time);
        })
      );
    else if (this.timeEntryMode.length === 0) return false;
    else return this.timeEntryMode === this.timeEntryModeWithout;
  }

  /* UI State */
  get Step() {
    return productModule.CreateProductStep;
  }

  get Saving() {
    return productModule.CreateProductSaving;
  }

  get ScheduleOpen() {
    return productModule.CreateProductScheduleOpen;
  }

  set ScheduleOpen(open: boolean) {
    productModule.setCreateProductScheduleOpen(open);
  }

  get IsEditPage() {
    return this.$route.path.includes('view');
  }

  get StartDate() {
    const padTo2Digits = (num: any) => num.toString().padStart(2, '0');
    return [
      this.dateNow.getFullYear(),
      padTo2Digits(this.dateNow.getMonth() + 1),
      padTo2Digits(this.dateNow.getDate() - 1),
    ].join('/');
  }

  /* UI Data */
}
