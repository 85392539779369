












































import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import _ from 'lodash';
import TicketFormatViewEdit from '@/components/product/detail/components/TicketFormatViewEdit.vue';
import DeliveryMethodsViewEdit from '@/components/product/detail/components/DeliveryMethodsViewEdit.vue';
import RedemptionMethodViewEdit from '@/components/product/detail/components/RedemptionMethodViewEdit.vue';
import { offerModule } from '@/store/modules/moduleOffer';
@Component({
  components: { RedemptionMethodViewEdit, DeliveryMethodsViewEdit, TicketFormatViewEdit },
})
export default class OfferEditTicket extends Vue {
  private requiredField = requiredFieldRules;
  private offerDescription = '';
  private baseOfferDescription = '';
  private deliveryMethods = {
    TICKET: 'Single bar code per traveler',
    VOUCHER: 'Single bar code per booking',
  };

  mounted() {
    // this.offerDescription = _.cloneDeep(this.Offer.description || '');
    // this.baseOfferDescription = _.cloneDeep(this.Offer.description || '');
  }

  saveDescription() {
    // todo update name
    const offer = _.cloneDeep(this.Offer);
    offerModule.updateOffer(offer);
    // todo close edit description
    offerModule.toggleEditOfferTicketing();
  }

  cancelDescription() {
    this.offerDescription = this.baseOfferDescription;
    offerModule.toggleEditOfferTicketing();
  }

  get Offer() {
    return offerModule.Offer;
  }

  get EditOfferTicketing() {
    return offerModule.EditOfferTicketing;
  }

  get Product() {
    return offerModule.Product;
  }
}
