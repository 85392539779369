









import { Component, Vue } from 'vue-property-decorator';
import { channelModule } from '@/store/modules/moduleChannel';

@Component
export default class RateCardOfferNameView extends Vue {
  toggleEditOffersState() {
    channelModule.setEditOffersState();
  }

  get Loading() {
    return channelModule.Loading;
  }

  get RateCard() {
    return channelModule.RateCard;
  }
}
