import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { InventoryEvent } from '@/models/InventoryModels';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-inventory',
  namespaced: true,
})
class ModuleInventory extends VuexModule {
  /* Data */
  private loading = false;
  private itemOpen = false;
  private editMode = false;
  private addMoreInventoryMode = false;
  private eventData = {} as InventoryEvent;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }
  get ItemOpen(): boolean {
    return this.itemOpen;
  }
  get EditMode(): boolean {
    return this.editMode;
  }
  get EventData() {
    return this.eventData;
  }
  get AddMoreInventoryMode() {
    return this.addMoreInventoryMode;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  /* MUTATION */

  @Mutation
  setItemState() {
    this.itemOpen = !this.itemOpen;
  }

  @Mutation
  setEventData(event: any) {
    this.eventData = event;
  }

  @Mutation
  setEditMode() {
    this.editMode = !this.editMode;
  }

  @Mutation
  setAddMoreInventoryMode() {
    this.addMoreInventoryMode = !this.addMoreInventoryMode;
  }

  /* Actions */
  @Action
  toggleEvent(event?: any) {
    this.setItemState();
    if (event) this.setEventData(event);
  }

  @Action
  toggleAddMoreInventoryMode() {
    this.setAddMoreInventoryMode();
  }

  @Action
  toggleEditMode() {
    this.setEditMode();
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    if (this.ItemOpen) this.setItemState();
  }
}

export const inventoryModule = getModule(ModuleInventory, store);
