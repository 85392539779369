

























































































































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import {
  requiredFieldRules,
  requiredMultiSelectFieldRules,
  requiredNumberFieldRules,
  requiredStartTimeBeforeEndTimeRules,
} from '@/utils/validation-rules';
import {
  AvailabilitySchedule,
  AvailabilityType,
  Days,
  ScheduleView,
} from '@/api/inventory/InventoryModels';
import _ from 'lodash';
import moment from 'moment';
import faker from 'faker';
import RdmMessage from '@/components/common/alert/RdmMessage.vue';
import { constants } from '@/utils/constants';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import ProductCalendarSummary from '@/components/product/detail/components/availability/ProductCalendarSummary.vue';
import ProductCalendarFullscreen from '@/components/product/detail/components/availability/ProductCalendarFullscreen.vue';
import RdmWarnModal from '@/components/common/modal/WarnModal.vue';
import { httpModule } from '@/store/modules/moduleHttp';

interface CalendarEvent {
  name: string;
  start: string;
  end: string;
  color: string;
  capacity: string;
  timed: boolean;
}

interface AvailabilityTime {
  start: string;
  end: string;
  capacity: number;
}

interface IRdmWeeklyAvailSchedule {
  Monday: Array<AvailabilitySchedule>;
  Tuesday: Array<AvailabilitySchedule>;
  Wednesday: Array<AvailabilitySchedule>;
  Thursday: Array<AvailabilitySchedule>;
  Friday: Array<AvailabilitySchedule>;
  Saturday: Array<AvailabilitySchedule>;
  Sunday: Array<AvailabilitySchedule>;
}

@Component({
  components: {
    RdmCollapsableCard,
    RdmMessage,
    RdmWarnModal,
    ProductCalendarSummary,
    ProductCalendarFullscreen,
  },
  props: {},
})
export default class ProductAvailabilitySummary extends Vue {
  private addSchedule: AvailabilitySchedule = {
    daysOfWeek: [],
    eventPadding: 0,
  } as AvailabilitySchedule;
  private editSchedule: AvailabilitySchedule = {} as AvailabilitySchedule;
  private removeSchedule: AvailabilitySchedule = {} as AvailabilitySchedule;
  private createScheduleModal = false;
  private editScheduleModal = false;
  private removeScheduleModal = false;
  private viewScheduleModal = false;
  private scheduleAddForm = false;
  private scheduleEditForm = false;
  private menuStartTime = false;
  private menuEndTime = false;
  private menuExclusionDates = false;
  private menuValidDates = false;
  private displayCalendar = false;
  private inventoryFreesale = AvailabilityType.OpeningHours;
  private addStartTime = '';
  private addEndTime = '';
  private addValidDates: string[] = [];
  private addExclusionDates: string[] = [];
  private addTimesLength = 1;
  private addAvailTimes: Array<AvailabilityTime> = [
    {
      start: '',
      end: '',
      capacity: 0,
    },
  ];
  private editStartTime = '';
  private editEndTime = '';
  private editValidDates: string[] = [];
  private editExclusionDates: string[] = [];
  private editAvailTimes: Array<AvailabilityTime> = [
    {
      start: '',
      end: '',
      capacity: 0,
    },
  ];
  private weekday = [0, 1, 2, 3, 4, 5, 6];
  private type = 'week' as 'month' | 'week' | 'day';
  private events: any[] = [];
  private calendarValue = '';
  private mode = 'stack';
  private freesaleMinimum = -1;
  private limitCapacityMinimum = 1;
  private colors = [
    '#489DCB',
    '#DB1D4A',
    '#fca311',
    '#3CBE6A',
    '#0C334C',
    // '#8d8d8f',
    // '#202020',
  ];
  private requiredFieldRule = requiredFieldRules;
  private requiredMultipleSelect = requiredMultiSelectFieldRules;
  private requiredNumberRule = requiredNumberFieldRules;
  private requiredStartBeforeEndRule = requiredStartTimeBeforeEndTimeRules;
  private currentRemoveSchedule = {} as ScheduleView;
  private days = [] as Array<Days>;
  private weeklyAvailSchedule: IRdmWeeklyAvailSchedule = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  };

  mounted() {
    httpModule.loadAvailabilitySchedules();
    this.days = this.createInitialDays();
    this.$root.$on(constants.events.OPEN_FULLSCREEN_CALENDAR, () => {
      this.viewScheduleModal = true;
    });
  }

  beforeDestroy() {
    this.$root.$off(constants.events.OPEN_FULLSCREEN_CALENDAR);
  }

  scrollCalendar() {
    const calendar = this.$refs.calendar as any;
    this.$vuetify.goTo(calendar);
  }

  selectEvent() {
    // todo set a random availability amount less than or equal to maxCapacity
  }
  /* End calendar functions */

  editGroupSchedule(schedule: ScheduleView) {
    productModule.setSelectedScheduleView(schedule);
    this.$router
      .push({ name: constants.routes.PRODUCT_TIME_CAPACITY_EDIT })
      .catch(() => {});
  }

  removeGroupSchedule(schedule: ScheduleView) {
    this.currentRemoveSchedule = schedule;
    const warn: any = this.$refs.warn;
    warn.dialog = true;
  }

  proceedRemoveGroupSchedule() {
    httpModule
      .removeAvailabilitySchedules(this.currentRemoveSchedule.schedules)
      .then(() => {});
  }

  validateCreateForm() {
    if (this.$refs && this.$refs.addScheduleForm) {
      const addScheduleForm: any = this.$refs.addScheduleForm;
      const valid = addScheduleForm.validate();

      if (!valid) {
        return;
      }
      this.createSchedule();
    }
  }

  createSchedule() {
    // todo convert addAvailTimes to schedules
    const days = this.addSchedule.daysOfWeek;

    if (days && Array.isArray(days)) {
      const validStart = moment().format('YYYY-MM-DD');
      const validEnd = moment(`2100-01-01 00:00`).format('YYYY-MM-DD');
      days.forEach((day: string) => {
        if (this.addAvailTimes && Array.isArray(this.addAvailTimes)) {
          this.addAvailTimes.forEach((availTime: AvailabilityTime) => {
            const schedule = {
              id: faker.datatype.uuid(),
              supplierId: '',
              productId: this.Product.id,
              name: day,
              daysOfWeek: [day],
              startTime: availTime.start,
              endTime: availTime.end,
              eventDuration: 0,
              eventPadding: 0,
              maxCapacity: _.toNumber(availTime.capacity) || 0,
              excludedDays: [],
              validFrom: validStart,
              validUntil: validEnd,
            } as AvailabilitySchedule;

            productModule
              .createAvailabilitySchedule(schedule)
              .then(() => {})
              .finally(() => {
                this.createScheduleModal = false;
                this.addTimesLength = 1;
                this.addAvailTimes = [
                  {
                    start: '',
                    end: '',
                    capacity: 0,
                  },
                ];
                this.addSchedule = {
                  daysOfWeek: [],
                  eventPadding: 0,
                } as AvailabilitySchedule;
              });
          });
        }
      });
    }
  }

  updateSchedule() {
    const schedule = {
      id: this.editSchedule.id,
      supplierId: this.editSchedule.supplierId,
      productId: this.editSchedule.productId,
      name: this.editSchedule.name,
      daysOfWeek: this.editSchedule.daysOfWeek,
      startTime: this.editStartTime,
      endTime: this.editEndTime,
      eventDuration: _.toNumber(this?.editSchedule?.eventDuration) || 0,
      eventPadding: _.toNumber(this?.editSchedule?.eventPadding) || 0,
      maxCapacity: _.toNumber(this?.editSchedule?.maxCapacity) || 0,
      excludedDays: this.editExclusionDates,
      validFrom: this.editValidDates[0] || '',
      validUntil: this.editValidDates[1] || '',
    } as AvailabilitySchedule;

    httpModule
      .updateAvailabilitySchedule(schedule)
      .then(() => {})
      .finally(() => {
        this.editScheduleModal = false;
        this.editAvailTimes = [
          {
            start: '',
            end: '',
            capacity: 0,
          },
        ];
        this.editSchedule = {
          daysOfWeek: [],
          eventPadding: 0,
        } as AvailabilitySchedule;
      });
  }

  proceedRemoveSchedule(schedule: AvailabilitySchedule) {
    httpModule
      .removeAvailabilitySchedule(schedule)
      .then(() => {})
      .finally(() => {
        this.removeScheduleModal = false;
        this.removeSchedule = {} as AvailabilitySchedule;
      });
  }

  saveValidDates() {
    this.sortValidDates();
    const validDateRef = this.$refs.validDates as any;
    if (!_.isUndefined(validDateRef)) {
      validDateRef.save(this.addValidDates);
    }
  }

  sortValidDates() {
    this.addValidDates.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });
  }

  addAvailTimeItem() {
    this.addAvailTimes.push({
      start: '',
      end: '',
      capacity: 0,
    });
  }

  toggleEditModeAvailSchedule() {
    productModule.setEditModeAvailabilitySchedule(!this.EditMode);
  }

  goToTimeAndCapacityPage() {
    this.$router
      .push({ name: constants.routes.PRODUCT_TIME_CAPACITY_ADD })
      .catch(() => {});
  }

  openCreateModal() {
    this.addSchedule = {
      eventPadding: 0,
    } as AvailabilitySchedule;
    this.addStartTime = '';
    this.addEndTime = '';
    this.addValidDates = [];
    this.addExclusionDates = [];
    this.createScheduleModal = true;
  }

  openEditModal(schedule: AvailabilitySchedule) {
    this.editSchedule = { ...schedule } as AvailabilitySchedule;
    this.editStartTime = schedule.startTime || '';
    this.editEndTime = schedule.endTime || '';
    this.editValidDates = [schedule.validFrom || '', schedule.validUntil || ''];
    this.editExclusionDates = schedule.excludedDays || [];
    this.editScheduleModal = true;
  }

  cancelRemoveSchedule() {
    this.removeScheduleModal = false;
    setTimeout(() => {
      this.removeSchedule = {} as AvailabilitySchedule;
    }, 200);
  }

  openRemoveConfirmationModal(schedule: AvailabilitySchedule) {
    this.removeSchedule = schedule;
    this.removeScheduleModal = true;
  }

  closeCreateModal() {
    this.createScheduleModal = false;
  }

  closeEditModal() {
    this.editScheduleModal = false;
  }

  toggleCalendar() {
    this.displayCalendar = !this.displayCalendar;
  }

  compareTimes(a: AvailabilitySchedule, b: AvailabilitySchedule): number {
    if (!a.startTime || !b.startTime) {
      return 0;
    }

    if (moment(a.startTime).isBefore(b.startTime)) {
      return -1;
    }

    if (moment(a.startTime).isAfter(b.startTime)) {
      return 1;
    }

    return 0;
  }

  initWeeklyAvailSchedule() {
    this.weeklyAvailSchedule = {
      Monday: [] as Array<AvailabilitySchedule>,
      Tuesday: [] as Array<AvailabilitySchedule>,
      Wednesday: [] as Array<AvailabilitySchedule>,
      Thursday: [] as Array<AvailabilitySchedule>,
      Friday: [] as Array<AvailabilitySchedule>,
      Saturday: [] as Array<AvailabilitySchedule>,
      Sunday: [] as Array<AvailabilitySchedule>,
    };
  }

  populateAvailabilityByDay() {
    this.initWeeklyAvailSchedule();
    productModule.AvailabilitySchedules.forEach((availSchedule: AvailabilitySchedule) => {
      if (availSchedule.name && availSchedule.name === 'Monday') {
        this.weeklyAvailSchedule.Monday.push(availSchedule);
        this.weeklyAvailSchedule.Monday.sort((a, b) => this.compareTimes(a, b));
      } else if (availSchedule.name && availSchedule.name === 'Tuesday') {
        this.weeklyAvailSchedule.Tuesday.push(availSchedule);
        this.weeklyAvailSchedule.Tuesday.sort((a, b) => this.compareTimes(a, b));
      } else if (availSchedule.name && availSchedule.name === 'Wednesday') {
        this.weeklyAvailSchedule.Wednesday.push(availSchedule);
        this.weeklyAvailSchedule.Wednesday.sort((a, b) => this.compareTimes(a, b));
      } else if (availSchedule.name && availSchedule.name === 'Thursday') {
        this.weeklyAvailSchedule.Thursday.push(availSchedule);
        this.weeklyAvailSchedule.Thursday.sort((a, b) => this.compareTimes(a, b));
      } else if (availSchedule.name && availSchedule.name === 'Friday') {
        this.weeklyAvailSchedule.Friday.push(availSchedule);
        this.weeklyAvailSchedule.Friday.sort((a, b) => this.compareTimes(a, b));
      } else if (availSchedule.name && availSchedule.name === 'Saturday') {
        this.weeklyAvailSchedule.Saturday.push(availSchedule);
        this.weeklyAvailSchedule.Saturday.sort((a, b) => this.compareTimes(a, b));
      } else if (availSchedule.name && availSchedule.name === 'Sunday') {
        this.weeklyAvailSchedule.Sunday.push(availSchedule);
        this.weeklyAvailSchedule.Sunday.sort((a, b) => this.compareTimes(a, b));
      }
    });
  }

  createInitialDays(): Array<Days> {
    return [
      Days.Sunday,
      Days.Monday,
      Days.Tuesday,
      Days.Wednesday,
      Days.Thursday,
      Days.Friday,
      Days.Saturday,
    ];
  }

  groupSchedules(schedules: Array<AvailabilitySchedule>): Array<AvailabilitySchedule> {
    const groupedSchedules = [] as Array<AvailabilitySchedule>;
    // const groupScheduleMap = {} as any;

    schedules.forEach((schedule) => {
      // const dayStr = schedule.daysOfWeek?.join(', ');
      const existingSchedule = groupedSchedules.find((groupedSchedule) => {
        return _.isEqual(groupedSchedule.daysOfWeek, schedule.daysOfWeek);
      });
      if (existingSchedule) {
        // existingSchedule
        // todo add schedule start, end, capacity to array
      } else {
        // todo add entry to groupedSchedules array
      }
    });

    return groupedSchedules;
  }

  groupSchedulesDictionary(schedules: Array<AvailabilitySchedule>) {
    return _.groupBy<AvailabilitySchedule>(schedules, (s) => s.daysOfWeek);
  }

  get AvailabilitySchedules() {
    return productModule.AvailabilitySchedules.filter((availabilitySchedule) => {
      return availabilitySchedule.productId === this.Product.id;
    });
  }

  get GroupedSchedules() {
    return productModule.GroupedAvailabilitySchedules;
  }

  get ScheduleViews() {
    return productModule.ScheduleViews.filter(
      (scheduleViews) => scheduleViews.productId === this.Product.id,
    );
  }

  get GroupedAvailabilitySchedules(): IRdmWeeklyAvailSchedule {
    return this.weeklyAvailSchedule;
  }

  get IsFreeSale() {
    return this.Product?.availabilityType === AvailabilityType.OpeningHours;
  }

  get SelectedInventoryType() {
    return productModule.SelectedInventoryType;
  }

  get Product() {
    return productModule.Product;
  }

  get EditMode() {
    return productModule.EditModeAvailabilitySchedule;
  }
}
