import { CommonErrHandlers } from '@/api/ErrHandlers';
import { StringI18n } from '@/utils/alert';

// eslint-disable-next-line
export function DefaultAlertErrHandler(customMessage: StringI18n) {
  return new (class implements CommonErrHandlers {
    public onAborted = () => {
      // todo handle abort
    };

    public onNetworkFailed = (e: any) => {
      // todo network error
      e.isHandled = true;
    };

    public on403 = (e: any) => {
      // todo 403 event
      e.isHandled = true;
    };
  })();
}

// eslint-disable-next-line
export function DefaultFloatErrHandler(customMessage: StringI18n, disappearing = true) {
  return new (class implements CommonErrHandlers {
    public onAborted = () => {
      // todo abort event
    };

    public onNetworkFailed = (e: any) => {
      // todo network failed
      e.isHandled = true;
    };

    public on403 = (e: any) => {
      // todo 403 error
      e.isHandled = true;
    };
  })();
}
