
































































import { Component, Vue } from 'vue-property-decorator';
import RateWizard from '../../components/.deprecated/ratewizard/RateWizard.vue';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import { channelModule } from '@/store/modules/moduleChannel';
import {
  tablePaginationOptions,
  nameFormattingForDuplicatedItems,
} from '@/utils/helpers';
import _ from 'lodash';
import { constants } from '@/utils/constants';
import { pricingModule } from '@/store/modules/modulePricing';
import { httpModule } from '@/store/modules/moduleHttp';
import { RateCard } from '@/models';

@Component({
  components: {
    RdmStatusIndicator,
    RateWizard,
  },
})
export default class RateCardListings extends Vue {
  private rateCardDialog = false;
  private routeToRateCardView = constants.routes.CHANNEL_RATE_CARD_VIEW;
  private routeToAddRateCardView = constants.routes.CHANNEL_RATE_CARD_CREATE;
  private notAuthorizedMessage = constants.messages.UNAUTHORIZED_CHANNEL_API;

  private rateCardHeaders: Array<Record<string, any>> = [
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    {
      text: 'Offer(s)',
      align: 'left',
      sortable: true,
      filterable: false,
      value: 'offerNames',
    },
    {
      text: 'Connected Channels',
      align: 'left',
      sortable: true,
      filterable: false,
      value: 'channels',
    },
    { text: '', value: 'action', align: 'right', filterable: false, sortable: false },
  ];

  async mounted() {
    await httpModule.getRateCardsWithOfferDetails();
    if (this.ChannelAccess) await httpModule.getCompanyChannels();
    await httpModule.getRateCardsChannel(this.RateCards);
    channelModule.setCreateRateCardDetailsForm({});
    channelModule.setCreateRateCardAdjustPriceForm({});
    channelModule.setFormPriceAdjustmentsState(false);
    channelModule.setFormConnectChanelState(false);
  }

  displayChannelsName(channels: any) {
    return _.isArray(channels)
      ? channels.map((channel: any) => channel.name).join(', ')
      : '';
  }

  closeModal() {
    this.rateCardDialog = false;
  }

  viewDetails(itm: any) {
    this.$router.push({ name: 'channel-rate-card-details', params: { id: itm.id } });
  }

  duplicate(item: RateCard) {
    channelModule.setCreateRateCardDetailsForm({
      name: nameFormattingForDuplicatedItems(item.name),
      validFrom: item.validFrom,
      validUntil: item.validUntil,
      offers: item.offers,
    });
    if (item.adjustment !== undefined && Object.keys(item.adjustment).length) {
      channelModule.setAdjustPriceState(true);
      const source =
        item && item.adjustment && _.isString(item.adjustment.source)
          ? item.adjustment.source
          : '';
      const destination =
        item && item.adjustment && _.isString(item.adjustment.destination)
          ? item.adjustment.destination
          : '';
      const adjustmentType =
        item && item.adjustment && _.isString(item.adjustment.calculationType)
          ? item.adjustment.calculationType
          : '';
      const adjustmentValue =
        item && item.adjustment && _.isNumber(item.adjustment.value)
          ? item.adjustment.value
          : 0;
      channelModule.setCreateRateCardAdjustPriceForm({
        source: source,
        destination: destination,
        calculationType: adjustmentType,
        value: adjustmentValue,
      });
    }
    this.$router.push({ name: this.routeToAddRateCardView });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filterRateCards(value: string, search: string, item: any) {
    if (value != null && search != null) {
      return value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    return false;
  }

  resetSearch() {
    this.SearchText = '';
  }

  get Loading() {
    return channelModule.Loading;
  }

  get Pricings() {
    return pricingModule.Pricing;
  }

  get RateCards() {
    return channelModule.RateCards;
  }

  get SearchText() {
    return channelModule.SearchText;
  }

  set SearchText(search: string) {
    channelModule.setSearchText(search);
  }

  get paginationOptions() {
    return tablePaginationOptions(this.RateCards);
  }

  get RateRowCount() {
    if (!_.isEmpty(this.SearchText)) {
      return channelModule.RateCards.filter((v) => {
        return !(
          v &&
          v.name &&
          v.name.toLowerCase().indexOf(this.SearchText.toLowerCase()) === -1
        );
      }).length;
    }
    return channelModule.RateCards.length;
  }

  get HideHeader() {
    return this.RateRowCount === 0;
  }

  get HideFooter() {
    return this.RateRowCount === 0;
  }

  get ChannelAccess() {
    return channelModule.ChannelAccess;
  }
}
