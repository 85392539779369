






























































import { Component, Vue } from 'vue-property-decorator';
import { optionModule } from '@/store/modules/moduleOption';
import { productModule } from '@/store/modules/moduleProduct';
import { descriptionLimitRules } from '@/utils/validation-rules';
import { constants } from '@/utils/constants';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';

@Component
export default class ProductViewDescription extends Vue {
  private descriptionLimitRules = descriptionLimitRules;
  private saving = false;
  private descriptionLimit = 1000;
  private descriptionPlaceholder = 'Enter product description';
  private descriptionModel = '';
  private baseDescriptionModel = '';

  mounted() {
    if (this.$refs && this.$refs.productDescForm) {
      const { productDescForm } = this.$refs as any;
      productDescForm.validate();
    }

    this.$root.$on(constants.events.PRODUCT_LOAD_COMPLETE, () => {
      this.descriptionModel = _.cloneDeep(this.Description);
      this.baseDescriptionModel = _.cloneDeep(this.Description);
    });

    this.$root.$on(constants.events.OPTION_LOAD_COMPLETE, () => {
      this.descriptionModel = _.cloneDeep(this.Description);
      this.baseDescriptionModel = _.cloneDeep(this.Description);
    });
  }

  toggleEdit() {
    if (this.Loading) {
      return;
    }

    this.descriptionModel = _.cloneDeep(this.Description || '');

    if (this.EditMode) {
      // productModule.setEditModeDescription(false);
    } else {
      this.baseDescriptionModel = _.cloneDeep(this.Description || '');
      // productModule.setEditModeDescription(true);
    }

    if (this.$props && this.$props.option) {
      if (this.EditMode) {
        optionModule.setEditModeDescription(false);
      } else {
        optionModule.setEditModeDescription(true);
      }
    } else {
      if (this.EditMode) {
        productModule.setEditModeDescription(false);
      } else {
        productModule.setEditModeDescription(true);
      }
    }
  }

  saveChanges() {
    this.saving = true;
    if (this.$props && this.$props.option) {
      const option = _.cloneDeep(optionModule.Option);
      option.description = this.descriptionModel;
      optionModule.setOption(option);
      httpModule
        .updateOption(this.Option)
        .then(() => {
          optionModule.setEditModeDescription(false);
        })
        .catch(() => {
          // todo send alert
        })
        .finally(() => {
          this.saving = false;
          this.descriptionModel = _.cloneDeep(this.Description || '');
          this.baseDescriptionModel = _.cloneDeep(this.Description || '');
        });
    } else {
      const product = _.cloneDeep(productModule.Product);
      product.description = this.descriptionModel;
      productModule.setProduct(product);
      httpModule
        .updateProduct()
        .then(() => {
          productModule.setEditModeDescription(false);
        })
        .catch(() => {
          // todo send alert
        })
        .finally(() => {
          this.saving = false;
          this.descriptionModel = _.cloneDeep(this.Description || '');
          this.baseDescriptionModel = _.cloneDeep(this.Description || '');
        });
    }
  }

  cancel() {
    this.descriptionModel = _.cloneDeep(this.baseDescriptionModel);
    if (this.$props && this.$props.option) {
      optionModule.setEditModeDescription(false);
    } else {
      productModule.setEditModeDescription(false);
    }
  }

  get EditMode() {
    if (this.$props && this.$props.option) {
      return optionModule.EditModeDescription;
    }
    return productModule.EditModeDescription;
  }

  get Description() {
    if (this.$props && this.$props.option) {
      return optionModule.Description;
    }
    return productModule.Description;
  }

  get ProductDescriptionValid() {
    return true;
  }

  set ProductDescriptionValid(valid: boolean) {}

  get Option() {
    return optionModule.Option;
  }

  get Product() {
    return productModule.Product;
  }

  get Loading() {
    return productModule.Loading;
  }
}
