import _ from 'lodash';

// if toLower is true, set not 1st letters in a word to lower case
// e.g. joHNy -> Johny
const capitalizeGeneric = function (value: string, toLower: boolean): string {
  return value && _.isString(value)
    ? value
        .split(' ')
        .map(
          (word) =>
            word.charAt(0).toUpperCase() +
            (toLower ? word.slice(1).toLowerCase() : word.slice(1)),
        )
        .join(' ')
    : '';
};

// 'johnny dePP' -> 'Johnny DePP'
export const capitalize = function (value: string) {
  return capitalizeGeneric(value, false);
};

// 'joHNny DePP' -> 'Johnny Depp'
export const titleize = function (value: string) {
  return capitalizeGeneric(value, true);
};

export const uppercase = function (value: string) {
  return value ? value.toString().toUpperCase() : '';
};

export const lowercase = function (value: string) {
  return value ? value.toString().toLowerCase() : '';
};
