
























































































































































import { Component, Vue } from 'vue-property-decorator';
import { generateUser, knownRoles } from '@/utils/helpers';
import { Account as UserProfile } from '@/api/wps/BackplaneModels';
import { userModule } from '@/store/modules/moduleUser';
import { httpModule } from '@/store/modules/moduleHttp';
import backplaneClient from '@/api/backplane/BackplaneClient';

@Component
export default class BulkActionUserModal extends Vue {
  /* DATA */

  // user to create (ready payload, only org_code is absent)
  private user: UserProfile;

  // stages
  private stageNumber = 1;

  private isValidStage1 = true;

  private isValidStage2 = true;

  private isValidStage3 = true;

  // permissions
  private permissionsArray: Array<any> = [];

  private selectedPermissions: Array<any> = [];

  // other

  private warningDialog = false;

  private loading = false;

  private placeholderData: object = generateUser();

  /* HOOKS */

  private created() {
    backplaneClient.getRoles().subscribe((res) => {
      this.permissionsArray = res.groups
        .filter((group: any) => {
          return !!knownRoles.find((v) => v === group.name);
        })
        .map((group: any) => {
          if (group.name === 'SUPPLIER_BUSINESS_STAFF') {
            this.selectedPermissions.push(group.id);
          }
          return {
            key: group.id,
            label: group.name,
            disabled: group.name === 'SUPPLIER_BUSINESS_STAFF',
          };
        });

      if (this.permissionsArray && Array.isArray(this.permissionsArray)) {
        this.permissionsArray.forEach((p: any, index) => {
          if (p.label === 'SUPPLIER_BUSINESS_STAFF') {
            const viewElement = p;
            this.permissionsArray.splice(index, 1);
            this.permissionsArray.unshift(viewElement);
          }
        });
      }
      this.configureSelectedRole();
    });
  }

  /* METHODS */

  private configureSelectedRole() {
    if (this.selectedUsers && Array.isArray(this.selectedUsers)) {
      this.selectedUsers.forEach((user: any) => {
        if (user && user.groups && Array.isArray(user.groups)) {
          user.groups.forEach((group: any) => {
            if (
              knownRoles.findIndex((g: any) => g === group.name) > -1 &&
              this.selectedPermissions.findIndex((p: any) => p === group.id) === -1
            ) {
              this.selectedPermissions.push(group.id);
            }
          });
        }
      });
    }
  }

  private cancelForm() {
    this.$emit('user-add-cancelled');
  }

  private previousStage() {
    --this.stageNumber;
  }

  private nextStage() {
    ++this.stageNumber;
  }

  private warningProceed() {
    this.warningDialog = false;
    this.nextStage();
  }

  private checkRolesAndNextStage() {
    // Alert if view role not added
    const role = this.permissionsArray.find((p) => {
      return p.label.toUpperCase() === 'SUPPLIER_BUSINESS_STAFF';
    });

    if (
      role &&
      this.selectedPermissions &&
      this.selectedPermissions.findIndex((p) => {
        return p === role.key;
      }) > -1
    ) {
      this.nextStage();
      return;
    }

    this.warningDialog = true;
  }

  private nextOrConfirm() {
    if (this.stageNumber === 1) {
      if (this.$refs && this.$refs.stage1Form) {
        const { stage1Form } = this.$refs as any;
        const valid = stage1Form.validate();
        if (valid) {
          this.nextStage();
        } else {
          // stage1Form
        }
      }
    } else if (this.stageNumber === 2) {
      if (this.$refs && this.$refs.stage2Form) {
        const { stage2Form } = this.$refs as any;
        const valid = stage2Form.validate();
        if (valid) {
          this.nextStage();
        }
      }
    } else if (this.stageNumber === 3) {
      if (this.isValidStage1 && this.isValidStage2) {
        this.saveForm();
      }
    }
  }

  private saveForm() {
    const selectedGroups = this.permissionsArray
      .filter((v) => {
        // prevent removing view
        if (this.bulkAction === 'removeRole' && v.label === 'SUPPLIER_BUSINESS_STAFF') {
          return;
        }
        return !!this.selectedPermissions.find((selected) => selected === v.key);
      })
      .map((v) => ({
        id: v.key,
        name: v.label,
      }));

    this.loading = true;
    httpModule
      .updateSelectedUserGroups(selectedGroups)
      .then(() => {
        const successMessage = {
          type: 'success',
          message:
            this.bulkAction === 'addRole'
              ? 'UserManagementPage.RolesAddedCompleteMessage'
              : 'UserManagementPage.RolesRemoveCompleteMessage',
        };
        this.$emit('user-add-confirmed', successMessage);
      })
      .catch(() => {
        const failureMessage = {
          type: 'error',
          message: 'UserManagementPage.ErrorOccurred',
        };
        this.$emit('user-add-confirmed', failureMessage);
      })
      .finally(() => {
        this.loading = false;
        // this.nextStage();
      });
  }

  private closeModal() {
    this.$emit('user-add-confirmed', {
      type: 'success',
      message:
        this.bulkAction === 'addRole'
          ? 'RolesAddedCompleteMessage'
          : 'RolesRemoveCompleteMessage',
    });
  }

  get selectedUsers() {
    return userModule.SelectedUsers;
  }

  get bulkAction() {
    return userModule.BulkAction;
  }
}
