import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { OrganizationRS, OrganizationWrapper } from '@/api/wps/WpsModels';
import { Address, Company, Contact } from '@/models';

@Module({
  dynamic: true,
  store,
  name: 'rdm-rc-company',
  namespaced: true,
})
class ModuleCompany extends VuexModule {
  /* Data */
  private loading = false;
  private company = {} as Company;
  private organization: OrganizationRS | null = null;
  private addresses = [] as Array<Address>;
  private selectedAddress = {} as Address;
  private contacts = [] as Array<Contact>;
  private searchText = '';
  private locationSearchText = '';
  private contactSearchText = '';
  private editModeCompanyDetails = false;
  private editModeCompanyName = false;
  private editModeCompanyWebsite = false;
  private editModeCompanyAddress = false;
  private editModeCompanyContact = false;
  private editModeContactName = false;
  private editModeContactAddress = false;
  private editModeContactEmail = false;
  private editModeContactPhone = false;
  private editModeContactJobType = false;
  private editModeLocationName = false;
  private editModeLocationPlaceId = false;
  private editModeLocationAddress = false;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get Company(): Company {
    return this.company;
  }

  get Organization(): OrganizationRS | null {
    return this.organization;
  }

  get SearchText() {
    return this.searchText;
  }

  get SearchTextLocation() {
    return this.locationSearchText;
  }

  get SearchTextContact() {
    return this.contactSearchText;
  }

  get Addresses() {
    return this.addresses || ([] as Array<Address>);
  }

  get SelectedAddress() {
    return this.selectedAddress;
  }

  get Contacts() {
    return this.contacts;
  }

  get PrimaryContact() {
    return this.contacts.find((c) => c.primary) || ({} as Contact);
  }

  get EditModeCompanyDetails() {
    return this.editModeCompanyDetails;
  }

  get EditModeCompanyName() {
    return this.editModeCompanyName;
  }

  get EditModeCompanyWebsite() {
    return this.editModeCompanyWebsite;
  }

  get EditModeCompanyAddress() {
    return this.editModeCompanyAddress;
  }

  get EditModeCompanyContact() {
    return this.editModeCompanyContact;
  }

  get EditModeContactName() {
    return this.editModeContactName;
  }

  get EditModeContactAddress() {
    return this.editModeContactAddress;
  }

  get EditModeContactEmail() {
    return this.editModeContactEmail;
  }

  get EditModeContactPhone() {
    return this.editModeContactPhone;
  }

  get EditModeContactJobType() {
    return this.editModeContactJobType;
  }

  get EditModeLocationName() {
    return this.editModeLocationName;
  }

  get EditModeLocationPlaceId() {
    return this.editModeLocationPlaceId;
  }

  get EditModeLocationAddress() {
    return this.editModeLocationAddress;
  }

  // get AddressesPrimary() {
  //   return this.addresses.filter((a) => a.type === AddressType.PRIMARY);
  // }
  //
  // get AddressesDeparture() {
  //   return this.addresses.filter((a) => a.type === AddressType.DEPARTURE);
  // }
  //
  // get AddressesArrival() {
  //   return this.addresses.filter((a) => a.type === AddressType.ARRIVAL);
  // }
  //
  // get AddressesStop() {
  //   return this.addresses.filter((a) => a.type === AddressType.STOP);
  // }
  //
  // get AddressesOther() {
  //   return this.addresses.filter((a) => a.type === AddressType.OTHER);
  // }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setCompany(company: Company) {
    this.company = company;
  }

  @Mutation
  setOrganization(organization: OrganizationRS | null) {
    this.organization = organization;
  }

  @Mutation
  setSearchText(search: string) {
    this.searchText = search;
  }

  @Mutation
  setSearchTextLocation(search: string) {
    this.locationSearchText = search;
  }

  @Mutation
  setSearchTextContact(search: string) {
    this.contactSearchText = search;
  }

  @Mutation
  setAddresses(addresses: Array<Address>) {
    this.addresses = addresses;
  }

  @Mutation
  setSelectedAddress(address: Address) {
    this.selectedAddress = address;
  }

  @Mutation
  setContacts(contacts: Array<Contact> | null) {
    this.contacts = contacts || [];
  }

  @Mutation
  setEditModeCompanyDetails(editMode: boolean) {
    this.editModeCompanyDetails = editMode;
  }

  @Mutation
  setEditModeCompanyName(editMode: boolean) {
    this.editModeCompanyName = editMode;
  }

  @Mutation
  setEditModeCompanyWebsite(editMode: boolean) {
    this.editModeCompanyWebsite = editMode;
  }

  @Mutation
  setEditModeCompanyAddress(editMode: boolean) {
    this.editModeCompanyAddress = editMode;
  }

  @Mutation
  setEditModeCompanyContact(editMode: boolean) {
    this.editModeCompanyContact = editMode;
  }

  @Mutation
  setEditModeContactName(editMode: boolean) {
    this.editModeContactName = editMode;
  }

  @Mutation
  setEditModeContactAddress(editMode: boolean) {
    this.editModeContactAddress = editMode;
  }

  @Mutation
  setEditModeContactEmail(editMode: boolean) {
    this.editModeContactEmail = editMode;
  }

  @Mutation
  setEditModeContactPhone(editMode: boolean) {
    this.editModeContactPhone = editMode;
  }

  @Mutation
  setEditModeContactJobType(editMode: boolean) {
    this.editModeContactJobType = editMode;
  }

  @Mutation
  setEditModeLocationName(editMode: boolean) {
    this.editModeLocationName = editMode;
  }

  @Mutation
  setEditModeLocationAddress(editMode: boolean) {
    this.editModeLocationAddress = editMode;
  }

  @Mutation
  setEditModeLocationPlaceId(editMode: boolean) {
    this.editModeLocationPlaceId = editMode;
  }

  /* Actions */
  @Action
  configCompany(company: Company) {
    this.setCompany(company);
  }
  //
  // @Action
  // configSuppliers(suppliers: Array<SupplierWrapper>) {
  //   this.setSuppliers(suppliers);
  //   if (suppliers && suppliers.length > 0 && suppliers[0].supplier) {
  //     // setting first supplier
  //     const locations = [] as Array<SupplierLocation>;
  //     const supplier = { ...suppliers[0].supplier };
  //     if (supplier.location) locations.push(supplier.location);
  //     if (supplier.otherLocations) locations.push(...supplier.otherLocations);
  //     if (supplier.contact) supplier.contact.role = 'Primary';
  //     if (supplier.otherContacts && Array.isArray(supplier.otherContacts)) {
  //       supplier.otherContacts.forEach((contact) => {
  //         contact.id = faker.datatype.uuid();
  //       });
  //     }
  //     if (this.Organization && this.Organization.id) {
  //       supplier.id = this.Organization.id;
  //     }
  //     this.setSupplier(supplier);
  //     this.setLocations(locations);
  //   }
  // }

  @Action
  configOrganization(organization: OrganizationWrapper) {
    const organizations = organization.organizations;
    if (organizations) {
      this.setOrganization(organizations[0]);
    }
  }

  @Action
  appendContact(contact: Contact) {
    this.contacts.push(contact);
  }

  @Action
  removeContact(contact: Contact) {
    const i = this.contacts.findIndex((c) => c.id === contact.id);
    if (i > -1) {
      this.contacts.splice(i, 1);
    }
  }

  @Action
  appendAddress(address: Address) {
    this.addresses.push(address);
  }

  @Action
  updateAddress(address: Address) {
    const i = this.addresses.findIndex((a) => a.id === address.id);
    if (i > -1) {
      this.addresses[i] = address;
    }
  }

  @Action
  upsertAddress(address: Address) {
    const i = this.addresses.findIndex((a) => a.id === address.id);
    if (i === -1) {
      this.appendAddress(address);
    } else {
      this.addresses[i] = address;
    }
  }

  @Action
  removeAddress(address: Address) {
    const i = this.addresses.findIndex((a) => a.id === address.id);
    if (i > -1) {
      this.addresses.splice(i, 1);
    }
  }

  @Action
  toggleEditModeCompanyDetails() {
    this.setEditModeCompanyDetails(!this.EditModeCompanyDetails);
  }

  @Action
  toggleEditModeCompanyName() {
    this.setEditModeCompanyName(!this.EditModeCompanyName);
  }

  @Action
  toggleEditModeCompanyWebsite() {
    this.setEditModeCompanyWebsite(!this.EditModeCompanyWebsite);
  }

  @Action
  toggleEditModeCompanyAddress() {
    this.setEditModeCompanyAddress(!this.EditModeCompanyAddress);
  }

  @Action
  toggleEditModeCompanyContact() {
    this.setEditModeCompanyContact(!this.EditModeCompanyContact);
  }

  @Action
  toggleEditModeContactName() {
    this.setEditModeContactName(!this.EditModeContactName);
  }

  @Action
  toggleEditModeContactAddress() {
    this.setEditModeContactAddress(!this.EditModeContactAddress);
  }

  @Action
  toggleEditModeContactPhone() {
    this.setEditModeContactPhone(!this.EditModeContactPhone);
  }

  @Action
  toggleEditModeContactEmail() {
    this.setEditModeContactEmail(!this.EditModeContactEmail);
  }

  @Action
  toggleEditModeContactJobType() {
    this.setEditModeContactJobType(!this.EditModeContactJobType);
  }

  @Action
  toggleEditModeLocationName() {
    this.setEditModeLocationName(!this.EditModeLocationName);
  }

  @Action
  toggleEditModeLocationAddress() {
    this.setEditModeLocationAddress(!this.EditModeLocationAddress);
  }

  @Action
  toggleEditModeLocationPlaceId() {
    this.setEditModeLocationPlaceId(!this.EditModeLocationPlaceId);
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setCompany({} as Company);
    this.setLoading(false);
    this.setOrganization(null);
    this.setSearchText('');
    this.setSearchTextLocation('');
    this.setSearchTextContact('');
    this.setAddresses([]);
    this.setSelectedAddress({} as Address);
    this.setEditModeCompanyDetails(false);
    this.setEditModeCompanyName(false);
    this.setEditModeCompanyWebsite(false);
    this.setEditModeCompanyAddress(false);
    this.setEditModeCompanyContact(false);
    this.setEditModeContactName(false);
    this.setEditModeContactAddress(false);
    this.setEditModeContactPhone(false);
    this.setEditModeContactEmail(false);
    this.setEditModeContactJobType(false);
    this.setEditModeLocationName(false);
    this.setEditModeLocationAddress(false);
    this.setEditModeLocationPlaceId(false);
  }
}

export const companyModule = getModule(ModuleCompany, store);
