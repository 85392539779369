import { BarcodeScanner } from 'dynamsoft-javascript-barcode';

BarcodeScanner.license =
  'DLS2eyJoYW5kc2hha2VDb2RlIjoiMzkyMDc5LXIxNjkxMDA1NzkwIiwibWFpblNlcnZlclVSTCI6Imh0dHBzOi8vbWx0cy5keW5hbXNvZnQuY29tLyIsIm9yZ2FuaXphdGlvbklEIjoiMzkyMDc5Iiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2x0cy5keW5hbXNvZnQuY29tLyIsImNoZWNrQ29kZSI6MTUzMDgyOTgwNH0=';
// BarcodeScanner.engineResourcePath =
//   '/node_modules/dynamsoft-javascript-barcode/dist/dbr.js';
BarcodeScanner.engineResourcePath =
  'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.20/dist/';
// Dynamsoft.BarcodeReader.productKeys = '848ac2c6';
// Dynamsoft.BarcodeReader._bUseFullFeature = true; // Control of loading min wasm or full wasm.
export default BarcodeScanner;
