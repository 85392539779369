type Constructor = {
  new (...args: any[]): any;
};

// 'protected' field modifier was used for inheritance, but inheritance doesn't work with shitty 'vuex-module-decorators'
export function ModuleWithLoading<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    /*protected*/ private loading = 0; // loading !== 0 means loading is happening

    get Loading(): boolean {
      return this.loading !== 0;
    }

    /*protected*/ startLoading() {
      ++this.loading;
    }
    /*protected*/ finishLoading() {
      --this.loading;
    }
    /*protected*/ clearLoading() {
      this.loading = 0;
    }

    /*protected*/ async do(func: () => Promise<void>) {
      try {
        this.startLoading();
        await func();
      } finally {
        this.finishLoading();
      }
    }
  };
}

export function ModuleWithDates<TBase extends Constructor>(Base: TBase) {
  class Generated extends Base {
    /*protected*/ private fromDate: moment.Moment | null = null;
    /*protected*/ private untilDate: moment.Moment | null = null;

    get FromDate() {
      return this.fromDate;
    }
    get UntilDate() {
      return this.untilDate;
    }

    /*protected*/ setFromDate(fromDate: moment.Moment | null) {
      this.fromDate = fromDate;
    }
    /*protected*/ setUntilDate(untilDate: moment.Moment | null) {
      this.untilDate = untilDate;
    }

    private static needsUpdate(
      dateNew: moment.Moment | null | undefined,
      dateOld: moment.Moment | null,
    ) {
      return (
        dateNew !== undefined &&
        !(dateNew === null && dateOld === null) &&
        !(dateNew !== null && dateOld !== null && dateOld.isSame(dateNew))
      );
    }

    /*protected*/ datesToChange({ fromDate, untilDate }: DateRange): DateRange {
      fromDate = fromDate && fromDate.startOf('day');
      untilDate = untilDate && untilDate.endOf('day');

      return {
        fromDate: Generated.needsUpdate(fromDate, this.fromDate) ? fromDate : undefined,
        untilDate: Generated.needsUpdate(untilDate, this.untilDate)
          ? untilDate
          : undefined,
      };
    }
  }
  return Generated;
}

// undefined - means do not change this date
// null - means no date binding
export interface DateRange {
  fromDate?: moment.Moment | null;
  untilDate?: moment.Moment | null;
}
