


































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import {
  requiredFieldRules,
  requiredMultiSelectFieldRules,
} from '@/utils/validation-rules';
import _ from 'lodash';
import moment from 'moment/moment';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import faker from 'faker';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { appModule } from '@/store/modules/moduleApp';
import { taxesAndFeesModule } from '@/store/modules/moduleTaxAndFees';
import { companyModule } from '@/store/modules/moduleCompany';
import { httpModule } from '@/store/modules/moduleHttp';
import { Fee } from '@/models';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { centsToDollars, dollarsToCents } from '@/utils/helpers';

@Component({
  components: {
    RdmCollapsableControlCard,
    RdmCollapsableCard,
    RdmReturnToBtn,
  },
  props: {
    isPricing: {
      type: String,
      default: 'false',
    },
  },
})
export default class TaxesAndFeesCreate extends Vue {
  private routerTaxesAndFees = constants.routes.TAXES_AND_FEES;
  private parentName = constants.titles.TAXES_AND_FEES;
  private requiredField = requiredFieldRules;
  private requiredMultipleSelect = requiredMultiSelectFieldRules;
  private saving = false;
  private displayTaxes = false;
  private addTravelerTypeModal = false;
  private taxAndFeeForm = false;
  private effectiveDates: string[] = [];
  private dateMsg = '';
  private name = '';
  private type = 'TAX';
  private rate = 'FLAT';
  private amount = null as null | number;
  private includedInPricing = false;
  private rule = 'INDEPENDENT';
  private application = 'PER_PERSON';
  private isEditForm = false;
  private dateFrom = '';
  private dateUntil: string | undefined = '';
  private dateFromMenu = false;
  private dateUntilMenu = false;

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get AmountPrefix() {
    return this.rate === 'FLAT' ? '' : '%';
  }
  get Currencies() {
    return appModule.SortedCurrencies;
  }
  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  private async mounted() {
    if (this.$route.name === constants.routes.TAXES_AND_FEES_EDIT) {
      await taxesAndFeesModule.selectTaxAndFee(this.$route.params.id);
      this.setFormValues();
    }
  }

  setFormValues() {
    const {
      name,
      amount,
      chargeType,
      ruleType,
      rateType,
      includedInPricing,
      applicationLevel,
      validFrom,
      validUntil,
    } = taxesAndFeesModule.SingleTaxAndFee;
    this.name = name;
    this.type = chargeType;
    this.rate = rateType;
    this.amount = centsToDollars(amount);
    this.includedInPricing = includedInPricing;
    this.rule = ruleType;
    this.application = applicationLevel;
    this.dateFrom = validFrom;
    this.dateUntil = validUntil;
    this.isEditForm = true;
  }

  addTravelerType() {
    this.addTravelerTypeModal = true;
  }

  formattedCurrencies() {
    return this.Currencies.map((item) => {
      return { ...item, itemText: `${item.description} (${item.value})` };
    });
  }

  handleCloseTravelerType() {
    this.addTravelerTypeModal = false;
  }

  discardChanges() {
    this.$router.push({ name: this.routerTaxesAndFees }).catch(() => {});
  }

  changeDate() {
    this.dateMsg = '';
    if (this.effectiveDates && _.isArray(this.effectiveDates)) {
      if (this.effectiveDates.length === 1) {
        this.dateMsg = `${moment(this.effectiveDates[0]).format('MM/DD/YYYY')}`;
      } else if (this.effectiveDates.length === 2) {
        const startDt = moment(this.effectiveDates[0]).format('MM/DD/YYYY');
        const endDt = moment(this.effectiveDates[1]).format('MM/DD/YYYY');
        this.dateMsg = `${startDt} to ${endDt}`;
      } else {
        this.dateMsg = 'No date range selected!';
      }
    }
  }

  navigateToTaxesList() {
    const params = this.$route.query;
    if (
      params &&
      params['backTo'] &&
      !_.isEmpty(params['backTo']) &&
      _.isString(params['backTo'])
    ) {
      this.$router.push({ name: params['backTo'] }).catch(() => {});
    } else if (this.$props.isPricing === 'true') {
      this.$router.push({ name: constants.routes.PRICING_CREATE }).catch(() => {});
    } else {
      this.$router.push({ name: constants.routes.TAXES_AND_FEES }).catch(() => {});
    }
  }

  saveValidDates() {
    this.sortValidDates();
    const validDateRef = this.$refs.validDates as any;
    if (!_.isUndefined(validDateRef)) {
      validDateRef.save(this.effectiveDates);
    }
  }

  sortValidDates() {
    this.effectiveDates.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });
  }

  savePricing() {
    if (!this.form.validate()) return false;
    const taxAndFee = this.isEditForm
      ? { ...taxesAndFeesModule.SingleTaxAndFee }
      : ({} as Fee);
    _.set(
      taxAndFee,
      ['id'],
      this.isEditForm ? taxesAndFeesModule.SingleTaxAndFee.id : faker.datatype.uuid(),
    );
    // todo add validation to object
    _.set(taxAndFee, ['supplierId'], companyModule.Company?.orgId || '');
    _.set(taxAndFee, ['productId'], '');
    _.set(taxAndFee, ['optionId'], '');
    _.set(taxAndFee, ['name'], this.name);
    _.set(taxAndFee, ['description'], '');
    _.set(taxAndFee, ['chargeType'], this.type);
    _.set(taxAndFee, ['rateType'], this.rate);
    _.set(
      taxAndFee,
      ['amount'],
      this.rate === 'FLAT' ? dollarsToCents(Number(this.amount)) : Number(this.amount),
    );
    _.set(taxAndFee, ['ruleType'], this.rule);
    _.set(taxAndFee, ['applicationLevel'], this.application);
    _.set(taxAndFee, ['includedInPricing'], this.includedInPricing);
    const startDate = moment().format('YYYY-MM-DD');
    _.set(taxAndFee, ['validFrom'], startDate);
    _.set(taxAndFee, ['validUntil'], null);
    _.set(taxAndFee, ['applicationOrder'], 1);
    this.saving = true;
    if (this.isEditForm) {
      httpModule
        .updateTaxesAndFees(taxAndFee as Fee)
        .then(() => this.navigateToTaxesList())
        .catch(() => {
          //TODO: handle error
        })
        .finally(() => (this.saving = false));
    } else {
      httpModule
        .createTaxesAndFees(taxAndFee as Fee)
        .then(() => this.navigateToTaxesList())
        .catch(() => {
          //TODO: handle error
        })
        .finally(() => (this.saving = false));
    }
  }

  get BackToRoute() {
    const params = this.$route.query;
    if (
      params &&
      params['backTo'] &&
      !_.isEmpty(params['backTo']) &&
      _.isString(params['backTo'])
    ) {
      return _.toString(params['backTo']);
    }
    return this.routerTaxesAndFees;
  }

  get BackToRouteName() {
    const params = this.$route.query;
    if (
      params &&
      params['backTo'] &&
      !_.isEmpty(params['backTo']) &&
      _.isString(params['backTo']) &&
      params['backTo'] === constants.routes.OFFER_CREATE
    ) {
      return constants.titles.OFFER_CREATE;
    }
    return this.parentName;
  }
}
