





























































import { Component, Prop, Vue } from 'vue-property-decorator';

import RequestConnectionWizardStage1 from './RequestConnectionWizardStage1.vue';
import RequestConnectionWizardStage2 from './RequestConnectionWizardStage2.vue';
import RequestConnectionWizardStage3 from './RequestConnectionWizardStage3.vue';
import RequestConnectionWizardStage4 from './RequestConnectionWizardStage4.vue';
import RequestConnectionWizardStage5 from './RequestConnectionWizardStage5.vue';
import RequestConnectionWizardStage6 from './RequestConnectionWizardStage6.vue';

@Component({
  components: {
    'request-connection-wizard-stage1': RequestConnectionWizardStage1,
    'request-connection-wizard-stage2': RequestConnectionWizardStage2,
    'request-connection-wizard-stage3': RequestConnectionWizardStage3,
    'request-connection-wizard-stage4': RequestConnectionWizardStage4,
    'request-connection-wizard-stage5': RequestConnectionWizardStage5,
    'request-connection-wizard-stage6': RequestConnectionWizardStage6,
  },
})
export default class ConnectionRequestWizard extends Vue {
  private stepperModel = 0;

  private addProductModel = false;

  @Prop()
  private channel!: any;

  @Prop()
  private inputRef: any;

  mounted() {}

  checkConfirmation(result: boolean) {
    if (result) {
      this.stepperModel = 3;
    } else {
      this.stepperModel = 2;
    }
  }

  incrementStepper() {
    this.stepperModel++;
  }

  submitRequest() {
    this.closeModal();
    this.stepperModel = 1;
  }

  resetModal() {
    this.stepperModel = 1;
  }

  closeModal() {
    this.resetModal();
    this.$emit('close-modal', true);
  }
}
