













































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { constants } from '@/utils/constants';
import { OppPricingDisplay } from '@/api/inventory/InventoryModels';
import { requiredFieldRules, requiredNumberFieldRules } from '@/utils/validation-rules';
import { channelModule } from '@/store/modules/moduleChannel';
import { pricingModule } from '@/store/modules/modulePricing';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { appModule } from '@/store/modules/moduleApp';
import { Channel } from '@/models/ChannelModels';
import { Address } from '@/models/ModelsOppInventory';

@Component({
  components: {
    RdmReturnToBtn,
  },
})
export default class RateCardEdit extends Vue {
  private address = {} as Address;
  private formValid = false;
  private confirmModal = false;
  private disconnectValid = false;
  private selectedPrices = [] as Array<OppPricingDisplay>;
  private availablePrices = [] as Array<OppPricingDisplay>;
  private appliedPrices = [] as Array<OppPricingDisplay>;
  private selectedChannel = {} as Channel;
  private discountValue = 'none';
  private routeToRateCards = constants.routes.CHANNEL_RATE_CARDS;
  private requiredField = requiredFieldRules;
  private requiredNumberFieldRules = requiredNumberFieldRules;
  private pricingHeaders: Array<Record<string, any>> = [
    {
      text: 'Price',
      value: 'displayName',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    {
      text: 'Currency',
      value: 'currency',
      sortable: true,
      filterable: false,
      align: 'left',
    },
    {
      text: 'Effective Dates',
      value: 'displayDateRange',
      sortable: true,
      filterable: false,
      align: 'left',
    },
    { text: '', value: 'action', align: 'right', sortable: false, filterable: false },
  ];
  private channelHeaders: Array<Record<string, any>> = [
    {
      text: 'Channels',
      value: 'channelName',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    {
      text: 'Account Manager',
      value: 'accountManager',
      sortable: true,
      filterable: false,
      align: 'left',
    },
    { text: '', value: 'action', align: 'right', sortable: false, filterable: false },
  ];

  mounted() {
    this.availablePrices.push(...this.DisplayPrices);
  }

  applyPrices() {
    if (this.selectedPrices && Array.isArray(this.selectedPrices)) {
      this.selectedPrices.forEach((price) => {
        this.appliedPrices.push(price);
        const i = this.availablePrices.findIndex((p) => p.id === price.id);
        this.availablePrices.splice(i, 1);
      });
    }
    this.selectedPrices = [];
  }

  removeAppliedPrice(price: OppPricingDisplay) {
    this.availablePrices.push(price);
    const i = this.appliedPrices.findIndex((p) => p.id === price.id);
    this.appliedPrices.splice(i, 1);
  }

  closeDisconnectModal() {
    this.confirmModal = false;
    this.disconnectValid = false;
  }

  disconnectModal(channel: Channel) {
    this.selectedChannel = channel;
    this.confirmModal = true;
  }

  discard() {
    this.$router.push({ name: constants.routes.LOCATIONS }).catch(() => {});
  }

  get DisconnectMessage() {
    return `Are you sure you want to disconnect ${
      this.selectedChannel.rateCard?.name || ''
    } from ${
      this.selectedChannel.channelName || 'this channel'
    }? The pricing for this rate card will no longer be offered to the channel.`;
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Loading() {
    return pricingModule.Loading;
  }

  get ConnectedChannels() {
    return channelModule.ConnectedChannels;
  }

  get Prices() {
    return pricingModule.Pricing;
  }

  get DisplayPrices() {
    return pricingModule.PricingDisplay;
  }

  get Products() {
    return productModule.Products;
  }

  get Options() {
    return optionModule.Options;
  }

  get Currencies() {
    return appModule.Codes.currency || [];
  }

  get SearchText() {
    return channelModule.RateSearchText;
  }

  set SearchText(search: string) {
    channelModule.setRateSearchText(search);
  }
}
