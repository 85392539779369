














































































import { Component, Vue } from 'vue-property-decorator';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { Unit } from '@/api/inventory/InventoryModels';
import _ from 'lodash';
import { requiredFieldRules } from '@/utils/validation-rules';
import { httpModule } from '@/store/modules/moduleHttp';

@Component
export default class TravelerTypeDetails extends Vue {
  private travelerType = {} as Unit;
  private baseTravelerType = {} as Unit;
  private requiredFieldRules = requiredFieldRules;

  mounted() {
    this.travelerType = _.cloneDeep(this.TravelerType);
    this.baseTravelerType = _.cloneDeep(this.TravelerType);
  }

  toggleEdit() {
    if (this.Loading) {
      return;
    }

    travelerTypeModule.setEditModeDetails(!this.EditMode);
  }

  save() {
    this.baseTravelerType = _.cloneDeep(this.travelerType);
    httpModule.updateTravelerTypes(this.travelerType).then(() => {
      this.toggleEdit();
    });
  }

  cancel() {
    this.travelerType = _.cloneDeep(this.baseTravelerType);
    this.toggleEdit();
  }

  get Loading() {
    return travelerTypeModule.Loading;
  }

  get EditMode() {
    return travelerTypeModule.EditModeDetails;
  }

  get TravelerTypeDetailValid() {
    return travelerTypeModule.TravelerTypeDetailValid;
  }

  set TravelerTypeDetailValid(valid: boolean) {
    travelerTypeModule.setTravelerTypeDetailValid(valid);
  }

  get TravelerType() {
    return travelerTypeModule.SelectedTravelerType;
  }
}
