
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class WarnModal extends Vue {
  @Prop({ type: String, default: 'Remove' })
  public questionHeader!: string;

  @Prop({ type: String, default: 'Are you sure you want to?' })
  public message!: string;

  private dialog = false;

  cancelAction() {
    this.dialog = false;
  }

  acceptAction(accept: boolean) {
    this.$emit('acceptance', accept);
    this.dialog = false;
  }
}
