











































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import { format, parseISO } from 'date-fns';
import { requiredNumberFieldRules } from '@/utils/validation-rules';
import { AvailabilityItem, InventoryType } from '@/models';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';

@Component
export default class AvailabilityStatusDayInventoryCard extends Vue {
  @Prop()
  private avail: AvailabilityItem;

  private requiredNumberFieldRules = requiredNumberFieldRules;
  private availItem: AvailabilityItem;
  private saving = false;
  private newVacancies = 0;
  private isLoading = false;
  private isValidForm = false;

  mounted() {
    this.newVacancies = this.avail.vacancies || 0;
    this.availItem = _.cloneDeep(this.avail);
  }

  toggleEdit() {
    if (this.avail.isFreesale || this.avail.isBlackedOut) return;
    availabilityStatusModule.changeEditModeState(!this.EditMode);
  }

  discard() {
    availabilityStatusModule.changeEditModeState(false);
  }

  async save() {
    this.saving = true;
    await httpModule
      .updateAvailabilityVacanies({ avail: this.avail, vacancies: this.newVacancies })
      .finally(() => {
        this.saving = false;
        this.toggleEdit();
      });
  }

  closeSidebar() {
    availabilityStatusModule.toggleShowSidebar();
  }

  formatDate(d: string) {
    return format(parseISO(d), 'hh:mm a');
  }

  async toggleBlackout() {
    this.isLoading = true;
    const status = this.avail.isBlackedOut
      ? InventoryType.CLOSED
      : InventoryType.AVAILABLE;
    await httpModule
      .updateAvailabilityStatus({ avail: this.avail, status })
      .finally(() => (this.isLoading = false));
  }

  getTravelerTypeName(id: string): string {
    const traveler = travelerTypeModule.TravelerTypes.find((t) => t.id === id);
    if (traveler && traveler.internalName) {
      return traveler.internalName;
    }
    return '';
  }

  get EditMode() {
    return availabilityStatusModule.EditMode;
  }

  get Loading(): boolean {
    return availabilityStatusModule.Loading;
  }
}
