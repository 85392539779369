
















































































































import { Component, Vue } from 'vue-property-decorator';
import DeleteUserModal from '@/components/user/DeleteUserModal.vue';
import { knownRoles } from '@/utils/helpers';
import _ from 'lodash';
import { Account as UserProfile, Group } from '@/api/wps/BackplaneModels';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import RdmEditNavBtn from '@/components/common/button/RdmEditNavBtn.vue';
import { userModule } from '@/store/modules/moduleUser';
import { profileModule } from '@/store/modules/moduleProfile';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import { Address } from '@/models';
import backplaneClient from '@/api/backplane/BackplaneClient';

@Component({
  components: {
    DeleteUserModal,
    RdmEditNavBtn,
    RdmReturnToBtn,
  },
})
export default class UserDetailView extends Vue {
  /* DATA */

  // modules
  private parentName = constants.titles.USERS;

  // dialog appearance
  private deleteDialog = false;

  private warningDialog = false;

  // user to edit
  private user: UserProfile = {};

  private fName = '';

  private lName = '';

  // permissions
  private permissionsArray: Array<any> = [];

  private selectedPermissions: Array<any> = [];

  private managedLocations: Array<Address> = [];

  private routerUserList = constants.routes.USERS;
  private routerUserDetails = constants.routes.USER_DETAILS;

  // other

  private snackbar = false;

  /* COMPUTED */

  get Loading(): boolean {
    return userModule.Loading;
  }

  get LoadingOperatorUser(): boolean {
    return userModule.LoadingOperatorUser;
  }

  get isOrgAdmin() {
    return profileModule.IsOrgAdmin;
  }

  get isAdmin() {
    return profileModule.IsAdmin;
  }

  get currentUser() {
    return profileModule.Profile;
  }

  get Locations() {
    return companyModule.Addresses;
  }

  /* HOOKS */

  private mounted() {
    userModule.setOperatorUser(null);
    httpModule.getUsers().then(() => {
      if (!this.$route.params.id) return;
      userModule.chooseSelectedUserToUpdate(this.$route.params.id);
      this.user = _.cloneDeep(userModule.UserToEdit);

      if (this.user && this.user.given_name) {
        this.fName = this.user.given_name.valueOf();
      }

      if (this.user && this.user.surname) {
        this.lName = this.user.surname.valueOf();
      }

      if (this.isOrgAdmin) {
        backplaneClient.getRoles().subscribe(
          (res) => {
            this.permissionsArray = res.groups
              .filter((group: any) => {
                return !!knownRoles.find((v) => v === group.name);
              })
              .map((group: any) => {
                if (group.name === 'SUPPLIER_BUSINESS_STAFF') {
                  this.selectedPermissions.push(group.id);
                }
                return {
                  key: group.id,
                  label: group.name,
                  disabled: group.name === 'SUPPLIER_BUSINESS_STAFF',
                };
              });
          },
          () => {},
        );
      }

      if (this.user && this.user.groups && Array.isArray(this.user.groups)) {
        this.selectedPermissions = this.user.groups.map((group) => group.id);
      }
      httpModule.loadOperatorUser(this.$route.params.id).then(() => {
        const operatorUser = _.cloneDeep(userModule.OperatorUser);
        if (
          operatorUser &&
          operatorUser.config &&
          Array.isArray(operatorUser.config.managedLocation)
        ) {
          operatorUser.config.managedLocation.forEach((l: string) => {
            const location = this.Locations.find((location) => location.id === l);
            if (location) {
              this.managedLocations.push(location);
            }
          });
        }
      });
    });
  }

  /* METHODS */

  private editUser() {
    this.$router.push({ name: 'user-details', params: { id: this.user.id as string } });
  }

  private checkRoles() {
    // Alert if view role not added
    const role = this.permissionsArray.find((p) => {
      return p.label.toUpperCase() === 'SUPPLIER_BUSINESS_STAFF';
    });

    if (
      role &&
      this.selectedPermissions &&
      this.selectedPermissions.findIndex((p) => {
        return p === role.key;
      }) > -1
    ) {
      return;
    }

    this.warningDialog = true;
  }

  private closeAndSave() {
    this.warningDialog = false;
  }

  private getExistingRoles(): Array<Group> {
    if (this.user && this.user.groups && Array.isArray(this.user.groups)) {
      return this.user.groups.filter((role: any) => {
        return knownRoles.findIndex((knownRole: any) => knownRole === role.name) === -1;
      });
    }
    return [];
  }

  async toUserManagement() {
    userModule.chooseUserToUpdate(-1);
    await httpModule.getUsers();
    // await this.$router.push({ name: 'users' });
  }
}
