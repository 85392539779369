








































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import RateCardCreateOfferModal from '@/components/rateCard/create/RateCardCreateOfferModal.vue';
import { channelModule } from '@/store/modules/moduleChannel';
import { tablePaginationOptions } from '@/utils/helpers';
import { pricingModule } from '@/store/modules/modulePricing';
import { httpModule } from '@/store/modules/moduleHttp';
import { Address, Offer } from '@/models';
import { productModule } from '@/store/modules/moduleProduct';
import { companyModule } from '@/store/modules/moduleCompany';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { offerModule } from '@/store/modules/moduleOffer';
import { constants } from '@/utils/constants';

@Component({
  components: {
    RdmCollapsableControlCard,
    RateCardCreateOfferModal,
  },
})
export default class RateCardCreateSelectOffers extends Vue {
  private selectAll = false;
  private name = '';
  private modalIsOpen = false;
  private adjustPrice = false;
  private selectedOffers = [] as any;
  private offerId = '';
  private offerSearch = '';
  private offersNotSelected = false;
  private rateCardOffersHeaders: Array<Record<string, any>> = [
    { text: 'Offer', align: 'left', sortable: true, value: 'name' },
  ];
  private offerCapacitiesHeaders: Array<Record<string, any>> = [
    { text: 'Pricing Sets', align: 'left', sortable: true, value: 'name' },
    { text: 'Price Types', align: 'left', sortable: true, value: 'capacityLimit' },
    { text: 'Effective Dates', align: 'left', sortable: true, value: 'validFrom' },
  ];

  async mounted() {
    await httpModule.getOffers();
    this.adjustPrice = channelModule.AdjustPriceState;
  }

  destroyed() {
    channelModule.clearState();
    this.selectedOffers = [];
    channelModule.setSelectOffersState(true);
  }

  discard() {
    this.$router.push({ name: constants.routes.CHANNEL_RATE_CARDS });
  }

  toggleSelectAll() {
    if (this.selectAll) this.selectedOffers = this.Offers;
    else this.selectedOffers = [];
  }

  async toggleOffer(id: string) {
    const updatedOffers = this.Offers.map((offer: Offer) => {
      if (id === offer.id)
        return {
          ...offer,
          open: !offer.open,
        };
      return {
        ...offer,
        open: false,
      };
    });
    await httpModule.getOffer({ offerId: id, withCapacity: true });
    if (this.Offer.capacity?.length) {
      await httpModule.getOfferCapacity(this.Offer.capacity);
    }
    pricingModule.setOffers(updatedOffers);
  }

  getPrimaryAddress(id: string): string {
    const p = this.Products.find((p: any) => p.id === id);
    if (p) {
      const address = this.CompanyAddresses.find((a: Address) => a.id === p.addressId);
      if (address) {
        return `${address.name}`;
      }
    }

    return '';
  }

  unitIdToTravelerName(id: string) {
    const i = this.DisplayTravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.DisplayTravelerTypes[i].text;
    }
    return '';
  }

  get RateCard() {
    return channelModule.RateCard;
  }

  get DetailsForm(): any {
    if (channelModule.CreateRateCardDetailsForm) {
      return channelModule.CreateRateCardDetailsForm;
    }
    return {};
  }

  get DisplayTravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get CreateRateCardFormState() {
    return channelModule.CreateRateCardFormState;
  }

  set CreateRateCardFormState(state: boolean) {
    channelModule.setCreateRateCardFormState(state);
  }

  get paginationOptions() {
    return tablePaginationOptions(this.Offers, 5);
  }

  get Offers() {
    return pricingModule.Offers;
  }

  get Offer() {
    return pricingModule.Offer;
  }

  get OfferCapacityList() {
    return pricingModule.OfferCapacityList;
  }

  get Products() {
    return productModule.Products;
  }

  get CompanyAddresses() {
    return companyModule.Addresses;
  }

  get SelectOfferState() {
    return channelModule.SelectOffersState;
  }

  get ValidationProgress() {
    return channelModule.ValidationProgress;
  }

  async validate() {
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      this.offersNotSelected = this.selectedOffers.length === 0;
      if (isValid && !this.offersNotSelected) {
        const offers = [...this.selectedOffers.map((item: any) => item.id)];
        this.DetailsForm.offers = offers;
        channelModule.setValidationProgress(0);
        pricingModule.setValidatedOffers([]);
        channelModule.setValidationError(false);
        channelModule.setValidationState(true);
        channelModule.setValidationInterval(setInterval(this.incrementProgress, 500));
        channelModule.setSelectOffersState(false);
        this.selectedOffers = [];
        this.selectAll = false;
        offerModule.setOffersToPublish(offers);
        // await httpModule.validateOffer(offers);
        await httpModule.offerValidation({
          dryRun: 'true',
          offers: offers,
        });
      }
    }
  }
  incrementProgress() {
    if (this.ValidationProgress < 75)
      channelModule.setValidationProgress(this.ValidationProgress + 25);
    if (this.ValidationProgress >= 75 && this.ValidationProgress < 99)
      channelModule.setValidationProgress(this.ValidationProgress + 1);
  }

  closeModal() {
    this.modalIsOpen = false;
  }

  openModal(id: string) {
    this.offerId = id;
    this.modalIsOpen = true;
  }

  toggleSelectOffersState() {
    channelModule.setSelectOffersState(!this.SelectOfferState);
  }
}
