










































































import { Component, Vue } from 'vue-property-decorator';
import RateWizard from '@/components/.deprecated/ratewizard/RateWizard.vue';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import { channelModule } from '@/store/modules/moduleChannel';
import { tablePaginationOptions } from '@/utils/helpers';

@Component({
  components: {
    RdmStatusIndicator,
    RateWizard,
  },
})
export default class RateCardListings extends Vue {
  private isValid = false;
  private windowWidth = 0;

  private action: any = function () {};

  private commission = 0;

  private productHeaders: Array<Record<string, any>> = [
    { text: 'Option', align: 'left', sortable: true, value: 'name' },
    { text: '', value: 'action', align: 'right', sortable: false },
  ];

  private channels = [] as Array<string>;

  private products: Array<any> = [];

  mounted() {
    // this.commission = this.RateCard?.commission || 0;
  }

  paginationOptions(items: any[]) {
    return tablePaginationOptions(items);
  }
  viewDetails(itm: any) {
    this.$router
      .push({ name: 'channel-rate-card-pricing', params: { id: itm.name } })
      .catch(() => {});
  }

  updateRate() {
    // if (this.$refs && this.$refs.editRateForm) {
    //   const { editRateForm } = this.$refs as any;
    //   const valid = editRateForm.validate();
    //   if (!valid) {
    //     return;
    //   }
    //   const rate = _.cloneDeep(this.RateCard);
    //   if (rate) {
    //     rate.commission = _.toNumber(this.commission);
    //     channelModule.updateRateCard(rate);
    //     this.$router.push({ name: constants.routes.CHANNEL_RATE_CARDS });
    //   }
    // }
  }

  get SelectedRateCardId() {
    return this.$route?.params?.id;
  }

  get RateCard() {
    return channelModule.RateCards.find(
      (rateCard: any) => rateCard.id === this.SelectedRateCardId,
    );
  }

  get Channels() {
    return [
      'Country Info Experts',
      'Ticketmate',
      'Tomorrows Voyage',
      'Fun Tours for All',
      'AttractionsHouse',
    ].sort();
  }
}
