



















import { Component, Vue } from 'vue-property-decorator';
import RdmMessage from './RdmMessage.vue';

@Component({
  components: {
    RdmMessage,
  },
  props: {
    type: {
      type: String,
      default: 'info',
      validator(value) {
        return ['info', 'warning', 'success', 'error'].indexOf(value) !== -1;
      },
    },
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: false,
    },
  },
})
export default class RdmGenericMessageModal extends Vue {
  cancel() {
    this.$emit('cancel');
  }

  proceed() {
    this.$emit('proceed');
  }
}
