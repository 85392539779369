import axios, { AxiosInstance } from 'axios';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AxiosErrorWithResponse, intercept } from '@/api/AxiosInterceptors';
import { CommonErrHandlers, handleErrs, OppApiError } from '@/api/ErrHandlers';
import { config } from '@/utils/config';
import { BookingsReportFilter } from '@/models/Report';

export class ReportsClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.ReportingApiUrl,
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);

    this.httpClient.interceptors.response.use(
      intercept.Response,
      intercept.Error(ReportsClient.ErrorResponse),
    );
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return ReportsClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data as OppApiError;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  /* configure client */

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    ReportsClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  public search(
    filters: BookingsReportFilter,
    errs?: CommonErrHandlers,
  ): Observable<any> {
    return from(this.httpClient.get<any>('/search', { params: filters })).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
}

const reportsClient = new ReportsClient();
export default reportsClient;
