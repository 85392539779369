

















































































import { Component, Vue } from 'vue-property-decorator';
import { userLoginRules } from '@/utils/validation-rules';
import { userModule } from '@/store/modules/moduleUser';
import _ from 'lodash';
import { constants } from '@/utils/constants';
import backplaneClient from '@/api/backplane/BackplaneClient';

@Component
export default class ForgotPassword extends Vue {
  private email = '';

  private emailCopy = '';

  private validReset = false;

  private loading = false;

  private emailRules = userLoginRules;

  private errorAlert = false;

  private successAlert = false;

  mounted() {
    this.email = _.cloneDeep(userModule.ForgotEmail);
  }

  backToLogin() {
    userModule.clearForgotEmail();
    this.$router.push({ name: constants.routes.LOGIN }).catch();
  }

  private async sendResetLink() {
    this.errorAlert = false;
    this.successAlert = false;
    this.emailCopy = this.email.valueOf();
    this.loading = true;
    try {
      await backplaneClient.forgotPassword({ email: this.email }).toPromise();
      this.successAlert = true;
    } catch {
      this.errorAlert = true;
    } finally {
      this.loading = false;
      this.email = '';
    }
  }

  private resetAlert() {
    if (this.successAlert) this.successAlert = false;
    if (this.errorAlert) this.errorAlert = false;
  }

  private get alertType() {
    return this.errorAlert ? 'error' : 'success';
  }
}
