var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row'),_c('v-card',{attrs:{"outlined":"","flat":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 margin-bottom-16",attrs:{"headers":_vm.taxAndFeesHeaders,"items":_vm.Taxes,"items-per-page":10,"sort-by":['name'],"hide-default-footer":"","footer-props":{
            itemsPerPageOptions: [10, 25, 50, -1],
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
          }},scopedSlots:_vm._u([{key:"item.chargeType",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("lowercase")(item.chargeType)))+" ")]}},{key:"item.rateType",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.rateType === 'FLAT' ? ("" + (_vm.centsToDollars(item.amount))) : item.amount + '%')+" ")]}},{key:"item.calcText",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("lowercase")(item.calcText.replace('|', ', ').replaceAll('_', ' '))))+" ")]}},{key:"item.validFrom",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.validFrom ? item.validFrom : '-')+" - "+_vm._s(item.validUntil ? item.validUntil : 'Ongoing')+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-left"},[_vm._v(" You don’t have any taxes applied for this pricing set. Click "),_c('span',{staticClass:"click-here",on:{"click":_vm.openModal}},[_vm._v(" here ")]),_vm._v(" to add one. ")])]},proxy:true}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"1048px"},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 margin-bottom-16",attrs:{"headers":_vm.taxAndFeesHeaders,"items":_vm.TaxesAndFees,"items-per-page":10,"sort-by":['name'],"hide-default-footer":"","show-select":true,"footer-props":{
                      itemsPerPageOptions: [10, 25, 50, -1],
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                    }},scopedSlots:_vm._u([{key:"item.chargeType",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("lowercase")(item.chargeType)))+" ")]}},{key:"item.rateType",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.rateType === 'FLAT' ? ("" + (_vm.centsToDollars(item.amount))) : item.amount + '%')+" ")]}},{key:"item.calcText",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("lowercase")(item.calcText.replace('|', ', ').replaceAll('_', ' '))))+" ")]}},{key:"item.effectiveDateRange",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.validFrom ? item.validFrom : '-')+" - "+_vm._s(item.validUntil ? item.validUntil : 'Ongoing')+" ")]}},{key:"item.view",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
                          name: '',
                          params: {
                            id: item.id,
                            isPricing: 'true',
                          },
                          query: {
                            backTo: '',
                          },
                        },"color":"primary","plain":"","disabled":!item.id}},[_vm._v(" Edit ")])]}}]),model:{value:(_vm.selectedTnF),callback:function ($$v) {_vm.selectedTnF=$$v},expression:"selectedTnF"}})],1)],1),_c('v-btn',{staticClass:"remove-padding mt-6",attrs:{"color":"primary","depressed":"","disabled":_vm.selectedTnF.length === 0},on:{"click":_vm.applyTaxesAndFees}},[_vm._v(" Apply ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-dark"},[_vm._v("Applied Taxes & Fees")]),(_vm.AppliedTnF.length === 0)?_c('div',[_vm._v("Not applied")]):_vm._e(),(_vm.AppliedTnF.length)?_c('div',[_c('div',[_vm._v(" Move taxes and fees up and down to adjust the ascending order that they are applied to pricing. ")]),_vm._l((_vm.AppliedTnF),function(item,index){return _c('v-card',{key:((item.id) + "-" + index),staticClass:"appliedTax",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"align-self-center"},[_c('div',[_c('span',[_vm._v(_vm._s(item.name)+": ")]),_c('span',[_vm._v(_vm._s(item.amount))]),_c('span',[_vm._v(_vm._s(item.rateType === 'FLAT' ? ' USD' : '%')+" ")])])]),_c('v-col',[_c('div',[_c('v-btn',{attrs:{"to":{
                            name: '',
                            params: {
                              id: item.id,
                              isPricing: 'true',
                            },
                            query: {
                              backTo: '',
                            },
                          },"color":"primary","plain":"","disabled":!item.id}},[_vm._v(" Edit ")]),(!_vm.IndependentOnlyApplied)?_c('v-btn',{attrs:{"color":"primary","plain":"","disabled":index === 0},on:{"click":function($event){return _vm.moveAppliedUp(index)}}},[_vm._v(" Move Up ")]):_vm._e(),(!_vm.IndependentOnlyApplied)?_c('v-btn',{attrs:{"color":"primary","plain":"","disabled":index === _vm.AppliedTnF.length - 1},on:{"click":function($event){return _vm.moveAppliedDown(index)}}},[_vm._v(" Move Down ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","plain":"","disabled":!item.id},on:{"click":function($event){return _vm.removeAppliedTax(item)}}},[_vm._v(" Remove ")])],1)])],1)],1)})],2):_vm._e()]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","outlined":""},on:{"click":_vm.closeModal}},[_vm._v("Discard")]),_c('v-btn',{attrs:{"disabled":_vm.AppliedTnF.length === 0,"loading":_vm.Loading,"color":"primary","depressed":""},on:{"click":_vm.addTaxesToPricing}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }