


























import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmDashboardCard from '@/components/common/card/RdmDashboardCard.vue';
import { profileModule } from '@/store/modules/moduleProfile';
import _ from 'lodash';

@Component({
  components: {
    RdmDashboardCard,
  },
})
export default class Home extends Vue {
  private authorizedCards = [] as Array<any>;
  private cards = [
    {
      title: 'Your Company',
      text: 'Add company information such as people, address, schedules and traveler types on the Company page. This information will feed into other features on the platform.',
      link: constants.routes.COMPANY,
      icon: 'mdi-office-building',
      beta: true,
      roles: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
    {
      title: 'Locations',
      text: 'Add locations you do business out of on this page. This can include departure points and stops throughout the customer’s journey.',
      link: constants.routes.LOCATIONS,
      icon: 'mdi-map-marker',
      beta: true,
      roles: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
    {
      title: 'Products',
      text: 'Add products and options on the product page. Products are things like general admission tickets, and options are things like meal tickets or photos. You can also determine their availability here.',
      link: constants.routes.PRODUCT_LIST,
      icon: 'mdi-ticket',
      beta: true,
      roles: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
    {
      title: 'Pricing & Inventory',
      text: 'Add pricing, inventory and availability to pair with your products on the Pricing & Inventory page. You can also manage your taxes here.',
      link: constants.routes.OFFER_LIST,
      icon: 'mdi-archive',
      beta: true,
      roles: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
    {
      title: 'Channels & Rate Cards',
      text: 'On the channels page, you can create rate cards and connect to channels.',
      link: constants.routes.CHANNEL_LIST,
      icon: 'mdi-store',
      beta: true,
      ticketing: true,
      rolesAllowAny: true,
      roles: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
    },
    {
      title: 'User Management',
      text: 'Add your colleagues as users here so that they can access the platform.',
      link: constants.routes.USERS,
      icon: 'mdi-account-cog',
      roles: [],
    },
    {
      title: 'Reporting',
      text: 'On the report pages you can access predefined reports. Allowing you to see upcoming arrivals, a view of all bookings, a customer manifest by location as well as view your redemptions.',
      link: constants.routes.REPORT_ALL_BOOKINGS,
      icon: 'mdi-chart-box',
      roles: [
        constants.roles.SUPPLIER_REPORTING_STAFF,
        constants.roles.SUPPLIER_REDEMPTION_STAFF,
        constants.roles.SUPPLIER_REPORTING_ALL_BOOKING_STAFF,
        constants.roles.SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF,
      ],
      rolesRequireOnlyOne: true,
    },
    {
      title: 'Web Redemption',
      text: 'Scan or manually Redeem bar codes presented by guests on the Web Redemption Page.',
      link: constants.routes.REDEMPTION,
      icon: 'mdi-barcode-scan',
      roles: [constants.roles.SUPPLIER_REDEMPTION_STAFF],
    },
  ];
  private redemptionRoute = constants.routes.REDEMPTION;
  private usersRoute = constants.routes.USERS;
  private productRoute = constants.routes.PRODUCT_LIST;
  private inventoryRoute = constants.routes.PRICING_LIST;
  private companyRoute = constants.routes.COMPANY;
  private arrivalRoute = constants.routes.REPORT_ARRIVALS;

  mounted() {
    const userRoles = [] as Array<string>;
    profileModule.Profile?.groups?.forEach((g) => {
      if (g && g.name) {
        userRoles.push(g.name);
      }
    });

    this.cards.forEach((card: any) => {
      if (Array.isArray(card.roles) && card.roles.length === 0) {
        this.authorizedCards.push(card);
      } else if (
        Array.isArray(card.roles) &&
        !card.rolesRequireOnlyOne &&
        _.difference(card.roles, userRoles).length === 0
      ) {
        this.authorizedCards.push(card);
      } else if (card.rolesAllowAny && Array.isArray(card.allowedAnyRole)) {
        if (
          userRoles.findIndex(
            (r) => r.toUpperCase() === constants.roles.RC_TICKETING_EDIT,
          ) > -1 &&
          card.ticketing
        ) {
          this.authorizedCards.push(card);
        }
      } else if (Array.isArray(card.roles) && card.rolesRequireOnlyOne) {
        let c = null;
        if (
          userRoles.findIndex(
            (r) =>
              r.toUpperCase() === constants.roles.SUPPLIER_REPORTING_ALL_BOOKING_STAFF,
          ) > -1
        ) {
          c = _.cloneDeep(card);
          c.link = constants.routes.REPORT_ALL_BOOKINGS;
        } else if (
          userRoles.findIndex(
            (r) => r.toUpperCase() === constants.roles.SUPPLIER_REPORTING_STAFF,
          ) > -1
        ) {
          c = _.cloneDeep(card);
          c.link = constants.routes.REPORT_ARRIVALS;
        } else if (
          userRoles.findIndex(
            (r) =>
              r.toUpperCase() ===
              constants.roles.SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF,
          ) > -1
        ) {
          c = _.cloneDeep(card);
          c.link = constants.routes.REPORT_CUSTOMER_MANIFEST;
        } else if (
          userRoles.findIndex(
            (r) =>
              r.toUpperCase() === constants.roles.SUPPLIER_REPORTING_REDEMPTIONS_STAFF,
          ) > -1
        ) {
          c = _.cloneDeep(card);
          c.link = constants.routes.REPORT_REDEMPTIONS;
        }

        if (c) {
          this.authorizedCards.push(c);
        }
      }
    });
  }

  canViewBeta(beta: boolean | undefined) {
    if (!beta) {
      return true;
    }
    return beta && profileModule.IsBetaUser;
  }

  canViewTicketing(view: boolean | undefined) {
    if (!view) {
      return true;
    }
    return view && profileModule.IsTicketingEditRole;
  }

  get backgroundImage() {
    return {
      backgroundImage: `url(${require('@/assets/images/backgrounds/home-header-img.png')})`,
    };
  }
}
