


































































































import { Component, Vue } from 'vue-property-decorator';
import { companyModule } from '@/store/modules/moduleCompany';
import { requiredFieldRules } from '@/utils/validation-rules';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import { Option, TripRoute } from '@/models';
import { constants } from '@/utils/constants';
import { optionModule } from '@/store/modules/moduleOption';

@Component
export default class OptionViewLocationModal extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private option = {} as Option;
  private tripRoute = {
    departure: '',
    arrival: '',
    stops: [] as Array<string>,
  } as TripRoute;

  mounted() {
    this.$root.$on(
      constants.events.OPTION_LOCATION_EDIT_MODAL_CLEAR_VALIDATION,
      this.clearValidateState,
    );
  }

  destroyed() {
    this.$root.$off(constants.events.OPTION_LOCATION_EDIT_MODAL_CLEAR_VALIDATION);
  }

  clearValidateState() {
    if (this.$refs?.optionLocationForm) {
      const optionLocationForm: any = this.$refs.optionLocationForm;
      optionLocationForm.reset();
    }
  }

  validate() {
    if (this.$refs?.optionLocationForm) {
      const optionLocationForm: any = this.$refs.optionLocationForm;
      const isValid = optionLocationForm.validate();
      if (isValid) {
        this.updateTripRoute();
      }
    }
  }

  closeEditModal() {
    optionModule.clearEditOptionLocationForm();
    optionModule.setEditOptionLocationModal(false);
  }

  updateTripRoute() {
    const map = new Map(Object.entries(this.LocationForm));

    this.tripRoute = Object(map.get('tripRoute'));

    if (
      this.tripRoute &&
      this.tripRoute.departure &&
      _.isString(this.tripRoute.departure) &&
      this.tripRoute.departure.length > 0 &&
      this.tripRoute.arrival &&
      _.isString(this.tripRoute.arrival) &&
      this.tripRoute.arrival.length > 0
    ) {
      optionModule.setTripRoute(this.tripRoute);
      httpModule
        .updateOptionTripRoute()
        .then(() => {
          this.closeEditModal();
        })
        .catch();
    } else {
      this.closeEditModal();
    }
  }

  get Option() {
    return optionModule.Option;
  }

  get TripRoute() {
    return optionModule.TripRoute;
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get HasArrivalDeparture() {
    return (
      (this.LocationForm &&
        this.LocationForm.tripRoute &&
        _.isString(this.LocationForm.tripRoute.departure) &&
        this.LocationForm.tripRoute.departure.length > 0) ||
      (this.LocationForm &&
        this.LocationForm.tripRoute &&
        _.isString(this.LocationForm.tripRoute.arrival) &&
        this.LocationForm.tripRoute.arrival.length > 0)
    );
  }

  get LocationForm(): any {
    return optionModule.EditOptionLocationForm;
  }

  set LocationForm(form: any) {
    optionModule.setEditOptionLocationForm(form);
  }

  get EditOptionLocationFormState() {
    return optionModule.EditOptionLocationFormState;
  }

  set EditOptionLocationFormState(state: boolean) {
    optionModule.setEditOptionLocationFormState(state);
  }
}
