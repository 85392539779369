











































































import { Component, Vue } from 'vue-property-decorator';

import {
  AddressType,
  Location,
  OppProductAddressWrapper,
} from '@/api/inventory/InventoryModels';
import _ from 'lodash';
import { GetLocationToRestoreRS } from '@/api/wps/WpsModels';
import { constants } from '@/utils/constants';
import { productModule } from '@/store/modules/moduleProduct';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import faker from 'faker';
import { Address } from '@/models/ModelsOppInventory';

// declare const google: any;

@Component({
  components: {},
  props: {},
  mixins: [],
})
export default class PrimaryLocationSection extends Vue {
  private primaryLocation = {} as Address;
  private basePrimaryLocation = {} as Address;
  private companyLocationIdx = -1;
  private address = '';
  private selectedLocationId = '';
  private baseSelectedLocationId = '';
  private saving = false;
  // private map: any;
  private lat = 0;
  private long = 0;

  mounted() {
    this.init();
    this.$root.$on(constants.events.PRODUCT_LOAD_COMPLETE, () => {
      this.init();
    });
    // this.$root.$on(constants.events.REDRAW_DEPARTURE_MAP, () => {
    //   this.init();
    // });
  }

  init() {
    // todo: match selected product primary address to list of locations; get location ID
    this.primaryLocation = _.clone(this.PrimaryLocation);
    this.basePrimaryLocation = _.clone(this.PrimaryLocation);
    this.selectedLocationId = this.primaryLocation?.id || '';
    this.baseSelectedLocationId = this.primaryLocation?.id || '';
    if (this.primaryLocation && !_.isEmpty(this.primaryLocation)) {
      this.getCompanyLocationId(this.primaryLocation);
      if (this.SelectedCompanyLocation && this.SelectedCompanyLocation.name) {
        this.address = this.SelectedCompanyLocation.name;
        return;
      }
      this.address =
        this.companyLocationIdx && this.companyLocationIdx > -1
          ? this.configureAddress()
          : this.configureAddressFromLocation();
      // this.updateLatLong();
    }
  }

  changeLocation() {
    // todo: get current selected location
    const companyLocation = this.SelectedCompanyLocation;
    if (companyLocation) {
      if (companyLocation.name) {
        this.address = companyLocation.name;
      } else {
        this.address = this.convertCompanyLocationToString(companyLocation);
      }
      // this.updateLatLong();
      const invLocation = this.convertCompanyLocationToInventoryLocation(companyLocation);

      productModule.updateProductPrimaryLocation(invLocation);
    }
  }

  convertInventoryLocationToCompanyLocation(location: Location): GetLocationToRestoreRS {
    return {
      id: location.id || '', //todo: get ID from list of company locations
      location_name: '',
      description: '',
      longitude: location.longitude?.toString() || '0',
      latitude: location.latitude?.toString() || '0',
      address: {
        streetAddress: location.streetAddress || '',
        city: location.city || '',
        state: location.region || '',
        postalCode: location.postalCode || '',
        countryCode: location.country || '',
        phone: '',
        email: '',
      },
    };
  }

  convertCompanyLocationToInventoryLocation(location: Address): Address {
    return {
      id: location.id,
      name: location.name || '',
      streetAddress: location.streetAddress || '',
      city: location.city || '',
      region: location.region || '',
      postalCode: location.postalCode || '',
      countryCode: location.countryCode,
      latitude: location.latitude || 0,
      longitude: location.longitude || 0,
      // type: AddressType.PRIMARY,
      googlePlaceId: location.googlePlaceId,
      version: location.version,
    };
  }

  convertInventoryLocationToString(location: Location): string {
    return `${location.streetAddress}, ${location.city}, ${location.region} ${location.postalCode}, ${location.country}`;
  }

  convertCompanyLocationToString(location: Address): string {
    if (location && location.name) {
      return location.name;
    }
    const street = location?.streetAddress ? location?.streetAddress + ',' : '';
    const city = location?.city ? location?.city + ',' : '';
    const region = location?.region ? location?.region : '';
    const postal = location?.postalCode ? location?.postalCode + ',' : '';
    const countryCode = location?.countryCode ? location?.countryCode : '';
    return `${street} ${city} ${region} ${postal} ${countryCode}`.trim();
  }

  getCompanyLocationId(location: Address): string {
    const companyLocation = this.CompanyAddresses.find((l: Address) => {
      return (
        l.streetAddress === location.streetAddress &&
        l.region === location.region &&
        l.city === location.city &&
        l.postalCode === location.postalCode
      );
    });

    if (companyLocation) {
      this.companyLocationIdx = this.CompanyAddresses.findIndex(
        (location: Address) => location.id === companyLocation.id,
      );
    }
    return '';
  }

  configureAddress(): string {
    return this.convertCompanyLocationToString(
      this.CompanyAddresses[this.companyLocationIdx],
    );
  }

  configureAddressFromLocation(): string {
    if (this.primaryLocation && !_.isEmpty(this.primaryLocation)) {
      const street = this.primaryLocation?.streetAddress
        ? this.primaryLocation?.streetAddress + ','
        : '';
      const city = this.primaryLocation?.city ? this.primaryLocation?.city + ',' : '';
      const region = this.primaryLocation?.region ? this.primaryLocation?.region : '';
      const postal = this.primaryLocation?.postalCode
        ? this.primaryLocation?.postalCode + ','
        : '';
      const countryCode = this.primaryLocation?.countryCode
        ? this.primaryLocation?.countryCode
        : '';
      return `${street} ${city} ${region} ${postal} ${countryCode}`.trim();
    }
    return '';
  }

  saveChanges() {
    this.saving = true;
    const id = faker.datatype.uuid();
    const supplierId = faker.datatype.uuid(); //companyModule.Supplier?.location?.id;
    const companyId = companyModule.Organization
      ? companyModule.Organization.ID
      : faker.datatype.uuid();
    const productId = this.Product.id;
    const addressWrapper = {
      productAddress: {
        addressId: id,
        type: AddressType.PRIMARY,
        status: 1,
        version: 1,
      },
      criteria: {
        addressId: id,
        companyId: companyId,
        supplierId: supplierId,
        productId: productId,
      },
    } as OppProductAddressWrapper;
    httpModule
      .createProductAddress(addressWrapper)
      .then(() => {
        productModule.setEditModePrimaryLocation(false);
      })
      .catch(() => {
        // todo send alert
      })
      .finally(() => {
        this.saving = false;
      });
    // httpModule
    //   .updateProduct()
    //   .then(() => {
    //     productModule.setEditModePrimaryLocation(false);
    //   })
    //   .catch(() => {
    //     // todo send alert
    //   })
    //   .finally(() => {
    //     this.saving = false;
    //     this.primaryLocation = _.clone(this.PrimaryLocation || '');
    //     this.basePrimaryLocation = _.clone(this.PrimaryLocation || '');
    //   });
  }

  cancel() {
    this.primaryLocation = _.clone(this.basePrimaryLocation);
    this.selectedLocationId = this.baseSelectedLocationId;
    this.toggleEdit();
    this.changeLocation();
  }

  toggleEdit() {
    productModule.setEditModePrimaryLocation(!this.EditMode);
  }

  get PrimaryLocation() {
    return productModule.PrimaryLocation;
  }

  get CompanyAddresses() {
    return companyModule.Addresses;
  }

  get SelectedCompanyLocation() {
    return companyModule.Addresses.find(
      (location: Address) => location.id === this.selectedLocationId,
    );
  }

  get Product() {
    return productModule.Product;
  }

  get EditMode() {
    return productModule.EditModePrimaryLocation;
  }
}
