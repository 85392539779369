

















































import { Component, Vue } from 'vue-property-decorator';
import { DeliveryMethods } from '@/models/ApiV2Models';
import { productModule } from '@/store/modules/moduleProduct';
import { constants } from '@/utils/constants';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import { appModule } from '@/store/modules/moduleApp';

@Component({
  components: {},
  props: {
    option: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class DeliveryMethodsViewEdit extends Vue {
  private deliveryMethodsModel = {} as DeliveryMethods;
  private baseDeliveryMethodsModel = {} as DeliveryMethods;
  private saving = false;
  private deliveryMethods = {
    TICKET: 'Single bar code per traveler',
    VOUCHER: 'Single bar code per booking',
  };
  private deliveryMethodsToEdit = [
    {
      value: 'TICKET',
      text: this.deliveryMethods.TICKET,
    },
    {
      value: 'VOUCHER',
      text: this.deliveryMethods.VOUCHER,
    },
  ];

  mounted() {
    this.$root.$on(constants.events.PRODUCT_LOAD_COMPLETE, () => {
      this.deliveryMethodsModel = _.cloneDeep(this.DeliveryMethods);
      this.baseDeliveryMethodsModel = _.cloneDeep(this.DeliveryMethods);
    });
  }

  toggleDeliveryMethodsEdit() {
    this.deliveryMethodsModel = _.cloneDeep(this.DeliveryMethods || []);

    if (!this.EditModeDeliveryMethods) {
      this.baseDeliveryMethodsModel = _.cloneDeep(this.DeliveryMethods || []);
    }
    productModule.setEditModeDeliveryMethods(!this.EditModeDeliveryMethods);
  }

  saveChanges() {
    this.saving = true;
    productModule.setDeliveryMethods(this.deliveryMethodsModel);
    const product = _.cloneDeep(productModule.Product);
    const ticketDelivery = product.ticketDelivery;

    if (ticketDelivery && ticketDelivery.deliveryMethods) {
      ticketDelivery.deliveryMethods = [this.deliveryMethodsModel];
      product.ticketDelivery = ticketDelivery;
      productModule.setProduct(product);
    }
    httpModule
      .updateProduct()
      .then(() => {
        productModule.setEditModeDeliveryMethods(false);
      })
      .catch(() => {
        // todo send alert
      })
      .finally(() => {
        this.saving = false;
        this.deliveryMethodsModel = _.cloneDeep(this.DeliveryMethods || {});
        this.baseDeliveryMethodsModel = _.cloneDeep(this.DeliveryMethods || {});
      });
  }

  cancel() {
    this.deliveryMethodsModel = _.cloneDeep(this.baseDeliveryMethodsModel);
    this.toggleDeliveryMethodsEdit();
  }

  get Product() {
    return productModule.Product;
  }

  get EditModeDeliveryMethods() {
    return productModule.EditModeDeliveryMethods;
  }

  get DeliveryMethodCodes() {
    return appModule.Codes.deliveryMethods || [];
  }

  get DeliveryMethods(): DeliveryMethods {
    return productModule.DeliveryMethods;
  }

  set DeliveryMethods(methods: DeliveryMethods) {
    productModule.setDeliveryMethods(methods);
  }
}
