



















































import { Component, Vue } from 'vue-property-decorator';
import RequestConnectionModal from '../../components/channel/requestconnectionwizard/RequestConnectionModal.vue';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import RdmConnectAvailableChannel from '@/components/channel/connect-channel/RdmConnectAvailableChannel.vue';
import { channelModule } from '@/store/modules/moduleChannel';
import { tablePaginationOptions } from '@/utils/helpers';
import { constants } from '@/utils/constants';
import { httpModule } from '@/store/modules/moduleHttp';
import { Channel } from '@/models/ChannelModels';

@Component({
  components: {
    RdmStatusIndicator,
    RdmConnectAvailableChannel,
    RequestConnectionModal,
  },
})
export default class AvailableChannelListing extends Vue {
  private selectedChanel = {} as Channel;
  private dialog = false;

  private availableChannelsHeaders: Array<Record<string, any>> = [
    { text: 'Channel', align: 'left', sortable: true, value: 'name' },
    { text: '', value: 'action', align: 'right', sortable: false },
  ];

  /* COMPUTED */

  get AvailableChannels() {
    return channelModule.AvailableChannels;
  }

  get paginationOptions() {
    return tablePaginationOptions(this.AvailableChannels);
  }

  get Loading() {
    return channelModule.Loading;
  }

  get ChannelAccess() {
    return channelModule.ChannelAccess;
  }

  private async mounted() {
    if (this.ChannelAccess) await httpModule.getCompanyChannels();
  }

  connect() {}

  closeDialog() {
    channelModule.setChannelToBind({});
    this.dialog = false;
  }

  modalEvents(modalEvent: any) {
    if (modalEvent && this.$refs.requestConnectionWizard) {
      const requestConnection: any = this.$refs.requestConnectionWizard;
      requestConnection.resetModal();
    } else if (!modalEvent && this.$refs.requestConnectionWizard) {
      const requestConnection: any = this.$refs.requestConnectionWizard;
      requestConnection.closeModal();
    }
  }

  selectChannel(channel: Channel) {
    channelModule.setChannelToBind(channel);
    this.dialog = true;
  }

  private goToFeedback() {
    this.$router.push({ name: constants.routes.FEEDBACK }).catch(() => {});
  }

  get SearchText() {
    return channelModule.SearchText;
  }
}
