
































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import faker from 'faker';
import { RateCardPrices } from '@/models/ChannelModels';
import { requiredFieldRules } from '@/utils/validation-rules';
import { tablePaginationOptions } from '@/utils/helpers';

@Component
export default class RateCardListings extends Vue {
  private productName = '';

  private rateCardName = '';

  private commission = 0;

  private picker: string = new Date().toISOString().substr(0, 7);

  private priceRules = requiredFieldRules;

  private menu = false;

  private loading = false;

  private pricingHeaders: Array<Record<string, any>> = [
    { text: 'Date', align: 'left', sortable: true, value: 'date' },
    { text: 'Adult (Retail)', align: 'left', sortable: true, value: 'adult_retail' },
    { text: 'Adult (Net)', align: 'left', sortable: true, value: 'adult_net' },
    { text: 'Child (Retail)', align: 'left', sortable: true, value: 'child_retail' },
    { text: 'Child (Net)', align: 'left', sortable: true, value: 'child_net' },
    { text: 'Senior (Retail)', align: 'left', sortable: true, value: 'senior_retail' },
    { text: 'Senior (Net)', align: 'left', sortable: true, value: 'senior_net' },
    { text: 'Other (Retail)', align: 'left', sortable: true, value: 'other_retail' },
    { text: 'Other (Net)', align: 'left', sortable: true, value: 'other_net' },
  ];

  private ratePrices: Array<RateCardPrices> = [];

  get paginationOptions() {
    return tablePaginationOptions(this.ratePrices);
  }

  mounted() {
    this.loading = true;
    this.productName = this.$route.params.product;
    this.commission = parseInt(this.$route.params.commission, 10);
    this.rateCardName = `Rate Card (${this.commission}%)`;
    this.generateRateCardPrices(
      this.parseMonth(this.picker),
      this.parseYear(this.picker),
    );
    this.loading = false;
  }

  month() {
    this.generateRateCardPrices(
      this.parseMonth(this.picker),
      this.parseYear(this.picker),
    );
  }

  parseMonth(monthStr: string) {
    const parsed = monthStr.split('-');
    return parsed[1];
  }

  parseYear(monthStr: string) {
    const parsed = monthStr.split('-');
    return parsed[0];
  }

  generateRateCardPrices(month: string, year: string) {
    this.ratePrices = [];
    for (let i = 1; i < 31; i++) {
      const p: RateCardPrices = new RateCardPrices();
      const ar = faker.datatype.number({ min: 100, max: 125 });
      const an = ar * ((100 - this.commission) / 100);
      const cr = faker.datatype.number({ min: 65, max: 85 });
      const cn = cr * ((100 - this.commission) / 100);
      const sr = faker.datatype.number({ min: 75, max: 95 });
      const sn = sr * ((100 - this.commission) / 100);
      const or = faker.datatype.number({ min: 60, max: 80 });
      const on = or * ((100 - this.commission) / 100);
      p.date = `${month}/${i}/${year}`;
      p.adult_retail = faker.finance.amount(ar, ar, 2, '$');
      p.adult_net = faker.finance.amount(an, an, 2, '$');
      p.child_retail = faker.finance.amount(cr, cr, 2, '$');
      p.child_net = faker.finance.amount(cn, cn, 2, '$');
      p.senior_retail = faker.finance.amount(sr, sr, 2, '$');
      p.senior_net = faker.finance.amount(sn, sn, 2, '$');
      p.other_retail = faker.finance.amount(or, or, 2, '$');
      p.other_net = faker.finance.amount(on, on, 2, '$');
      this.ratePrices.push(p);
    }
  }

  save() {}

  cancel() {}

  open() {}

  close() {}
}
