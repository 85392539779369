





























import { Component, Vue } from 'vue-property-decorator';
import AvailabilityStatusFilter from '@/components/inventory/availability/AvailabilityStatusFilter.vue';
import AvailabilityStatusCalendar from '@/components/inventory/availability/AvailabilityStatusCalendar.vue';
import AvailabilityStatusGetStarted from '@/components/inventory/availability/AvailabilityStatusGetStarted.vue';
import AvailabilityStatusDayInventory from '@/components/inventory/availability/AvailabilityStatusDayInventory.vue';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import { httpModule } from '@/store/modules/moduleHttp';
import { offerModule } from '@/store/modules/moduleOffer';
import { format, lastDayOfMonth } from 'date-fns';

@Component({
  components: {
    AvailabilityStatusDayInventory,
    AvailabilityStatusGetStarted,
    AvailabilityStatusCalendar,
    AvailabilityStatusFilter,
  },
})
export default class AvailabilityStatus extends Vue {
  private calendarDate = 'year';
  private selectedOffer = {} as any;

  async mounted() {
    availabilityStatusModule.clearState();
    await httpModule.getOffers();
  }

  async getAvailsForOffer(offer: any) {
    availabilityStatusModule.clearState();
    if (offer && offer.id) {
      let startDate = '';
      let endDate = '';
      if (this.calendarDate === 'month') {
        startDate = `${format(this.CurrentDate, 'yyyy-MM-01')}T00:00:00Z`;
        endDate = `${format(lastDayOfMonth(this.CurrentDate), 'yyyy-MM-dd')}T23:59:59Z`;
      } else if (this.calendarDate === 'year') {
        startDate = `${this.CurrentDate.getFullYear()}-01-01T00:00:00Z`;
        endDate = `${this.CurrentDate.getFullYear()}-12-31T23:59:59Z`;
      }
      await httpModule.getAvailabilitiesForOffer({ offer, startDate, endDate });
      this.selectedOffer = offer;
    }
  }

  async selectedCalendarDate(date: string) {
    this.calendarDate = date;
    await this.getAvailsForOffer(this.selectedOffer);
  }

  get ShowCalendar() {
    return !!this.selectedOffer.id;
  }

  get CurrentDate() {
    return availabilityStatusModule.CurrentDate;
  }

  get Loading(): boolean {
    return offerModule.Loading;
  }

  get Offers() {
    return offerModule.Offers.sort((a, b) => {
      const upperA = a.name?.toUpperCase() || '';
      const upperB = b.name?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get HasPricing() {
    // return pricingModule.Pricing.length > 0;
    return true;
  }

  get HasInventory() {
    // return pricingModule.
    return true;
  }

  get NotReady() {
    return !this.HasPricing || !this.HasInventory;
  }

  get ShowSidebar() {
    return availabilityStatusModule.ShowSidebar;
  }
}
