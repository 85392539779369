




























































import { Component, Vue } from 'vue-property-decorator';
import RequestConnectionModal from '../../components/channel/requestconnectionwizard/RequestConnectionModal.vue';
import RequestConnectionWizard from '../../components/channel/requestconnectionwizard/RequestConnectionWizard.vue';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import RdmGenericMessageModal from '@/components/common/alert/RdmGenericMessageModal.vue';
import { constants } from '@/utils/constants';
import { channelModule } from '@/store/modules/moduleChannel';
import { tablePaginationOptions } from '@/utils/helpers';
import { httpModule } from '@/store/modules/moduleHttp';
import { ChannelBinding, RateCard, RateCardChannel } from '@/models';

/**
 * ConnectedChannel - internal model to hold the connected channel the details about it
 */
export interface ConnectedChannel {
  channel: RateCardChannel;
  rateCards: Array<RateCard>;
}

@Component({
  components: {
    RdmGenericMessageModal,
    RdmStatusIndicator,
    RequestConnectionWizard,
    RequestConnectionModal,
  },
})
export default class ChannelListing extends Vue {
  private routeCreateRateCard = constants.routes.CHANNEL_RATE_CARD_CREATE;
  private requestConnectionDialog = false;
  private removeConnectionDialog = false;
  private selectedChannel: ConnectedChannel = {} as ConnectedChannel;
  private connectedChannels = Array<ConnectedChannel>();
  private connectedChannelsHeaders: Array<Record<string, any>> = [
    {
      text: 'Channel',
      align: 'left',
      sortable: false,
      value: 'channel',
    },
    {
      text: 'Rate Cards',
      align: 'left',
      sortable: false,
      value: 'rateCards',
    },
    {
      text: '',
      align: 'right',
      sortable: false,
      value: 'action',
    },
  ];

  /* COMPUTED */

  get RateCards() {
    return channelModule.RateCards || [];
  }

  /**
   * determineConnectedChannels creates a mapping of channel to rate cards
   */
  determineConnectedChannels() {
    const channelToRateCardMap = new Map<string, Array<RateCard>>();
    this.RateCards.forEach((rateCard: RateCard) => {
      rateCard.channels?.forEach((channel: RateCardChannel) => {
        const channelKey = JSON.stringify(channel);
        if (!channelToRateCardMap.has(channelKey)) {
          const rateCards = Array<RateCard>();
          rateCards.push(rateCard);
          channelToRateCardMap.set(channelKey, rateCards);
        } else {
          channelToRateCardMap.get(channelKey)?.push(rateCard);
        }
      });
    });

    const connectedChannels = Array<ConnectedChannel>();
    for (const [key, value] of channelToRateCardMap) {
      connectedChannels.push({
        channel: JSON.parse(key),
        rateCards: value,
      });
    }

    // sort
    connectedChannels.sort((a, b) => {
      const upperA = a.channel.name.toUpperCase();
      const upperB = b.channel.name.toUpperCase();
      if (upperA < upperB) {
        return -1;
      }
      if (upperA > upperB) {
        return 1;
      }
      return 0;
    });
    this.connectedChannels = connectedChannels;
  }

  get ConnectedChannels(): Array<ConnectedChannel> {
    return this.connectedChannels;
  }

  get Loading() {
    return channelModule.Loading;
  }

  get ChannelAccess() {
    return channelModule.ChannelAccess;
  }

  async mounted() {
    await this.loadData();
  }

  destroyed() {
    this.requestConnectionDialog = false;
    this.removeConnectionDialog = false;
    this.selectedChannel = {} as ConnectedChannel;
    this.connectedChannels = Array<ConnectedChannel>();
  }

  private async loadData() {
    if (this.ChannelAccess) {
      await httpModule.getRateCards();
      await httpModule.getRateCardsChannel(this.RateCards);
      this.determineConnectedChannels();
    }
  }

  displayRatesName(rates: Array<RateCard>) {
    return rates.map((item: RateCard) => `• ${item.name}`).join('\n');
  }

  goToFeedback() {
    this.$router.push({ name: constants.routes.FEEDBACK }).catch(() => {});
  }

  closeDialog() {
    this.requestConnectionDialog = false;
  }

  get selectedChannelName(): string {
    return this.selectedChannel?.channel?.name || '';
  }

  get selectedChannelID(): string {
    return this.selectedChannel?.channel?.id || '';
  }

  /**
   * sets the selected channel to be removed and opens the removal modal for confirmation
   * @param selectedChannel
   */
  removeConnection(selectedChannel: any) {
    if (selectedChannel) {
      this.selectedChannel = selectedChannel;
      this.removeConnectionDialog = true;
    }
  }

  /**
   * closes the remove bindings model and deselects the active selected channel
   */
  cancelRemoveConnection() {
    this.selectedChannel = {} as ConnectedChannel;
    this.removeConnectionDialog = false;
  }

  /**
   * for the selected channel it will remove all channel bindings
   */
  proceedRemoveConnection() {
    const channelID = this.selectedChannelID;
    const bindingsForChannel = channelModule.ConnectedChannels.filter(
      (item: ChannelBinding) => {
        return item.channel.id === channelID;
      },
    );

    // remove bindings from channel API
    bindingsForChannel.forEach((binding) => {
      httpModule.deleteChannelBinding({
        supplierId: binding.supplier.id,
        productId: binding.product.id,
        channelId: binding.channel.id,
      });
    });

    // remove bindings from rate cards
    if (this.selectedChannel.rateCards) {
      this.selectedChannel.rateCards.forEach((rateCard: any) => {
        httpModule.deleteRateCardChannel({
          rateCardId: rateCard.id,
          channelId: channelID,
        });
      });
    }
    this.loadData();
    this.removeConnectionDialog = false;
  }

  toConnectedChannelDetails(channel: ChannelBinding) {
    if (channel && channel.channel.id) {
      this.$router
        .push({
          name: constants.routes.CHANNEL_DETAILS,
          params: { id: channel.channel.id || '' },
        })
        .catch(() => {});
      return;
    }
    // todo alert no channel id
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get SearchText() {
    return channelModule.SearchText;
  }

  get paginationOptions() {
    return tablePaginationOptions(this.ConnectedChannels);
  }
}
