








































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import AddTravelerType from '@/components/product/list/AddTravelerType.vue';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';

@Component({
  components: {
    AddTravelerType,
    RdmReturnToBtn,
  },
})
export default class TravelerTypeCreate extends Vue {
  private routeTravelers = constants.routes.TRAVELER_LIST;

  mounted() {}

  discard() {
    this.goToTravelerTypes();
  }

  validate() {
    if (this.CreateTravelerTypeAgeRangeState) {
      return;
    }
    this.$root.$emit(constants.events.CREATE_TRAVELER_TYPE);
  }

  goToTravelerTypes() {
    this.$router.push({ name: this.routeTravelers }).catch();
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get Loading(): boolean {
    return travelerTypeModule.Loading;
  }

  get CreateTravelerTypeFormState(): boolean {
    return travelerTypeModule.CreateTravelerTypeFormState;
  }

  get CreateTravelerTypeAgeRangeState(): boolean {
    return travelerTypeModule.CreateTravelerTypeAgeRangeState;
  }
}
