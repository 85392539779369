import { render, staticRenderFns } from "./UserDetailView.vue?vue&type=template&id=1a3f75d0&scoped=true&"
import script from "./UserDetailView.vue?vue&type=script&lang=ts&"
export * from "./UserDetailView.vue?vue&type=script&lang=ts&"
import style0 from "./UserDetailView.vue?vue&type=style&index=0&id=1a3f75d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a3f75d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCol,VListItem,VListItemContent,VOverlay,VProgressCircular,VRow})
