

























import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { appModule } from '@/store/modules/moduleApp';

@Component({})
export default class RdmAboutPage extends Vue {
  closeModal() {
    appModule.setShowAboutPage(false);
  }

  get Version() {
    return appModule.Version;
  }

  get ShowAboutPage() {
    return appModule.ShowAboutPage;
  }

  set ShowAboutPage(show: boolean) {
    appModule.setShowAboutPage(show);
  }
}
