





























































import { Component, Vue } from 'vue-property-decorator';
import { optionModule } from '@/store/modules/moduleOption';
import { descriptionLimitRules } from '@/utils/validation-rules';
import { constants } from '@/utils/constants';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';

@Component
export default class OptionViewDescription extends Vue {
  private descriptionLimitRules = descriptionLimitRules;
  private saving = false;
  private descriptionLimit = 1000;
  private descriptionPlaceholder = 'Enter product description';
  private descriptionModel = '';
  private baseDescriptionModel = '';

  mounted() {
    if (this.$refs && this.$refs.optionDescForm) {
      const { optionDescForm } = this.$refs as any;
      optionDescForm.validate();
    }

    this.$root.$on(constants.events.OPTION_LOAD_COMPLETE, () => {
      this.descriptionModel = _.cloneDeep(this.Description);
      this.baseDescriptionModel = _.cloneDeep(this.Description);
    });
  }

  toggleEdit() {
    if (this.Loading) {
      return;
    }

    this.descriptionModel = _.cloneDeep(this.Description || '');

    if (this.EditMode) {
      // productModule.setEditModeDescription(false);
    } else {
      this.baseDescriptionModel = _.cloneDeep(this.Description || '');
    }

    if (this.EditMode) {
      optionModule.setEditModeDescription(false);
    } else {
      optionModule.setEditModeDescription(true);
    }
  }

  saveChanges() {
    this.saving = true;
    optionModule.setDescription(this.descriptionModel);
    httpModule
      .updateOption(this.Option)
      .then(() => {
        optionModule.setEditModeDescription(false);
      })
      .catch(() => {
        // todo send alert
      })
      .finally(() => {
        this.saving = false;
        this.descriptionModel = _.cloneDeep(this.Description || '');
        this.baseDescriptionModel = _.cloneDeep(this.Description || '');
      });
  }

  cancel() {
    this.descriptionModel = _.cloneDeep(this.baseDescriptionModel);
    optionModule.setEditModeDescription(false);
  }

  get EditMode() {
    return optionModule.EditModeDescription;
  }

  get Description() {
    return optionModule.Description;
  }

  get OptionDescriptionValid() {
    return true;
  }

  set OptionDescriptionValid(valid: boolean) {}

  get Option() {
    return optionModule.Option;
  }

  get Loading() {
    return optionModule.Loading;
  }
}
