





























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import {
  optionalStartDateBeforeEndDateRules,
  requiredStartDateBeforeTodayDateRules,
  requiredFieldRules,
} from '@/utils/validation-rules';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { companyModule } from '@/store/modules/moduleCompany';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import RdmTimeInput from '@/components/common/time/RdmTimeInput.vue';
import { TimeEntryMode } from '@/api/inventory/InventoryModels';
import moment from 'moment';
import _ from 'lodash';

@Component({
  components: {
    RdmCollapsableControlCard,
    RdmTimeInput,
  },
})
export default class OptionCreateSchedule extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private optionalStartBeforeEndField = optionalStartDateBeforeEndDateRules;
  private requiredStartBeforeEndField = requiredStartDateBeforeTodayDateRules;
  private dateNow = new Date();
  private minDate = moment(new Date()).format('YYYY-MM-DD');
  private startDateMenu = false;
  private endDateMenu = false;
  private timeEntryModes = [TimeEntryMode.Grouped, TimeEntryMode.Individual];
  private timeEntryModeGrouped = TimeEntryMode.Grouped;
  private timeEntryModeIndividual = TimeEntryMode.Individual;
  private days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ] as Array<string>;
  private availAbleDays = [] as Array<string>;
  private selectedDays = [] as Array<string>;
  private groupedDays = [] as any;
  private individualDays = [] as any;
  private time = {
    start: null,
    end: null,
    timeStartMenu: false,
    timeEndMenu: false,
  };

  mounted() {
    this.availAbleDays = this.days;
    this.individualDays = this.days.map((day) => {
      return {
        weekday: day,
        timeSlots: [{ ...this.time }],
      };
    });
    scheduleModule.updateIndividualDays(this.individualDays);
  }

  destroyed() {
    scheduleModule.clearState();
  }

  updateSlotStart(slot: any, newStartTime: any) {
    slot.start = newStartTime;
    this.CreateOptionScheduleForm = true;
  }

  updateSlotEnd(slot: any, newEndTime: any) {
    slot.end = newEndTime;
    this.CreateOptionScheduleForm = true;
  }

  removeTimeSlot(i: number, group: any) {
    if (i !== 0) group.times.splice(i, 1);
  }

  toggleScheduleOpen() {
    productModule.setCreateProductScheduleOpen(!this.ScheduleOpen);
  }

  addDayGroup() {
    this.availAbleDays = this.availAbleDays.filter(
      (el) => !this.selectedDays.includes(el),
    );
    this.groupedDays = [
      ...this.groupedDays,
      {
        days: this.selectedDays,
        times: [{ ...this.time }],
      },
    ];
    this.selectedDays = [];
    scheduleModule.updateGroupedDays(this.groupedDays);
  }

  sortDays(a: any, b: any) {
    a = this.days.indexOf(a);
    b = this.days.indexOf(b);
    return a < b ? 0 : 1;
  }

  removeGroup(index: number) {
    const groups = this.groupedDays;
    const removed = groups.splice(index, 1);
    this.availAbleDays = [...this.availAbleDays, ...removed[0].days].sort(this.sortDays);
    this.groupedDays = groups;
  }

  addMoreTimeSlot(times: any) {
    times.push({ ...this.time });
  }

  triggerFormValidate() {
    if (this.$refs && this.$refs.scheduleForm) {
      const { scheduleForm } = this.$refs as any;
      const valid = scheduleForm.validate();
      const group =
        this.TimeEntryMode === TimeEntryMode.Grouped &&
        Array.isArray(this.groupedDays) &&
        this.groupedDays.length > 0;
      const individual =
        this.TimeEntryMode === TimeEntryMode.Individual && this.individualDays.length > 0;
      const formValid = valid && (group || individual);
      optionModule.setCreateOptionScheduleForm(formValid);
    }
  }

  invalidSlotTime(slot: any) {
    if (slot.start && slot.end) {
      let hourMins = slot.start.split(':');
      const start = {
        hours: Number(hourMins[0]),
        minutes: Number(hourMins[1]),
      };

      hourMins = slot.end.split(':');
      const end = {
        hours: Number(hourMins[0]),
        minutes: Number(hourMins[1]),
      };

      if (start.hours * 60 + start.minutes > end.hours * 60 + end.minutes) {
        return true;
      }
    }

    return false;
  }

  /* Page Data */

  get IndividualDays() {
    return scheduleModule.IndividualDays;
  }

  get GroupedDays() {
    return scheduleModule.GroupedDays;
  }

  get Schedule() {
    return scheduleModule.Schedule;
  }

  get Company() {
    return companyModule.Company;
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get Product() {
    return productModule.Product;
  }

  get CreateOptionScheduleForm() {
    return optionModule.CreateOptionScheduleForm;
  }

  set CreateOptionScheduleForm(state: boolean) {
    let isValid = true;
    if (this.TimeEntryMode === TimeEntryMode.Grouped) {
      this.groupedDays.forEach((group: any) => {
        if (group.times.length === 0) {
          isValid = false;
        }
        group.times.forEach((time: any) => {
          if (time.start == null || time.end == null || this.invalidSlotTime(time)) {
            isValid = false;
          }
        });
      });
    } else if (this.TimeEntryMode === TimeEntryMode.Individual) {
      const days = this.IndividualDays.filter((day: any) => {
        return !!day.timeSlots.find((t: any) => {
          return (
            _.isString(t.start) &&
            t.start.length > 0 &&
            _.isString(t.end) &&
            t.end.length > 0
          );
        });
      });
      if (days.length == 0) isValid = false;
      else if (
        days.filter((day: any) =>
          day.timeSlots.some(
            (t: any) => t.start == null || t.end == null || this.invalidSlotTime(t),
          ),
        ).length > 0
      )
        isValid = false;
    } else {
      isValid = false;
    }

    optionModule.setCreateOptionScheduleForm(state && isValid);
  }

  get ScheduleData() {
    return scheduleModule.ScheduleData;
  }

  set ScheduleData(data: any) {
    scheduleModule.setScheduleData(data);
  }
  get TimeEntryMode() {
    return scheduleModule.TimeEntryMode;
  }
  set TimeEntryMode(mode: string) {
    scheduleModule.updateTimeEntryMode(mode);
  }

  /* UI State */
  get Step() {
    return productModule.CreateProductStep;
  }

  get Saving() {
    return productModule.CreateProductSaving;
  }

  get ScheduleOpen() {
    return productModule.CreateProductScheduleOpen;
  }

  set ScheduleOpen(open: boolean) {
    productModule.setCreateProductScheduleOpen(open);
  }

  get StartDate() {
    const padTo2Digits = (num: any) => num.toString().padStart(2, '0');
    return [
      this.dateNow.getFullYear(),
      padTo2Digits(this.dateNow.getMonth() + 1),
      padTo2Digits(this.dateNow.getDate() - 1),
    ].join('/');
  }

  get DateNowString() {
    return this.dateNow.toISOString();
  }

  /* UI Data */
}
