




























































import { Component, Vue } from 'vue-property-decorator';
import { productModule } from '@/store/modules/moduleProduct';
import {
  InventoryType,
  IRdmAvailabilityItems,
  Option,
  RdmAvailabilityItem,
} from '@/models';
import { constants } from '@/utils/constants';
import _ from 'lodash';
import { AvailabilityType } from '@/api/inventory/InventoryModels';
import {
  optionalNumberMinimumRules,
  requiredStartTimeBeforeEndTimeRules,
} from '@/utils/validation-rules';
import { optionModule } from '@/store/modules/moduleOption';
import { httpModule } from '@/store/modules/moduleHttp';

@Component({
  components: {},
  props: {},
})
export default class RdmInventoryType extends Vue {
  private inventoryType = '' as AvailabilityType;
  private baseInventoryType = '' as AvailabilityType;
  private saving = false;
  private days = ['0', '1', '2', '3', '4', '5', '6'];
  private dates = {} as IRdmAvailabilityItems;
  private requiredStartTimeBeforeEndTimeRules = requiredStartTimeBeforeEndTimeRules;
  private optionalNumberMinimumRules = optionalNumberMinimumRules;

  mounted() {
    this.$root.$on(constants.events.PRODUCT_LOAD_COMPLETE, () => {
      this.inventoryType = _.cloneDeep(this.SelectedInventoryType);
      this.baseInventoryType = _.cloneDeep(this.SelectedInventoryType);
      this.dates = {
        '0': {
          open: false,
          times: [{ localDateTimeStart: '08:00', localDateTimeEnd: '17:00' }],
          capacity: 0,
        },
        '1': {
          open: false,
          times: [{ localDateTimeStart: '08:00', localDateTimeEnd: '17:00' }],
          capacity: 0,
        },
        '2': {
          open: false,
          times: [{ localDateTimeStart: '08:00', localDateTimeEnd: '17:00' }],
          capacity: 0,
        },
        '3': {
          open: false,
          times: [{ localDateTimeStart: '08:00', localDateTimeEnd: '17:00' }],
          capacity: 0,
        },
        '4': {
          open: false,
          times: [{ localDateTimeStart: '08:00', localDateTimeEnd: '17:00' }],
          capacity: 0,
        },
        '5': {
          open: false,
          times: [{ localDateTimeStart: '08:00', localDateTimeEnd: '17:00' }],
          capacity: 0,
        },
        '6': {
          open: false,
          times: [{ localDateTimeStart: '08:00', localDateTimeEnd: '17:00' }],
          capacity: 0,
        },
      };
      // productModule.loadAvailabilitySchedules();
    });
  }

  destroyed() {
    this.$root.$off(constants.events.OPTION_LOAD_COMPLETE);
  }

  addCapacity(day: string) {
    const date = this.dates[day as keyof IRdmAvailabilityItems] as RdmAvailabilityItem;
    if (date) {
      date.times.push({ localDateTimeStart: '', localDateTimeEnd: '' });
    }
  }

  removeCapacityRow(day: string) {
    const date = this.dates[day as keyof IRdmAvailabilityItems] as RdmAvailabilityItem;
    if (date) {
      date.times.pop();
    }
  }

  saveChanges() {
    this.saving = true;
    productModule.setSelectedInventoryType(this.inventoryType);
    httpModule
      .updateProduct()
      .then(() => {
        productModule.setEditModeInventoryType(false);
      })
      .catch(() => {
        // todo send alert
      })
      .finally(() => {
        this.saving = false;
        this.inventoryType = _.cloneDeep(this.SelectedInventoryType);
        this.baseInventoryType = _.cloneDeep(this.SelectedInventoryType);
      });
  }

  cancel() {
    this.inventoryType = this.baseInventoryType;
    this.toggleInventoryEditMode();
  }

  toggleInventoryEditMode() {
    this.inventoryType = _.cloneDeep(this.SelectedInventoryType || []);

    if (!this.EditMode) {
      this.baseInventoryType = _.cloneDeep(this.SelectedInventoryType || []);
    }
    productModule.setEditModeInventoryType(!this.EditMode);
  }

  get LocationOpen(): boolean {
    return _.filter(this.dates, (d: RdmAvailabilityItem) => d.open).length > 0;
  }

  get Option(): Option {
    return (
      _.first(
        _.filter(optionModule.Options, (o: Option) => {
          return this.$props.id === o.id;
        }) || [],
      ) || ({} as Option)
    );
  }

  get Product() {
    return productModule.Product;
  }

  get SelectedInventoryType() {
    return productModule.SelectedInventoryType;
  }

  get InventoryTypes() {
    return [InventoryType.FREESALE, InventoryType.LIMITED];
  }

  get AvailabilityTypes() {
    return [AvailabilityType.OpeningHours, AvailabilityType.StartTime];
  }

  get EditMode() {
    return productModule.EditModeInventoryType;
  }
}
