

















import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import { pricingModule } from '@/store/modules/modulePricing';
import _ from 'lodash';
import { productModule } from '@/store/modules/moduleProduct';

@Component
export default class OfferEditChannel extends Vue {
  private requiredField = requiredFieldRules;
  private offerDescription = '';
  private baseOfferDescription = '';

  mounted() {
    this.offerDescription = _.cloneDeep(this.Offer.description || '');
    this.baseOfferDescription = _.cloneDeep(this.Offer.description || '');
  }

  toggleLocation() {
    pricingModule.toggleEditOfferLocation();
  }

  saveLocation() {
    // todo update name
    const offer = _.cloneDeep(this.Offer);
    offer.description = this.offerDescription;
    pricingModule.updateOffer(offer);
    // todo close edit description
    pricingModule.toggleEditOfferLocation();
  }

  cancelLocation() {
    this.offerDescription = this.baseOfferDescription;
    pricingModule.toggleEditOfferLocation();
  }

  get Offer() {
    return pricingModule.Offer;
  }

  get EditOfferLocation() {
    return pricingModule.EditOfferLocation;
  }

  get PrimaryAddress() {
    return productModule.PrimaryLocation;
  }
}
