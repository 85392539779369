







































































import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import {
  adminItems,
  connectivityManagementItems,
  noGroupItems,
  reportsItems,
  helpCenterItems,
} from './sidebar/sidebarItems';
import RdmLoading from '@/layouts/RdmLoading.vue';
import RdmAlert from '@/layouts/RdmAlert.vue';
import RdmSidebar from './sidebar/RdmSidebar.vue';
import RdmFooter from './RdmFooter.vue';
import RdmWelcomeWizard from '@/layouts/RdmWelcomeWizard.vue';
import RdmAlertFloat from '@/layouts/RdmAlertFloat.vue';
import RdmBreadcrumb from '@/layouts/RdmBreadcrumb.vue';
import { constants } from '@/utils/constants';
import TheNavbar from '@/layouts/app-navbar/TheNavbar.vue';
import RdmAboutPage from '@/components/common/about/RdmAboutPage.vue';
import RdmGeneralAlert from '@/components/common/alert/RdmGeneralAlert.vue';
import { httpModule } from '@/store/modules/moduleHttp';
import { appModule } from '@/store/modules/moduleApp';
import { profileModule } from '@/store/modules/moduleProfile';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import FailedToLoad from '@/views/error/FailedToLoad.vue';
import _ from 'lodash';
import { companyModule } from '@/store/modules/moduleCompany';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { GRSchedule, Schedule } from '@/models';
import * as faker from 'faker';
import moment from 'moment';

declare global {
  interface Window {
    checkAndAttachMapScript: any;
    mapApiInitialized: any;
  }
}

@Component({
  components: {
    FailedToLoad,
    TheNavbar,
    RdmSidebar,
    RdmWelcomeWizard,
    RdmLoading,
    RdmAlert,
    RdmAlertFloat,
    RdmBreadcrumb,
    RdmFooter,
    RdmAboutPage,
    RdmGeneralAlert,
  },
})
export default class MainView extends Vue {
  /* DATA */

  private fab = false;
  private ready = false;
  private loadError = false;
  private currentDate = new Date();
  private defaultSchedule = {
    id: faker.datatype.uuid(),
    name: 'Default',
    validFrom: moment(new Date()).format('YYYY-MM-DD'),
    validUntil: null,
    timeSlots: [
      {
        weekday: 'Sunday',
        start: '00:00',
        end: '23:59',
      },
      {
        weekday: 'Monday',
        start: '00:00',
        end: '23:59',
      },
      {
        weekday: 'Tuesday',
        start: '00:00',
        end: '23:59',
      },
      {
        weekday: 'Wednesday',
        start: '00:00',
        end: '23:59',
      },
      {
        weekday: 'Thursday',
        start: '00:00',
        end: '23:59',
      },
      {
        weekday: 'Friday',
        start: '00:00',
        end: '23:59',
      },
      {
        weekday: 'Saturday',
        start: '00:00',
        end: '23:59',
      },
    ],
  } as Schedule;
  /* HOOKS */

  private created() {
    window.addEventListener(constants.events.RESIZE, this.handleResize);
    this.$root.$on(constants.events.INIT_LOAD_COMPLETE, () => {
      this.ready = true;
    });
  }

  private destroyed() {
    window.removeEventListener(constants.events.RESIZE, this.handleResize);
  }

  private async mounted() {
    this.handleResize();
    this.initMaps();
    // this.initSidebar();
    // promise resolve for init load
    _.delay(() => {
      if (!this.ready) {
        this.loadError = true;
        // todo log load error to backend
      }
    }, 45000);
    await this.initModules();
    await this.loadData();

    // TODO: before this mounted is accomplished page's mounted may already upload data
    // TODO: (e.g. company, see this is happening for /company) - how to handle that right? do we need a queue or smth like that?

    // const language = meModule.Settings?.language;
    const language = 'en';
    this.$i18n.locale = language ?? 'en';

    this.$root.$on(constants.events.REFRESH_PRODUCTS, () => {
      this.loadProducts();
    });

    // await this.initContacts();
  }

  /* METHODS */

  // native event handlers

  private handleResize() {
    if (window.innerWidth > 960 && !appModule.IsSidebarActive) {
      appModule.setIsSidebarActive(true);
    }
  }

  // vue event handlers

  private onScroll() {
    if (typeof window === 'undefined') return;
    const top = window.pageYOffset || 0;
    this.fab = top > 400;
  }

  private toTop() {
    this.$vuetify.goTo(0);
  }

  // helpers

  private initMaps() {
    // todo call backend to get api key
    window.checkAndAttachMapScript = function (callback: any) {
      if (window.google) {
        callback();
        return true;
      }

      window.mapApiInitialized = callback;
      const script = document.createElement('script');
      // todo replace with token in build script
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBo5AFtMCUCakcoR3CnLuTr-6uygfCVkQk' +
        '&libraries=places&callback=mapApiInitialized';
      document.body.appendChild(script);
    };
    window.checkAndAttachMapScript(() => true);
  }

  private async initModules() {
    const promises = [];
    //TODO: save these commented sections to restore public features when we will be ready RE-7998
    // if (appModule.Features.length === 0) {
    //   promises.push(httpModule.getPublicFeatures());
    // }
    if (!profileModule.Profile) {
      promises.push(httpModule.getProfile());
    }

    await Promise.all(promises);
    await httpModule.getCompany();
    if (profileModule.Profile?.id)
      await httpModule.loadOperatorUser(profileModule.Profile.id);
  }

  async loadData() {
    this.initSidebar();
    await httpModule.getCodes();
    await httpModule.getVersion();
    if (companyModule.Organization?.ID !== undefined) {
      await httpModule.getContacts();
      await this.loadProducts();
      await this.manageDefaultSchedule();
    }
    // // todo call availability schedules
    await travelerTypeModule.configAgeClassification();
    this.configTimezones();
    this.configContactRoles();
    this.$root.$emit(constants.events.INIT_LOAD_COMPLETE);
  }

  async loadProducts() {
    await httpModule.loadProducts().then(() => {});
    await httpModule.getTravelerTypes();
    await httpModule.loadOptions();
    await httpModule.loadCompanyAddresses();
  }

  async manageDefaultSchedule() {
    await httpModule.getSchedules();
    const def = scheduleModule.Schedules.find(
      (schedule: GRSchedule) => schedule.name === this.defaultSchedule.name,
    );
    if (!def) {
      await httpModule.updateSchedule(this.defaultSchedule);
      scheduleModule.setDefaultSchedule({
        id: scheduleModule.Schedule.id,
        name: scheduleModule.Schedule.name,
        path: '',
        open: false,
      });
    } else {
      scheduleModule.setDefaultSchedule({
        ...def,
        open: false,
      });
    }
  }

  configTimezones() {
    appModule.configDisplayTime();
  }

  configContactRoles() {
    appModule.configureContactRoles();
  }

  private isAuthorized(item: any): boolean {
    if (item.ticketing && profileModule.IsTicketingEditRole) {
      return true;
    }

    if (item.beta && !profileModule.IsBetaUser) {
      return false;
    }

    if (!item.admin) {
      return true;
    }

    const checkAuth = (admin: string) => {
      switch (admin) {
        case 'REDEMPTION':
          return profileModule.IsRedemptionRole;
        case 'REPORT':
          return profileModule.IsReportUpcomingArrivalsRole;
        case 'REPORT_CUSTOMER_MANIFEST':
          return profileModule.IsReportCustomerManifestRole;
        case 'REPORT_ALL_BOOKINGS':
          return profileModule.IsReportAllBookingRole;
        case 'REPORT_REDEMPTIONS':
          return profileModule.IsReportRedemptionsRole;
        case 'CHANNEL':
          return profileModule.IsTicketingEditRole || profileModule.IsOrgAdmin;
        case 'ORG':
          return profileModule.IsOrgAdmin;
        case 'TECHNICAL':
          return profileModule.IsAdmin;
        default:
          return false;
      }
    };

    if (item.admin instanceof Array) {
      let result = false;
      (item.admin as Array<string>).forEach((admin) => {
        result = result || checkAuth(admin);
      });
      return result;
    }
    return checkAuth(item.admin);
  }

  ////////////////////////////////////////////////////////////////////////////// TODO

  private filteredSidebarItems: any = [];
  private filteredSidebarChildItems: any = {};
  private filteredSidebarSupportItems: any = [];
  private filteredSidebarSupportChildItems: any = {};
  private filteredSidebarNoGroupItems: any = [];
  private filteredSidebarAdminItems: any = [];
  private filteredSidebarHelpCenterItems: any = [];
  private filteredSidebarAdminChildItems: any = {};

  initSidebar() {
    this.filteredSidebarItems = [];

    this.filteredSidebarItems = connectivityManagementItems.filter((item) => {
      // console.log('connect', item);
      return this.isAuthorized(item);
    });

    this.filteredSidebarSupportItems = reportsItems.filter((item) => {
      return this.isAuthorized(item);
    });

    this.filteredSidebarNoGroupItems = noGroupItems.filter((item) => {
      return this.isAuthorized(item);
    });

    this.filteredSidebarAdminItems = adminItems.filter((item) => {
      return this.isAuthorized(item);
    });
    this.filteredSidebarHelpCenterItems = helpCenterItems;
  }

  get IsFullscreenPage() {
    return this.$route.name === constants.routes.BASE;
  }
}
