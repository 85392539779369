





























































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import AddUserModal from '@/components/user/AddUserModal.vue';
import { knownRoles, tablePaginationOptions } from '@/utils/helpers';
import { Account as UserProfile } from '@/api/wps/BackplaneModels';
import BulkActionUserModal from '@/components/user/BulkActionUserModal.vue';
import BulkActionDeleteUserModal from '@/components/user/BulkActionDeleteUserModal.vue';
import { constants } from '@/utils/constants';
import { profileModule } from '@/store/modules/moduleProfile';
import { userModule } from '@/store/modules/moduleUser';
import { httpModule } from '@/store/modules/moduleHttp';

@Component({
  components: {
    BulkActionUserModal,
    BulkActionDeleteUserModal,
    AddUserModal,
  },
})
export default class UserManagement extends Vue {
  /* DATA */

  // dialog appearance
  private activateUserModal = false;
  private addDialog = false;
  private bulkActionDialog = false;
  private bulkActionDeleteDialog = false;
  private bulkActionMessage = false;
  private deactivateUserModal = false;

  // locations
  private locationsList: string[] = ['Corporate']; // todo

  // other
  private search = '';

  private selectedUsers = [];
  private activateSelectedUser = null;
  private deactivateSelectedUser = null;

  private filterBoxActive = false;

  private page = 1;
  private pageCount = 10;
  private itemsPerPage = 25;
  private tableMdCols = 12;

  private bulkActions = [
    {
      text: 'Add Role',
      action: constants.ROLE_ADD,
      icon: 'mdi-account-multiple-plus',
      color: 'success',
    },
    {
      text: 'Remove Role',
      action: constants.ROLE_REMOVE,
      icon: 'mdi-account-multiple-minus',
      color: 'error',
    },
    {
      text: 'Deactivate User',
      action: constants.REMOVE_USER,
      icon: 'mdi-account-remove',
      color: 'error',
      disabled: 2,
    },
    {
      text: 'Activate User',
      action: constants.ACTIVATE_USER,
      icon: 'mdi-account-plus',
      color: '',
      disabled: 1,
    },
  ];

  private roleList = [
    {
      label: 'Admin',
      value: 'ORG_MGMT',
    },
    {
      label: 'Redemption',
      value: 'SUPPLIER_REDEMPTION_STAFF',
    },
    {
      label: 'View',
      value: 'SUPPLIER_BUSINESS_STAFF',
    },
    {
      label: 'Reporting',
      value: 'SUPPLIER_REPORTING_STAFF',
    },
  ];

  private locationList = [];

  private tableHeaders: Array<Record<string, any>> = [
    {
      text: this.$i18n.t('Global.FirstName'),
      value: 'given_name',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    {
      text: this.$i18n.t('Global.LastName'),
      value: 'surname',
      sortable: true,
      filterable: true,
      align: 'left',
    },
    // {
    //   text: this.$i18n.t('Link.Location'),
    //   value: 'location',
    //   sortable: true,
    //   filterable: true,
    //   width: '120px',
    // },
    // { text: 'User Name', value: 'username', sortable: true, filterable: true },
    {
      text: this.$i18n.t('Global.Email'),
      value: 'email',
      sortable: true,
      filterable: true,
    },
    {
      text: this.$i18n.t('UserManagementPage.Permissions'),
      value: 'groups',
      sortable: false,
      filterable: false,
      width: '120px',
      class: 'not-sorting-header',
    },
    {
      text: '',
      value: 'action',
      sortable: false,
      filterable: false,
      class: 'not-sorting-header',
    },
  ];

  private messageType = {
    message: '',
    type: 'success',
  };

  /* COMPUTED */

  get IsEditAccess(): boolean {
    return profileModule.IsOrgAdmin;
  }

  get Users(): any[] {
    return userModule.Users;
  }

  get FilteredUsers(): any[] {
    return userModule.Users.filter((user) => {
      return this.SelectedRoles.length === 0
        ? true
        : user &&
            Array.isArray(user.groups) &&
            user.groups.findIndex((group: any) =>
              this.SelectedRoles.includes(group.name),
            ) > -1;
    });
  }

  get Loading(): boolean {
    return userModule.Loading;
  }

  get SelectedPage(): number {
    return userModule.UserManagementPage;
  }

  set SelectedPage(page: number) {}

  get SelectedUserStatus(): number {
    return userModule.UserManagementSelectStatus;
  }

  set SelectedUserStatus(status: number) {}

  get SelectedRoles(): Array<string> {
    return userModule.UserManagementSelectedRole;
  }

  set SelectedRoles(roles: Array<string>) {}

  get SelectedLocations(): Array<string> {
    return userModule.UserManagementSelectedLocations;
  }

  set SelectedLocations(locations: Array<string>) {}

  get paginationOptions() {
    return tablePaginationOptions(this.FilteredUsers);
  }
  /* HOOKS */

  private async created() {
    await httpModule.getUsers();
  }

  private mounted() {
    userModule.checkUserManagementPage();
    userModule.checkUserManagementSelectedRole();
  }

  /* METHODS */

  // event handlers

  private viewDetailsAndEdit(item: any) {
    if (!this.IsEditAccess) return;

    try {
      userModule.chooseUserToUpdate(item.i);
      this.$router.push({ name: 'user-details', params: { id: item.id } });
    } catch {
      // todo send error to backend
    }
  }

  private viewDetails(item: any) {
    try {
      userModule.chooseUserToUpdate(item.i);
      this.$router.push({ name: 'user-details-view', params: { id: item.id } });
    } catch {
      // todo send error to backend
    }
  }

  private removeUser(item: any) {
    if (!this.IsEditAccess) return;

    try {
      userModule.chooseUserToUpdate(item.i);
      httpModule.deactivateUser().then(() => {
        // this.changeSelectedUserStatus(this.selectedUserStatus);
        // httpModule.toggleUserStatus();
        this.deactivateSelectedUser = null;
        this.closeDeactivateUserModal();
      });
    } catch {
      // todo send error to backend
      this.deactivateSelectedUser = null;
      this.closeDeactivateUserModal();
    }
  }

  private activateUser(item: any) {
    if (!this.IsEditAccess) return;

    try {
      userModule.chooseUserToUpdate(item.i);
      httpModule.activateUser().then(() => {
        // this.changeSelectedUserStatus(this.selectedUserStatus);
        // httpModule.toggleUserStatus();
        this.activateSelectedUser = null;
        this.closeActivateUserModal();
      });
    } catch {
      // todo send error to backend
      this.activateSelectedUser = null;
      this.closeActivateUserModal();
    }
  }

  private async createUser(user: UserProfile) {
    if (!this.IsEditAccess) return;

    try {
      user.org_code = profileModule.Profile?.org_code;
      user.department = 'UNKNOWN';
      await httpModule.createUser(user);
    } catch {
      // todo send error to backend
    }
  }

  private selectAction(action: string) {
    userModule.setBulkAction(action);
    userModule.setSelectedUsers(this.selectedUsers);
    if (
      userModule.BulkAction === constants.REMOVE_USER ||
      userModule.BulkAction === constants.ACTIVATE_USER
    ) {
      this.bulkActionDeleteDialog = true;
    } else {
      this.bulkActionDialog = true;
    }
  }

  private deleteComplete(message: any) {
    this.messageType = message;
    this.bulkActionMessage = true;
    this.bulkActionDeleteDialog = false;
    this.selectedUsers = [];
  }

  private bulkActionComplete(message: any) {
    this.messageType = message;
    this.bulkActionMessage = true;
    this.bulkActionDialog = false;
    this.selectedUsers = [];
  }

  private openActivateUserModal(item: any) {
    this.activateUserModal = true;
    this.activateSelectedUser = item;
  }

  private openDeactivateUserModal(item: any) {
    this.deactivateUserModal = true;
    this.deactivateSelectedUser = item;
  }

  private closeActivateUserModal() {
    this.activateUserModal = false;
  }

  private closeDeactivateUserModal() {
    this.deactivateUserModal = false;
  }

  private filterRole(roles: Array<any>) {
    userModule.updateUserManagementSelectedRole(roles);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private filterLocations(locations: Array<any>) {
    // this.uiModule.UpdateUserManagementSelectedRole(roles);
  }

  private toggleFilterBox() {
    this.filterBoxActive = !this.filterBoxActive;
    this.toggleTableMdCols();
  }

  private toggleTableMdCols() {
    if (this.filterBoxActive) {
      this.tableMdCols = 9;
    } else {
      //delaying table cols to prevent redraw flicker
      setTimeout(() => {
        this.tableMdCols = 12;
      }, 300);
    }
  }

  // template helpers

  private filteredGroups(groups: Array<any>): Array<any> {
    try {
      if (groups && groups.length && groups.length > 0) {
        return groups
          .filter((group) => {
            return !!knownRoles.find((v) => v === group.name);
          })
          .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      }
    } catch (e) {
      // todo send error to backend
    }
    return [];
  }

  private async changeSelectedUserStatus(status: number) {
    if (!this.Loading) {
      try {
        await httpModule.getUsers(status);
        userModule.setUserManagementSelectedStatus(status);
      } catch (e) {
        // todo send error to backend
      }
    }
  }

  private updatePage(page: number) {
    userModule.updateUserManagementPage(page);
  }

  private clearSelected() {
    this.selectedUsers = [];
  }

  private resetFilters() {
    userModule.resetUserManagement();
    this.selectedUsers = [];
    this.search = '';
    if (this.SelectedUserStatus > 0) {
      this.changeSelectedUserStatus(0);
    }
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }
}
