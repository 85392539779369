







































































































































































import { Component, Vue } from 'vue-property-decorator';
import { companyModule } from '@/store/modules/moduleCompany';
import { productModule } from '@/store/modules/moduleProduct';
import { requiredFieldRules } from '@/utils/validation-rules';
import _ from 'lodash';
import { httpModule } from '@/store/modules/moduleHttp';
import { Product, TripRoute } from '@/models';
import { constants } from '@/utils/constants';
import { timezoneByCountry } from '@/app-codes/timezones';

@Component
export default class ProductViewLocationModal extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private timeZones = timezoneByCountry;
  private product = {} as Product;
  private tripRoute = {
    departure: '',
    arrival: '',
    stops: [] as Array<string>,
  } as TripRoute;

  mounted() {
    this.$root.$on(
      constants.events.PRODUCT_LOCATION_EDIT_MODAL_CLEAR_VALIDATION,
      this.clearValidateState,
    );
  }

  destroyed() {
    this.$root.$off(constants.events.PRODUCT_LOCATION_EDIT_MODAL_CLEAR_VALIDATION);
  }

  clearValidateState() {
    if (this.$refs?.locationForm) {
      const locationForm: any = this.$refs.locationForm;
      locationForm.reset();
    }
  }

  validate() {
    if (this.$refs?.locationForm) {
      const locationForm: any = this.$refs.locationForm;
      const isValid = locationForm.validate();
      if (isValid) {
        this.updateProduct();
      }
    }
  }

  closeEditModal() {
    productModule.clearEditProductLocationForm();
    productModule.setEditProductLocationModal(false);
  }

  async updateProduct() {
    const map = new Map(Object.entries(this.LocationForm));

    this.product = _.cloneDeep(this.Product);
    this.product.addressId = String(map.get('addressId'));
    this.product.timeZone = String(map.get('timezone'));
    const productChanged = !_.isEqual(this.Product, this.product);

    // todo save
    if (productChanged) {
      productModule.setProduct(this.product);
      await httpModule
        .updateAddProduct()
        .then(() => {})
        .catch(() => {
          // todo send alert
        });
    }
    await this.updateTripRoute();
  }

  async updateTripRoute() {
    const map = new Map(Object.entries(this.LocationForm));

    this.tripRoute = Object(map.get('tripRoute'));

    if (
      this.tripRoute &&
      this.tripRoute.departure &&
      _.isString(this.tripRoute.departure) &&
      this.tripRoute.departure.length > 0 &&
      this.tripRoute.arrival &&
      _.isString(this.tripRoute.arrival) &&
      this.tripRoute.arrival.length > 0
    ) {
      productModule.setTripRoute(this.tripRoute);
      await httpModule
        .updateProductTripRoute()
        .then(() => {})
        .catch();
    } else {
      await httpModule.deleteProductTripRoute().then();
    }
    this.closeEditModal();
  }

  get Product() {
    return productModule.Product;
  }

  get TripRoute() {
    return productModule.TripRoute;
  }

  get HasArrivalDeparture() {
    return (
      (this.LocationForm &&
        this.LocationForm.tripRoute &&
        _.isString(this.LocationForm.tripRoute.departure) &&
        this.LocationForm.tripRoute.departure.length > 0) ||
      (this.LocationForm &&
        this.LocationForm.tripRoute &&
        _.isString(this.LocationForm.tripRoute.arrival) &&
        this.LocationForm.tripRoute.arrival.length > 0)
    );
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get CountryTimezones() {
    const address = this.Addresses.find((a) => a.id === this.LocationForm.addressId);
    if (address) {
      // checking for both 2 and 3-letter country code
      let tz = this.timeZones.filter((t) => t.countryCodeIso === address.countryCode);
      if (tz.length === 0) {
        tz = this.timeZones.filter((t) => t.countryCode === address.countryCode);
      }

      if (tz && tz.length > 0) {
        // todo replace sort with helper function
        return tz.sort((a, b) => {
          const upperA =
            `(GMT${a?.offset?.standard}) ${a?.name} Time`.toUpperCase() || '';
          const upperB =
            `(GMT${b?.offset?.standard}) ${b?.name} Time`.toUpperCase() || '';

          if (upperA < upperB) {
            return -1;
          }

          if (upperA > upperB) {
            return 1;
          }

          return 0;
        });
      }
    }
    return this.timeZones.sort((a, b) => {
      const upperA = `(GMT${a?.offset?.standard}) ${a?.name} Time`.toUpperCase() || '';
      const upperB = `(GMT${b?.offset?.standard}) ${b?.name} Time`.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get LocationForm(): any {
    return productModule.EditProductLocationForm;
  }

  set LocationForm(form: any) {
    productModule.setEditProductLocationForm(form);
  }

  get EditProductLocationFormState() {
    return productModule.EditProductLocationFormState;
  }

  set EditProductLocationFormState(state: boolean) {
    productModule.setEditProductLocationFormState(state);
  }

  get Loading() {
    return productModule.Loading;
  }
}
