import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import dinero from 'dinero.js';
import _ from 'lodash';
import { ProductStatus } from '@/models';

export const emptyDash = function (value: string) {
  return value && value.length > 0 ? value : '—';
};

export const emptyNone = function (value: string) {
  return value && value.length > 0 ? value : 'None';
};

export const emptyNotProvided = function (value: string) {
  return value && _.isString(value) && value.trim().length > 0 ? value : 'Not provided';
};

export const emptyOngoing = function (value: string) {
  return value && value.length > 0 ? value : 'Ongoing';
};

export const emptyNumberNotProvided = function (value: number) {
  return value && _.isNumber(value) ? value : 'Not provided';
};

export const truncate = function (value: string, length: number) {
  const len = length || 10;
  if (!value || typeof value !== 'string') return '';
  if (value.length <= len) return value;
  return `${value.substring(0, len)}...`;
};

export const numberToCurrency = function (amount: number | string) {
  if (amount && typeof amount === 'number') {
    try {
      const iAmount = _.toInteger(amount * 100);
      return dinero({ amount: iAmount, currency: 'USD' }).toFormat('0,0.00');
    } catch (e) {
      // todo alert to backend
      console.log('num currency err', e);
    }
  } else if (amount && typeof amount === 'string') {
    try {
      const amountNumeric = _.toNumber(amount);
      const iAmount = _.toInteger(amountNumeric * 100);
      return dinero({ amount: iAmount, currency: 'USD' }).toFormat('0,0.00');
    } catch (e) {
      // todo alert backend
      console.log('string currency err', e);
    }
  }
  return amount;
};

export const minorNumberToCurrencyNumber = function (amount: number) {
  if (amount) {
    const amountNumber = _.toNumber(amount);
    return (amountNumber * 0.01).toFixed(2);
  }
  return amount;
};

export const phoneFormat = function (phone: string) {
  try {
    const phoneNumber = parsePhoneNumber(`${phone}`, 'US' as CountryCode);
    return phoneNumber.formatNational();
  } catch {
    return phone;
  }
};

export const productStatusName = function (value: number) {
  if (value && _.isNumber(value)) {
    switch (value) {
      case ProductStatus.ACTIVE:
        return 'ACTIVE';
      case ProductStatus.INACTIVE:
        return 'INACTIVE';
      case ProductStatus.DRAFT:
        return 'DRAFT';
      case ProductStatus.PENDING:
        return 'PENDING';
      default:
        return 'UNKNOWN';
    }
  }
  return value;
};

export const underscoreToSpace = function (value: string) {
  return value && _.isString(value) ? value.replaceAll('_', ' ') : '';
};
