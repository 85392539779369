







































































































import { Component, Vue } from 'vue-property-decorator';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import { productModule } from '@/store/modules/moduleProduct';
import { constants } from '@/utils/constants';
import RdmMessage from '@/components/common/alert/RdmMessage.vue';
import { httpModule } from '@/store/modules/moduleHttp';
import { tablePaginationOptions } from '@/utils/helpers';
import { Product } from '@/models';
import { companyModule } from '@/store/modules/moduleCompany';

@Component({
  components: {
    RdmMessage,
    RdmStatusIndicator,
  },
})
export default class ProductListingSummary extends Vue {
  private productToRemove = {} as Product;
  private modalIsOpen = false;

  private routerProductDetailsName = constants.routes.PRODUCT_DETAILS;
  private routerProductCreate = constants.routes.PRODUCT_CREATE;

  private productHeaders: Array<Record<string, any>> = [
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'internalName',
    },
    {
      text: 'Primary Location',
      align: 'left',
      sortable: false,
      value: 'addressId',
      filterable: false,
    },
    { text: '', value: 'view', align: 'right', sortable: false, width: 300 },
  ];

  mounted() {
    productModule.clearState();
    httpModule.loadProducts();
  }

  convertAddressIdToName(id: string): string {
    if (id && id.length > 0) {
      const address = this.Addresses.find((a) => a.id === id);
      if (address && address.name && address.name.length > 0) {
        return address.name;
      }
    }
    return '';
  }

  private openModal(product: Product) {
    this.productToRemove = product;
    this.modalIsOpen = true;
  }

  private closeModal() {
    this.modalIsOpen = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filterProducts(value: string, search: string, item: any) {
    return (
      value != null &&
      search != null &&
      typeof value === 'string' &&
      value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }

  async proceedRemoveProduct() {
    this.closeModal();
    await httpModule.removeProduct(this.productToRemove?.id);
    await httpModule.loadProducts();
  }

  get loading(): boolean {
    return productModule.Loading;
  }

  get productsList(): Array<Product> {
    return productModule.Products;
  }

  get Addresses() {
    return companyModule.Addresses;
  }

  get productSearch() {
    return productModule.SearchText;
  }

  set productSearch(search: string) {
    productModule.setSearchText(search);
  }
  get paginationOptions() {
    return tablePaginationOptions(this.productsList);
  }
}
