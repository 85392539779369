/*= ========================================================================================
  Description: Sidebar Items list. Add / Remove menu items from here.
  Structure:
    url     => router path
    name    => name to display in sidebar
    slug    => router path name
    icon    => Material Icon component/icon name
    tag     => text to display on badge
    tagColor  => class to apply on badge element
    submenu   => submenu of current item (current item will become dropdown )
    isDisabled  => disable sidebar item/group
    admin    => role required to view sidebar. Can be one of a set of values {REDEMPTION, REPORT, ORG, TECHNICAL}
    beta    => role required to view sidebar. Can only be RC_CONNECTIVITY_MANAGEMENT

  Roles:
    REDEMPTION    => Users with SUPPLIER_REDEMPTION_STAFF
    REPORT        => Users with SUPPLIER_REPORTING_STAFF
    CHANNEL       => Users with REDEAM_CENTRAL_TICKETING_VIEW or ORG_MGMT
    ORG           => Users with ORG_MGMT or REDEAM_TECHNICAL_STAFF
    TECHNICAL     => Users with REDEAM_TECHNICAL_STAFF
========================================================================================== */

import { constants } from '@/utils/constants';

export const connectivityManagementItems = [
  {
    id: 'dc4725f0-0b31-485b-bbdb-4872a53c4bb3',
    url: '/company',
    name: 'Company',
    slug: 'company',
    icon: 'mdi-office-building',
    i18n: 'Company',
    beta: true,
    feature: constants.features.SIDEBAR_COMPANY_ITEM,
  },
  {
    id: '1476bc59-9d1a-4c0b-80ad-b625b9973530',
    url: '/locations',
    name: 'Locations',
    slug: 'location',
    icon: 'mdi-map-marker',
    i18n: 'Location',
    beta: true,
    feature: constants.features.SIDEBAR_COMPANY_ITEM,
  },
  {
    id: 'ebfbdcf3-c147-4027-b1a7-2ecf9cfebea7',
    url: '/products',
    name: 'Products',
    slug: 'products',
    icon: 'mdi-ticket',
    i18n: 'ProductsListing',
    wip: true,
    beta: true,
    feature: constants.features.SIDEBAR_PRODUCT_ITEM,
  },
  {
    id: 'cbfbdcf3-c147-4027-b1a7-2ecf9cfebea7',
    url: '/prices',
    name: 'Pricing & Inventory',
    slug: 'inventory',
    icon: 'mdi-archive',
    i18n: 'Inventory',
    wip: true,
    beta: true,
    feature: constants.features.SIDEBAR_PRODUCT_ITEM,
  },
  {
    id: 'fcf2da2e-3ace-44db-9329-46b2bc3a62bb',
    url: '/channels',
    name: 'Channels',
    slug: 'Channels',
    icon: 'mdi-store',
    i18n: 'Channels',
    wip: true,
    beta: true,
    ticketing: true,
    admin: 'CHANNEL',
    feature: constants.features.SIDEBAR_CHANNEL_ITEM,
  },
];
export const reportsItems = [
  {
    id: 'f22f21e7-2c5c-4c79-b6d7-cf2487716c00',
    url: '/reports/all-bookings',
    name: 'All Bookings',
    slug: 'reports',
    icon: 'mdi-chart-box',
    i18n: 'AllReports',
    feature: constants.features.SIDEBAR_REPORTS_GROUP_DAILY_OP_ITEM,
    admin: 'REPORT_ALL_BOOKINGS',
  },
  {
    id: '7c3609dc-c073-43b0-b1f5-f1f0159ac014',
    url: '/reports/manifest',
    name: 'Customer Manifest',
    slug: 'reports',
    icon: 'mdi-chart-box',
    i18n: 'Reports',
    feature: constants.features.SIDEBAR_REPORTS_GROUP_DAILY_OP_ITEM,
    admin: 'REPORT_CUSTOMER_MANIFEST',
  },
  {
    id: '6a0e8bb8-1190-433c-8778-3a87bd7bf5c9',
    url: '/reports/redemptions',
    name: 'Redemptions',
    slug: 'reports',
    icon: 'mdi-chart-box',
    i18n: 'RedemptionReports',
    feature: constants.features.SIDEBAR_REPORTS_GROUP_REDEMPTION_ITEM,
    admin: 'REPORT_REDEMPTIONS',
  },
  {
    id: 'cf152e2d-8b46-4457-932b-8e0242950c6d',
    url: '/reports/arrivals',
    name: 'Upcoming Arrivals',
    slug: 'reports',
    icon: 'mdi-chart-box',
    i18n: 'ArrivalReports',
    feature: constants.features.SIDEBAR_REPORTS_GROUP_ARRIVAL_ITEM,
    admin: 'REPORT',
  },
];
// 2d79a204-1b57-4fda-b2b2-a2afba0588c6

export const noGroupItems = [
  {
    id: '938520f2-2134-47b9-9c1b-1deea6d7f799',
    url: '/redemption',
    name: 'Redemption',
    slug: 'redemption',
    icon: 'mdi-barcode-scan',
    i18n: 'WebRedemption',
    feature: constants.features.SIDEBAR_WEB_REDEMPTION_ITEM,
    admin: 'REDEMPTION',
  },
];

export const adminItems = [
  {
    id: 'd55fd1f3-4585-4c8c-9bd3-60d788cc933d',
    url: '/users',
    name: 'User Management',
    slug: 'users',
    icon: 'mdi-account-cog',
    i18n: 'UserManagement',
    wip: false,
    feature: constants.features.SIDEBAR_USER_ITEM,
  },
];

export const helpCenterItems = [
  {
    id: '2483f972-286a-442d-baaa-3573de828447',
    url: 'https://redeam-2763550.hs-sites.com/knowledge',
    name: 'Help Center',
    slug: 'hc',
    icon: 'mdi-help',
    i18n: 'HelpCenter',
    wip: false,
    feature: constants.features.SIDEBAR_HELP_CENTER_FEATURE_ITEM,
  },
];

export const sidebarItems = [
  // {
  //   id: 'd3ad6056-f074-406f-89df-a75419e2a477',
  //   url: '/home',
  //   name: 'Home',
  //   slug: 'home',
  //   icon: 'mdi-home',
  //   i18n: 'Home',
  //   feature: null,
  // },
  {
    id: 'dc4725f0-0b31-485b-bbdb-4872a53c4bb3',
    url: '/',
    name: 'Company',
    slug: 'company',
    icon: 'mdi-office-building',
    i18n: 'Company',
    beta: true,
    feature: constants.features.SIDEBAR_COMPANY_ITEM,
  },
  {
    id: 'dc4725f0-0b31-485b-bbdb-4872a53c4bb4',
    url: '/locations',
    name: 'Locations',
    slug: 'location',
    icon: 'mdi-office-building',
    i18n: 'Location',
    beta: true,
    feature: constants.features.SIDEBAR_COMPANY_ITEM,
  },
  {
    id: 'ebfbdcf3-c147-4027-b1a7-2ecf9cfebea7',
    url: '/products',
    name: 'Products',
    slug: 'products',
    icon: 'mdi-ticket',
    i18n: 'ProductsListing',
    wip: true,
    beta: true,
    feature: constants.features.SIDEBAR_PRODUCT_ITEM,
  },
  {
    id: 'cbfbdcf3-c147-4027-b1a7-2ecf9cfebea7',
    url: '/inventory',
    name: 'Pricing & Inventory',
    slug: 'inventory',
    icon: 'mdi-bag-checked',
    i18n: 'Inventory',
    wip: true,
    beta: true,
    feature: constants.features.SIDEBAR_PRODUCT_ITEM,
  },
  {
    id: '938520f2-2134-47b9-9c1b-1deea6d7f799',
    url: '/redemption',
    name: 'Redemption',
    slug: 'redemption',
    icon: 'mdi-barcode-scan',
    i18n: 'WebRedemption',
    feature: constants.features.SIDEBAR_WEB_REDEMPTION_ITEM,
    admin: 'REDEMPTION',
  },
  {
    id: 'd55fd1f3-4585-4c8c-9bd3-60d788cc933d',
    url: '/users',
    name: 'Users',
    slug: 'users',
    icon: 'mdi-account-group',
    i18n: 'UserManagement',
    wip: false,
    feature: constants.features.SIDEBAR_USER_ITEM,
  },
  {
    id: 'fb4b139a-63f1-4e28-b5e7-acf49ecf9a70',
    url: null,
    name: 'Reports',
    slug: 'reports',
    icon: 'mdi-file-chart',
    i18n: 'Reports',
    feature: constants.features.SIDEBAR_REPORTS_GROUP_ITEM,
    children: true,
    admin: 'REPORT',
  },
];

export const sidebarSupportItems = [
  {
    id: 'ec6d2b07-eec7-439e-80e5-24eb3bf2b78d',
    url: '/groupon',
    name: 'Groupon',
    slug: 'groupon',
    icon: 'mdi-earth',
    i18n: 'Groupon',
    wip: true,
    feature: constants.features.SIDEBAR_GROUPON_GROUP_ITEM,
    children: true,
    beta: true,
    admin: 'TECHNICAL',
  },
];

export const sidebarSupportChildItems = new Map<string, Array<any>>().set(
  'ec6d2b07-eec7-439e-80e5-24eb3bf2b78d',
  [
    {
      id: '9d3bdfd9-0e95-4654-9e2f-68f57f380fba',
      url: '/groupon/pricing',
      name: 'Pricing',
      slug: 'admin',
      icon: 'mdi-currency-usd',
      i18n: 'SupplementalPricing',
      beta: true,
      feature: constants.features.SIDEBAR_GROUPON_GROUP_PRICING_ITEM,
      admin: 'TECHNICAL', // todo: create role for accessing Groupon items
    },
  ],
);

export const sidebarAdminItems = [
  {
    id: '605ab910-caca-4d56-9402-d12e25e94270',
    url: null,
    name: 'Admin',
    slug: 'admin',
    icon: 'mdi-cog',
    i18n: 'Admin',
    beta: true,
    feature: constants.features.SIDEBAR_ADMIN_GROUP_ITEM,
    admin: 'TECHNICAL',
    children: true,
  },
];

export const sidebarAdminChildItems = new Map<string, Array<any>>().set(
  '605ab910-caca-4d56-9402-d12e25e94270',
  [
    {
      id: '3983302c-0d01-4287-b903-a7b52b906c22',
      url: '/features',
      name: 'Feature Management',
      slug: 'admin',
      icon: 'mdi-cog',
      i18n: 'Feature',
      beta: true,
      feature: constants.features.SIDEBAR_ADMIN_GROUP_ORG_FEATURE_ITEM,
      admin: 'TECHNICAL',
    },
    {
      id: 'e6e52780-2a77-40d6-8599-c6d0169c1d97',
      url: '/admin',
      name: 'App Management',
      slug: 'feature',
      icon: 'mdi-cogs',
      i18n: 'AppAdmin',
      beta: true,
      feature: constants.features.SIDEBAR_ADMIN_GROUP_APP_FEATURE_ITEM,
      admin: 'TECHNICAL',
    },
    {
      id: 'e6e52780-2a77-40d6-8599-c6d0169c1d96', // todo move to admin group
      url: '/admin/company',
      name: 'Company Management',
      slug: 'feature',
      icon: 'mdi-cogs',
      i18n: 'AppAdmin',
      beta: true,
      feature: constants.features.SIDEBAR_ADMIN_GROUP_APP_FEATURE_ITEM,
      admin: 'TECHNICAL',
    },
  ],
);
