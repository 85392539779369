












































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { requiredFieldRules, validWholeNumbersRules } from '@/utils/validation-rules';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import { optionModule } from '@/store/modules/moduleOption';
import { handleKeyDownOnlyWholeNumeric } from '@/utils/helpers';

@Component({
  components: {
    RdmCollapsableCard,
  },
})
export default class OptionCreatePricingSet extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private validWholeNumbersRules = validWholeNumbersRules;
  private handleKeyDown = handleKeyDownOnlyWholeNumeric;
  private openOptionPricing = false;
  private createOptionPricingForm = false;
  private step = 1;
  private saving = false;
  private selectedTravelers = [];
  private selectedSchedule = '';
  private validFrom = '';
  private validUntil = '';
  private selectedDateTimes = [] as Array<any>;
  private dateTimes = [
    {
      id: '1002',
      time: 'Sunday',
      times: [
        { id: 2, day: 'Sunday', time: '08:00 - 09:00' },
        { id: 3, day: 'Sunday', time: '09:00 - 10:00' },
      ],
    },
    {
      id: '1003',
      time: 'Monday',
      times: [{ id: 4, day: 'Monday', time: '11:00 - 13:00' }],
    },
  ];

  toggleOptionPricing() {
    this.openOptionPricing = !this.openOptionPricing;
  }

  discard() {
    this.$router.push({ name: constants.routes.OPTION_LIST }).catch();
  }

  nextStepValidate4(done: boolean) {
    if (this.$refs?.pricingForm) {
      const form: any = this.$refs.pricingForm;
      const isValid = form.validate();
      if (isValid) {
        if (done) {
          // this.addOptionDetails();
        } else {
          // this.step4NextStage();
        }
      }
    }
  }

  toggleAllTimes(selectAll: any) {
    this.selectedDateTimes = [];
    if (selectAll) {
      this.dateTimes.forEach((d) => {
        if (d && d.times && Array.isArray(d.times)) {
          this.selectedDateTimes.push(...d.times);
        }
      });
    }
  }

  removeSelectedTraveler(id: string) {
    if (this.selectedTravelers && Array.isArray(this.selectedTravelers)) {
      const i = this.selectedTravelers.findIndex((index) => index === id);
      if (i > -1) {
        this.selectedTravelers.splice(i, 1);
      }
    }
  }

  closeAddPricingSet() {
    optionModule.setCreateModeOptionCreatePricingSet(false);
  }

  addAndNewPricingSet() {
    optionModule.appendPricingSetAndNext();
    // // todo add to pricing set list
    // optionModule.setCreateModeOptionCreatePricingSet(false);
    // // optionModule.setCreateModeOptionCreatePricingSet(true);
  }

  unitIdToTravelerName(id: string) {
    const i = this.TravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.TravelerTypes[i].text;
    }
    return '';
  }

  get HasOwnData() {
    return true;
    // return (
    //     this.shareLocation === 'yes' ||
    //     this.sharePolicy === 'yes' ||
    //     this.sharePricing === 'yes' ||
    //     this.shareSchedule === 'yes'
    // );
  }

  get TravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get CountAllTimes() {
    let count = 0;
    this.dateTimes.forEach((d) => {
      if (d && d.times && Array.isArray(d.times)) {
        count += d.times.length;
      }
    });
    return count;
  }

  get Schedules() {
    return [];
  }
}
