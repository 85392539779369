













































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { offerModule } from '@/store/modules/moduleOffer';
@Component({
  components: { RdmCollapsableCard, RdmCollapsableControlCard },
})
export default class OfferEditSchedules extends Vue {
  private scheduleOpen = true;
  private dayTimeExpand = [0];

  get Offer() {
    return offerModule.Offer;
  }

  get OfferPricing() {
    return offerModule.OfferPricing;
  }

  get Schedule() {
    return scheduleModule.Schedule;
  }

  toggleOpen() {
    this.scheduleOpen = !this.scheduleOpen;
  }

  get SelectedSchedule() {
    if (!this.OfferPricing.id || this.OfferPricing.timeSlots === null) return [];
    const days = [] as Array<string>;
    let timeSlots = [] as any;
    const structured = [] as any;
    this.OfferPricing.timeSlots.map((item) => {
      if (!days.includes(item.weekday)) {
        days.push(item.weekday);
      }
    });
    days.map((day) => {
      this.OfferPricing.timeSlots.map((item) => {
        if (item.weekday === day) {
          item.editMode = true;
          timeSlots.push(item);
        }
      });
      structured.push({
        weekday: day,
        selectedAll: true,
        timeSlots: timeSlots,
      });
      timeSlots = [];
    });
    return structured;
  }
}
