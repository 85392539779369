

















import { Component, Vue } from 'vue-property-decorator';
import { companyModule } from '@/store/modules/moduleCompany';
import { offerModule } from '@/store/modules/moduleOffer';
import PrimaryLocationSection from '@/components/product/detail/components/locations/PrimaryLocationSection.vue';
@Component({
  components: { PrimaryLocationSection },
})
export default class OfferEditLocation extends Vue {
  get Product() {
    return offerModule.Product;
  }

  convertAddressIdToAddress(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.streetAddress && address.streetAddress.length > 0) {
        return `${address.streetAddress}, ${address.city}, ${address.region} ${address.postalCode}`;
      }
    }
    return '';
  }

  convertAddressIdToName(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.name && address.name.length > 0) {
        return address.name;
      }
    }
    return '';
  }

  convertAddressIdToLatLong(id: string) {
    if (id && id.length > 0) {
      const address = companyModule.Addresses.find((a) => a.id === id);
      if (address && address.latitude && address.longitude) {
        return `${address.latitude}, ${address.longitude}`;
      }
    }
    return '';
  }
}
