import { codes } from '@/utils/codeConstants';
import { CodeAction } from '@/models';

/**
 * Event models for displaying popups to the user when events occur. User the following model to
 * feed into the event snackbars displayed to the user.
 *
 * Add a new event with the following model
 * Key: Use a code from the codeConstants.ts file
 * value:
 * - message (Required) - default string message displayed in the snackbar
 * - retryable (Optional) - if true, will display a "Retry" button
 * - viewable (Optional) - if true, will display a "View" button
 * - undoable (Optional) - if true, will display a "Undo" button
 * - hasHelp (Optional) - if true, will display a help Icon button
 * - helpID (Optional) - if hasHelp is set to true, you can be used to display an onscreen hint
 * - helpLink  (Optional) - if hasHelp is set to true, you can put an http string to launch an external help page.
 * Takes priority over helpID
 *
 */

const c = new Map<number, CodeAction>();

/**
 * Profile
 * Event codes 10000 - 10999
 */
c.set(codes.PROFILE_ERROR_UNAUTHORIZED, {
  message: 'Unauthorized to view page',
});
/**
 * User
 * Event codes 11000 - 11999
 */
c.set(codes.USER_LOAD, {
  message: 'Loaded user',
});
c.set(codes.USER_ACTIVATED, {
  message: 'Activated user',
  viewable: true,
});
c.set(codes.USER_DEACTIVATED, {
  message: 'Deactivated user',
  undoable: true,
});
c.set(codes.USER_UPDATED, {
  message: 'Updated User',
});
c.set(codes.USER_DELETED, {
  message: 'Deleted user',
});
c.set(codes.USER_CREATED, {
  message: 'Created user',
  viewable: true,
});
c.set(codes.USER_ERROR_500_LOAD_USER, {
  message: 'Unable to load user',
});
c.set(codes.USER_ERROR_500_CREATE_USER, {
  message: 'Unable to create user',
});
c.set(codes.USER_ERROR_404_UPDATE_USER, {
  message: 'Unable to update user',
});
c.set(codes.USER_ERROR_500_UPDATE_USER, {
  message: 'Unable to update user',
});
c.set(codes.USER_ERROR_404_DELETE_USER, {
  message: 'Unable to delete user',
});
c.set(codes.USER_ERROR_500_DELETE_USER, {
  message: 'Unable to delete user',
});
c.set(codes.USER_ERROR_500_ACTIVATED_USER, {
  message: 'Unable to activate user',
});
c.set(codes.USER_ERROR_500_DEACTIVATED_USER, {
  message: 'Unable to deactivate user',
});
/**
 * Company
 * Event codes 20000 - 20999
 */
c.set(codes.COMPANY_LOAD, {
  message: 'Loaded company',
});
c.set(codes.COMPANY_CREATED, {
  message: 'Added company',
});
c.set(codes.COMPANY_UPDATED, {
  message: 'Updated company',
});
c.set(codes.COMPANY_DELETED, {
  message: 'Removed company',
});
c.set(codes.COMPANY_LOAD_ADDRESS, {
  message: 'Loaded addresses',
});
c.set(codes.COMPANY_LOAD_SUPPLIER_ADDRESS, {
  message: 'Loaded addresses',
});
c.set(codes.COMPANY_LOAD_PRODUCT_ADDRESS, {
  message: 'Loaded addresses',
});
c.set(codes.COMPANY_CREATE_COMPANY_ADDRESS, {
  message: 'Added address',
});
c.set(codes.COMPANY_UPDATE_COMPANY_ADDRESS, {
  message: 'Updated address',
});
c.set(codes.COMPANY_DELETE_COMPANY_ADDRESS, {
  message: 'Removed address',
});
c.set(codes.COMPANY_CREATE_SUPPLIER_ADDRESS, {
  message: 'Added address',
});
c.set(codes.COMPANY_CREATE_SUPPLIER_BULK_ADDRESS, {
  message: 'Added addresses',
});
c.set(codes.COMPANY_UPDATE_SUPPLIER_ADDRESS, {
  message: 'Updated address',
});
c.set(codes.COMPANY_DELETE_SUPPLIER_ADDRESS, {
  message: 'Removed address',
});
c.set(codes.COMPANY_CREATE_PRODUCT_ADDRESS, {
  message: 'Added address',
});
c.set(codes.COMPANY_UPDATE_PRODUCT_ADDRESS, {
  message: 'Updated address',
});
c.set(codes.COMPANY_DELETE_PRODUCT_ADDRESS, {
  message: 'Removed address',
});
c.set(codes.COMPANY_ERROR_404_LOAD_COMPANY, {
  message: 'Company data not configured',
});
c.set(codes.COMPANY_ERROR_500_LOAD_COMPANY, {
  message: 'Unable to load company',
  retryable: true,
});
c.set(codes.COMPANY_ERROR_500_CREATE_COMPANY, {
  message: 'Unable to create',
});
c.set(codes.COMPANY_ERROR_404_UPDATE_COMPANY, {
  message: 'Unable to update',
});
c.set(codes.COMPANY_ERROR_500_UPDATE_COMPANY, {
  message: 'Unable to update',
});
c.set(codes.COMPANY_ERROR_404_DELETED_COMPANY, {
  message: 'Unable to delete',
});
c.set(codes.COMPANY_ERROR_500_DELETED_COMPANY, {
  message: 'Unable to delete',
});
c.set(codes.COMPANY_CREATE_CONTACT, {
  message: 'Contact Successfully Created',
});
c.set(codes.COMPANY_ERROR_500_CREATE_CONTACT, {
  message: 'Unable to create',
});
c.set(codes.COMPANY_ERROR_404_UPDATE_CONTACT, {
  message: 'Unable to update',
});
c.set(codes.COMPANY_ERROR_500_UPDATE_CONTACT, {
  message: 'Unable to update',
});
c.set(codes.COMPANY_ERROR_404_DELETED_CONTACT, {
  message: 'Unable to delete',
});
c.set(codes.COMPANY_ERROR_500_DELETED_CONTACT, {
  message: 'Unable to delete',
});
c.set(codes.COMPANY_ERROR_404_LOAD_ADDRESS, {
  message: 'Unable to load addresses',
});
c.set(codes.COMPANY_ERROR_500_LOAD_ADDRESS, {
  message: 'Unable to load addresses',
});
c.set(codes.COMPANY_ERROR_404_LOAD_SUPPLIER_ADDRESS, {
  message: 'Unable to load addresses',
});
c.set(codes.COMPANY_ERROR_500_LOAD_SUPPLIER_ADDRESS, {
  message: 'Unable to load addresses',
});
c.set(codes.COMPANY_ERROR_404_LOAD_PRODUCT_ADDRESS, {
  message: 'Unable to load addresses',
});
c.set(codes.COMPANY_ERROR_500_LOAD_PRODUCT_ADDRESS, {
  message: 'Unable to load addresses',
});
c.set(codes.COMPANY_ERROR_404_CREATE_COMPANY_ADDRESS, {
  message: 'Unable to create address',
});
c.set(codes.COMPANY_ERROR_500_CREATE_COMPANY_ADDRESS, {
  message: 'Unable to create address',
});
c.set(codes.COMPANY_ERROR_404_UPDATE_COMPANY_ADDRESS, {
  message: 'Unable to find address',
});
c.set(codes.COMPANY_ERROR_500_UPDATE_COMPANY_ADDRESS, {
  message: 'Unable to update address',
});
c.set(codes.COMPANY_ERROR_404_DELETED_COMPANY_ADDRESS, {
  message: 'Unable to find address',
});
c.set(codes.COMPANY_ERROR_500_DELETED_COMPANY_ADDRESS, {
  message: 'Unable to delete address',
});
c.set(codes.COMPANY_ERROR_404_CREATE_SUPPLIER_ADDRESS, {
  message: 'Unable to create address',
});
c.set(codes.COMPANY_ERROR_500_CREATE_SUPPLIER_ADDRESS, {
  message: 'Unable to create address',
});
c.set(codes.COMPANY_ERROR_404_CREATE_SUPPLIER_BULK_ADDRESS, {
  message: 'Unable to create addresses',
});
c.set(codes.COMPANY_ERROR_500_CREATE_SUPPLIER_BULK_ADDRESS, {
  message: 'Unable to create addresses',
});
c.set(codes.COMPANY_ERROR_404_UPDATE_SUPPLIER_ADDRESS, {
  message: 'Unable to find address',
});
c.set(codes.COMPANY_ERROR_500_UPDATE_SUPPLIER_ADDRESS, {
  message: 'Unable to update address',
});
c.set(codes.COMPANY_ERROR_404_DELETED_SUPPLIER_ADDRESS, {
  message: 'Unable to find address',
});
c.set(codes.COMPANY_ERROR_500_DELETED_SUPPLIER_ADDRESS, {
  message: 'Unable to delete address',
});
c.set(codes.COMPANY_ERROR_404_CREATE_PRODUCT_ADDRESS, {
  message: 'Unable to create address',
});
c.set(codes.COMPANY_ERROR_500_CREATE_PRODUCT_ADDRESS, {
  message: 'Unable to create address',
});
c.set(codes.COMPANY_ERROR_404_UPDATE_PRODUCT_ADDRESS, {
  message: 'Unable to find address',
});
c.set(codes.COMPANY_ERROR_500_UPDATE_PRODUCT_ADDRESS, {
  message: 'Unable to update address',
});
c.set(codes.COMPANY_ERROR_404_DELETED_PRODUCT_ADDRESS, {
  message: 'Unable to find address',
});
c.set(codes.COMPANY_ERROR_500_DELETED_PRODUCT_ADDRESS, {
  message: 'Unable to delete address',
});
c.set(codes.COMPANY_ERROR_NO_COMPANY_ID, {
  message: 'Please configure company data',
});
/**
 * Product
 * Event codes 21000 - 21999
 */
c.set(codes.PRODUCT_LOAD, {
  message: 'Loaded products',
});
c.set(codes.PRODUCT_CREATED, {
  message: 'Created product',
});
c.set(codes.PRODUCT_UPDATED, {
  message: 'Update product',
});
c.set(codes.PRODUCT_DELETED, {
  message: 'Deleted product',
});
c.set(codes.PRODUCT_ERROR_500_LOAD_COMPANY, {
  message: 'Unable to load product',
  retryable: true,
});
c.set(codes.PRODUCT_ERROR_500_CREATE_COMPANY, {
  message: 'Unable to create',
});
c.set(codes.PRODUCT_ERROR_404_UPDATE_COMPANY, {
  message: 'Unable to update',
});
c.set(codes.PRODUCT_ERROR_404_ADD_SCHEDULE, {
  message: 'Unable to add schedule to product',
});
c.set(codes.PRODUCT_ERROR_500_UPDATE_COMPANY, {
  message: 'Unable to update',
});
c.set(codes.PRODUCT_ERROR_404_DELETE_COMPANY, {
  message: 'Unable to remove',
});
c.set(codes.PRODUCT_ERROR_500_DELETE_COMPANY, {
  message: 'Unable to remove',
});
c.set(codes.PRODUCT_TRIP_ROUTE_LOAD, {
  message: 'Loaded addresses',
});
c.set(codes.PRODUCT_TRIP_ROUTE_CREATED, {
  message: 'Created addresses',
});
c.set(codes.PRODUCT_TRIP_ROUTE_UPDATED, {
  message: 'Update addresses',
});
c.set(codes.PRODUCT_TRIP_ROUTE_DELETED, {
  message: 'Deleted addresses',
});
c.set(codes.PRODUCT_TRIP_ROUTE_ERROR_500_LOAD_COMPANY, {
  message: 'Unable to load product addresses',
  // retryable: true,
});
c.set(codes.PRODUCT_TRIP_ROUTE_ERROR_500_CREATE_COMPANY, {
  message: 'Unable to create',
});
c.set(codes.PRODUCT_TRIP_ROUTE_ERROR_404_UPDATE_COMPANY, {
  message: 'Unable to update',
});
c.set(codes.PRODUCT_TRIP_ROUTE_ERROR_500_UPDATE_COMPANY, {
  message: 'Unable to update',
});
c.set(codes.PRODUCT_TRIP_ROUTE_ERROR_404_DELETE_COMPANY, {
  message: 'Unable to remove',
});
c.set(codes.PRODUCT_TRIP_ROUTE_ERROR_500_DELETE_COMPANY, {
  message: 'Unable to remove',
});
c.set(codes.PRODUCT_SCHEDULE_ERROR_404_CREATE_SCHEDULE, {
  message: 'Unable to create product schedule',
});
/**
 * Option
 * Event Codes 22000 - 22999
 */
c.set(codes.OPTION_LOAD, {
  message: 'Loaded options',
});
c.set(codes.OPTION_CREATED, {
  message: 'Created option',
});
c.set(codes.OPTION_UPDATED, {
  message: 'Update option',
});
c.set(codes.OPTION_DELETED, {
  message: 'Deleted option',
});
c.set(codes.OPTION_ERROR_500_LOAD_OPTION, {
  message: 'Unable to load option',
  retryable: true,
});
c.set(codes.OPTION_ERROR_500_CREATE_OPTION, {
  message: 'Unable to create',
});
c.set(codes.OPTION_ERROR_404_UPDATE_OPTION, {
  message: 'Unable to update',
});
c.set(codes.OPTION_ERROR_500_UPDATE_OPTION, {
  message: 'Unable to update',
});
c.set(codes.OPTION_ERROR_404_DELETE_OPTION, {
  message: 'Unable to remove',
});
c.set(codes.OPTION_ERROR_500_DELETE_OPTION, {
  message: 'Unable to remove',
});
c.set(codes.OPTION_ERROR_404_ADD_SCHEDULE, {
  message: 'Unable to add schedule to option',
});
c.set(codes.OPTION_ERROR_409_ADD_SCHEDULE, {
  message:
    'Unable to add schedule to option. A schedule already exists with the same date range',
});
/**
 * Traveler Type
 * Event codes 23000 - 23999
 */
c.set(codes.TRAVELER_TYPE_LOAD, {
  message: 'Loaded traveler types',
});
c.set(codes.TRAVELER_TYPE_CREATED, {
  message: 'Added traveler types',
});
c.set(codes.TRAVELER_TYPE_UPDATED, {
  message: 'Updated traveler types',
});
c.set(codes.TRAVELER_TYPE_DELETED, {
  message: 'Removed traveler types',
});
c.set(codes.TRAVELER_TYPE_ERROR_500_LOAD, {
  message: 'Unable to load',
  retryable: true,
});
c.set(codes.TRAVELER_TYPE_ERROR_500_CREATE_TRAVELER_TYPE, {
  message: 'Unable to create',
});
c.set(codes.TRAVELER_TYPE_ERROR_500_UPDATE_TRAVELER_TYPE, {
  message: 'Unable to update',
});
c.set(codes.TRAVELER_TYPE_ERROR_404_UPDATE_TRAVELER_TYPE, {
  message: 'Unable to update',
});
c.set(codes.TRAVELER_TYPE_ERROR_404_DELETE_TRAVELER_TYPE, {
  message: 'Unable to remove',
});
c.set(codes.TRAVELER_TYPE_ERROR_500_DELETE_TRAVELER_TYPE, {
  message: 'Unable to remove',
});
c.set(codes.TRAVELER_TYPE_ERROR_MALFORMED_DELETE_TRAVELER_TYPE, {
  message: 'Unable to remove',
});
/**
 * Price schedule
 * Event codes 24000 - 24999
 */
c.set(codes.PRICE_SCHEDULE_LOAD, {
  message: 'Loaded price schedules',
});
c.set(codes.PRICE_SCHEDULE_CREATED, {
  message: 'Added price schedule',
  // viewable: true,
});
c.set(codes.PRICE_SCHEDULE_UPDATED, {
  message: 'Updated price schedule',
  // viewable: true,
  // undoable: true,
});
c.set(codes.PRICE_SCHEDULE_DELETED, {
  message: 'Removed price schedule',
  // undoable: true,
});
c.set(codes.PRICE_SCHEDULE_ERROR_500_LOAD_PRICES, {
  message: 'Unable to load prices',
  retryable: true,
});
c.set(codes.PRICE_SCHEDULE_ERROR_500_CREATE_PRICE_SCHEDULE, {
  message: 'Unable to create price schedule',
});
c.set(codes.PRICE_SCHEDULE_ERROR_404_UPDATE_PRICE_SCHEDULE, {
  message: 'Unable to update price schedule',
});
c.set(codes.PRICE_SCHEDULE_ERROR_500_UPDATE_PRICE_SCHEDULE, {
  message: 'Unable to update price schedule',
});
c.set(codes.PRICE_SCHEDULE_ERROR_404_REMOVE_PRICE_SCHEDULE, {
  message: 'Unable to remove price schedule',
});
c.set(codes.PRICE_SCHEDULE_ERROR_500_REMOVE_PRICE_SCHEDULE, {
  message: 'Unable to remove price schedule',
});
c.set(codes.PRICE_AND_TAXES_LOAD, {
  message: 'Loaded price',
});
c.set(codes.PRICE_AND_TAXES_CREATED, {
  message: 'Added price',
  // viewable: true,
});
c.set(codes.PRICE_AND_TAXES_UPDATED, {
  message: 'Updated price',
  // viewable: true,
  // undoable: true,
});
c.set(codes.PRICE_AND_TAXES_DELETED, {
  message: 'Removed price',
  // undoable: true,
});
c.set(codes.PRICE_AND_TAXES_ERROR_500_LOAD_PRICES, {
  message: 'Unable to load prices',
  retryable: true,
});
c.set(codes.PRICE_AND_TAXES_ERROR_500_CREATE_PRICE, {
  message: 'Unable to create price',
  // retryable: true,
});
c.set(codes.PRICE_AND_TAXES_ERROR_404_UPDATE_PRICE, {
  message: 'Unable to update price',
});
c.set(codes.PRICE_AND_TAXES_ERROR_500_UPDATE_PRICE, {
  message: 'Unable to update price',
  // retryable: true,
});
c.set(codes.PRICE_AND_TAXES_ERROR_404_REMOVE_PRICE, {
  message: 'Unable to remove price',
});
c.set(codes.PRICE_AND_TAXES_ERROR_500_REMOVE_PRICE, {
  message: 'Unable to remove price',
  // retryable: true,
});
c.set(codes.PRICE_LOAD, {
  message: 'Loaded price',
});
c.set(codes.PRICE_CREATED, {
  message: 'Added price',
  // viewable: true,
});
c.set(codes.PRICE_UPDATED, {
  message: 'Updated price',
  // viewable: true,
  // undoable: true,
});
c.set(codes.PRICE_DELETED, {
  message: 'Removed price',
  // undoable: true,
});
c.set(codes.PRICE_ERROR_500_LOAD_PRICES, {
  message: 'Unable to load prices',
  retryable: true,
});
c.set(codes.PRICE_ERROR_500_CREATE_PRICE, {
  message: 'Unable to create price',
  // retryable: true,
});
c.set(codes.PRICE_ERROR_404_UPDATE_PRICE, {
  message: 'Unable to update price',
});
c.set(codes.PRICE_ERROR_500_UPDATE_PRICE, {
  message: 'Unable to update price',
  // retryable: true,
});
c.set(codes.PRICE_ERROR_404_REMOVE_PRICE, {
  message: 'Unable to remove price',
});
c.set(codes.PRICE_ERROR_500_REMOVE_PRICE, {
  message: 'Unable to remove price',
  // retryable: true,
});
/**
 * Taxes and Fees
 * Event codes 25000 - 25999
 */
c.set(codes.TAXES_AND_FEES_LOAD, {
  message: 'Loaded taxes and fees',
});
c.set(codes.TAXES_AND_FEES_CREATED, {
  message: 'Added taxes and fees',
});
c.set(codes.TAXES_AND_FEES_UPDATED, {
  message: 'Updated taxes and fees',
});
c.set(codes.TAXES_AND_FEES_DELETED, {
  message: 'Removed taxes and fees',
});
c.set(codes.TAXES_AND_FEES_ERROR_500_LOAD_TAXES, {
  message: 'Unable to load taxes and fees',
  retryable: true,
});
c.set(codes.TAXES_AND_FEES_ERROR_500_CREATE_TAXES, {
  message: 'Unable to create',
});
c.set(codes.TAXES_AND_FEES_ERROR_404_UPDATE_TAXES, {
  message: 'Unable to update',
});
c.set(codes.TAXES_AND_FEES_ERROR_500_UPDATE_TAXES, {
  message: 'Unable to update',
});
c.set(codes.TAXES_AND_FEES_ERROR_404_DELETE_TAXES, {
  message: 'Unable to remove',
});
c.set(codes.TAXES_AND_FEES_ERROR_500_DELETE_TAXES, {
  message: 'Unable to remove',
});
c.set(codes.TAXES_AND_FEES_FEEDBACK_SENT, {
  message: 'Message was sent',
});
/**
 * /* Availability Status (27000 - 27999)
 */
c.set(codes.AVAILABILITY_STATUS_404_UPDATE, {
  message: 'Unable to update availability',
});
c.set(codes.AVAILABILITY_STATUS_500_UPDATE, {
  message: 'Unable to update availability',
});
/**
 * Channels
 * Event codes 28000 - 28999
 */
c.set(codes.CHANNELS_FEEDBACK_SENT, {
  message: 'Message was sent',
});
/**
 * Reports
 * Event codes 29000 - 29999
 */
c.set(codes.REPORTS_ERROR_400_SEARCH, {
  message: 'No data found',
});
c.set(codes.REPORTS_ERROR_500_SEARCH, {
  message: 'Reporting not configured',
});
c.set(codes.REPORTS_ERROR_400_MISSING_EID, {
  message: 'External System ID is missing. Please contact support to fix this issue.',
});

/**
 * Offers
 * Event codes 30000 - 30999
 */
c.set(codes.OFFER_ERROR_404_GET, {
  message: 'Unable to load offer',
});
c.set(codes.OFFERS_ERROR_404_GET, {
  message: 'Unable to load offers',
});
c.set(codes.OFFER_REMOVED, {
  message: 'Offer removed',
});
c.set(codes.OFFER_ERROR_500, {
  message: 'Offer is already present for product id and option id',
});
c.set(codes.OFFER_CAPACITY_CONFLICT, {
  message: 'Timeslots already have capacity set elsewhere',
});
c.set(codes.OFFER_REMOVED_ERROR_500, {
  message: 'Unable to remove offer',
});
c.set(codes.OFFER_PRICING_REMOVED, {
  message: 'Offer pricing  removed',
});
c.set(codes.OFFER_PRICING_UPDATED, {
  message: 'Offer pricing  updated',
});
/**
 * Schedules
 * Event codes 40000 - 40999
 */
c.set(codes.SCHEDULE_DELETED, {
  message: 'Schedule deleted',
});
c.set(codes.SCHEDULE_UPDATED, {
  message: 'Schedule updated',
});
c.set(codes.SCHEDULE_CREATED_ERROR, {
  message: 'Unable to create Schedule',
});
c.set(codes.SCHEDULE_CREATED_409_ERROR, {
  message: 'Unable to create Schedule. Schedule start date already in use',
});
c.set(codes.SCHEDULE_CREATED, {
  message: 'Schedule created',
});
c.set(codes.SCHEDULE_ERROR, {
  message: 'Unable to load Schedule',
});
/**
 * Inventory
 * Event codes 50000 - 50999
 */
c.set(codes.INVENTORY_CREATED, {
  message: 'Inventory Created',
});
/**
 * Rate Card
 * Event codes 60000 - 60999
 */
c.set(codes.RATE_CARD_ERROR, {
  message: 'Rate Card Error',
});
c.set(codes.RATE_CARD_CHANNEL_DELETED, {
  message: 'Channel disconnected from Rate Card',
});
c.set(codes.RATE_CARD_CHANNEL_CONNECTED, {
  message: 'Channel connected to Rate Card',
});
c.set(codes.RATE_CARD_CHANNEL_BINDING_CREATED, {
  message: 'Channel binding created',
});
c.set(codes.RATE_CARD_CHANNEL_BINDING_UPDATED, {
  message: 'Channel binding updated',
});
c.set(codes.RATE_CARD_CHANNEL_BINDING_DELETED, {
  message: 'Channel binding deleted',
});
/**
 * Operator User
 * Event codes 80000 - 80999
 */
c.set(codes.OPERATOR_USER_404_DELETE, {
  message: 'Unable to find user',
});
c.set(codes.OPERATOR_USER_500_DELETE, {
  message: 'Operator User Error',
});
c.set(codes.OPERATOR_USER_404_CREATE, {
  message: 'Unable to find user',
});
c.set(codes.OPERATOR_USER_404_DELETE, {
  message: 'Unable to find user',
});
c.set(codes.OPERATOR_USER_404_GET, {
  message: 'Unable to find user',
});
c.set(codes.OPERATOR_USER_500_GET, {
  message: 'Error retrieving user',
});
/**
 * Generic errors
 * Event codes 90000 - 99999
 */
c.set(codes.GENERAL_MESSAGE_NO_ORG_ID, {
  message: `Error ${codes.GENERAL_MESSAGE_NO_ORG_ID}: No Company ID, request not allowed`,
});
c.set(codes.GENERAL_MESSAGE_NO_SUPPLIER_ID, {
  message: `Error ${codes.GENERAL_MESSAGE_NO_SUPPLIER_ID}: No Supplier ID, request not allowed`,
});
c.set(codes.GENERAL_MESSAGE_FORBIDDEN, {
  message: `Error ${codes.GENERAL_MESSAGE_FORBIDDEN}: Access not allowed`,
  hasHelp: true,
});

c.set(codes.GENERAL_MESSAGE_COPIED_CLIPBOARD, {
  message: `Copied`,
});

export const codeConfiguration = c;
