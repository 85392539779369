
























































































































import { Component, Vue } from 'vue-property-decorator';
import faker from 'faker';
import { fakeRateCard, RateCard } from '@/models/ChannelModels';
import RateWizard from '@/components/.deprecated/ratewizard/RateWizard.vue';
import { requiredFieldRules } from '@/utils/validation-rules';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import {
  demoChannels,
  demoProductNames,
  demoProductOptionSuffixes,
} from '@/utils/demoData';
import { ProductStatus } from '@/models';
import { tablePaginationOptions } from '@/utils/helpers';

@Component({
  components: {
    RdmStatusIndicator,
    RateWizard,
  },
})
export default class ChannelRateCardDetails extends Vue {
  private windowWidth = 0;

  private action: any = function () {};

  private rateCard: RateCard = fakeRateCard();

  private requiredFieldRules = requiredFieldRules;

  private ProductStatus = ProductStatus;

  private productHeaders: Array<Record<string, any>> = [
    { text: 'Option', align: 'left', sortable: true, value: 'name' },
    { text: '', value: 'action', align: 'right', sortable: false },
  ];

  private channels: Array<string> = demoChannels;

  private products: Array<any> = [];

  mounted() {
    this.generateProductAndOptions();
  }

  paginationOptions(items: any[]) {
    return tablePaginationOptions(items);
  }

  generateProductAndOptions() {
    for (let i = 0; i < demoProductNames.length; i++) {
      const p = {
        id: '',
        name: '',
        product_options: [],
      };
      p.id = faker.random.alphaNumeric(16);
      p.name = demoProductNames[i];
      p.product_options = [];
      for (let j = 0; j < demoProductOptionSuffixes.length; j++) {
        const o = {
          id: '',
          name: '',
        };
        o.id = faker.random.alphaNumeric(16);
        o.name = `${p.name} ${demoProductOptionSuffixes[j]}`;
        // p.product_options.push(o);
      }
      this.products.push(p);
    }
  }

  goToDetails(option: any) {
    this.$router.push({ name: 'channel-rate-card-pricing', params: { id: option.name } });
  }
}
