



























































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmReturnToBtn from '../../../components/common/button/RdmReturnToBtn.vue';
import { constants } from '@/utils/constants';
import { productModule } from '@/store/modules/moduleProduct';
import {
  optionalStartDateBeforeEndDateRules,
  requiredFieldRules,
  requiredNumberFieldRules,
  requiredOnlyPositiveWholeNumbersFieldRules,
  requiredStartDateBeforeEndDateRules,
} from '@/utils/validation-rules';
import {
  AvailabilitySchedule,
  AvailabilityType,
  Days,
  SelectedDay,
  SelectedDays,
  TimeCapacity,
  TimeEntryMode,
} from '@/api/inventory/InventoryModels';
import moment from 'moment';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import RdmUnsavedChangesModal from '@/components/common/alert/RdmUnsavedChangesModal.vue';
import faker from 'faker';

import _ from 'lodash';
import RdmTimeInput from '@/components/common/time/RdmTimeInput.vue';
import { profileModule } from '@/store/modules/moduleProfile';
import { optionModule } from '@/store/modules/moduleOption';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';

@Component({
  components: {
    RdmCollapsableCard,
    RdmReturnToBtn,
    RdmTimeInput,
    RdmUnsavedChangesModal,
  },
  props: {
    option: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class ProductTimeCapacityEdit extends Vue {
  private parentName = constants.titles.PRODUCT;
  private requiredField = requiredFieldRules;
  private requiredOnlyPositiveWholeNumbersFieldRules =
    requiredOnlyPositiveWholeNumbersFieldRules;
  private requiredNumberFieldRules = requiredNumberFieldRules;
  private optionalStartBeforeEndField = optionalStartDateBeforeEndDateRules;
  private requiredStartBeforeEndField = requiredStartDateBeforeEndDateRules;
  private unsavedChanges = false;
  private isLimitedCapacity = false;
  private formValid = false;
  private startDateMenu = false;
  private endDateMenu = false;
  private timeCapacity = {
    name: '',
    validStart: '',
    validEnd: '',
    includeCapacity: false,
  };
  private timeEntryModes = [TimeEntryMode.Grouped, TimeEntryMode.Individual];
  private timeEntryMode = '';
  private timeEntryModeGrouped = TimeEntryMode.Grouped;
  private timeEntryModeIndividual = TimeEntryMode.Individual;
  private days = [] as Array<Days>;
  private availAbleDays = [] as Array<Days>;
  private selectedDays = [] as Array<SelectedDays>;
  private groupsDays = [] as Array<SelectedDay>;
  private individualDays = [] as Array<SelectedDay>;

  mounted() {
    if (!this.$props.option && !productModule.Product?.id) {
      // todo pop alert
      this.$router.push({ name: constants.routes.PRODUCT_LIST }).catch(() => {});
      return;
    }
    if (!this.SelectedGroupSchedule?.schedules) {
      this.$router.push({ name: constants.routes.PRODUCT_DETAILS }).catch(() => {});
      return;
    }
    this.days = this.createInitialDays();
    this.availAbleDays = this.createInitialDays();
    this.timeCapacity.name = this.SelectedGroupSchedule.scheduleName;
    this.timeCapacity.validStart = this.SelectedGroupSchedule.validFrom || '';
    this.timeCapacity.validEnd = this.SelectedGroupSchedule.validUntil || '';
    this.configExistingGroups();
    if (this.timeEntryMode === this.timeEntryModeIndividual) {
      this.configExistingIndividual();
    }
    // this.timeCapacity.validStart = moment().format('YYYY-MM-DD');
    if (
      !this.$props.option &&
      this.Product?.availabilityType === AvailabilityType.OpeningHours
    ) {
      this.isLimitedCapacity = true;
      this.timeCapacity.includeCapacity = true;
    }
    this.addDayIndividual();
  }

  validateForm() {
    if (this.$refs && this.$refs.createTimeCapacityForm) {
      const { createTimeCapacityForm } = this.$refs as any;
      createTimeCapacityForm.validate();
    }
  }

  configExistingGroups() {
    const groupSchedules = this.groupSchedulesDictionary(
      this.SelectedGroupSchedule.schedules,
    );
    const keys = _.keys(groupSchedules);
    keys.forEach((key) => {
      const groups = groupSchedules[key] as Array<AvailabilitySchedule>;
      let id = '';
      let days = [] as Array<Days>;
      const times = [] as Array<TimeCapacity>;
      groups.forEach((schedule) => {
        if (schedule.daysOfWeek && schedule.daysOfWeek.length > 1) {
          if (this.timeEntryMode === '') {
            this.timeEntryMode = this.timeEntryModeGrouped;
          }
          schedule.daysOfWeek.forEach((day) => {
            _.remove(this.availAbleDays, (availDay: Days) => {
              return availDay === day;
            });
            _.remove(this.days, (d: Days) => {
              return d === day;
            });
          });
        }
        id = schedule.id || faker.datatype.uuid();
        days = [...((schedule.daysOfWeek || []) as Array<Days>)];
        times.push({
          start: schedule.startTime || '',
          end: schedule.endTime || '',
          maxCapacity: schedule.maxCapacity || 0,
          timeStartMenu: false,
          timeEndMenu: false,
        });
      });
      this.groupsDays.push({
        id: id,
        day: days,
        times: times,
      });
    });

    if (this.timeEntryMode === '') {
      this.timeEntryMode = this.timeEntryModeIndividual;
    }
  }

  configExistingIndividual() {
    this.SelectedGroupSchedule.schedules.forEach((schedule) => {
      if (schedule.maxCapacity && schedule.maxCapacity > 0) {
        this.timeCapacity.includeCapacity = true;
      }
      this.individualDays.push({
        id: schedule.id || faker.datatype.uuid(),
        day: [...((schedule.daysOfWeek || []) as Array<Days>)],
        times: [
          {
            start: schedule.startTime || '',
            end: schedule.endTime || '',
            maxCapacity: schedule.maxCapacity || 0,
            timeStartMenu: false,
            timeEndMenu: false,
          },
        ],
      });
    });
  }

  addDayIndividual() {
    for (let i = 0; i < this.days.length; i++) {
      const j = this.individualDays.findIndex((iDay) => {
        return (
          Array.isArray(iDay.day) && iDay.day.length > 0 && iDay.day[0] === this.days[i]
        );
      });
      if (j === -1) {
        this.individualDays.push(this.createIndividualDays(i));
      }
    }
    this.sortSelectedDays(this.individualDays);

    // todo sort individual days by day
  }

  addDayGroup() {
    this.sortDays(this.availAbleDays);
    this.groupsDays.push(this.createSelectedDay());
    this.availAbleDays.forEach((availableDay) => {
      const dayI = this.days.findIndex((day) => availableDay === day);
      this.days.splice(dayI, 1);
    });
    this.availAbleDays = [];
    this.sortDays(this.days);
  }

  removeDay(day: Days, groupIndex: number, dayIndex: number) {
    try {
      this.days.push(day);
      this.availAbleDays.push(day);
      this.sortDays(this.days);
      this.groupsDays[groupIndex].day.splice(dayIndex, 1);
    } catch (e) {
      // todo send error to backend
    }
  }

  removeDayGroup(groupIndex: number) {
    try {
      this.groupsDays[groupIndex].day.forEach((day) => {
        this.days.push(day);
        this.availAbleDays.push(day);
      });
      this.groupsDays.splice(groupIndex, 1);
      this.sortDays(this.days);
    } catch (e) {
      // todo send error to backend
    }
  }

  addTimeIndividualRow(groupIndex: number) {
    try {
      this.individualDays[groupIndex].times.push(this.createTimeCapacity());
    } catch (e) {
      // todo send error to backend
    }
  }

  removeTimeIndividualRow(groupIndex: number, timeIndex: number) {
    try {
      this.individualDays[groupIndex].times.splice(timeIndex, 1);
    } catch (e) {
      // todo send error to backend
    }
  }

  addTimeRow(groupIndex: number) {
    try {
      this.groupsDays[groupIndex].times.push(this.createTimeCapacity());
    } catch (e) {
      // todo send error to backend
    }
  }

  removeTimeRow(groupIndex: number, timeIndex: number) {
    try {
      this.groupsDays[groupIndex].times.splice(timeIndex, 1);
    } catch (e) {
      // todo send error to backend
    }
  }

  hasSelectedDay(day: string) {
    return this.availAbleDays.findIndex((element: string) => element === day) > -1;
  }

  createSelectedDay(): SelectedDay {
    return {
      day: [...this.availAbleDays],
      times: [this.createTimeCapacity()],
    };
  }

  createIndividualDays(dayIndex: number): SelectedDay {
    const day = this.days[dayIndex];
    return {
      day: [day],
      times: [this.createTimeCapacity()],
    };
  }

  createTimeCapacity(): TimeCapacity {
    return {
      start: '',
      end: '',
      maxCapacity: undefined,
      timeStartMenu: false,
      timeEndMenu: false,
    };
  }

  createInitialDays(): Array<Days> {
    return [
      Days.Sunday,
      Days.Monday,
      Days.Tuesday,
      Days.Wednesday,
      Days.Thursday,
      Days.Friday,
      Days.Saturday,
    ];
  }

  sortDays(days: Array<Days>) {
    return days.sort((a, b) => {
      const aVal = moment().day(a).day();
      const bVal = moment().day(b).day();
      if (aVal < bVal) {
        return -1;
      }

      if (aVal > bVal) {
        return 1;
      }

      return 0;
    });
  }

  sortSelectedDays(days: Array<SelectedDay>) {
    return days.sort((a, b) => {
      if (
        !(Array.isArray(a.day) && a.day.length > 0) ||
        !(Array.isArray(b.day) && b.day.length > 0)
      ) {
        return 0;
      }
      const aVal = moment().day(a.day[0]).day();
      const bVal = moment().day(b.day[0]).day();
      if (aVal < bVal) {
        return -1;
      }

      if (aVal > bVal) {
        return 1;
      }

      return 0;
    });
  }

  saveTimeCapacities() {
    if (this.$refs && this.$refs.createTimeCapacityForm) {
      const { createTimeCapacityForm } = this.$refs as any;
      const isValid = createTimeCapacityForm.validate();
      if (isValid) {
        const groupId = this.SelectedGroupSchedule.id;
        httpModule
          .removeAvailabilitySchedules(this.SelectedGroupSchedule.schedules)
          .then(() => {});
        const availabilitySchedules = [] as Array<AvailabilitySchedule>;
        if (this.timeEntryMode === this.timeEntryModeGrouped) {
          this.groupsDays.forEach((selectedDay) => {
            selectedDay.times.forEach((times) => {
              availabilitySchedules.push({
                id: faker.datatype.uuid(),
                supplierId: companyModule.Company?.orgId || '',
                productId: this.$props.option ? '' : productModule.Product?.id || '',
                optionId: this.$props.option ? optionModule.Option?.id || '' : '',
                groupId: groupId,
                org: profileModule.Profile?.org_code || '',
                name: this.timeCapacity.name,
                validFrom: this.timeCapacity.validStart,
                validUntil: this.timeCapacity.validEnd,
                startTime: times.start,
                endTime: times.end,
                maxCapacity:
                  this.timeCapacity.includeCapacity && times.maxCapacity
                    ? _.toNumber(times.maxCapacity)
                    : undefined,
                daysOfWeek: [...selectedDay.day],
                excludedDays: [],
                eventDuration: 0,
                eventPadding: 0,
              });
            });
          });
        } else if (this.timeEntryMode === this.timeEntryModeIndividual) {
          this.individualDays.forEach((selectedDay) => {
            if (selectedDay.times && selectedDay.times.length > 0) {
              selectedDay.times.forEach((times) => {
                availabilitySchedules.push({
                  id: faker.datatype.uuid(),
                  supplierId: companyModule.Company?.orgId || '',
                  productId: this.$props.option ? '' : productModule.Product?.id || '',
                  optionId: this.$props.option ? optionModule.Option?.id || '' : '',
                  groupId: groupId,
                  org: profileModule.Profile?.org_code || '',
                  name: this.timeCapacity.name,
                  validFrom: this.timeCapacity.validStart,
                  validUntil: this.timeCapacity.validEnd,
                  startTime: times.start,
                  endTime: times.end,
                  maxCapacity: times.maxCapacity
                    ? _.toNumber(times.maxCapacity)
                    : undefined,
                  daysOfWeek: [...selectedDay.day],
                  excludedDays: [],
                  eventDuration: 0,
                  eventPadding: 0,
                });
              });
            }
          });
        }
        httpModule
          .createAvailabilitySchedules(availabilitySchedules)
          .then(() => {
            if (this.$props.option) {
              this.returnToOptionPage();
            } else {
              this.returnToProductPage();
            }
          })
          .finally(() => {});
      }
    }
  }

  groupSchedulesDictionary(schedules: Array<AvailabilitySchedule>) {
    return _.groupBy<AvailabilitySchedule>(schedules, (s) => s.daysOfWeek);
  }

  discardWarning() {
    this.unsavedChanges = true;
  }

  returnToProductPage() {
    this.unsavedChanges = false;
    const location = productModule.Product?.id
      ? { name: this.RouterProductDetails, params: { id: productModule.Product.id } }
      : { name: this.RouterProductDetails };
    this.$router.push(location).catch(() => {});
  }

  returnToOptionPage() {
    this.unsavedChanges = false;
    const location = optionModule.Option?.id
      ? { name: this.RouterOptionDetails, params: { id: optionModule.Option.id } }
      : { name: this.RouterOptionDetails };
    this.$router.push(location).catch(() => {});
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get RouterProductDetails() {
    if (productModule.Product?.id) {
      return constants.routes.PRODUCT_DETAILS;
    }
    return constants.routes.PRODUCT_LIST;
  }

  get RouterOptionDetails() {
    if (optionModule.Option?.id) {
      return constants.routes.OPTION_DETAILS;
    }
    return constants.routes.OPTION_LIST;
  }

  get RouterProductDetailsParams() {
    if (
      productModule.Product &&
      productModule.Product.id &&
      productModule.Product.id.length > 0
    ) {
      return { id: productModule.Product.id };
    }
    return {};
  }

  get Product() {
    return productModule.Product;
  }

  get SelectedGroupSchedule() {
    return productModule.SelectedScheduleView;
  }

  get Loading() {
    return productModule.Loading;
  }
}
