






























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { requiredFieldRules, requiredNumberFieldRules } from '@/utils/validation-rules';
import _ from 'lodash';

@Component({})
export default class RdmTimeInput extends Vue {
  private timeForm = false;
  private hours = '00';
  private minutes = '00';
  private amPm = '';
  private outputTime = '';
  private requiredField = requiredFieldRules;
  private requiredNumberRule = requiredNumberFieldRules;
  private am = 'AM';
  private pm = 'PM';

  @Prop({ type: String })
  public time: string;

  mounted() {
    const parsedTime = this.parseTime();
    this.hours = parsedTime.hours;
    this.minutes = parsedTime.minutes;
    this.amPm = parsedTime.amPm;
  }

  parseTime() {
    if (this.time) {
      const hourMins = this.time.split(':');
      const isMidnight = hourMins[0] == '00';
      hourMins[0] = isMidnight ? '12' : hourMins[0];
      return {
        hours: String(
          Number(hourMins[0]) > 12 ? Number(hourMins[0]) - 12 : Number(hourMins[0]),
        ),
        minutes: hourMins[1],
        amPm: Number(hourMins[0]) >= 12 && !isMidnight ? this.pm : this.am,
      };
    }

    return {
      hours: '00',
      minutes: '00',
      amPm: this.am,
    };
  }

  destroyed() {}

  handleKeyDown(event: any): any {
    if (
      !(
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        event.keyCode === 16 ||
        event.keyCode === 27 ||
        (event.keyCode > 32 && event.keyCode < 41) ||
        (event.keyCode > 44 && event.keyCode < 58) ||
        event.keyCode === 91 ||
        event.keyCode === 92 ||
        (event.keyCode > 94 && event.keyCode < 106)
      )
    ) {
      event.preventDefault();
      return;
    }
  }

  handleHours() {
    if (!this.hours) {
      this.hours = '00';
    }

    if (Number(this.hours) > 23) {
      this.hours = '23';
    }

    if (Number(this.hours) > 12) {
      this.hours = String(Number(this.hours) - 12);
      this.amPm = this.pm;
    }

    if (Number(this.hours) < 0) {
      this.hours = '00';
      this.amPm = this.am;
    }
    this.handleOutputTime();
  }

  handleMinutes() {
    if (!this.minutes) {
      this.minutes = '00';
    }

    if (Number(this.minutes) > 59) {
      this.minutes = '59';
    }

    if (Number(this.minutes) < 0) {
      this.minutes = '00';
    }
    this.handleOutputTime();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleOutputTime(flag?: boolean) {
    if (Number(this.hours) == 0 && this.amPm == this.pm) this.hours = '12';
    const h = _.cloneDeep(this.hours);
    const m = _.cloneDeep(this.minutes);
    const hour = _.toString(this.hourConversion(h)).padStart(2, '0');
    const minutes = _.toString(m).padStart(2, '0');
    this.outputTime = `${hour}:${minutes}`;
    this.$emit('change', this.outputTime);
  }

  hourConversion(hour: string) {
    if (this.amPm === this.pm) {
      if (Number(hour) < 12) {
        return Number(hour) + 12;
      } else if (Number(hour) === 12) {
        return 12;
      }
    } else if (this.amPm === this.am) {
      if (Number(hour) === 12) {
        return 0;
      }
    }
    return hour;
  }
}
