








































































































































































import { Component, Vue } from 'vue-property-decorator';
import { passwordMatchRules, requiredFieldRules } from '@/utils/validation-rules';
import { RequestChangePassword } from '@/api/wps/BackplaneModels';
import { profileModule } from '@/store/modules/moduleProfile';
import backplaneClient from '@/api/backplane/BackplaneClient';

@Component
export default class UserProfile extends Vue {
  private requiredFieldRules = requiredFieldRules;
  private verifyPasswordRules = passwordMatchRules;
  private displayChangePasswordForm = false;
  private passwordForm = false;
  private passwordModel = '';
  private passwordConfirmModel = '';
  private showPassword = false;
  private resetPasswordError = false;
  private changePasswordLoading = false;
  private showChangePasswordSnackBar = false;
  private currentLanguage = 'en';

  toggleChangePasswordForm() {
    this.displayChangePasswordForm = !this.displayChangePasswordForm;
  }

  resetPassword() {
    const { passwordFormRef } = this.$refs as any;
    if (passwordFormRef) {
      const valid = passwordFormRef.validate();
      if (valid) {
        this.changePassword();
      }
    }
  }

  changePassword() {
    this.changePasswordLoading = true;
    const changePassword: RequestChangePassword = {
      newPassword: this.passwordModel,
    };
    backplaneClient
      .changePassword(changePassword)
      .toPromise()
      .then(() => {
        this.toggleChangePasswordForm();
        this.showChangePasswordSnackBar = true;
      })
      .catch(() => {
        this.resetPasswordError = true;
      })
      .finally(() => {
        this.changePasswordLoading = false;
      });
  }

  get user() {
    return profileModule.Profile;
  }

  get languages() {
    return [
      { name: 'English', code: 'en' },
      { name: 'Español', code: 'es' },
      { name: 'Français', code: 'fr' },
    ];
  }
}
