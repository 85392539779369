


























































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import { httpModule } from '@/store/modules/moduleHttp';
import { userModule } from '@/store/modules/moduleUser';

@Component
export default class BulkActionDeleteUserModal extends Vue {
  /* DATA */
  private loading = false;
  private unsavedChangesWarning = false;
  private removeUser = constants.REMOVE_USER;
  private activateUser = constants.ACTIVATE_USER;

  // stages
  private stageNumber = 1;

  private isValidStage1 = true;

  private isValidStage2 = true;

  /* METHODS */

  private unsavedCancel() {
    this.unsavedChangesWarning = true;
  }

  private cancelProceed() {
    this.unsavedChangesWarning = false;
    this.cancelForm();
  }

  private cancelForm() {
    this.$emit('user-add-cancelled');
  }

  private previousStage() {
    --this.stageNumber;
  }

  private nextStage() {
    ++this.stageNumber;
  }

  private nextOrConfirm() {
    if (this.stageNumber === 1) {
      if (this.$refs && this.$refs.stage1Form) {
        const { stage1Form } = this.$refs as any;
        const valid = stage1Form.validate();
        if (valid) {
          this.nextStage();
        } else {
          // stage1Form
        }
      }
    } else if (this.stageNumber === 2) {
      if (this.isValidStage1) {
        this.saveForm();
      }
    }
  }

  private confirm() {
    if (this.bulkAction === constants.REMOVE_USER) {
      this.loading = true;
      httpModule
        .deactivateSelectedUsers()
        .then(() => {
          const successMessage = {
            type: 'success',
            message: 'UserManagementPage.UserRemoveCompletedMessage',
          };
          this.$emit('user-add-confirmed', successMessage);
        })
        .catch(() => {
          const failureMessage = {
            type: 'error',
            message: 'UserManagementPage.ErrorOccurred',
          };
          this.$emit('user-add-failed', failureMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (this.bulkAction === constants.ACTIVATE_USER) {
      this.loading = true;
      httpModule
        .activateSelectedUsers()
        .then(() => {
          const successMessage = {
            type: 'success',
            message: 'UserManagementPage.UserActivateCompletedMessage',
          };
          this.$emit('user-add-confirmed', successMessage);
        })
        .catch(() => {
          const failureMessage = {
            type: 'error',
            message: 'UserManagementPage.ErrorOccurred',
          };
          this.$emit('user-add-failed', failureMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  saveForm() {
    httpModule.deleteSelectedUsers();
    this.$emit('user-add-confirmed');
  }

  get selectedUsers() {
    return userModule.SelectedUsers;
  }

  get bulkAction() {
    return userModule.BulkAction;
  }
}
