






















































import { Component, Vue } from 'vue-property-decorator';
import { InternalFeaturesRS } from '@/api/wps/WpsModels';

@Component
export default class AppAdmin extends Vue {
  /* DATA */

  private features: Array<InternalFeaturesRS> = [];

  private filterFeature = '';

  private loading = false;

  /* COMPUTED */

  private get DisplayFeatures(): Array<InternalFeaturesRS> {
    if (this.filterFeature === '') return this.features;
    return this.features.filter((feature) => {
      return (
        feature.name.toLowerCase().indexOf(this.filterFeature.toLowerCase()) > -1 ||
        feature.description.toLowerCase().indexOf(this.filterFeature.toLowerCase()) > -1
      );
    });
  }

  /* HOOKS */

  private mounted() {
    this.loading = true;
    //TODO: save these commented sections to restore public features when we will be ready RE-7998
    // wpsClient
    //   .listInternalFeatures(DefaultAlertErrHandler("Can't load features: "))
    //   .pipe(
    //     catchError(() => {
    //       return of(new Array<InternalFeaturesRS>());
    //     }),
    //     finalize(() => {
    //       this.loading = false;
    //     }),
    //   )
    //   .subscribe((result: Array<InternalFeaturesRS>) => {
    //     // sort by name
    //     // this.features = result.sort((a, b) => compareStrings(a.name, b.name));
    //
    //     // sort by update
    //     this.features = result.sort(
    //       (a, b) => -compareDateStrings(a.updated_at, b.updated_at),
    //     );
    //   });
  }

  /* METHODS */

  private updateFeatureState() {
    //TODO: save these commented sections to restore public features when we will be ready RE-7998
    //   feature.active = !feature.active;
    //   wpsClient
    //     .updateInternalFeatures(feature.id.toString(), feature)
    //     .pipe(
    //       catchError(() => {
    //         return EMPTY;
    //       }),
    //     )
    //     .subscribe(() => {});
  }
}
