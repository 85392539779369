






























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { httpModule } from '@/store/modules/moduleHttp';
import { pricingModule } from '@/store/modules/modulePricing';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { isEmpty } from '@/utils/helpers';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { companyModule } from '@/store/modules/moduleCompany';
import { inventoryModule } from '@/store/modules/moduleInventory';
import { CapacityPool, Event, OfferCapacity } from '@/models';
import InventorySkeleton from '@/components/inventory/InventorySkeleton.vue';
import InventoryCalendar from '@/components/inventory/inventoryManagement/InventoryCalendar.vue';
import InventoryDayLevel from '@/components/inventory/inventoryManagement/InventoryDayLevel.vue';
import { CapacityType } from '@/api/inventory/InventoryModels';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import { format, parseISO } from 'date-fns';
import { availabilityStatusModule } from '@/store/modules/moduleAvailabilityStatus';
import AvailabilityEdit from '@/views/inventory/AvailabilityEdit.vue';
import { constants } from '@/utils/constants';
import _ from 'lodash';
import RdmBanner from '@/components/common/banner/RdmBanner.vue';
import { eventsModule } from '@/store/modules/moduleEvents';

@Component({
  components: {
    AvailabilityEdit,
    RdmCollapsableCard,
    InventorySkeleton,
    InventoryCalendar,
    InventoryDayLevel,
    RdmBanner,
  },
})
export default class AvailabilityHome extends Vue {
  private loading = false; // todo replace with module variable
  private inventoryOpen = true;
  private isEmpty = isEmpty;
  private showSidebar = true;
  private editModal = false;
  private capacityLimitPooled = CapacityType.CapacityLimitPooled;
  private capacityLimitByUnit = CapacityType.CapacityLimitByUnit;
  private capacityUnlimited = CapacityType.CapacityLimitUnlimited;

  get OfferUnpublishedEvents() {
    return eventsModule.OfferUnpublishedEvents;
  }

  get ShowUnpublishedWarning(): boolean {
    return !!this.OfferUnpublishedEvents.find(
      (item: Event) => item.EntityType === 'offerCapacity',
    );
  }

  get InventoryLoading() {
    return pricingModule.InventoryLoading;
  }

  get Offer() {
    return pricingModule.Offer;
  }

  get ItemOpen() {
    return inventoryModule.ItemOpen;
  }

  get OfferCapacity() {
    return pricingModule.OfferCapacity;
  }

  get Schedule() {
    return scheduleModule.Schedule;
  }

  get TravelerTypes() {
    return travelerTypeModule.TravelerTypes;
  }

  get CapacityPool() {
    const productCapacity = this.ProductCapacityPool;
    const optionCapacity = this.OptionCapacityPool;

    if (!_.isEmpty(optionCapacity)) {
      return optionCapacity;
    }
    if (!_.isEmpty(productCapacity)) {
      return productCapacity;
    }
    return {} as CapacityPool;
  }

  get ProductCapacityPool() {
    if (
      productModule.ProductCapacityPool &&
      productModule.ProductCapacityPool.capacityPool
    ) {
      return productModule.ProductCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get OptionCapacityPool() {
    if (optionModule.OptionCapacityPool && optionModule.OptionCapacityPool.capacityPool) {
      return optionModule.OptionCapacityPool.capacityPool;
    }
    return {} as CapacityPool;
  }

  get Product() {
    return productModule.Product;
  }

  get Option() {
    return optionModule.Option;
  }

  get DisplayTravelerTypes() {
    return travelerTypeModule.DisplayTravelerTypes;
  }

  get SelectedCapacityType() {
    return pricingModule.SelectedCapacityType;
  }

  get SelectedCapacityProductOption() {
    return pricingModule.SelectedCapacityProductOption;
  }

  get SelectedCapacityByUnit() {
    return pricingModule.SelectedCapacityByUnits;
  }

  get OfferCapacityList() {
    return pricingModule.OfferCapacityList.sort((a, b) => {
      const upperA = a.name?.toUpperCase() || '';
      const upperB = b.name?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get Schedules() {
    return scheduleModule.Schedules;
  }

  get DayOfWeek() {
    if (this.CurrentDate) {
      return format(this.CurrentDate, 'eeee');
    }
    return '';
  }

  get IsoDate() {
    if (this.CurrentDate) {
      return format(this.CurrentDate, 'yyyy-MM-dd');
    }
    return '';
  }

  get CurrentDate() {
    if (
      availabilityStatusModule.SelectedDate &&
      availabilityStatusModule.SelectedDate.length > 0
    ) {
      return parseISO(availabilityStatusModule.SelectedDate);
    }
    return null;
  }

  async mounted() {
    inventoryModule.clearState();
    await this.pageInit();

    this.$root.$on(constants.events.INVENTORY_HOME_MODAL_CLOSE, (capacityId: string) => {
      this.closeModal(true, capacityId);
    });
  }

  private async pageInit() {
    this.loading = true;
    await httpModule.getOffer({ offerId: this.$route.params.id, withCapacity: true });
    if (this.Offer && this.Offer?.productId) {
      await httpModule.productSchedules(this.Offer.productId);
      await httpModule.getProduct(this.Offer.productId);
      await httpModule.getProductTripRoutes();
      await httpModule.getProductUnitTravelerTypes(this.Offer.productId);
      const id = companyModule.Organization?.ID || '';
      productModule.setProductCapacityPool({
        orgId: id,
        productId: this.Offer.productId,
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      await httpModule.getProductCapacity();
    }
    if (this.Offer && this.Offer?.optionId) {
      await httpModule.optionSchedules(this.Offer.optionId);
      await httpModule.getOption(this.Offer.optionId);
      await httpModule.getOptionTripRoutes();
      await httpModule.getOptionUnitTravelerTypes(this.Offer.optionId || '');
      const id = companyModule.Organization?.ID || '';
      optionModule.setOptionCapacityPool({
        orgId: id,
        optionId: this.Offer.optionId || '',
        capacityPool: {
          pooled: 0,
          unlimited: false,
          byUnit: {} as Map<string, string>,
        },
      });
      await httpModule.getOptionCapacity();
    }
    await pricingModule.chooseCapacityType();

    if (this.Offer && Array.isArray(this.Offer.capacity)) {
      await httpModule.getOfferCapacity(this.Offer.capacity);
      await httpModule.getSchedules();
      // this.Offer.capacity.forEach((capacity: OfferCapacityInList) => {
      //   httpModule.getOfferCapacity(capacity.id);
      // });
    }
    await httpModule.getUnpublishedChanges(this.$route.params.id);
    this.loading = false;
  }

  destroyed() {
    pricingModule.setOfferCapacity({} as OfferCapacity);
    this.$root.$off(constants.events.INVENTORY_HOME_MODAL_CLOSE);
  }

  goToAddInventory() {
    this.$router.push({ name: constants.routes.AVAILABILITY_CREATE }).catch();
  }

  saveCapacity() {
    this.$root.$emit(constants.events.INVENTORY_EDIT_SAVE);
  }

  removeCapacity() {
    this.$root.$emit(constants.events.INVENTORY_EDIT_REMOVE);
  }

  getScheduleName(id: string) {
    const schedule = this.Schedules.find((s) => s.id === id);
    if (schedule) {
      return schedule.name;
    }
    return '';
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  unitIdToTravelerName(id: string) {
    const i = this.DisplayTravelerTypes.findIndex((t) => t.value === id);
    if (i > -1) {
      return this.DisplayTravelerTypes[i].text;
    }
    return '';
  }

  async openEdit(capacityId: string) {
    await httpModule.getOfferCapacity(capacityId);
    this.editModal = true;
  }

  closeModal(load?: boolean, capacityId?: string) {
    this.editModal = false;
    if (load && capacityId) {
      // httpModule.getOfferCapacity(capacityId);
      this.pageInit();
    }
  }
}
